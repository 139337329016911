<template>
  <div class="ip-range">
    <el-input-number v-model="ipRange.octet1" :min="0" :max="255" :controls="false" @change="update1"></el-input-number>
    <div class="el-input-number divider-dot">.</div>
    <el-input-number v-model="ipRange.octet2" :min="0" :max="255" :controls="false" @change="update2"></el-input-number>
    <span class="el-input-number divider-dot">.</span>
    <el-input-number v-model="ipRange.octet3" :min="0" :max="255" :controls="false" @change="update3"></el-input-number>
    <span class="el-input-number divider-dot">.</span>
    <el-input-number v-model="ipRange.octet4" :min="0" :max="255" :controls="false" @change="update4"></el-input-number>
    <span class="el-input-number divider-slash">/</span>
    <el-input-number v-model="ipRange.mask" :min="8" :max="31" :controls="false" @change="updateMask"></el-input-number>
  </div>
</template>

<script>
export default{
  name: 'ip-range',
  props: ['value'],
  methods: {
    update1 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${value}.${ipRange.octet2}.${ipRange.octet3}.${ipRange.octet4}/${ipRange.mask}`)
    },
    update2 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${value}.${ipRange.octet3}.${ipRange.octet4}/${ipRange.mask}`)
    },
    update3 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${ipRange.octet2}.${value}.${ipRange.octet4}/${ipRange.mask}`)
    },
    update4 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${ipRange.octet2}.${ipRange.octet3}.${value}/${ipRange.mask}`)
    },
    updateMask (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${ipRange.octet2}.${ipRange.octet3}.${ipRange.octet4}/${value}`)
    }
  },
  computed: {
    ipRange () {
      const pattern = /^\d{1,3}(\.\d{1,3}){3}\/\d{1,2}$/
      if (pattern.test(this.value)) {
        const [ip, mask] = this.value.split('/')
        const [octet1, octet2, octet3, octet4] = ip.split('.')
        return { octet1, octet2, octet3, octet4, mask }
      } else {
        return { octet1: 0, octet2: 0, octet3: 0, octet4: 0, mask: 8 }
      }
    }
  }
}
</script>

<style scoped>
.ip-range {
  display: inline-block;
  height: 36px;
  border: 1px solid rgb(191, 203, 217);
  border-radius: 4px;
  padding-left: 10px;
}

.el-input-number {
  width: 32px;
}

.divider-dot {
  display: inline-block;
  color: rgb(131, 140, 150);
  font-size: 22px;
  width: 4px;
}

.divider-slash {
  display: inline-block;
  color: rgb(131, 140, 150);
  font-size: 22px;
  width: 8px;
}
</style>

<style>
  .ip-range .el-input-number.is-without-controls input {
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .ip-range .el-input__inner {
    background-color: transparent;
  }
</style>
