<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.el-dialog__header {
  margin-right: 0%;
}

h1 {
  color: #005ea3;
  margin: 0;
  padding: 1em;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: #000;
}

.bs-header {
  user-select: none;
}

.header {
  margin-top: 24px;
  margin-bottom: 24px;
}

.search {
  position: relative;
}

.search .clear {
  position: absolute;
  right: 6px;
  top: 10px;
  cursor: pointer;
}

.results {
  text-align: left;
  font-size: 14px;
  margin: 10px 12px;
  font-weight: 500;
  opacity: 0.7;
}

/* apply to clickable table rows */
.clickable {
  cursor: pointer;
}

/* dark header for all dialogs */
.el-dialog {
  text-align: left;
}

/* .el-dialog .el-dialog__header,
.el-dialog .el-dialog__header .el-dialog__headerbtn {
  background-color: #545c64;
} */

/* .el-dialog .el-dialog__header {
  padding: 20px;
} */

.el-dialog .el-dialog__footer .el-button {
  min-width: 100px;
}

.el-dialog .el-dialog__header .el-dialog__title {
  color: rgb(191, 203, 217);
}

.bs-align-right {
  text-align: right;
}

.el-table {
  text-align: left;
}
</style>
