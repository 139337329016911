<template>
  <el-dialog v-model="showDialog" title="Domain Details">
    <div v-if="domain">
      <el-row>
        <el-col :span="16">
          <h2>{{ domain.domain.name }}.{{ domain.domain.tld }}</h2>
        </el-col>
        <el-col :span="8">
          <h4>
            User: <el-tag size="small">{{ domain.creationUser }}</el-tag>
          </h4>
          <h4>Created: {{ datefilter(domain.creationDate) }}</h4>
          <h4>Status</h4>
          <span>{{ domain.status }}</span>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import ImpersonationMixin from "../../mixins/ImpersonationMixin";
import { getDomain } from "../../services/domains";
import moment from "moment";

export default {
  name: "domain-detail",
  mixins: [ImpersonationMixin],
  data() {
    return {
      showDialog: false,
      domain: undefined,
    };
  },
  methods: {
    open(baseId, domainId) {
      this.showDialog = true;
      getDomain(this.customer.id, baseId, domainId).then((response) => {
        this.domain = response.data;
      });
    },
  },
  computed: {
    datefilter(value) {
      if (value) {
        return moment(String(value)).format("MMMM Do YYYY, h:mm:ss A");
      } else {
        return null;
      }
    },
  },
};
</script>
