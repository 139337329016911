<template>
  <div>
    <header-inventory id="header-customer"></header-inventory>
    <div class="inventory-wrapper">
      <div class="inventory-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderInventory from './HeaderInventory'
  import impersonation from '../impersonation'

  export default {
    name: 'base-inventory',
    components: {
      HeaderInventory
    },
    beforeRouteEnter (to, from, next) {
      const customer = impersonation.getCustomer()
      if (!customer) {
        next('/customers')
      }
      next()
    },
    data () {
      return {}
    },
    methods: {
      handleNodeClick (node) {
        this.$router.push({name: node.to})
      },
      openWizard () {
        this.$refs.wizard.open()
      }
    }
  }

</script>

<style scoped>
  .inventory-wrapper {
    display: flex;
    width: 100%;
  }

  .inventory-content {
    width: 100%;
  }
</style>
