<template lang="html">
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
    <el-dialog
      v-model="showEditDialog"
      ref="editParam"
      title="Edit Parameter">
      <h3>Base Parameters</h3>
      <el-form :model="paramToEdit">
        <el-form-item label="Name">
          <el-input v-model="paramToEdit.name"></el-input>
        </el-form-item>
        <el-form-item label="Value">
          <el-input v-model="paramToEdit.value"></el-input>
        </el-form-item>
        <div v-if="errorsEdit.length > 0">
          <div  class="flex" v-for="(error, index) in errorsEdit" :key="index">
          <p class="nospace error">{{error}}</p>
        </div>
        </div>                
        <el-button @click="editParameter" type="primary">Edit</el-button>
      </el-form>
    </el-dialog>
    <h1>Base Parameters Details</h1>
    <el-row>
      <el-row>
        <el-dropdown @command="downloadVpnConfig">
          <el-button type="primary">
            <el-icon>
              <Download />
            </el-icon>
            VPN Config
          </el-button>
          <el-dropdown-menu>
            <template #suffix>
              <el-icon>
                <ArrowDown />
              </el-icon>
            </template>
            <el-dropdown-item v-for="config in vpnconfigs" :key="config.id" :command="config.id">{{ config.model }} ({{ config.firmwareVersion }})</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    <h3>{{ base.name }}'s Base Parameters</h3>
    <el-row>
      <el-col :span="22" >
        <el-row class="header" type="flex">
          <el-col :span="8" class="search">
            <el-input placeholder="Find fix base parameter" v-model="searchFixed" prefix-icon="Search">
              <template #suffix>
                <el-icon v-if="isSubsetFix" @click="clearSearch">
                  <CircleClose />                  
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubsetFix">
              showing {{filteredParametersFix.length}} of {{baseParamsFix.length}}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
        <el-table :data="baseParamsFix">
          <el-table-column label="Parameter Name" prop="name" sortable>
          </el-table-column>
          <el-table-column label="Value" prop="value" sortable></el-table-column>
        </el-table>

    <h3>Custom Base Parameters</h3>
      <el-col :span="22">
        <el-row class="header" type="flex">
          <el-col :span="8" class="search">
            <el-input placeholder="Find custom base parameter" v-model="searchCustom" prefix-icon="Search">
            <template #suffix>
              <el-icon v-if="isSubsetCustom" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
            </el-input>
          </el-col>
          <el-col :offset="1" :span="8">
            <div class="results" v-if="isSubsetCustom">
              showing {{filteredCustomEntites.length}} of {{baseParamsCustom.length}}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="flex" style="margin-bottom: 2rem">
      <el-input class="space-right" placeholder="Parameter name" v-model="parameterModel.name"></el-input>
      <el-input class="space-right" placeholder="Parameter value" v-model="parameterModel.value"></el-input>
      <el-button @click="addParameter" size="small" type="primary">
        <el-icon>
          <Plus />
        </el-icon>
        Add Parameter
      </el-button>
    </div>
    <div>
      <div v-if="errors.length > 0">
        <div class="flex" v-for="(error, index) in errors" :key="index">
        <p class="nospace error">{{error}}</p>
      </div>
      </div>      
    </div>
    <el-table :data="filteredCustomEntites">
      <el-table-column label="Parameter Name" prop="name" sortable>
      </el-table-column>
      <el-table-column label="Value" prop="value" sortable></el-table-column>
      <el-table-column label="Actions" width="110">
        <template v-slot="scope">
          <actions-dropdown 
            :entity="scope.row" 
            :edit="true" 
            @edit="editCustomParam"
            :hideRetreatFromShop="true">
            </actions-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <br/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import FilterMixin from "../../mixins/FilterMixin";
import ImpersonationMixin from "../../mixins/ImpersonationMixin";
import { getVpnConfig, getVpnConfigFile } from "../../services/vpns";
import FileSaver from "file-saver";
import _ from "lodash";

export default {
  name: "base-parameters",
  components: {
    ActionsDropdown,
  },
  mixins: [FilterMixin, ImpersonationMixin],
  created() {
    this.baseId = this.$route.params.id;
    getVpnConfig(this.customer.id, this.id).then((response) => {
      this.vpnconfigs.push(...response.data);
    });
    this.$store.dispatch("getBase", {
      customerId: this.customer.id,
      baseId: this.baseId,
    });
    this.errors = [];
  },
  data() {
    return {
      baseId: "",
      searchFixed: "",
      searchCustom: "",
      vpnconfigs: [],
      parameterModel: {
        name: "",
        value: "",
      },
      showEditDialog: false,
      paramToEdit: {
        name: "",
        value: "",
      },
      formerParamName: "",
      errorsEdit: [],
      errors: [],
      categories: { general: "General", network: "Network", vpn: "VPN" },
    };
  },
  methods: {
    downloadVpnConfig(a) {
      getVpnConfigFile(this.customer.id, this.$route.params.id, a).then(
        (response) => {
          const contentDisposition = response.headers["content-disposition"];
          const myRegexp = /.*filename=(.*?);/;
          const match = myRegexp.exec(contentDisposition);
          const filename = match[1];
          const blob = new window.Blob([response.data], {
            type: "text/plain;charset=utf-8",
          });
          FileSaver.saveAs(blob, filename, true);
        }
      );
    },
    addParameter() {
      if (this.validateInput()) {
        let data = {
          baseId: this.baseId,
          advancedProp: {
            name: String.trim(this.parameterModel.name),
            value: String.trim(this.parameterModel.value),
          },
          customerId: this.customer.id,
        };
        this.$store.dispatch("addOrUpdateAdvancedBaseProp", data);
        this.parameterModel.name = "";
        this.parameterModel.value = "";
      }
    },
    validateInput() {
      let specialCharRegex = /[!§@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errors = [];
      if (this.parameterModel.name && this.parameterModel.value) {
        if (!specialCharRegex.test(this.parameterModel.name)) {
          var count$ = this.countDollarSign(this.parameterModel.name);
          if (
            this.parameterModel.name.charAt(0) === "$" &&
            this.parameterModel.name.length > 1
          ) {
            if (count$ === 1) {
              for (let i = 0; i < this.base.advancedProperties.length; i++) {
                if (
                  this.base.advancedProperties[i].name ===
                  this.parameterModel.name
                ) {
                  this.errors.push(
                    "The chosen property already exists! Choose another name please!"
                  );
                  return false;
                }
              }
            } else {
              this.errors.push(
                "Please only use the $ sign at the beginning of the property name!"
              );
              return false;
            }
          } else {
            this.errors.push("The property name has to start with a $ sign!");
            return false;
          }
        } else {
          this.errors.push(
            "The name must not contain special characters or spaces! (except $)"
          );
          return false;
        }
        return true;
      } else {
        if (!this.parameterModel.name) {
          this.errors.push("You must insert a name for a new property!");
        }
        if (!this.parameterModel.value) {
          this.errors.push("You must enter a value for a new property!");
        }
        return false;
      }
    },
    countDollarSign(text) {
      let counter = 0;
      for (let i = 0; i < text.length; i++) {
        if (text[i] === "$") {
          counter++;
        }
      }
      return counter;
    },
    baseParamsCategory(category) {
      return _.filter(this.baseParamsFix, function (o) {
        return _.get(o, "category.name") === category;
      });
    },
    editCustomParam(parameter) {
      this.paramToEdit.name = parameter.name;
      this.paramToEdit.value = parameter.value;
      this.formerParamName = parameter.name;
      this.showEditDialog = true;
    },
    validateEditInput() {
      let specialCharRegex = /[!@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errorsEdit = [];
      if (!this.paramToEdit.name) {
        this.errorsEdit.push("You have to set the parameter name!");
      }
      if (!this.paramToEdit.value) {
        this.errorsEdit.push("You have to set the parameter value!");
      }
      if (!specialCharRegex.test(this.paramToEdit.name)) {
        if (this.paramToEdit.name !== this.formerParamName) {
          for (let i = 0; i < this.deviceParams.length; i++) {
            if (this.paramToEdit.name === this.deviceParams[i].name) {
              this.errorsEdit.push(
                "There already exists a parameter with this name! Please rename the new parameter!"
              );
            }
          }
        }
      } else {
        this.errorsEdit.push(
          "The name must not contain special characters or spaces!"
        );
      }
      return this.errorsEdit.length === 0;
    },
    editParameter() {
      if (this.validateEditInput()) {
        let payload = {
          customerId: this.customer.id,
          baseId: this.baseId,
          advancedProp: {
            name: String.trim(this.paramToEdit.name),
            value: String.trim(this.paramToEdit.value),
          },
        };
        this.$store.dispatch("addOrUpdateAdvancedBaseProp", payload);
        this.$store.dispatch("getBase", {
          customerId: this.customer.id,
          baseId: this.baseId,
        });
        this.showEditDialog = false;
      }
    },
  },
  computed: {
    filteredParametersFix() {
      return this.filterEntities(this.baseParamsFix, this.searchFixed);
    },
    filteredCustomEntites() {
      return this.filterEntities(this.baseParamsCustom, this.searchCustom);
    },
    isSubsetFix() {
      return this.baseParamsFix.length !== this.filteredParametersFix.length;
    },
    isSubsetCustom() {
      return this.baseParamsCustom.length !== this.filteredCustomEntites.length;
    },
    baseParamsFix() {
      return _.filter(this.base.advancedProperties, { isEditable: false });
    },
    baseParamsCustom() {
      return _.filter(this.base.advancedProperties, { isEditable: true });
    },
    base() {
      return this.$store.state.base.currentBase;
    },
  },
};
</script>

<style lang="css" scoped>
.centeredText {
  text-align: center;
}

.flex {
  width: 100%;
  display: flex;
}

.space-right {
  padding-right: 10px;
}
</style>
