<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Drivers</h3>
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input placeholder="Find driver" v-model="search" autofocus>
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubset"
              @click="clearSearchDrivers"
            ></i> -->
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredDrivers.length }} of {{ drivers.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button type="primary" @click="openAddDrivers"
            >Add Driver</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table :data="filteredDrivers" highlight-current-row>
        <el-table-column prop="name" label="Name" sortable> </el-table-column>
        <el-table-column prop="description" label="Description" sortable>
        </el-table-column>
        <el-table-column prop="connectionString" label="Connection String">
        </el-table-column>
        <el-table-column prop="containerName" label="Container Name">
        </el-table-column>
        <el-table-column prop="version" label="Version"></el-table-column>
        <el-table-column label="Published">
          <template v-slot="scope">
            <span v-if="scope.row.publishInShop">True</span>
            <span v-else>False</span>
          </template>
        </el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              :publishInShop="true"
              @edit="openEditDriver(scope.row)"
              @delete="deleteDriver(scope.row)"
              @publishInShop="publishDriver(scope.row)"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-driver ref="addDriver"></add-driver>
    <edit-driver ref="editDriver"></edit-driver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { publishInShop } from "../../services/drivers";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import AddDriver from "../drivers/AddDriver.vue";
import EditDriver from "../drivers/EditDriver.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "add-driver": AddDriver,
    "edit-driver": EditDriver,
  },
  created() {
    this.$store.dispatch("getDrivers");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    publishDriver(driver) {
      publishInShop(driver)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < this.$store.state.drivers.length; i++) {
            if (data.id === this.$store.state.drivers[i]) {
              this.$store.state.drivers.splice(i, 1, data);
              break;
            }
          }
        })
        .catch((error) => console.log({ error }));
    },
    deleteDriver(driver) {
      this.$store.dispatch("deleteDriver", driver.id);
    },
    openEditDriver(driver) {
      this.$refs.editDriver.openDialog(driver);
    },
    openAddDrivers() {
      this.$refs.addDriver.openDialog();
    },
    clearSearchDrivers() {
      this.search = "";
    },
  },
  computed: {
    ...mapState(["drivers"]),
    filteredDrivers() {
      return this.filterEntities(this.drivers, this.search);
    },
    isSubset() {
      return this.drivers.length !== this.filteredDrivers.length;
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  padding-bottom: 50px;
}
</style>
