export function vnNameStart (rule, value, callback) {
  if (!/^[a-zA-Z0-9]{1}[a-zA-Z0-9_.-]*$/.test(value)) {
    callback('Name must start with a letter or digit.')
    return
  }
  callback()
}

export function vnName (rule, value, callback) {
  if (!/^[a-zA-Z0-9_.-]*$/.test(value)) {
    callback('Name can contain just letters, digits, underscores, dots and dashes.')
    return
  }
  callback()
}

export function vnNameEnd (rule, value, callback) {
  if (!/^[a-zA-Z0-9_.-]*[a-zA-Z0-9_]{1}$/.test(value)) {
    callback('Name must end with a letter, digit or underscore.')
    return
  }
  callback()
}

export default{
  name: 'virtual-network-validation',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input virtual network name', trigger: 'blur' },
          { min: 2, message: 'Name should at least have 2 chars', trigger: 'blur' },
          { max: 64, message: 'Name should at most have 64 chars', trigger: 'blur' },
          { validator: vnName, trigger: 'blur' },
          { validator: vnNameStart, trigger: 'blur' },
          { validator: vnNameEnd, trigger: 'blur' }
        ],
        resourceGroupName: [
          { required: true, message: 'Please select a resource group', trigger: 'change' }
        ],
        addressRange: [
          { required: true, message: 'Please select an address range', trigger: 'blur' }
        ],
        subnetName: [
          { required: true, message: 'Please input subnet name', trigger: 'blur' }
        ]
      }
    }
  }
}
