<template>
  <div>
    <el-dialog
      v-model="showDialog"
      title="Add Azure Cloud Credentials"
      :close-on-click-modal="false"
    >
      <el-row style="margin-bottom: 20px">
        <el-col
          ><el-button type="primary" disabled
            >New Subscription</el-button
          ></el-col
        >
        <el-col>
          <el-button @click="openCredentialsTutorial">
            <el-icon>
              <Document />
            </el-icon>
            Retrieve Credentials</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-form
          ref="form"
          :model="azureEP"
          :rules="rulesCEP"
          label-width="180px"
        >
          <el-form-item label="Name" prop="name">
            <el-input v-model="azureEP.name"></el-input>
          </el-form-item>
          <el-form-item label="Azure Tenant ID" prop="tenantId">
            <el-input
              autocomplete="new-password"
              v-model="azureEP.TenantId"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="Azure Client ID" prop="clientId">
            <el-input
              autocomplete="new-password"
              v-model="azureEP.ClientId"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="Azure Client Secret" prop="clientSecret">
            <el-input
              autocomplete="new-password"
              v-model="azureEP.ClientSecret"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="Standard Credentials" prop="isStandard">
            <el-checkbox v-model="azureEP.isStandard"></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">Submit</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <credentials-tutorial ref="credentialsTutorial"></credentials-tutorial>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import CredentialsTutorial from "../customers/CredentialsTutorial.vue";
import CloudEPValidation from "./CloudEPValidation";
import _ from "lodash";
import { addAzureData } from "../../services/azureClouds";
// import {Message} from 'element-ui'
export default {
  name: "cloud-azure",
  mixins: [CloudEPValidation],
  components: {
    CredentialsTutorial,
  },
  created() {
    // getAzureData(impersonation.getCustomer().id).then((response) => {
    //   if (response.data !== null) {
    //     this.azureEP = response.data
    //     this.dataSave = false
    //   } else {
    //     Message.error({message: 'Your cloud credentials  are  not set. Please  set your cloud credentials before you continue!'})
    //   }
    // })
    this.customerId = impersonation.getCustomer().id;
  },
  data() {
    return {
      showDialog: false,
      azureEP: {
        tenantId: "",
        clientId: "",
        clientSecret: "",
        type: "azure",
        isStandard: false,
        name: "",
      },
      customerId: "",
      dataSave: true,
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addAzureData(this.customerId, _.cloneDeep(this.azureEP))
            .then((response) => {
              this.$store.dispatch("setCEPs", response.data);
            })
            .finally(() => this.$store.dispatch("getSubscriptions"));
          this.showDialog = false;
        }
      });
    },
    openCredentialsTutorial() {
      this.$refs.credentialsTutorial.open();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
