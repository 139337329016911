<template>
  <el-dialog
    title="Add Storage Entry Point"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form :model="sep" ref="form" :rules="rules">
      <el-form-item label="Type" prop="type">
        <el-select
          style="width: 100%"
          v-model="type"
          placeholder="Select type please"
          @change="typeSelectChange"
        >
          <el-option
            v-for="t in typeOptions"
            :key="t.key"
            :label="t.label"
            :value="t.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div v-if="typeSelected">
        <el-form-item label="Subscription" prop="subscriptionId">
          <el-select
            :disabled="disableItems"
            v-model="sep.subscriptionId"
            filterable
            placeholder="Select Subscription"
          >
            <el-option
              v-for="sub in subscriptions"
              :key="sub.id"
              :label="sub.name"
              :value="sub.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-checkbox
          :disabled="disableItems"
          v-model="sep.isCSDP"
          @change="setCSDPSettings"
          >Is CSDP</el-checkbox
        >
        <el-form-item label="Resource Group" prop="resourceGrpName">
          <el-input
            :disabled="disableItems"
            v-model="sep.resourceGrpName"
            placeholder="Set the Resource Group name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Location" prop="location" v-model="sep.location">
          <el-select
            :disabled="disableItems"
            v-model="sep.location"
            placeholder="Select location"
            filterable
          >
            <el-option
              v-for="location in locations"
              :key="location.name"
              :label="location.displayName"
              :value="location.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Storage Account Name" prop="storageAccount">
          <el-input
            :disabled="disableItems"
            v-model="sep.storageAccount"
            placeholder="Set the Storage Account name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Storage Account Kind" prop="storageAccountKind">
          <el-select
            :disabled="disableItems"
            v-model="sep.kind"
            placeholder="Select the Storage Account Kind"
          >
            <el-option
              v-for="kind in kinds"
              :key="kind.id"
              :label="kind.name"
              :value="kind.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Storage Account Replication"
          prop="storageAccountType"
        >
          <el-select
            :disabled="disableItems"
            v-model="sep.storageAccountType"
            placeholder="Select the Storage Account Type"
          >
            <el-option
              v-for="type in storageTypes"
              :key="type.id"
              :label="type.name"
              :value="type.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Blob Container Name" prop="blobContainerName">
          <el-input
            :disabled="disableBlobContainerName"
            v-model="sep.blobContainerName"
            placeholder="Please set the blob container name"
          >
          </el-input>
        </el-form-item>
        <el-button
          style="margin: 0 auto; text-align: center"
          type="primary"
          @click="submit"
          >Add Storage Entry Point</el-button
        >
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import SubscriptionSelect from "../subscriptions/SubscriptionSelect.vue";
// import store from '../../store/index'
import { checkResGrpNameAvailable } from "../../services/resourceGroups";
import impersonation from "../../impersonation";
import _ from "lodash";
import { getBitStreamSubscription } from "../../services/subscriptions";
// import {mapState} from 'vuex'

export default {
  name: "addSEP",
  components: [SubscriptionSelect],
  beforeCreate() {
    this.$store.dispatch("getSubscriptions");
    this.$store.dispatch("getLocations");
  },
  data() {
    let validateBlobContainerName = (_, value, callback) => {
      let blobName = this.sep.blobContainerName;
      if (blobName === "") {
        callback("Please set the blob container name");
      }
      if (!/^[a-z0-9]*$/.test(blobName)) {
        callback("Name can include only lowercase letters and numbers");
      }
      callback();
    };
    // let validateStorageType = (_, value, callback) => {
    //   if (this.sep.storageAccountType === '') {
    //     callback('Please select the Storage Account type')
    //   } else {
    //     callback()
    //   }
    // }
    let validateStorageName = async (_, value, callback) => {
      let storageName = this.sep.storageAccount;
      if (storageName === "") {
        callback("Please set the Storage Account name");
      } else {
        if (!/^[a-z0-9]*$/.test(storageName)) {
          callback("Name can include only lowercase letters and numbers.");
        } else {
          let customerId = impersonation.getCustomer().id;
          await window.axiosInstance
            .post(`/storage-accounts/${customerId}/validateName`, {
              Name: storageName,
              Managed: this.type.split(" ")[0],
            })
            .then(() => {
              callback();
            })
            .catch((err) => {
              let errorMsg = "";
              if (this.type === "Managed Storage [250GB] (9,95€/month)") {
                this.counter++;
                var customerName = impersonation
                  .getCustomer()
                  .name.toLowerCase();
                customerName = customerName.split(" ").join("");
                errorMsg =
                  "The Storage Account Name " +
                  "'" +
                  this.sep.storageAccount +
                  "'" +
                  "is taken already. We changed it for you. Please try again.";
                this.sep.storageAccount = customerName + this.counter;
              }
              if (errorMsg === "") {
                callback(err.response.data);
              } else {
                callback(errorMsg);
              }
            });
        }
      }
    };
    // let validateLocation = (_, value, callback) => {
    //   if (this.sep.loaction === '') {
    //     callback('Please select the location')
    //   } else {
    //     callback()
    //   }
    // }
    let validateSubscriptionId = (_, value, callback) => {
      if (this.subscriptions.length !== 0) {
        if (this.sep.subscriptionId !== "") {
          callback();
        } else {
          callback("Please select the subscription");
        }
      } else {
        callback("Please add a Cloud Entry Point first");
      }
    };
    let validateRgName = async (_, value, callback) => {
      var toValidate = this.sep.resourceGrpName;
      var allowedChars = new Map();
      var seed =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789().-_";
      if (toValidate === "") {
        callback("Please set the resource group name");
      }
      for (var i = 0; i < seed.length; i++) {
        allowedChars.set(seed.charAt(i));
      }
      for (i = 0; i < toValidate.length; i++) {
        if (!allowedChars.has(toValidate.charAt(i))) {
          callback(
            "Name can only include alphanumeric chars, dots, underscores, dashes and parenthesis."
          );
          return;
        }
      }
      if (this.sep.subscriptionId === "") {
        callback("Please select the subscription");
        return;
      }
      await checkResGrpNameAvailable(
        impersonation.getCustomer().id,
        this.sep.subscriptionId,
        toValidate,
        this.type.split(" ")[0]
      )
        .then((response) => {
          if (response.status === 200) {
            callback();
          }
        })
        .catch(() => callback("The resource group name is already taken"));
    };
    let validateStrgKind = (_, value, callback) => {
      if (this.sep.kind !== "") {
        callback();
      } else {
        callback("Please set the Storage Account Kind");
      }
    };
    return {
      counter: 0,
      disableBlobContainerName: false,
      kinds: [
        { id: 1, label: "Storage" },
        { id: 2, label: "Storage V2" },
        { id: 3, label: "BlobStorage" },
      ],
      type: "",
      typeOptions: [
        { key: 1, label: "Managed Storage [250GB] (9,95€/month)" },
        { key: 2, label: "Customer Cloud (cloud entry point needed)" },
      ],
      rules: {
        subscriptionId: [
          {
            validator: validateSubscriptionId,
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        resourceGrpName: [
          {
            validator: validateRgName,
            trigger: "blur",
          },
        ],
        location: [
          // {
          //   validator: validateLocation, trigger: 'change'
          // }
          {
            required: true,
            message: "Please select the Azure Location",
            trigger: ["change", "blur"],
          },
        ],
        storageAccount: [
          {
            required: true,
            validator: validateStorageName,
            trigger: "blur",
          },
        ],
        storageAccountType: [
          // {
          //   validator: validateStorageType, trigger: 'change'
          // }
          {
            required: true,
            message: "Please set the Storage Account Type",
            trigger: ["change", "blur"],
          },
        ],
        storageAccountKind: [
          { required: true, validator: validateStrgKind, trigger: "change" },
        ],
        blobContainerName: [
          {
            validator: validateBlobContainerName,
            trigger: "blur",
          },
        ],
      },
      showDialog: false,
      typeSelected: false,
      sep: {
        subscriptionId: "",
        isCSDP: true,
        resourceGrpName: "",
        location: "",
        storageAccount: "",
        type: "Azure",
        replication: "",
        blobContainerName: "",
        storageAccountType: "Standard_LRS",
        kind: "",
        managed: false,
      },
      bitstreamSubscriptions: [],
      disableItems: false,
    };
  },
  methods: {
    typeSelectChange(val) {
      var removeDiacritics = require("diacritics").remove;
      this.typeSelected = true;
      this.type = val;
      var customerName = impersonation.getCustomer().name.toLowerCase();
      customerName = customerName.replace(/\u00e4/g, "ae");
      customerName = customerName.replace(/\u00fc/g, "ue");
      customerName = customerName.replace(/\u00f6/g, "oe");
      customerName = removeDiacritics(customerName);
      customerName = customerName = customerName.split(" ").join("");
      this.sep.resourceGrpName = "Charged-ResGroup-" + customerName;
      this.sep.storageAccount = customerName;
      this.sep.location = "germanywestcentral";
      this.sep.blobContainerName = "csdp";
      this.sep.kind = "Storage V2";
      this.disableBlobContainerName = true;
      if (this.type.includes("Managed Storage")) {
        this.sep.storageAccount = this.sep.storageAccount.replace(
          /[^a-zA-Z ]/g,
          ""
        );
        this.sep.managed = true;
        getBitStreamSubscription(impersonation.getCustomer().id)
          .then((response) => {
            this.disableItems = true;
            this.bitstreamSubscriptions = response.data;
            this.sep.subscriptionId = this.bitstreamSubscriptions.find(
              (el) => el.id === "18c38e17-e770-4a59-aba4-6c1bc4bf4b2e"
            ).id;
          })
          .catch((error) => console.log({ error }));
      } else {
        this.disableItems = false;
        this.sep.subscriptionId = "";
        this.sep.resourceGrpName = "ResGroup-" + customerName;
        this.bitstreamSubscriptions = [];
        this.sep.managed = false;
      }
    },
    setCSDPSettings() {
      if (this.sep.isCSDP) {
        this.disableBlobContainerName = true;
        this.sep.blobContainerName = "csdp";
      } else {
        this.disableBlobContainerName = false;
        if (this.sep.blobContainerName === "csdp") {
          this.sep.blobContainerName = "";
        }
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.typeSelected = false;
      this.type = "";
      this.disableItems = false;
      this.disableBlobContainerName = false;
      this.counter = 0;
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let customerId = impersonation.getCustomer().id;
          let payload = {
            customerId: customerId,
            payload: _.cloneDeep(this.sep),
          };
          this.$store.dispatch("addSEP", payload);
          this.$refs.form.resetFields();
          this.typeSelected = false;
          this.disableItems = false;
          this.disableBlobContainerName = false;
          this.type = "";
          this.counter = 0;
          this.showDialog = false;
        }
      });
    },
    openDialog() {
      this.showDialog = true;
    },
  },
  computed: {
    storageTypes: {
      get() {
        return this.$store.state.storageTypes;
      },
      set() {
        // Do nothing
      },
    },
    locations: {
      get() {
        return this.$store.state.locations;
      },
      set() {
        // Do nothing
      },
    },
    subscriptions: {
      get() {
        if (this.$store.state.subscriptions.length !== 0) {
          return this.$store.state.subscriptions;
        } else {
          return this.bitstreamSubscriptions;
        }
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
