export function addAssetLabels (customerId, amount) {
  return window.axiosInstance.post(`asset-mgmt/${customerId}/${amount}`)
}

export function getAssetLabels () {
  return window.axiosInstance.get(`asset-mgmt`)
}

export function editAsset (item) {
  return window.axiosInstance.put(`asset-mgmt`, item)
}

export function getAssetLabel (id) {
  return window.axiosInstance.get(`asset-mgmt/${id}`)
}

export function assignAsset (clientId, assetModelId) {
  return window.axiosInstance.put(`asset-mgmt/${clientId}/${assetModelId}`)
}

export function deleteAssetModel (id) {
  return window.axiosInstance.delete(`asset-mgmt/${id}`)
}

export function unassignAsset (id) {
  return window.axiosInstance.put(`asset-mgmt/${id}`)
}

export function exportAssetSelectionToCSV (payload) {
  return window.axiosInstance.post('asset-mgmt/export', payload, { responseType: 'blob' })
}

export function addAssetType (payload) {
  return window.axiosInstance.post('asset-types', payload)
}

export function getAssetTypes (customerId) {
  return window.axiosInstance.get(`asset-types/${customerId}`)
}

export function addAssetClass (payload) {
  return window.axiosInstance.post('asset-classes', payload)
}

export function getAssetClasses (customerId) {
  return window.axiosInstance.get(`asset-classes/${customerId}`)
}

export function updateAssetModel (payload) {
  return window.axiosInstance.put('asset-mgmt/update', payload)
}

export function assignPerson (assetId, personId) {
  return window.axiosInstance.put(`asset-mgmt/person/${assetId}/${personId}`)
}

export function assignLocation (assetId, locationId) {
  return window.axiosInstance.put(`asset-mgmt/location/${assetId}/${locationId}`)
}

export function assignVendor (assetId, vendorModelId) {
  return window.axiosInstance.put(`asset-mgmt/vendor/${assetId}/${vendorModelId}`)
}
