<template>
  <div class="info-panel" :style="{ backgroundColor: color, height: height }">
    <p>
      {{ text }}
      <el-button class="Report" type="text" @click="openBugReport">
        <el-icon size="20px" style="margin-right: 5px">
          <Warning />
        </el-icon>
        Report a bug</el-button
      >
    </p>
    <bug-report ref="bugReport"></bug-report>
  </div>
</template>

<script>
import BugReport from "./BugReport";
export default {
  name: "info-panel",
  components: { BugReport },
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.computeHeight();
  },
  data() {
    return {
      height: "25px",
    };
  },
  methods: {
    openBugReport() {
      this.$refs.bugReport.openDialog();
    },
    computeHeight() {
      let adminHeader = document.getElementById("header-admin");
      let customerHeader = document.getElementById("header-customer");
      if (adminHeader) {
        let fetchedHeight = adminHeader.scrollHeight;
        this.height = 0.25 * fetchedHeight;
      } else if (customerHeader) {
        let fetchedHeight = customerHeader.scrollHeight;
        this.height = 0.25 * fetchedHeight;
      }
    },
  },
};
</script>

<style>
.info-panel {
  padding-top: 2px;
  color: #fff;
  font-size: 12px;
}
.Report {
  color: #fff;
  font-size: 12px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  right: 5px;
}
</style>
