<template lang="html">
  <el-dialog
    :title="getTitle"
    width="75%"
    :close-on-click-modal="false"
    v-model="showDialog">
    <el-col :offset="20"><el-button type="primary" @click="reloadActivityLog">Refresh</el-button></el-col>
    <el-table v-if="activityLogs.length !== 0" height="600" :data="activityLogs">
      <el-table-column sortable label="Timestamp" prop="timeStamp"></el-table-column>
      <el-table-column sortable label="Log" prop="message"></el-table-column>
      <el-table-column sortable label="Username" prop="userName"></el-table-column>
    </el-table>
    <div v-else style="text-align: center; margin: 0 auto; width: 50%">
      <span style="color: red">There are no activities logged yet.</span>
    </div>
  </el-dialog>
</template>

<script>
import { getActivityLog } from "../../services/clients";
import impersonation from "../../impersonation";

export default {
  name: "activity-log",
  data() {
    return {
      showDialog: false,
      activityLogs: [],
      clientName: "",
      clientId: "",
      customerId: "",
    };
  },
  methods: {
    reloadActivityLog() {
      getActivityLog(this.customerId, this.clientId)
        .then((response) => {
          this.activityLogs = response.data;
        })
        .catch((err) => console.error(err));
    },
    openDialog(clientId, clientName) {
      this.clientName = clientName;
      this.clientId = clientId;
      this.showDialog = true;
      this.customerId = impersonation.getCustomer().id;
      getActivityLog(this.customerId, clientId)
        .then((response) => {
          this.activityLogs = response.data;
        })
        .catch((err) => console.error(err));
    },
  },
  computed: {
    getTitle() {
      return "Activity Log for " + this.clientName;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
