import {
  addBase,
  addOrUpdateAdvancedBaseProp,
  getBase,
  getBases
} from '../../services/bases'
import impersonation from '../../impersonation'
import { debug } from './util'

export default {
  state: {
    bases: [],
    basesLoading: false,
    currentBase: []
  },
  mutations: {
    setBasesLoading(state, loading) {
      state.basesLoading = loading
    },
    addBases(state, bases) {
      state.bases = bases
    },
    addBase(state, base) {
      state.domains.push(base)
    },
    setBase(state, base) {
      state.currentBase = base
    }
  },
  actions: {
    getBases({ commit }) {
      commit('setBasesLoading', true)
      getBases(impersonation.getCustomer().id)
        .then(response => commit('addBases', response.data))
        .catch(debug)
        .finally(() => commit('setBasesLoading', false))
    },
    getBase({ commit }, payload) {
      getBase(payload.customerId, payload.baseId)
        .then(response => commit('setBase', response.data))
        .catch(debug)
    },
    addBase({ commit }, base) {
      addBase(impersonation.getCustomer().id, base)
        .then(response => commit('addBase', response.data))
        .catch(debug)
    },
    addOrUpdateAdvancedBaseProp(data) {
      addOrUpdateAdvancedBaseProp(data.customerId, data.baseId, data.advancedProp)
        // .then(response => console.log(response.data))
        .catch(debug)
    }
  },
  getters: {}
}
