<template>
  <el-dialog
    v-model="showDialog"
    title="Tutorial to create Bootable USB Drive"
    width="65%"
  >
    <div class="steps-footer">
      <el-button type="primary" @click="prev" :disabled="step < 1">
        <el-icon>
          <ArrowLeft />
        </el-icon>
        Previous
      </el-button>
      <div style="width: 100%; padding: 0 5px; text-align: left">
        <el-steps :active="step" finish-status="success">
          <el-step title="Step 1"></el-step>
          <el-step title="Step 2"></el-step>
          <el-step title="Step 3"></el-step>
          <el-step title="Step 4"></el-step>
          <el-step title="Step 5"></el-step>
          <el-step title="Step 6"></el-step>
          <el-step title="Step 7"></el-step>
          <el-step title="Step 8"></el-step>
          <el-step title="Step 9"></el-step>
          <el-step title="Step 10"></el-step>
          <el-step title="Step 11"></el-step>
        </el-steps>
      </div>
      <el-button>
        Next
        <el-icon>
          <ArrowRight />
        </el-icon>
      </el-button>
    </div>

    <div v-if="step === 0">
      <h3>
        Insert the USB Device you want to boot from into the USB-Plug. <br />
        Type 'cmd' in the search box below the Windows Button to open the
        command prompt on your computer.
      </h3>
      <img
        src="static/bootableUsb/tutorial-1-search-cmd.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 1">
      <h3>The command prompt window appears.</h3>
      <img
        src="static/bootableUsb/tutorial-2-open-cmd.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 2">
      <h3>
        Type 'diskpart', you have to probably verify with a yes. Another window
        appears.
      </h3>
      <img
        src="static/bootableUsb/tutorial-3-diskpart.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 3">
      <h3>
        Type 'list disk' in the new window which appeared. The list of all the
        devices is shown. <br />
        Find your USB device from the list and note the details including disk
        number and name of the device.
      </h3>
      <img
        src="static/bootableUsb/tutorial-4-find-diskname.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 4">
      <h3>
        Type 'select disk' followed by the disk number of the USB device which
        was noted earlier.
      </h3>
      <img
        src="static/bootableUsb/tutorial-5-diskname-no.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 5">
      <h3>Type 'clean' and press the enter key.</h3>
      <img src="static/bootableUsb/tutorial-6-clean.png" class="screenshot" />
    </div>

    <div v-if="step === 6">
      <h3>Type 'create partition primary' and press the enter key.</h3>
      <img
        src="static/bootableUsb/tutorial-7-create-primary-partition.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 7">
      <h3>Type 'format fs=ntfs quick' and press the enter key.</h3>
      <img
        src="static/bootableUsb/tutorial-8-format-primary-partition.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 8">
      <h3>Type 'active' and press the enter key.</h3>
      <img
        src="static/bootableUsb/tutorial-9-check-active.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 9">
      <h3>Type 'assign' and press the enter key.</h3>
      <img src="static/bootableUsb/tutorial-10-assign.png" class="screenshot" />
    </div>

    <div v-if="step === 10">
      <h3>
        In the end to exit the screen. Type 'exit' and press the enter key.
      </h3>
      <img src="static/bootableUsb/tutorial-11-exit.png" class="screenshot" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "bootableusb-tutorial",
  data() {
    return {
      showDialog: false,
      step: 0,
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    next() {
      this.step += 1;
    },
    prev() {
      this.step -= 1;
    },
  },
};
</script>

<style scoped>
.screenshot {
  width: 100%;
}
.el-row {
  margin-bottom: 25px;
}

.steps-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
