<template>
  <div>
    <InfoPanel text="Admin Panel" color="#545c64" />
    <el-menu
      mode="horizontal"
      :default-active="routeName"
      :router="true"
      class="bs-header"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-sub-menu
        mode="horizontal"
        :default-active="routeName"
        index="scripts"
        v-if="isAdmin"
      >
        <template v-slot:title>Environment</template>
        <el-menu-item style="min-width: auto" index="scripts" route="/scripts"
          >Release Management</el-menu-item
        >
        <el-menu-item
          style="min-width: auto"
          index="vendors"
          route="/vendorModels"
          >Vendor Management</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu index="hardware" v-if="isAdmin">
        <template v-slot:title>Hardware</template>
        <el-menu-item index="hardware" route="/hardware">Hardware</el-menu-item>
        <el-menu-item index="drivers" route="/drivers"
          >Driver Management</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu mode="horizontal" index="softwares" v-if="isAdmin">
        <template v-slot:title>Software</template>
        <el-menu-item index="images" route="/images"
          >OS Management</el-menu-item
        >

        <el-menu-item index="softwares" route="/softwares" v-if="isAdmin"
          >App Management</el-menu-item
        >
        <el-menu-item index="tasks">Task Management</el-menu-item>
        <el-menu-item index="deviceOptions" route="/deviceOptions"
          >Device Option Management</el-menu-item
        >
        <el-menu-item
          v-if="isAdmin"
          index="adminOptions"
          route="/adminDeviceOption"
          >Admin Device Option Management</el-menu-item
        >
        <el-menu-item index="deviceProperties" route="/deviceProperties"
          >Device Property Management</el-menu-item
        >
      </el-sub-menu>
      <el-menu-item index="admin-workflow" v-if="isAdmin"
        >Workflows</el-menu-item
      >
      <el-menu-item index="admin-domainreg" v-if="isAdmin"
        >Domain Registration</el-menu-item
      >
      <el-menu-item index="admin-certification" v-if="isAdmin"
        >Certification</el-menu-item
      >
      <el-menu-item index="systemhouses" style="float: right" v-if="isAdmin"
        >System houses</el-menu-item
      >
      <el-menu-item index="customers" style="float: right"
        >Customers</el-menu-item
      >
      <el-menu-item index="users" style="float: right">Users</el-menu-item>
      <el-sub-menu
        index="additional"
        style="float: right; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>
          {{ auth.sub }}
        </template>
        <el-menu-item index="role" style="min-width: auto" :disabled="true">
          Role - {{ permission }}
        </el-menu-item>
        <el-menu-item style="min-width: auto" index="settings"
          >Settings</el-menu-item
        >
        <el-menu-item style="min-width: auto" index="login" @click="signOut"
          >Logout</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu
        style="float: right"
        mode="horizontal"
        index="softwares"
        v-if="isSystemhouse && !isAdmin"
      >
        <template v-slot:title>Software</template>
        <el-menu-item index="images" route="/images"
          >OS Management</el-menu-item
        >
        <!-- <el-menu-item index="drivers" route="/drivers">Driver Management</el-menu-item> -->
        <el-menu-item index="softwares" route="/softwares"
          >App Management</el-menu-item
        >
        <el-menu-item index="tasks">Task Management</el-menu-item>
        <el-menu-item index="deviceOptions" route="/deviceOptions"
          >Device Option Management</el-menu-item
        >
        <!-- <el-menu-item index="deviceProperties" route="/deviceProperties">Device Property Management</el-menu-item> -->
      </el-sub-menu>
    </el-menu>
    <bug-report ref="bugReport"></bug-report>
  </div>
</template>

<script>
import Auth from "../auth";
import Impersonation from "../impersonation";
import Permission from "../mixins/Permission";
import InfoPanel from "./InfoPanel";
import BugReport from "./BugReport.vue";
import * as Msal from "@azure/msal-browser";

export default {
  name: "header-admin",
  components: {
    InfoPanel,
    BugReport,
  },
  mixins: [Permission],
  data() {
    return {
      noclickable: true,
    };
  },
  methods: {
    signOut() {
      if (this.$store.state.loginType == "microsoft") {
        const msalConfig = {
          auth: {
            clientId: window.APPLICATION_SETTING_B2C_CLIENT_ID,
            authority: window.APPLICATION_SETTING_B2C_AUTHORITY,
            redirectUri: window.APPLICATION_SETTING_B2C_REDIRECT_URL,
            knownAuthorities: window.APPLICATION_SETTING_B2C_KNOWN_AUTHS,
            autoRefershToken: false,
          },
        };
        const msalInstance = new Msal.PublicClientApplication(msalConfig);
        // sessionStorage.clear();
        msalInstance.logoutPopup();
      }
      Auth.signOut();
      Impersonation.removeCustomer();
      this.$store.commit("initState");
    },
    openBugReportDialog() {
      this.$refs.bugReport.openDialog();
    },
  },
  computed: {
    auth() {
      return Auth.getAuth();
    },
    permission() {
      let role = Auth.getPermission();
      if (role === "systemhouse") {
        return "Systemhouse Manager";
      }
      if (role === "customer") {
        return "Customer Manager";
      }
      if (role === "admin") {
        return "Admin";
      }
      return null;
    },
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.el-menu--dark .el-menu-item,
.el-menu--dark .el-sub-menu__title {
  color: rgb(226, 234, 241);
}
.el-menu-item.is-active {
  background-color: rgb(72, 87, 106);
}
.el-menu--horizontal.el-menu--dark .el-menu-item:hover,
.el-menu--horizontal > .el-menu-item:hover,
.el-menu--horizontal > .el-sub-menu:hover .el-sub-menu__title {
  background-color: rgb(72, 87, 106);
  border-bottom: 5px solid rgba(255, 255, 255, 0.8);
}
</style>
