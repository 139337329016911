export function addSoftwareAssign (customerId, clientId, softwareAssign) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${clientId}/software`, softwareAssign)
}

export function getSoftwareAssign (customerId, clientId) {
  return window.axiosInstance.get(`/customers/${customerId}/clients/${clientId}/software`)
}

export function deleteSoftwareAssign (customerId, clientId, softwareId, softwareAssign) {
  return window.axiosInstance.delete(`/customers/${customerId}/clients/${clientId}/software/${softwareId}`, softwareAssign)
}
