<template>
  <el-dialog
    title="Modify Parameters"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="returnOldScript"
  >
    {{ model }}
    <el-form :model="model" label-width="280px" ref="form">
      <div v-if="loading === true">
        <span v-loading>LOADING PARAMETERS ...</span>
      </div>
      <div v-else>
        <h2 v-if="model.parameters">Parameters</h2>
        <span v-if="model.parameters && model.parameters.length === 0"
          >This script has no parameters.</span
        >
        <div v-if="model.parameters">
          <el-form-item
            :key="parameter.key"
            :label="parameter.key"
            :prop="'parameters.' + index + '.value'"
            :rules="rulesParameter"
            placeholder="Specify Parameter"
            v-for="(parameter, index) in model.parameters"
          >
            <template
              v-if="
                (parameter.value === '$true' || parameter.value === '$false') &&
                setSwitchValue(parameter.value)
              "
            >
              <div>
                <el-switch
                  v-model="parameter.value"
                  active-value="$true"
                  inactive-value="$false"
                  active-text="True"
                  inactive-text="False"
                  active-color="#33cc33"
                  inactive-color="#ff3333"
                >
                </el-switch>
              </div>
            </template>
            <template v-else>
              <el-input v-model="parameter.value"></el-input>
            </template>
          </el-form-item>
          <el-button @click="submit()" type="primary">Assign</el-button>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import ImpersonationMixin from "../../mixins/ImpersonationMixin";
// import {scriptExecute, scriptGetParams} from '../../services/scripts'
import { getDeviceParameterWithDefaultValues } from "../../services/clients";
import impersonation from "../../impersonation";
import { assignAdminOption } from "../../services/deviceoptions";
import _ from "lodash";

export default {
  emits: ["returnOldScript"],
  name: "deviceoption-parameter",
  mixins: [ImpersonationMixin],
  created() {
    this.$store.dispatch("getScripts");
  },
  data() {
    let validateParameterValue = (_, value, callback) => {
      if (value.includes('"') || value.includes("'")) {
        callback("The special characters ' and \" are mot allowed!");
      } else {
        callback();
      }
    };
    return {
      isAdminScript: false,
      index: null,
      oldScript: null,
      switchValue: "",
      showDialog: false,
      scriptId: null,
      clientId: null,
      loading: false,
      model: {
        id: "",
        scriptVersionId: "",
        parameters: [
          {
            key: "",
            value: "",
          },
        ],
        name: "",
      },
      rules: {
        scriptVersionId: [
          {
            required: true,
            message: "Please select a script.",
            trigger: "change",
          },
        ],
        key: [
          {
            required: true,
            message: "Please specify a value.",
            trigger: "blur",
          },
        ],
      },
      rulesParameter: [
        { required: true, message: "Please fill in a value", trigger: "blur" },
        { validator: validateParameterValue, trigger: "blur" },
      ],
    };
  },
  methods: {
    returnOldScript(done) {
      if (this.oldScript !== null && this.index !== null) {
        this.$emit("returnOldScript", {
          oldScript: this.oldScript,
          index: this.index,
        });
        done();
      }
    },
    open(clientId, script, index, isAdminScript, modelId) {
      this.model.id = modelId;
      this.isAdminScript = isAdminScript;
      this.index = index;
      this.oldScript = script;
      this.model.scriptVersionId = script.id;
      this.model.name = script.name;
      this.clientId = clientId;
      this.loading = true;
      getDeviceParameterWithDefaultValues(
        impersonation.getCustomer().id,
        clientId,
        script.id,
        isAdminScript
      )
        .then((response) => {
          this.model.parameters = response.data;
        })
        .finally(
          (this.loading = false)
          // loading.close()
        );
      // this.model = {
      //   scriptVersionId: null,
      //   parameters: null
      // }
      this.showDialog = true;
    },
    openWithModel(clientId, script, index, isAdminScript, model) {
      this.model = model;
      this.isAdminScript = isAdminScript;
      this.index = index;
      this.oldScript = script;
      this.model.scriptVersionId = script.id;
      this.model.name = script.name;
      this.clientId = clientId;
      this.loading = true;
      getDeviceParameterWithDefaultValues(
        impersonation.getCustomer().id,
        clientId,
        script.id,
        isAdminScript
      )
        .then((response) => {
          this.model.parameters = response.data;
        })
        .finally(
          (this.loading = false)
          // loading.close()
        );
      // this.model = {
      //   scriptVersionId: null,
      //   parameters: null
      // }
      this.showDialog = true;
    },
    setSwitchValue(value) {
      this.switchValue = value;
      return true;
    },
    changeSwitchValue(value) {
      this.switchValue = !value;
    },
    editParameters(parameters) {
      let result = [];
      for (let i = 0; i < parameters.length; i++) {
        if (parameters[i].value.charAt(0) === '"') {
          result.push({
            key: parameters[i].key,
            description: parameters[i].description,
            displayName: parameters[i].displayName,
            value: parameters[i].value.substring(
              1,
              parameters[i].value.length - 1
            ),
            doubleQuotes: true,
            singleQuotes: false,
          });
        } else if (parameters[i].value.charAt(0) === "'") {
          result.push({
            key: parameters[i].key,
            description: parameters[i].description,
            displayName: parameters[i].displayName,
            value: parameters[i].value.substring(
              1,
              parameters[i].value.length - 1
            ),
            doubleQuotes: false,
            singleQuotes: true,
          });
        } else {
          result.push({
            key: parameters[i].key,
            description: parameters[i].description,
            displayName: parameters[i].displayName,
            value: parameters[i].value,
            doubleQuotes: false,
            singleQuotes: false,
          });
        }
        return result;
      }
    },
    submit() {
      let dataModel = _.cloneDeep(this.model);
      if (!this.isAdminScript) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // scriptExecute(this.customer.id, this.baseId, this.domainId, model.scriptVersionId, model.parameters)
            //   .then((response) => {
            //     console.log(response.data)
            //   })
            dataModel.id = this.model.scriptVersionId;
            this.$store.state.assignedDeviceOptions.push(dataModel);
            this.showDialog = false;
            this.$refs.form.resetFields();
          }
        });
      } else {
        assignAdminOption(
          impersonation.getCustomer().id,
          this.clientId,
          dataModel
        )
          .then((response) => console.log({ response }))
          .catch((error) => console.log({ error }));
        this.showDialog = false;
        this.$refs.form.resetFields();
      }
    },
  },
  computed: {
    ...mapState(["scripts"]),
    scriptVersions() {
      const scriptVersions = [];
      if (this.scripts && this.scriptId) {
        const script = this.scripts.filter(
          (script) => script.id === this.scriptId
        );
        const name = script[0].name;
        script[0]["versions"].map((version) =>
          scriptVersions.push({
            name: name + " v" + version.number,
            id: version.id,
          })
        );
      }
      return scriptVersions;
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
