export function createDomainRegistration (payload) {
  return window.axiosInstance.post(`/domainRegistrationTemp`, payload)
}

export function getDomainRegistrations () {
  return window.axiosInstance.get(`/domainRegistrationTemp`)
}

export function updateDomainRegistration (payload) {
  return window.axiosInstance.put(`/domainRegistrationTemp`, payload)
}
