<template>
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <!-- <el-input placeholder="Find domains" v-model="search">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i slot="suffix" class="el-input__icon el-icon-circle-close clickable" v-if="isSubset" @click="clearSearch"></i>
            </el-input> -->
          </el-col>
          <el-col :span="8">
            <!-- <div class="results" v-if="isSubset">
              showing {{filteredDomains.length}} of {{domains.length}}
            </div> -->
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <el-dropdown @command="downloadVpnConfig">
              <!-- icon="el-icon-download" -->
              <el-button type="primary">
                VPN Config
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="config in vpnconfigs"
                  :key="config.id"
                  :command="config.id"
                  >{{ config.model }} ({{
                    config.firmwareVersion
                  }})</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- icon="el-icon-tickets" -->
            <el-button @click="openBaseParameters(id)">Base Details</el-button>
            <!-- icon="el-icon-plus" -->
            <el-button
              class="button_conf"
              type="primary"
              @click="openDomainAdd"
              :disabled="filteredDomains.length > 0 ? true : false"
              >Add Domain</el-button
            >
            <!--el-button class="button_conf" icon="el-icon-plus" type="primary" @click="openConfigAdd">Add Domain</el-button-->
          </el-col>
        </el-row>
        <el-table
          :data="filteredDomains"
          v-loading.body="domainsLoading"
          row-class-name="clickable"
        >
          <el-table-column prop="name" label="Name" width="150">
            <template v-slot="scope">
              <el-button
                type="text"
                size="default"
                @click="openDomain(scope.row)"
              >
                {{ scope.row.domain.name }}.{{ scope.row.domain.tld }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Status">
            <template v-slot="scope">
              {{ scope.row.status }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--    <base-detail ref="baseDetail"></base-detail>-->
    <domain-add ref="domainAdd"></domain-add>
    <!--configwizard-add ref="configwizardAdd"></configwizard-add-->
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
// import BaseDetail from '../bases/BaseDetail'
import ImpersonationMixin from "../../mixins/ImpersonationMixin";
import DomainAdd from "./DomainAdd";
// import ConfigwizardAdd from '../configwizard/ConfigwizardAdd'
import { getVpnConfig, getVpnConfigFile } from "../../services/vpns";
// import { getBase } from '../../services/bases'
import FileSaver from "file-saver";

export default {
  name: "domains",
  mixins: [FilterMixin, ImpersonationMixin],
  components: {
    // BaseDetail,
    DomainAdd,
    // ConfigwizardAdd
  },
  created() {
    this.id = this.$route.params.id;
    this.$store.dispatch("getDomains", this.id);
    // getBase(this.customer.id, this.$route.params.id).then((response) => {
    //   this.baseDetails = response.data
    // })
    getVpnConfig(this.customer.id, this.id).then((response) => {
      this.vpnconfigs.push(...response.data);
    });
  },
  data() {
    return {
      id: "",
      search: "",
      vpnconfigs: [],
    };
  },
  methods: {
    /* openConfigAdd () {
      let idobj = {
        baseId: this.id
      }
      this.$refs.configwizardAdd.open(idobj)
    }, */
    openDomainAdd() {
      this.$refs.domainAdd.open();
    },
    // openBaseDetail (baseId) {
    //   this.$refs.baseDetail.open(baseId)
    // },
    openBaseParameters(baseId) {
      this.$router.push({ name: "parameters", params: { id: baseId } });
    },
    downloadVpnConfig(a) {
      getVpnConfigFile(this.customer.id, this.$route.params.id, a).then(
        (response) => {
          const contentDisposition = response.headers["content-disposition"];
          const myRegexp = /.*filename=(.*?);/;
          const match = myRegexp.exec(contentDisposition);
          const filename = match[1];
          const blob = new window.Blob([response.data], {
            type: "text/plain;charset=utf-8",
          });
          FileSaver.saveAs(blob, filename, true);
        }
      );
    },
    openDomain(domain) {
      this.$router.push({
        name: "domain",
        params: { id: this.id, did: domain.id },
      });
    },
  },
  computed: {
    domains() {
      return this.$store.state.domains;
    },
    domainsLoading() {
      return this.$store.state.domainsLoading;
    },
    filteredDomains() {
      let filteredArray = this.filterEntities(this.domains, this.search);
      if (filteredArray.length > 0) {
        return [filteredArray[0]];
      } else {
        return [];
      }
    },
    isSubset() {
      return this.domains.length !== this.filteredDomains.length;
    },
  },
};
</script>

<style>
.button_conf {
  margin-top: 5px;
}
</style>
