<template>
  <el-dialog
    v-model="showDialog"
    title="Create new domain"
    custom-class="step-dialog"
  >
    <el-form ref="form" :model="model" label-position="top" v-if="step === 0">
      <domain-input v-model="model.domain" prop="domain.name"></domain-input>
    </el-form>
    <el-button type="primary" @click="submit(model)"> Add </el-button>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { defaultmodel } from "./DomainAddModel";
import ToggleMask from "../../mixins/ToggleMask";
import DomainInput from "./DomainInput.vue";

export default {
  name: "domain-add",
  mixins: [ToggleMask],
  components: {
    DomainInput,
  },
  data() {
    return {
      showDialog: false,
      step: 0,
      type: "password",
      model: _.clone(defaultmodel),
    };
  },
  methods: {
    open() {
      this.model = _.clone(defaultmodel);
      this.model.baseId = this.$route.params.id;
      this.showDialog = true;
    },
    submit(configuration) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("addDomain", _.cloneDeep(configuration));
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 25px;
}
</style>
