<template>
  <div>
    <add-software-pre ref="addSwPre"></add-software-pre>
    <software-edit
      ref="softwareEdit"
      @revisionMessage="openRevisionMessage"
    ></software-edit>
    <revision-dialog ref="revisionMessage"></revision-dialog>
    <revision-overview ref="revisionOverview"></revision-overview>
    <el-dialog
      v-model="showDialog"
      title="Manage Versions"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="75%"
    >
      <h3 style="margin: 0 auto; text-align: center">
        {{ softwareStream.name }}
      </h3>
      <br />
      <el-row>
        <el-col :span="8" :offset="18"
          ><el-button type="primary" @click="openAddSoftwarePre"
            >Add New Version</el-button
          ></el-col
        >
      </el-row>
      <el-table
        :data="filteredSoftwares"
        highlight-current-row
        height="300"
        @sort-change="sortVersions"
      >
        <el-table-column prop="version" label="Version" sortable="custom">
        </el-table-column>
        <el-table-column
          prop="status"
          label="Admin Status"
          sortable
        ></el-table-column>
        <el-table-column
          prop="publishInShop"
          label="Published in Shop"
          sortable
        ></el-table-column>
        <el-table-column
          prop="displayRevisionNumber"
          label="Revision Number"
        ></el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              :exportData="true"
              :downloadFiles="true"
              :hideRetreatFromShop="
                scope.row.publishInShop === 'False' ? true : false
              "
              :publishInShop="
                scope.row.publishInShop === 'False' ? true : false
              "
              :activateSW="true"
              :outdateSW="true"
              :showRevisionNr="true"
              @edit="openSoftwareEdit(scope.row)"
              @delete="confirmDelete"
              @export="exportSoftware"
              @downloadFiles="downloadSWFiles(scope.row)"
              @publishInShop="publishSoftware(scope.row)"
              @retreatFromShop="retreatSoftware(scope.row)"
              @activateSW="activateSoftware"
              @outdateSW="outdateSoftware"
              @showRevisionNr="openRevisionOverview"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import AddSoftwarePreDialog from "./AddSoftwarePreDialog.vue";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import SoftwareEdit from "../software/SoftwareEdit.vue";
import RevisionMessageDialog from "./RevisionMessageDialog.vue";
import RevisionMessageOverview from "./RevisionMessageOverview.vue";
import {
  getSoftwareJsonFile,
  downloadSoftwareFiles,
  retreatFromShop,
  publishInShop,
  activateSoftware,
  outdateSoftware,
} from "../../services/softwares";
import FileSaver from "file-saver";
import { mapState } from "vuex";
// import { errorMessage } from '../../store/modules/util'

export default {
  components: {
    "add-software-pre": AddSoftwarePreDialog,
    "revision-dialog": RevisionMessageDialog,
    "revision-overview": RevisionMessageOverview,
    ActionsDropdown,
    SoftwareEdit,
  },
  mixins: [FilterMixin],
  data() {
    return {
      showDialog: false,
      sortVersionOrder: undefined,
      softwareStream: {
        id: "",
        name: "",
        updateSettings: "",
        streamMembers: [],
      },
    };
  },
  methods: {
    sortVersions(column) {
      this.sortVersionOrder = column.order;
    },
    openRevisionMessage(softwareId) {
      this.$refs.revisionMessage.open(softwareId, "software");
    },
    activateSoftware(software) {
      activateSoftware(software.id)
        .then(() => {
          // Update data of store
          for (let i = 0; i < this.$store.state.softwares.length; i++) {
            if (this.$store.state.softwares[i].id === software.id) {
              this.$store.state.softwares[i].status = "Active";
              break;
            }
          }
        })
        .catch((error) => console.log({ error }));
    },
    openRevisionOverview(software) {
      this.$refs.revisionOverview.open(software.id);
    },
    outdateSoftware(software) {
      outdateSoftware(software.id)
        .then(() => {
          // Update data of store
          for (let i = 0; i < this.$store.state.softwares.length; i++) {
            if (this.$store.state.softwares[i].id === software.id) {
              this.$store.state.softwares[i].status = "Outdated";
              break;
            }
          }
        })
        .catch((error) => console.log({ error }));
    },
    publishSoftware(software) {
      // this.$store.dispatch('publishInShop', software.id)
      publishInShop(software.id)
        .then((response) => {
          let responseSW = response.data;
          for (let i = 0; this.$store.state.softwares.length; i++) {
            if (this.$store.state.softwares[i].id === responseSW.id) {
              this.$store.state.softwares.splice(i, 1, responseSW);
              break;
            }
          }
        })
        .catch((error) => console.log({ error }));
    },
    retreatSoftware(software) {
      // this.$store.dispatch('retreatFromShop', software.id)
      retreatFromShop(software.id)
        .then((response) => {
          let responseSW = response.data;
          for (let i = 0; this.$store.state.softwares.length; i++) {
            if (this.$store.state.softwares[i].id === responseSW.id) {
              this.$store.state.softwares.splice(i, 1, responseSW);
              break;
            }
          }
        })
        .catch((error) => console.log({ error }));
    },
    downloadSWFiles(software) {
      downloadSoftwareFiles(software.id)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < data.length; i++) {
            let name = data[i].fileName;
            let uri = data[i].sasUri;
            let authHeader =
              window.axiosInstance.defaults.headers.common.Authorization;
            delete window.axiosInstance.defaults.headers.common[
              "Authorization"
            ];
            window.axiosInstance
              .get(uri, { responseType: "blob" })
              .then((response1) => {
                let blob = response1.data;
                FileSaver.saveAs(blob, name, true);
              })
              .catch((err) => console.log(err.data));
            window.axiosInstance.defaults.headers.common["Authorization"] =
              authHeader;
          }
        })
        .catch(() => console.log("ERROR LVL 1"));
    },
    exportSoftware(software) {
      getSoftwareJsonFile(software.id).then((response) => {
        let blob = response.data;
        var fileName = software.name.split(" ").join("_");
        fileName += "_" + software.version;
        FileSaver.saveAs(blob, fileName + ".zip", true);
      });
    },
    openAddSoftwarePre() {
      this.$refs.addSwPre.openDialog(
        this.softwareStream.id,
        this.softwareStream.name,
        this.softwareStream.type
      );
    },
    confirmDelete(software) {
      this.$confirm(
        "This will permanently delete the software. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, software), this.deleteError);
    },
    deleteSuccess(software) {
      this.$store.dispatch("deleteSoftware", software);
    },
    openSoftwareEdit(software) {
      this.$refs.softwareEdit.open(software.id, this.softwareStream.id);
    },
    openDialog(stream) {
      this.softwareStream = stream;
      this.showDialog = true;
    },
    handleClose() {
      this.showDialog = false;
    },
    compareDesc(a, b) {
      if (
        parseInt(a.version.replaceAll(".", "")) <
        parseInt(b.version.replaceAll(".", ""))
      ) {
        return 1;
      } else {
        return -1;
      }
    },
    compareAsc(a, b) {
      if (
        parseInt(a.version.replaceAll(".", "")) >
        parseInt(b.version.replaceAll(".", ""))
      ) {
        return 1;
      } else {
        return -1;
      }
    },
  },
  computed: {
    ...mapState(["softwares", "softwareStreams"]),
    filteredSoftwares() {
      let tempSoftware = [];
      for (let i = 0; i < this.softwares.length; i++) {
        if (this.softwares[i].softwareStreamId === this.softwareStream.id) {
          tempSoftware.push(this.softwares[i]);
        }
      }
      if (this.sortVersionOrder === "ascending") {
        tempSoftware = tempSoftware.sort(this.compareAsc);
      } else {
        tempSoftware = tempSoftware.sort(this.compareDesc);
      }
      return tempSoftware;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
