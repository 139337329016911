<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input placeholder="Find user" v-model="search" autofocus>
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredUsers.length }} of {{ users.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <!-- icon="el-icon-plus" -->
            <el-button type="primary" @click="openUserAdd">Add user</el-button>
          </el-col>
        </el-row>
        <el-table :data="filteredUsers" v-loading.body="usersLoading">
          <el-table-column prop="firstName" label="First Name" sortable>
          </el-table-column>
          <el-table-column prop="familyName" label="Family Name" sortable>
          </el-table-column>
          <el-table-column prop="email" label="Email"> </el-table-column>
          <el-table-column prop="roles" label="Role">
            <template v-slot="scope">
              <el-tag size="small" type="info" v-if="scope.row.roles">
                {{ getRole(scope.row) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="admin"
            label="Admin"
            :filters="[
              { text: 'Admin', value: true },
              { text: 'User', value: false },
            ]"
            :filter-method="filterAdmin"
            v-if="isAdmin"
          >
            <!-- <template v-slot="scope">
              <i v-show="scope.row.admin" class="el-icon-check"></i>
            </template> -->
          </el-table-column>
          <el-table-column
            prop="systemhouse.name"
            label="System house"
            :filters="systemhousesMap"
            :filter-method="filterBySystemhouse"
            :filtered-value="systemhouseFilter"
            v-if="isAdmin"
          >
            <template v-slot="scope">
              <el-tag size="small" v-if="scope.row.systemhouse">
                {{ scope.row.systemhouse.name }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="customer.name"
            label="Customer"
            :filters="customersMap"
            :filter-method="filterByCustomer"
            :filtered-value="customerFilter"
          >
            <template v-slot="scope">
              <el-tag size="small" v-if="scope.row.customer">
                {{ scope.row.customer.name }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="active"
            label="Active"
            :filters="[
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ]"
            :filter-method="filterActive"
            width="100"
          >
            <!-- <template v-slot="scope">
              <i class="el-icon-check" v-if="scope.row.active"></i>
            </template> -->
          </el-table-column>
          <el-table-column label="Actions" width="110">
            <template v-slot="scope">
              <!-- {{ currentUserId == scope.row.id || returnAuth.admin }}
              {{ returnAuth }} -->
              <actions-dropdown
                :hideRetreatFromShop="true"
                :entity="scope.row"
                :deleteItem="enableDisableDelete(scope)"
                :edit="true"
                @edit="openUserEdit"
                @delete="confirmDelete"
                v-if="scope.row.email !== 'ams@bitstream.de'"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <user-add ref="userAdd"></user-add>
    <user-edit ref="userEdit"></user-edit>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Permission from "../../mixins/Permission";
import DisplayRole from "../../mixins/DisplayRole";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import UserAdd from "./UserAdd.vue";
import UserEdit from "./UserEdit.vue";
import Auth from "../../auth";

export default {
  name: "users",
  mixins: [Permission, DisplayRole, FilterMixin],
  components: {
    ActionsDropdown,
    UserAdd,
    UserEdit,
  },
  created() {
    this.$store.dispatch("getUsers");
  },
  data() {
    var systemhouseFilter = this.$route.query.systemhouse
      ? [this.$route.query.systemhouse]
      : [];
    var customerFilter = this.$route.query.customer
      ? [this.$route.query.customer]
      : [];

    return {
      systemhouseFilter: systemhouseFilter,
      customerFilter: customerFilter,
    };
  },
  methods: {
    filterAdmin(value, row) {
      return row.admin === value;
    },
    filterBySystemhouse(value, row) {
      return row.systemhouse.id === value;
    },
    filterByCustomer(value, row) {
      return row.customer && row.customer.id === value;
    },
    filterActive(value, row) {
      return row.admin === value;
    },
    openUserAdd() {
      this.$refs.userAdd.open();
    },
    openUserEdit(user) {
      this.$refs.userEdit.open(user);
    },
    confirmDelete(user) {
      this.$confirm(
        "This will permanently delete the user. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, user), this.deleteError);
    },
    deleteSuccess(user) {
      this.$store.dispatch("deleteUser", user);
    },
    deleteError() {},
    enableDisableDelete(scope) {
      var result = false;

      // Allow delete if user is admin
      var permission = Auth.getPermission();
      if (permission == "admin") {
        result = true;
      } else if (permission == "systemhouse") {
        // TODO
      }
      if (scope.row.id === this.currentUserId) {
        result = false;
      }
      return result;
    },
  },
  computed: {
    ...mapState(["users", "usersLoading"]),
    ...mapGetters(["systemhousesMap", "customersMap"]),
    filteredUsers() {
      return this.filterEntities(this.users, this.search);
    },
    isSubset() {
      return this.users.length !== this.filteredUsers.length;
    },
    currentUserId() {
      return Auth.getAuth().id;
    },
  },
};
</script>
