<template>
  <el-dialog v-model="showDialog" title="Edit domainreg">
    <el-form ref="form" :model="domainreg" label-width="180px">
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="domainreg.name"
          placeholder="Domain Registration name"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submit(domainreg)">Edit</el-button>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "domainreg-edit",
  data() {
    return {
      showDialog: false,
      domainreg: {
        name: "",
      },
    };
  },
  methods: {
    open(domainreg) {
      this.domainreg = domainreg;
      this.showDialog = true;
    },
    submit(domainreg) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editDomainreg", _.cloneDeep(domainreg));
          this.showDialog = false;
        }
      });
    },
  },
};
</script>
