import impersonation from '../../impersonation'

function saName(_, value, callback) {
  if (!/^[a-z0-9]*$/.test(value)) {
    callback('Name can include only lowercase letters and numbers.')
    return
  }
  callback()
}

async function uniqueName(_, value) {
  let customerId = impersonation.getCustomer().id
  await window.axiosInstance.post(`/storage-accounts/${customerId}/validateName`, { Name: value })
}

export default {
  name: 'storage-account-validation',
  data() {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input storage account name', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' },
          { max: 24, message: 'Name should at most have 24 chars', trigger: 'blur' },
          { validator: saName, trigger: 'blur' },
          { validator: uniqueName, message: 'Name is already used', trigger: 'blur' }
        ],
        resourceGroupName: [
          { required: true, message: 'Please select a resource group', trigger: 'change' }
        ],
        type: [
          { required: true, message: 'Please select a type', trigger: 'blur' }
        ]
      }
    }
  }
}
