<template>
  <div>
    <el-dialog
      v-model="showDialog"
      title="Assign Device Options"
      width="85%"
      :close-on-click-modal="false"
    >
      <div class="list">
        <div class="block">
          <el-row class="header" type="flex">
            <el-col :span="11" class="search">
              <el-input
                autofocus
                placeholder="Find Available Device Options"
                v-model="search"
                prefix-icon="Search"
              >
                <template #suffix>
                  <el-icon v-if="isSubset" @click="clearSearch">
                    <CircleClose />
                  </el-icon>
                </template>
              </el-input>
            </el-col>
            <el-col :span="14">
              <div class="results" v-if="isSubset">
                showing {{ filteredDeviceOptions.length }} of
                {{ deviceOptionVersions.length }}
              </div>
            </el-col>
            <el-col :span="20" style="padding-top: 10px">
              <el-checkbox-group :max="1" v-model="checkedOSType">
                <el-checkbox label="Windows"></el-checkbox>
                <el-checkbox label="Ubuntu"></el-checkbox>
                <el-checkbox label="RedHat"></el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <el-table
            :data="filteredDeviceOptions"
            @selection-change="handleSelectionAvail"
            highlight-current-row
            ref="multipleTableOne"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="Available Device Options" width="500">
              <template v-slot="scope">{{ scope.row.name }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-row></el-row>
          <el-col>
            <el-row style="margin-bottom: 20px">
              <el-button
                :disabled="disableAssignValue"
                @click="onAssign"
                size="small"
                type="success"
              >
                <el-icon><CaretRight /></el-icon>
              </el-button>
            </el-row>
            <el-row>
              <el-button
                :disabled="disableUnassign"
                @click="OnUnassign"
                size="small"
                type="danger"
              >
                <el-icon>
                  <CaretLeft />
                </el-icon>
              </el-button>
            </el-row>
          </el-col>
        </div>
        <div class="block">
          <el-row></el-row>
          <el-table
            :data="AssignedOptions"
            @selection-change="handleSelectionUnassign"
            ref="multipleTableTwo"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="Assigned Device Options" width="300">
              <template v-slot="scope">{{ scope.row.name }} </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-button @click="submit()" type="primary">Save</el-button>
    </el-dialog>
    <deviceoption-parameter
      ref="deviceOptionParameter"
      @returnOldScript="addOldScript"
    ></deviceoption-parameter>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import * as _ from "lodash";
import DeviceoptionParameter from "./DeviceOptionParameter";
import { ElMessage } from "element-plus";

export default {
  name: "assign-device-options",
  mixins: [FilterMixin],
  components: { DeviceoptionParameter },
  data() {
    return {
      checkedOSType: [],
      showDialog: false,
      clientId: "",
      oldAvail: [],
      assignSelection: [],
      unassignSelection: [],
      assignedOptions: [],
      disableAssignValue: false,
    };
  },
  methods: {
    addOldScript(data) {
      if (!this.findScriptVersion(data.oldScript)) {
        this.$store.state.deviceOptionVersions.splice(
          data.index,
          0,
          data.oldScript
        );
      }
    },
    findScriptVersion(oldScript) {
      let foundScript = null;
      this.$store.state.deviceOptionVersions.find((el) => {
        if (el.id === oldScript.id) {
          foundScript = el;
        }
      });
      if (foundScript === null) {
        return false;
      } else {
        return true;
      }
    },
    open(clientId) {
      this.clientId = clientId;
      this.showDialog = true;
    },
    submit() {
      this.assignedOptions = _.cloneDeep(
        this.$store.state.assignedDeviceOptions
      );
      if (this.assignedOptions.length > 0) {
        this.assignedOptions[0].clientId = this.clientId;
        for (let index in this.assignedOptions) {
          this.assignedOptions[index].order = index;
        }
        this.$store.dispatch("addDeviceOptionAssign", this.assignedOptions);
      } else {
        this.$store.dispatch("deleteDeviceOptionAssign", this.clientId);
      }

      // for (let index in this.unassignedDeviceOptions) {
      //   this.unassignedDeviceOptions[index].clientId = this.clientId
      //   this.$store.dispatch('deleteDeviceOptionAssign', this.unassignedDeviceOptions[index])
      // }
      this.showDialog = false;
    },
    handleSelectionAvail(val) {
      if (val.length > 0) {
        if (val.length === 1) {
          this.assignSelection = val;
          this.disableAssignValue = false;
        } else {
          this.disableAssignValue = true;
          ElMessage.warning({
            message:
              "Multiple selection is currently disabled! Please select just one option.",
            showClose: true,
          });
        }
      }
    },
    handleSelectionUnassign(val) {
      this.unassignSelection = val;
    },
    openParameterDefaults(script, index) {
      // TODO: add option id
      console.log("assignSelection: ", this.assignSelection[0]);
      this.$refs.deviceOptionParameter.openWithModel(
        this.clientId,
        script,
        index,
        false,
        this.assignSelection[0]
      );
    },
    onAssign() {
      let index = null;
      if (this.assignSelection.length >= 1) {
        for (
          let i = 0;
          i < this.$store.state.deviceOptionVersions.length;
          i++
        ) {
          if (
            this.$store.state.deviceOptionVersions[i].id ===
            this.assignSelection[0].id
          ) {
            index = i;
            break;
          }
        }
        this.$store.state.deviceOptionVersions = _.pull(
          this.$store.state.deviceOptionVersions,
          this.assignSelection[0]
        );
        // this.$store.state.assignedDeviceOptions = _.union(this.$store.state.assignedDeviceOptions,
        //   this.assignSelection)
        this.openParameterDefaults(this.assignSelection[0], index);
      }
    },
    OnUnassign() {
      if (this.unassignSelection.length >= 1) {
        this.$store.state.deviceOptionVersions = _.union(
          this.$store.state.deviceOptionVersions,
          this.unassignSelection
        );
        this.$store.state.assignedDeviceOptions = _.difference(
          this.$store.state.assignedDeviceOptions,
          this.unassignSelection
        );
        this.$store.state.unassignedDeviceOptions = _.union(
          this.$store.state.unassignedDeviceOptions,
          this.unassignSelection
        );
      }
    },
  },
  computed: {
    ...mapState([
      "deviceOptionVersions",
      "deviceOptionVersionsLoading",
      "assignedDeviceOptions",
      "deviceOptionsAssignLoading",
    ]),
    filteredDeviceOptions() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.oldAvail = this.$store.state.deviceOptionVersions;
      if (this.$store.state.deviceOptionVersions.length) {
        for (let index in this.$store.state.assignedDeviceOptions) {
          let arrayToRemove = this.$store.state.assignedDeviceOptions[index];
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$store.state.deviceOptionVersions =
            this.$store.state.deviceOptionVersions.filter(
              (e) => e.id !== arrayToRemove.id
            );
        }
      }
      let tempArray = [];
      if (this.checkedOSType.length !== 0) {
        for (
          let i = 0;
          i < this.$store.state.deviceOptionVersions.length;
          i++
        ) {
          let temp = this.$store.state.deviceOptionVersions[i];
          if (temp.osType === this.checkedOSType[0]) {
            tempArray.push(temp);
          }
        }
      }
      if (tempArray.length === 0 && this.checkedOSType.length === 0) {
        return this.filterEntities(
          this.$store.state.deviceOptionVersions,
          this.search
        );
      } else {
        return this.filterEntities(tempArray, this.search);
      }
    },
    isSubset() {
      return (
        this.deviceOptionVersions.length !== this.filteredDeviceOptions.length
      );
    },
    AssignedOptions() {
      return this.$store.state.assignedDeviceOptions;
    },
    disableAssign() {
      if (this.assignSelection.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    disableUnassign() {
      if (this.unassignSelection.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.list {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
