export function getShopItems () {
  return window.axiosInstance.get('/shop')
}
export function addShopItem (shopitems) {
  return window.axiosInstance.post('/shop', shopitems)
}

export function editShopItem (item) {
  return window.axiosInstance.put(`/shop/${item.id}`, item)
}

export function deleteShopItem (item) {
  return window.axiosInstance.delete(`/shop/${item.id}`)
}

export function getOSImages () {
  return window.axiosInstance.get('/shop/getOSImages')
}

export function getDrivers (customerId) {
  return window.axiosInstance.get(`shop/getDrivers/${customerId}`)
}
