<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Assign Vendor Model">
    <el-form
      ref="form"
      :model="vendorModel"
      :rules="rules">
      <el-form-item prop="vendorModelId">
        <el-select
          v-model="vendorModel.vendorModelId"
          placeholder="Please select the vendor model"
          @change="addVendorModel">
          <el-option
            v-for="vendorModel in vendorModels"
            :key="vendorModel.id"
            :label="vendorModel.name"
            :value="vendorModel.id">
          </el-option>
          <el-option
            key="new-vendor"
            value="new-vendor"
            label="+ Add Vendor Model">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="submit">
        <el-button type="primary" @click="submit">Assign</el-button>
      </div>
    </el-form>
    <addVendorModel ref="addVendorModel"></addVendorModel>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import AddVendorModel from "../../vendor_management/dialogs/AddVendorModel.vue";

export default {
  name: "assignVendor",
  components: {
    AddVendorModel,
  },
  created() {
    this.$store.dispatch("getVendorModels");
  },
  data() {
    let validateVendorModelId = (rule, value, callback) => {
      if (this.vendorModel.vendorModelId.length === 0) {
        callback(new Error("Please select the vendor model to assign"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      vendorModel: {
        assetId: "",
        vendorModelId: "",
      },
      rules: {
        vendorModelId: [
          { validator: validateVendorModelId, trigger: "change" },
        ],
      },
    };
  },
  methods: {
    openDialog(assetModelId) {
      this.vendorModel.assetId = assetModelId;
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("assignVendor", _.cloneDeep(this.vendorModel));
          this.$refs.form.resetFields();
          this.$emit("refresh");
          this.showDialog = false;
        }
      });
    },
    addVendorModel() {
      if (this.vendorModel.vendorModelId === "new-vendor") {
        this.$refs.addVendorModel.openDialog();
        this.vendorModel.assetModelId = "";
      }
    },
  },
  computed: {
    ...mapState(["vendorModels"]),
  },
};
</script>

<style lang="css" scoped>
</style>
