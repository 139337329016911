// import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import { serverTypes, storageTypes, tasks } from './data'
import Auth from '../auth'
import impersonation from '../impersonation'
import FileSaver from 'file-saver'
import { addSystemhouse, deleteSystemhouse, editSystemhouse, getSystemhouses } from '../services/systemhouses'
import { addVariable, editDefault, getVariables, getDefaults, updateGeneralData } from '../services/customers'
import { addScript, addScriptVersion, editScript, getScripts, getLatestBitStreamScriptVersion } from '../services/scripts'
import { addUser, deleteUser, editUser, getUsers } from '../services/users'
import { addSoftware, deleteSoftware, editSoftware, getSoftwares, getSoftwareIcon, getSoftwareIconForShop } from '../services/softwares'
import { getLocations, addLocation, getCustomersLocations, deleteLocation, updateLocation } from '../services/locations'
import { getSubscriptions } from '../services/subscriptions'
import { addDomain, getDomains } from '../services/domains'
import { getImages } from '../services/images'
import { addShopItem, deleteShopItem, editShopItem, getShopItems, getOSImages } from '../services/shops'
import { getCategories } from '../services/categories'
import { getBuyItemImage, getBuyItems, getSoftwareShopItems, getShopItemsCategories } from '../services/buy'
import {
  addAssetLabels,
  getAssetLabels,
  editAsset,
  assignAsset,
  deleteAssetModel,
  unassignAsset,
  exportAssetSelectionToCSV,
  addAssetType,
  addAssetClass,
  getAssetTypes,
  getAssetClasses,
  updateAssetModel,
  assignPerson,
  assignLocation,
  assignVendor
} from '../services/assetManagement.js'
// import {addCustomInventory, getCustomInventory} from '../services/customWMI'
import {
  addClient,
  addClientSoftware,
  deleteClientSoftware,
  editClient,
  getDeviceInventory,
  getClients,
  getClientSoftware,
  addCustomParameter,
  getDeviceParameters,
  editDeviceParameter,
  getDeviceParameterWithDefaultValues,
  exportInventoryAsCSV,
  exportInventoriesCSV,
  deleteClient
} from '../services/clients'
import { downloadFirstAgent, getWinPESASUrl, downloadSmartDeploy } from '../services/downloadwinpe'
import { addOrder, orderSoftware, orderImage, orderSelectedSoftware } from '../services/order'
import { getDatasheet } from '../services/datasheet'
import { defaultModel } from '../components/datasheet/DatasheetModel'
import { addSoftwareAssign, getSoftwareAssign, deleteSoftwareAssign } from '../services/softwareassign'
import { addconfigWizard, downloadCSVtemplate } from '../services/configwizard'
import { defaultDomainModel } from '../components/configwizard/ConfigwizardModel'
import { getDeviceOptions, addDeviceOption, editDeviceOption, addDeviceOptionVersion, getDeviceOptionVersions, addDeviceOptionAssign, deleteDeviceOptionAssign, getDeviceOptionAssign, downloadDeviceOption } from '../services/deviceoptions'
import { getDeviceProperties, getDevicePropCategory, addDeviceProperty, editDeviceProperty } from '../services/deviceproperties'
import { addPerson, getCustomersUnemployedPersons, addExpert, deletePerson, editPerson, getCustomersPersons } from '../services/person'
import { addCompany, getCustomersCompanies, updateMainCompany, deleteCompany } from '../services/company'
import { getCustomersSmbStorages, createSmbStorage, updateSmbStorage, deleteSmbStorage } from '../services/smbStorages'
import { getCountries } from '../services/countries'
import { getCEPs, deleteCEP, updateCEP } from '../services/azureClouds'
import { addSEP, getCustomersSEPs, deleteSEP } from '../services/storageEntryPoints'
import { getCustomersAzureBlobs, createAzureBlob } from '../services/azureBlobs'
import { getCustomersStorageAccounts, createStorageAccount } from '../services/storageAccounts'
import { getCustomersResourceGroups, createResourceGroup } from '../services/resourceGroups'
import { createVM, getVMs } from '../services/virtualMachines'
import { createWorkflow, getWorkflows, updateWorkflow } from '../services/workflows'
import { createCertification, getCertifications, updateCertification } from '../services/certifications'
import { createDomainRegistration, getDomainRegistrations, updateDomainRegistration } from '../services/domainRegistrations'
import { addHardwareModel, getHardwareModels, editHardwareModel, deleteHardwareModel } from '../services/hardwareModels'
import { addDriver, editDriver, getDrivers, updateDriver, deleteDriver } from '../services/drivers'
import { removeReleaseMgmtFile } from '../services/releaseMgmtFiles'
import { addOSModel, getOSModels, deleteOSModel, editOSModel } from '../services/OSModels'
import { getVendorModels, addVendorModel, updateVendorModel } from '../services/vendorModels'
import { getRules, addRule, updateRule, deleteRule } from '../services/rules'
import { addSoftwareStream, getSoftwareStreams, editSoftwareStream, setSoftwaresToStream, deleteSoftwareStream } from '../services/softwareStreams'
import {
  getCustomersSoftware,
  getCustomersStreams,
  editCustomerSoftwareStream,
  deleteCustomerSoftware,
  deleteCustomerStream,
  editCustomerSoftware,
  getNewSoftware,
  checkRevisionNumber
} from '../services/customerSoftwares'
import { addReleasePlan, getCustomersReleasePlans, editReleasePlan, deleteReleasePlan } from '../services/releasePlans'
import { getSWImages, addSWImage, editSWImage, deleteSWImage } from '../services/swImages'
import {
  getCustomersOSImages,
  deleteCustomerOSImage,
  getCustomerImageStreams,
  deleteCustomerImageStream,
  getRevisionImages,
  getNewImages
} from '../services/customerImages'
import { getCustomerHardwareModels } from '../services/customerHardwareModels'
import { getImageStreams, addImageStream, getImageIcon, deleteImageStream, editImageStream } from '../services/imageStreams'
import { addAdminOption, getAdminOptions, editAdminOption } from '../services/adminOptions'
import { getCustomerDrivers } from '../services/customerDrivers'
import base from './modules/base'
import customer from './modules/customer'
import { debug, success, errorMessage } from './modules/util'

// Vue.use(Vuex)

function getCustomerId() {
  return impersonation.getCustomer().id
}

const defaultState = {
  tasks: tasks,
  scriptsLoading: false,
  scripts: [],
  softwares: [],
  softwaresLoading: false,
  shopitems: [],
  shopLoading: false,
  buy: [],
  buyLoading: false,
  buyImages: [],
  itemimages: [],
  itemImageLoading: false,
  buyDetails: false,
  workflows: [],
  domainregs: [],
  certs: [],
  users: [],
  usersLoading: false,
  systemhouses: [],
  systemhousesLoading: false,
  locations: [],
  subscriptions: [],
  subscriptionsLoading: false,
  domains: [],
  domainsLoading: false,
  storageTypes: storageTypes,
  images: [],
  serverTypes: serverTypes,
  clients: [],
  clientLoading: false,
  clientSoftwares: [],
  clientSoftwareLoading: false,
  variables: [],
  variableLoading: false,
  defaultLoading: false,
  winpeLoading: false,
  orders: [],
  orderLoading: false,
  datasheet: [],
  clientInventory: [],
  datasheetLoading: false,
  softwareAssignLoading: false,
  softwareIcon: [],
  addconfigWizard: defaultDomainModel,
  deviceOptions: [],
  deviceOptionLoading: false,
  assignedDeviceOptions: [],
  unassignedDeviceOptions: [],
  deviceOptionsAssignLoading: false,
  deviceOptionVersions: [],
  deviceOptionVersionsLoading: false,
  deviceParameters: [],
  categories: [],
  defaults: [],
  categoriesLoading: false,
  selectedCategories: [],
  devicePropertiesLoading: false,
  deviceProperties: [],
  devicePropCategory: [],
  persons: [],
  customersLocations: [],
  customersCompanies: [],
  customerUnemployedPersons: [],
  smbStorages: [],
  countries: getCountries(),
  azureBlobs: [],
  storageAccounts: [],
  resourceGroups: [],
  errorResGrpName: false,
  virtualMachines: [],
  createVMLoading: false,
  customInventory: [],
  drivers: [],
  osModels: [],
  biosModels: [],
  hardwareModels: [],
  assetLoading: false,
  assetLabels: [],
  assetClasses: [],
  assetTypes: [],
  vendorModels: [],
  rules: [],
  rulesLoading: false,
  CEPs: [],
  SEPs: [],
  sepsLoading: false,
  softwareStreams: [],
  softwareShopItems: [],
  shopItemCategories: [],
  customersSoftware: [],
  loadingCustomerSoftware: false,
  customersStreams: [],
  loadingCustomersStreams: false,
  releasePlans: [],
  swImages: [],
  buyOSImages: [],
  customersOSImages: [],
  loadingCustomersImage: false,
  customerHardwareModels: [],
  refreshBasesID: '',
  newSoftwarePackages: [],
  loadingNewSoftwarePackages: false,
  revisionSoftwarePackages: [],
  loadingRevisionSoftwarePackages: false,
  imageStreams: [],
  customerImageStreams: [],
  adminOptions: [],
  customerDrivers: [],
  revisionImages: [],
  loadingRevisionImages: false,
  loadingNewImages: false,
  newImages: [],
  loginType: 'TEST LOGIN TYPE'
}

const resetInventory = {
  subscriptions: [],
  bases: [],
  domains: []
}

export default new Vuex.Store({
  state: _.cloneDeep(defaultState),
  modules: { base, customer },
  getters: {
    systemhousesMap(state) {
      return state.systemhouses.map(systemhouse => {
        return { text: systemhouse.name, value: systemhouse.id }
      })
    },
    // customersMap (state) {
    //   return state.customers.map(customer => {
    //     return {text: customer.name, value: customer.id}
    //   })
    // },
    softwaresArray(state) {
      return state.softwares.map(software => {
        return { id: software.id, icon: software.icon }
      })
    }
  },
  mutations: {
    initState(state) {
      _.extend(state, defaultState)
    },
    initInventory(state) {
      _.extend(state, resetInventory)
    },

    // Admin Options
    addAdminOption(state, adminOption) {
      state.adminOptions.push(adminOption)
    },
    setAdminOptions(state, adminOptions) {
      state.adminOptions = adminOptions
    },
    editAdminOption(state, adminOption) {
      for (let i = 0; i < state.adminOptions.length; i++) {
        if (state.adminOptions[i].id === adminOption.id) {
          state.adminOptions.splice(i, 1, adminOption)
          break
        }
      }
    },

    // Customer Drivers
    setCustomerDrivers(state, customerDrivers) {
      state.customerDrivers = customerDrivers
    },

    // ImageStreams
    setImageStreams(state, imageStreams) {
      state.imageStreams = imageStreams
    },
    addImageStream(state, imageStream) {
      state.imageStreams.push(imageStream)
    },
    editImageStream(state, imageStream) {
      for (let i = 0; i < state.imageStreams.length; i++) {
        if (state.imageStreams[i].id === imageStream.id) {
          state.imageStreams.splice(i, 1, imageStream)
          break
        }
      }
    },
    deleteImageStream(state, streamId) {
      for (let i = 0; i < state.imageStreams.length; i++) {
        if (state.imageStreams[i].id === streamId) {
          state.imageStreams.splice(i, 1)
          break
        }
      }
    },

    // SW images
    setSWImages(state, swImages) {
      state.swImages = swImages
    },
    addSWImage(state, swImage) {
      state.swImages.push(swImage)
    },
    editSWImage(state, swImage) {
      for (let i = 0; i < state.swImages.length; i++) {
        if (state.swImages[i].id === swImage.id) {
          state.swImages.splice(i, 1, swImage)
          break
        }
      }
    },
    deleteSWImage(state, swImage) {
      for (let i = 0; i < state.swImages.length; i++) {
        if (state.swImages[i].id === swImage.id) {
          state.swImages.splice(i, 1)
          break
        }
      }
    },

    // Customer Hardware Models
    setCustomerHardwareModels(state, hardwareModels) {
      state.customerHardwareModels = hardwareModels
    },

    // Buy OS images
    setBuyOSImages(state, osImages) {
      state.buyOSImages = osImages
    },

    // Customer Images
    setCustomersOSImages(state, customerImages) {
      state.customersOSImages = customerImages
    },
    addCustomersOSImage(state, customerImage) {
      state.customersOSImages.push(customerImage)
    },
    setCustomersImagesLoading(state, value) {
      state.loadingCustomersImage = value
    },
    deleteCustomerOSImage(state, customerImage) {
      console.log({ customerImage })
      console.log('STORES CUSTOMER OS IMAGES: ', state.customersOSImages)
      for (let i = 0; i < state.customersOSImages.length; i++) {
        if (state.customersOSImages[i].id === customerImage.id) {
          state.customersOSImages.splice(i, 1)
          break
        }
      }
    },
    setCustomerImageStreams(state, customerImageStreams) {
      state.customerImageStreams = customerImageStreams
    },
    deleteCustomerImageStream(state, streamId) {
      for (let i = 0; i < state.customerImageStreams.length; i++) {
        if (state.customerImageStreams[i].id === streamId) {
          state.customerImageStreams.splice(i, 1)
          break
        }
      }
    },
    setNewImageLoading(state, value) {
      state.loadingNewImages = value
    },
    setNewImages(state, newImages) {
      state.newImages = newImages
    },
    removeNewImage(state, id) {
      let ni = state.newImages
      console.log({ id })
      console.log({ ni })
      for (let i = 0; i < state.newImages.length; i++) {
        if (state.newImages[i].id === id) {
          state.newImages.splice(i, 1)
          break
        }
      }
    },
    setRevisionImage(state, revisionImage) {
      state.revisionImages = revisionImage
    },
    setLoadingRevisionImage(state, value) {
      state.loadingRevisionImages = value
    },
    removeRevisionImage(state, id) {
      for (let i = 0; i < state.revisionImages.length; i++) {
        if (state.revisionImages[i].id === id) {
          state.revisionImages.splice(i, 1)
          break
        }
      }
    },

    // Customers Software
    setRevisionSoftware(state, revisionSoftware) {
      state.revisionSoftwarePackages = revisionSoftware
    },
    setLoadingRevisionSoftware(state, value) {
      state.loadingRevisionSoftwarePackages = value
    },
    removeRevisionSoftware(state, id) {
      for (let i = 0; i < state.revisionSoftwarePackages.length; i++) {
        if (state.revisionSoftwarePackages[i].id === id) {
          state.revisionSoftwarePackages.splice(i, 1)
          break
        }
      }
    },
    removeNewSoftware(state, id) {
      for (let i = 0; i < state.newSoftwarePackages.length; i++) {
        if (state.newSoftwarePackages[i].id === id) {
          state.newSoftwarePackages.splice(i, 1)
          break
        }
      }
    },
    setNewSoftware(state, newSoftware) {
      state.newSoftwarePackages = newSoftware
    },
    setNewSoftwareLoading(state, value) {
      state.loadingNewSoftwarePackages = value
    },
    setCustomersSoftware(state, customersSoftware) {
      state.customersSoftware = customersSoftware
    },
    editCustomerSoftware(state, customerSoftware) {
      for (let i = 0; i < state.customersSoftware.length; i++) {
        if (state.customersSoftware[i].id === customerSoftware.id) {
          state.customersSoftware.splice(i, 1, customerSoftware)
          break
        }
      }
    },
    setCustomersStreams(state, customersStreams) {
      state.customersStreams = customersStreams
    },
    addCustomersSoftware(state, customerSoftware) {
      state.customersSoftware.push(customerSoftware)
    },
    setCustomersSoftwareLoading(state, value) {
      state.loadingCustomerSoftware = value
    },
    setCustomersStreamsLoading(state, value) {
      state.loadingCustomersStreams = value
    },
    editCustomerSoftwareStream(state, stream) {
      for (let i = 0; i < state.customersStreams.length; i++) {
        if (state.customersStreams[i].id === stream.id) {
          state.customersStreams.splice(i, 1, stream)
          break
        }
      }
    },
    deleteCustomerSoftware(state, customerSoftwareStream) {
      for (let i = 0; i < state.customersStreams.length; i++) {
        if (state.customersStreams[i].id === customerSoftwareStream.id) {
          if (customerSoftwareStream.deletedDate === null) {
            state.customersStreams.splice(i, 1, customerSoftwareStream)
          } else {
            state.customersStreams.splice(i, 1)
          }
          break
        }
      }
    },
    deleteCustomerStream(state, customerStream) {
      for (let i = 0; i < state.customersStreams.length; i++) {
        if (state.customersStreams[i].id === customerStream.id) {
          state.customersStreams.splice(i, 1)
          break
        }
      }
    },

    /* Release Plans */
    addReleasePlan(state, releasePlan) {
      state.releasePlans.push(releasePlan)
    },
    setReleasePlans(state, releasePlans) {
      state.releasePlans = releasePlans
    },
    editReleasePlan(state, releasePlan) {
      for (let i = 0; i < state.releasePlans.length; i++) {
        if (state.releasePlans[i].id === releasePlan.id) {
          state.releasePlans.splice(i, 1, releasePlan)
          break
        }
      }
    },
    deleteReleasePlan(state, releasePlan) {
      for (let i = 0; i < state.releasePlans.length; i++) {
        if (state.releasePlans[i].id === releasePlan.id) {
          state.releasePlans.splice(i, 1)
          break
        }
      }
    },

    /* Countries */
    setCountries(state, countries) {
      state.countries = countries
    },
    /* assetManagement */
    setAssetLoading(state, loading) {
      state.assetLoading = loading
    },
    addAssetLabels(state, assetLabel) {
      state.assetLabels.push(assetLabel)
      success()
    },
    setAssetLabels(state, assetLabels) {
      state.assetLabels = assetLabels
    },
    editAsset(state, item) {
      const id = _.findIndex(state.assetLabels, { id: item.id })
      _.extend(state.assetLabels[id], item)
    },
    assignAsset(state, assetModel) {
      for (let i = 0; i < state.assetLabels.length; i++) {
        if (state.assetLabels[i].id === assetModel.id) {
          state.assetLabels.splice(i, 1, assetModel)
          success()
          return
        }
      }
    },
    deleteAssetModel(state, assetModelId) {
      for (let i = 0; i < state.assetLabels.length; i++) {
        if (state.assetLabels[i].id === assetModelId) {
          state.assetLabels.splice(i, 1)
        }
      }
    },

    // Vendor Models
    addVendorModel(state, vendorModel) {
      state.vendorModels.push(vendorModel)
    },
    setVendorModels(state, vendorModels) {
      state.vendorModels = vendorModels
    },
    updateVendorModel(state, vendorModel) {
      for (let i = 0; i < state.vendorModels; i++) {
        if (state.vendorModels[i].id === vendorModel.id) {
          state.vendorModels.splice(i, 1, vendorModel)
          return
        }
      }
    },

    // Rules
    setRulesLoading(state, ifLoading) {
      state.rulesLoading = ifLoading
    },
    setRules(state, rules) {
      state.rules = rules
    },
    addRule(state, rule) {
      state.rules.push(rule)
    },
    updateRule(state, rule) {
      for (let i = 0; i < state.rules.length; i++) {
        if (state.rules[i].id === rule.id) {
          state.rules.splice(i, 1, rule)
          return
        }
      }
    },
    deleteRule(state, ruleId) {
      for (let i = 0; i < state.rules.length; i++) {
        if (state.rules[i].id === ruleId) {
          state.rules.splice(i, 1)
          return
        }
      }
    },

    /* ShopItems */
    setShopLoading(state, loading) {
      state.shopLoading = loading
    },
    addShopItems(state, items) {
      state.shopitems = items
    },
    addShopItem(state, item) {
      state.shopitems.push(item)
    },
    editShopItem(state, item) {
      const id = _.findIndex(state.shopitems, { id: item.id })
      _.extend(state.shopitems[id], item)
    },
    deleteShopItem(state, item) {
      const id = _.findIndex(state.shopitems, { id: item.id })
      state.shopitems.splice(id, 1)
    },
    /* Software */
    setSoftwaresLoading(state, loading) {
      state.softwaresLoading = loading
    },
    addSoftwares(state, softwares) {
      state.softwares = softwares
    },
    addSoftware(state, software) {
      state.softwares.push(software)
    },
    editSoftware(state, software) {
      const id = _.findIndex(state.softwares, { id: software.id })
      _.extend(state.softwares[id], software)
      success()
    },
    deleteSoftware(state, software) {
      const id = _.findIndex(state.softwares, { id: software.id })
      state.softwares.splice(id, 1)
    },
    addSoftwareIcon(state, softwareIcon) {
      state.softwareIcon.push(softwareIcon)
    },
    updateSoftwares(state, softwares) {
      softwares.forEach((item) => {
        for (let i = 0; i < state.softwares.length; i++) {
          if (state.softwares[i].id === item.id) {
            state.softwares.splice(i, 1, item)
            break
          }
        }
      })
    },

    /* Workflows */
    addWorkflow(state, workflow) {
      state.workflows.push(workflow)
    },
    editWorkflow(state, workflow) {
      for (let i = 0; i < state.workflows.length; i++) {
        if (state.workflows[i].id === workflow.id) {
          state.workflows.splice(i, 1, workflow)
          return
        }
      }
    },
    deleteWorkflow(state, workflow) {
      const id = _.findIndex(state.workflows, { id: workflow.id })
      state.workflows.splice(id, 1)
    },
    setWorkflows(state, workflows) {
      state.workflows = workflows
    },

    /* Tasks */
    addTask(state, task) {
      state.tasks.push(task)
    },
    editTask(state, task) {
      const id = _.findIndex(state.tasks, { id: task.id })
      _.extend(state.tasks[id], task)
    },
    deleteTask(state, task) {
      const id = _.findIndex(state.tasks, { id: task.id })
      state.tasks.splice(id, 1)
    },

    /* Domain Registrations */
    addDomainreg(state, domainreg) {
      state.domainregs.push(domainreg)
    },
    editDomainreg(state, domainreg) {
      for (let i = 0; i < state.domainregs.length; i++) {
        if (state.domainregs[i].id === domainreg.id) {
          state.domainregs.splice(i, 1, domainreg)
          return
        }
      }
    },
    deleteDomainreg(state, domainreg) {
      const id = _.findIndex(state.domainregs, { id: domainreg.id })
      state.domainregs.splice(id, 1)
    },
    setDomainRegs(state, domainregs) {
      state.domainregs = domainregs
    },

    /* Certifications */
    addCert(state, cert) {
      state.certs.push(cert)
    },
    editCert(state, cert) {
      for (let i = 0; state.certs.length; i++) {
        if (state.certs[i].id === cert.id) {
          state.certs.splice(i, 1, cert)
          return
        }
      }
    },
    deleteCert(state, cert) {
      const id = _.findIndex(state.certs, { id: cert.id })
      state.certs.splice(id, 1)
    },
    setCerts(state, certs) {
      state.certs = certs
    },

    /* Scripts */
    setScriptsLoading(state, loading) {
      state.scriptsLoading = loading
    },
    addScripts(state, scripts) {
      state.scripts = scripts
    },
    addScript(state, script) {
      state.scripts.push(script)
    },
    editScript(state, script) {
      const id = _.findIndex(state.scripts, { id: script.id })
      _.extend(state.scripts[id], script)
    },

    /* Users */
    setUsersLoading(state, loading) {
      state.usersLoading = loading
    },
    addUsers(state, users) {
      state.users = users
    },
    addUser(state, user) {
      state.users.push(user)
    },
    editUser(state, user) {
      const id = _.findIndex(state.users, { id: user.id })
      _.extend(state.users[id], user)
    },
    deleteUser(state, user) {
      const id = _.findIndex(state.users, { id: user.id })
      state.users.splice(id, 1)
    },

    /* Systemhouses */
    setSystemhousesLoading(state, loading) {
      state.systemhousesLoading = loading
    },
    addSystemhouses(state, systemhouses) {
      state.systemhouses = systemhouses
    },
    addSystemhouse(state, systemhouse) {
      state.systemhouses.push(systemhouse)
    },
    editSystemhouse(state, systemhouse) {
      const id = _.findIndex(state.systemhouses, { id: systemhouse.id })
      _.extend(state.systemhouses[id], systemhouse)
    },
    deleteSystemhouse(state, systemhouse) {
      const id = _.findIndex(state.systemhouses, { id: systemhouse.id })
      state.systemhouses.splice(id, 1)
    },

    /* Customers */
    // setCustomersLoading (state, loading) {
    //   state.customersLoading = loading
    // },
    // addCustomers (state, customers) {
    //   state.customers = customers
    // },
    // addCustomer (state, customer) {
    //   state.customers.push(customer)
    // },
    // editCustomer (state, customer) {
    //   const id = _.findIndex(state.customers, {id: customer.id})
    //   _.extend(state.customers[id], customer)
    // },
    // deleteCustomer (state, customer) {
    //   const id = _.findIndex(state.customers, {id: customer.id})
    //   state.customers.splice(id, 1)
    // },
    // setCustomerDetails (state, customer) {
    //   state.customerDetails = customer
    // },
    // addCorporateParameter (state, parameter) {
    //   state.customerDetails.parameters.push(parameter)
    // },

    /* SmbStorages */
    setCustomersSmbStorages(state, smbStorages) {
      state.smbStorages = smbStorages
    },
    addSmbStorage(state, smbStorage) {
      state.smbStorages.push(smbStorage)
      success()
    },

    updateSmbStorage(state, smbStorage) {
      const id = smbStorage.id
      for (let i = 0; i < state.smbStorages.length; i++) {
        if (state.smbStorages[i].id === id) {
          state.smbStorages.splice(i, 1, smbStorage)
          break
        }
      }
      success()
    },

    deleteSmbStorage(state, smbStorage) {
      const id = smbStorage.id
      for (let i = 0; i < state.smbStorages.length; i++) {
        if (state.smbStorages[i].id === id) {
          state.smbStorages.splice(i, 1)
          break
        }
      }
      success()
    },

    /* Azure Blobs */
    setAzureBlobs(state, azureBlobs) {
      state.azureBlobs = azureBlobs
    },

    addAzureBlob(state, azureBlob) {
      state.azureBlobs.push(azureBlob)
    },

    /* Storage Accounts */
    setStorageAccounts(state, storageAccounts) {
      state.storageAccounts = storageAccounts
    },
    addStorageAccount(state, storageAccount) {
      state.storageAccounts.push(storageAccount)
    },

    /* Resource Groups */
    setResourceGroups(state, resourceGroups) {
      state.resourceGroups = resourceGroups
    },
    addResourceGroup(state, resourceGroup) {
      state.resourceGroups.push(resourceGroup)
      success()
    },
    setErrorResourceGroup(state, errorResult) {
      state.errorResGrpName = errorResult
    },

    /* Variables */
    setVariablesLoading(state, loading) {
      state.variableLoading = loading
    },
    addVariables(state, variables) {
      state.variables = variables
    },
    addVariable(state, variable) {
      state.variables.push(variable)
    },
    editVarDefault(state, variable) {
      const id = _.findIndex(state.variables, { id: variable.id })
      _.extend(state.variables[id], variable)
    },

    /* Defaults */
    setDefaultsLoading(state, loading) {
      state.defaultLoading = loading
    },
    addDefaults(state, defaults) {
      state.defaults.push(defaults)
    },
    editDefault(state, defaultData) {
      const id = _.findIndex(state.defaults, { id: defaultData.id })
      _.extend(state.defaults[id], defaultData)
    },

    /* Locations */
    addLocation(state, location) {
      success()
      state.locations.push(location)
      state.customersLocations.push(location)
    },

    setCustomersLocations(state, locations) {
      state.customersLocations = locations
    },

    setLocations(state, locations) {
      state.locations = locations
    },

    deleteLocation(state, location) {
      for (let i = 0; i < state.customersLocations.length; i++) {
        if (state.customersLocations[i].id === location.id) {
          state.customersLocations.splice(i, 1)
        }
      }
    },

    updateLocation(state, location) {
      for (let i = 0; i < state.customersLocations.length; i++) {
        if (state.customersLocations[i].id === location.id) {
          state.customersLocations.splice(i, 1, location)
          break
        }
      }
    },

    /* Images */
    addImages(state, images) {
      state.images = images
    },

    /* Domains */
    setDomainsLoading(state, loading) {
      state.domainsLoading = loading
    },
    addDomains(state, domains) {
      state.domains = domains
    },
    addDomain(state, domain) {
      state.domains.push(domain)
    },

    /* Subscriptions */
    setSubscriptionsLoading(state, loading) {
      state.subscriptionsLoading = loading
    },
    addSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions
    },

    /* Clients */
    refresh() {

    },
    setClientLoading(state, loading) {
      state.clientLoading = loading
    },
    addClients(state, clients) {
      state.clients = clients
    },
    addClient(state, client) {
      state.clients.push(client)
    },
    editClient(state, client) {
      const id = _.findIndex(state.clients, { id: client.id })
      _.extend(state.clients[id], client)
    },
    setClientSoftwareLoading(state, loading) {
      state.clientSoftwareLoading = loading
    },
    addClientSoftwares(state, clientsoftwares) {
      state.clientSoftwares = clientsoftwares
    },
    addClientSoftware(state, clientsoftware) {
      state.clientSoftwares.push(clientsoftware)
    },
    addDeviceParameter(state, parameter) {
      state.deviceParameters.push(parameter)
    },
    setDeviceParameters(state, parameters) {
      state.deviceParameters = parameters
    },
    editDeviceParameter(state, parameter) {
      for (let i = 0; i < state.deviceParameters.length; i++) {
        if (state.deviceParameters[i].id === parameter.id) {
          state.deviceParameters.splice(i, 1, parameter)
        }
      }
    },
    deleteClient(state, client) {
      for (let i = 0; i < state.clients.length; i++) {
        if (state.clients[i].id === client.id) {
          state.clients.splice(i, 1)
          return
        }
      }
    },

    /* CUSTOM INVENTORY */
    setCustomInventory(state, custom) {
      state.customInventory = custom
    },
    addCustomInventory(state, custom) {
      state.customInventory.push(custom)
    },
    /* buy */
    setSoftwareShopItems(state, shopItems) {
      state.softwareShopItems = shopItems
    },
    setShopItemCategories(state, shopItemCategories) {
      state.shopItemCategories = shopItemCategories
    },
    addBuy(state, buy) {
      state.buy = buy
    },
    addBuyImages(state, buyImages) {
      state.buyImages = buyImages
    },
    setBuyLoading(state, loading) {
      state.buyLoading = loading
    },
    setBuyDetailsLoading(state, loading) {
      state.buyDetails = loading
    },
    addItemImage(state, itemimage) {
      state.itemimages.push(itemimage)
    },
    setItemImageLoading(state, loading) {
      state.itemImageLoading = loading
    },

    /* download Winpe */
    setwinpeLoading(state, loading) {
      state.winpeLoading = loading
    },

    /* order */
    setorderLoading(state, loading) {
      state.orderLoading = loading
    },
    addOrder(state, orders) {
      state.orders = orders
    },

    /* datasheet */
    setdatasheetLoading(state, loading) {
      state.datasheetLoading = loading
    },
    addDatasheet(state, datasheet) {
      state.datasheet = datasheet
    },

    /* DeviceOption */
    setDeviceOptionLoading(state, loading) {
      state.deviceOptionLoading = loading
    },
    addDeviceOptions(state, deviceOptions) {
      state.deviceOptions = deviceOptions
    },
    addDeviceOption(state, deviceOption) {
      state.deviceOptions.push(deviceOption)
    },
    editDeviceOption(state, deviceOption) {
      const id = _.findIndex(state.deviceOptions, { id: deviceOption.id })
      _.extend(state.deviceOptions[id], deviceOption)
    },

    setDeviceOptionVersionsLoading(state, loading) {
      state.deviceOptionVersionsLoading = loading
    },
    addDeviceOptionVersions(state, deviceOptionVersions) {
      state.deviceOptionVersions = deviceOptionVersions
    },
    addDeviceOptionVersion(state, deviceOptionVersion) {
      state.deviceOptionVersions.push(deviceOptionVersion)
    },

    /* Assign device options */
    addDeviceOptionAssign(state, deviceOption) {
      state.assignedDeviceOptions.push(deviceOption)
    },
    addDeviceOptionsAssign(state, deviceOptions) {
      state.assignedDeviceOptions = deviceOptions
    },
    setDeviceOptionsAssignLoading(state, loading) {
      state.deviceOptionsAssignLoading = loading
    },

    /* categories */
    addCategories(state, categories) {
      state.categories = categories
    },
    setCategoriesLoading(state, loading) {
      state.categoriesLoading = loading
    },

    /* Device Properties */
    setDevicePropertiesLoading(state, loading) {
      state.devicePropertiesLoading = loading
    },
    addDeviceProperties(state, deviceProperties) {
      state.deviceProperties = deviceProperties
    },
    addDeviceProperty(state, deviceProperty) {
      state.deviceProperties.push(deviceProperty)
    },
    editDeviceProperty(state, deviceProperty) {
      const id = _.findIndex(state.deviceProperties, { id: deviceProperty.id })
      _.extend(state.deviceProperties[id], deviceProperty)
    },
    addDevicePropCategory(state, devicePropCategory) {
      state.devicePropCategory = devicePropCategory
    },
    addVM(state, vm) {
      state.virtualMachines.push(vm)
      state.createVMLoading = false
      success()
    },
    setVMs(state, vms) {
      state.virtualMachines = vms
    },
    setVMLoadingValue(state, value) {
      state.createVMLoading = value
    },

    /* Persons */
    addPerson(state, person) {
      success()
      if (person.companyId == null) {
        state.customerUnemployedPersons.push(person)
      }
      state.persons.push(person)
    },
    loadCustomersUnemployedPersons(state, persons) {
      state.customerUnemployedPersons = persons
    },
    setPersons(state, persons) {
      state.persons = persons
    },
    deletePerson(state, personId) {
      const id = _.findIndex(state.persons, { id: personId })
      state.persons.splice(id, 1)
    },
    editPerson(state, person) {
      const id = person.id
      for (let i = 0; i < state.persons.length; i++) {
        if (state.persons[i].id === id) {
          state.persons.splice(i, 1, person)
          return
        }
      }
    },

    /* Companies */
    addCompany(state, company) {
      success()
      state.customersCompanies.push(company)
    },
    setCustomersCompanies(state, companies) {
      state.customersCompanies = companies
    },
    removeCompany(state, companyId) {
      for (let i = 0; i < state.customersCompanies.length; i++) {
        if (state.customersCompanies[i].id === companyId) {
          state.customersCompanies.splice(i, 1)
          return
        }
      }
    },
    updateCompany(state, companyData) {
      for (let i = 0; i < state.customersCompanies.length; i++) {
        if (state.customersCompanies[i].id === companyData.id) {
          state.customersCompanies.splice(i, 1, companyData)
          success()
          return
        }
      }
    },

    // CEPs
    setCEPs(state, ceps) {
      state.CEPs = ceps
    },
    addCEP(state, cep) {
      state.CEPs.push(cep)
    },
    editCEP(state, cep) {
      for (let i = 0; i < state.CEPs.length; i++) {
        if (state.CEPs[i].id === cep.id) {
          state.CEPs.splice(i, 1, cep)
          success()
          return
        }
      }
    },
    deleteCEP(state, cepId) {
      for (let i = 0; i < state.CEPs.length; i++) {
        if (state.CEPs[i].id === cepId) {
          state.CEPs.splice(i, 1)
          return
        }
      }
    },

    // SEPs
    setSEPs(state, seps) {
      state.SEPs = seps
    },
    addSEP(state, sep) {
      state.SEPs.push(sep)
    },
    setSEPsLoading(state, value) {
      state.sepsLoading = value
    },
    deleteSEP(state, sep) {
      for (let i = 0; i < state.SEPs.length; i++) {
        if (state.SEPs[i].id === sep.id) {
          state.SEPs.splice(i, 1)
          return
        }
      }
    },

    // Hardware Models
    addHardwareModel(state, hardwareModel) {
      state.hardwareModels.push(hardwareModel)
    },
    setHardwareModels(state, hardwareModels) {
      state.hardwareModels = hardwareModels
    },
    deleteHardwareModel(state, hardwareModelId) {
      for (let i = 0; i < state.hardwareModels.length; i++) {
        if (state.hardwareModels[i].id === hardwareModelId) {
          state.hardwareModels.splice(i, 1)
          return
        }
      }
    },
    editHardwareModel(state, hardwareModel) {
      for (let i = 0; i < state.hardwareModels.length; i++) {
        if (state.hardwareModels.id === hardwareModel.id) {
          state.hardwareModels[i] = hardwareModel
          return
        }
      }
    },
    // Hardware Models
    addDriver(state, driver) {
      state.drivers.push(driver)
    },
    setDrivers(state, drivers) {
      state.drivers = drivers
    },
    editDriver(state, driver) {
      for (let i = 0; i < state.drivers.length; i++) {
        if (state.drivers[i].id === driver.id) {
          state.drivers.splice(i, 1, driver)
          break
        }
      }
    },
    deleteDriver(state, driverId) {
      for (let i = 0; i < state.drivers.length; i++) {
        if (state.drivers[i].id === driverId) {
          state.drivers.splice(i, 1)
          return
        }
      }
    },

    // OS Models
    addOSModel(state, osModel) {
      state.osModels.push(osModel)
    },
    editOSModel(state, osModel) {
      for (let i = 0; i < state.osModels.length; i++) {
        if (state.osModels.id === osModel.id) {
          state.osModels[i] = osModel
          return
        }
      }
    },
    setOSModels(state, osModels) {
      state.osModels = osModels
    },
    deleteOSModel(state, osModelId) {
      for (let i = 0; i < state.osModels.length; i++) {
        if (state.osModels[i].id === osModelId) {
          state.osModels.splice(i, 1)
          return
        }
      }
    },

    // AssetTypes
    addAssetType(state, assetType) {
      state.assetTypes.push(assetType)
    },
    setAssetTypes(state, assetTypes) {
      state.assetTypes = assetTypes
    },

    // Asset classes
    addAssetClass(state, assetClass) {
      state.assetClasses.push(assetClass)
    },
    setAssetClasses(state, assetClasses) {
      state.assetClasses = assetClasses
    },

    // Software Streams
    setSoftwareStreams(state, softwareStreams) {
      state.softwareStreams = softwareStreams
    },
    addSoftwareStream(state, softwareStream) {
      state.softwareStreams.push(softwareStream)
    },
    editSoftwareStream(state, softwareStream) {
      for (let i = 0; i < state.softwareStreams.length; i++) {
        if (state.softwareStreams[i].id === softwareStream.id) {
          state.softwareStreams.splice(i, 1, softwareStream)
        }
      }
    },
    deleteSoftwareStream(state, streamId) {
      for (let i = 0; i < state.softwareStreams.length; i++) {
        if (state.softwareStreams[i].id === streamId) {
          state.softwareStreams.splice(i, 1)
          return
        }
      }
    }
  },
  actions: {
    init({ dispatch }) {
      if (Auth.hasPermission('admin')) {
        dispatch('getScripts')
        dispatch('getSystemhouses')
      }
      if (Auth.hasPermission('systemhouse')) {
        dispatch('getCustomers')
        dispatch('getUsers')
      }
      if (Auth.hasPermission('customer')) {
        dispatch('getLocations')
      }
    },

    /* Customer Drivers */
    getCustomerDrivers({ commit }, customerId) {
      getCustomerDrivers(customerId)
        .then(response => {
          commit('setCustomerDrivers', response.data)
        })
        .catch(error => console.log({ error }))
    },

    /* Admin Options */
    addAdminOption({ commit }, payload) {
      addAdminOption(payload)
        .then(response => commit('addAdminOption', response.data))
        .catch(error => console.log({ error }))
    },
    getAdminOptions({ commit }) {
      getAdminOptions()
        .then(response => commit('setAdminOptions', response.data))
        .catch(error => console.log({ error }))
    },
    editAdminOption({ commit }, payload) {
      editAdminOption(payload)
        .then(response => commit('editAdminOption', response.data))
        .catch(error => console.log({ error }))
    },

    /* ImageStreams */
    getImageStreams({ commit }) {
      getImageStreams()
        .then(response => {
          let streamArray = response.data
          streamArray.forEach(function (el) {
            if (el.icon !== null && el.icon.guid !== '') {
              let currentIcon = null
              let currentIconId = el.icon.id
              getImageIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
            commit('setImageStreams', response.data)
          })
        })
        .catch(debug)
    },
    addImageStream({ commit }, payload) {
      addImageStream(payload)
        .then(response => {
          let imageStream = response.data
          if (imageStream.icon !== null) {
            getImageIcon(imageStream.icon.id)
              .then(response1 => {
                let currentIcon = null
                currentIcon = window.URL.createObjectURL(response1.data)
                imageStream.icon = currentIcon
              })
          }
          commit('addImageStream', imageStream)
        })
        .catch(debug)
    },
    editImageStream({ commit }, payload) {
      editImageStream(payload)
        .then(response => {
          let imageStream = response.data
          if (imageStream.icon !== null) {
            getImageIcon(imageStream.icon.id)
              .then(response1 => {
                let currentIcon = null
                currentIcon = window.URL.createObjectURL(response1.data)
                imageStream.icon = currentIcon
              })
          }
          commit('editImageStream', imageStream)
        })
    },
    deleteImageStream({ commit }, streamId) {
      deleteImageStream(streamId)
        .then(() => { commit('deleteImageStream', streamId) })
        .catch(debug)
    },

    /* Customer Hardware Models */
    getCustomerHardwareModels({ commit }, customerId) {
      getCustomerHardwareModels(customerId)
        .then(response => commit('setCustomerHardwareModels', response.data))
        .catch(debug)
    },

    /* Software */
    getSoftwares({ commit }) {
      commit('setSoftwaresLoading', true)
      getSoftwares()
        .then(response => {
          let softwareArray = response.data
          commit('addSoftwares', softwareArray)
          softwareArray.forEach(function (el) {
            if (el.icon !== null) {
              let currentIcon = null
              let currentIconId = el.icon.id
              getSoftwareIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
          })
        })
        .catch(debug)
        .finally(() => {
          commit('setSoftwaresLoading', false)
        })
    },
    addSoftware({ commit }, software) {
      addSoftware(software).then(response => commit('addSoftware', response.data), debug)
    },
    editSoftware({ commit }, software) {
      editSoftware(software).then(response => commit('editSoftware', response.data), debug)
    },
    deleteSoftware({ commit }, software) {
      deleteSoftware(software).then(() => commit('deleteSoftware', software), debug)
    },

    getSoftwareIcon({ commit }, fileId) {
      commit('setItemImageLoading', true)
      getSoftwareIcon(fileId)
        .then(response => commit('addSoftwareIcon', response.data))
        .catch(debug)
        .finally(() => commit('setItemImageLoading', false))
    },
    fetchSoftwareIcon(fileId) {
      getSoftwareIcon(fileId)
        .then(response => {
          let icon = window.URL.createObjectURL(response.data)
          return icon
        })
        .catch(debug)
    },

    /* Assign Software */
    addSoftwareAssign({ commit }, assignSoftware) {
      console.log({ assignSoftware }, { commit })
      let clientId = assignSoftware.clientId
      console.log('GIVEM CLIENT ID: ', clientId)
      // delete assignSoftware.clientId
      // delete assignSoftware.name
      addSoftwareAssign(getCustomerId(), clientId, assignSoftware).then(response => console.log({ response })).catch(debug)
    },
    deleteSoftwareAssign(assignSoftware) {
      let clientId = assignSoftware.clientId
      let softwareId = assignSoftware.id
      delete assignSoftware.clientId
      delete assignSoftware.name
      deleteSoftwareAssign(getCustomerId(), clientId, softwareId, assignSoftware).catch(debug)
    },
    getSoftwareAssign({ commit }, clientId) {
      commit('setSoftwareAssignLoading', true)
      getSoftwareAssign(getCustomerId(), clientId).then({ commit }).then(response => {
        let r = response.data
        let resp = []
        if (r.length > 0) {
          r.forEach(function (element) {
            let obj = {
              name: element.name,
              id: element.id,
              required: element.required
            }
            resp.push(obj)
          })
        }
        return resp
      }).then(result => commit('addSoftwaresAssign', result))
        .catch(debug)
        .finally(() => commit('setSoftwareAssignLoading', false))
    },

    /* Release Plans */
    getCustomersReleasePlans({ commit }, customerId) {
      getCustomersReleasePlans(customerId)
        .then(response => commit('setReleasePlans', response.data))
        .catch(debug)
    },
    addReleasePlan({ commit }, payload) {
      addReleasePlan(payload)
        .then(response => commit('addReleasePlan', response.data))
        .catch(debug)
    },
    editReleasePlan({ commit }, payload) {
      editReleasePlan(payload)
        .then(response => commit('editReleasePlan', response.data))
        .catch(debug)
    },
    deleteReleasePlan({ commit }, releasePlanId) {
      deleteReleasePlan(releasePlanId)
        .then(response => commit('deleteReleasePlan', response.data))
        .catch(debug)
    },

    /* SW Images */
    getSWImages({ commit }) {
      getSWImages()
        .then(response => commit('setSWImages', response.data))
        .catch(debug)
    },
    addSWImage({ commit }, data) {
      addSWImage(data)
        .then(response => commit('addSWImage', response.data))
        .catch(debug)
    },
    editSWImage({ commit }, data) {
      editSWImage(data)
        .then(response => commit('editSWImage', response.data))
        .catch(debug)
    },
    deleteSWImage({ commit }, imageId) {
      deleteSWImage(imageId)
        .then(response => commit('deleteSWImage', response.data))
        .catch(debug)
    },

    /* Customer OS Images */
    getNewImages({ commit }, customerId) {
      commit('setNewImageLoading', true)
      getNewImages(customerId)
        .then(response => commit('setNewImages', response.data))
        .catch(debug)
        .finally(() => commit('setNewImageLoading', false))
    },
    getRevisionImage({ commit }, customerId) {
      commit('setLoadingRevisionImage', true)
      getRevisionImages(customerId)
        .then(response => commit('setRevisionImage', response.data))
        .catch(debug)
        .finally(() => commit('setLoadingRevisionImage', false))
    },
    getCustomersOSImages({ commit }, customerId) {
      commit('setCustomersImagesLoading', true)
      getCustomersOSImages(customerId)
        .then(response => commit('setCustomersOSImages', response.data))
        .catch(debug)
        .finally(() => commit('setCustomersImagesLoading', false))
    },
    deleteCustomerOSImage({ commit }, payload) {
      commit('setCustomersImagesLoading', true)
      deleteCustomerOSImage(payload.customerId, payload.imageId)
        .then(response => commit('deleteCustomerOSImage', response.data))
        .catch(debug)
        .finally(() => commit('setCustomersImagesLoading', false))
    },
    getCustomerImageStreams({ commit }, customerId) {
      getCustomerImageStreams(customerId)
        .then(response => {
          let streamArray = response.data
          streamArray.forEach(function (el) {
            if (el.icon !== null && el.icon.guid !== '') {
              let currentIcon = null
              let currentIconId = el.icon.id
              getImageIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
            commit('setCustomerImageStreams', response.data)
          })
        })
        .catch((error) => errorMessage(error.response))
    },
    deleteCustomerImageStream({ commit }, streamId) {
      deleteCustomerImageStream(streamId)
        .then(() => commit('deleteCustomerImageStream', streamId))
        .catch((error) => errorMessage(error.response))
    },

    /* Customer Software */
    getNewSoftware({ commit }, customerId) {
      commit('setNewSoftwareLoading', true)
      getNewSoftware(customerId)
        .then(response => commit('setNewSoftware', response.data))
        .catch(debug)
        .finally(() => commit('setNewSoftwareLoading', false))
    },
    getRevisionSoftware({ commit }, customerId) {
      commit('setLoadingRevisionSoftware', true)
      checkRevisionNumber(customerId)
        .then(response => commit('setRevisionSoftware', response.data))
        .catch(debug)
        .finally(() => commit('setLoadingRevisionSoftware', false))
    },
    getCustomersSoftware({ commit }, customerId) {
      commit('setCustomersSoftwareLoading', true)
      getCustomersSoftware(customerId)
        .then(response => commit('setCustomersSoftware', response.data))
        .catch(debug)
        .finally(() => commit('setCustomersSoftwareLoading', false))
    },
    orderSoftware({ commit }, payload) {
      commit('setCustomersSoftwareLoading', true)
      return orderSoftware(payload.shopItem, payload.customerId)
      // .then(response => {
      //   successMessage('Thanks! We have received your order. Your Software is now available.')
      //   commit('addCustomersSoftware', response.data)
      // })
      // .catch(error => {
      //   if (error.response.data.includes('The csdp storage entry point does not exist')) {
      //
      //   }
      //   errorMessage(error.response)
      // })
      // .finally(() => commit('setCustomersSoftwareLoading', false))
    },
    orderImage({ commit }, payload) {
      commit('setCustomersSoftwareLoading', true)
      return orderImage(payload.shopItem, payload.customerId)
    },
    orderSelectedSoftware({ commit }, payload) {
      commit('setCustomersSoftwareLoading', true)
      return orderSelectedSoftware(payload.selectedSoftwares, payload.customerId)
    },
    getCustomersStreams({ commit }, customerId) {
      commit('setCustomersStreamsLoading', true)
      getCustomersStreams(customerId)
        .then(response => {
          let streamArray = response.data
          streamArray.forEach(function (el) {
            if (el.icon !== null) {
              let currentIcon = null
              let currentIconId = el.icon.id
              getSoftwareIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
            commit('setCustomersStreams', response.data)
          })
        })
        .catch(debug)
        .finally(() => commit('setCustomersStreamsLoading', false))
    },
    editCustomerSoftwareStream({ commit }, payload) {
      // TODO: Fetch the icon
      let streamId = payload.streamId
      let customerId = payload.customerId
      let data = payload.data
      editCustomerSoftwareStream(customerId, streamId, data)
        .then(response => {
          let stream = response.data
          if (stream.icon !== null) {
            let currentIcon = null
            let currentIconId = stream.icon.id
            getSoftwareIcon(currentIconId)
              .then(response => {
                currentIcon = window.URL.createObjectURL(response.data)
                stream.icon = currentIcon
              })
          }
          commit('editCustomerSoftwareStream', stream)
        })
        .catch(debug)
    },
    editCustomerSoftware({ commit }, payload) {
      let customerId = payload.customerId
      let data = payload.data
      editCustomerSoftware(customerId, data)
        .then(response => commit('editCustomerSoftware', response.data))
        .catch(debug)
    },
    deleteCustomerSoftware({ commit }, payload) {
      deleteCustomerSoftware(payload.customerId, payload.customerSoftwareId)
        .then(response => commit('deleteCustomerSoftware', response.data))
        .catch(err => console.log({ err }))
    },
    deleteCustomerStream({ commit }, payload) {
      deleteCustomerStream(payload.customerId, payload.customerStreamId)
        .then(response => commit('deleteCustomerStream', response.data))
        .catch(debug)
    },

    /* Workflows */
    addWorkflow({ commit }, workflow) {
      createWorkflow(workflow)
        .then(response => {
          commit('addWorkflow', response.data)
        })
        .catch(debug)
    },
    editWorkflow({ commit }, workflow) {
      updateWorkflow(workflow)
        .then(response => commit('editWorkflow', response.data))
        .catch(debug)
    },
    deleteWorkflow({ commit }, workflow) {
      commit('deleteWorkflow', workflow)
    },
    getWorkflows({ commit }) {
      getWorkflows()
        .then(response => commit('setWorkflows', response.data))
        .catch(debug)
    },

    /* Tasks */
    addTask({ state, commit }, task) {
      const lastId = parseInt(state.tasks[state.tasks.length - 1].id)
      task.id = (lastId + 1).toString()
      commit('addTask', task)
    },
    editTask({ commit }, task) {
      commit('editTask', task)
    },
    deleteTask({ commit }, task) {
      commit('deleteTask', task)
    },

    /* Domain Registrations */
    addDomainreg({ commit }, domainreg) {
      createDomainRegistration(domainreg)
        .then(response => commit('addDomainreg', response.data))
        .catch(debug)
    },
    editDomainreg({ commit }, domainreg) {
      updateDomainRegistration(domainreg)
        .then(response => commit('editDomainreg', response.data))
        .catch(debug)
    },
    deleteDomainreg({ commit }, domainreg) {
      // TODO: refactor
      commit('deleteDomainreg', domainreg)
    },
    getDomainregs({ commit }) {
      getDomainRegistrations()
        .then(response => commit('setDomainRegs', response.data))
        .catch(debug)
    },

    /* Certifications */
    addCert({ commit }, cert) {
      createCertification(cert)
        .then(response => commit('addCert', response.data))
        .catch(debug)
    },
    editCert({ commit }, cert) {
      updateCertification(cert)
        .then(response => commit('editCert', response.data))
        .catch(debug)
    },
    deleteCert({ commit }, cert) {
      commit('deleteCert', cert)
    },
    getCerts({ commit }) {
      getCertifications()
        .then(response => commit('setCerts', response.data))
        .catch(debug)
    },

    /* script */
    getScripts({ commit }) {
      commit('setScriptsLoading', true)
      getScripts()
        .then(response => commit('addScripts', response.data))
        .catch(debug)
        .finally(() => commit('setScriptsLoading', false))
    },
    addScript({ commit }, script) {
      addScript(script).then(response => commit('addScript', response.data), debug)
    },
    editScript({ commit }, script) {
      editScript(script).then(response => commit('editScript', response.data), debug)
    },
    addScriptVersion({ commit }, script) {
      addScriptVersion(script).then(response => commit('editScript', response.data), debug)
    },
    getLatestBitStreamScriptVersion(payload) {
      return getLatestBitStreamScriptVersion(payload.actionName, payload.clientId, payload.customerId, payload.data)
    },

    /* Users */
    getUsers({ commit }) {
      commit('setUsersLoading', true)
      getUsers()
        .then(response => commit('addUsers', response.data))
        .catch(debug)
        .finally(() => commit('setUsersLoading', false))
    },
    addUser({ commit }, user) {
      addUser(user).then(response => commit('addUser', response.data), debug)
    },
    editUser({ commit }, user) {
      editUser(user).then(response => commit('editUser', response.data), debug)
    },
    deleteUser({ commit }, user) {
      deleteUser(user).then(() => commit('deleteUser', user), debug)
    },

    /* Systemhouses */
    getSystemhouses({ commit }) {
      commit('setSystemhousesLoading', true)
      getSystemhouses()
        .then(response => commit('addSystemhouses', response.data))
        .catch(debug)
        .finally(() => commit('setSystemhousesLoading', false))
    },
    addSystemhouse({ commit }, systemhouse) {
      addSystemhouse(systemhouse).then(response => commit('addSystemhouse', response.data), debug)
    },
    editSystemhouse({ commit }, systemhouse) {
      editSystemhouse(systemhouse).then(response => commit('editSystemhouse', response.data), debug)
    },
    deleteSystemhouse({ commit }, systemhouse) {
      deleteSystemhouse(systemhouse).then(() => commit('deleteSystemhouse', systemhouse), debug)
    },

    // /* Customers */
    // getCustomers ({commit}) {
    //   commit('setCustomersLoading', true)
    //   getCustomers()
    //     .then(response => commit('addCustomers', response.data))
    //     .catch(debug)
    //     .finally(() => commit('setCustomersLoading', false))
    // },
    // getCustomer ({commit}, customerId) {
    //   getCustomer(customerId)
    //     .then(response => commit('setCustomerDetails', response.data))
    //     .catch(debug)
    // },
    // addCustomer ({commit}, customer) {
    //   let result = addCustomer(customer).catch(debug)
    //   return result
    // },
    // editCustomer ({commit}, customer) {
    //   editCustomer(customer).then(response => commit('editCustomer', response.data), debug)
    // },
    // deleteCustomer ({commit}, customer) {
    //   deleteCustomer(customer).then(response => commit('deleteCustomer', customer), debug)
    // },
    // getMainCompanyAndExpert ({commit}, customerId) {
    //   return getMainCompanyAndExpert(customerId)
    // },
    // getCustomersPersons ({commit}, customerId) {
    //   return getPersonsOfCustomer(customerId)
    // },
    // returnCustomersCompanies ({commit}, customerId) {
    //   return getCompaniesOfCustomer(customerId)
    // },
    // returnCustomersLocations ({commit}, customerId) {
    //   return getLocationsOfCustomer(customerId)
    // },
    // addCustomInventory ({commit}, payload) {
    //   addCustomInventory(payload)
    //     .then(response => commit('addCustomInventory', response.data))
    //     .catch(debug)
    // },
    // getCustomInventory ({commit}, clientId) {
    //   getCustomInventory(clientId)
    //     .then(response => commit('setCustomInventory', response.data))
    //     .catch(debug)
    // },
    // addOrEditCustomCorporateParameter ({commit}, data) {
    //   addOrEditCustomCorporateParameter(data.customerId, data.parameter).then(response => commit(
    //     'addCorporateParameter', response.data), debug)
    // },

    /* SmbStorages */
    getCustomersSmbStorages({ commit }, customerId) {
      getCustomersSmbStorages(customerId)
        .then(response => commit('setCustomersSmbStorages', response.data))
        .catch(debug)
    },

    createSmbStorage({ commit }, payload) {
      createSmbStorage(payload)
        .then(response => commit('addSmbStorage', response.data))
        .catch(debug)
    },

    updateSmbStorage({ commit }, payload) {
      updateSmbStorage(payload)
        .then(response => commit('updateSmbStorage', response.data))
        .catch(debug)
    },

    deleteSmbStorage({ commit }, smbStorageId) {
      deleteSmbStorage(smbStorageId)
        .then(response => commit('deleteSmbStorage', response.data))
        .catch(debug)
    },

    /* Azure Blobs */
    getCustomersAzureBlobs({ commit }, customerId) {
      getCustomersAzureBlobs(customerId)
        .then(response => commit('setAzureBlobs', response.data))
        .catch(debug)
    },

    createAzureBlob({ commit }, payload) {
      createAzureBlob(payload)
        .then(response => commit('addAzureBlob', response.data))
    },

    /* Resource Groups */
    getCustomersResourceGroups({ commit }, customerId) {
      getCustomersResourceGroups(customerId)
        .then(response => commit('setResourceGroups', response.data))
    },
    createResourceGroup(resourceGrp) {
      return createResourceGroup(resourceGrp)
    },

    /* StorageAccounts */
    getCustomersStorageAccounts({ commit }, customerId) {
      getCustomersStorageAccounts(customerId)
        .then(response => commit('setStorageAccounts', response.data))
        .catch(debug)
    },
    createStorageAccount({ commit }, payload) {
      createStorageAccount(payload)
        .then(response => {
          commit('addStorageAccount', response.data)
        })
        .catch(error => console.log(error.data))
    },

    /* Variables */
    getVariables({ commit }, customer) {
      commit('setVariablesLoading', true)
      getVariables(customer)
        .then(response => commit('addVariables', response.data))
        .catch(debug)
        .finally(() => commit('setVariablesLoading', false))
    },
    addVariable({ commit }, variable) {
      let customer = variable.customer
      delete variable.customer
      addVariable(customer, variable).then(response => commit('addVariable', response.data), debug)
    },

    /* Defaults */
    getDefaults({ commit }, customer) {
      commit('setDefaultsLoading', true)
      getDefaults(customer)
        .then(response => commit('addDefaults', response.data))
        .catch(debug)
        .finally(() => commit('setDefaultsLoading', false))
    },
    editDefault({ commit }, payload) {
      editDefault(payload.customerId, payload.defaultData).then(response => commit('editDefault', response.data), debug)
    },

    /* Locations */
    getLocations({ commit }) {
      getLocations().then(response => commit('setLocations', response.data), debug)
    },

    addLocation({ commit }, payload) {
      addLocation(payload)
        .then(response => commit('addLocation', response.data), debug)
    },

    getCustomersLocations({ commit }, customerId) {
      getCustomersLocations(customerId)
        .then(response => commit('setCustomersLocations', response.data))
        .catch(debug)
    },

    deleteLocation({ commit }, locationId) {
      deleteLocation(locationId)
        .then(response => commit('deleteLocation', response.data))
        .catch(debug)
    },

    updateLocation({ commit }, location) {
      updateLocation(location)
        .then(response => commit('updateLocation', response.data))
        .catch(debug)
    },

    /* Images */
    getImages({ commit }) {
      getImages().then(response => commit('addImages', response.data), debug)
    },

    /* Domains */
    getDomains({ commit }, baseId) {
      commit('setDomainsLoading', true)
      getDomains(getCustomerId(), baseId)
        .then(response => commit('addDomains', response.data))
        .catch(debug)
        .finally(() => commit('setDomainsLoading', false))
    },
    addDomain({ commit }, domain) {
      const baseId = domain.baseId
      domain.baseId = undefined
      addDomain(getCustomerId(), baseId, domain)
        .then(response => commit('addDomain', response.data))
        .catch(debug)
    },
    addDomainConfiguration(configuration) {
      const baseId = configuration.baseId
      configuration.baseId = undefined
      addconfigWizard(getCustomerId(), baseId, configuration)
      //  .then(response => commit('addconfigWizard', response.data))
      // .catch(debug)
    },
    downloadCSVtemplate() {
      downloadCSVtemplate()
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'AD_User_template.csv', true)
        })
        .catch(debug)
    },

    /* Subscriptions */
    getSubscriptions({ commit }) {
      commit('setSubscriptionsLoading', true)
      getSubscriptions(getCustomerId())
        .then(response => commit('addSubscriptions', response.data))
        .catch(() => commit('addSubscriptions', []))
        .finally(() => commit('setSubscriptionsLoading', false))
    },

    /* Buy */
    getBuyItems({ commit }) {
      commit('setBuyLoading', true)
      getBuyItems(getCustomerId())
        .then(response => commit('addBuy', response.data))
        .catch(debug)
        .finally(() => commit('setBuyLoading', false))
    },

    getSoftwareShopItems({ commit }, customerId) {
      getSoftwareShopItems(customerId)
        .then(response => {
          let tempArray = response.data
          for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].icon !== null) {
              let blobarr = []
              getSoftwareIconForShop(tempArray[i].id)
                .then(response1 => {
                  let bloburl = window.URL.createObjectURL(response1.data)
                  blobarr.push(bloburl)
                })
                .catch(err => {
                  console.log(err)
                })
              tempArray[i].imgsrc = blobarr
            }
          }
          commit('setSoftwareShopItems', tempArray)
        })
        .catch(err => { console.log('ERROR: ' + { err }) })
    },

    getShopItemsCategories({ commit }) {
      getShopItemsCategories()
        .then(response => commit('setShopItemCategories', response.data))
        .catch(debug)
    },

    getBuyItemImage({ commit }, fileId) {
      commit('setItemImageLoading', true)
      getBuyItemImage(fileId)
        .then(response => commit('addItemImage', response.data))
        .catch(debug)
        .finally(() => commit('setItemImageLoading', false))
    },

    getBuyDetails({ commit }) {
      // get all the shop items and add imgsrc for each element that is list of blobs
      commit('setBuyDetailsLoading', true)
      getBuyItems({ commit }).then(response => {
        let buylist = response.data
        buylist.forEach(function (el) {
          if (Object.prototype.hasOwnProperty.call(el, 'images') && el['images'].length) {
            let blobarr = []
            el['images'].forEach(function (imgEl) {
              getBuyItemImage(imgEl['id']).then((respOne) => {
                let bloburl = window.URL.createObjectURL(respOne.data)
                blobarr.push(bloburl)
              })
            })
            el.imgsrc = blobarr
          }
        })
        return buylist
      })
        .then(result => commit('addBuyImages', result))
        .catch(debug)
        .finally(() => commit('setBuyDetailsLoading', false))
    },
    /* assetManagement */
    addAssetLabels({ commit }, payload) {
      addAssetLabels(payload.id, payload.amount).then(response => commit('addAssetLabels', response.data), debug)
    },
    getAssetLabels({ commit }) {
      commit('setAssetLoading', true)
      getAssetLabels()
        .then(response => {
          commit('setAssetLabels', response.data.assets)
        })
        .catch(debug)
        .finally(() => commit('setAssetLoading', false))
    },
    editAsset({ commit }, item) {
      editAsset(item).then(response => commit('editAsset', response.data), debug)
    },
    assignAsset({ commit }, payload) {
      assignAsset(payload.clientId, payload.assetId)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },
    deleteAssetModel({ commit }, assetModelId) {
      deleteAssetModel(assetModelId)
        .then(response => commit('deleteAssetModel', response.data.id))
    },
    unassignAsset({ commit }, assetModelId) {
      unassignAsset(assetModelId)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },
    exportAssetSelectionToCSV(selection) {
      exportAssetSelectionToCSV({ assets: selection })
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'assetLabels' + new Date(Date.now()).toUTCString() + '.csv', true)
        })
        .catch(error => {
          console.log({ error })
          debug(error.data)
        })
    },
    updateAssetModel({ commit }, payload) {
      updateAssetModel(payload)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },
    assignPerson({ commit }, payload) {
      assignPerson(payload.assetId, payload.personId)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },
    assignLocation({ commit }, payload) {
      assignLocation(payload.assetId, payload.locationId)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },

    // Vendor Models
    addVendorModel({ commit }, payload) {
      addVendorModel(payload)
        .then(response => commit('addVendorModel', response.data))
        .catch(debug)
    },
    getVendorModels({ commit }) {
      getVendorModels()
        .then(response => commit('setVendorModels', response.data))
        .catch(debug)
    },
    updateVendorModel({ commit }, payload) {
      updateVendorModel(payload)
        .then(response => commit('updateVendorModel', response.data))
        .catch(debug)
    },
    assignVendor({ commit }, payload) {
      assignVendor(payload.assetId, payload.vendorModelId)
        .then(response => commit('assignAsset', response.data))
        .catch(debug)
    },

    // Rules
    getRules({ commit }) {
      commit('setRulesLoading', true)
      getRules()
        .then(response => {
          commit('setRules', response.data)
        })
        .catch(debug)
        .finally(commit('setRulesLoading', false))
    },
    addRule({ commit }, ruleModel) {
      addRule(ruleModel)
        .then(response => commit('addRule', response.data))
        .catch(debug)
    },
    updateRule({ commit }, ruleModel) {
      updateRule(ruleModel)
        .then(response => commit('updateRule', response.data))
        .catch(debug)
    },
    deleteRule({ commit }, ruleId) {
      deleteRule(ruleId)
        .then(() => commit('deleteRule', ruleId))
        .catch(debug)
    },

    /* ShopItems */
    getShopItems({ commit }) {
      commit('setShopLoading', true)
      getShopItems()
        .then(response => commit('addShopItems', response.data))
        .catch(debug)
        .finally(() => commit('setShopLoading', false))
    },
    addShopItem({ commit }, item) {
      addShopItem(item).then(response => commit('addShopItem', response.data), debug)
    },
    editShopItem({ commit }, item) {
      editShopItem(item).then(response => commit('editShopItem', response.data), debug)
    },
    deleteShopItem({ commit }, item) {
      deleteShopItem(item).then(() => commit('deleteShopItem', item), debug)
    },
    getOSImages({ commit }) {
      getOSImages()
        .then(response => {
          let streamArray = response.data
          streamArray.forEach(function (el) {
            if (el.icon !== null && el.icon.guid !== '') {
              let currentIcon = null
              let currentIconId = el.icon.id
              getImageIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
          })
          commit('setBuyOSImages', streamArray)
        })
        .catch(debug)
    },

    /* Clients */
    getClients({ commit }) {
      commit('setClientLoading', true)
      getClients(getCustomerId())
        .then(response => commit('addClients', response.data))
        .catch(debug)
        .finally(() => commit('setClientLoading', false))
    },
    addClient({ commit }, payload) {
      addClient(getCustomerId(), payload.userId, payload.client)
        .then(response => {
          commit('addClient', response.data)
        })
        .catch(error => errorMessage(error.response))
    },
    editClient({ commit }, client) {
      editClient(getCustomerId(), client)
        .then(response => commit('editClient', response.data))
        .catch(debug)
    },
    getClientSoftware({ commit }, clientId) {
      commit('setClientSoftwareLoading', true)
      getClientSoftware(getCustomerId(), clientId)
        .then(response => commit('addClientSoftwares', response.data))
        .catch(debug)
        .finally(() => commit('setClientSoftwareLoading', false))
    },
    addClientSoftware({ commit }, software) {
      let clientId = software.clientId
      delete software.clientId
      addClientSoftware(getCustomerId(), clientId, software)
        .then(response => commit('addClientSoftware', response.data))
        .catch(debug)
    },
    deleteClientSoftware(software) {
      let clientId = software.clientId
      let softwareId = software.softwareId
      deleteClientSoftware(getCustomerId(), clientId, softwareId).catch(debug)
    },
    addCustomParameter({ commit }, payload) {
      addCustomParameter(payload.customerId, payload.parameter)
        .then(response => commit('addDeviceParameter', response.data))
        .catch(debug)
    },
    getDeviceParameters({ commit }, payload) {
      getDeviceParameters(payload.customerId, payload.clientId)
        .then(response => commit('setDeviceParameters', response.data))
        .catch(debug)
    },
    getDeviceParameterWithDefaultValues({ commit }, path) {
      getDeviceParameterWithDefaultValues(path.customerId, path.clientId, path.optionId)
        .then(response => commit('setDeviceParameters', response.data))
        .catch(debug)
    },
    editDeviceParameter({ commit }, payload) {
      editDeviceParameter(payload.customerId, payload.clientId, payload.data)
        .then(response => commit('editDeviceParameter', response.data))
        .catch(debug)
    },
    getClientInventory(payload) {
      return getDeviceInventory(payload.customerId, payload.clientId)
    },
    exportInventoryAsCSV(payload) {
      let customerId = payload.customerId
      let clientId = payload.clientId
      let clientName = payload.clientName
      exportInventoryAsCSV(customerId, clientId)
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'inventory-' + clientName + '.csv', true)
        })
        .catch(error => debug(error))
    },
    exportInventoriesCSV(payload) {
      let customerId = payload.customerId
      let customerName = payload.customerName
      exportInventoriesCSV(customerId)
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'inventory-' + customerName + '.csv', true)
        })
        .catch(error => debug(error))
    },
    deleteClient({ commit }, payload) {
      deleteClient(payload.customerId, payload.clientId)
        .then(response => commit('deleteClient', response.data))
        .catch(debug)
    },

    /* download winpe */
    downloadWinpe({ commit }) {
      commit('setwinpeLoading', true)
      getWinPESASUrl()
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'WinPE.iso', true)
        })
        // .then(response => {
        //   downloadViaSAS(response.data.url)
        //   var azure = require('azure-storage')
        //   console.log(azure)
        //     .then(response => {
        //       let blob = response.data
        //       FileSaver.saveAs(blob, 'WinPE.iso', true)
        //     })
        //     .catch(debug)
        // })
        .catch(debug)
        .finally(() => commit('setwinpeLoading', false))
    },
    downloadFirstAgent() {
      downloadFirstAgent()
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'FirstAgent.exe', true)
        })
        .catch(debug)
    },
    downloadSmartDeploy() {
      downloadSmartDeploy()
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, 'SmartDeploy.Package.Installer.msi', true)
        })
        .catch(debug)
    },
    /* order */
    addOrder({ commit }, order) {
      addOrder(order)
        .then(response => commit('addOrder', response.data))
        .catch(debug)
    },

    /* datasheet */

    getDatasheet({ commit }, clientId) {
      commit('setdatasheetLoading', true)
      getDatasheet(getCustomerId(), clientId).then({ commit }).then(response => {
        let resp = response.data
        let dataSheetModel = _.clone(defaultModel)
        for (let key in resp) {
          if (resp[key] === null || resp[key] === undefined) {
            resp[key] = dataSheetModel[key]
          } else if (typeof resp[key] === 'object') {
            let innerObj = resp[key]
            let dataModelObj = dataSheetModel[key]
            Object.keys(innerObj).forEach(function (prop) {
              if (Object.prototype.hasOwnProperty.call(innerObj, prop) && innerObj[prop] === null) {
                innerObj[prop] = dataModelObj[prop]
              }
            })
          }
        }
        return resp
      }).then(result => commit('addDatasheet', result))
        .catch(debug)
        .finally(() => commit('setdatasheetLoading', false))
    },

    /* device options */
    getDeviceOptions({ commit }) {
      commit('setDeviceOptionLoading', true)
      getDeviceOptions()
        .then(response => commit('addDeviceOptions', response.data))
        .catch(debug)
        .finally(() => commit('setDeviceOptionLoading', false))
    },
    addDeviceOption({ commit }, deviceOption) {
      addDeviceOption(deviceOption).then(response => commit('addDeviceOption', response.data), debug)
    },
    editDeviceOption({ commit }, deviceOption) {
      editDeviceOption(deviceOption).then(response => commit('editDeviceOption', response.data), debug)
    },
    addDeviceOptionVersion({ commit }, deviceOption) {
      addDeviceOptionVersion(deviceOption).then(response => commit('editDeviceOption', response.data), debug)
    },
    // downloadDeviceOption (payload) {
    //   console.log('PAYLOAD: ', payload)
    //   downloadDeviceOption()
    // },

    /* assign device options */

    addDeviceOptionAssign({ commit }, deviceOption) {
      // console.log('ASSIGNING DEVICE OPTION:', {deviceOption})
      let clientId = deviceOption[0].clientId
      delete deviceOption[0].clientId
      addDeviceOptionAssign(getCustomerId(), clientId, deviceOption).then(response => commit('addDeviceOptionAssign', response.data), debug)
    },
    deleteDeviceOptionAssign(clientId) {
      deleteDeviceOptionAssign(getCustomerId(), clientId).catch(debug)
    },
    getDeviceOptionAssign({ commit }, clientId) {
      commit('setDeviceOptionsAssignLoading', true)
      getDeviceOptionAssign(getCustomerId(), clientId).then({ commit }).then(response => {
        let r = response.data
        let resp = []
        if (r.length > 0) {
          r.forEach(function (element) {
            let obj = {
              name: element.name,
              id: element.id
            }
            resp.push(obj)
          })
        }
        return resp
      }).then(result => commit('addDeviceOptionsAssign', result))
        .catch(debug)
        .finally(() => commit('setDeviceOptionsAssignLoading', false))
    },

    getDeviceOptionVersions({ commit }) {
      commit('setDeviceOptionVersionsLoading', true)
      getDeviceOptionVersions()
        .then(response => commit('addDeviceOptionVersions', response.data))
        .catch(debug)
        .finally(() => commit('setDeviceOptionVersionsLoading', false))
    },
    downloadDeviceOption({ commit }, deviceoption) {
      commit('setwinpeLoading', true)
      downloadDeviceOption(deviceoption.id, deviceoption.detail)
        .then(response => {
          let blob = response.data
          FileSaver.saveAs(blob, deviceoption.name + '_v' + deviceoption.version + '.zip', true)
        })
        .catch(debug)
        .finally(() => commit('setwinpeLoading', false))
    },

    /* categories */
    getCategories({ commit }) {
      commit('setCategoriesLoading', true)
      getCategories()
        .then(response => commit('addCategories', response.data))
        .catch(debug)
        .finally(() => commit('setCategoriesLoading', false))
    },

    /* Device Properties */
    getDeviceProperties({ commit }) {
      commit('setDevicePropertiesLoading', true)
      getDeviceProperties()
        .then(response => commit('addDeviceProperties', response.data))
        .catch(debug)
        .finally(() => commit('setDevicePropertiesLoading', false))
    },
    addDeviceProperty({ commit }, deviceProperty) {
      addDeviceProperty(deviceProperty).then(response => commit('addDeviceProperty', response.data), debug)
    },
    editDeviceProperty({ commit }, deviceProperty) {
      editDeviceProperty(deviceProperty).then(response => commit('editDeviceProperty', response.data), debug)
    },
    getDevicePropCategory({ commit }) {
      commit('setDevicePropertiesLoading', true)
      getDevicePropCategory()
        .then(response => commit('addDevicePropCategory', response.data))
        .catch(debug)
        .finally(() => commit('setDevicePropertiesLoading', false))
    },

    /* Virtual Machines */
    createVirtualMachine({ commit }, payload) {
      commit('setVMLoadingValue', true)
      createVM(payload)
        .then(response => {
          commit('addVM', response.data)
        })
        .catch(() => {
          debug()
          commit('setVMLoadingValue', false)
        })
    },
    getVMs({ commit }, customerId) {
      getVMs(customerId)
        .then(response => commit('setVMs', response.data))
        .catch(debug)
    },

    /* Persons */
    addPerson({ commit }, person) {
      addPerson(person)
        .then(response => commit('addPerson', response.data))
        .catch(debug)
    },
    getCustomersUnemployedPersons({ commit }, customerId) {
      getCustomersUnemployedPersons(customerId)
        .then(response => commit('loadCustomersUnemployedPersons', response.data))
        .catch(debug)
    },
    addExpert({ commit }, person) {
      addExpert(person)
        .then(response => commit('addPerson', response.data))
        .catch(debug)
    },
    deletePerson({ commit }, personId) {
      deletePerson(personId).then(commit('deletePerson', personId)).catch(debug)
    },
    updatePerson(payload) {
      return editPerson(payload)
    },
    getCustomersPersons({ commit }, customerId) {
      getCustomersPersons(customerId)
        .then(response => commit('setPersons', response.data))
        .catch(debug)
    },

    /* Companies */
    addCompany({ commit }, company) {
      addCompany(company)
        .then(response => commit('addCompany', response.data))
        .catch(debug)
    },
    deleteCompany({ commit }, companyId) {
      deleteCompany(companyId)
        .then(response => commit('removeCompany', response.data.id))
        .catch(debug)
    },
    addMainCompany(company) {
      return addCompany(company)
    },
    getCustomersCompanies({ commit }, customerId) {
      getCustomersCompanies(customerId)
        .then(response => commit('setCustomersCompanies', response.data))
        .catch(debug)
    },
    updateMainCompany(data) {
      let companyId = data.mainCompanyId
      let payload = data.corporateData
      return updateMainCompany(companyId, payload)
    },
    updateCorporateData(data) {
      return updateGeneralData(impersonation.getCustomer().id, data)
    },
    // getWinPESAS ({commit}, customerId) {
    //   return getWinPESAS(customerId)
    // },

    /* Cloud Entry Points */
    getCustomersCEPs({ commit }, customerId) {
      getCEPs(customerId)
        .then(response => commit('setCEPs', response.data))
        .catch(err => console.error(err))
    },
    addCustomersCEP({ commit }, cep) {
      commit('addCEP', cep)
    },
    setCEPs({ commit }, ceps) {
      commit('setCEPs', ceps)
    },
    deleteCEP({ commit }, data) {
      deleteCEP(data.customerId, data.id)
        .then(response => commit('deleteCEP', response.data.id))
        .catch(debug)
    },
    updateCEP({ commit }, payload) {
      updateCEP(payload.customerId, payload.data)
        .then(response => commit('editCEP', response.data))
        .catch(debug)
    },

    // Storage Entry Points
    getSEPs({ commit }, customerId) {
      commit('setSEPsLoading', true)
      getCustomersSEPs(customerId)
        .then(response => commit('setSEPs', response.data))
        .catch(debug)
        .finally(() => commit('setSEPsLoading', false))
    },
    addSEP({ commit }, data) {
      commit('setSEPsLoading', true)
      addSEP(data.customerId, data.payload)
        .then(response => commit('addSEP', response.data))
        .catch(error => errorMessage(error.response))
        .finally(() => {
          commit('setSEPsLoading', false)
        })
    },
    deleteSEP({ commit }, data) {
      deleteSEP(data.customerId, data.sepId)
        .then(response => commit('deleteSEP', response.data))
        .catch(debug)
    },

    // RelaseMgmt files
    removeReleaseMgmtFile(fileName) {
      removeReleaseMgmtFile(fileName)
        .catch(debug)
    },

    // HardwareModels
    addHardwareModel({ commit }, payload) {
      addHardwareModel(payload)
        .then(response => commit('addHardwareModel', response.data))
        .catch(debug)
    },
    getHardwareModels({ commit }) {
      getHardwareModels()
        .then(response => {
          let hardwaremodels = response.data.hardwareModels
          commit('setHardwareModels', hardwaremodels)
        })
        .catch(debug)
    },
    editHardwareModel({ commit }, payload) {
      editHardwareModel(payload)
        .then(response => commit('editHardwareModel', response.data))
        .catch(debug)
    },
    deleteHardwareModel({ commit }, hardwareModelId) {
      deleteHardwareModel(hardwareModelId)
        .then(commit('deleteHardwareModel', hardwareModelId))
        .catch(debug)
    },

    // Drivers
    addDriver({ commit }, driver) {
      addDriver(driver)
        .then(response => {
          commit('addDriver', response.data)
        })
        .catch(debug)
    },
    editDriver({ commit }, driver) {
      editDriver(driver)
        .then(response => commit('editDriver', response.data))
        .catch(debug)
    },
    updateDriver({ commit }, driver) {
      updateDriver(driver)
        .then(response => commit('updateDriver', response.data))
        .catch(debug)
    },
    getDrivers({ commit }) {
      getDrivers()
        .then(response => commit('setDrivers', response.data.drivers))
        .catch(debug)
    },
    deleteDriver({ commit }, driverId) {
      deleteDriver(driverId)
        .then(commit('deleteDriver', driverId))
        .catch(debug)
    },

    // OS Models
    addOSModel({ commit }, osModel) {
      addOSModel(osModel)
        .then(response => {
          commit('addOSModel', response.data)
        })
        .catch(debug)
    },
    editOSModel({ commit }, osModel) {
      editOSModel(osModel)
        .then(response => {
          commit('editOSModel', response.data)
        })
        .catch(debug)
    },
    deleteOSModel({ commit }, osModelId) {
      deleteOSModel(osModelId)
        .then(() => {
          commit('deleteOSModel', osModelId)
        })
        .catch(debug)
    },
    getOSModels({ commit }) {
      getOSModels()
        .then(response => {
          commit('setOSModels', response.data.osModels)
        })
        .catch(debug)
    },

    // Asset Classes
    addAssetClass({ commit }, payload) {
      addAssetClass(payload)
        .then(response => commit('addAssetClass', response.data))
        .catch(debug)
    },
    getAssetClasses({ commit }, customerId) {
      getAssetClasses(customerId)
        .then(response => commit('setAssetClasses', response.data))
        .catch(debug)
    },

    // Asset Types
    addAssetType({ commit }, payload) {
      addAssetType(payload)
        .then(response => {
          commit('addAssetType', response.data)
        })
        .catch(debug)
    },
    getAssetTypes({ commit }, customerId) {
      getAssetTypes(customerId)
        .then(response => commit('setAssetTypes', response.data))
    },

    // Software Streams
    getSoftwareStreams({ commit }, customerId) {
      getSoftwareStreams(customerId)
        .then(response => {
          let streamArray = response.data
          streamArray.forEach(function (el) {
            if (el.icon !== null) {
              let currentIcon = null
              let currentIconId = el.icon.id
              getSoftwareIcon(currentIconId)
                .then(response => {
                  currentIcon = window.URL.createObjectURL(response.data)
                  el.icon = currentIcon
                })
            }
            commit('setSoftwareStreams', response.data)
          })
        })
        .catch(debug)
    },
    addSoftwareStream({ commit }, data) {
      addSoftwareStream(data)
        .then(response => commit('addSoftwareStream', response.data))
        .catch(debug)
    },
    editSoftwareStream({ commit }, data) {
      editSoftwareStream(data)
        .then(response => {
          let swStream = response.data
          if (swStream.icon !== null) {
            getSoftwareIcon(swStream.icon.id)
              .then(response1 => {
                let currentIcon = null
                currentIcon = window.URL.createObjectURL(response1.data)
                swStream.icon = currentIcon
              })
          }
          commit('editSoftwareStream', swStream)
        })
        .catch(debug)
    },
    setSoftwareToStream({ commit }, data) {
      setSoftwaresToStream(data.streamId, data.payload)
        .then(response => commit('updateSoftwares', response.data))
        .catch(debug)
    },
    deleteSoftwareStream({ commit }, streamId) {
      deleteSoftwareStream(streamId)
        .then(() => commit('deleteSoftwareStream', streamId))
        .catch(error => errorMessage(error.response))
    }
  }
})
