<template lang="html">
  <el-dialog
    v-model="showDialog">
  </el-dialog>
</template>

<script>
export default {
  name: "add-bios-dialog",
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
  },
  computed: {},
};
</script>

<style lang="css" scoped>
</style>
