export function getCustomers() {
  return window.axiosInstance.get('/customers')
}

export function getCustomer(customerId) {
  return window.axiosInstance.get(`/customers/${customerId}`)
}

export function addCustomer(payload) {
  console.log({ payload })
  let systemhouseId = payload.systemhouse
  console.log({ systemhouseId })
  return window.axiosInstance.post(`/systemhouses/${systemhouseId}/customers`, payload)
}

export function editCustomer(customer) {
  return window.axiosInstance.put(`/customers/${customer.id}`, customer)
}

export function deleteCustomer(customer) {
  return window.axiosInstance.delete(`/customers/${customer.id}`)
}

export function getVariables(customer) {
  return window.axiosInstance.get(`/customers/${customer.id}/variables`)
}

export function getDefaults(customer) {
  return window.axiosInstance.get(`/customers/${customer.id}/defaults`)
}

export function editDefault(customerId, defaultData) {
  return window.axiosInstance.put(`/customers/${customerId}/defaults/${defaultData.id}`, defaultData)
}

export function addVariable(customer, variable) {
  return window.axiosInstance.post(`/customers/${customer.id}/variables`, variable)
}

export function getMainCompanyAndExpert(customerId) {
  return window.axiosInstance.get(`/customers/mainCompany/${customerId}`)
}

export function getPersonsOfCustomer(customerId) {
  return window.axiosInstance.get(`/customers/${customerId}/persons`)
}

export function getCompaniesOfCustomer(customerId) {
  return window.axiosInstance.get(`/customers/${customerId}/companies`)
}

export function getLocationsOfCustomer(customerId) {
  return window.axiosInstance.get(`/customers/${customerId}/locations`)
}

export function updateGeneralData(customerId, payload) {
  return window.axiosInstance.put(`/customers/${customerId}/general`, payload)
}

export function addOrEditCustomCorporateParameter(customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/parameters`, payload)
}

export function deleteCustomParam(customerId, parameterId) {
  return window.axiosInstance.delete(`/customers/deleteParameter/${customerId}/${parameterId}`)
}

export function downloadIconOrBanner(fileId) {
  return window.axiosInstance.get(`/customers/iconOrBanner/${fileId}`, { responseType: 'blob' })
}

export function deleteIconOrBanner(fileId) {
  return window.axiosInstance.delete(`/customers/iconOrBanner/${fileId}`)
}

export function getCustomersClients(payload) {
  return window.axiosInstance.post(`/customers/getCustomersClients`, payload)
}

export function getWinPESAS(customerId) {
  return window.axiosInstance.get(`customers/getWinPESAS/${customerId}`)
}

export function downloadWinpe(url) {
  console.log({ url })
  const authHeader = window.axiosInstance.defaults.headers.common['Authorization']
  delete window.axiosInstance.defaults.headers.common['Authorization']
  window.axiosInstance.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
  const result = window.axiosInstance.get(url, { responseType: 'blob' })
  window.axiosInstance.defaults.headers.common['Authorization'] = authHeader
  return result
}

export function setSDSettings(payload) {
  return window.axiosInstance.post('/customers/customerSettings', payload)
}

export function setGeneralSettings(payload, customerId) {
  return window.axiosInstance.post(`customers/setGeneralSettings/${customerId}`, payload)
}

export function getGeneralSettings(customerId) {
  return window.axiosInstance.get(`customers/getGeneralSettings/${customerId}`)
}

export function getRegisterClientSAS() {
  return window.axiosInstance.get('customers/getRegisterClientSAS')
}

export function getOfficeConfig(customerId) {
  return window.axiosInstance.get(`customers/getOfficeConfig/${customerId}`)
}

export function setOfficeConfig(customerId, data) {
  return window.axiosInstance.post(`customers/setOfficeConfig/${customerId}`, data)
}

export function getSEPs(customerId) {
  return window.axiosInstance.get(`customers/getSEPs/${customerId}`)
}
