/* eslint-disable no-unused-vars */
import { debug, errorMessage, success } from './util'
import {
  addCustomer, addOrEditCustomCorporateParameter,
  deleteCustomer,
  editCustomer,
  getCompaniesOfCustomer,
  getCustomer,
  getCustomers,
  getLocationsOfCustomer,
  getMainCompanyAndExpert,
  getPersonsOfCustomer,
  deleteCustomParam,
  setSDSettings
} from '../../services/customers'
import { addCustomInventory, getCustomInventory } from '../../services/customWMI'
import _ from 'lodash'

export default {
  state: {
    customers: [],
    customerDetails: {},
    customersLoading: false
  },
  mutations: {
    setCustomersLoading(state, loading) {
      state.customersLoading = loading
    },
    addCustomers(state, customers) {
      state.customers = customers
    },
    addCustomer(state, customer) {
      state.customers.push(customer)
    },
    editCustomer(state, customer) {
      const id = _.findIndex(state.customers, { id: customer.id })
      _.extend(state.customers[id], customer)
    },
    deleteCustomer(state, customer) {
      const id = _.findIndex(state.customers, { id: customer.id })
      state.customers.splice(id, 1)
    },
    setCustomerDetails(state, customer) {
      state.customerDetails = customer
    },
    addCorporateParameter(state, parameter) {
      state.customerDetails.parameters.push(parameter)
    },
    editCorporateParameter(state, parameter) {
      for (let i = 0; i < state.customerDetails.parameters.length; i++) {
        if (state.customerDetails.parameters[i].id === parameter.id) {
          state.customerDetails.parameters.splice(i, 1, parameter)
          return
        }
      }
    },
    deleteCustomerParam(state, parameterId) {
      const index = _.findIndex(state.customerDetails.parameters, { id: parameterId })
      state.customerDetails.parameters.splice(index, 1)
    }
  },
  actions: {
    /* Customers */
    getCustomers({ commit }) {
      commit('setCustomersLoading', true)
      getCustomers()
        .then(response => commit('addCustomers', response.data))
        .catch(debug)
        .finally(() => commit('setCustomersLoading', false))
    },
    getCustomer({ commit }, customerId) {
      getCustomer(customerId)
        .then(response => commit('setCustomerDetails', response.data))
        .catch(debug)
    },
    addCustomer({ commit }, customer) {
      let result = addCustomer(customer).then(response => {
        commit('addCustomer', response.data)
      }).catch(debug)
      return result
    },
    editCustomer({ commit }, customer) {
      editCustomer(customer)
        .then(response => commit('editCustomer', response.data))
        .catch(err => {
          errorMessage(err.response)
        })
    },
    deleteCustomer({ commit }, customer) {
      deleteCustomer(customer).then(() => commit('deleteCustomer', customer), debug)
    },
    getMainCompanyAndExpert({ commit }, customerId) {
      return getMainCompanyAndExpert(customerId)
    },
    getCustomersPersons({ commit }, customerId) {
      return getPersonsOfCustomer(customerId)
    },
    returnCustomersCompanies({ commit }, customerId) {
      return getCompaniesOfCustomer(customerId)
    },
    returnCustomersLocations({ commit }, customerId) {
      return getLocationsOfCustomer(customerId)
    },
    addCustomInventory({ commit }, payload) {
      addCustomInventory(payload)
        .then(response => commit('addCustomInventory', response.data))
        .catch(debug)
    },
    getCustomInventory({ commit }, clientId) {
      getCustomInventory(clientId)
        .then(response => commit('setCustomInventory', response.data))
        .catch(debug)
    },
    addCustomCorporateParameter({ commit }, data) {
      addOrEditCustomCorporateParameter(data.customerId, data.parameter).then(response => commit(
        'addCorporateParameter', response.data), debug)
    },
    editCustomCorporateParameter({ commit }, data) {
      addOrEditCustomCorporateParameter(data.customerId, data.parameter)
        .then(response => commit('editCorporateParameter', response.data), debug)
    },
    deleteCustomParam({ commit }, payload) {
      deleteCustomParam(payload.customerId, payload.parameterId)
        .then(() => commit('deleteCustomerParam', payload.parameterId), debug)
    },
    setSDSettings({ commit }, payload) {
      setSDSettings(payload)
        .then(response => commit('editCustomer', response.data))
        .catch(debug)
        .finally(success())
    }
  },
  getters: {
    customersMap(state) {
      return state.customers.map(customer => {
        return { text: customer.name, value: customer.id }
      })
    }
  }
}
