export function addDriver (driver) {
  return window.axiosInstance.post('drivers', driver)
}

export function getDrivers () {
  return window.axiosInstance.get('drivers')
}

export function updateDriver (driver) {
  return window.axiosInstance.put('drivers', driver)
}

export function deleteDriver (driverId) {
  return window.axiosInstance.delete(`drivers/${driverId}`)
}

export function editDriver (driver) {
  return window.axiosInstance.post('drivers/edit', driver)
}

export function publishInShop (driver) {
  return window.axiosInstance.post('drivers/publishInShop', driver)
}
