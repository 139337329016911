<template>
  <el-dialog
    :title="title"
    v-model="showDialog"
    :before-close="onClose"
    fullscreen
  >
    <div class="container">
      <el-form
        :model="customer"
        :rules="customerRules"
        label-width="220px"
        ref="customerForm"
      >
        <el-form-item label="Customer name" prop="name">
          <el-input
            class="customerName"
            placeholder="Customer name"
            v-model="customer.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Systemhouse" prop="systemhouse" v-if="isAdmin">
          <el-select
            filterable
            placeholder="Select systemhouse"
            v-model="customer.systemhouse"
          >
            <el-option
              :key="systemhouse.id"
              :label="systemhouse.name"
              :value="systemhouse.id"
              v-for="systemhouse in systemhouses"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="WinPE Download Link" prop="winPELink">
          <el-input
            placeholder="WinPE Download Link"
            v-model="customer.winPEDownloadLink"
          ></el-input>
        </el-form-item>
        <div class="center">
          <el-button @click="submit" class="submit-btn" type="primary">
            <el-icon>
              <Plus />
            </el-icon>
            Add Customer
          </el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import Auth from "../../auth";
import { mapState } from "vuex";
import Permission from "../../mixins/Permission";
import CustomerValidation from "./CustomerValidation";
import _ from "lodash";

export default {
  name: "customer-add",
  mixins: [CustomerValidation, Permission],
  components: {},
  data() {
    return {
      activeNames: ["csdp-settings"],
      title: "Add Customer",
      showDialog: false,
      customer: {
        name: "",
        systemhouse: "",
        winPEDownloadLink: "",
        csdpRoot: "",
      },
    };
  },
  methods: {
    handleSyshouseChange(val) {
      console.log({ val });
    },
    handleChangeCollapseable(val) {
      this.activeNames = val;
    },
    onClose() {
      this.$refs.customerForm.resetFields();
      this.customer.iconLeft = { id: "", name: "" };
      this.customer.iconRight = { id: "", name: "" };
      this.customer.banner = { id: "", name: "" };
      this.customer.systemhouse = "";
      this.iconURLLeft = "";
      this.iconRightURL = "";
      this.bannerURL = "";
      this.showDialog = false;
    },

    open() {
      this.customer.systemhouse = "";
      this.showDialog = true;
    },
    submit() {
      if (!this.isAdmin) {
        const sh = JSON.parse(Auth.getAuth().systemhouse);
        this.customer.systemhouse = sh.id;
      }
      this.$refs.customerForm.validate((valid) => {
        if (valid) {
          let temp = _.cloneDeep(this.customer);
          this.$store.dispatch("addCustomer", temp);
          // .then((response) =>
          //   this.$store.commit("addCustomer", response.data)
          // );
          this.showDialog = false;
          this.$refs.customerForm.resetFields();
        } else {
          this.activeNames = ["csdp-settings"];
        }
      });
    },
  },
  computed: {
    ...mapState(["systemhouses"]),
    countries: {
      get() {
        return this.$store.state.countries;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style scoped>
.customerName {
  width: 300px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.avatar-uploader-icon {
  height: 100px;
  width: 100%;
}

i:before {
  position: relative;
  top: 30%;
}

.uploader-container-icon {
  width: 200px;
  height: 200px;
}

.uploader-container-banner {
  width: 500px;
  height: 200px;
}

.avatar {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  object-fit: fill;
}

.avatar-banner {
  margin: 0 auto;
  width: 500px;
  height: 100px;
  object-fit: fill;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.dialog {
  margin: 0 auto;
}

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: inline-block;
}

.submit-btn {
  margin-top: 10px;
  text-align: center;
}

/* form {
    padding: 2rem;
  } */

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
