<template>
  <el-dialog
    width="400px"
    title="Base Creation Status"
    v-model="showDialog"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div class="blackBackground">
      <div
        v-if="timeShowed !== ''"
        style="width: 100%; text-align: center; margin: 0 auto"
      >
        Please wait until the base is created. Estimated creation time:
        {{ timeShowed }}
      </div>
      <div style="margin: 10">
        <div style="width: 100%" class="flex">
          <span v-if="baseModel.resourceGroup !== undefined">{{
            "Creating Resource Group " + baseModel.resourceGroup.name + ":   "
          }}</span>
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-if="getComponentStatus('ResGrp') === 'loading'"
            src="../../../static/loading-gif.gif"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('ResGrp') === 'error'"
            src="../../../static/error.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('ResGrp') === 'success'"
            src="../../../static/success.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('ResGrp') === 'notStarted'"
            src="../../../static/notStarted.png"
          />
        </div>
        <div style="width: 100%" class="flex">
          <span>{{ "Creating Network: " }}</span>
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-if="getComponentStatus('VirNet') === 'loading'"
            src="../../../static/loading-gif.gif"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VirNet') === 'error'"
            src="../../../static/error.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VirNet') === 'success'"
            src="../../../static/success.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VirNet') === 'notStarted'"
            src="../../../static/notStarted.png"
          />
        </div>
        <div style="width: 100%" class="flex">
          <span v-if="baseModel.storageAccount !== undefined">{{
            "Creating Storage Account " + baseModel.storageAccount.name + ":   "
          }}</span>
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-if="getComponentStatus('StrgAcc') === 'loading'"
            src="../../../static/loading-gif.gif"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('StrgAcc') === 'error'"
            src="../../../static/error.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('StrgAcc') === 'success'"
            src="../../../static/success.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('StrgAcc') === 'notStarted'"
            src="../../../static/notStarted.png"
          />
        </div>
        <div style="width: 100%" class="flex">
          <span v-if="baseModel.vpn !== undefined">{{
            "Creating VPN " + baseModel.vpn.name + ":   "
          }}</span>
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-if="getComponentStatus('VPN') === 'loading'"
            src="../../../static/loading-gif.gif"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VPN') === 'error'"
            src="../../../static/error.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VPN') === 'success'"
            src="../../../static/success.png"
          />
          <img
            style="position: relative; float: right"
            width="20"
            height="20"
            v-else-if="getComponentStatus('VPN') === 'notStarted'"
            src="../../../static/notStarted.png"
          />
        </div>
        <!-- v-if="errorMessage.length > 0" -->
        <textarea
          style="
            width: 250px;
            height: 200px;
            margin-top: 20px;
            width: 100%;
            text-align: center;
            color: red;
            overflow: hidden;
          "
          v-if="errorMessage.length > 0"
          v-model="getErrorMessage"
        ></textarea>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  createResourceGrp,
  addBase,
  createVirtualNetwork,
  createVPN,
  createStorageAcc,
  addParameters,
  getBaseStatus,
} from "../../services/bases";
import impersonation from "../../impersonation";
import _ from "lodash";

export default {
  name: "base-add-status",
  data() {
    return {
      showDialog: false,
      baseModel: {},
      resourceGrpCreated: false,
      networkCreated: false,
      storageAccountCreated: false,
      vpnCreated: false,
      countDown: 1800,
      timeShowed: "",
      errorCreationResGrp: false,
      errorCreationNetwork: false,
      errorCreationStorageAcc: false,
      errorCreationVPN: false,
      errorMessage: "",
      refreshIntervalID: "",
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          var minutes = Math.floor(this.countDown / 60);
          var seconds = this.countDown - minutes * 60;
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          this.timeShowed = "" + minutes + ":" + seconds;
          this.countDownTimer();
        }, 1000);
      }
    },
    onClose() {
      if (this.refreshIntervalID !== "") {
        clearInterval(this.refreshIntervalID);
      }
    },
    openDialog(baseModel) {
      this.baseModel = baseModel;
      console.log(this.baseModel);
      this.showDialog = true;
      if (baseModel.id === undefined) {
        this.startBaseCreation();
      } else {
        this.refreshIntervalID = setInterval(
          () => this.getBaseStatus(this.baseModel),
          10000
        );
      }
    },
    getBaseStatus() {
      getBaseStatus(impersonation.getCustomer().id, this.baseModel.id)
        .then((response) => {
          this.baseModel.baseStatus = response.data;
        })
        .catch((err) => console.log({ err }));
    },
    startBaseCreation() {
      this.countDownTimer();
      setTimeout(this.createBase, 2000);
    },
    createBase() {
      let customerId = impersonation.getCustomer().id;
      let baseModel = _.cloneDeep(this.baseModel);
      addBase(customerId, baseModel)
        .then((response) => {
          console.log(this.$store.state);
          this.baseModel = response.data;
          this.getBaseStatus(this.baseModel);
          this.refreshIntervalID = setInterval(
            () => this.getBaseStatus(this.baseModel),
            10000
          );
        })
        .catch((error) => console.log({ error }))
        .finally(() => {
          baseModel = _.cloneDeep(this.baseModel);
        });
    },
    createResourceGroup(baseModel, customerId) {
      setTimeout(() => {
        createResourceGrp(customerId, baseModel)
          .then(() => {
            this.resourceGrpCreated = true;
          })
          .catch((error) => {
            this.errorMessage = this.errorMessage + error.response.data + "\n";
            this.errorCreationResGrp = true;
          })
          .finally(() => {
            if (this.resourceGrpCreated) {
              this.createVirtualNetwork(baseModel, customerId);
            }
          });
      }, 2000);
    },
    createVirtualNetwork(baseModel, customerId) {
      setTimeout(() => {
        createVirtualNetwork(customerId, baseModel)
          .then(() => {
            this.networkCreated = true;
          })
          .catch((error) => {
            this.errorMessage = this.errorMessage + error.response.data + "\n";
            this.errorCreationNetwork = true;
          })
          .finally(() => {
            this.createStorageAccount(baseModel, customerId);
          });
      }, 2000);
    },
    createStorageAccount(baseModel, customerId) {
      setTimeout(() => {
        createStorageAcc(customerId, baseModel)
          .then(() => {
            this.storageAccountCreated = true;
          })
          .catch((error) => {
            this.errorMessage = this.errorMessage + error.response.data + "\n";
            this.errorCreationStorageAcc = true;
          })
          .finally(() => {
            if (this.storageAccountCreated) {
              this.createVPN(baseModel, customerId);
            }
          });
      }, 2000);
    },
    createVPN(baseModel, customerId) {
      this.vpnCreationStarted = true;
      setTimeout(() => {
        createVPN(customerId, baseModel)
          .then(() => {
            this.vpnCreated = true;
          })
          .catch((error) => {
            this.errorMessage = this.errorMessage + error.response.data + "\n";
            this.errorCreationVPN = true;
          })
          .finally(() => {
            addParameters(customerId, baseModel);
          });
      }, 2000);
    },
    getComponentStatus(component) {
      if (
        this.baseModel.baseStatus === undefined ||
        this.baseModel.baseStatus === null
      ) {
        return "notStarted";
      }
      if (component === "ResGrp") {
        if (this.baseModel.baseStatus.resourceGroupStatus === null) {
          return "notStarted";
        } else {
          if (this.baseModel.baseStatus.resourceGroupStatus === "Creating") {
            return "loading";
          } else if (
            this.baseModel.baseStatus.resourceGroupStatus === "Created"
          ) {
            return "success";
          } else if (
            this.baseModel.baseStatus.resourceGroupStatus === "Error"
          ) {
            return "error";
          }
        }
      } else if (component === "VirNet") {
        if (this.baseModel.baseStatus.virtualNetworkStatus === null) {
          return "notStarted";
        } else {
          if (this.baseModel.baseStatus.virtualNetworkStatus === "Creating") {
            return "loading";
          } else if (
            this.baseModel.baseStatus.virtualNetworkStatus === "Created"
          ) {
            return "success";
          } else if (
            this.baseModel.baseStatus.virtualNetworkStatus === "Error"
          ) {
            return "error";
          }
        }
      } else if (component === "StrgAcc") {
        if (this.baseModel.baseStatus.storageAccountStatus === null) {
          return "notStarted";
        } else {
          if (this.baseModel.baseStatus.storageAccountStatus === "Creating") {
            return "loading";
          } else if (
            this.baseModel.baseStatus.storageAccountStatus === "Created"
          ) {
            return "success";
          } else if (
            this.baseModel.baseStatus.storageAccountStatus === "Error"
          ) {
            return "error";
          }
        }
      } else if (component === "VPN") {
        if (this.baseModel.baseStatus.vpnStatus === null) {
          return "notStarted";
        } else {
          if (this.baseModel.baseStatus.vpnStatus === "Creating") {
            return "loading";
          } else if (this.baseModel.baseStatus.vpnStatus === "Created") {
            return "success";
          } else if (this.baseModel.baseStatus.vpnStatus === "Error") {
            return "error";
          }
        }
      }
    },
  },
  computed: {
    getErrorMessage() {
      return "Errors: " + this.errorMessage;
    },
  },
};
</script>

<style lang="css" scoped>
.blackBackground {
  background-color: black;
  color: white;
  padding: 10px;
  width: 300;
  height: 300;
}

.imgContainer {
  width: 20;
  height: 20;
}
</style>
