<template>
  <el-dialog v-model="showDialog" title="Add system house">
    <el-form ref="form" :model="systemhouse" :rules="rules" label-width="180px">
      <el-form-item label="System house name" prop="name">
        <el-input
          v-model="systemhouse.name"
          placeholder="System house name"
        ></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(systemhouse)">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import SystemhouseValidation from "./SystemhouseValidation";

export default {
  name: "systemhouse-add",
  mixins: [SystemhouseValidation],
  data() {
    return {
      showDialog: false,
      systemhouse: {
        name: "",
      },
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    submit(systemhouse) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addSystemhouse", _.cloneDeep(systemhouse));
          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
  },
};
</script>
