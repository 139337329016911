<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Add Driver Model">
    {{sasURL}}
    <el-form :model="driverModel" ref="form" :rules="rules">
      <el-form-item label="Name" prop="driverName">
        <el-input
          placeholder="Please set the driver name"
          v-model="driverModel.driverName">
        </el-input>
      </el-form-item>
      <el-form-item label="Version" prop="version">
        <el-input
          placeholder="Please set the version"
          v-model="driverModel.version">
        </el-input>
      </el-form-item>
      <el-form-item label="Vendor" prop="vendor">
        <el-input
          placeholder="Please set the vendor name"
          v-model="driverModel.vendor">
        </el-input>
      </el-form-item>
      <el-form-item label="Vendor ID" prop="vendorId">
        <el-input
          placeholder="Please set the vendor ID"
          v-model="driverModel.vendorId">
        </el-input>
      </el-form-item>
      <el-form-item label="Valid Operating System" prop="validOS">
        <el-input
          placeholder="Please set the Operating System"
          v-model="driverModel.validOS">
        </el-input>
      </el-form-item>
      <el-form-item label="Release Date" prop="releaseDate">
        <el-date-picker
          v-model="driverModel.releaseDate"
          placeholder="Pick the release date"
          format="DD.MM.YYYY"
          @change="formatDate"
          type="date">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Valid Hardware Models" prop="validModels">
        <el-select multiple v-model="driverModel.validModels" placeholder="Select Hardware Models">
          <el-option
            v-for="hardware in hardwareModels"
            :key="hardware.id"
            :label="hardware.name"
            :value="hardware.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Device Name" prop="deviceName">
        <el-input
          placeholder="Please set the device name"
          v-model="driverModel.deviceName">
        </el-input>
      </el-form-item>
      <el-form-item label="Device Name Driver Manager" prop="deviceNameDriverManager">
        <el-input
          placeholder="Please set the device name in driver manager"
          v-model="driverModel.deviceNameDriverManager">
        </el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item label-position="left" label="Content" prop="content">
          <input
            id="fileUpload" type="file" @change="processFile($event, 'content')"/>
        </el-form-item>
        <el-progress
          v-if="showContentProgress"
          class="progress"
          type="circle"
          :percentage="calculateContentProgress('content')"
          :color="calculateContentProgress('content') === 100 ? '#4BB543' : '#ffae42'"
          :width="45">
        </el-progress>
      </div>
      <div class="flex">
        <el-form-item label-position="left" label="Readme File" prop="readMe">
          <input id="fileUpload" type="file" @change="processFile($event, 'readme')"/>
        </el-form-item>
        <el-progress
          class="progress"
          v-if="showReadmeProgress"
          type="circle"
          :percentage="calculateContentProgress('readme')"
          :width="45"
          :color="calculateContentProgress('readme') === 100 ? '#4BB543' : '#ffae42'">
        </el-progress>
        <el-input
          class="progress percentage"
          readonly>
        </el-input>
      </div>
      <el-button type="primary" @click="submit">Add Driver</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { getResourcesReleaseSASURL } from "../../services/releaseMgmtFiles";
// import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
const {
  BlobServiceClient,
  AnonymousCredential,
} = require("@azure/storage-blob");

export default {
  name: "add-driver-dialog",
  created() {
    getResourcesReleaseSASURL().then((response) => {
      this.sasURL = response.data.url;
    });
  },
  data() {
    let validateContent = (rule, value, callback) => {
      let vm = this.driverModel;
      if (
        vm.content === null ||
        vm.content.id === "" ||
        vm.content.name === ""
      ) {
        callback(new Error("Please add a file"));
      } else {
        callback();
      }
    };
    let validateReadMe = (rule, value, callback) => {
      let vm = this.driverModel;
      if (vm.readMe === null || vm.readMe.id === "" || vm.readMe.name === "") {
        callback(new Error("Please add a file"));
      } else {
        callback();
      }
    };
    let validateValidModels = (rule, value, callback) => {
      let vm = this.driverModel;
      if (vm.validModels === null || vm.validModels.length === 0) {
        callback(new Error("Please select min one hardware model"));
      } else {
        callback();
      }
    };
    return {
      completeContentSize: 0,
      completeReadmeSize: 0,
      uploadedBytesReadme: 0,
      uploadedBytesContent: 0,
      showContentProgress: false,
      showReadmeProgress: false,
      sasURL: "",
      uploadURL:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/tasks/upload/releaseMgmt",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      showDialog: false,
      rules: {
        driverName: [
          {
            required: true,
            message: "The driver name has to be set",
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: "The version has to be set",
            trigger: "blur",
          },
        ],
        vendor: [
          {
            required: true,
            message: "The vendor has to be set",
            trigger: "blur",
          },
        ],
        validOS: [
          {
            required: true,
            message: "The valid OS has to be set",
            trigger: "blur",
          },
        ],
        releaseDate: [
          {
            required: true,
            message: "The release date has to be set",
            trigger: "blur",
          },
        ],
        deviceName: [
          {
            required: true,
            message: "The device name has to be set",
            trigger: "blur",
          },
        ],
        deviceNameDriverManager: [
          {
            required: true,
            message: "The driver name of driver manager has to be set",
            trigger: "blur",
          },
        ],
        vendorId: [
          {
            required: true,
            message: "The vendor ID has to be set",
            trigger: "blur",
          },
        ],
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        readMe: [
          {
            validator: validateReadMe,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        validModels: [
          { validator: validateValidModels, trigger: ["blur", "change"] },
        ],
      },
      driverModel: {
        driverName: "",
        version: "",
        vendor: "",
        validOS: "",
        releaseDate: "",
        validModels: [],
        deviceName: "",
        deviceNameDriverManager: "",
        vendorId: "",
        content: { id: "", name: "" },
        readMe: { id: "", name: "" },
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addDriver", _.cloneDeep(this.driverModel));
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
    setContent(file) {
      if (file.length > 0) {
        this.driverModel.content = {
          id: file[0].response.id,
          name: file[0].response.name,
        };
      } else {
        this.driverModel.content = { id: "", name: "" };
      }
    },
    handleRemoveContent(a, fileList) {
      let fileName = a.name;
      this.$store.dispatch("removeReleaseMgmtFile", fileName);
      this.setContent(fileList);
    },
    handleSuccessContent(a, b, fileList) {
      this.setContent(fileList);
    },
    setReadMe(file) {
      if (file.length > 0) {
        this.driverModel.readMe = {
          id: file[0].response.id,
          name: file[0].response.name,
        };
      } else {
        this.driverModel.readMe = { id: "", name: "" };
      }
    },
    handleRemoveReadMe(a, fileList) {
      let fileName = a.name;
      this.$store.dispatch("removeReleaseMgmtFile", fileName);
      this.setReadMe(fileList);
    },
    handleSuccessReadMe(a, b, fileList) {
      this.setReadMe(fileList);
    },
    formatDate() {
      let date = new Date(Date.parse(this.driverModel.releaseDate));
      date = new Date(date - date.getTimezoneOffset() * 60000);
      this.driverModel.releaseDate = date.toUTCString();
    },
    async processFile(event, dataName) {
      var file = event.target.files[0];
      if (dataName === "content") {
        this.uploadedBytesContent = 0;
        this.completeContentSize = file.size;
      } else {
        this.uploadedBytesReadme = 0;
        this.completeReadmeSize = file.size;
      }
      const blobServiceClient = new BlobServiceClient(
        // When using AnonymousCredential, following url should include a valid SAS or support public access
        this.sasURL,
        new AnonymousCredential()
      );
      console.log("Containers:");
      for await (const container of blobServiceClient.listContainers()) {
        console.log(`- ${container.name}`);
      }
      const containerClient =
        blobServiceClient.getContainerClient("release-mgmt-files");
      const response = await containerClient.createIfNotExists();
      console.log({ response });
      if (dataName === "content") {
        this.showContentProgress = true;
      } else {
        this.showReadmeProgress = true;
      }
      // const pipeline = StorageURL.newPipeline(anonymousCredential);
      // const serviceURL = new ServiceURL(this.sasURL, pipeline);
      // const containerURL = ContainerURL.fromServiceURL(
      //   serviceURL,
      //   "release-mgmt-files"
      // );
      // const blobURL = BlobURL.fromContainerURL(containerURL, file.name);
      // const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

      // await uploadBrowserDataToBlockBlob(Aborter.none, file, blockBlobURL, {
      //   progress: (data) => {
      //     if (dataName === "content") {
      //       this.uploadedBytesContent = data.loadedBytes;
      //     } else {
      //       this.uploadedBytesReadme = data.loadedBytes;
      //     }
      //   },
      // });
      if (dataName === "content") {
        this.driverModel.content.name = file.name;
      } else if (dataName === "readme") {
        this.driverModel.readMe.name = file.name;
      }
      // this.uploadFileToAzure()
    },
    calculateContentProgress(dataName) {
      if (dataName === "content") {
        return (this.uploadedBytesContent / this.completeContentSize) * 100;
      } else {
        return (this.uploadedBytesReadme / this.completeReadmeSize) * 100;
      }
    },
    getProgressStatus(dataName) {
      if (dataName === "content") {
        if (this.completeContentSize === this.uploadedBytesContent) {
          return "success";
        } else {
          return "";
        }
      } else {
        if (this.completeContentSize === this.uploadedBytesContent) {
          return "success";
        } else {
          return "";
        }
      }
    },
  },
  computed: {
    hardwareModels() {
      return this.$store.state.hardwareModels;
    },
  },
};
</script>

<style lang="css" scoped>
.progress {
  margin: auto 0;
}

.percentage {
  width: 30px;
}

.flex {
  display: flex;
}
</style>
