export function getCustomersAzureBlobs (customerId) {
  return window.axiosInstance.get(`/azure-blobs/${customerId}`)
}

export function createAzureBlob (payload) {
  return window.axiosInstance.post(`/azure-blobs`, payload)
}

export function updateAzureBlob (payload) {
  return window.axiosInstance.put(`/azure-blobs`, payload)
}

export function deleteAzureBlob (azureBlobId) {
  return window.axiosInstance.delete(`/azure-blobs/${azureBlobId}`)
}
