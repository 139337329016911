export function getClients(customerId) {
  return window.axiosInstance.get(`/customers/${customerId}/clients`)
}

export function addClient(customerId, userId, clients) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${userId}`, clients)
}

export function editClient(customerId, clients) {
  return window.axiosInstance.put(`/customers/${customerId}/clients`, clients)
}

export function addClientSoftware(customerId, clientId, software) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${clientId}/software`, software)
}

export function getClientSoftware(customerId, clientId) {
  return window.axiosInstance.get(`/customers/${customerId}/clients/${clientId}/software`)
}

export function deleteClientSoftware(customerId, clientId, softwareId) {
  return window.axiosInstance.delete(`/customers/${customerId}/clients/${clientId}/software/${softwareId}`)
}

export function addCustomParameter(customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/add-parameter`, payload)
}

export function getDeviceParameters(customerId, clientId, payload) {
  return window.axiosInstance.get(`/customers/${customerId}/clients/${clientId}/get-parameters`, payload)
}

export function editDeviceParameter(customerId, clientId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/edit-parameter`, payload)
}

export function getDeviceParameterWithDefaultValues(customerId, clientId, optionId, isAdminOption) {
  return window.axiosInstance.get(`/customers/${customerId}/clients/${clientId}/options/${optionId}/parameter/${isAdminOption}`)
}

export function getDeviceInventory(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/inventory/${clientId}`)
}

export function exportInventoryAsCSV(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/export/csv/${clientId}`, { responseType: 'blob' })
}

export function exportInventoriesCSV(customerId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/export/csv`, { responseType: 'blob' })
}

export function deleteClient(customerId, clientId) {
  return window.axiosInstance.delete(`customers/${customerId}/clients/delete/${clientId}`)
}

export function getActivityLog(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/${clientId}/activityLog`)
}

export function setOSSettings(customerId, customerImageId, clientId, payload) {
  return window.axiosInstance.put(`customers/${customerId}/clients/osSettings/${customerImageId}/${clientId}`, payload)
}

export function getOSSettings(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/osSettings/${clientId}`)
}

export function getMACAddresses(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/getClientsMACAddresses/${clientId}`)
}

export function getInventoryFilesContent(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/getInventoryFileContents/${clientId}`)
}

export function peekAssignedTasks(customerId, clientId) {
  return window.axiosInstance.get(`customers/${customerId}/clients/${clientId}/getAssignedTasks`)
}