<template>
  <el-col :offset="1" :span="22">
    <template>
      <div class="container">
        <el-main>
          <div class="card">
            <el-row type="flex" class="header">
              <el-col class="bs-align-left" :span="8">
                <div style="white-space: nowrap">
                  <label> Create Asset Label(s)</label>
                </div>
              </el-col>
            </el-row>
            <!-- <p><hr style="height:2px"/></p> -->
            <el-row type="flex">
              <el-col :span="12">
                <el-form
                  ref="form"
                  :model="customer"
                  :rules="rules"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item label="Customer Name" prop="customerName">
                    {{ customerName }}
                  </el-form-item>
                  <el-form-item label="Amount  of IDs" prop="amount">
                    <el-input
                      class="input-style"
                      placeholder="Amount of IDs"
                      v-model="customer.amount"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="6" :offset="6">
                <div style="white-space: nowrap">
                  <el-button class="button_conf" type="primary" @click="submit"
                    >Create</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-main>
      </div>
    </template>
  </el-col>
</template>
<script>
import FilterMixin from "../../mixins/FilterMixin";
import _ from "lodash";
// import { mapState } from 'vuex'
import impersonation from "../../impersonation";

export default {
  name: "AssetLabel",
  mixins: [FilterMixin],
  components: {},
  created() {
    this.$store.dispatch("getCustomers");
    this.customer.id = impersonation.getCustomer().id;
  },
  data() {
    let validateAmount = (rule, value, callback) => {
      if (this.customer.amount === "") {
        callback(new Error("The amount has to be set!"));
      } else {
        callback();
      }
    };
    return {
      customer: {
        id: "",
        amount: "",
      },
      rules: {
        amount: [{ validator: validateAmount, trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addAssetLabels", _.cloneDeep(this.customer));
          this.$router.push({ name: "asset-management" });
        }
      });
    },
  },
  computed: {
    customerName() {
      return impersonation.getCustomer().name;
    },
    // ...mapState({customers: state => state.customer.customers})
  },
};
</script>

<style scoped>
.card {
  /*background-color: #e7e7e7;*/
  margin-top: 20px;
  margin-left: 20px;
}
.input-style {
  margin-bottom: 4px;
  border-radius: 20px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  color: #3c3c3c;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
label {
  *display: inline;
  display: inline-block;
  text-align: left;
  flex-direction: row;
  justify-content: flex-end;
  width: 120px;
}
input {
  display: inline-block;
  *display: inline;
}
p {
  text-align: left;
  margin-left: 20px;
}
</style>
