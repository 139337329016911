<template>
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <div>
          <h3>Hardware</h3>
          <el-row type="flex" class="header">
            <el-col :span="8" class="search">
              <el-input
                placeholder="Find hardware"
                v-model="searchHardware"
                autofocus
              >
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-circle-close clickable"
                  v-if="isSubsetHardware"
                  @click="clearSearchHardware"
                ></i> -->
              </el-input>
            </el-col>
            <el-col :span="8" class="border">
              <div class="results" v-if="isSubsetHardware">showing of</div>
            </el-col>
            <el-col :span="8" class="bs-align-right">
              <!-- icon="el-icon-plus" -->
              <el-button
                type="primary"
                class="button"
                @click="openHardwareAddDialog"
              >
                Add Hardware
              </el-button>
            </el-col>
          </el-row>
          <el-table :data="hardwareModels">
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column label="Vendor" prop="vendor"></el-table-column>
            <el-table-column
              label="Model Family"
              prop="modelFamily"
            ></el-table-column>
            <el-table-column
              label="Model Type"
              prop="modelType"
            ></el-table-column>
            <el-table-column label="Production Start" prop="productionStart">
              <template v-slot="scope">
                {{ getTimeTableContent(scope.row.productionStart) }}
              </template>
            </el-table-column>
            <el-table-column label="Production End" prop="productionEnd">
              <template v-slot="scope">
                {{ getTimeTableContent(scope.row.productionEnd) }}
              </template>
            </el-table-column>
            <el-table-column label="Actions">
              <template v-slot="scope">
                <actions-dropdown
                  :hideRetreatFromShop="true"
                  :entity="scope.row"
                  :edit="true"
                  :deleteItem="true"
                  @edit="editHardwareModel"
                  @delete="deleteHardwareModel"
                >
                </actions-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <hr />
        <div>
          <h3>Operating System</h3>
          <el-row type="flex" class="header">
            <el-col :span="8" class="search">
              <el-input placeholder="Find OS" v-model="searchOS" autofocus>
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-circle-close clickable"
                  v-if="isSubsetOS"
                  @click="clearSearchOS"
                ></i> -->
              </el-input>
            </el-col>
            <el-col :span="8" class="border">
              <div class="results" v-if="isSubsetOS">showing of</div>
            </el-col>
            <el-col :span="8" class="bs-align-right">
              <!-- icon="el-icon-plus" -->
              <el-button type="primary" class="button" @click="openOSAddDialog">
                Add Operating System
              </el-button>
            </el-col>
          </el-row>
          <el-table :data="osModels">
            <el-table-column prop="osName" label="Name"></el-table-column>
            <el-table-column prop="vendor" label="Vendor"></el-table-column>
            <el-table-column prop="osType" label="Type"></el-table-column>
            <el-table-column prop="version" label="Version"></el-table-column>
            <el-table-column
              prop="architecture"
              label="Architecture"
            ></el-table-column>
            <el-table-column prop="releaseDate" label="Release Date">
              <template v-slot="scope">
                {{ getTimeTableContent(scope.row.releaseDate) }}
              </template>
            </el-table-column>
            <el-table-column prop="supportEnd" label="Support End">
              <template v-slot="scope">
                {{ getTimeTableContent(scope.row.supportEnd) }}
              </template>
            </el-table-column>
            <el-table-column prop="content" label="Content file">
              <template v-slot="scope">
                {{ scope.row.content.name }}
              </template>
            </el-table-column>
            <el-table-column prop="hardwareModels" label="Hardware Models">
              <template v-slot="scope">
                {{ getHardwareModelTableContent(scope.row.hardwareModels) }}
              </template>
            </el-table-column>
            <el-table-column label="Actions">
              <template v-slot="scope">
                <actions-dropdown
                  :hideRetreatFromShop="true"
                  :entity="scope.row"
                  :edit="true"
                  :deleteItem="true"
                  @edit="editOSModel"
                  @delete="deleteOSModel"
                >
                </actions-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <hr />
        <div>
          <h3>Driver</h3>
          <el-row type="flex" class="header">
            <el-col :span="8" class="search">
              <el-input
                placeholder="Find Driver"
                v-model="searchDriver"
                autofocus
              >
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-circle-close clickable"
                  v-if="isSubsetDriver"
                  @click="clearSearchDriver"
                ></i> -->
              </el-input>
            </el-col>
            <el-col :span="8" class="border">
              <div class="results" v-if="isSubsetDriver">showing of</div>
            </el-col>
            <el-col :span="8" class="bs-align-right">
              <!-- icon="el-icon-plus" -->
              <el-button
                type="primary"
                class="button"
                @click="openDriverAddDialog"
              >
                Add Driver
              </el-button>
            </el-col>
          </el-row>
          <el-table :data="drivers">
            <el-table-column prop="driverName" label="Name"></el-table-column>
            <el-table-column prop="version" label="Version"></el-table-column>
            <el-table-column prop="vendor" label="Vendor"></el-table-column>
            <el-table-column
              prop="vendorId"
              label="Vendor ID"
            ></el-table-column>
            <el-table-column
              prop="deviceName"
              label="Device Name"
            ></el-table-column>
            <el-table-column
              prop="deviceNameDriverManager"
              label="Device Name (Driver Manager)"
            ></el-table-column>
            <el-table-column prop="driverName" label="Name"></el-table-column>
            <el-table-column prop="releaseDate" label="Release Date">
              <template v-slot="scope">
                {{ getTimeTableContent(scope.row.releaseDate) }}
              </template>
            </el-table-column>
            <el-table-column prop="content" label="Content file">
              <template v-slot="scope">
                {{ scope.row.content.name }}
              </template>
            </el-table-column>
            <el-table-column prop="readeMe" label="Readme file">
              <template v-slot="scope">
                {{ scope.row.readMe.name }}
              </template>
            </el-table-column>
            <el-table-column label="Actions">
              <template v-slot="scope">
                <actions-dropdown
                  :hideRetreatFromShop="true"
                  :entity="scope.row"
                  :edit="true"
                  :deleteItem="true"
                  @edit="editDriver"
                  @delete="deleteDriver"
                >
                </actions-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <hr />
        <div>
          <h3>BIOS</h3>
          <el-row type="flex" class="header">
            <el-col :span="8" class="search">
              <el-input placeholder="Find BIOS" v-model="searchBIOS" autofocus>
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-circle-close clickable"
                  v-if="isSubsetBIOS"
                  @click="clearSearchBIOS"
                ></i> -->
              </el-input>
            </el-col>
            <el-col :span="8" class="border">
              <div class="results" v-if="isSubsetBIOS">showing of</div>
            </el-col>
            <el-col :span="8" class="bs-align-right">
              <!-- icon="el-icon-plus" -->
              <el-button
                type="primary"
                class="button"
                @click="openBIOSAddDialog"
              >
                Add BIOS
              </el-button>
            </el-col>
          </el-row>
          <el-table></el-table>
        </div>
      </el-col>
    </el-row>
    <add-driver-dialog ref="addDriver"></add-driver-dialog>
    <add-hardware-model-dialog ref="addHardware"></add-hardware-model-dialog>
    <add-OS-dialog ref="addOS"></add-OS-dialog>
    <add-bios-dialog ref="addBIOS"></add-bios-dialog>
  </div>
</template>

<script>
import AddHardwareModelDialog from "../release-mgmt/AddHardwareModelDialog.vue";
import AddOSDialog from "../release-mgmt/AddOSDialog.vue";
import AddBiosDialog from "../release-mgmt/AddBiosDialog.vue";
import AddDriverDialog from "../release-mgmt/AddDriverDialog.vue";
import { mapState } from "vuex";
import ActionsDropdown from "../shared/ActionsDropdown";

export default {
  components: {
    AddHardwareModelDialog,
    AddDriverDialog,
    AddBiosDialog,
    AddOSDialog,
    ActionsDropdown,
  },
  name: "scripts-standby",
  beforeCreate() {
    // Register components that cannot be loaded right
    // this.$options.components.EditHardwareModel = require('../release-mgmt/EditHardwareModel.vue')
  },
  created() {
    this.$store.dispatch("getHardwareModels");
    this.$store.dispatch("getOSModels");
    this.$store.dispatch("getDrivers");
  },
  data() {
    return {
      searchHardware: "",
      searchOS: "",
      searchDriver: "",
      searchBIOS: "",
    };
  },
  methods: {
    openHardwareAddDialog() {
      this.$refs.addHardware.openDialog();
    },
    openBIOSAddDialog() {
      this.$refs.addBIOS.openDialog();
    },
    openDriverAddDialog() {
      this.$refs.addDriver.openDialog();
    },
    openOSAddDialog() {
      this.$refs.addOS.openDialog();
    },
    isSubsetHardware() {
      return true;
    },
    isSubsetDriver() {
      return true;
    },
    isSubsetOS() {
      return true;
    },
    isSubsetBIOS() {
      return true;
    },
    clearSearchHardware() {
      this.searchHardware = "";
    },
    clearSearchBIOS() {
      this.searchBIOS = "";
    },
    clearSearchDriver() {
      this.searchDriver = "";
    },
    clearSearchOS() {
      this.searchOS = "";
    },
    deleteHardwareModel(hardwareModel) {
      this.$store.dispatch("deleteHardwareModel", hardwareModel.id);
    },
    editHardwareModel() {
      // this.$refs.editHardware.openDialog(hardwareModel)
    },
    editDriver() {},
    deleteOSModel(osModel) {
      this.$store.dispatch("deleteOSModel", osModel.id);
    },
    editOSModel() {},
    deleteDriver(driver) {
      this.$store.dispatch("deleteDriver", driver.id);
    },
    getHardwareModelTableContent(hardwareModels) {
      var result = "";
      for (let i = 0; i < hardwareModels.length; i++) {
        if (i === hardwareModels.length - 1) {
          result += hardwareModels[i].name;
        } else {
          result += hardwareModels[i].name + " / ";
        }
      }
      return result;
    },
    getTimeTableContent(time) {
      return this.formatDate(time);
    },
    formatDate(time) {
      let date = new Date(Date.parse(time));
      date = new Date(date - date.getTimezoneOffset() * 60000);
      return date.toUTCString().substring(0, 16);
    },
  },
  computed: {
    ...mapState(["hardwareModels", "drivers", "osModels"]),
  },
};
</script>

<style lang="css" scoped>
.button {
  width: 200px;
}
</style>
