<template>
  <el-dialog v-model="showDialog" title="Edit user">
    <el-form ref="form" :model="user" :rules="rules" label-width="180px">
      {{ user }}
      <el-form-item prop="firstName" label="First Name">
        <el-row>
          <el-col :span="16">
            <el-input
              v-model="user.firstName"
              placeholder="First Name"
            ></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-switch
              v-if="enableActiveSwitch()"
              v-model="user.active"
              active-color="#13ce66"
              active-text="Active"
              inactive-text=""
              :width="70"
            >
            </el-switch>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="familyName" label="Family Name">
        <el-row>
          <el-col :span="16">
            <el-input
              v-model="user.familyName"
              placeholder="Family Name"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="email" label="Email">
        <el-input
          disabled
          v-model="user.email"
          placeholder="User email"
        ></el-input>
      </el-form-item>
      <el-form-item prop="systemhouse" label="Access Rights">
        <el-row>
          <el-col :span="16">
            <el-cascader
              ref="cascader"
              v-model="cascader"
              :props="{ expandTrigger: 'hover' }"
              :placeholder="userRole"
              :options="accessRights"
              @change="accessRightsChange"
              filterable
              style="width: 100%"
            >
            </el-cascader>
          </el-col>
          <el-col :span="8" style="text-align: right" v-if="admin">
            <el-checkbox v-model="user.admin">Administrator</el-checkbox>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit">Update</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import UserEditValidation from "./UserEditValidation";
import DisplayRole from "../../mixins/DisplayRole";
import Auth from "../../auth";

export default {
  name: "user-edit",
  mixins: [UserEditValidation, DisplayRole],
  data() {
    return {
      showDialog: false,
      user: {
        id: "",
        firstName: "",
        familyName: "",
        active: true,
        email: "",
        role: "",
        systemhouse: { id: "", name: "" },
        customer: "",
      },
      cascader: [],
      userRole: "",
    };
  },
  methods: {
    enableActiveSwitch() {
      if (this.user.email === Auth.getAuth().unique_name) {
        return false;
      } else {
        return true;
      }
    },
    open(user) {
      this.user.id = user.id;
      this.user.firstName = user.firstName;
      this.user.familyName = user.familyName;
      this.user.active = user.active;
      this.user.email = user.email;
      this.user.role = user.roles;
      this.user.admin = user.admin;
      if (user.systemhouse) {
        this.user.systemhouse = user.systemhouse;
      }
      if (user.customer) {
        this.user.customer = user.customer.id;
      }
      this.userRole = DisplayRole.methods.getRoleName(user);
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var payload = _.cloneDeep(this.user);
          this.$store.dispatch("editUser", payload);
          this.$refs.form.resetFields();
          this.cascader = [];
          this.showDialog = false;
        }
      });
    },
    accessRightsChange([role, entityId]) {
      let foundSysHouse = _.find(this.$store.state.systemhouses, {
        id: entityId,
      });
      console.log({ foundSysHouse });
      this.user.role = role;
      if (role === "systemhouse") {
        console.log({ entityId });
        this.user.systemhouse = _.find(this.$store.state.systemhouses, {
          id: entityId,
        });
        this.user.customer = "";
      }
      if (role === "customer") {
        this.user.systemhouse = _.find(this.$store.state.customer.customers, {
          id: entityId,
        }).systemhouse; // get sh of customer
        this.user.customer = entityId;
      }
    },
  },
  computed: {
    accessRights() {
      let accessRights = [];
      let systemhouses = [];
      if (Auth.isAdmin()) {
        systemhouses = this.$store.state.systemhouses.map((sh) => {
          return { value: sh.id, label: sh.name };
        });
        accessRights.push({
          value: "systemhouse",
          label: "Systemhouse Manager",
          children: systemhouses,
        });
      } else {
        const sh = JSON.parse(Auth.getAuth().systemhouse);
        systemhouses = [{ value: sh.id, label: sh.name }];
        accessRights.push({
          value: "systemhouse",
          label: "Systemhouse Manager",
          children: systemhouses,
        });
      }
      // accessRights.push({
      //   value: "customer",
      //   label: "Customer Manager",
      //   children: this.$store.state.customer.customers.map((customer) => {
      //     return { value: customer.id, label: customer.name };
      //   }),
      // });
      return accessRights;
    },
    admin() {
      return Auth.isAdmin();
    },
  },
};
</script>
