import store from '../../store/index'
import { checkResGrpNameAvailable } from '../../services/resourceGroups'
import impersonation from '../../impersonation'

async function rgName (_, value, callback) {
  var allowedChars = new Map()
  var seed = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789().-_'
  for (var i = 0; i < seed.length; i++) {
    allowedChars.set(seed.charAt(i))
  }
  for (i = 0; i < value.length; i++) {
    if (!allowedChars.has(value.charAt(i))) {
      callback('Name can only include alphanumeric chars, dots, underscores, dashes and parenthesis.')
      return
    }
  }
  if (store.state.currentSelectedSubscriptionId === '') {
    callback('Please select the subscription')
    return
  }
  await checkResGrpNameAvailable(impersonation.getCustomer().id, store.state.currentSelectedSubscriptionId, value)
    .then(response => {
      if (response.status === 200) {
        callback()
      }
    })
    .catch(() => callback('The resource group name is already taken'))
}

function rgNameEnd (_, value, callback) {
  if (!/^(.*)[a-zA-Z0-9_\-()]{1}$/.test(value)) {
    callback('Name can cannot end in a dot.')
    return
  }
  callback()
}

export default{
  name: 'resource-group-validation',
  data () {
    return {
      rules: {
        resourceGroupName: [
          { required: true, message: 'Please input resource group name', trigger: 'blur' },
          { validator: rgName, trigger: 'blur' },
          { validator: rgNameEnd, trigger: 'blur' }
        ],
        subscriptionId: [
          { required: true, message: 'Please select a subscription', trigger: 'change' }
        ],
        location: [
          { required: true, message: 'Please select a location', trigger: 'blur' }
        ]
      }
    }
  }
}
