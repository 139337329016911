import { ElMessage } from 'element-plus'

export const debug = function (response) {
  console.debug(response)
  ElMessage.error({ message: ' HTTP Error: Check console for more', showClose: true })
}

export const errorMessage = function (response) {
  ElMessage.error({ message: response.data, showClose: true, duration: 5000 })
}

export const errorMessageBlob = function (errorMessage) {
  ElMessage.error({ message: errorMessage, showClose: true })
}

export const info = function (message) {
  ElMessage.info({ message: message, duration: 10000 })
}

export const success = function () {
  ElMessage.success({ message: 'The action was successful', duration: 5000 })
}

export const successMessage = function (message) {
  ElMessage.success({ message: message, duration: 5000 })
}
