<template>
  <div>
    <el-dialog
      v-model="showDialog"
      title="Manage Customer Software Stream"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form :model="customerSoftwareStream" ref="form" :rules="rules">
        <el-form-item label="Application-Owner" prop="applicationOwnerId">
          <el-select
            v-model="customerSoftwareStream.applicationOwnerId"
            @change="changedPerson"
          >
            <el-option
              v-for="p in personsArray"
              :key="p.id"
              :value="p.id"
              :label="p.givenName + ' ' + p.surname"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Release-Plan" prop="releasePlanId">
          <el-select
            v-model="customerSoftwareStream.releasePlanId"
            @change="changedReleasePlan"
          >
            <el-option
              v-for="r in releasePlansArray"
              :key="r.id"
              :label="r.name"
              :value="r.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="isEnterpriseStream">
          <el-checkbox v-model="customerSoftwareStream.isEnterpriseStream"
            >Is Enterprise Stream</el-checkbox
          >
        </el-form-item>
        <el-form-item v-if="customerSoftwareStream.isEnterpriseStream">
          <el-switch
            v-model="clientOrServer"
            active-text="Client & Server"
            inactive-text="Client only"
            @change="changeClientOrServer"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          v-if="customerSoftwareStream.isEnterpriseStream"
          prop="priority"
          label="Priority Number"
        >
          <el-input
            type="number"
            style="width: 200px"
            placeholder="Please set the priority number"
            v-model="customerSoftwareStream.priority"
            min="0"
            max="999"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="updateSettings">
          <el-switch
            v-model="switchValue"
            active-text="Auto Update"
            inactive-text="Managed Release"
            @change="changeUpdateSettings"
          >
          </el-switch>
        </el-form-item>
        <el-button type="primary" @click="submit">Save</el-button>
      </el-form>
    </el-dialog>
    <add-release-plan ref="addReleasePlan"></add-release-plan>
    <add-person ref="addPerson" @newPersonName="setPerson"></add-person>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import AddReleasePlan from "../releasePlan/AddReleasePlan.vue";
import AddPersonDialog from "../customers/settings/dialogs/AddPersonDialog.vue";

export default {
  components: {
    "add-release-plan": AddReleasePlan,
    "add-person": AddPersonDialog,
  },
  data() {
    return {
      switchValue: false,
      showDialog: false,
      clientOrServer: false,
      customerSoftwareStream: {
        id: "",
        applicationOwnerId: "",
        releasePlanId: "",
        isEnterpriseStream: false,
        updateSettings: "",
        priority: 0,
        clientOrServer: "",
      },
      rules: {},
    };
  },
  methods: {
    setPerson(person) {
      this.customerSoftwareStream.applicationOwnerId = person.id;
    },
    changeUpdateSettings(val) {
      if (val) {
        this.customerSoftwareStream.updateSettings = "auto";
      } else {
        this.customerSoftwareStream.updateSettings = "managed";
      }
    },
    changeClientOrServer(val) {
      if (val) {
        this.customerSoftwareStream.clientOrServer = "Client & Server";
      } else {
        this.customerSoftwareStream.clientOrServer = "Client only";
      }
    },
    changedPerson(value) {
      if (value === "new") {
        this.$refs.addPerson.openDialog();
      } else {
        this.customerSoftwareStream.applicationOwnerId = value;
      }
    },
    changedReleasePlan(value) {
      if (value === "new") {
        this.$refs.addReleasePlan.openDialog();
      } else {
        this.customerSoftwareStream.releasePlanId = value;
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    openDialog(stream) {
      this.customerSoftwareStream.id = stream.id;
      if (stream.priority !== null) {
        this.customerSoftwareStream.priority = stream.priority;
      }
      if (stream.applicationOwnerId !== null) {
        this.customerSoftwareStream.applicationOwnerId =
          stream.applicationOwnerId;
      }
      if (stream.releasePlanId !== null) {
        this.customerSoftwareStream.releasePlanId = stream.releasePlanId;
      }
      this.customerSoftwareStream.isEnterpriseStream =
        stream.isEnterpriseStream;
      this.customerSoftwareStream.updateSettings = stream.updateSettings;
      if (stream.updateSettings === "managed") {
        this.switchValue = false;
      } else {
        this.switchValue = true;
      }
      if (stream.clientOrServer === null) {
        this.clientOrServer = true;
        this.customerSoftwareStream.clientOrServer = "Client & Server";
      } else {
        this.customerSoftwareStream.clientOrServer = stream.clientOrServer;
        if (this.customerSoftwareStream.clientOrServer === "Client & Server") {
          this.clientOrServer = true;
        } else {
          this.clientOrServer = false;
        }
      }
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = {
            streamId: this.customerSoftwareStream.id,
            customerId: impersonation.getCustomer().id,
            data: {
              applicationOwnerId:
                this.customerSoftwareStream.applicationOwnerId,
              releasePlanId: this.customerSoftwareStream.releasePlanId,
              isEnterpriseStream:
                this.customerSoftwareStream.isEnterpriseStream,
              updateSettings: this.customerSoftwareStream.updateSettings,
              priority: this.customerSoftwareStream.priority,
              clientOrServer: this.customerSoftwareStream.clientOrServer,
            },
          };
          this.$store.dispatch("editCustomerSoftwareStream", payload);
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    ...mapState(["persons", "releasePlans"]),
    releasePlansArray() {
      let result = _.cloneDeep(this.releasePlans);
      result.push({ id: "new", name: "Add new Release-Plan" });
      return result;
    },
    personsArray() {
      let result = _.cloneDeep(this.persons);
      result.push({ id: "new", givenName: "Add new Person", surname: "" });
      return result;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
