<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Report a bug"
    :close-on-click-modal="false">
    <el-form
      ref="form"
      :model="bugReport"
      :rules="rules">
      <el-form-item prop="subject" label="Subject">
        <el-input v-model="bugReport.subject"></el-input>
      </el-form-item>
      <el-form-item prop="message" label="Message">
        <el-input
          v-model="bugReport.message"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10}">
        </el-input>
      </el-form-item>
      <div style="text-align: center; margin: 0 auto">
        <el-button type="primary" @click="submit">Report Bug</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import Auth from "../auth";
import { reportBug } from "../services/users";
import { ElMessage } from "element-plus";

export default {
  name: "bug-report",
  data() {
    return {
      showDialog: false,
      bugReport: {
        subject: "",
        message: "",
        userId: Auth.getAuth().id,
      },
      rules: {
        subject: [
          {
            required: true,
            message: "Please set the subject",
            trigger: "blur",
          },
        ],
        message: [
          {
            required: true,
            message: "Please set the message",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          reportBug(_.cloneDeep(this.bugReport))
            .then((response) => {
              if (response.status === 200) {
                ElMessage.success({
                  message:
                    "The bug was successfully reported to the Bitstream team!",
                  duration: 3000,
                });
              }
            })
            .catch((error) => {
              ElMessage.error({
                message:
                  "The bug could not be reported. Please contact the Bitstream support: support@bitstream.de",
                showClose: true,
              });
              console.log({ error });
            });
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
