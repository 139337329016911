<template>
  <div>
    <el-dropdown @command="handleCommand" class="action-dropdown">
      <span class="el-dropdown-link">
        Actions
        <el-icon class="el-icon--right"><CaretBottom /></el-icon>
      </span>
      <!-- slot="dropdown" -->
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-if="execute" command="execute">
            Execute
          </el-dropdown-item>
          <!-- :disabled="!this._events.edit" -->
          <el-dropdown-item v-if="edit" command="edit">
            Edit <el-icon class="el-icon--right"><Edit /></el-icon>
          </el-dropdown-item>
          <!-- :hidden="!this._events.datasheet" -->
          <el-dropdown-item v-if="datasheet" command="datasheet">
            Details <el-icon class="el-icon--right"><Tickets /></el-icon>
          </el-dropdown-item>
          <!-- :hidden="!this._events.clientdatasheet" -->
          <el-dropdown-item v-if="clientdatasheet" command="clientdatasheet">
            Inventory <el-icon class="el-icon--right"><Tickets /></el-icon>
          </el-dropdown-item>
          <!-- :hidden="!this._events.parameters" -->
          <!-- <i class="el-icon-setting context-menu-item"></i> -->
          <el-dropdown-item v-if="parameters" command="parameters">
            Client Parameters</el-dropdown-item
          >
          <!-- :hidden="!this._events.software" -->
          <!-- <i class="el-icon-s-platform context-menu-item"></i> -->
          <el-dropdown-item v-if="software" command="software">
            Software</el-dropdown-item
          >
          <!-- :hidden="!this._events.deviceoptions" -->
          <!-- <i class="el-icon-set-up context-menu-item"></i> -->
          <el-dropdown-item v-if="deviceoptions" command="deviceoptions">
            Device Options</el-dropdown-item
          >
          <!-- :hidden="!this._events.assign" -->
          <el-dropdown-item v-if="assign" command="assign"
            >Assign</el-dropdown-item
          >
          <!-- :hidden="!this._events.downloadFiles" -->
          <!-- <i class="el-icon-download"></i> -->
          <el-dropdown-item v-if="downloadFiles" command="downloadFiles">
            Download Files</el-dropdown-item
          >
          <!-- :hidden="!this._events.details" -->
          <el-dropdown-item v-if="details" command="details"
            >Details</el-dropdown-item
          >
          <!-- :hidden="!this._events.download" -->
          <!-- <i class="el-icon-download context-menu-icon"></i> -->
          <el-dropdown-item v-if="download" command="download">
            Download</el-dropdown-item
          >
          <!-- :hidden="!this._events.version" -->
          <!-- <i class="el-icon-plus context-menu-icon"></i>  -->
          <el-dropdown-item v-if="version" command="version">
            Version</el-dropdown-item
          >
          <!-- :hidden="!this._events.export" -->
          <!-- <i class="el-icon-copy-document context-menu-icon"></i> -->
          <el-dropdown-item v-if="exportData" command="export">
            Export</el-dropdown-item
          >
          <!-- :hidden="!this._events.activityLog" -->
          <!-- <i class="el-icon-reading context-menu-icon"></i> -->
          <el-dropdown-item v-if="activityLog" command="activityLog">
            Activity Log</el-dropdown-item
          >
          <!-- :hidden="!this._events.createSAS" -->
          <!-- <i class="el-icon-key"></i> -->
          <el-dropdown-item v-if="createSAS" command="createSAS">
            Create SAS keys</el-dropdown-item
          >
          <!-- :hidden="!this._events.manageSoftware" -->
          <!-- <i class="el-icon-edit-outline"></i> -->
          <el-dropdown-item v-if="manageSoftware" command="manageSoftware"
            >Versions</el-dropdown-item
          >
          <!-- :hidden="!this._events.showCustomerSoftware" -->
          <!-- <i class="el-icon-edit-outline"></i> -->
          <el-dropdown-item
            v-if="showCustomerSoftware"
            command="showCustomerSoftware"
          >
            Show Software Packages</el-dropdown-item
          >
          <!-- :hidden="this.hidePublishInShop || !this._events.publishInShop" -->
          <!-- <i class="el-icon-money"></i> -->
          <el-dropdown-item v-if="publishInShop" command="publishInShop">
            Publish in Shop</el-dropdown-item
          >
          <!-- :hidden="this.hideRetreatFromShop || !this._events.retreatFromShop" -->
          <!-- <i class="el-icon-document-delete"></i> -->
          <el-dropdown-item
            v-if="!hideRetreatFromShop"
            command="retreatFromShop"
          >
            Retreat from Shop</el-dropdown-item
          >
          <!-- :hidden="!this._events.showDrivers" -->
          <!-- <i class="el-icon-truck"></i> -->
          <el-dropdown-item v-if="showDrivers" command="showDrivers">
            Show Needed Drivers</el-dropdown-item
          >
          <!-- :hidden="!this._events.manageSettingsFiles" -->
          <!-- <i class="el-icon-s-cooperation"></i> -->
          <el-dropdown-item
            v-if="manageSettingsFiles"
            command="manageSettingsFiles"
          >
            Manage Settings Files</el-dropdown-item
          >
          <!-- :hidden="!this._events.osSettings" -->
          <!-- <i class="el-icon-s-management"></i> -->
          <el-dropdown-item v-if="osSettings" command="osSettings"
            >OS Installation</el-dropdown-item
          >
          <!-- <i class="el-icon-delete context-menu-icon"></i> -->
          <el-dropdown-item v-if="deleteItem" command="delete">
            Delete
          </el-dropdown-item>
          <!-- :hidden="this.hideActivateSW || !this._events.activateSW" -->
          <!-- <i class="el-icon-document-checked"></i> -->
          <el-dropdown-item v-if="activateSW" command="activateSW">
            Set Active
          </el-dropdown-item>
          <!-- :hidden="this.hideOutdateSW || !this._events.outdateSW" -->
          <!-- <i class="el-icon-document-remove"></i> -->
          <el-dropdown-item v-if="outdateSW" command="outdateSW">
            Set Outdated</el-dropdown-item
          >
          <!-- :hidden="!this._events.showRevisionNr" -->
          <!-- <i class="el-icon-tickets"></i> -->
          <el-dropdown-item v-if="showRevisionNr" command="showRevisionNr">
            Show Revision Messages</el-dropdown-item
          >
          <!-- :hidden="!this._events.addImages" -->
          <!-- <i class="el-icon-document-add"></i> -->
          <el-dropdown-item v-if="addImages" command="addImages">
            Add Images</el-dropdown-item
          >
          <!-- :hidden="!this._events.showStreamImages" -->
          <!-- <i class="el-icon-document-copy"></i> -->
          <el-dropdown-item v-if="showStreamImages" command="showStreamImages"
            >Show Images</el-dropdown-item
          >
          <!-- :hidden="!this._events.showCustomerImages" -->
          <!-- <i class="el-icon-document-copy"></i> -->
          <el-dropdown-item
            v-if="showCustomerImages"
            command="showCustomerImages"
            >Show Images</el-dropdown-item
          >
          <!-- :hidden="!this._events.osStreamSettings" -->
          <!-- <i class="el-icon-setting"></i> -->
          <el-dropdown-item v-if="osStreamSettings" command="osStreamSettings"
            >OS Stream Settings</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  emits: [
    "edit",
    "entity",
    "delete",
    "datasheet",
    "software",
    "deviceoptions",
    "clientdatasheet",
    "assign",
    "details",
    "download",
    "version",
    "export",
    "activityLog",
    "createSAS",
    "manageSoftware",
    "showCustomerSoftware",
    "downloadFiles",
    "publishInShop",
    "retreatFromShop",
    "showDrivers",
    "hideRetreatFromShop",
    "hidePublishInShop",
    "manageSettingsFiles",
    "hideOutdateSW",
    "hideActivateSW",
    "activateSW",
    "outdateSW",
    "showRevisionNr",
    "addImages",
    "showStreamImages",
    "showCustomerImages",
    "osSettings",
    "osStreamSettings",
    "parameters",
  ],
  name: "actions-dropdown",
  props: {
    edit: Boolean,
    entity: Object,
    deleteItem: Boolean,
    datasheet: Boolean,
    software: Boolean,
    deviceoptions: Boolean,
    clientdatasheet: Boolean,
    assign: Boolean,
    details: Boolean,
    download: Boolean,
    version: Boolean,
    exportData: Boolean,
    activityLog: Boolean,
    createSAS: Boolean,
    manageSoftware: Boolean,
    showCustomerSoftware: Boolean,
    downloadFiles: Boolean,
    publishInShop: Boolean,
    retreatFromShop: Boolean,
    showDrivers: Boolean,
    hideRetreatFromShop: Boolean,
    hidePublishInShop: Boolean,
    manageSettingsFiles: Boolean,
    hideOutdateSW: Boolean,
    hideActivateSW: Boolean,
    activateSW: Boolean,
    outdateSW: Boolean,
    showRevisionNr: Boolean,
    addImages: Boolean,
    showStreamImages: Boolean,
    showCustomerImages: Boolean,
    osSettings: Boolean,
    osStreamSettings: Boolean,
    execute: Boolean,
    parameters: Boolean,
  },
  methods: {
    handleCommand(command) {
      if (command === "execute") {
        this.$emit("execute", this.entity);
      }
      if (command === "edit") {
        this.$emit("edit", this.entity);
      }
      if (command === "delete") {
        this.$emit("delete", this.entity);
      }
      if (command === "datasheet") {
        this.$emit("datasheet", this.entity);
      }
      if (command === "clientdatasheet") {
        this.$emit("clientdatasheet", this.entity);
      }
      if (command === "parameters") {
        this.$emit("parameters", this.entity);
      }
      if (command === "software") {
        this.$emit("software", this.entity);
      }
      if (command === "deviceoptions") {
        this.$emit("deviceoptions", this.entity);
      }
      if (command === "assign") {
        this.$emit("assign", this.entity);
      }
      if (command === "details") {
        this.$emit("details", this.entity);
      }
      if (command === "version") {
        this.$emit("version", this.entity);
      }
      if (command === "download") {
        this.$emit("download", this.entity);
      }
      if (command === "export") {
        this.$emit("export", this.entity);
      }
      if (command === "activityLog") {
        this.$emit("activityLog", this.entity);
      }
      if (command === "createSAS") {
        this.$emit("createSAS", this.entity);
      }
      if (command === "manageSoftware") {
        this.$emit("manageSoftware", this.entity);
      }
      if (command === "showCustomerSoftware") {
        this.$emit("showCustomerSoftware", this.entity);
      }
      if (command === "downloadFiles") {
        this.$emit("downloadFiles", this.entity);
      }
      if (command === "publishInShop") {
        this.$emit("publishInShop", this.entity);
      }
      if (command === "retreatFromShop") {
        this.$emit("retreatFromShop", this.entity);
      }
      if (command === "showDrivers") {
        this.$emit("showDrivers", this.entity);
      }
      if (command === "manageSettingsFiles") {
        this.$emit("manageSettingsFiles", this.entity);
      }
      if (command === "activateSW") {
        this.$emit("activateSW", this.entity);
      }
      if (command === "outdateSW") {
        this.$emit("outdateSW", this.entity);
      }
      if (command === "showRevisionNr") {
        this.$emit("showRevisionNr", this.entity);
      }
      if (command === "addImages") {
        this.$emit("addImages", this.entity);
      }
      if (command === "showStreamImages") {
        this.$emit("showStreamImages", this.entity);
      }
      if (command === "showCustomerImages") {
        this.$emit("showCustomerImages", this.entity);
      }
      if (command === "osSettings") {
        this.$emit("osSettings", this.entity);
      }
      if (command === "osStreamSettings") {
        this.$emit("osStreamSettings", this.entity);
      }
    },
  },
};
</script>

<style>
.el-table tr.current-row .action-dropdown,
.el-table tr:hover .action-dropdown {
  opacity: 1;
}
</style>
