export function addHardwareModel(payload) {
  return window.axiosInstance.post('hardwareModels', payload)
}

export function getHardwareModels() {
  return window.axiosInstance.get('hardwareModels')
}

export function editHardwareModel(payload) {
  return window.axiosInstance.put('hardwareModels', payload)
}

export function deleteHardwareModel(hardwareModelId) {
  return window.axiosInstance.delete(`hardwareModels/${hardwareModelId}`)
}
