<template>
  <el-dialog
    v-model="showDialog"
    title="Add software"
    :close-on-click-modal="false"
    :before-close="handleClose"
    width="75%"
  >
    <el-form :model="software" label-width="220px" ref="form">
      <el-button
        @click="changeAccordion"
        size="small"
        style="margin-bottom: 5px"
        type="primary"
      >
        {{ label }}</el-button
      >
      <el-collapse v-model="activeNames">
        <el-collapse-item title="General" name="general">
          <el-form-item label="Name" prop="name" :rules="rulesSoftware.name">
            <el-input
              disabled
              v-model="software.name"
              placeholder="Software name"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="App Version"
            prop="version"
            :rules="rulesSoftware.version"
          >
            <el-input
              v-model="software.version"
              placeholder="Version"
              @change="versionChanged"
            ></el-input>
          </el-form-item>
          <el-form-item label="Type" prop="type" :rules="rulesSoftware.type">
            <el-select disabled v-model="software.type">
              <el-option v-for="t in types" :key="t" :label="t" :value="t">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Running Context"
            prop="runningContext"
            :rules="rulesSoftware.runningContext"
          >
            <el-select
              placeholder="Please select the running context"
              v-model="software.runningContext"
              @change="setTaskRC"
            >
              <el-option
                v-for="rc in runningContexts"
                :key="rc"
                :label="rc"
                :value="rc"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Package-Status"
            prop="status"
            :rules="rulesSoftware.status"
          >
            <el-select v-model="software.status" filterable>
              <el-option v-for="s in status" :key="s" :label="s" :value="s">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Systemhouses">
            <el-select
              v-model="software.systemhouses"
              @change="getSystemhousesCustomers"
              multiple
              filterable
            >
              <el-option
                v-for="sys in systemhouses"
                :key="sys.id"
                :value="sys.id"
                :label="sys.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="software.systemhouses.length !== 0"
            label="Customers"
          >
            <el-select
              v-model="software.customers"
              filterable
              multiple
              @change="getCustomersClients"
            >
              <el-option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
                :label="customer.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="software.customers.length !== 0" label="Clients">
            <el-select v-model="software.clients" multiple filterable>
              <el-option
                v-for="client in clients"
                :key="client.id"
                :label="client.name"
                :value="client.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Package Size (MB)">
            <el-input
              v-model="software.packageSize"
              placeholder="Please set the package size"
            ></el-input>
          </el-form-item>
          <el-form-item label="Estimated Installation Time (sec)">
            <el-input
              v-model="software.installationTime"
              placeholder="Please set the estimated installation time"
            ></el-input>
          </el-form-item>
          <el-form-item label="Prerequisites/Dependencies">
            <el-input
              :autosize="{ minRows: 5 }"
              placeholder="Prerequisites/Dependencies"
              type="textarea"
              v-model="software.prerequisites"
            ></el-input>
          </el-form-item>
          <el-form-item label="Vendor Release Date">
            <el-input
              placeholder="Please set the vendor release date"
              v-model="software.vendorReleaseDate"
            ></el-input>
          </el-form-item>
          <el-form-item label="Compliancy Rule">
            <el-input
              :autosize="{ minRows: 5 }"
              placeholder="Compliancy Rule"
              type="textarea"
              v-model="software.compliancyRule"
            ></el-input>
          </el-form-item>
          <el-form-item label="SHA256 Checksum">
            <!-- TODO: Validation for numbers and letters -->
            <el-input
              placeholder="SHA256 Checksum"
              v-model="software.checksum"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Dedicated Download Link"
            prop="dedicatedDownloadLink"
          >
            <el-input v-model="software.dedicatedDownloadLink"></el-input>
          </el-form-item>
          <el-form-item
            v-if="software.version !== ''"
            label="Next Software Version"
            prop="nextSoftwareId"
          >
            <el-select v-model="software.nextSoftwareId">
              <el-option
                v-for="next in nextSW"
                :key="next.id"
                :value="next.id"
                :label="next.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="software.version !== ''"
            label="Previous Software Version"
            prop="prevSoftwareId"
          >
            <el-select
              v-model="software.prevSoftwareId"
              @change="loadSmallerVersions"
            >
              <el-option
                v-for="prev in prevSW"
                :key="prev.id"
                :value="prev.id"
                :label="prev.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="software.prevSoftwareId !== ''"
            label="Minimal Software Version"
            prop="minimalSoftwareId"
          >
            <el-select v-model="software.minimalSoftwareId">
              <el-option
                v-for="min in minimalSW"
                :key="min.id"
                :value="min.id"
                :label="min.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item
          v-if="software.type !== 'Task'"
          name="ruleApplicability"
          title="Rule Applicability"
        >
          <el-form
            :model="ruleApplicabilityModel"
            ref="formRuleApplicability"
            :rules="rulesApplicability"
          >
            <el-form-item prop="osType">
              <el-checkbox-group
                v-model="checkedOSType"
                @change="onChangeOSType"
                :max="1"
              >
                <el-checkbox label="Windows"></el-checkbox>
                <el-checkbox disabled label="Linux"></el-checkbox>
                <el-checkbox disabled label="MacOS"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item v-if="isWindows" prop="osVersions">
              <el-checkbox-group
                v-model="osVersionNames"
                @change="onChangeVersionName"
              >
                <el-checkbox
                  v-for="versionName in windowsVersionNames"
                  :label="versionName"
                  :key="versionName"
                >
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <div style="display: flex">
              <el-form-item v-if="isWindows10" prop="win10Versions">
                <el-checkbox
                  label="All Win10 Versions"
                  v-model="software.allWin10Versions"
                ></el-checkbox>
                <el-checkbox-group
                  :disabled="software.allWin10Versions ? true : false"
                  v-model="selectedWin10Versions"
                  @change="onChangeWin10Versions"
                >
                  <div
                    v-for="version in win10Versions"
                    :key="version"
                    style="display: block"
                  >
                    <el-checkbox :label="version" :key="version"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item v-if="win11Selected" prop="win11Versions">
                <el-checkbox
                  label="All Win11 Versions"
                  v-model="software.allWin11Versions"
                ></el-checkbox>
                <el-checkbox-group
                  :disabled="software.allWin11Versions ? true : false"
                  v-model="selectedWin11Versions"
                  @change="onChangeWin11Versions"
                >
                  <div
                    v-for="version in win11Versions"
                    :key="version"
                    style="display: block"
                  >
                    <el-checkbox :label="version" :key="version"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <el-form-item label="Architecture" prop="architecture">
              <el-checkbox-group
                :disabled="win11Selected ? true : false"
                v-model="selectedArchitectureApplicability"
                @change="onChangeArchitectureApplicability"
              >
                <el-checkbox label="64bit"></el-checkbox>
                <el-checkbox label="32bit"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item
          v-if="software.type !== 'Task'"
          name="ruleDetection"
          title="Rule Detection"
        >
          <el-form
            :model="ruleDetectionModel"
            ref="formRuleDetection"
            :rules="rulesDetection"
          >
            <el-form-item label="Type" prop="type">
              <el-select
                v-model="ruleDetectionModel.type"
                placeholder="Rule type"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="this.ruleDetectionModel.type === 'fileExists'">
              <el-form-item label="Path" prop="path">
                <el-input
                  v-model="ruleDetectionModel.path"
                  placeholder="Path"
                ></el-input>
              </el-form-item>
              <el-form-item prop="checkVersionNr">
                <el-checkbox
                  disabled
                  v-model="ruleDetectionModel.checkVersionNr"
                  >Check Version Number</el-checkbox
                >
              </el-form-item>
              <el-form-item label="Check Version Nr" prop="versionNr">
                <el-input
                  v-model="ruleDetectionModel.versionNr"
                  placeholder="Version Number"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              v-if="this.ruleDetectionModel.type === 'script'"
              label="File"
              prop="data"
            >
              <upload-file
                :id="id"
                :action="uploadUrl"
                :file-list="files"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              ></upload-file>
            </el-form-item>
            <el-form-item>
              <span>
                Succession
                <el-switch v-model="ruleDetectionModel.successon"></el-switch>
              </span>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="taskInstall" title="Task Install">
          <task-input
            :visibleName="false"
            :setName="getSoftwareName"
            id="taskInstall"
            prop="taskInstall"
            :value="software.taskInstall"
            :enableContext="software.type === 'Task' ? true : false"
            :enableNewWindow="software.type === 'Task' ? true : false"
          >
          </task-input>
        </el-collapse-item>
        <el-collapse-item name="taskUninstall" title="Task Uninstall">
          <el-form-item>
            <span style="float: right">
              Disabled
              <el-switch
                @change="togglelabel('taskuninstall')"
                v-model="taskuninstall.rule"
              >
              </el-switch>
              Enabled
            </span>
          </el-form-item>
          <task-input
            :addrule="taskuninstall.rule"
            id="taskuninstall"
            prop="taskUninstall"
            v-if="taskuninstall.status === 'Disable'"
            v-model="software.taskUninstall"
          ></task-input>
        </el-collapse-item>
        <el-collapse-item name="taskUpdate" title="Task Update">
          <el-form-item>
            <span style="float: right">
              Disabled
              <el-switch
                @change="togglelabel('taskupdate')"
                v-model="taskupdate.rule"
              >
              </el-switch>
              Enabled
            </span>
          </el-form-item>
          <task-input
            :addrule="taskupdate.rule"
            id="taskupdate"
            prop="taskUpdate"
            v-if="taskupdate.status === 'Disable'"
            v-model="software.taskUpdate"
          ></task-input>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <template v-slot:footer>
      <el-button @click="submit(software)" type="primary">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { defaultModel } from "./SoftwareAddModel";
import TaskInput from "../tasks/TaskInput";
import { mapState } from "vuex";
import { getSoftwareRules } from "../../services/rules";
import {
  getSystemhousesCustomers,
  getSyshouseUserCustomers,
} from "../../services/systemhouses";
import { getCustomersClients } from "../../services/customers";
import { getNextSoftware, getPrevSoftware } from "../../services/softwares";
import Auth from "../../auth";
import UploadFile from "../shared/UploadFile.vue";

export default {
  emits: ["refresh"],
  name: "software-add",
  components: {
    TaskInput,
    UploadFile,
  },
  // mixins: [ SoftwareValidation ],
  data() {
    let validateData = (rule, value, callback) => {
      let vm = this.value;
      if (vm.data === null || vm.data.length === 0) {
        callback(new Error("Please upload a file"));
      } else {
        callback();
      }
    };
    let validateArchitectureApplicability = (rule, value, callback) => {
      if (this.selectedArchitectureApplicability.length === 0) {
        callback(new Error("Please select at least one architecture type"));
      } else {
        callback();
      }
    };
    let validateArchitectureDetection = (rule, value, callback) => {
      if (this.selectedArchitectureDetection.length === 0) {
        callback(new Error("Please select at least one architecture type"));
      } else {
        callback();
      }
    };
    let validateOSTypeApplicability = (rule, value, callback) => {
      if (this.checkedOSType.length === 0) {
        callback(new Error("Please select at least one OS type"));
      } else {
        callback();
      }
    };
    let validateOSVersionApplicability = (rule, value, callback) => {
      if (this.osVersionNames.length === 0) {
        callback(new Error("Please select at least one Windows OS type"));
      } else {
        callback();
      }
    };
    let validateWin10VersionsApplicability = (rule, value, callback) => {
      if (this.software.allWin10Versions) {
        callback();
      }
      if (this.selectedWin10Versions.length === 0) {
        callback(new Error("Please select at least on Windows 10 version"));
      } else {
        callback();
      }
    };
    let validateWin11VersionsApplicability = (rule, value, callback) => {
      if (this.software.allWin11Versions) {
        callback();
      }
      if (this.selectedWin11Versions.length === 0) {
        callback(new Error("Please select at least on Windows 11 version"));
      } else {
        callback();
      }
    };
    let validateVersionNr = (rule, value, callback) => {
      let exp = /^\d+/;
      let array = this.ruleDetectionModel.versionNr.split(".");
      if (array.length === 1) {
        this.ruleDetectionModel.versionNr =
          this.ruleDetectionModel.versionNr + ".0.0.0";
        array = this.ruleDetectionModel.versionNr.split(".");
      }
      if (array.length === 4) {
        for (let i = 0; i < 4; i++) {
          // eslint-disable-next-line no-empty
          if (exp.test(array[i])) {
          } else {
            callback(
              "Please set the version number with 4 digits like x.x.x.x"
            );
            break;
          }
        }
        callback();
      } else {
        callback("The version number has to have 4 digits");
      }
    };
    let validateVersion = (rule, value, callback) => {
      let exp = /^(\d+.)+\d+/;
      if (this.software.version[this.software.version.length - 1] === ".") {
        callback(new Error("The version number must not end with a dot"));
      } else {
        var expressionTestResult = exp.test(this.software.version);
        if (expressionTestResult) {
          callback();
        } else {
          callback("The version number format is not supported");
        }
      }
    };
    return {
      selectedWin11Versions: ["21H2", "22H2"],
      win11Selected: false,
      installationTypeChecked: ["Powershell Script"],
      types: ["Software", "Task"],
      prevSW: [],
      nextSW: [],
      minimalSW: [],
      customers: [],
      clients: [],
      runningContexts: ["Run in service", "Run in user context"],
      status: ["Creation", "Test", "Acceptance", "Pilot", "Active", "Outdated"],
      showDialog: false,
      checkedOSType: ["Windows"],
      osVersionNames: [
        "Win 7",
        "Win 8",
        "Win 10",
        "Win 11",
        "Win Server 2016",
        "Win Server 2019",
        "Win Server 2022",
      ],
      selectedWin10Versions: [
        "1709",
        "1803",
        "1809",
        "1903",
        "1909",
        "2004",
        "20H2",
        "21H1",
        "21H2",
        "22H2",
      ],
      selectedArchitectureApplicability: ["64bit"],
      selectedArchitectureDetection: ["64bit"],
      activeNames: ["general"],
      model: _.clone(defaultModel),
      ruleDetectionModel: {
        id: "",
        type: "fileExists",
        architecture: ["64bit"],
        successon: false,
        data: null,
        versionNr: "0.0.0.1",
        checkVersionNr: true,
        path: "",
      },
      ruleApplicabilityModel: {
        id: "",
        type: "fileExists",
        architecture: ["64bit"],
        successon: true,
        data: null,
        versionNr: "",
        checkVersionNr: false,
        osType: "Windows",
        osVersionNames: [
          "Win 7",
          "Win 8",
          "Win 10",
          "Win 11",
          "Win Server 2016",
          "Win Server 2019",
          "Win Server 2022",
        ],
        win10Versions: [
          "1709",
          "1803",
          "1809",
          "1903",
          "1909",
          "2004",
          "20H2",
          "21H1",
          "21H2",
          "22H2",
        ],
        win11Versions: ["21H2", "22H2"],
        path: "%windir%\\win.ini",
      },
      windowsVersionNames: [
        "Win 7",
        "Win 8",
        "Win 10",
        "Win 11",
        "Win Server 2016",
        "Win Server 2019",
        "Win Server 2022",
      ],
      win10Versions: [
        "1709",
        "1803",
        "1809",
        "1903",
        "1909",
        "2004",
        "20H2",
        "21H1",
        "21H2",
        "22H2",
      ],
      win11Versions: ["21H2", "22H2"],
      isWindows: true,
      isWindows10: true,
      options: [
        {
          value: "script",
          label: "Script",
        },
        {
          value: "fileExists",
          label: "File exists",
        },
      ],
      windowsOptions: [
        {
          value: "win7",
          label: "Windows 7",
        },
        {
          value: "win8",
          label: "Windows 8",
        },
        {
          value: "win10",
          label: "Windows 10",
        },
      ],
      rulesDetection: {
        data: [
          {
            validator: validateData,
            type: "array",
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the type",
            trigger: ["change", "blur"],
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: ["blur", "change"],
          },
        ],
        architecture: [
          {
            required: true,
            validator: validateArchitectureDetection,
            type: "array",
            message: "Please select at least one architecture type",
            trigger: "change",
          },
        ],
        path: [
          { required: true, message: "Please set the path", trigger: "blur" },
        ],
        versionNr: [
          {
            required: true,
            message: "Please set the version number",
            trigger: "blur",
          },
          { validator: validateVersionNr, trigger: "blur" },
        ],
      },
      rulesApplicability: {
        data: [
          {
            validator: validateData,
            type: "array",
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the type",
            trigger: ["change", "blur"],
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: ["blur", "change"],
          },
        ],
        architecture: [
          {
            required: true,
            validator: validateArchitectureApplicability,
            type: "array",
            message: "Please select at least one architecture type",
            trigger: "change",
          },
        ],
        path: [
          { required: true, message: "Please set the path", trigger: "blur" },
        ],
        osType: [
          {
            required: true,
            validator: validateOSTypeApplicability,
            type: "array",
            message: "Please select at least one OS type",
            trigger: "change",
          },
        ],
        osVersions: [
          {
            required: true,
            validator: validateOSVersionApplicability,
            type: "array",
            message: "Please select at least one OS version",
            trigger: "change",
          },
        ],
        win10Versions: [
          {
            required: true,
            validator: validateWin10VersionsApplicability,
            type: "array",
            message: "Please select at least one Win 10 version",
            trigger: "change",
          },
        ],
        win11Versions: [
          {
            required: true,
            validator: validateWin11VersionsApplicability,
            type: "array",
            message: "Please select at least one Win 11 version",
            trigger: "change",
          },
        ],
      },
      software: {
        allWin10Versions: true,
        allWin11Versions: false,
        dedicatedDownloadLink: "",
        installationType: "",
        prevSoftwareId: "",
        nextSoftwareId: "",
        minimalSoftwareId: "",
        streamId: "",
        runningContext: "Run in service",
        systemhouses: [],
        customers: [],
        clients: [],
        type: "",
        website: "",
        downloadLink: "",
        status: "Creation",
        name: "",
        descriptionShort: "",
        description: "",
        vendor: "",
        version: "",
        language: "not definded",
        architecture: "x64",
        installationTime: "",
        packageSize: "",
        prerequisites: "",
        vendorReleaseDate: "",
        gnuLicence: false,
        compliancyRule: "",
        checksum: "",
        icon: {
          id: "",
          name: "",
        },
        ruleApplicability: {
          id: "",
          name: "",
          type: "",
          architecture: "",
          successon: true,
          data: null,
          versionNr: "",
          checkVersionNr: false,
          osType: "",
          osVersionNames: [],
          win10Versions: [],
        },
        ruleDetection: {
          id: "",
          name: "",
          type: "",
          architecture: "",
          successon: true,
          data: null,
          versionNr: "",
          checkVersionNr: false,
          path: "",
        },
        taskInstall: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
          executionContext: "",
          visibility: "",
          restartRequired: false,
          runningContext: "Run in service",
          installationType: "Powershell Script",
          returnCodes: [],
          restartBehavior: "",
          uninstallCommand: "",
          intuneFile: { id: "", guid: "", name: "" },
        },
        taskUninstall: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
        },
        taskUpdate: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
        },
      },
      label: "Expand all",
      taskuninstall: {
        status: "Enable",
        rule: false,
      },
      taskupdate: {
        status: "Enable",
        rule: false,
      },
      rulesSoftware: {
        name: [
          {
            required: true,
            message: "Please input software name",
            trigger: "blur",
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: "blur",
          },
        ],
        version: [
          { validator: validateVersion, required: true, trigger: "blur" },
        ],
        ruleApplicabilityId: [
          {
            required: true,
            message: "Please select the applicability rule",
            trigger: "change",
          },
        ],
        ruleDetectionId: [
          {
            required: true,
            message: "Please select the detection rule",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select the status",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the software type",
            trigger: "change",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please select the architecture",
            trigger: "change",
          },
        ],
        runningContext: [
          {
            required: true,
            message: "Please select the running context",
            trigger: "change",
          },
        ],
        publishInShop: [
          {
            required: true,
            message:
              "Please select if you want to publish this software in the customer shops",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    onChangeWin11Versions(value) {
      this.ruleApplicabilityModel.win11Versions = value;
    },
    getSyshouseUserData() {
      getSyshouseUserCustomers()
        .then((response) => {
          this.$store.state.systemhouses = [response.data];
        })
        .catch((err) => console.error({ err }));
    },
    changedInstalltionType() {
      this.software.installationType = this.installationTypeChecked[0];
    },
    loadSmallerVersions(value) {
      if (this.software.ruleDetection !== null) {
        let prevSWPackage = this.prevSW.find((el) => el.id === value);
        this.software.ruleDetection = prevSWPackage.ruleDetection;
        this.ruleDetectionModel = prevSWPackage.ruleDetection;
        this.ruleDetectionModel.versionNr = this.software.version;
        this.software.ruleDetection.version = this.software.version;
        let prevVersion = prevSWPackage.version;
        prevVersion = prevVersion.split(".");
        let result = [];
        for (let i = 0; i < this.prevSW.length; i++) {
          let currentVersion = this.prevSW[i].version.split(".");
          if (currentVersion.length === prevVersion.length) {
            if (Number(currentVersion[i]) < Number(prevVersion[i])) {
              result.push(this.prevSW[i]);
            } else if (Number(currentVersion[i]) === Number(prevVersion[i])) {
              if (i === this.prevSW.length - 1) {
                result.push(this.prevSW[i]);
              } else {
                continue;
              }
            } else {
              break;
            }
          }
        }
        this.minimalSW = result;
      }
    },
    versionChanged(value) {
      var splitByDot = value.split(".");
      if (splitByDot.length == 1) {
        value = value + ".0";
        this.software.version = value;
      }
      getNextSoftware(this.software.streamId, value).then((response) => {
        this.nextSW = response.data;
      });
      getPrevSoftware(this.software.streamId, value).then((response) => {
        this.prevSW = response.data;
      });
    },
    setTaskRC(val) {
      this.software.taskInstall.runningContext = val;
    },
    getCustomersClients(value) {
      getCustomersClients(value).then((response) => {
        this.clients = response.data;
      });
    },
    getSystemhousesCustomers(value) {
      getSystemhousesCustomers(value).then((response) => {
        this.customers = response.data;
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      if (this.software.type !== "Task") {
        this.$refs.formRuleDetection.resetFields();
        this.$refs.formRuleApplicability.resetFields();
      }
      this.showDialog = false;
    },
    onChangeArchitectureApplicability(value) {
      this.ruleApplicabilityModel.architecture = value;
    },
    onChangeArchitectureDetection(value) {
      this.ruleDetectionModel.architecture = value;
    },
    onChangeWin10Versions(value) {
      this.ruleApplicabilityModel.win10Versions = value;
    },
    onChangeVersionName(value) {
      this.ruleApplicabilityModel.osVersionNames = value;
      var found11 = this.ruleApplicabilityModel.osVersionNames.find(
        (x) => x === "Win 11"
      );
      if (found11 === "Win 11") {
        this.win11Selected = true;
        this.software.allWin11Versions = true;
        this.ruleApplicabilityModel.architecture = ["64bit"];
      } else {
        this.win11Selected = false;
      }
      var found10 = this.ruleApplicabilityModel.osVersionNames.find(
        (x) => x === "Win 10"
      );
      if (found10 === "Win 10") {
        this.isWindows10 = true;
      } else {
        this.isWindows10 = false;
      }
    },
    onChangeOSType(value) {
      this.ruleApplicabilityModel.osType = value[0];
      if (value.length !== 0 && value[0] === "Windows") {
        this.isWindows = true;
      } else {
        this.isWindows = false;
      }
    },
    compare(a, b) {
      if (
        parseInt(a.version.replaceAll(".", "")) <
        parseInt(b.version.replaceAll(".", ""))
      ) {
        return 1;
      } else {
        return -1;
      }
    },
    open(software, streamId, streamName, streamType) {
      this.win11Selected = true;
      let stream = this.softwareStreams.filter((x) => x.id === streamId);
      if (stream.length > 0) {
        stream = stream[0];
      }
      let latestSoftware = stream.streamMembers.sort(this.compare)[0];
      if (latestSoftware !== undefined) {
        if (
          this.software.ruleDetection !== null &&
          latestSoftware.ruleDetection !== null
        ) {
          this.software.ruleDetection.path = latestSoftware.ruleDetection.path;
          this.ruleDetectionModel.path = latestSoftware.ruleDetection.path;
        }
      }
      this.software.taskInstall.name = streamName;
      this.software.name = streamName;
      this.software.type = streamType;
      this.software.streamId = streamId;
      this.software.allWin11Versions = true;
      if (software !== null) {
        // Get primitive data
        this.software.runningContext = software.runningContext;
        this.software.name = software.name;
        this.software.type = software.type;
        this.software.website = software.website;
        this.software.downloadLink = software.downloadLink;
        this.software.description = software.description;
        this.software.descriptionShort = software.descriptionShort;
        this.software.vendor = software.vendor;
        this.software.version = software.version;
        this.software.language = software.language;
        this.software.architecture = software.architecture;
        this.software.installationTime = software.installationTime;
        this.software.packageSize = software.packageSize;
        this.software.prerequisites = software.prerequisites;
        this.software.vendorReleaseDate = software.vendorReleaseDate;
        this.software.compliancyRule = software.compliancyRule;
        this.software.checksum = software.checksum;
        this.software.allWin11Versions = software.allWin11Versions;
        if (software.systemhouses.length > 0) {
          this.software.systemhouses = [];
          software.systemhouses.forEach((el) => {
            this.software.systemhouses.push(el.systemhouseId);
          });
          this.getSystemhousesCustomers(this.software.systemhouses);
        }
        if (software.customers.length > 0) {
          this.software.customers = [];
          software.customers.forEach((el) => {
            this.software.customers.push(el.customerId);
          });
          this.getCustomersClients(this.software.customers);
        }
        if (software.clients.length > 0) {
          this.software.clients = [];
          software.clients.forEach((el) => {
            this.software.clients.push(el.clientId);
          });
        }

        // Set complex data
        if (software.ruleDetection !== null) {
          this.ruleDetectionModel.type = software.ruleDetection.type.name;
          this.ruleDetectionModel.path = software.ruleDetection.path;
          this.ruleDetectionModel.versionNr = software.ruleDetection.versionNr;
        }
        if (software.ruleApplicability !== null) {
          this.ruleApplicabilityModel.osType =
            software.ruleApplicability.osType;
          if (software.ruleApplicability.osType != null) {
            this.checkedOSType = [software.ruleApplicability.osType];
            if (software.ruleApplicability.osType === "Windows") {
              this.isWindows = true;
            }
          }
          var fetchedArchitecture = [];
          software.ruleApplicability.architecture.forEach((el) => {
            fetchedArchitecture.push(el.version);
          });
          this.selectedArchitectureApplicability = fetchedArchitecture;
          this.ruleApplicabilityModel.architecture = fetchedArchitecture;
          var fetchedOsVersionNames = [];
          software.ruleApplicability.osVersionNames.forEach((el) => {
            fetchedOsVersionNames.push(el.version);
          });
          this.osVersionNames = fetchedOsVersionNames;
          this.ruleApplicabilityModel.osVersionNames = fetchedOsVersionNames;
          var fetchedWin10Versions = [];
          software.ruleApplicability.win10Versions.forEach((el) => {
            fetchedWin10Versions.push(el.version);
          });
          // var fetchedWin11Versions = []
          // software.ruleApplicability.win11Versions.forEach((el, i) => {
          //   fetchedWin11Versions.push(el.version)
          // })
          // console.log({fetchedWin11Versions})
          this.selectedWin10Versions = fetchedWin10Versions;
          // this.selectedWin11Versions = fetchedWin11Versions
          this.ruleApplicabilityModel.win10Versions = fetchedWin10Versions;
          var foundWin10 = this.osVersionNames.find((x) => x === "Win 10");
          if (foundWin10 === "Win 10") {
            this.isWindows10 = true;
          }
          // var foundWin11 = this.osVersionNames.find(x => x === 'Win 11')
          // console.log({foundWin11})
          // if (foundWin11 === 'Win 11') {
          //   this.win11Selected = true
          // }
        }
        this.software.taskInstall.commandline =
          software.taskInstall.commandline;
        this.software.taskInstall.useShellExecute =
          software.taskInstall.useShellExecute;
        this.software.taskInstall.visibility = software.taskInstall.visibility;
        this.software.taskInstall.executionContext =
          software.taskInstall.executionContext;
        this.software.taskInstall.restartRequired =
          software.taskInstall.restartRequired;
      } else {
        // Get systemhouses depending if user is systemhouse
        if (Auth.hasPermission("systemhouse")) {
          this.getSyshouseUserData();
        }
      }
      this.showDialog = true;
    },
    getSoftwareRules(software) {
      getSoftwareRules(software.id)
        .then((result) => {
          this.software.ruleDetectionId = result.data.ruleDetection.id;
          this.software.ruleApplicabilityId = result.data.ruleApplicability.id;
        })
        .catch((error) =>
          console.error("The rules could not be loaded: " + error.data)
        );
    },
    openClone(software) {
      this.software = software;
      this.getSoftwareRules(software);
      this.showDialog = true;
    },
    submit(software) {
      if (software.taskInstall.returnCodes.length != 0) {
        software.taskInstall.returnCodes =
          software.taskInstall.returnCodes.filter(
            (x) => x.code != "" && x.value != ""
          );
      }
      this.$refs.form.validate((validForm) => {
        if (software.type !== "Task") {
          this.$refs.formRuleApplicability.validate((validApplicability) => {
            this.$refs.formRuleDetection.validate((validDetection) => {
              if (validForm && validApplicability && validDetection) {
                if (this.taskupdate.status === "Enable")
                  delete software.taskUpdate;
                if (this.taskuninstall.status === "Enable")
                  delete software.taskUninstall;
                this.software.ruleDetection = this.ruleDetectionModel;
                this.software.ruleApplicability = this.ruleApplicabilityModel;
                var payload = _.cloneDeep(this.software);
                this.$store.dispatch("addSoftware", payload);
                this.showDialog = false;
                this.$emit("refresh");
                this.activeNames = ["general"];
                this.$refs.form.resetFields();
                this.$refs.formRuleDetection.resetFields();
                this.$refs.formRuleApplicability.resetFields();
              } else {
                this.activeNames = [
                  "general",
                  "ruleApplicability",
                  "ruleDetection",
                  "taskInstall",
                  "taskUninstall",
                  "taskUpdate",
                ];
                this.label = "Collapse all";
              }
            });
          });
        } else {
          if (validForm) {
            this.software.ruleApplicability = this.ruleApplicabilityModel;
            this.software.ruleDetection = this.ruleDetectionModel;
            var payload = _.cloneDeep(this.software);
            this.$store.dispatch("addSoftware", payload);
            this.showDialog = false;
            this.$emit("refresh");
            this.activeNames = ["general"];
            this.$refs.form.resetFields();
            this.$refs.formRuleApplicability.resetFields();
          }
        }
      });
    },
    changeAccordion() {
      // this.label = this.label === 'Collapse all' ? 'Expand all' : 'Collapse all'
      if (this.activeNames.length > 0 && this.label === "Expand all") {
        this.label = "Collapse all";
        this.activeNames = [
          "general",
          // 'ruleApplicability',
          // 'ruleDetection',
          "taskInstall",
          "taskUninstall",
          "taskUpdate",
        ];
      } else if (this.activeNames.length > 0) {
        this.label = "Expand all";
        this.activeNames = [];
      } else {
        this.label = "Collapse all";
        this.activeNames = [
          "general",
          // 'ruleApplicability',
          // 'ruleDetection',
          "taskInstall",
          "taskUninstall",
          "taskUpdate",
        ];
      }
    },
    togglelabel(propname) {
      if (this[propname]["status"] === "Enable") {
        this[propname]["status"] = "Disable";
        this[propname]["rule"] = true;
      } else {
        this[propname]["status"] = "Enable";
        this[propname]["rule"] = false;
      }
    },
  },
  computed: {
    ...mapState(["rules", "systemhouses", "softwareStreams"]),
    getSoftwareName() {
      var val = "Install " + this.software.name + " " + this.software.version;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.software.taskInstall.name = val;
      return val;
    },
  },
};
</script>
