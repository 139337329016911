<template>
  <div>
    <add-company-dialog
      v-if="showCategorySelection"
      ref="addCompanyDialog"
      :renderNewLocationOption="true"
    ></add-company-dialog>
    <el-dialog title="Add New Person" v-model="showDialog" center>
      <el-form :model="formModel" ref="addPersonForm" :rules="personRules">
        <el-form-item label="Title" prop="title">
          <el-input placeholder="Insert Title" v-model="formModel.title" />
        </el-form-item>
        <el-form-item label="Given Name" prop="givenName">
          <el-input
            placeholder="Insert Given Name"
            v-model="formModel.givenName"
          />
        </el-form-item>
        <el-form-item label="Middle Name" prop="middleName">
          <el-input
            placeholder="Insert Middle Name"
            v-model="formModel.middleName"
          />
        </el-form-item>
        <el-form-item label="Surname" prop="surname">
          <el-input placeholder="Insert Surname" v-model="formModel.surname" />
        </el-form-item>
        <el-form-item label="Academic Degree" prop="academicDegree">
          <el-input
            placeholder="Insert Academic Degree"
            v-model="formModel.academicDegree"
          />
        </el-form-item>
        <el-form-item label="Employee Type" prop="employeeType">
          <el-select v-model="formModel.employeeType" placeholder="Select Type">
            <el-option
              v-for="type in typeOptions"
              :key="type.value"
              :label="type.label"
              :value="type.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Domain" prop="domain">
          <el-input placeholder="Insert Domain" v-model="formModel.domain" />
        </el-form-item>
        <el-form-item label="Departement" prop="departementName">
          <el-input
            placeholder="Insert Departement Name"
            v-model="formModel.departementName"
          />
        </el-form-item>
        <el-form-item label="Departement (short)" prop="departementShort">
          <el-input
            placeholder="Insert Abbreviation of Departement"
            v-model="formModel.departementShort"
          />
        </el-form-item>
        <el-form-item label="Cost Center" prop="costCenter">
          <el-input
            placeholder="Insert Cost Center"
            v-model="formModel.costCenter"
          />
        </el-form-item>
        <el-form-item label="Room Number" prop="roomNr">
          <el-input
            placeholder="Insert Room Number"
            v-model="formModel.roomNr"
          />
        </el-form-item>
        <el-form-item label="Phone Number" prop="phoneNr">
          <el-input
            placeholder="Insert Phone Number"
            v-model="formModel.phoneNr"
          />
        </el-form-item>
        <el-form-item label="Fax Number" prop="faxNr">
          <el-input placeholder="Insert Fax Number" v-model="formModel.faxNr" />
        </el-form-item>
        <el-form-item label="Mobile Phone Number" prop="mobileNr">
          <el-input
            placeholder="Insert Mobil Phone Number"
            v-model="formModel.mobileNr"
          />
        </el-form-item>
        <el-form-item label="Email" prop="emailPrimary">
          <el-input
            placeholder="Insert Email"
            v-model="formModel.emailPrimary"
          />
        </el-form-item>
        <el-form-item label="Email (optional)" prop="emailOptional">
          <el-input
            placeholder="Insert Optional Email"
            v-model="formModel.emailOptional"
          />
        </el-form-item>
        <el-form-item label="Employee Number" prop="employeeNr">
          <el-input
            placeholder="Insert Employee Number"
            v-model="formModel.employeeNr"
          />
        </el-form-item>
        <el-form-item label="State" prop="state">
          <el-select v-model="formModel.state" placeholder="Select State">
            <el-option
              v-for="state in stateOptions"
              :key="state.value"
              :label="state.label"
              :value="state.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Company"
          prop="companyId"
          v-if="showCategorySelection"
        >
          <el-select
            v-model="formModel.companyId"
            placeholder="Select Company"
            @change="openCreateCompanyDialog"
          >
            <el-option
              key="new-company"
              value="new-company"
              label="+ New Company"
            >
            </el-option>
            <el-option
              v-for="company in companies"
              :key="company.id"
              :label="company.corporateName"
              :value="company.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit('addPersonForm')">Add</el-button>
    </el-dialog>
  </div>
</template>

<script>
import impersonation from "../../../../impersonation";
import _ from "lodash";
import AddPersonValidationRules from "../AddPersonValidationRules";
import AddCompanyDialog from "./AddCompanyDialog";
import { addPerson } from "../../../../services/person";

export default {
  name: "add-person-dialog",
  mixins: [AddPersonValidationRules],
  props: ["showCategorySelection"],
  components: {
    AddCompanyDialog,
  },
  created() {},
  data() {
    return {
      showDialog: false,
      formModel: {
        title: "",
        givenName: "",
        middleName: "",
        surname: "",
        academicDegree: "",
        employeeType: "",
        domain: "",
        departementShort: "",
        departementName: "",
        costCenter: "",
        roomNr: "",
        phoneNr: "",
        faxNr: "",
        mobileNr: "",
        emailPrimary: "",
        emailOptional: "",
        employeeNr: "",
        state: "",
        companyId: "",
      },
      typeOptions: [
        {
          value: "internal",
          label: "Internal Employee",
        },
        {
          value: "external",
          label: "External Employee",
        },
      ],
      stateOptions: [
        {
          value: "new",
          label: "Created",
        },
        {
          value: "enabled",
          label: "Enabled",
        },
        {
          value: "disabled",
          label: "Disabled",
        },
        {
          value: "recycled",
          label: "Recycled",
        },
      ],
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit(formName) {
      let payload = this.createPayloadData();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addPerson(payload)
            .then((response) => {
              payload = response.data;
              this.$store.commit("addPerson", response.data);
            })
            .finally(() => {
              this.$refs.addPersonForm.resetFields();
              this.$emit("newPersonName", _.cloneDeep(payload));
              this.closeDialog();
            });
          // this.$store.dispatch('addPerson', _.cloneDeep(payload))
        }
      });
    },
    openCreateCompanyDialog() {
      if (this.formModel.companyId === "new-company") {
        this.$refs.addCompanyDialog.openDialog();
        this.formModel.companyId = "";
      }
    },
    createPayloadData() {
      let model = this.formModel;
      return {
        title: model.title,
        givenName: model.givenName,
        middleName: model.middleName,
        surname: model.surname,
        academicDegree: model.academicDegree,
        employeeType: model.employeeType,
        domain: model.domain,
        departementShort: model.departementShort,
        departementName: model.departementName,
        costCenter: model.costCenter,
        roomNr: model.roomNr,
        phoneNr: model.phoneNr,
        faxNr: model.faxNr,
        mobileNr: model.mobileNr,
        emailPrimary: model.emailPrimary,
        emailOptional: model.emailOptional,
        employeeNr: model.employeeNr,
        state: model.state,
        customerId: impersonation.getCustomer().id,
        companyId: model.companyId,
      };
    },
    resetForm() {
      this.formModel = {
        title: "",
        givenName: "",
        middleName: "",
        surname: "",
        academicDegree: "",
        employeeType: "",
        domain: "",
        departementShort: "",
        departementName: "",
        costCenter: "",
        roomNr: "",
        phoneNr: "",
        faxNr: "",
        mobileNr: "",
        emailPrimary: "",
        emailOptional: "",
        employeeNr: "",
        state: "",
        companyId: "",
      };
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
  computed: {
    companies: {
      get() {
        return this.$store.state.customersCompanies;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
