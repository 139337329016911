import { vnName, vnNameStart, vnNameEnd } from '../virtualNetworks/VirtualNetworkValidation'

export default{
  name: 'vpn-validation',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please define a VPN name', trigger: 'blur' },
          { min: 2, message: 'Name should at least have 2 chars', trigger: 'blur' },
          { max: 64, message: 'Name should at least have 64 chars', trigger: 'blur' },
          { validator: vnName, trigger: 'blur' },
          { validator: vnNameStart, trigger: 'blur' },
          { validator: vnNameEnd, trigger: 'blur' }
        ],
        virtualNetwork: [
          { required: true, message: 'Please select a resource group', trigger: 'change' }
        ],
        'local.addressRange': [
          { required: true, message: 'Please select an address range', trigger: 'blur' }
        ],
        'local.publicIp': [
          { required: true, message: 'Please input a public IP', trigger: 'blur' }
        ]
      }
    }
  }
}
