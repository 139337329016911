<template>
  <el-dialog v-model="showDialog" title="Edit Device Option">
    <el-form
      ref="form"
      :model="deviceOption"
      :rules="rules"
      label-width="180px"
    >
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="deviceOption.name"
          placeholder="Device Option name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          v-model="deviceOption.description"
          placeholder="Device Option description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Os Type" prop="osType">
        <span>{{ deviceOption.osType }}</span>
      </el-form-item>
      <el-form-item label="BitStream only script" prop="bitstreamScript">
        <el-switch
          v-model="deviceOption.bitstreamScript"
          active-text="true"
          inactive-text="false"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="PE only" prop="peOnly">
        <el-checkbox v-model="deviceOption.peOnly"></el-checkbox>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submit(deviceOption)">Edit</el-button>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import DeviceOptionValidation from "./DeviceOptionValidation";

export default {
  name: "device-option-edit",
  mixins: [DeviceOptionValidation],
  data() {
    return {
      osTypes: [
        { key: 1, value: "Windows" },
        { key: 2, value: "Ubuntu" },
        { key: 3, value: "RedHat" },
      ],
      showDialog: false,
      deviceOption: {
        id: "",
        name: "",
        description: "",
        bitstreamScript: false,
        peOnly: false,
        osType: "",
      },
    };
  },
  methods: {
    open(deviceOption) {
      this.deviceOption.id = deviceOption.id;
      this.deviceOption.name = deviceOption.name;
      this.deviceOption.description = deviceOption.description;
      this.deviceOption.bitstreamScript = !deviceOption.showToCustomer;
      this.deviceOption.peOnly = deviceOption.peOnly;
      this.deviceOption.osType = deviceOption.osType;
      this.showDialog = true;
    },
    submit(deviceOption) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editDeviceOption", _.cloneDeep(deviceOption));
          this.showDialog = false;
        }
      });
    },
  },
};
</script>
