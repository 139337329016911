<template>
  <div>
    <el-dialog
      title="OS Images"
      v-model="showDialog"
      width="80%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-table :data="images" highlight-current-row>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column
          prop="description"
          label="Description"
        ></el-table-column>
        <el-table-column label="Build Number" prop="buildNr"></el-table-column>
        <el-table-column label="Update" prop="update"></el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :deleteItem="true"
              @delete="confirmDeleteImage(scope.row)"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import impersonation from "../../impersonation";
import {
  deleteCustomerOSImage,
  getCustomerImageStreamsImages,
} from "../../services/customerImages";

export default {
  components: {
    ActionsDropdown,
  },
  data() {
    return {
      showDialog: false,
      images: [],
    };
  },
  methods: {
    confirmDeleteImage(customerImage) {
      this.$confirm(
        "This will permanently delete the Image. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(
        this.deleteCustomerImage.bind(this, customerImage),
        this.deleteError
      );
    },
    deleteError() {},
    deleteCustomerImage(customerImage) {
      deleteCustomerOSImage(
        impersonation.getCustomer().id,
        customerImage.id
      ).finally(() => {
        getCustomerImageStreamsImages(customerImage.customerImageStreamId).then(
          (response) => {
            this.images = response.data;
          }
        );
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    openDialog(imageStream) {
      this.images = imageStream.images;
      this.showDialog = true;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
