export const defaultDomainModel = {
  domain: {
    name: 'yourdomain',
    tld: 'de'
  },
  dns: {
    forwarders: '9.9.9.9' /* 9.9.9.9 Quad9 Datenschutzfreundlicher DNS, 9.9.9.10 ohne Filter, 8.8.8.8 Google DNS */
  },
  domainusercsv: {
    files: []
  },
  gpo: {
    bsiCertified: true,
    wallpaper: {
      files: []
    },
    lockscreen: {
      files: []
    }
  },
  organizationalUnits: {
    oulevels: [],
    oubaselevels: []
  }
}

/* office365configurationxml: {
    files: []
} */
