export function addSEP (customerId, payload) {
  return window.axiosInstance.post(`sep/addSEP/${customerId}`, payload)
}

export function getCustomersSEPs (customerId) {
  return window.axiosInstance.get(`sep/${customerId}`)
}

export function deleteSEP (customerId, sepId) {
  return window.axiosInstance.delete(`sep/deleteSEP/${customerId}/${sepId}`)
}

export function getSASKey (customerId, payload) {
  return window.axiosInstance.post(`sep/createSAS/${customerId}`, payload)
}
