<template>
  <el-dialog
    v-model="showDialog"
    title="Edit customer"
    fullscreen
    :before-close="onClose"
  >
    <el-form
      ref="form"
      :model="customer"
      :rules="customerEditRules"
      label-width="220px"
    >
      <el-form-item label="Customer name" prop="name">
        <el-input
          class="customerName"
          v-model="customer.name"
          placeholder="Customer name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Systemhouse" prop="systemhouseId" v-if="isAdmin">
        <el-select
          placeholder="Select systemhouse"
          v-model="customer.systemhouseId"
        >
          <el-option
            :key="systemhouse.id"
            :label="systemhouse.name"
            :value="systemhouse.id"
            v-for="systemhouse in systemhouses"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="WinPE Download Link" prop="winPELink">
        <el-input
          placeholder="WinPE Download Link"
          v-model="customer.winPEDownloadLink"
        ></el-input>
      </el-form-item>
      <el-collapse
        v-model="activeNames"
        @change="handleChangeCollapseable"
      ></el-collapse>
    </el-form>
    <div class="center">
      <el-button class="submit-btn" type="primary" @click="submit"
        >Save</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import CustomerValidation from "./CustomerValidation";
import { mapState } from "vuex";
import Permission from "../../mixins/Permission";
import { deleteIconOrBanner } from "../../services/customers";

export default {
  name: "customer-edit",
  mixins: [CustomerValidation, Permission],
  data() {
    return {
      activeNames: ["csdp-settings"],
      showDialog: false,
      uploadUrl:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/software/iconAndBanner/upload",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      iconsLeft: [],
      iconsRight: [],
      iconURLLeft: "",
      iconURLRight: "",
      bannerURL: "",
      customer: {
        id: "",
        name: "",
        systemhouseId: "",
        winPEDownloadLink: "",
      },
    };
  },
  methods: {
    handleChangeCollapseable(val) {
      this.activeNames = val;
    },
    onClose() {
      this.$refs.form.resetFields();
      this.customer.iconRight = { id: "", name: "" };
      this.customer.iconLeft = { id: "", name: "" };
      this.customer.banner = { id: "", name: "" };
      this.iconURLRight = "";
      this.iconURLLeft = "";
      this.bannerURL = "";
      this.showDialog = false;
    },
    setFilesIconRight(file) {
      if (file.length > 0) {
        this.customer.iconRight = {
          id: file[file.length - 1].response.id,
          name: file[file.length - 1].name,
        };
      } else {
        this.customer.iconRight = { id: "", name: "" };
      }
    },
    handleRemoveIconRight(a, files) {
      if (a.response === undefined) {
        var toDelete = this.customer.iconRight.id;
        deleteIconOrBanner(toDelete).catch((error) => console.log(error));
        this.customer.iconRight = { id: "", name: "" };
        this.iconURLRight = "";
      } else {
        this.setFilesIconRight(files);
        deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
        this.iconURLRight = "";
      }
    },
    handleSuccessIconRight(a, b, files) {
      this.setFilesIconRight(files);
    },
    setFilesIconLeft(file) {
      if (file.length > 0) {
        this.customer.iconLeft = {
          id: file[0].response.id,
          name: file[0].name,
        };
      } else {
        this.customer.iconLeft = { id: "", name: "" };
      }
    },
    handleRemoveIconLeft(a, files) {
      if (a.response === undefined) {
        var toDelete = this.customer.iconLeft.id;
        deleteIconOrBanner(toDelete).catch((error) => console.log(error));
        this.customer.iconLeft = { id: "", name: "" };
        this.iconURLLeft = "";
      } else {
        this.setFilesIconLeft(files);
        deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
        this.iconURLLeft = "";
      }
    },
    handleSuccessIconLeft(a, b, files) {
      this.setFilesIconLeft(files);
    },
    setFilesBanner(file) {
      if (file.length > 0) {
        this.customer.banner = { id: file[0].response.id, name: file[0].name };
      } else {
        this.customer.banner = { id: "", name: "" };
      }
    },
    handleRemoveBanner(a, files) {
      if (a.response === undefined) {
        var toDelete = this.customer.banner.id;
        deleteIconOrBanner(toDelete).catch((error) => console.log(error));
        this.customer.banner = { id: "", name: "" };
        this.bannerURL = "";
      } else {
        this.setFilesBanner(files);
        deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
        this.bannerURL = "";
      }
    },
    handleSuccessBanner(a, b, files) {
      this.setFilesBanner(files);
    },
    open(customer) {
      this.customer.winPEDownloadLink = customer.winPEDownloadLink;
      this.customer.name = customer.name;
      this.customer.id = customer.id;
      this.customer.systemhouseId = customer.systemhouse.id;
      this.showDialog = true;
    },
    beforeUploadIconRight(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURLRight = url;
    },
    beforeUploadIconLeft(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURLLeft = url;
    },
    beforeUploadBanner(file) {
      var url = window.URL.createObjectURL(file);
      this.bannerURL = url;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.customer);
          this.$store.dispatch("editCustomer", _.cloneDeep(payload));
          this.$refs.form.resetFields();
          this.showDialog = false;
        } else {
          this.activeNames = ["csdp-settings"];
        }
      });
    },
  },
  computed: {
    ...mapState(["systemhouses"]),
  },
};
</script>

<style scoped>
.customerName {
  width: 300px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.avatar-uploader-icon {
  height: 100px;
  width: 100%;
}

i:before {
  position: relative;
  top: 30%;
}

.uploader-container-icon {
  width: 200px;
  height: 200px;
}

.uploader-container-banner {
  width: 500px;
  height: 200px;
}

.avatar {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  object-fit: fill;
}

.avatar-banner {
  margin: 0 auto;
  width: 500px;
  height: 100px;
  object-fit: fill;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.dialog {
  margin: 0 auto;
}

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: inline-block;
}

.submit-btn {
  margin-top: 10px;
  text-align: center;
}

/* form {
  padding: 2rem;
} */

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
