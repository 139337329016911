<template>
  <el-dialog v-model="showDialog" title="Add Costum Inventory" width="85%">
    <el-form :model="custom" :rules="rules" ref="form">
      <el-form-item label="Name" prop="name">
        <el-input v-model="custom.name" placeholder="Insert a Name" />
      </el-form-item>
      <el-form-item label="WMI" prop="command">
        <el-input
          placeholder="Insert your WMI query"
          v-model="custom.command"
        />
      </el-form-item>
      <el-form-item label="Category" prop="category">
        <el-input
          disabled
          placeholder="Insert Category"
          v-model="custom.category"
        />
      </el-form-item>
      <el-button class="space-top" type="primary" @click="submit">
        Submit
      </el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import _ from "lodash";

export default {
  name: "custom-inventory-dialog",
  mixins: [FilterMixin],
  data() {
    return {
      showDialog: false,
      custom: {
        clientId: "",
        name: "",
        command: "",
        category: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please set a name",
            trigger: "blur",
          },
        ],
        command: [
          {
            required: true,
            message: "Please set a WMI command",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog(clientId) {
      this.custom.clientId = clientId;
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addCustomInventory", _.cloneDeep(this.custom));
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.list {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
