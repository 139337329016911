<template>
  <el-dialog
    v-model="showDialog"
    title="Edit OS Image Stream"
    :close-on-click-modal="false"
    :before-close="handleClose"
    width="75%"
  >
    <div v-if="model.type === '' && model.type === null" style="margin: 0 auto">
      <el-select v-model="model.type">
        <el-option
          v-for="t in types"
          :disabled="t.name === 'MacOS'"
          :key="t.key"
          :label="t.name"
          :value="t.name"
        >
        </el-option>
      </el-select>
    </div>
    <el-form v-if="model.type !== ''" ref="form" :model="model" :rules="rules">
      <el-form-item label="OS Name" prop="name">
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item label="Short Description" prop="descriptionShort">
        <el-input
          placeholder="Short Description"
          v-model="model.descriptionShort"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          :autosize="{ minRows: 5 }"
          placeholder="Description"
          type="textarea"
          v-model="model.description"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Edition" prop="edition">
        <el-input
          v-model="model.edition"
          placeholder="Please set the edition"
        ></el-input>
      </el-form-item>
      <el-form-item label="Vendor" prop="vendor">
        <el-input placeholder="Vendor" v-model="model.vendor"></el-input>
      </el-form-item>
      <el-form-item label="Website" prop="website">
        <el-input v-model="model.website"></el-input>
      </el-form-item>
      <el-form-item label="Language" prop="language">
        <el-select
          v-model="model.language"
          placeholder="Please select the language"
        >
          <el-option v-for="l in languages" :key="l" :value="l" :label="l">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Architecture" prop="architecture">
        <el-select
          v-model="model.architecture"
          placeholder="Please select the architecture"
        >
          <el-option
            v-for="a in swArchitectures"
            :label="a"
            :key="a"
            :value="a"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="URL Prefix" prop="prefixUrl">
        <el-input
          v-model="model.prefixUrl"
          placeholder="Please set the URL Prefix for the image file"
        ></el-input>
      </el-form-item>
      <el-form-item label="SAS Key" prop="sasKey">
        <el-input
          v-model="model.sasKey"
          placeholder="Please set the SAS key for the image file"
        ></el-input>
      </el-form-item>
      <el-form-item label="Subfolder Name" prop="subFolderName">
        <el-input
          placeholder="Please set the name of the subfolder"
          v-model="model.subFolderName"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="showSettingsFiles"
        label="OEM Partition File (*.txt)"
        prop="oemPartition"
      >
        <el-upload
          accept="text/plain"
          class="upload-item-file"
          :headers="headers"
          :action="actionOEMPartition"
          drag
          :limit="1"
          :before-upload="validateOEMPartition"
          :on-exceed="onExceed"
          :file-list="fileListOEMPartition"
          :on-remove="onRemoveOEMPartition"
          :on-success="onSuccessOEMPartition"
        >
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
        v-if="showSettingsFiles"
        label="Unattend File (*.xml)"
        prop="unattend"
      >
        <el-upload
          accept="text/xml"
          class="upload-item-file"
          :headers="headers"
          :action="actionUnattend"
          drag
          :limit="1"
          :before-upload="validateUnattendFile"
          :on-exceed="onExceed"
          :file-list="fileListUnattend"
          :on-remove="onRemoveUnattend"
          :on-success="onSuccessUnattend"
        >
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="Icon" prop="icon">
        <div class="uploader-container-banner">
          <el-upload
            ref="bannerUploader"
            class="avatar-uploader"
            :headers="headers"
            :action="uploadUrl"
            :limit="1"
            drag
            :file-list="icons"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="iconURL" :src="iconURL" class="avatar-icon" />
            <div v-else>
              <!-- <i class="el-icon-upload"></i> -->
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </div>
          </el-upload>
        </div>
      </el-form-item>
      <el-button style="text-align: center" type="primary" @click="submit"
        >Save</el-button
      >
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { getIconRef } from "../../services/imageStreams";

export default {
  data() {
    let validatePrefix = (rule, value, callback) => {
      if (this.model.prefixUrl !== "" && this.model.prefixUrl !== null) {
        if (
          this.model.prefixUrl.charAt(this.model.prefixUrl.length - 1) === "/"
        ) {
          callback();
        } else {
          callback("The URL Prefix has to end with a /");
        }
      } else {
        callback("Please set the URL prefix");
      }
    };
    return {
      fileListUnattend: [],
      fileListOEMPartition: [],
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      types: [
        { key: 1, name: "Windows" },
        { key: 2, name: "Linux" },
        { key: 3, name: "MacOS" },
      ],
      icons: [],
      iconURL: "",
      uploadUrl:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/imageStreams/icon/upload",
      showDialog: false,
      swArchitectures: ["x64", "x86", "x86 + x64"],
      languages: [
        "not defined",
        "MUI (multi user interface)",
        "German (de-DE)",
        "English (en-US)",
      ],
      model: {
        prefixUrl: "",
        sasKey: "",
        type: "",
        edition: "",
        id: "",
        name: "",
        description: "",
        vendor: "",
        architecture: "",
        language: "",
        website: "",
        descriptionShort: "",
        subFolderName: "",
        oemPartition: {
          id: "",
          name: "",
          guid: "",
        },
        unattend: {
          id: "",
          name: "",
          guid: "",
        },
        icon: {
          id: "",
          name: "",
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please set the name of the image stream",
            trigger: "blur",
          },
        ],
        subFolderName: [
          {
            required: true,
            message: "Please set the subfolder name",
            trigger: "blur",
          },
        ],
        edition: [
          {
            required: true,
            message: "Please set the edition",
            trigger: "blur",
          },
        ],
        language: [
          {
            required: true,
            message: "Please set the language",
            trigger: "change",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please set the architecture",
            trigger: "change",
          },
        ],
        prefixUrl: [
          { required: true, validator: validatePrefix, trigger: "blur" },
        ],
        sasKey: [
          {
            required: true,
            message: "Please set the SAS key for the image",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the type",
            trigger: "change",
          },
        ],
        unattend: [
          {
            required: true,
            message: "Please set a unattend file for the stream",
            trigger: "change",
          },
        ],
        oemPartition: [
          {
            required: true,
            message: "Please set a oem partition file for the stream",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    onRemoveUnattend() {
      this.model.unattend = {
        id: "",
        name: "",
        guid: "",
      };
    },
    onSuccessOEMPartition(a) {
      this.model.oemPartition = a;
    },
    onRemoveOEMPartition() {
      /*
      deleteUnattendFileEdit(this.subFolderName, this.image.id)
        .then(response => console.log({response}))
        .catch(err => console.log({err}))
      this.image.oemPartition = {
        id: '',
        name: '',
        guid: ''
      }
      */
    },
    onSuccessUnattend(a) {
      this.model.unattend = a;
    },
    onExceed() {
      this.$message.error("Do not upload more than 1 files");
    },
    validateOEMPartition(file) {
      if (this.model.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      if (file.type === "text/plain") {
        return true;
      } else {
        this.$message.error("The OEM partition file has to be a text file");
        return false;
      }
    },
    validateUnattendFile(file) {
      if (this.model.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      if (file.type === "text/xml") {
        return true;
      } else {
        this.$message.error("The unattend file has to be a xml file");
        return false;
      }
    },
    showSetupFileElements() {
      var result = false;
      if (
        this.model.prefixUrl !== "" &&
        this.model.sasKey !== "" &&
        this.model.subFolderName !== ""
      ) {
        result = true;
      }
      return result;
    },
    beforeUpload(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURL = url;
    },
    setFiles(file) {
      if (file.length > 0) {
        this.model.icon = { id: file[0].response.id, name: file[0].name };
      } else {
        this.model.icon = { id: "", name: "" };
      }
    },
    handleRemove(a, files) {
      this.setFiles(files);
      this.iconURL = "";
    },
    handleSuccess(a, b, files) {
      this.setFiles(files);
    },
    async openDialog(imageStream) {
      this.model = imageStream;
      if (this.model.unattend !== null) {
        this.fileListUnattend.push(this.model.unattend);
      }
      if (this.model.oemPartition !== null) {
        this.fileListOEMPartition.push(this.model.oemPartition);
      }
      if (imageStream.icon !== null && imageStream.icon.guid !== "") {
        this.iconURL = imageStream.icon;
        getIconRef(this.model.id)
          .then((response) => {
            let fetchedIcon = response.data;
            this.model.icon = fetchedIcon;
          })
          .catch((err) => console.log(err.data));
      } else {
        this.iconUrl = "";
      }
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.fileListOEMPartition = [];
      this.fileListUnattend = [];
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editImageStream", _.cloneDeep(this.model));
          this.fileListOEMPartition = [];
          this.fileListUnattend = [];
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    showSettingsFiles() {
      var result = false;
      if (
        this.model.prefixUrl !== "" &&
        this.model.subFolderName !== "" &&
        this.model.sasKey !== "" &&
        this.model.type == "Windows"
      ) {
        result = true;
      }
      return result;
    },
    actionOEMPartition() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/oemPartition/" +
        this.model.subFolderName
      );
    },
    actionUnattend() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/unattend/" +
        this.model.subFolderName
      );
    },
  },
};
</script>

<style lang="css" scoped>
.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.uploader-container-icon {
  width: 500px;
  height: 200px;
}

.avatar-icon {
  margin: 0 auto;
  width: 500px;
  height: 100px;
  object-fit: fill;
}
</style>
