<template>
  <el-dialog
    v-model="showDialog"
    title="Edit Software Package"
    :close-on-click-modal="false"
    :before-close="onClose"
  >
    <el-form v-model="swPackage">
      <el-form-item label="Customer Status" prop="customerStatus">
        <el-select
          v-model="swPackage.customerStatus"
          placeholder="Please set the Customer Status for the Software Package"
        >
          <el-option
            v-for="status in customerStatusList"
            :key="status.key"
            :label="status.value"
            :value="status.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import impersonation from "../../impersonation";

export default {
  data() {
    return {
      showDialog: false,
      swPackage: {
        customerStatus: "",
      },
      customerStatusList: [
        { key: 1, value: "New" },
        { key: 2, value: "Test" },
        { key: 3, value: "Pilot" },
        { key: 4, value: "Outdated" },
        { key: 5, value: "Acceptance" },
        { key: 6, value: "Active" },
      ],
    };
  },
  methods: {
    openDialog(swPackage) {
      this.swPackage = swPackage;
      this.showDialog = true;
    },
    onClose() {
      this.swPackage = {
        customerStatus: "",
      };
      this.showDialog = false;
    },
    submit() {
      let payload = {
        customerId: impersonation.getCustomer().id,
        data: this.swPackage,
      };
      this.$store.dispatch("editCustomerSoftware", payload);
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
