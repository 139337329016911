export default function passwordStrength (rule, value, callback) {
  if (!/[a-z]/.test(value)) {
    callback('Password needs to contain lowercase characters')
    return
  }

  if (!/[A-Z]/.test(value)) {
    callback('Password needs to contain uppercase characters')
    return
  }

  if (!/\d/.test(value)) {
    callback('Password needs to contain digits.')
    return
  }

  if (!/\W/.test(value)) {
    callback('Password needs to contain special characters.')
    return
  }

  callback()
}
