<template>
  <div class="container">
    <h3>Locations</h3>
    <el-main>
      <location-settings :customerId="customerId"></location-settings>
    </el-main>
  </div>
</template>

<script>
import LocationSettings from '../locations/LocationSettings'
import impersonation from '../../impersonation'

export default {
  components: {
    LocationSettings
  },
  created () {
    this.$store.dispatch('getCustomersLocations', impersonation.getCustomer().id)
    this.customerId = impersonation.getCustomer().id
  },
  data () {
    return {
      customerId: ''
    }
  }
}
</script>

<style lang="css" scoped>
  .container {
    margin-top: 10px;
  }
</style>
