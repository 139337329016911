<template>
  <el-dialog
    v-model="showDialog"
    title="Add Devices"
    :before-close="handleClose"
  >
    <el-upload
      ref="upload"
      class="upload-demo"
      drag
      :limit="1"
      :headers="headers"
      :action="action"
      accept=".csv"
      :on-success="handleSuccess"
      :fileList="fileList"
    >
      <div class="el-upload__text">
        <el-icon> <UploadFilled /> </el-icon>
        Drop file here or <em>click to upload</em>
      </div>
    </el-upload>
  </el-dialog>
</template>

<script>
import impersonation from "../../impersonation";
import { successMessage } from "../../store/modules/util";

export default {
  emits: ["reloadClients"],
  data() {
    const customer = impersonation.getCustomer();
    return {
      showDialog: false,
      action:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customers/" +
        customer.id +
        "/clients/importClients",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
    };
  },
  methods: {
    handleClose() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
    handleSuccess() {
      this.$emit("reloadClients");
      this.$refs.upload.clearFiles();
      this.showDialog = false;
      successMessage("The clients where imported successfully");
    },
  },
};
</script>

<style lang="css" scoped>
</style>
