<template>
  <el-dialog v-model="showDialog" title="Add shop item">
    <el-form ref="form" :model="item">
      <el-form-item
        label="Name"
        prop="name"
        :rules="[{ required: true, message: 'Item name is required' }]"
      >
        <el-input v-model="item.name" placeholder="Item name"></el-input>
      </el-form-item>
      <!-- validator: validatePrice, trigger: 'blur' -->
      <el-form-item
        label="Price netto in €"
        prop="price"
        :rules="[
          {
            required: true,
            message: 'Please insert a price!',
            trigger: 'blur',
          },
          { validator: validatePrice, trigger: 'blur' },
        ]"
      >
        <!-- <el-input-number v-model="item.price" :precision="2"  controls-position="right"  :step="0.1" :min="0" ></el-input-number> -->
        <el-input v-model="item.price"></el-input>
      </el-form-item>
      <el-form-item
        label="Managed Service Price netto in €"
        prop="managedServicePrice"
        :rules="[
          {
            required: true,
            message: 'Please insert a price!',
            trigger: 'blur',
          },
          { validator: validateManagedServicePrice, trigger: 'blur' },
        ]"
      >
        <el-input v-model="item.managedServicePrice"></el-input>
      </el-form-item>
      <el-form-item
        label="Managed Service Lifecycle Price netto in €"
        prop="managedServiceLifecyclePrice"
        :rules="[
          {
            required: true,
            message: 'Please insert a price!',
            trigger: 'blur',
          },
          { validator: validateManagedLifecycleServicePrice, trigger: 'blur' },
        ]"
      >
        <el-input v-model="item.managedServiceLifecyclePrice"></el-input>
      </el-form-item>
      <el-form-item
        label="Category"
        prop="category"
        :rules="[{ validator: validateCategory, trigger: 'change' }]"
      >
        <el-select
          v-model="tempCategory"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Choose Categories"
        >
          <el-option
            v-for="element in categories"
            :key="element.id"
            :label="element.name"
            :value="element.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isHardware" label="Drivers" prop="drivers">
        <el-select multiple v-model="selectedDrivers">
          <el-option
            v-for="driver in drivers"
            :key="driver.id"
            :label="driver.name"
            :value="driver.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          type="textarea"
          v-model="item.description"
          placeholder="Description"
          :autosize="{ minRows: 3 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="Images" prop="images">
        <upload-files
          :action="uploadUrl"
          :fileList="images"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        ></upload-files>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import UploadFiles from "../shared/UploadFiles";
import { mapState } from "vuex";

export default {
  name: "item-add",
  components: {
    UploadFiles,
  },
  created() {
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getDrivers");
  },
  data() {
    return {
      selectedDrivers: [],
      uploadUrl: window.APPLICATION_SETTING_API_ENDPOINT_URL + "/shop/upload",
      showDialog: false,
      images: [],
      item: {
        name: "",
        description: "",
        price: 0,
        managedServicePrice: 0,
        managedServiceLifecyclePrice: 0,
        images: [],
        categories: [],
        drivers: [],
      },
      tempCategory: [],
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    validatePrice(rule, value, callback) {
      if (this.item.price !== 0) {
        if (this.item.price.includes(".")) {
          callback(new Error("Please use a comma as seperator"));
          return;
        }
        if (this.item.price.charAt(0) === ",") {
          callback(new Error("The price has to start with a number"));
        }
        var tempArray = this.item.price.split(",");
        if (tempArray.length !== 2) {
          this.item.price = this.item.price + ",00";
        } else {
          if (tempArray[1].length === 1) {
            this.item.price = this.item.price + "0";
          }
        }
        let regex = /^[0-9]+,\d\d$/;
        let regexZeroPrice = /^[0]+,[0]+$/;
        if (
          regex.test(this.item.price) &&
          !regexZeroPrice.test(this.item.price)
        ) {
          callback();
        } else {
          callback(
            new Error('The price is invalid. Please set a value like "xx,xx"')
          );
        }
      } else {
        callback(new Error("Please set a price"));
      }
    },
    validateManagedServicePrice(rule, value, callback) {
      if (this.item.managedServicePrice !== 0) {
        if (this.item.managedServicePrice.includes(".")) {
          callback(new Error("Please use a comma as seperator"));
          return;
        }
        if (this.item.managedServicePrice.charAt(0) === ",") {
          callback(new Error("The price has to start with a number!"));
        }
        var tempArray = this.item.managedServicePrice.split(",");
        if (tempArray.length !== 2) {
          this.item.managedServicePrice = this.item.managedServicePrice + ",00";
        } else {
          if (tempArray[1].length === 1) {
            this.item.managedServicePrice = this.item.managedServicePrice + "0";
          }
        }
        let regex = /^[0-9]+,\d\d$/;
        let regexZeroPrice = /^[0]+,[0]+$/;
        if (
          regex.test(this.item.managedServicePrice) &&
          !regexZeroPrice.test(this.item.managedServicePrice)
        ) {
          callback();
        } else {
          callback(
            new Error('The price is invalid. Please set a value like "xx,xx"')
          );
        }
      } else {
        callback(new Error("Please set a price"));
      }
    },
    validateManagedLifecycleServicePrice(rule, value, callback) {
      if (this.item.managedServiceLifecyclePrice !== 0) {
        if (this.item.managedServiceLifecyclePrice.includes(".")) {
          callback(new Error("Please use a comma as seperator"));
          return;
        }
        if (this.item.managedServiceLifecyclePrice.charAt(0) === ",") {
          callback(new Error("The price has to start with a number!"));
        }
        var tempArray = this.item.managedServiceLifecyclePrice.split(",");
        if (tempArray.length !== 2) {
          this.item.managedServiceLifecyclePrice =
            this.item.managedServiceLifecyclePrice + ",00";
        } else {
          if (tempArray[1].length === 1) {
            this.item.managedServiceLifecyclePrice =
              this.item.managedServiceLifecyclePrice + "0";
          }
        }
        let regex = /^[0-9]+,\d\d$/;
        let regexZeroPrice = /^[0]+,[0]+$/;
        if (
          regex.test(this.item.managedServiceLifecyclePrice) &&
          !regexZeroPrice.test(this.item.managedServiceLifecyclePrice)
        ) {
          callback();
        } else {
          callback(
            new Error('The price is invalid. Please set a value like "xx,xx"')
          );
        }
      } else {
        callback(new Error("Please set a price"));
      }
    },
    validateCategory(rule, value, callback) {
      let cats = this.tempCategory;
      if (cats.length === 0) {
        callback("Please set the categories for the shop item");
      } else {
        if (cats.indexOf("Software") === -1) {
          callback();
        } else {
          callback(
            "The category software must not be used as it is already taken"
          );
          // cats = cats.filter(el => {
          //   el !== 'Software'
          // })
          // this.tempArray = cats
        }
      }
    },
    submit() {
      let categories = this.categories;
      let item = _.cloneDeep(this.item);
      item.drivers = this.selectedDrivers;
      this.$refs.form.validate((valid) => {
        if (valid) {
          item.categories = [];
          this.tempCategory.forEach(function (el) {
            let obj = categories.find((obj) => obj.name === el);
            if (obj) {
              item.categories.push(obj);
            } else {
              item.categories.push({ name: el });
            }
          });
          this.$store.dispatch("addShopItem", _.cloneDeep(item));
          this.showDialog = false;
          this.tempCategory = [];
          this.selectedDrivers = [];
          this.$store.dispatch("getCategories");
          this.$refs.form.resetFields();
        }
      });
    },
    setFiles(fileList) {
      this.item.images = fileList.map(function (file) {
        return file.response;
      });
    },
    handleRemove(a, fileList) {
      this.setFiles(fileList);
    },
    handleSuccess(a, b, fileList) {
      this.setFiles(fileList);
    },
  },
  computed: {
    ...mapState(["categories", "drivers"]),
    getCategories() {
      return this.categories;
    },
    isHardware() {
      var index = this.tempCategory.indexOf("Hardware");
      if (index === -1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
