<template>
  <el-dialog
    v-model="showDialog"
    title="Edit OS-Image"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="image" :rules="rules">
      <el-form-item label="Name" prop="name">
        <el-input
          disabled
          placeholder="Please set the name"
          v-model="image.name"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="streamType === 'Windows'"
        label="File Name"
        prop="fileName"
      >
        <el-input
          placeholder="Please set the file name"
          v-model="image.fileName"
        ></el-input>
      </el-form-item>
      <el-form-item label="Build-Nr" prop="buildNr">
        <el-input
          v-model="image.buildNr"
          placeholder="Please set the build number of the image"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="streamType === 'Windows'"
        label="Update"
        prop="update"
      >
        <el-input
          v-model="image.update"
          placeholder="Please set the update of the image"
        ></el-input>
      </el-form-item>
      <el-form-item label="Systemhouses">
        <el-select
          v-model="image.systemhouses"
          @change="getSystemhousesCustomers"
          multiple
          filterable
        >
          <el-option
            v-for="sys in systemhouses"
            :key="sys.id"
            :value="sys.id"
            :label="sys.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="image.systemhouses.length !== 0" label="Customers">
        <el-select
          v-model="image.customers"
          filterable
          multiple
          @change="getCustomersClients"
        >
          <el-option
            v-for="customer in customers"
            :key="customer.id"
            :value="customer.id"
            :label="customer.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-collapse v-if="streamType === 'Windows'" v-model="activeNames">
        <el-collapse-item title="Setup Files" name="1">
          <el-form-item label="OEM Partition File (*.txt)" prop="oemPartition">
            <el-upload
              accept="text/plain"
              class="upload-item-file"
              :headers="headers"
              :action="actionOEMPartition"
              drag
              :limit="1"
              :before-upload="validateOEMPartition"
              :on-exceed="onExceed"
              :file-list="fileListOEMPartition"
              :on-remove="onRemoveOEMPartition"
              :on-success="onSuccessOEMPartition"
            >
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="Unattend File (*.xml)" prop="unattend">
            <el-upload
              accept="text/xml"
              class="upload-item-file"
              :headers="headers"
              :action="actionUnattend"
              drag
              :limit="1"
              :before-upload="validateUnattendFile"
              :on-exceed="onExceed"
              :file-list="fileListUnattend"
              :on-remove="onRemoveUnattend"
              :on-success="onSuccessUnattend"
            >
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </el-upload>
          </el-form-item>
        </el-collapse-item>
      </el-collapse> -->
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import Auth from "../../auth";
import { mapState } from "vuex";
import { editSWImage } from "../../services/swImages";
import {
  getSystemhousesCustomers,
  getSyshouseUserCustomers,
} from "../../services/systemhouses";
import { getCustomersClients } from "../../services/customers";

export default {
  emits: ["reloadImages"],
  data() {
    let validateUnattend = (rule, value, callback) => {
      if (this.image.unattend !== null) {
        callback();
      } else {
        callback(new Error("Please upload an unattend file"));
      }
    };
    let validatePartitionLayout = (rule, value, callback) => {
      if (this.image.oemPartition !== null) {
        callback();
      } else {
        callback(new Error("Please upload a partition layout file"));
      }
    };
    return {
      streamType: "",
      subFolderName: "",
      clients: [],
      customers: [],
      fileListOEMLogo: [],
      fileListUnattend: [],
      fileListOEMPartition: [],
      fileListDeskBackground: [],
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      activeNames: [],
      swArchitectures: [
        { key: 1, value: "x64" },
        { key: 2, value: "x86" },
        { key: 3, value: "x86 + x64" },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Please set the name of the image",
            trigger: "blur",
          },
        ],
        fileName: [
          {
            required: true,
            message: "Please set the file name of the image",
            trigger: "blur",
          },
        ],
        update: [
          {
            required: true,
            message: "Please set the update info",
            trigger: "blur",
          },
        ],
        oemPartition: [
          {
            validator: validatePartitionLayout,
            required: true,
            trigger: "change",
          },
        ],
        unattend: [
          { validator: validateUnattend, required: true, trigger: "change" },
        ],
      },
      showDialog: false,
      languages: [
        { key: 1, value: "not defined" },
        { key: 2, value: "MUI (multi user interface)" },
        { key: 3, value: "German (de-DE)" },
        { key: 4, value: "English (en-US)" },
      ],
      image: {
        customers: [],
        systemhouses: [],
        name: "",
        fileName: "",
        update: "",
        vendor: "",
        buildNr: "",
        desktopFile: {
          id: "",
          name: "",
          guid: "",
        },
        oemLogo: {
          id: "",
          name: "",
          guid: "",
        },
        oemPartition: {
          id: "",
          name: "",
          guid: "",
        },
        unattend: {
          id: "",
          name: "",
          guid: "",
        },
      },
    };
  },
  methods: {
    getSyshouseUserData() {
      getSyshouseUserCustomers()
        .then((response) => {
          this.$store.state.systemhouses = [response.data];
        })
        .catch((err) => console.error({ err }));
    },
    getSystemhousesCustomers(value) {
      getSystemhousesCustomers(value).then((response) => {
        this.customers = response.data;
      });
    },
    getCustomersClients(value) {
      getCustomersClients(value).then((response) => {
        this.clients = response.data;
      });
    },
    handleClose() {
      this.fileListOEMLogo = [];
      this.fileListUnattend = [];
      this.fileListOEMPartition = [];
      this.fileListDeskBackground = [];
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    onExceed() {
      this.$message.error("Do not upload more than 1 files");
    },
    onSuccessOEMLogo(a) {
      this.image.oemLogo = a;
    },
    onSuccessUnattend(a) {
      this.image.unattend = a;
    },
    onSuccessOEMPartition(a) {
      this.image.oemPartition = a;
    },
    onSuccessDeskBackground(a) {
      this.image.desktopFile = a;
    },
    onRemoveOEMPartition() {
      /*
      deleteUnattendFileEdit(this.subFolderName, this.image.id)
        .then(response => console.log({response}))
        .catch(err => console.log({err}))
      this.image.oemPartition = {
        id: '',
        name: '',
        guid: ''
      }
      */
    },
    onRemoveUnattend() {
      this.image.unattend = {
        id: "",
        name: "",
        guid: "",
      };
    },
    validateDeskBackground(file) {
      if (this.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      var fileType = file.name.split(".")[1];
      if (fileType === "deskthemepack") {
        return true;
      } else {
        this.$message.error(
          "The desktop background file has to be a .deskthemepack"
        );
        return false;
      }
    },
    validateOEMPartition(file) {
      if (this.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      if (file.type === "text/plain") {
        return true;
      } else {
        this.$message.error("The OEM partition file has to be a text file");
        return false;
      }
    },
    validateUnattendFile(file) {
      if (this.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      if (file.type === "text/xml") {
        return true;
      } else {
        this.$message.error("The unattend file has to be a xml file");
        return false;
      }
    },
    validateOEMLogo(file) {
      if (this.subFolderName === "" || this.subFolderName === null) {
        this.$message.error(
          "Please set the Subfolder of the image first. This is needed for the upload."
        );
        return false;
      }
      if (file.type === "image/bmp") {
        return true;
      } else {
        this.$message.error("The OEM logo has to be a bmp file");
        return false;
      }
    },
    openDialog(image, subFolderName, streamType) {
      this.fileListUnattend = [];
      this.fileListOEMPartition = [];
      this.streamType = streamType;
      this.subFolderName = subFolderName;
      this.image = _.cloneDeep(image);
      if (image.unattend !== null) {
        this.fileListUnattend.push(image.unattend);
      }
      if (image.oemPartition !== null) {
        this.fileListOEMPartition.push(image.oemPartition);
      }
      if (Auth.hasPermission("systemhouse")) {
        this.getSyshouseUserData();
      }
      if (image.systemhouses.length !== 0) {
        this.getSystemhousesCustomers(image.systemhouses);
      }
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          editSWImage(_.cloneDeep(this.image)).finally(() => {
            this.$refs.form.resetFields();
            this.$emit("reloadImages");
            this.showDialog = false;
          });
        } else {
          this.activeNames = ["1"];
        }
      });
    },
  },
  computed: {
    ...mapState(["systemhouses"]),
    actionOEMPartition() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/oemPartition/" +
        this.subFolderName
      );
    },
    actionUnattend() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/unattend/" +
        this.subFolderName
      );
    },
    actionOEMLogo() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/oemLogo/" +
        this.subFolderName
      );
    },
    actionDeskBackground() {
      return (
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/swImages/desktopFile/" +
        this.subFolderName
      );
    },
  },
};
</script>

<style lang="css" scoped>
</style>
