export default {
  name: 'useredit-validation',
  data() {
    return {
      rules: {
        firstName: [
          {
            required: true,
            message: "Please set your first name",
            trigger: "blur",
          },
        ],
        familyName: [
          {
            required: true,
            message: "Please set your family name",
            trigger: "blur",
          },
        ],
        systemhouse: [
          {
            required: true,
            message: "Please specify the access rights",
            trigger: "change",
          },
        ],
        // email: [
        //   {required: true, message: 'Please input user email', trigger: 'blur'},
        //   {type: 'email', message: 'Please input valid email address', trigger: 'blur'}]
      }
    }
  }
}
