export function getDomains (customerId, baseId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases/${baseId}/domains`)
}

export function addDomain (customerId, baseId, domain) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/${baseId}/domains`, domain)
}

export function getDomain (customerId, baseId, domainId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases/${baseId}/domains/${domainId}`)
}
