<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Admin Device Options</h3>
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input
            placeholder="Find device option"
            v-model="search"
            autofocus
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon
                style="vertical-align: middle"
                v-if="isSubset"
                @click="clearSearch"
              >
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredAdminOptions.length }} of
            {{ adminOptions.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <el-button type="primary" @click="openAddAdminOption">
            <el-icon style="margin-right: 5px">
              <Plus />
            </el-icon>
            Add Admin Device Option</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table :data="filteredAdminOptions" highlight-current-row>
        <el-table-column prop="name" label="Name" sortable> </el-table-column>
        <el-table-column prop="description" label="Description">
        </el-table-column>
        <el-table-column prop="type" label="Type"></el-table-column>
        <el-table-column prop="versions" label="Versions" sortable width="130">
          <template v-slot="scope">
            {{ scope.row.versions.length }} versions
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="90">
          <template v-slot="scope">
            <actions-dropdown
              :entity="scope.row"
              :deleteItem="true"
              :version="true"
              :edit="true"
              @version="openDeviceOptionVersionAdd(scope.row)"
              @delete="deleteAdminOption(scope.row)"
              @edit="openEditAdminOption(scope.row)"
              :hideRetreatFromShop="true"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-admin-option ref="addAdminOption"></add-admin-option>
    <addAdminOptionVersion
      @refreshAdminOptions="refreshAdminOptions"
      ref="addAdminOptionVersion"
    ></addAdminOptionVersion>
    <editAdminOption ref="editAdminOption"></editAdminOption>
  </div>
</template>

<script>
import AddAdminOption from "./AddAdminOption.vue";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import AdminOptionNewVersion from "./AdminOptionNewVersion.vue";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import { deleteAdminOption } from "../../services/adminOptions";
import EditAdminOption from "./EditAdminOption.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "add-admin-option": AddAdminOption,
    addAdminOptionVersion: AdminOptionNewVersion,
    editAdminOption: EditAdminOption,
  },
  created() {
    this.$store.dispatch("getAdminOptions");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    openEditAdminOption(adminOption) {
      this.$refs.editAdminOption.openDialog(adminOption);
    },
    deleteAdminOption(adminOption) {
      this.$confirm(
        "This will permanently delete the option. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(
        this.deleteOptionSuccess.bind(this, adminOption),
        this.deleteError
      );
    },
    deleteOptionSuccess(adminOption) {
      deleteAdminOption(adminOption.id)
        .then(() => {
          this.refreshAdminOptions();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    openAddAdminOption() {
      this.$refs.addAdminOption.openDialog();
    },
    openDeviceOptionVersionAdd(adminOption) {
      this.$refs.addAdminOptionVersion.openDialog(adminOption);
    },
    refreshAdminOptions() {
      this.$store.dispatch("getAdminOptions");
    },
  },
  computed: {
    ...mapState(["adminOptions"]),
    filteredAdminOptions() {
      return this.filterEntities(this.adminOptions, this.search);
    },
    isSubset() {
      return this.adminOptions.length !== this.filteredAdminOptions.length;
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  padding-bottom: 50px;
}

.tableContainer {
  width: 80%;
  margin: 0 auto;
}
</style>
