<template lang="html">
  <div>
    <el-dialog
      v-model="showDialog"
      title="Assign Location">
      <el-form
        ref="form"
        :model="location"
        :rules="rules">
        <el-form-item prop="locationId">
          <el-select
            v-model="location.locationId"
            placeholder="Please select the location"
            @change="addLocation">
            <el-option
              v-for="location in customersLocations"
              :key="location.id"
              :label="location.name"
              :value="location.id">
            </el-option>
            <el-option
              key="new-location"
              value="new-location"
              label="+ Add Location">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="submit">
          <el-button type="primary" @click="submit">Assign</el-button>
        </div>
      </el-form>
    </el-dialog>
    <add-location ref="addLocation"></add-location>
  </div>
</template>

<script>
import AddLocation from "../../locations/AddLocation.vue";
import impersonation from "../../../impersonation";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "assign-location",
  components: {
    AddLocation,
  },
  created() {
    this.$store.dispatch(
      "getCustomersLocations",
      impersonation.getCustomer().id
    );
  },
  data() {
    let validateLocationId = (rule, value, callback) => {
      if (this.location.locationId.length === 0) {
        callback(new Error("Please select the location to assign"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      location: {
        locationId: "",
        assetId: "",
      },
      rules: {
        locationId: [{ validator: validateLocationId, trigger: "change" }],
      },
    };
  },
  methods: {
    addLocation(value) {
      if (value === "new-location") {
        this.$refs.addLocation.openDialog();
        this.location.locationId = "";
      }
    },
    openDialog(assetModelId) {
      this.location.assetId = assetModelId;
      this.showDialog = true;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("assignLocation", _.cloneDeep(this.location));
          this.$refs.form.resetFields();
          this.$emit("refresh");
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    ...mapState(["customersLocations"]),
  },
};
</script>

<style lang="css" scoped>
.submit {
  margin: 0 auto;
  text-align: center;
}
</style>
