<template>
  <el-dialog
    v-model="showDialog"
    title="Edit Azure CEP"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="azureCEP" :rules="rulesCEP" label-width="180px">
      <el-form-item label="Name" prop="name">
        <el-input v-model="azureCEP.name"></el-input>
      </el-form-item>
      <el-form-item label="Azure Tenant ID" prop="tenantId">
        <el-input
          autocomplete="new-password"
          v-model="azureCEP.tenantId"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Azure Client ID" prop="clientId">
        <el-input
          autocomplete="new-password"
          v-model="azureCEP.clientId"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Azure Client Secret" prop="clientSecret">
        <el-input
          autocomplete="new-password"
          v-model="azureCEP.clientSecret"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Standard Credentials" prop="isStandard">
        <el-checkbox v-model="azureCEP.isStandard"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">Edit</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import CloudEPValidation from "./CloudEPValidation";
import impersonation from "../../impersonation";

export default {
  name: "edit-azureCEP",
  mixins: [CloudEPValidation],
  data() {
    return {
      showDialog: false,
      azureCEP: {
        id: "",
        name: "",
        tenantId: "",
        clientId: "",
        clientSecret: "",
        type: "azure",
        isStandard: false,
      },
    };
  },
  methods: {
    open(cep) {
      this.azureCEP = cep;
      this.azureCEP.clientSecret = "";
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = {
            customerId: impersonation.getCustomer().id,
            data: this.azureCEP,
          };
          this.$store.dispatch("updateCEP", payload);
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
