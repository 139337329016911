export function getBases (customerId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases`)
}

export function addBase (customerId, base) {
  return window.axiosInstance.post(`/customers/${customerId}/bases`, base)
}

export function getBase (customerId, baseId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases/${baseId}`)
}

export function addOrUpdateAdvancedBaseProp (customerId, baseId, advancedProp) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/${baseId}/advanced`, advancedProp)
}

export function deleteBase (customerId, baseId) {
  return window.axiosInstance.delete(`/customers/${customerId}/bases/${baseId}`)
}

export function createResourceGrp (customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/createResourceGroup`, payload)
}

export function createStorageAcc (customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/createStorageAccount`, payload)
}

export function createVirtualNetwork (customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/createVirtualNetwork`, payload)
}

export function createVPN (customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/createVPN`, payload)
}

export function addParameters (customerId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/addParams`, payload)
}

export function getBaseStatus (customerId, baseId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases/baseStatus/${baseId}`)
}
