export function getDeviceProperties () {
  return window.axiosInstance.get('/device-properties')
}
export function getDevicePropCategory () {
  return window.axiosInstance.get('/device-properties/categories')
}

export function addDeviceProperty (deviceProperty) {
  return window.axiosInstance.post('/device-properties', deviceProperty)
}

export function editDeviceProperty (deviceProperty) {
  return window.axiosInstance.put(`/device-properties/${deviceProperty.id}`, deviceProperty)
}
