<template lang="html">
  <div class="flex">
    <el-select
      placeholder="Select Country"
      v-model="country.name"
      @change="fillCountryCode"
      filterable>
      <el-option
        v-for="country in countries"
        :key="country.Name"
        :label="country.countryName"
        :value="country.countryName">
      </el-option>
    </el-select>
    <el-input readonly v-model="country.code"></el-input>
  </div>
</template>

<script>
export default {
  name: 'country-selection',
  props: ['passedCountryName'],
  created () {
    if (this.passedCountryName) {
      this.country.name = this.passedCountryName
      this.fillCountryCode()
    }
  },
  data () {
    return {
      country: {
        name: '',
        code: '',
        zones: []
      }
    }
  },
  methods: {
    fillCountryCode () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].countryName === this.country.name) {
          this.country.code = this.countries[i].countryCode
          if (this.countries[i].zones !== undefined) {
            this.country.zones = this.countries[i].zones
          } else {
            this.country.zones = [{'zoneName': this.countries[i].zoneName, 'gmtOffset': this.countries[i].gmtOffset, 'timestamp': this.countries[i].timestamp}]
          }
          break
        }
      }
      this.sendCountryData()
    },
    sendCountryData () {
      this.$emit('setCountryData', this.country)
    }
  },
  computed: {
    countries: {
      get () {
        return this.$store.state.countries
      },
      set () {
        // Do nothing
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
