<template>
  <el-dialog
    title="Streamless Software"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
    width="75%"
  >
    <el-table
      :data="filteredSoftwares"
      highlight-current-row
      @selection-change="handleSelectionChange"
      ref="multipleSelection"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column label="Type" prop="type"></el-table-column>
      <el-table-column label="Version" prop="version"></el-table-column>
      <el-table-column prop="status" label="Status" sortable></el-table-column>
      <el-table-column label="Actions" width="110">
        <template v-slot="scope">
          <actions-dropdown
            :hideRetreatFromShop="true"
            :entity="scope.row"
            :deleteItem="true"
            :downloadFiles="true"
            @delete="confirmDelete"
            @downloadFiles="downloadSWFiles(scope.row)"
            :edit="false"
          >
          </actions-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="submit">Add selected Software</el-button>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { downloadSoftwareFiles } from "../../services/softwares";
import FileSaver from "file-saver";
import ActionsDropdown from "../shared/ActionsDropdown.vue";

export default {
  components: {
    ActionsDropdown,
  },
  data() {
    return {
      streamId: "",
      showDialog: false,
      selectedSoftwares: [],
    };
  },
  methods: {
    downloadSWFiles(software) {
      downloadSoftwareFiles(software.id)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < data.length; i++) {
            let name = data[i].fileName;
            let uri = data[i].sasUri;
            let authHeader =
              window.axiosInstance.defaults.headers.common.Authorization;
            delete window.axiosInstance.defaults.headers.common[
              "Authorization"
            ];
            window.axiosInstance
              .get(uri, { responseType: "blob" })
              .then((response1) => {
                let blob = response1.data;
                FileSaver.saveAs(blob, name, true);
              })
              .catch((err) => console.log(err.data));
            window.axiosInstance.defaults.headers.common["Authorization"] =
              authHeader;
          }
        })
        .catch(() => console.log("ERROR LVL 1"));
    },
    handleSelectionChange(value) {
      this.selectedSoftwares = value;
    },
    handleClose() {
      this.$refs.multipleSelection.clearSelection();
      this.showDialog = false;
    },
    openDialog(streamId) {
      this.streamId = streamId;
      this.showDialog = true;
    },
    submit() {
      if (this.selectedSoftwares.length !== 0) {
        let data = {
          streamId: this.streamId,
          payload: { softwares: this.selectedSoftwares },
        };
        this.$store.dispatch("setSoftwareToStream", data);
        this.$refs.multipleSelection.clearSelection();
        this.showDialog = false;
      }
    },
    confirmDelete(software) {
      this.$confirm(
        "This will permanently delete the software. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, software), this.deleteError);
    },
    deleteSuccess(software) {
      this.$store.dispatch("deleteSoftware", software);
    },
  },
  computed: {
    ...mapState(["softwares"]),
    filteredSoftwares() {
      let tempSoftwares = [];
      for (let i = 0; i < this.softwares.length; i++) {
        if (this.softwares[i].softwareStreamId === null) {
          tempSoftwares.push(this.softwares[i]);
        }
      }
      return tempSoftwares;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
