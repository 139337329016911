<template>
  <div>
    <br />
    <el-col :span="22" :offset="1">
      <div class="alert-banner" v-if="showHintBanner">
        <span
          >You dont have created a Storage Entry Point yet. Please create a SEP </span
        ><router-link :to="{ name: 'bases' }"> here</router-link>
      </div>
      <br />
      <el-col :span="6" class="search">
        <el-input
          placeholder="Find products"
          v-model="search"
          prefix-icon="Search"
        >
          <template #suffix>
            <el-icon v-if="isSubset" @click="clearSearch">
              <CircleClose />
            </el-icon>
          </template>
        </el-input>
        <div v-if="isSubset">
          showing {{ filteredSoftware.length }} of
          {{ softwareShopItems.length }}
        </div>
      </el-col>
      <br />
      <br />
      <div style="width: 85%; margin: 0 auto">
        <el-button
          style="margin-bottom: 20px"
          type="primary"
          v-if="selectedSoftware.length !== 0"
          @click="confirmOrderSelectedSoftware"
          >Order Selected Software</el-button
        >
        <el-table
          :data="filteredSoftware"
          v-loading="loading"
          @selection-change="handleSWSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column
            label="Architecture"
            prop="architecture"
          ></el-table-column>
          <el-table-column label="Language" prop="language"></el-table-column>
          <el-table-column label="Version" prop="version"></el-table-column>
          <el-table-column label="Vendor" prop="vendor"></el-table-column>
          <el-table-column label="icon">
            <template v-slot="scope">
              <img
                v-if="scope.row.imgsrc !== undefined"
                :src="scope.row.imgsrc[0]"
                height="40"
                width="40"
              />
              <img
                v-else
                src="../../../static/packages-icon-21.png"
                height="40"
                width="40"
              />
            </template>
          </el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <el-button type="primary" @click="confirmOrder(scope.row)"
                >Order</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import { getSEPs } from "../../services/customers";
import FilterMixin from "../../mixins/FilterMixin";
import { successMessage, errorMessage } from "../../store/modules/util";

export default {
  mixins: [FilterMixin],
  created() {
    var customerId = impersonation.getCustomer().id;
    getSEPs(customerId)
      .then((response) => {
        if (response.data.length !== 0) {
          this.showHintBanner = false;
        } else {
          this.showHintBanner = true;
        }
      })
      .catch(() => {
        this.showHintBanner = true;
      });
    this.$store.dispatch("getSoftwareShopItems", customerId);
    this.$store.dispatch("getCustomersSoftware", customerId);
  },
  data() {
    return {
      selectedSoftware: [],
      search: "",
      showHintBanner: false,
      loading: false,
    };
  },
  methods: {
    confirmOrderSelectedSoftware() {
      this.$confirm(
        "Do you want to order the selected software. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.orderSelectedSoftware.bind(this), this.donothing);
    },
    orderSelectedSoftware() {
      var customerId = impersonation.getCustomer().id;
      let payload = {
        selectedSoftwares: this.selectedSoftware,
        customerId: customerId,
      };
      this.loading = true;
      this.$store
        .dispatch("orderSelectedSoftware", payload)
        .then((response) => {
          successMessage(
            "Thanks! We have received your order. Your Software is now available."
          );
          let responseArray = response.data;
          responseArray.forEach((item) => {
            this.$store.commit("addCustomersSoftware", item);
          });
        })
        .catch((error) => {
          if (
            error.response.data.includes(
              "The csdp Cloud Entry Point is not set"
            ) ||
            error.response.data.includes(
              "The csdp storage entry point does not exist or has not been created yet"
            )
          ) {
            this.$router.push("bases");
          }
          errorMessage(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmOrder(shopitem) {
      this.$confirm(
        "Do you want to order the " + shopitem.name + " software. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.orderSoftware.bind(this, shopitem), this.donothing);
    },
    donothing() {},
    orderSoftware(shopitem) {
      let payload = {
        shopItem: shopitem,
        customerId: impersonation.getCustomer().id,
      };
      this.loading = true;
      this.$store
        .dispatch("orderSoftware", payload)
        .then((response) => {
          successMessage(
            "Thanks! We have received your order. Your Software is now available."
          );
          this.$store.commit("addCustomersSoftware", response.data);
        })
        .catch((error) => {
          if (
            error.response.data.includes(
              "The csdp Cloud Entry Point is not set"
            ) ||
            error.response.data.includes(
              "The csdp storage entry point does not exist or has not been created yet"
            )
          ) {
            this.$router.push("bases");
          }
          errorMessage(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearSearch() {
      this.search = "";
    },
    handleSWSelectionChange(val) {
      this.selectedSoftware = val;
    },
  },
  computed: {
    ...mapState(["softwareShopItems", "customersSoftware"]),
    filteredSoftware() {
      for (let i = 0; i < this.customersSoftware.length; i++) {
        let temp = this.customersSoftware[i];
        for (let j = 0; j < this.softwareShopItems.length; j++) {
          if (this.softwareShopItems[j].id === temp.softwareId) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.softwareShopItems.splice(j, 1);
            break;
          }
        }
      }
      return this.filterEntities(this.softwareShopItems, this.search);
    },
    isSubset() {
      return this.softwareShopItems.length !== this.filteredSoftware.length;
    },
  },
};
</script>

<style lang="css" scoped>
.alert-banner {
  border-radius: 20px;
  background-color: #ffdb1f;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  min-height: 25px;
  height: 25px;
}
</style>
