<template>
  <el-dialog v-model="showDialog" title="Add user" :before-close="handleClose">
    <el-form ref="form" :model="user" :rules="rules" label-width="180px">
      <el-form-item prop="firstName" label="First Name">
        <el-row>
          <el-col :span="16">
            <el-input
              v-model="user.firstName"
              placeholder="First Name"
            ></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right" v-if="isAdmin">
            <el-switch
              v-model="user.active"
              active-color="#13ce66"
              active-text="Active"
              inactive-text=""
              :width="70"
            ></el-switch>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="familyName" label="Family Name">
        <el-row>
          <el-col :span="16">
            <el-input
              v-model="user.familyName"
              placeholder="Family Name"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="email" label="Email">
        <el-input v-model="user.email" placeholder="Email"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="Initial Password">
        <div class="flex">
          <el-input
            disabled
            autocomplete="new-password"
            prop="password"
            v-model="user.password"
          ></el-input>
        </div>
        <el-button @click="setPassword"
          ><el-icon><Refresh /></el-icon
        ></el-button>
      </el-form-item>
      <!-- <el-form-item prop="confirmPwd" label="Confirm Initial Password">
        <el-input
          type="password"
          prop="confirmPwd"
          v-model="user.confirmPwd"
        ></el-input>
      </el-form-item> -->
      <el-form-item prop="systemhouse" label="Access Rights">
        <el-row>
          <el-col :span="16">
            <el-cascader
              v-model="cascader"
              ref="cascader"
              :props="{ expandTrigger: 'hover' }"
              :options="accessRights"
              @change="accessRightsChange"
              filterable
              style="width: 100%"
            >
            </el-cascader>
          </el-col>
          <el-col :span="8" style="text-align: right" v-if="isAdmin">
            <el-checkbox v-model="user.admin">Administrator</el-checkbox>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(user)">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import Auth from "../../auth";
import Permission from "../../mixins/Permission";
import { mapState } from "vuex";
import isEmailUnique from "../../validations/isEmailUnique";

export default {
  name: "user-add",
  components: {},
  mixins: [Permission],
  data() {
    // let validatePass = (rule, value, callback) => {
    //   let username = this.user.name;
    //   let symbolexp = /[[\]{}()/\\]/;
    //   let regexp =
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.=_+<>#-:,;])[A-Za-z\d@$!%*?&.=_+<>#-:,;]{8,}$/;
    //   if (this.user.password === "") {
    //     callback(new Error("Please input the password"));
    //   }
    //   if (this.user.password.length < 8) {
    //     callback(new Error("Password must be minimum 8 characters long"));
    //   }
    //   if (username !== "" && this.user.password !== "") {
    //     if (this.user.password.includes(username)) {
    //       callback(new Error("Password must not contain username"));
    //     }
    //   }
    //   if (this.user.password.match(symbolexp)) {
    //     callback(
    //       new Error(
    //         "[ ] { } (  ) / \\ characters are not allowed in the password."
    //       )
    //     );
    //   }
    //   if (!this.user.password.match(regexp)) {
    //     callback(
    //       new Error(
    //         "Password must have a digit, uppercase and lowercase letter and one of these characters @$!%*?&.=_+<>#-:,;"
    //       )
    //     );
    //   } else {
    //     if (this.user.confirmPwd !== "") {
    //       this.$refs.form.validateField("confirmPwd");
    //     }
    //     callback();
    //   }
    // };
    // let validatePass2 = (rule, value, callback) => {
    //   if (this.user.confirmPwd === "") {
    //     callback(new Error("Please input the password again"));
    //   } else if (this.user.confirmPwd !== this.user.password) {
    //     callback(new Error("Passwords do not match"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      showDialog: false,
      user: {
        firstName: "",
        familyName: "",
        admin: false,
        active: true,
        email: "",
        password: "",
        role: "",
        systemhouse: "",
        customer: "",
      },
      overrideLabel: "Confirm password",
      cascader: [],
      rules: {
        // password: [
        //   { validator: validatePass, required: true, trigger: "change" },
        // ],
        firstName: [
          {
            required: true,
            message: "Please set your first name",
            trigger: "blur",
          },
        ],
        familyName: [
          {
            required: true,
            message: "Please set your family name",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "Please input email", trigger: "blur" },
          {
            type: "email",
            message: "Please input valid email address",
            trigger: "blur",
          },
          { validator: isEmailUnique, trigger: "blur" },
        ],
        systemhouse: [
          {
            required: true,
            message: "Please specify the access rights",
            trigger: "change",
          },
        ],
        // confirmPwd: [
        //   { validator: validatePass2, required: true, trigger: "change" },
        // ],
      },
    };
  },
  methods: {
    setPassword() {
      this.user.password = this.createPassword();
    },
    open() {
      this.showDialog = true;
      this.setPassword();
    },
    handleClose(done) {
      if (this.$refs["form"] !== undefined) {
        this.$refs["form"].resetFields();
        this.cascader = [];
      }
      done();
    },
    accessRightsChange([role, entityId]) {
      let foundSysHouse = _.find(this.$store.state.systemhouses, {
        id: entityId,
      });
      console.log({ foundSysHouse });
      this.user.role = role;
      if (role === "systemhouse") {
        this.user.systemhouse = _.find(this.$store.state.systemhouses, {
          id: entityId,
        });
        this.user.customer = "";
      }
      if (role === "customer") {
        this.user.systemhouse = _.find(this.$store.state.customer.customers, {
          id: entityId,
        }).systemhouse.id; // get sh of customer
        this.user.customer = entityId;
      }
    },
    submit(user) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.user.admin === true && this.user.role === "customer") {
            this.$message.error(
              "User could not be created. An admin has to be a Systemhouse Manager."
            );
          } else {
            this.$store.dispatch("addUser", _.cloneDeep(user));
            this.showDialog = false;
            this.$refs.form.resetFields();
            this.user.admin = false;
            this.user.active = true;
            this.user.systemhouse = "";
            this.user.customer = "";
            this.user.role = "";
            this.cascader = [];
          }
          // this.user.systemhouse = ''
          // this.user.customer = ''
        }
      });
    },
    createPassword() {
      var result = "";
      const valid =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!#$%^&()_+~|}{[]:;?,./-=";
      var iteratorIndex = 0;
      while (iteratorIndex < 15) {
        result = result + valid[Math.floor(Math.random() * valid.length)];
        iteratorIndex++;
      }
      return result;
    },
  },
  computed: {
    ...mapState(["systemhouses", "customers"]),
    accessRights() {
      let accessRights = [];
      let systemhouses = [];
      if (this.isAdmin) {
        systemhouses = this.$store.state.systemhouses.map((sh) => {
          return { value: sh.id, label: sh.name };
        });
        accessRights.push({
          value: "systemhouse",
          label: "Systemhouse Manager",
          children: systemhouses,
        });
      } else {
        const sh = JSON.parse(Auth.getAuth().systemhouse);
        systemhouses = [{ value: sh.id, label: sh.name }];
        accessRights.push({
          value: "systemhouse",
          label: "Systemhouse Manager",
          children: systemhouses,
        });
      }
      // accessRights.push({
      //   value: "customer",
      //   label: "Customer Manager",
      //   children: this.$store.state.customer.customers.map((customer) => {
      //     return { value: customer.id, label: customer.name };
      //   }),
      // });
      return accessRights;
    },
  },
};
</script>
