<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input placeholder="Find customer" v-model="search" autofocus>
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredCustomerDrivers.length }} of
              {{ customerDrivers.length }}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div style="width: 85%; margin: 0 auto">
      <el-table :data="filteredCustomerDrivers">
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column
          label="Description"
          prop="description"
        ></el-table-column>
        <el-table-column label="Vendor" prop="vendor"></el-table-column>
        <el-table-column label="Version" prop="version"></el-table-column>
        <!-- <el-table-column>
            <template slot-scope="scope">
              <el-button type="primary" @click="confirmOrder(scope.row)">Order</el-button>
            </template>
          </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";

export default {
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getCustomerDrivers", impersonation.getCustomer().id);
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    ...mapState(["customerDrivers"]),
    isSubset() {
      return (
        this.customerDrivers.length !== this.filteredCustomerDrivers.length
      );
    },
    filteredCustomerDrivers() {
      return this.filterEntities(this.customerDrivers, this.search);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
