<template>
  <el-dialog v-model="showDialog" title="Add Device Property">
    <el-form ref="form" :model="deviceProperty" label-width="180px">
      <el-form-item
        label="Name"
        prop="propertyName"
        :rules="rules.propertyName"
      >
        <el-input
          v-model="deviceProperty.propertyName"
          placeholder="Device property name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Category" prop="category" :rules="rules.category">
        <el-select
          v-model="category"
          filterable
          allow-create
          default-first-option
          placeholder="Choose category"
          id="category-selection"
        >
          <el-option
            v-for="item in devicePropCategory"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Command" prop="command">
        <el-input
          type="textarea"
          v-model="deviceProperty.command"
          placeholder="Device property command"
          :autosize="{ minRows: 5 }"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Parameter Name"
        prop="parameterName"
        :rules="rules.parameterName"
      >
        <el-input
          v-model="deviceProperty.parameterName"
          placeholder="Choose the name for the dedicated created client parameter"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submit()">Add</el-button>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import DevicePropertyValidation from "./DevicePropertyValidation";

export default {
  name: "device-properties-add",
  mixins: [DevicePropertyValidation],
  data() {
    let validatecategory = (rule, value, callback) => {
      let vm = this;
      if (vm.category.length > 0) {
        vm.deviceProperty.category = { name: vm.category };
        callback();
      } else {
        callback(new Error("Please select at least one category"));
      }
    };

    return {
      showDialog: false,
      deviceProperty: {
        propertyname: "",
        command: "",
        category: {},
        parameterName: "",
      },
      category: "",
      rules: {
        category: [
          {
            validator: validatecategory,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.$store.dispatch("getDevicePropCategory");
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.deviceProperty.category = { name: this.category };
          this.$store.dispatch(
            "addDeviceProperty",
            _.cloneDeep(this.deviceProperty)
          );
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
    resetForm() {
      this.deviceProperty = {
        name: "",
        command: "",
        category: {},
      };
      // Empty array - causes vue warning but does not affect functionality
      this.category = [];
    },
  },
  computed: {
    ...mapState(["devicePropCategory", "devicePropertiesLoading"]),
  },
};
</script>

<style>
.error {
  color: red;
  opacity: 0.65;
  padding: 0;
  font-size: 12px;
}
</style>
