<template>
  <el-form-item label="Storage Account Name" :prop="prop" :rules="rules.name">
    <el-input v-model="model" @change="change" placeholder="Storage account name"></el-input>
  </el-form-item>
</template>

<script>
import StorageAccountValidation from './StorageAccountValidation'

export default {
  name: 'storage-account-name',
  props: ['value', 'prop'],
  mixins: [
    StorageAccountValidation
  ],
  data () {
    return {
      model: this.value
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    },
    resetModel () {
      this.model = ''
    }
  }
}
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
