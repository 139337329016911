export default {
  name: 'person-validation',
  data () {
    return {
      personRules: {
        /* title: [
          {
            required: true,
            message: 'Please insert title',
            trigger: 'blur'
          }
        ], */
        givenName: [
          {
            required: true,
            message: 'Please insert the given name',
            trigger: 'blur'
          }
        ],
        surname: [
          {
            required: true,
            message: 'Please insert the surname',
            trigger: 'blur'
          }
        ],
        /* employeeType: [
          {
            required: true,
            message: 'Please insert the employee type',
            trigger: 'change'
          }
        ],
        companyId: [
          {
            required: true,
            message: 'Please select the company the person is working at',
            trigger: 'change'
          }
        ],
        domain: [
          {
            required: true,
            message: 'Please insert the domain name',
            trigger: 'blur'
          }
        ], */
        /* departementShort: [
          {
            required: true,
            message: 'Please insert the abbreciation of the department',
            trigger: 'blur'
          }
        ], */
        /* departementName: [
          {
            required: true,
            message: 'Please insert the department name',
            trigger: 'blur'
          }
        ],
        roomNr: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: 'Please insert the room number of the office',
            trigger: 'blur'
          }
        ],
        costCenter: [
          {
            required: true,
            message: 'Please insert the cost center name',
            trigger: 'blur'
          }
        ],
        phoneNr: [
          {
            required: true,
            pattern: /^[+]*[0-9]*$/,
            message: 'Please insert the phone number',
            trigger: 'blur'
          }
        ], */
        /* faxNr: [
          {
            pattern: /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/g,
            message: 'Please insert a valid Fax number',
            trigger: 'blur'
          }
        ], */
        /* mobileNr: [
          {
            required: true,
            pattern: /^[+]*[0-9]*$/,
            message: 'Please insert the mobile phone number',
            trigger: 'blur'
          }
        ], */
        emailPrimary: [
          {
            required: true,
            pattern: /^[a-zA-Z0-9-_.]+@[a-zA-z0-9-_.]+.[a-zA-Z0-9-_.]+$/,
            message: 'Please input email like "<name>@<email-provider>.<domain>"',
            trigger: 'blur'
          }
        ]
        /* emailOptional: [
          {
            type: 'email',
            pattern: /^[a-zA-Z0-9-_.]+@[a-zA-z0-9-_.]+.[a-zA-Z0-9-_.]+$/,
            message: 'Please input email like "<name>@<email-provider>.<domain>"',
            trigger: 'blur'
          }
        ], */
        /* employeeNr: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: 'Please insert the employee number',
            trigger: 'blur'
          }
        ],
        state: [
          {
            required: true,
            message: 'Please insert the state',
            trigger: 'change'
          }
        ] */
      }
    }
  }
}
