<template>
  <div>
    <el-dialog
      v-model="showDialog"
      title="Stream Images"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="75%"
    >
      <el-col :span="22" :offset="1">
        <h3 style="text-align: center">OS Images</h3>
        <el-row type="flex" class="header">
          <el-col :span="6" class="search">
            <el-input placeholder="Find OS Image" v-model="search">
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
            <div class="results" v-if="isSubset">
              <div v-if="!search">
                showing {{ filteredImages.length }} of {{ images.length }}
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-row>
        <el-col :span="8" :offset="18"
          ><el-button type="primary" @click="openAddImage"
            >Add New Version</el-button
          ></el-col
        >
      </el-row>
      <el-table :data="filteredImages">
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column
          label="Description"
          prop="descriptionShort"
        ></el-table-column>
        <el-table-column label="Build Nr" prop="buildNr"></el-table-column>
        <el-table-column label="Published">
          <template v-slot="scope">
            <span v-if="scope.row.publishInShop">True</span>
            <span v-else>False</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <actions-dropdown
              :edit="true"
              @edit="openEditImage(scope.row)"
              :deleteItem="true"
              @delete="deleteImage(scope.row)"
              :hideRetreatFromShop="
                scope.row.publishInShop === false ? true : false
              "
              :publishInShop="true"
              @publishInShop="publishImage(scope.row)"
              @retreatFromShop="retreatImage(scope.row)"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <edit-image ref="editImage" @reloadImages="reloadImages"></edit-image>
    <add-image ref="addImage" @reloadImages="reloadImages"></add-image>
  </div>
</template>

<script>
import {
  getStreamsImages,
  publishInShop,
  retreatFromShop,
} from "../../services/swImages";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import EditImage from "../swImages/EditImage.vue";
import AddSWImage from "../swImages/AddSWImage.vue";
// import AddImage from './AddImage.vue'

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "add-image": AddSWImage,
    "edit-image": EditImage,
  },
  data() {
    return {
      search: "",
      showDialog: false,
      streamId: "",
      subFolderName: "",
      images: [],
      streamName: "",
      streamType: "",
    };
  },
  methods: {
    publishImage(image) {
      publishInShop(image.id)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < this.images.length; i++) {
            if (this.images[i].id === data.id) {
              this.images.splice(i, 1, data);
              break;
            }
          }
          this.$store.dispatch("getImageStreams");
        })
        .catch((error) => console.log({ error }));
    },
    retreatImage(image) {
      retreatFromShop(image.id)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < this.images.length; i++) {
            if (this.images[i].id === data.id) {
              this.images.splice(i, 1, data);
              break;
            }
          }
          this.$store.dispatch("getImageStreams");
        })
        .catch((error) => console.log({ error }));
    },
    openAddImage() {
      this.$refs.addImage.openDialog(
        this.streamId,
        this.subFolderName,
        this.streamName,
        this.streamType
      );
    },
    reloadImages() {
      getStreamsImages(this.streamId)
        .then((response) => {
          this.images = response.data;
        })
        .catch((error) => console.log({ error }));
    },
    openEditImage(image) {
      this.$refs.editImage.openDialog(
        image,
        this.subFolderName,
        this.streamType
      );
    },
    openDialog(streamId, subFolderName, streamName, streamType) {
      this.streamName = streamName;
      this.subFolderName = subFolderName;
      this.streamId = streamId;
      this.streamType = streamType;
      getStreamsImages(streamId)
        .then((response) => {
          this.images = response.data;
        })
        .catch((error) => console.log({ error }));
      this.showDialog = true;
    },
    handleClose() {
      this.streamId = "";
      this.showDialog = false;
    },
    deleteImage(image) {
      this.$confirm(
        "This will permanently delete the imnage. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, image), this.deleteError);
    },
    deleteSuccess(image) {
      this.$store.dispatch("deleteSWImage", image.id).then(() => {
        this.images.forEach((element, index) => {
          if (element == image) {
            this.images.splice(index, 1);
          }
        });
      });
    },
    deleteError() {},
  },
  computed: {
    filteredImages() {
      return this.filterEntities(this.images, this.search);
    },
    isSubset() {
      return this.images.length !== this.filteredImages.length;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
