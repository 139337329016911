<template>
  <el-dialog v-model="showDialog" title="Add domain Registration">
    <el-form ref="form" :model="domainreg" label-width="180px">
      <el-form-item
        label="Name"
        prop="name"
        :rules="[{ required: true, message: 'domain name is required' }]"
      >
        <el-input v-model="domainreg.name" placeholder="Domain name"></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(domainreg)">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "domainreg-add",
  data() {
    return {
      showDialog: false,
      files: [],
      domainreg: {
        name: "",
      },
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    submit(domainreg) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addDomainreg", _.cloneDeep(domainreg));
          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
  },
};
</script>
