<template>
  <el-form-item :label="setLabel" :prop="prop" :rules="setRule">
    <el-input
      v-model="model"
      @change="change"
      placeholder="Password"
      :type="type"
    >
      <!-- slot="suffix" -->
      <!-- <i class="el-input__icon el-icon-more clickable" @click="toggleMask"></i> -->
    </el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "password-input",
  props: ["value", "prop", "overrideRule", "overridelabel"],
  data() {
    return {
      type: "password",
      model: this.value,
      rules: [
        { required: true, message: "Password is required", trigger: "blur" },
      ],
    };
  },
  methods: {
    toggleMask() {
      this.type = this.type === "password" ? "text" : "password";
    },
    change(value) {
      this.$emit("input", value);
    },
    resetValue() {
      this.model = "";
    },
  },
  computed: {
    setRule() {
      if (this.overrideRule) {
        return this.overrideRule;
      } else {
        return this.rules;
      }
    },
    setLabel() {
      if (this.overridelabel) {
        return this.overridelabel;
      } else {
        return "Password";
      }
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
