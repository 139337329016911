export default{
  name: 'filter-mixin-category',
  data () {
    return {
      searchcategory: ''
    }
  },
  methods: {
    clearSearchCategory () {
      this.searchcategory = ''
    },
    filterCategory (entities, query, property = 'name') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity.categories.find(function (obj) { return obj[property].toLowerCase().indexOf(query) !== -1 })) {
            return entity
          }
        })
      }
      return result
    }
  }
}
