<template lang="html">
  <el-dialog
    v-model="showDialog">
    <div v-if="!typeChosen" class="centered">
      <h3>Please select the type of the location</h3>
      <el-select
        placeholder="Select Type"
        v-model="formModel.type">
        <el-option
          v-for="type in types"
          :key="type.key"
          :lable="type.name"
          :value="type.name">
        </el-option>
      </el-select>
      <!-- icon="el-icon-success" -->
      <el-button
        type="primary"
        @click="typeIsChosen"
        >Continue</el-button>
    </div>
    <el-form
      v-else
      :model="formModel"
      :rules="locationRules"
      ref="add-location-form">
      <el-form-item label="Name" prop="name">
        <el-input
          placeholder="Please insert the name"
          v-model="formModel.name"></el-input>
      </el-form-item>
      <el-form-item label="Name (abbr.)" prop="nameAbbreviation">
        <el-input
          placeholder="Please insert the name abbreviation"
          v-model="formModel.nameAbbreviation"></el-input>
      </el-form-item>
      <div v-if="isInCloud()">
        <el-form-item label="Azure Locations" prop="azureLocation">
          <el-select
            placeholder="Select Azure Location"
            v-model="formModel.azureLocation"
            filterable>
            <el-option
              v-for="azureLocation in azureLocations"
              :key="azureLocation.name"
              :label="azureLocation.displayName"
              :value="azureLocation.displayName">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="Country" prop="country">
          <country-selection @setCountryData="fillCountryData"></country-selection>
        </el-form-item>
        <el-form-item label="Time Zone" prop="timeZone">
          <el-select
           v-model="formModel.timeZone"
           placeholder="Select Timezone">
           <el-option
             v-for="timeZone in timeZones"
             :key="timeZone.zoneName"
             :value="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
             :label="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
             >
           </el-option>
          </el-select>
          <p class="error" v-if="timeZones.length === 0">
           Please select the country first
          </p>
        </el-form-item>
        <el-form-item label="City" prop="city">
          <el-input
            placeholder="Please insert the city"
            v-model="formModel.city"></el-input>
        </el-form-item>
        <el-form-item label="City (abbr.)" prop="cityAbbreviation">
          <el-input
            placeholder="Please insert the city abbreviation"
            v-model="formModel.cityAbbreviation"></el-input>
        </el-form-item>
        <el-form-item label="Street" prop="street">
          <el-input
            placeholder="Please insert the street"
            v-model="formModel.street"></el-input>
        </el-form-item>
        <el-form-item label="Number" prop="number">
          <el-input
            placeholder="Please insert the house number"
            v-model="formModel.number"></el-input>
        </el-form-item>
        <el-form-item label="Postcode" prop="postcode">
          <el-input
            placeholder="Please insert the postcode"
            v-model="formModel.postcode"></el-input>
        </el-form-item>
        <el-form-item label="Public IP" prop="publicIp">
          <el-input
            placeholder="Please insert the public IP address"
            v-model="formModel.publicIp"></el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item label="Download Speed" prop="downloadSpeed">
            <el-input placeholder="Please insert download speed (kbit/s)" v-model="formModel.downloadSpeed"></el-input>
          </el-form-item>
          <el-form-item label="Upload Speed" prop="uploadSpeed">
            <el-input placeholder="Please insert upload speed (kbit/s)" v-model="formModel.uploadSpeed"></el-input>
          </el-form-item>
        </div>
      </div>
      <!-- icon="el-icon-plus" -->
      <el-button
        type="primary"        
        @click="submit('add-location-form')">
          Add
      </el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import impersonation from "../../impersonation";
import CountrySelection from "./CountrySelection";
import LocationValidationRules from "./LocationValidationRules";

export default {
  name: "add-location-dialog",
  components: { CountrySelection },
  mixins: [LocationValidationRules],
  props: ["loadLocations", "showCompanies"],
  data() {
    return {
      showDialog: false,
      typeChosen: false,
      formModel: {
        name: "",
        nameAbbreviation: "",
        country: "",
        countryAbbreviation: "",
        city: "",
        cityAbbreviation: "",
        street: "",
        number: "",
        postcode: "",
        publicIp: "",
        company: "",
        uploadSpeed: "",
        downloadSpeed: "",
        type: "",
        timeZone: "",
        azureLocation: "",
      },
      types: [
        {
          key: 1,
          name: "on-prem",
        },
        {
          key: 2,
          name: "in-cloud",
        },
      ],
      timeZones: [],
    };
  },
  methods: {
    openDialog() {
      this.typeChosen = false;
      this.formModel.type = "";
      this.showDialog = true;
    },
    isInCloud() {
      return this.formModel.type === "in-cloud";
    },
    typeIsChosen() {
      if (
        this.formModel.type === "on-prem" ||
        this.formModel.type === "in-cloud"
      ) {
        this.typeChosen = true;
      }
    },
    fillCountryData(countryData) {
      this.formModel.country = countryData.name;
      this.formModel.countryAbbreviation = countryData.code;
      this.timeZones = countryData.zones;
      this.formModel.timeZone = "";
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let payload = this.createPayloadData();
          this.$store.dispatch("addLocation", payload);
          this.$refs[formName].resetFields();
          this.showDialog = false;
        }
      });
    },
    createPayloadData() {
      let model = this.formModel;
      return {
        name: model.name,
        nameAbbreviation: model.nameAbbreviation,
        country: model.country,
        countryAbbreviation: model.countryAbbreviation,
        city: model.city,
        cityAbbreviation: model.cityAbbreviation,
        street: model.street,
        number: model.number,
        postcode: model.postcode,
        publicIp: model.publicIp,
        uploadSpeed: model.uploadSpeed,
        downloadSpeed: model.downloadSpeed,
        type: model.type,
        timeZone: model.timeZone,
        azureLocation: model.azureLocation,
        customerId: impersonation.getCustomer().id,
        companyId:
          this.showCompanies === false && this.compId !== undefined
            ? this.compId
            : model.company,
      };
    },
    computeTimeZone(zoneName, gmtOffset) {
      return zoneName + " (GMT" + gmtOffset + ")";
    },
  },
  computed: {
    azureLocations: {
      get() {
        return this.$store.state.locations;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
