export function getScripts () {
  return window.axiosInstance.get('/scripts')
}

export function getScript (script) {
  return window.axiosInstance.get(`/scripts/${script.id}`)
}

export function addScript (script) {
  return window.axiosInstance.post('/scripts', script)
}

export function editScript (script) {
  return window.axiosInstance.put(`/scripts/${script.id}`, script)
}

export function addScriptVersion (script) {
  return window.axiosInstance.post(`/scripts/${script.id}/versions`, script)
}

export function scriptGetParams (customerId, baseId, domainId, scriptVersionId) {
  return window.axiosInstance.get(`/customers/${customerId}/bases/${baseId}/domains/${domainId}/script/${scriptVersionId}`)
}

export function scriptExecute (customerId, baseId, domainId, scriptVersionId, parameters) {
  return window.axiosInstance.post(`/customers/${customerId}/bases/${baseId}/domains/${domainId}/script/${scriptVersionId}`, parameters)
}

export function getLatestBitStreamScriptVersion (actionName, clientId, customerId, data) {
  if (data === undefined) {
    data = {}
  }
  return window.axiosInstance.post(`/scripts/bitstreamAction/${actionName}/${clientId}/${customerId}`, data)
}
