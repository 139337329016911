export function addReleasePlan (payload) {
  return window.axiosInstance.post('release-plans', payload)
}

export function getCustomersReleasePlans (customerId) {
  return window.axiosInstance.get(`release-plans/${customerId}`)
}

export function editReleasePlan (payload) {
  return window.axiosInstance.put('release-plans', payload)
}

export function deleteReleasePlan (releasePlanId) {
  return window.axiosInstance.delete(`release-plans/delete/${releasePlanId}`)
}
