export function getRules () {
  return window.axiosInstance.get('rules')
}

export function addRule (ruleModel) {
  return window.axiosInstance.post('rules', ruleModel)
}

export function updateRule (ruleModel) {
  return window.axiosInstance.put('rules', ruleModel)
}

export function deleteRule (ruleId) {
  return window.axiosInstance.delete(`rules/${ruleId}`)
}

export function getSoftwareRules (softwareRule) {
  return window.axiosInstance.get(`rules/${softwareRule}`)
}
