<template>
  <el-row class="under-construction">
    <el-col :span="22" :offset="1">
      <h2>{{ title }}</h2>
      <el-alert
        title="Under Construction"
        type="warning"
        description="This screen is currently being implemented."
        :closable="false"
        show-icon>
      </el-alert>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: 'under-construction',
    props: ['title']
  }
</script>

<style scoped>
  .under-construction {
    text-align: left;
    margin-top: 40px;
  }
</style>
