<template>
  <div>
    <el-dialog
      v-model="showDialog"
      title="Import software"
      :close-on-click-modal="false"
    >
      <el-form :model="model" ref="form" :rules="rules">
        <el-form-item label="Path" prop="path">
          <el-upload
            :action="uploadURL"
            accept=".zip"
            :on-success="handleSuccess"
            :on-error="handleError"
            :headers="headers"
            ref="upload"
            :auto-upload="false"
            :limit="1"
          >
            <template #trigger>
              <el-button type="primary">Select File(s)</el-button>
            </template>
            <el-button class="ml-3" type="success" @click="uploadFile">
              Upload to Server
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>
    <software-add ref="addSoftware"></software-add>
  </div>
</template>

<script>
import SoftwareAdd from "./SoftwareAdd.vue";

export default {
  name: "import-software",
  components: {
    SoftwareAdd,
  },
  data() {
    return {
      streamId: "",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      uploadURL:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/software/importSoftware",
      rules: {
        path: [
          { required: true, message: "The path is required", trigger: "blur" },
        ],
      },
      showDialog: false,
      model: {
        path: "",
      },
    };
  },
  methods: {
    handleError(error) {
      this.$message.error(error.message);
    },
    openDialog(streamId) {
      this.streamId = streamId;
      this.uploadURL += "/" + this.streamId;
      this.showDialog = true;
    },
    uploadFile() {
      this.$refs.upload.submit();
    },
    handleSuccess(a) {
      var json = a;
      this.$store.state.softwares.push(json);
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
