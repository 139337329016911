<template>
  <div>
    <el-col :span="22" :offset="1">
      <br />
      <div class="alert-banner" v-if="showHintBanner">
        <span
          >You dont have created a Storage Entry Point yet. Please create a SEP </span
        ><router-link :to="{ name: 'bases' }"> here</router-link>
      </div>
      <br />
      <el-row type="flex" class="header">
        <el-col :span="6">
          <el-select
            v-model="selectedCategory"
            placeholder="Please select a category"
          >
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="search">
          <el-input
            placeholder="Find products"
            v-model="search"
            @input="setShop"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon v-if="isSubset" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
          <div class="results" v-if="isSubset">
            <div v-if="selectedCategory === 'Hardware'">
              showing {{ filteredProducts.length }} of {{ buyImages.length }}
            </div>
            <div v-else-if="selectedCategory === 'Software'">
              showing {{ filteredProducts.length }} of
              {{ softwareShopItems.length }}
            </div>
            <div v-else>
              showing {{ filteredProducts.length }} of {{ buyOSImages.length }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-loading="loadingCustomerSoftware" style="margin: 0 -16px">
        <el-col :span="8" v-for="product in filteredProducts" :key="product.id">
          <el-col>
            <el-card
              class="box-card"
              shadow="hover"
              style="
                margin: 0 16px 32px 16px;
                padding-bottom: 8px;
                height: 350px;
              "
            >
              <el-carousel
                v-if="product.imgsrc !== null"
                indicator-position="none"
                :autoplay="false"
                height="150px"
              >
                <el-carousel-item
                  style="display: block"
                  v-for="(prodImg, index) in product.imgsrc"
                  :key="index"
                >
                  <img :src="prodImg" class="image" />
                </el-carousel-item>
              </el-carousel>
              <div v-else style="height: 150px">
                <img src="../../../static/packages-icon-21.png" class="image" />
              </div>
              <div style="text-align: left">
                <div class="name">{{ product.name }}</div>
                <div class="description">{{ product.description }}</div>
                <div class="price"></div>
                <span
                  >{{ "Price: " + toCurrenc(product.bruttoPrice) }} €
                  brutto</span
                >
                <div class="price">
                  {{
                    "Managed Service: " +
                    toCurrency(product.bruttoManagedServicePrice)
                  }}
                  € brutto/month
                </div>
                <div class="price">
                  {{
                    "Managed Service incl. Lifecycle: " +
                    toCurrency(product.bruttoManagedServiceLifecyclePrice)
                  }}
                  € brutto/month
                </div>
                <div class="categories">
                  <div
                    v-for="index in product.categories"
                    :key="index.id"
                    style="display: inline; margin-left: 5px"
                  >
                    <el-tag size="small">{{ index.name }}</el-tag>
                  </div>
                </div>
              </div>
              <el-button
                type="text"
                class="button"
                @click="confirmOrder(product)"
                >Order Now</el-button
              >
            </el-card>
          </el-col>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import FilterMixinCategory from "../../mixins/FilterMixinCategory";
import impersonation from "../../impersonation";
// import _ from 'lodash'
import { addOrder } from "../../services/order";
import { successMessage, errorMessage } from "../../store/modules/util";
import { getSEPs } from "../../services/customers";

export default {
  name: "buy",
  mixins: [FilterMixin, FilterMixinCategory],
  beforeCreate() {
    var customerId = impersonation.getCustomer().id;
    getSEPs(customerId)
      .then((response) => {
        if (response.data.length !== 0) {
          this.showHintBanner = false;
        } else {
          this.showHintBanner = true;
        }
      })
      .catch(() => {
        this.showHintBanner = true;
      });
    this.$store.dispatch("getBuyDetails");
    this.$store.dispatch("getShopItemsCategories");
  },
  data() {
    return {
      showHintBanner: false,
      selectedCategory: "Hardware",
      searchVar: false,
      loadingScreenHW: false,
    };
  },
  methods: {
    handleClose() {
      // Dialog cannot be closed
    },
    confirmOrder(shopitem) {
      this.$confirm("Do you want to order this item. Continue?", "Confirm", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(this.orderItem.bind(this, shopitem), this.donothing);
    },
    orderItem(shopitem) {
      if (
        this.selectedCategory !== "Software" &&
        this.selectedCategory !== "Images"
      ) {
        let vm = this;
        let orderObj = {
          shopItems: [shopitem.id],
        };
        this.$store.state.loadingCustomerSoftware = true;
        addOrder(orderObj, impersonation.getCustomer().id)
          .then(() => {
            vm.$message({
              message: "Thanks! We have received your order.",
              type: "success",
            });
          })
          .finally(() => {
            this.$store.state.loadingCustomerSoftware = false;
          });
      } else if (this.selectedCategory === "Images") {
        let payload = {
          shopItem: shopitem,
          customerId: impersonation.getCustomer().id,
        };
        this.$store
          .dispatch("orderImage", payload)
          .then((response) => {
            successMessage(
              "Thanks! We have received your order. Your OS-Image is now available."
            );
            this.$store.commit("addCustomersOSImage", response.data);
          })
          .catch((error) => {
            if (
              error.response.data.includes(
                "The csdp Cloud Entry Point is not set"
              ) ||
              error.response.data.includes(
                "The csdp storage entry point does not exist or has not been created yet"
              )
            ) {
              this.$router.push("bases");
            }
            errorMessage(error.response);
          })
          .finally(() =>
            this.$store.commit("setCustomersSoftwareLoading", false)
          );
      }
    },
    donothing() {},
    setCategory() {
      this.searchVar = true;
    },
    setShop() {
      this.searchVar = false;
    },
  },
  computed: {
    ...mapState([
      "buyImages",
      "buyDetails",
      "softwareShopItems",
      "shopItemCategories",
      "customersSoftware",
      "loadingCustomerSoftware",
      "buyOSImages",
      "customersOSImages",
    ]),
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    categories() {
      let result = this.shopItemCategories;
      // let tempSoftware = result.find(val => {
      //   return val.id === 1
      // })
      // let tempImages = result.find(val => {
      //   return val.id === 2
      // })
      // if (tempSoftware === undefined) {
      //   result.push({id: 1, name: 'Software'})
      // }
      // if (tempImages === undefined) {
      //   result.push({id: 2, name: 'Images'})
      // }
      return result;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filteredProducts() {
      if (
        this.selectedCategory !== "Software" &&
        this.selectedCategory !== "Images"
      ) {
        let tempArray = [];
        for (let i = 0; i < this.buyImages.length; i++) {
          let temp = this.buyImages[i].categories.find((val) => {
            return val.name === this.selectedCategory;
          });
          if (temp !== undefined) {
            tempArray.push(this.buyImages[i]);
          }
        }
        return this.filterEntities(tempArray, this.search);
      } else if (this.selectedCategory === "Images") {
        for (let i = 0; i < this.customersOSImages.length; i++) {
          let temp = this.customersOSImages[i];
          for (let j = 0; j < this.buyOSImages.length; j++) {
            if (this.buyOSImages[j].id === temp.imageId) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.buyOSImages.splice(j, 1);
              break;
            }
          }
        }
        return this.filterEntities(this.buyOSImages, this.search);
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    isSubset() {
      if (this.selectedCategory !== "Software") {
        return this.buyImages.length !== this.filteredProducts.length;
      }
    },
  },
};
</script>

<style scoped>
.price {
  font-weight: bold;
  color: #5a5e66;
  font-size: 10px;
  font-family: "Helvetica";
}
.description {
  color: #5a5e66;
  font-size: 10px;
  font-family: "Helvetica";
  padding-bottom: 2px;
}
.name {
  color: #5a5e66;
  font-size: 10px;
  font-family: "Helvetica";
  padding-top: 10px;
  padding-bottom: 2px;
}
.button {
  padding: 2px;
  float: right;
  font-weight: bold;
}
.image {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  max-height: 100%;
}
.categories {
  margin-top: 10px;
}

.alert-banner {
  border-radius: 20px;
  background-color: #ffdb1f;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  min-height: 25px;
  height: 25px;
}
</style>
