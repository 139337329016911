<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Edit Rule">
    <el-form
      :model="rule"
      ref="form">
      <el-form-item label="Name" prop="name">
        <el-input v-model="rule.name" placeholder="Rule name"></el-input>
      </el-form-item>
      <el-form-item label="Type" prop="type">
        <el-select  v-model="rule.type" placeholder="Rule type">
          <el-option  v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
         <span style="float: right">
          Succession <el-switch v-model="rule.successon"></el-switch>
        </span>
      </el-form-item>
      <el-form-item v-if="this.rule.type === 'fileExists'" label="Architecture" prop="data" :rules="addArchitectureRule">
        <el-input v-model="rule.architecture" placeholder="Rule architecture"></el-input>
      </el-form-item>
      <div v-if="this.rule.type === 'fileExists'">
        <el-form-item label="Path" prop="path" :rules="addPathRule">
          <el-input v-model="rule.path" placeholder="Path"></el-input>
        </el-form-item>
        <el-form-item prop="checkVersionNr">
          <el-checkbox v-model="rule.checkVersionNr">Check Version Number</el-checkbox>
        </el-form-item>
        <el-form-item label="Version Nr" prop="versionNr">
          <el-input v-model="rule.versionNr" placeholder="Version Number"></el-input>
        </el-form-item>
      </div>
      <el-form-item v-if="this.rule.type === 'script'" label="File" prop="prop.">
        <upload-file :id="id" :action="uploadUrl" :file-list="files" :on-remove="handleRemove" :on-success="handleSuccess"></upload-file>
      </el-form-item>
      <el-button type="primary" @click="submit">Edit Rule</el-button>
    </el-form>
    <!-- <rule-input prop="ruleApplicability" v-model="rule"></rule-input> -->
  </el-dialog>
</template>

<script>
import RuleValidation from "./RuleValidation";
import UploadFile from "../shared/UploadFile";
import _ from "lodash";

export default {
  emits: ["refresh"],
  name: "edit-rule",
  components: {
    UploadFile,
  },
  mixins: [RuleValidation],
  data() {
    return {
      showDialog: false,
      uploadUrl: window.APPLICATION_SETTING_API_ENDPOINT_URL + "/rules/upload",
      rule: {
        name: "",
        type: "",
        architecture: "",
        successon: true,
        data: null,
        versionNr: "",
        checkVersionNr: false,
      },
      options: [
        {
          value: "script",
          label: "Script",
        },
        {
          value: "fileExists",
          label: "File exists",
        },
      ],
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.rule);
          this.$store.dispatch("updateRule", payload);
          this.$refs.form.resetFields();
          this.showDialog = false;
          this.$emit("refresh");
        }
      });
    },
    openDialog(rule) {
      this.rule = _.cloneDeep(rule);
      if (rule.type !== null || rule.type !== undefined) {
        this.rule.type = rule.type.name;
      }
      this.showDialog = true;
    },
    handleRemove() {
      this.setFiles(null);
    },
    handleSuccess(a, b) {
      this.setFiles(b);
      this.removeError();
    },
    setFiles(file) {
      if (file != null) {
        this.rule.data = { id: file.response.id, name: file.name };
      } else {
        this.rule.data = null;
      }
    },
  },
  computed: {
    addPathRule() {
      if (this.rule.type === "fileExists") {
        return this.rules.path;
      } else {
        return [];
      }
    },
    addArchitectureRule() {
      if (this.rule.type === "fileExists") {
        return this.rules.architecture;
      } else {
        return [];
      }
    },
    files() {
      if (this.rule.data && this.rule.data.name) {
        return [{ name: this.rule.data.name }];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
