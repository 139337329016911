export const shopitems = [
  {
    id: '1',
    name: 'Dell XPS 13',
    price: 699.89,
    description: 'Dell XPS 13 is the next generation of the most powerful office workstation',
    files: ['xps13-1.jpg', 'xps13-2.jpg', 'xps13-3.jpg']
  },
  {
    id: '2',
    name: 'Dell XPS 15',
    price: 779.89,
    description: 'Dell XPS 15 is the next generation of the most powerful office workstation',
    files: ['xps15-1.jpg', 'xps15-2.jpg', 'xps15-3.jpg', 'xps15-4.jpg']
  }
]

export const workflows = [
  {
    id: '1',
    name: 'Grant Admin Rights',
    description: 'Standard routine to run when a user recevies admin privileges'
  },
  {
    id: '2',
    name: 'Install new client',
    description: 'Routine to trigger notification for new client installation'
  },
  {
    id: '3',
    name: 'Remove expired client',
    description: 'Remove expired client from organization'
  }
]

export const domainregs = [
  {
    id: '1',
    name: 'bitstream.de'
  },
  {
    id: '2',
    name: 'bitstream.net'
  },
  {
    id: '3',
    name: 'bitstream.com'
  }
]

export const certs = [
  {
    id: '1',
    name: 'EU Datenschutz Bestimmungen',
    description: 'Standardklauseln des Datenschutz für Unternehmen in der Europäischen Union'
  },
  {
    id: '2',
    name: 'DSGVO',
    description: 'Datenschutz Grundverordnung der Europäischen Union'
  }
]

export const tasks = [
  {
    id: '1',
    name: 'Reboot',
    executable: 'reboot.bar',
    commandline: '',
    shellExec: false,
    files: ['reboot.bar']
  },
  {
    id: '2',
    name: '7zip',
    executable: '7z1604-x64.msi',
    commandline: '/qn /norestart',
    shellExec: true,
    files: ['7z1604-x64.msi']
  },
  {
    id: '3',
    name: 'ReadMe',
    executable: 'readme.txt',
    commandline: '',
    shellExec: false,
    files: ['readme.txt']
  },
  {
    id: '4',
    name: 'AwesomeSoft',
    executable: 'awesome1.msi',
    commandline: '',
    shellExec: false,
    files: ['awesome1.msi', 'awesome2.msi', 'awesome3.msi']
  }
]

export const scripts = [
  {
    id: 1,
    name: 'AdobeFlash.ps',
    description: 'Install Adobe Flash Plugin',
    versions: [
      {
        id: 1,
        version: 1
      },
      {
        id: 2,
        version: 2
      },
      {
        id: 3,
        version: 3
      }
    ]
  },
  {
    id: 2,
    name: 'MsOffice2003.ps',
    description: '(Re)Install Office 2003',
    versions: [
      {
        id: 1,
        version: 1
      },
      {
        id: 2,
        version: 2
      }
    ]
  },
  {
    id: 3,
    name: 'CrazyScript.ps',
    description: 'Do mysterious things...',
    versions: [
      {
        id: 1,
        version: 1
      },
      {
        id: 2,
        version: 2
      },
      {
        id: 3,
        version: 3
      },
      {
        id: 4,
        version: 4
      },
      {
        id: 5,
        version: 5
      }
    ]
  }
]

export const storageTypes = [
  {
    id: 'Standard_LRS',
    name: 'Standard LRS',
    description: 'Locally Redundant Storage'
  },
  {
    id: 'Premium_LRS',
    name: 'Premium LRS',
    description: 'Premium Locally Redundant Storage'
  },
  {
    id: 'Standard_ZRS',
    name: 'Standard ZRS',
    description: 'Zone Redundant Storage'
  },
  {
    id: 'Standard_GRS',
    name: 'Standard GRS',
    description: 'Geo Redundant Storage'
  },
  {
    id: 'Standard_RAGRS',
    name: 'Standard RAGRS',
    description: 'Read Access Geo Redundant Storage'
  }
]

export const clients = [
  {
    id: 'cdf2016b-67e1-4ba8-be1d-f5749cc3a90a',
    name: 'hp-bea',
    description: 'HP Workstation for Secretary',
    deploy: true,
    tasks: [
      tasks[1],
      tasks[2]
    ],
    lastPing: '2017-03-01T16:47:00+00:00'
  },
  {
    id: 'b4dff5fb-b21e-4d80-b3c2-75ac9d7a688a',
    name: 'thinkpad-x220t',
    description: 'Alwin\'s Workstation',
    deploy: true,
    tasks: [
      tasks[0]
    ],
    lastPing: '2017-03-01T16:42:00+00:00'
  },
  {
    id: 'b686bec4-55ef-4caa-9b28-22a85a946dd2',
    name: 'acer-330',
    description: 'Toms Acer Laptop',
    deploy: true,
    tasks: [
      tasks[1],
      tasks[3]
    ],
    lastPing: '2017-03-01T16:32:00+00:00'
  },
  {
    id: 'd591db78-49f1-417e-a66f-80dd48e8df34',
    name: 'hp-qtr',
    description: 'HP Client with Photoshop',
    deploy: false,
    tasks: [
      tasks[2]
    ],
    lastPing: '2017-03-01T16:21:00+00:00'
  },
  {
    id: '741344da-6ee4-4dc1-8042-f462fd6d69a3',
    name: 'thinkpad-pres',
    description: 'Presentation Notebook',
    deploy: true,
    tasks: [],
    lastPing: '2017-02-24T19:18:00+00:00'
  }
]

export const serverTypes = [
  {
    id: 'Standard_F1S_v2',
    name: 'Standard F1S v2'
  },
  {
    id: 'Standard_F2S_v2',
    name: 'Standard F2S v2'
  },
  {
    id: 'Standard_DS1_v2',
    name: 'Standard DS1 v2'
  },
  {
    id: 'Standard_DS2_v2',
    name: 'Standard DS2 v2'
  }
]

export const comPremDevices = [
  {
    id: '1',
    image: 'http://store.hp.com/wcsstore/hpusstore/Treatment/HP_ED800_DMini_q1fy17_ksp1_pdt.jpg',
    title: 'HP EliteDesk 800 G3 Desktop Mini PC',
    os: 'Windows 10 Pro 64',
    processor: 'Intel® Core™ i3-6500T',
    memory: '16GB DDR4-2133 RAM',
    ports: [
      'Desktop Mini 2nd DisplayPort',
      'Desktop Mini Serial Port',
      'Desktop Mini HDMI Port'
    ]
  },
  {
    id: '2',
    image: 'http://store.hp.com/wcsstore/hpusstore/Treatment/HP_ED800_DMini_q1fy17_ksp1_pdt.jpg',
    title: 'HP EliteDesk 800 G3 Desktop Mini PC',
    os: 'Windows 10 Pro 64',
    processor: 'Intel® Core™ i5-6500T',
    memory: '16GB DDR4-2133 RAM',
    ports: [
      'Desktop Mini 2nd DisplayPort',
      'Desktop Mini Serial Port',
      'Desktop Mini HDMI Port'
    ]
  },
  {
    id: '3',
    image: 'http://store.hp.com/wcsstore/hpusstore/Treatment/HP_ED800_DMini_q1fy17_ksp1_pdt.jpg',
    title: 'HP EliteDesk 800 G3 Desktop Mini PC',
    os: 'Windows 10 Pro 64',
    processor: 'Intel® Core™ i7-6500T',
    memory: '16GB DDR4-2133 RAM',
    ports: [
      'Desktop Mini 2nd DisplayPort',
      'Desktop Mini Serial Port',
      'Desktop Mini HDMI Port'
    ]
  },
  {
    id: '4',
    image: 'http://www.91-img.com/pictures/laptops/lenovo/lenovo-t430-23426qu-core-i5-3rd-gen-4-gb-500-gb-windows-7-pro-61998-large-1.jpg',
    title: 'Lenovo Thinkpad T430',
    os: 'Windows 10 Pro',
    processor: 'Intel® Core™ i5-3230M',
    memory: '4GB DDR3-1600 RAM',
    ports: [
      '2 x USB 3.0 slots',
      '2 x USB 2.0 slots',
      'SD Card Reader',
      'Headphone Jack',
      'Microphone Jack'
    ]
  },
  {
    id: '5',
    image: 'https://www.bhphotovideo.com/images/images1000x1000/lenovo_20fn002sus_14_thinkpad_t460_ultrabook_1241308.jpg',
    title: 'Lenovo Thinkpad T460',
    os: 'Windows 10 Pro',
    processor: '2.3 GHz Intel Core i5-6200U Dual-Core',
    memory: '4GB DDR3-1600 RAM',
    ports: [
      '2 x USB 3.0 slots',
      '2 x USB 2.0 slots',
      'SD Card Reader',
      'Headphone Jack',
      'Microphone Jack'
    ]
  },
  {
    id: '6',
    image: 'http://www.lenovo.com/shop/americas/content/img_lib/subseries/T560-hero.png',
    title: 'Lenovo Thinkpad T430',
    os: 'Windows 10 Pro',
    processor: 'Intel® Core™ i5-6200U',
    memory: '16GB DDR4 RAM',
    ports: [
      'RJ45',
      'Mini DisplayPort™',
      'HDMI™',
      '4-in-1 Card Reader',
      'Headphone / Microphone',
      'Smart Card Reader(Optional)',
      'Fingerprint Reader(Optional)',
      'WWAN(Optional)',
      '3 x USB 3.0 (one Always On)'
    ]
  }
]

export const infPremDevices = [
  {
    id: '1',
    image: 'http://www.intel.com/content/dam/www/public/us/en/images/photography-consumer/rwd/937391-swift-canyon-short-nuc-frontangle-rwd.png.rendition.intel.web.480.270.png',
    title: 'Intel® NUC Kit NUC6i5SYK',
    processor: 'Intel® Core™ i5-6260U',
    memory: '16GB DDR4-2133 RAM',
    ports: [
      '4x UBS 3.0',
      '2x USB 2.0',
      'Infrared sensor'
    ]
  },
  {
    id: '2',
    image: 'https://www.enbitcon.com/media/image/ac/8b/6c/x1_60E_front_600x600.png.pagespeed.ic.QefaDb7O_d.jpg',
    title: 'FortiGate 60E'
  },
  {
    id: '3',
    image: 'http://www.avfirewalls.com/images/FortiGate/FortiGate-100E-101E/FortiGate-100E-101E.jpg',
    title: 'FortiGate 100E'
  }
]
