<template>
  <div>
    <!-- <client-parameters ref="clientParams"></client-parameters> -->
    <el-row>
      <br />
      <el-button type="primary" @click="exportInventoriesCSV"
        >Export Inventories as CSV</el-button
      >
      <el-col :span="22" :offset="1">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input placeholder="Find devices" v-model="search">
              <template #suffix>
                <el-icon
                  v-if="isSubset"
                  @click="clearSearch"
                  prefix-icon="Search"
                >
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset && filteredClients">
              showing {{ filteredClients.length }} of {{ clients.length }}
            </div>
          </el-col>
          <el-col class="bs-align-right" :span="8">
            <el-button
              class="button_conf"
              type="primary"
              @click="importClients"
            >
              <el-icon> <Upload /> </el-icon>
              Import devices</el-button
            >
            <el-button
              class="button_conf"
              type="primary"
              @click="openClientAdd"
            >
              <el-icon>
                <Plus />
              </el-icon>
              Add device</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="filteredClients">
          <el-table-column prop="uuid" label="UUID" width="100px" sortable>
            <template v-slot="scope">
              <span>{{ scope.row.uuid.substr(0, 8) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Computer Target Name" sortable>
          </el-table-column>
          <el-table-column prop="description" label="Description">
          </el-table-column>
          <!-- <el-table-column label="Base" prop="base.name">
            </el-table-column> -->
          <el-table-column
            label="Manufacturer"
            prop="manufacturer"
          ></el-table-column>
          <el-table-column label="Model" prop="model"></el-table-column>
          <el-table-column align="center" label="Online" prop="isOnline">
            <template v-slot="scope">
              <img
                width="30"
                height="30"
                v-if="
                  validateOnlineStatus(
                    scope.row.isOnline,
                    scope.row.lastOnlineStatusUpdate
                  )
                "
                src="../../../static/connected.png"
              />
              <img
                width="30"
                height="30"
                v-else-if="
                  !validateOnlineStatus(
                    scope.row.isOnline,
                    scope.row.lastOnlineStatusUpdate
                  )
                "
                src="../../../static/disconnected.jpg"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="Last Status Update"
            prop="lastOnlineStatusUpdate"
          >
            <template v-slot="scope">
              {{ parseDate(scope.row.lastOnlineStatusUpdate) }}
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="110">
            <template v-slot="scope">
              <actions-dropdown
                :entity="scope.row"
                :edit="true"
                :deleteItem="true"
                :clientdatasheet="true"
                :parameters="true"
                :deviceoptions="true"
                :software="true"
                :activityLog="true"
                :osSettings="true"
                :hideRetreatFromShop="true"
                @edit="openClientEdit"
                @delete="confirmDelete"
                @clientdatasheet="openInventory"
                @parameters="openParameters"
                @deviceoptions="openAssignDeviceOptions(scope.row)"
                @software="openAssignSoftware(scope.row)"
                @activityLog="openActivityLog(scope.row.id, scope.row.name)"
                @osSettings="openOSSettings(scope.row)"
              >
              </actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find virtual machines"
              v-model="searchVM"
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubsetVM" @click="clearSearchVM">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubsetVM && virtualMachines">
              showing {{ filteredVMs.length }} of {{ virtualMachines.length }}
            </div>
          </el-col>
        </el-row>
        <el-table :data="filteredVMs">
          <el-table-column
            prop="name"
            label="Name"
            width="250px"
            sortable
          ></el-table-column>
          <el-table-column
            prop="status"
            label="Status"
            width="250px"
            sortable
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <client-add ref="clientAdd"></client-add>
    <client-edit ref="clientEdit"></client-edit>
    <assign-software ref="assignSoftware"></assign-software>
    <assign-device-options ref="assignDeviceOptions"></assign-device-options>
    <activity-log ref="activityLog"></activity-log>
    <os-settings ref="osSettings"></os-settings>
    <import-clients
      ref="importClients"
      @reloadClients="reloadClients"
    ></import-clients>
  </div>
</template>
<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import ClientAdd from "./ClientAdd.vue";
import ClientEdit from "./ClientEdit.vue";
import AssignSoftware from "../software/AssignSoftware";
import AssignDeviceOptions from "../deviceoptions/AssignDeviceOptions";
import impersonation from "../../impersonation";
import ActivityLog from "./ActivityLog";
import OSSettings from "./OSSettings.vue";
import ImportClients from "./ImportClients.vue";

export default {
  name: "clients",
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getClients");
    this.$store.dispatch("getVMs", impersonation.getCustomer().id);
  },
  components: {
    ClientAdd,
    ClientEdit,
    ActionsDropdown,
    AssignSoftware,
    AssignDeviceOptions,
    ActivityLog,
    "os-settings": OSSettings,
    "import-clients": ImportClients,
    // ClientParameters
  },
  data() {
    return {
      searchVM: "",
    };
  },
  methods: {
    reloadClients() {
      this.$store.dispatch("getClients");
    },
    importClients() {
      this.$refs.importClients.openDialog();
    },
    openOSSettings(client) {
      this.$refs.osSettings.openDialog(client);
    },
    parseDate(dateData) {
      if (dateData !== "0001-01-01T00:00:00") {
        var date = new Date(Date.parse(dateData));
        var day = date.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        var month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        var hours = date.getHours();
        if (hours < 10) {
          hours = "0" + hours;
        }
        var minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        return (
          day +
          "." +
          month +
          "." +
          date.getUTCFullYear() +
          " " +
          hours +
          ":" +
          minutes
        );
      } else {
        return "--";
      }
    },
    openActivityLog(clientId, clientName) {
      this.$refs.activityLog.openDialog(clientId, clientName);
    },
    exportInventoriesCSV() {
      let customer = impersonation.getCustomer();
      let payload = {
        customerId: customer.id,
        customerName: customer.name,
      };
      this.$store.dispatch("exportInventoriesCSV", payload);
    },
    clearSearchVM() {
      this.searchVM = "";
    },
    openClientAdd() {
      this.$refs.clientAdd.open();
    },
    openClientEdit(client) {
      this.$refs.clientEdit.open(_.cloneDeep(client));
    },
    openAssignSoftware(client) {
      // this.$store.dispatch("getSoftwareAssign", client.id);
      this.$store.dispatch(
        "getCustomersSoftware",
        impersonation.getCustomer().id
      );
      this.$refs.assignSoftware.open(client.id, client);
    },
    confirmDelete(client) {
      this.$confirm(
        "This will permanently delete the client. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, client), this.deleteError);
    },
    // showClientParameters (client) {
    //   this.$refs.clientParams.openDialog(client)
    // },
    deleteSuccess(client) {
      let payload = {
        customerId: client.customerId,
        clientId: client.id,
      };
      this.$store.dispatch("deleteClient", payload);
    },
    deleteError() {},
    openParameters(client) {
      this.$router.push({ name: "datasheet", params: { id: client.id } });
    },
    openInventory(client) {
      this.$router.push({ name: "clientinventory", params: { id: client.id } });
    },
    openAssignDeviceOptions(client) {
      this.$store.dispatch("getDeviceOptionAssign", client.id);
      this.$store.dispatch("getDeviceOptionVersions");
      this.$refs.assignDeviceOptions.open(client.id);
    },

    validateOnlineStatus(isOnline, timestamp) {
      var lastOnlineTimestamp = new Date(Date.parse(timestamp));
      lastOnlineTimestamp = new Date(
        lastOnlineTimestamp - lastOnlineTimestamp.getTimezoneOffset() * 60000
      );
      var currentDate = new Date(Date.now());
      var TWO_MIN = 2 * 60 * 1000;
      if (currentDate - lastOnlineTimestamp > TWO_MIN) {
        return false;
      } else {
        return isOnline;
      }
    },
  },
  computed: {
    ...mapState([
      "clients",
      "clientLoading",
      "winpeLoading",
      "virtualMachines",
    ]),
    filteredClients() {
      return this.filterEntities(this.clients, this.search);
    },
    isSubset() {
      return this.clients.length !== this.filteredClients.length;
    },
    filteredVMs() {
      // let toFilter = []
      // for (let i = 0; i < this.virtualMachines.length; i++) {
      //   if (this.virtualMachines[i].status !== 'VM ready') {
      //     toFilter.push(this.virtualMachines[i])
      //   }
      // }
      return this.filterEntities(this.virtualMachines, this.searchVM);
    },
    isSubsetVM() {
      return this.virtualMachines.length !== this.filteredVMs.length;
    },
  },
};
</script>
<style scoped>
.button_conf {
  margin-top: 5px;
}
</style>
