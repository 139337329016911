<template>
  <el-form-item ref="form1" label="Resource Group Name" :prop="prop" :rules="rules.resourceGroupName">
    <el-input v-model="model" @change="change" placeholder="Select Resource Group name"></el-input>
  </el-form-item>
</template>

<script>
  import ResourceGroupValidation from './ResourceGroupValidation'

  export default {
    name: 'resource-group-name',
    props: ['value', 'prop'],
    mixins: [
      ResourceGroupValidation
    ],
    data () {
      return {
        model: this.value
      }
    },
    methods: {
      change (value) {
        this.$emit('input', value)
      },
      resetModel () {
        this.model = ''
      }
    }
  }
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
