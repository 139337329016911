export default {
  name: 'company-validation',
  data () {
    return {
      companyRules: {
        corporateName: [
          {
            required: true,
            message: 'Please insert the corporate name',
            trigger: 'blur'
          }
        ]
        /* formOfOrganization: [
          {
            required: true,
            message: 'Please insert the form of organization',
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: 'Please insert a description',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: 'Please insert a type',
            trigger: 'change'
          }
        ],
        street: [
          {
            required: true,
            message: 'Please insert a street name',
            trigger: 'blur'
          }
        ],
        streetNr: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: 'Please insert a street number',
            trigger: 'blur'
          }
        ],
        postcode: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: 'Please insert the postcode',
            trigger: 'blur'
          }
        ],
        city: [
          {
            required: true,
            message: 'Please insert the city',
            trigger: 'blur'
          }
        ],
        country: [
          {
            required: true,
            message: 'Please insert the country',
            trigger: 'blur'
          }
        ],
        phoneNr: [
          {
            required: true,
            pattern: /^[0-9]*$/,
            message: 'Please insert a phone number',
            trigger: 'blur'
          }
        ],
        linkWebsite: [
          {
            required: true,
            message: 'Please add a link to the website of the company',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: 'Please insert the name of the headquarter',
            trigger: 'blur'
          }
        ],
        publicIp: [
          {
            required: true,
            pattern: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
            message: 'Please insert the public IP address',
            trigger: 'blur'
          }
        ],
        expertId: [
          {
            required: true,
            message: 'Please select or create an expert for the company',
            trigger: 'change'
          }
        ],
        headquarterId: [
          {
            required: true,
            message: 'Please select or create a headquarter for the company',
            trigger: 'change'
          }
        ] */
      }
    }
  }
}
