<template>
  <div>
    <el-col :span="22" :offset="1">
      <div class="alert-banner" v-if="showHintBanner">
        <span
          >You dont have created a Storage Entry Point yet. Please create a SEP </span
        ><router-link :to="{ name: 'bases' }"> here</router-link>
      </div>
      <br />
      <el-col :span="6" class="search">
        <el-input
          placeholder="Find products"
          v-model="search"
          prefix-icon="Search"
        >
          <template #suffix>
            <el-icon v-if="isSubset" @click="clearSearch">
              <CircleClose />
            </el-icon>
          </template>
        </el-input>
        <div v-if="isSubset">
          showing {{ filteredDrivers.length }} of {{ drivers.length }}
        </div>
      </el-col>
      <br />
      <br />
      <div style="width: 85%; margin: 0 auto">
        <el-table :data="filteredDrivers" v-loading="loading">
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column
            label="Description"
            prop="description"
          ></el-table-column>
          <el-table-column label="Vendor" prop="vendor"></el-table-column>
          <el-table-column label="Version" prop="version"></el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <el-button type="primary" @click="confirmOrder(scope.row)"
                >Order</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { getSEPs } from "../../services/customers";
import { getDrivers } from "../../services/shops";
import FilterMixin from "../../mixins/FilterMixin";
import { orderDriver } from "../../services/order";

export default {
  mixins: [FilterMixin],
  created() {
    var customerId = impersonation.getCustomer().id;
    getSEPs(customerId)
      .then((response) => {
        if (response.data.length !== 0) {
          this.showHintBanner = false;
        } else {
          this.showHintBanner = true;
        }
      })
      .catch(() => {
        this.showHintBanner = true;
      });
    getDrivers(customerId)
      .then((response) => {
        let data = response.data;
        this.drivers = data;
      })
      .catch((error) => console.log({ error }));
  },
  data() {
    return {
      showHintBanner: false,
      drivers: [],
      search: "",
      loading: false,
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    confirmOrder(shopitem) {
      this.$confirm(
        "Do you want to order the " +
          shopitem.name +
          " Driver Package? Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.orderDrivers.bind(this, shopitem), this.donothing);
    },
    donothing() {},
    orderDrivers(shopitem) {
      let customerId = impersonation.getCustomer().id;
      this.loading = true;
      orderDriver(shopitem, customerId)
        .then(() => {
          getDrivers(customerId)
            .then((response) => {
              let data = response.data;
              this.drivers = data;
            })
            .catch((error) => console.log({ error }))
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => console.log({ error }));
    },
  },
  computed: {
    isSubset() {
      return this.drivers.length !== this.filteredDrivers.length;
    },
    filteredDrivers() {
      return this.filterEntities(this.drivers, this.search);
    },
  },
};
</script>

<style lang="css" scoped>
.alert-banner {
  border-radius: 20px;
  background-color: #ffdb1f;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  min-height: 25px;
  height: 25px;
}
</style>
