<template>
  <el-col :offset="1" :span="22">
    <template>
      <div class="container">
        <el-main>
          <div class="card">
            <el-row type="flex" class="header">
              <el-col class="bs-align-left" :span="8">
                <div style="white-space: nowrap">
                  <label>{{ clientName }} Inventory</label>
                </div>
              </el-col>
              <el-col class="bs-align-right" :span="16">
                <div style="white-space: nowrap">
                  <label>Collected</label>
                  <label
                    class="date-input"
                    style="width: 20%; padding-right: 10px"
                    >{{
                      updateDate === "" ? "Not fetched yet" : updateDate
                    }}</label
                  >
                  <!-- <label class="date-input">{{updateDate}}</label> -->
                  <!-- <el-button class="button_conf" type="primary" @click="refresh"><el-icon><Refresh /></el-icon>Refresh</el-button> -->
                </div>
              </el-col>
              <hr style="height: 2px" />
            </el-row>
            <el-row type="flex">
              <el-col :span="6">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label for="name">Name</label>
                      <el-input
                        class="input-style"
                        id="name"
                        v-model="clientName"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label for="domain">Domain</label>
                      <el-input
                        class="input-style"
                        id="domain"
                        v-model="domain"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label for="os">OS</label>
                      <el-input
                        class="input-style"
                        readonly
                        id="os"
                        v-model="os"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label for="release"> Version </label>
                      <el-input
                        class="input-style"
                        readonly
                        id="release"
                        v-model="version"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="6" :offset="4">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">Model</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="systemFamily"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">CPU</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="cpu"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">RAM</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="totalPhysicalMemory"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">System Drive</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[7]"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="4" :offset="4">
                <qrcode-vue :value="getQRValue()"></qrcode-vue>
                <span>Client Matching ID</span>
                <br />
                <span>(WPM ID)</span>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label>Last Boot</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="lastBoot"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label>Main User</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[9]"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="6" :offset="4">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">IP</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[10]"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">Subnet</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[11]"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">Gateway</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[12]"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label>Managed</label>
                      <el-icon color="green"><CircleCheck /></el-icon>
                      <el-input
                        class="input-style"
                        readonly
                        aria-placeholder="Smart Deploy Agent"
                      ></el-input>
                    </div>
                    <div style="white-space: nowrap">
                      <label>Compliant</label>
                      <el-icon color="green"><CircleCheck /></el-icon>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="6" :offset="4">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">Serial</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="serialNumber"
                      ></el-input>
                      <el-button disabled type="primary">RDP</el-button>
                    </div>
                    <div style="white-space: nowrap">
                      <label style="margin-right: 20px">Asset-ID</label>
                      <el-input
                        class="input-style"
                        readonly
                        v-model="clientInventory[15]"
                      ></el-input>
                      <el-button disabled type="primary">Ping</el-button>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </div>
        </el-main>
      </div>
      <div class="container">
        <!--<el-header>{{clientName}} Hardware Inventory</el-header>-->
        <el-main>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <p>Details</p>
            </el-col>
            <el-col :span="8"><div></div></el-col>
            <el-col class="bs-align-right" :span="8">
              <el-button type="primary" disabled @click="downloadInventoryAsCSV"
                >Export as CSV</el-button
              >
            </el-col>
          </el-row>
          <hr style="height: 10px" />
          <el-collapse @change="handleCollapseableOS">
            <el-collapse-item title="OS Inventory Details">
              <div class="alignLeft">
                <el-input
                  :autosize="{ minRows: 5 }"
                  id="osInventoryDetails"
                  placeholder="Script"
                  type="textarea"
                  v-model="osInventoryDetails"
                  wrap="off"
                ></el-input>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse @change="handleCollapseableHW">
            <el-collapse-item title="HW Inventory Details">
              <div class="alignLeft">
                <el-input
                  :autosize="{ minRows: 5 }"
                  id="hwInventoryDetails"
                  placeholder="Script"
                  type="textarea"
                  v-model="hwInventoryDetails"
                  wrap="off"
                ></el-input>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse @change="handleCollapseableBaseline">
            <el-collapse-item title="Baseline Details">
              <div class="alignLeft">
                <el-input
                  :autosize="{ minRows: 5 }"
                  id="baselineDetails"
                  placeholder="Script"
                  type="textarea"
                  v-model="baselineDetails"
                  wrap="off"
                ></el-input>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-main>
      </div>
      <br />
    </template>
    <custom-inventory-dialog ref="inventoryDialog"></custom-inventory-dialog>
  </el-col>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import FilterMixin from "../../mixins/FilterMixin";
import CustomInventoryDialog from "./CustomInventoryDialog";
import QrcodeVue from "qrcode.vue";
import {
  getDeviceInventory,
  getInventoryFilesContent,
} from "../../services/clients";
// import Codemirror from "../../../static/codemirror/lib/codemirror";

require("../../../static/codemirror/addon/edit/matchbrackets");
require("../../../static/codemirror/doc/docs.css");
require("../../../static/codemirror/lib/codemirror.css");
require("../../../static/codemirror/theme/monokai.css");

export default {
  name: "client-inventory",
  mixins: [FilterMixin],
  components: {
    QrcodeVue,
    CustomInventoryDialog,
  },
  created() {
    /* eslint-disable */
    this.customerId = impersonation.getCustomer().id;
    this.clientId = this.$route.params.id;
    getInventoryFilesContent(this.customerId, this.clientId)
      .then((response) => {
        this.osInventoryDetails = response.data.osInventory;
        this.hwInventoryDetails = response.data.hwInventory;
        this.baselineDetails = response.data.baselineResult;
      })
      .catch((error) => console.log({ error }))
      .finally(() => {
        let vm = this;
        vm.$nextTick(() => {
          vm.editorInventoryDetails = Codemirror.fromTextArea(
            document.getElementById("osInventoryDetails"),
            {
              mode: { name: "javascript", json: true },
              lineNumbers: true,
              theme: "monokai",
              indentUnit: 4,
              tabMode: "shift",
              matchBrackets: true,
              readonly: true,
            }
          );
          var obj = JSON.parse(vm.osInventoryDetails);
          var contentOsInventory = JSON.stringify(obj, undefined, 4);
          vm.editorInventoryDetails.getDoc().setValue(contentOsInventory);
          // setTimeout(function() {
          //   vm.editorInventoryDetails.refresh();
          // },1)
          vm.editorHWDetails = Codemirror.fromTextArea(
            document.getElementById("hwInventoryDetails"),
            {
              mode: { name: "javascript", json: true },
              lineNumbers: true,
              theme: "monokai",
              indentUnit: 4,
              tabMode: "shift",
              matchBrackets: true,
              readonly: true,
            }
          );
          var obj = JSON.parse(vm.hwInventoryDetails);
          var contentHwInventory = JSON.stringify(obj, undefined, 4);
          vm.editorHWDetails.getDoc().setValue(contentHwInventory);
          // setTimeout(function() {
          //   editorHW.refresh();
          // },1)
          vm.editorBaseline = Codemirror.fromTextArea(
            document.getElementById("baselineDetails"),
            {
              mode: { name: "javascript", json: true },
              lineNumbers: true,
              theme: "monokai",
              indentUnit: 4,
              tabMode: "shift",
              matchBrackets: true,
              readonly: true,
            }
          );
          var obj = JSON.parse(vm.baselineDetails);
          var contentBaseline = JSON.stringify(obj, undefined, 4);
          vm.editorBaseline.getDoc().setValue(contentBaseline);
        });
      });
    this.$store.dispatch("getClients");
    // this.$store.dispatch('getClientInventory', {customerId: this.customerId, clientId: this.clientId})
    getDeviceInventory(this.customerId, this.clientId)
      .then((response) => this.setInventoryData(response.data))
      .catch((error) => console.log("Error: " + error.data));
  },
  data() {
    return {
      editorInventoryDetails: "",
      editorHWDetails: "",
      editorBaseline: "",
      osInventoryDetails: "",
      hwInventoryDetails: "",
      baselineDetails: "",
      client: {},
      assetModelId: "",
      value: "",
      customerId: "",
      clientId: "",
      searchSoftware: "",
      searchHardware: "",
      searchBIOS: "",
      searchCustom: "",
      updateDate: "",
      domain: "",
      os: "",
      version: "",
      systemFamily: "",
      totalPhysicalMemory: "",
      serialNumber: "",
      clientName: "",
      cpu: "",
      clientInventory: [],
      lastBoot: "",
      inventoryData: {
        inventoryHW: {},
        inventoryOS: {},
      },
    };
  },
  methods: {
    handleCollapseableOS(val) {
      let temp = this.editorInventoryDetails;
      console.log({ temp });
      setTimeout(() => {
        this.editorInventoryDetails.refresh();
      }, 1);
    },
    handleCollapseableHW(val) {
      setTimeout(() => {
        this.editorHWDetails.refresh();
      }, 1);
    },
    handleCollapseableBaseline(val) {
      setTimeout(() => {
        this.editorBaseline.refresh();
      }, 1);
    },
    getQRValue() {
      if (this.assetModelId !== null && this.assetModelId.length > 0) {
        return (
          window.APPLICATION_SETTING_API_ENDPOINT_URL +
          "/#/assetmanagementdetails/" +
          this.assetModelId
        );
      } else {
        return this.clientId;
      }
    },
    downloadInventoryAsCSV() {
      let payload = {
        customerId: this.customerId,
        clientId: this.clientId,
        clientName: this.clientName,
      };
      this.$store.dispatch("exportInventoryAsCSV", payload);
    },
    setInventoryData(data) {
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i].id === this.clientId) {
          this.client = this.clients[i];
          break;
        }
      }
      this.updateDate = data.timestamp;
      this.lastBoot = data.lastBootUpTime;
      this.clientName = data.name;
      this.domain = data.domain;
      this.os = data.os;
      this.version = data.version;
      this.systemFamily = data.model;
      this.totalPhysicalMemory = data.ram + " GB";
      this.serialNumber = data.serialNumber;
      this.cpu = data.cpu;
    },
    getTimeAsString(date) {
      let now = new Date(Date.now());
      let yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      let timeString = "";
      if (
        now.getDay() === date.getDay() &&
        date.getMonth() === now.getMonth() &&
        now.getYear() === date.getYear()
      ) {
        let hour = date.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        let minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        timeString = "Today " + hour + ":" + minutes;
      } else if (
        yesterday.getDay() === date.getDay() &&
        date.getMonth() === yesterday.getMonth() &&
        yesterday.getYear() === date.getYear()
      ) {
        timeString = "Yesterday " + date.getHours() + ":" + date.getMinutes();
      } else {
        timeString = date.toString();
        let gmtIndex = 0;
        for (let i = 0; i < timeString.length; i++) {
          if (timeString[i] === "G") {
            gmtIndex = i;
            break;
          }
        }
        timeString = timeString.substring(0, gmtIndex - 4);
      }
      return timeString;
    },
    setHWInventory() {
      let HWInventory = [];
      // this.inventoryData.inventory.forEach(function (element) {
      //   if ((element.category === 'Hardware' || element.category === 'LogicalDisk' || element.category === 'null') & (element.method !== 'COM')) {
      //     HWInventory.push(element)
      //   }
      // })
      return HWInventory;
    },
    setOSInventory() {
      let OSInventory = [];
      // this.inventoryData.inventory.forEach(function (element) {
      //   if ((element.category === 'OS') & (element.method !== 'COM')) {
      //     OSInventory.push(element)
      //   }
      // })
      return OSInventory;
    },
    setCustomInventory() {
      let CustomInventory = [];
      // this.inventoryData.inventory.forEach(function (element) {
      // })
      return CustomInventory;
    },
    back() {
      this.$router.push({ name: "devices" });
    },
    isSubset() {},
    clearSearch(name) {
      if (name === "hardware") {
        this.searchHardware = "";
      } else if (name === "bios") {
        this.searchBIOS = "";
      } else if (name === "software") {
        this.searchSoftware = "";
      } else {
        this.searchCustom = "";
      }
    },
    handleRemove(a, fileList) {
      this.setFiles(null);
    },
    handleSuccess(a, b, fileList) {
      this.setFiles(b);
    },
    formatValues(values) {
      let valueArray = values.split(",,");
      for (let i = 0; i < valueArray.length; i++) {
        if (valueArray[i].length === 0) {
          valueArray.splice(i, 1);
        }
      }
      return valueArray;
    },
    showDataTeaser(value) {
      if (value.length > 50) {
        return value.substring(0, 50) + "...";
      } else {
        return value;
      }
    },
    refresh() {
      let payload = {
        actionName: "INVENTORY",
        clientId: this.clientId,
        customerId: this.customerId,
      };
      this.$store.dispatch("getLatestBitStreamScriptVersion", payload);
    },
    getBIOSInventory() {
      return this.BIOSInventory;
    },
    openCustomInventory() {
      this.$refs.inventoryDialog.openDialog(this.clientId);
    },
  },
  computed: {
    ...mapState([
      "clientdatasheet",
      "datasheetLoading",
      "customInventory",
      "clients",
    ]),
    filteredHW() {
      return this.filterInventory(
        this.setHWInventory(),
        this.searchHardware
      ).concat(
        this.valueFilterInventory(this.setHWInventory(), this.searchHardware)
      );
    },
    filteredOS() {
      return this.filterInventory(
        this.setOSInventory(),
        this.searchBIOS
      ).concat(
        this.valueFilterInventory(this.setOSInventory(), this.searchBIOS)
      );
    },
    filteredCustom() {
      return this.filterEntities(
        this.customInventory,
        this.searchCustom
      ).concat(
        this.valueFilterInventory(this.customInventory, this.searchCustom)
      );
    },
    categories() {
      return this.datasheet.categories;
    },
  },
};
</script>

<style scoped>
.card {
  /*background-color: #e7e7e7;*/
  margin-top: 20px;
  margin-left: 20px;
}
.input-style {
  margin-bottom: 4px;
  border-radius: 20px;
  width: 350px;
}
.header {
  border-bottom: 5px simple black;
}
.span-updateDate {
  font-size: 14px;
}
.span-client-inventory {
  font-size: 14px;
}

.border-red {
  border: 1px solid red;
}

.border-black {
  border: 1px solid black;
}

.preserve-space {
  white-space: pre;
}

.heading {
  font-size: 14px;
  color: #909399;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: bold;
  float: left;
}

.contents {
  font-size: 14px;
  color: #606266;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: left;
  margin: 0;
}

.nospace {
  margin-bottom: 0;
}
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.date-input {
  width: 160px;
  border-radius: 20px;
}
.collapse {
  margin-left: 20px;
  color: #aaaaaa;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  color: #3c3c3c;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
label {
  *display: inline;
  display: inline-block;
  text-align: left;
  flex-direction: row;
  justify-content: flex-end;
  width: 80px;
}
input {
  display: inline-block;
  *display: inline;
}
p {
  text-align: left;
  margin-left: 20px;
}

.alignLeft {
  text-align: left;
}
</style>
