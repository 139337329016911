<template>
  <el-dialog
    title="Edit Software Stream"
    v-model="showDialog"
    :close-on-click-modal="false"
    width="75%"
  >
    <el-form :model="softwareStream" ref="form" :rules="rules">
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="softwareStream.name"
          placeholder="Please set the software stream name"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="updateSettings">
        <el-switch
          v-model="switchValue"
          active-text="Auto Update"
          inactive-text="Managed Release"
          @change="changeUpdateSettings"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="Type" prop="type">
        <el-select v-model="softwareStream.type">
          <el-option v-for="t in types" :key="t" :label="t" :value="t">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Short Description" prop="descriptionShort">
        <el-input
          placeholder="Short Description"
          v-model="softwareStream.descriptionShort"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          :autosize="{ minRows: 5 }"
          placeholder="Description"
          type="textarea"
          v-model="softwareStream.description"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Vendor" prop="vendor">
        <el-input
          placeholder="Vendor"
          v-model="softwareStream.vendor"
        ></el-input>
      </el-form-item>
      <el-form-item label="Website" prop="website">
        <el-input v-model="softwareStream.website"></el-input>
      </el-form-item>
      <el-form-item label="Download Link" prop="downloadLink">
        <el-input v-model="softwareStream.downloadLink"></el-input>
      </el-form-item>
      <el-form-item label="Language" prop="language">
        <el-select
          v-model="softwareStream.language"
          placeholder="Please select the language"
        >
          <el-option v-for="l in languages" :key="l" :value="l" :label="l">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Architecture" prop="architecture">
        <el-select
          v-model="softwareStream.architecture"
          placeholder="Please select the architecture"
        >
          <el-option
            v-for="a in swArchitectures"
            :label="a"
            :key="a"
            :value="a"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="GNU Licence">
        <el-checkbox v-model="softwareStream.gnuLicence"></el-checkbox>
      </el-form-item>
      <el-form-item label="Proof Web Version Script">
        <el-input
          type="textarea"
          v-model="softwareStream.proofVersionScript"
        ></el-input>
      </el-form-item>
      <el-form-item label="Icon" prop="icon">
        <div class="uploader-container-banner">
          <img v-if="iconURL" :src="iconURL" class="image-fit" />
          <input
            type="file"
            capture
            accept="image/*"
            @change="onFileChanged($event)"
          />
        </div>
        <!-- <div class="uploader-container-icon">
          <el-upload
            ref="bannerUploader"
            class="avatar-uploader"
            :headers="headers"
            :action="uploadUrl"
            :limit="1"
            drag
            :file-list="icons"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="iconURL" :src="iconURL" class="avatar-icon" />
            <div v-else>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </div>
          </el-upload>
        </div> -->
      </el-form-item>
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { getIconRef } from "../../services/softwareStreams";

export default {
  data() {
    return {
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      icons: [],
      iconURL: "",
      uploadUrl:
        window.APPLICATION_SETTING_API_ENDPOINT_URL + "/software/icon/upload",
      swArchitectures: ["x64", "x86", "x86 + x64"],
      languages: [
        "not defined",
        "MUI (multi user interface)",
        "German (de-DE)",
        "English (en-US)",
      ],
      types: ["Software", "Task"],
      showDialog: false,
      switchValue: false,
      softwareStream: {
        proofVersionScript: "",
        type: "",
        id: "",
        name: "",
        updateSettings: "",
        descriptionShort: "",
        description: "",
        vendor: "",
        website: "",
        downloadLink: "",
        language: "",
        architecture: "",
        gnuLicence: "",
        icon: {
          id: "",
          name: "",
        },
      },
      rules: {
        type: [
          {
            required: true,
            message: "Please select the software type",
            trigger: "change",
          },
        ],
        name: [
          // {validator: uniqueName, trigger: 'blur'},
          {
            required: true,
            message: "Please set the name of the software stream",
            trigger: "blur",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please select the architecture type",
            trigger: "change",
          },
        ],
        vendor: [
          {
            required: true,
            message: "Please set the vendor of the software stream",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async onFileChanged(event) {
      const target = event.target;
      if (target && target.files) {
        var file = target.files[0];
        var url = window.URL.createObjectURL(file);
        this.iconURL = url;
        console.log({ url });
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          window.axiosInstance.defaults.headers.common.Authorization
        );
        const payload = new FormData();
        payload.append("file", file, file.name);
        await fetch(
          window.axiosInstance.defaults.baseURL + "/software/icon/upload",
          {
            method: "POST",
            headers: myHeaders,
            body: payload,
          }
        )
          .then(async (response) => {
            const data = await response.json();
            this.setFiles(data, file.name);
          })
          .catch((err) => console.log({ err }));
        // window.axiosInstance.post("/software/icon/upload", target.files[0]);
      }
    },
    beforeUpload(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURL = url;
    },
    setFiles(file, fileName) {
      if (file !== undefined && file !== null) {
        this.softwareStream.icon = {
          id: file.id,
          name: fileName,
        };
      } else {
        this.softwareStream.icon = { id: "", name: "" };
      }
    },
    handleRemove(a, files) {
      this.setFiles(files);
      this.iconURL = "";
    },
    handleSuccess(a, b, files) {
      this.setFiles(files);
    },
    async openDialog(softwareStream) {
      this.softwareStream = softwareStream;
      if (softwareStream.icon !== null) {
        this.iconURL = softwareStream.icon;
        getIconRef(this.softwareStream.id)
          .then((response) => {
            let fetchedIcon = response.data;
            this.softwareStream.icon = fetchedIcon;
          })
          .catch((err) => console.log(err.data));
      } else {
        this.iconUrl = "";
      }
      if (softwareStream.updateSettings === "auto") {
        this.switchValue = true;
      } else {
        this.switchValue = false;
      }
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    changeUpdateSettings(val) {
      if (val) {
        this.softwareStream.updateSettings = "auto";
      } else {
        this.softwareStream.updateSettings = "managed";
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.softwareStream.updateSettings === "") {
            if (this.switchValue) {
              this.softwareStream.updateSettings = "auto";
            } else {
              this.softwareStream.updateSettings = "managed";
            }
          }
          this.$store.dispatch(
            "editSoftwareStream",
            _.cloneDeep(this.softwareStream)
          );
          this.$refs.form.resetFields();
          this.icons = [];
          this.iconURL = "";
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.uploader-container-banner {
  width: 400px;
  height: 100px;
}
.image-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
