<template>
  <el-col :offset="1" :span="22">
    <template>
      <div class="container">
        <el-main>
          <div class="card">
            <el-row type="flex" class="header">
              <el-col class="bs-align-left" :span="8">
                <div>
                  <label style="width: 500px"> Asset Management </label>
                  <el-button class="assign-btn" type="primary" @click="submit"
                    >Save</el-button
                  >
                </div>
              </el-col>
            </el-row>
            <!-- <p><hr style="height:2px"/></p> -->
            <el-row type="flex">
              <el-col :span="12">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap; display: flex">
                      <label class="label" for="ID">Asset-ID</label>
                      <el-input
                        readonly
                        class="input-style"
                        id="ID"
                        v-model="assetModel.assetID"
                      ></el-input>
                      <div :class="getStatusClass()">
                        {{ assetModel.assetStatus }}
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item class="padding-right">
                    <div style="white-space: nowrap">
                      <label for="type">Asset-Type</label>
                      <el-select
                        class="input-style"
                        id="type"
                        v-model="assetModel.assetTypeId"
                        @change="openAssetTypeDialog"
                      >
                        <el-option
                          v-for="assetType in assetTypes"
                          :key="assetType.id"
                          :label="assetType.name"
                          :value="assetType.id"
                        >
                        </el-option>
                        <el-option
                          key="newAssetType"
                          label="+ Asset Type"
                          value="newAssetType"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item class="padding-right">
                    <div style="white-space: nowrap">
                      <label for="class">Asset-Class</label>
                      <el-select
                        class="input-style"
                        id="class"
                        v-model="assetModel.assetClassId"
                        @change="openAssetClassDialog"
                      >
                        <el-option
                          v-for="assetClass in filteredAssetClasses()"
                          :key="assetClass.id"
                          :label="assetClass.name"
                          :value="assetClass.id"
                        >
                        </el-option>
                        <el-option
                          key="newAssetClass"
                          label="+ Asset Class"
                          value="newAssetClass"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label for="description">Description</label>
                      <el-input
                        class="input-style"
                        id="description"
                        v-model="assetModel.description"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="6" :offset="4">
                <el-form
                  ref="form"
                  :model="form"
                  size="medium"
                  label-width="120px"
                  label-position="left"
                >
                  <el-form-item>
                    <div style="white-space: nowrap">
                      <label for="type">Label Preview</label>
                      <qrcode-vue :value="url"></qrcode-vue>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <el-row type="flex" class="header">
              <el-col class="bs-align-left" :span="8">
                <div style="white-space: nowrap">
                  <label> CI-Management </label>
                </div>
              </el-col>
            </el-row>
            <!-- <p><hr style="height:2px"/></p> -->
            <el-row type="flex">
              <el-col :offset="2" :span="20">
                <el-form>
                  <el-form-item>
                    <div class="flex">
                      <label for="CINr">CI-Number</label>
                      <el-input
                        v-model="assetModel.ciNumber"
                        id="CINr"
                      ></el-input>
                      <el-button
                        class="margin-left"
                        type="primary"
                        @click="generateUUID"
                        >Generate</el-button
                      >
                    </div>
                  </el-form-item>
                  <el-row type="flex" class="header">
                    <el-col :span="8">
                      <div class="flex-div" style="white-space: nowrap">
                        <span>Vendor Class (Vendor Class ID)</span>
                        <el-button
                          class="assign-btn"
                          type="primary"
                          @click="openAssignVendor"
                          >Assign</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- <p><hr style="height:2px"/></p> -->
                  <el-form-item>
                    <div class="flex padding-bottom">
                      <label for="VendorName">Vendor Name</label>
                      <el-input
                        readonly
                        v-model="assetModel.vendorModel.name"
                        id="VendorName"
                      ></el-input>
                    </div>
                    <div class="flex padding-bottom">
                      <label for="SystemFamily">System-Family</label>
                      <el-input
                        readonly
                        v-model="assetModel.vendorModel.modelFamily"
                        id="SystemFamily"
                      ></el-input>
                    </div>
                    <div class="flex">
                      <label for="ModelGroup">Model-Group</label>
                      <el-input
                        id="ModelGroup"
                        readonly
                        v-model="assetModel.vendorModel.modelType"
                      ></el-input>
                    </div>
                  </el-form-item>
                  <div v-if="assetModel.assetType.name === 'Managed Device'">
                    <el-row type="flex" class="header">
                      <el-col :span="8">
                        <div class="flex-div" style="white-space: nowrap">
                          <span>Inventory (Inventory-ID = UUID)</span>
                          <el-button
                            class="assign-btn"
                            type="primary"
                            @click="assignClient"
                            >Assign</el-button
                          >
                        </div>
                      </el-col>
                    </el-row>
                    <!-- <p><hr style="height:2px"/></p> -->
                    <el-form-item>
                      <div class="flex padding-bottom">
                        <label for="UUID">UUID</label>
                        <el-input
                          v-model="assetModel.client.uuid"
                          readonly
                          id="UUID"
                          style="color: black"
                        ></el-input>
                      </div>
                      <div class="flex padding-bottom">
                        <label for="SerialNr">Serial Number</label>
                        <el-input
                          v-model="assetModel.client.serialNumber"
                          readonly
                          id="SerialNr"
                        ></el-input>
                      </div>
                      <div class="flex">
                        <label for="MAC">MAC</label>
                        <el-input
                          v-model="assetModel.client.macAddress"
                          id="MAC"
                          readonly
                        ></el-input>
                      </div>
                    </el-form-item>
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label> Person-Assignment </label>
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"/></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="User-ID">Person-ID</label>
                    <el-input
                      v-model="assetModel.person.id"
                      id="User-ID"
                      readonly
                      style="width: 60%"
                    ></el-input>
                    <el-button
                      class="assign-btn"
                      type="primary"
                      @click="assignPerson"
                      >Assign</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="flex-div" style="white-space: nowrap">
                    <span>Person-Details</span>
                  </div>
                  <div
                    class="flex inside-container padding-bottom"
                    id="UserDetails"
                  >
                    <label for="FirstName">First Name</label>
                    <el-input
                      v-model="assetModel.person.givenName"
                      style="width: 35%"
                      readonly
                      id="FirstName"
                    ></el-input>
                    <label for="Surname" style="padding-left: 10px"
                      >Surname</label
                    >
                    <el-input
                      v-model="assetModel.person.surname"
                      style="width: 35%"
                      readonly
                      id="Surname"
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Departement">Departement</label>
                    <el-input
                      v-model="assetModel.person.departementName"
                      style="width: 60%"
                      readonly
                      id="Departement"
                    ></el-input>
                  </div>
                  <div class="flex inside-container padding-bottom">
                    <label for="Mobile">Mobile Phone</label>
                    <el-input
                      v-model="assetModel.person.mobileNr"
                      style="width: 35%"
                      id="Mobile"
                      readonly
                    ></el-input>
                    <label for="Phone" style="padding-left: 10px">Phone</label>
                    <el-input
                      v-model="assetModel.person.phoneNr"
                      id="Phone"
                      style="width: 35%"
                      readonly
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Email">E-Mail</label>
                    <el-input
                      v-model="assetModel.person.emailPrimary"
                      style="width: 60%"
                      readonly
                      id="Email"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="CostCenter">Cost-Center</label>
                    <el-input
                      v-model="assetModel.person.costCenter"
                      readonly
                      id="CostCenter"
                      style="width: 60%"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label>Location</label>
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"/></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="LocationID">Location-ID</label>
                    <el-input
                      v-model="assetModel.location.id"
                      id="LocationID"
                      readonly
                      style="width: 60%"
                    ></el-input>
                    <el-button
                      class="assign-btn"
                      type="primary"
                      @click="assignLocation()"
                      >Assign</el-button
                    >
                  </div>
                  <div class="flex-div" style="white-space: nowrap">
                    <span>Location-Details</span>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Country">Country</label>
                    <el-input
                      v-model="assetModel.location.country"
                      readonly
                      id="Country"
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="City">City</label>
                    <el-input
                      v-model="assetModel.location.city"
                      readonly
                      id="City"
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Street">Street</label>
                    <el-input
                      v-model="assetModel.location.street"
                      readonly
                      id="Street"
                      style="width: 35%"
                    ></el-input>
                    <label for="StreetNr" style="padding-left: 10px">Nr.</label>
                    <el-input
                      readonly
                      v-model="assetModel.location.number"
                      id="StreetNr"
                      style="width: 35%; color: black"
                    ></el-input>
                  </div>
                  <!-- Editable -->
                  <div class="flex padding-bottom">
                    <label for="Building">Building</label>
                    <el-input
                      v-model="assetModel.building"
                      id="Building"
                      style="width: 60%"
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Floor">Floor</label>
                    <el-input
                      v-model="assetModel.floor"
                      id="Floor"
                      style="width: 60%"
                    ></el-input>
                  </div>
                  <div class="flex padding-bottom">
                    <label for="Room">Room</label>
                    <el-input
                      v-model="assetModel.room"
                      id="Room"
                      style="width: 60%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="Coordinates">Coordinates</label>
                    <el-input
                      v-model="assetModel.coordinates"
                      id="Coordinates"
                      style="width: 60%"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label>Financial</label>
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"/></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="Invoice">Invoice</label>
                    <el-upload
                      class="upload-demo"
                      drag
                      :headers="headers"
                      :action="uploadURL"
                      :on-remove="handleRemoveContent"
                      :on-success="handleSuccessContent"
                      :file-list="assetModel.invoice"
                    >
                      <div class="el-upload__text">
                        <el-icon><UploadFilled /></el-icon>
                        Drop file here or <em>click to upload</em>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="flex">
                    <label for="PurchaseDate">Purchase Date</label>
                    <el-date-picker
                      v-model="assetModel.purchaseDate"
                      id="PurchaseDate"
                      placeholder="Pick the purchase date"
                      format="DD.MM.YYYY"
                      @change="formatDate"
                      type="date"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="flex">
                    <label for="PurchaseValue">Purchase Value</label>
                    <el-input-number
                      :step="0.1"
                      :precision="2"
                      v-model="assetModel.purchaseValue"
                      id="PurchaseValue"
                      style="width: 30%"
                      @change="calculateAccountingValue()"
                    >
                    </el-input-number>
                    <div style="width: 300px; position: absolute; right: 10px">
                      <label for="Currency">Currency</label>
                      <el-select
                        style="width: 80px"
                        placeholder=""
                        id="Currency"
                        v-model="assetModel.currency"
                        @change="calculateAccountingValue()"
                      >
                        <el-option
                          v-for="currency in currencies"
                          :disabled="currency.value === '€' ? false : true"
                          :key="currency.key"
                          :value="currency.value"
                          :label="currency.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="flex">
                    <label for="AccountingValue">Accounting Value</label>
                    <el-input
                      readonly
                      v-model="accountingValue"
                      id="AccountingValue"
                      style="width: 100px; padding-right: 50px"
                    ></el-input>
                    <div style="width: 400px; position: absolute; right: 10px">
                      <label for="DepreciationMonths">DEPN. (mos.)</label>
                      <el-input-number
                        :step="1"
                        v-model="assetModel.depreciationMonths"
                        id="DepreciationMonths"
                        style="width: 30%"
                        @change="calculateAccountingValue()"
                      >
                      </el-input-number>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label
                  >Information & Attachments
                  <span style="color: red"
                    >(The content below is currently disabled)</span
                  ></label
                >
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"/></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="guarantee">Guarantee End</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="guarantee"
                      style="width: 50%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="lease">Leasing End</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="lease"
                      style="width: 50%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="weight">Weight</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="weight"
                      style="width: 50%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="t">size</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 12%"
                      id="t"
                    ></el-input>
                    <label for="b" style="padding-left: 10px">T</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 12%"
                      id="b"
                    ></el-input>
                    <label for="h" style="padding-left: 10px">B</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 12%"
                      id="h"
                    ></el-input>
                    <label for="h" style="padding-left: 10px">H</label>
                  </div>
                  <div class="flex">
                    <label for="picture">Picture</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="picture"
                      style="width: 80%"
                    ></el-input>
                    <el-button disabled class="margin-left" type="primary"
                      >Upload</el-button
                    >
                  </div>
                  <div class="flex">
                    <label for="op">Operational Manual</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="op"
                      style="width: 80%"
                    ></el-input>
                    <el-button disabled class="margin-left" type="primary"
                      >Upload</el-button
                    >
                  </div>
                  <div class="flex">
                    <label for="support">Support Hotline</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="support"
                      style="width: 95%"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-row type="flex" class="header">
                  <el-col :span="20">
                    <div class="flex-div" style="white-space: nowrap">
                      <span>Distributor</span>
                    </div>
                  </el-col>
                </el-row>
                <!-- <p><hr style="height:2px"/></p> -->
                <el-form-item>
                  <div class="flex">
                    <label for="companyid">Company-ID</label>
                    <el-input disabled id="companyid"></el-input>
                    <el-button disabled class="margin-left" type="primary"
                      >Assign</el-button
                    >
                  </div>
                  <div class="flex-div" style="white-space: nowrap">
                    <span>Person-Details</span>
                  </div>
                  <div class="flex">
                    <label for="name">Name</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 60%"
                      id="name"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="tel">Phone</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 60%"
                      id="tel"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="mail">E-mail</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 60%"
                      id="mail"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label>Servicing Information</label>
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="safety">Safety Information</label>
                    <el-input
                      disabled
                      class="input-style"
                      id="safety"
                      style="width: 100%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="service">Service-Plan</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 20%"
                      readonly
                      id="service"
                    ></el-input>
                    <label for="lab3"></label>
                    <el-input
                      disabled
                      id="lab3"
                      style="width: 50%"
                      readonly
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="cleaning">Cleaning Info</label>
                    <el-input
                      disabled
                      class="input-style"
                      type="textarea"
                      id="cleaning"
                      style="width: 100%"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <label for="cleaningPlan">Cleaning-Plan</label>
                    <el-input
                      disabled
                      class="input-style"
                      style="width: 20%"
                      readonly
                      id="cleaningPlan"
                    ></el-input>
                    <label for="lab2"></label>
                    <el-input
                      disabled
                      id="lab2"
                      style="width: 50%"
                      readonly
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row type="flex" class="header">
            <el-col class="bs-align-left" :span="8">
              <div style="white-space: nowrap">
                <label>Accompanying Assets</label>
              </div>
            </el-col>
          </el-row>
          <!-- <p><hr style="height:2px"/></p> -->
          <el-row type="flex">
            <el-col :offset="2" :span="20">
              <el-form>
                <el-form-item>
                  <div class="flex">
                    <label for="assetId">Asset-ID</label>
                    <el-input
                      disabled
                      style="width: 20%"
                      readonly
                      id="assetId"
                    ></el-input>
                    <label for="lab1"></label>
                    <el-input
                      disabled
                      id="lab1"
                      style="width: 50%"
                      readonly
                    ></el-input>
                    <el-button disabled class="margin-left" type="primary"
                      >Open</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-main>
      </div>
      <assign-client
        ref="assignClient"
        @refresh="refreshPage()"
      ></assign-client>
      <assign-person
        ref="assignPerson"
        @refresh="refreshPage()"
      ></assign-person>
      <assign-location
        ref="assignLocation"
        @refresh="refreshPage()"
      ></assign-location>
      <assignVendor ref="vendorModel" @refresh="refreshPage()"></assignVendor>
      <asset-type-dialog ref="assetType"></asset-type-dialog>
      <asset-class-dialog ref="assetClass"></asset-class-dialog>
    </template>
  </el-col>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import QrcodeVue from "qrcode.vue";
import { getAssetLabel } from "../../services/assetManagement";
import AssetTypeDialog from "./AssetTypeDialog";
import AssetClassDialog from "./AssetClassDialog";
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import AssignClient from "./dialogs/AssignClient.vue";
import AssignPerson from "./dialogs/AssignPerson.vue";
import AssignLocation from "./dialogs/AssignLocation.vue";
import AssignVendor from "./dialogs/AssignVendor.vue";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Asset-Management-Details",
  mixins: [FilterMixin],
  components: {
    QrcodeVue,
    AssetTypeDialog,
    AssetClassDialog,
    AssignClient,
    AssignPerson,
    AssignLocation,
    AssignVendor,
  },
  created() {
    getAssetLabel(this.$route.params.id).then((response) => {
      this.assetModel = response.data;
      if (this.assetModel.assetType === null) {
        this.assetModel.assetType = {
          name: "",
          id: "",
        };
      }
      if (this.assetModel.assetClass === null) {
        this.assetModel.assetClass = {
          id: "",
          name: "",
        };
      }
      if (response.data.invoice !== null) {
        this.assetModel.invoice = [response.data.invoice];
      } else {
        this.assetModel.invoice = [];
      }
      this.calculateAccountingValue();
      this.url = this.url + this.assetModel.id;
      if (this.assetModel.purchaseDate === "0001-01-01T00:00:00") {
        this.assetModel.purchaseDate = "";
      }
      if (
        this.assetModel.client === null ||
        this.assetModel.client === undefined
      ) {
        this.assetModel.client = {
          id: "",
          serialNumber: "",
          uuid: "",
          maxAddress: "",
          name: "",
        };
      }
      if (this.assetModel.location === null) {
        this.assetModel.location = {
          id: "",
          name: "",
          nameAbbreviation: "",
          country: "",
          countryAbbreviation: "",
          city: "",
          cityAbbreviation: "",
          timeZone: "",
          street: "",
          number: "",
          postcode: "",
          publicIP: "",
          downloadSpeed: "",
          uploadSpeed: "",
          type: "",
          azureLocation: "",
          customerId: "",
        };
      }
      if (this.assetModel.person === null) {
        this.assetModel.person = {
          id: "",
          title: "",
          middleName: "",
          surname: "",
          academicDegree: "",
          employeeType: "",
          costCenter: "",
          phoneNr: "",
          faxNr: "",
          mobileNr: "",
          emailPrimary: "",
          state: "",
          emailOptional: "",
          domain: "",
          departementName: "",
          departementShort: "",
          roomNr: "",
          employeeNr: "",
          customerId: "",
        };
      }
      if (this.assetModel.vendorModel === null) {
        this.assetModel.vendorModel = {
          id: "",
          name: "",
          modelFamily: "",
          modelType: "",
          files: [],
        };
      }
    });
    this.$store.dispatch("getAssetTypes", impersonation.getCustomer().id);
    this.$store.dispatch("getAssetClasses", impersonation.getCustomer().id);
  },
  data() {
    return {
      uploadURL:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/tasks/upload/releaseMgmt",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      url:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/#/assetmanagementdetails/",
      assetModel: {
        id: "",
        assetID: "",
        assetTypeId: "",
        customerId: "",
        customer: {},
        assetClassId: "",
        assetStatus: "",
        description: "",
        assetClass: {
          name: "",
          id: "",
        },
        assetType: {
          name: "",
          id: "",
        },
        building: "",
        floor: "",
        room: "",
        coordinates: "",
        invoice: [],
        invoiceId: "",
        purchaseDate: "",
        purchaseValue: 0,
        ciNumber: "",
        person: {
          id: "",
          title: "",
          middleName: "",
          surname: "",
          academicDegree: "",
          employeeType: "",
          costCenter: "",
          phoneNr: "",
          faxNr: "",
          mobileNr: "",
          emailPrimary: "",
          state: "",
          emailOptional: "",
          domain: "",
          departementName: "",
          departementShort: "",
          roomNr: "",
          employeeNr: "",
          customerId: "",
        },
        personId: "",
        location: {
          id: "",
          name: "",
          nameAbbreviation: "",
          country: "",
          countryAbbreviation: "",
          city: "",
          cityAbbreviation: "",
          timeZone: "",
          street: "",
          number: "",
          postcode: "",
          publicIP: "",
          downloadSpeed: "",
          uploadSpeed: "",
          type: "",
          azureLocation: "",
          customerId: "",
        },
        locationId: "",
        clientId: "",
        currency: "",
        depreciationMonths: "",
        client: {
          id: "",
          serialNumber: "",
          uuid: "",
          maxAddress: "",
          name: "",
        },
        vendorModel: {
          id: "",
          name: "",
          modelFamily: "",
          modelType: "",
          files: [],
        },
      },
      currencies: [
        { key: 1, value: "€" },
        { key: 2, value: "$" },
        { key: 3, value: "£" },
      ],
      accountingValue: "",
    };
  },
  methods: {
    openAssignVendor() {
      this.$refs.vendorModel.openDialog(this.assetModel.id);
    },
    handleRemoveContent(a, fileList) {
      let fileName = a.name;
      this.$store.dispatch("removeReleaseMgmtFile", fileName);
      this.setContent(fileList);
    },
    handleSuccessContent(a, b, fileList) {
      this.setContent(fileList);
    },
    setContent(file) {
      if (file.length > 0) {
        this.assetModel.invoice = [
          { id: file[0].response.id, name: file[0].response.name },
        ];
      } else {
        this.osModel.content = [{ id: "", name: "" }];
      }
    },
    generateUUID() {
      this.assetModel.ciNumber = uuidv4();
    },
    assignClient() {
      this.$refs.assignClient.openDialog(this.assetModel.id);
    },
    assignPerson() {
      this.$refs.assignPerson.openDialog(this.assetModel.id);
    },
    assignLocation() {
      this.$refs.assignLocation.openDialog(this.assetModel.id);
    },
    getStatusClass() {
      if (this.assetModel.assetStatus === "new") {
        return "statusNew";
      } else {
        return "statusActive";
      }
    },
    refreshPage() {
      this.$router.go();
    },
    submit() {
      if (this.assetModel.purchaseDate === "") {
        this.assetModel.purchaseDate = "0001-01-01T00:00:00";
      }
      let payload = _.cloneDeep(this.assetModel);
      if (this.assetModel.invoice.length === 0) {
        payload.invoice = null;
      } else {
        payload.invoice = this.assetModel.invoice[0];
      }
      this.$store.dispatch("updateAssetModel", payload);
      this.refreshPage();
    },
    openAssetTypeDialog(value) {
      if (value === "newAssetType") {
        this.$refs.assetType.openDialog();
        this.assetModel.assetTypeId = "";
      } else {
        this.assetTypes.forEach((el) => {
          if (el.id === value) {
            this.assetModel.assetType = el;
          }
        });
      }
    },
    openAssetClassDialog(value) {
      if (value === "newAssetClass") {
        this.$refs.assetClass.openDialog();
        this.assetModel.assetClassId = "";
      }
    },
    filteredAssetClasses() {
      let result = [];
      if (
        this.assetModel.assetTypeId === null ||
        this.assetModel.assetTypeId.length === 0
      ) {
        return result;
      } else {
        this.assetClasses.forEach((x) => {
          if (x.assetTypeId === this.assetModel.assetTypeId) {
            result.push(x);
          }
        });
        return result;
      }
    },
    formatDate() {
      let date = new Date(Date.parse(this.assetModel.purchaseDate));
      date = new Date(date - date.getTimezoneOffset() * 60000);
      this.assetModel.purchaseDate = date.toUTCString();
      this.calculateAccountingValue();
    },
    calculateAccountingValue() {
      if (
        this.assetModel.depreciationMonths > 0 &&
        this.assetModel.purchaseDate !== ""
      ) {
        var now = new Date();
        var purchaseDate = new Date(Date.parse(this.assetModel.purchaseDate));
        var monthsDifference =
          (now.getYear() - purchaseDate.getYear()) * 12 +
          now.getMonth() -
          purchaseDate.getMonth();
        var value =
          this.assetModel.purchaseValue -
          (this.assetModel.purchaseValue / this.assetModel.depreciationMonths) *
            monthsDifference;
        if (value >= 0) {
          if (this.assetModel.currency !== null) {
            this.accountingValue =
              "" + value.toFixed(2) + " " + this.assetModel.currency;
          } else {
            this.accountingValue = "" + value.toFixed(2);
          }
        } else {
          if (this.assetModel.currency !== null) {
            this.accountingValue = "" + 0 + " " + this.assetModel.currency;
          } else {
            this.accountingValue = "" + 0;
          }
        }
      }
    },
  },
  computed: {
    ...mapState(["assetTypes", "assetClasses"]),
  },
};
</script>

<style scoped>
.inside-container {
  width: 90%;
}
.assign-btn {
  height: 35px;
  position: absolute;
  right: 10px;
  top: 5px;
  text-align: center;
}

.sub-headline {
  left: -30px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding-right {
  padding-right: 150px;
}

.statusNew {
  background-color: #0b57a5;
  width: 150px;
  height: 35px;
  border-radius: 2px;
  color: white;
}

.statusActive {
  background-color: green;
  width: 150px;
  height: 35px;
  border-radius: 2px;
  color: white;
}

.flex {
  display: flex;
}

.label {
  padding-right: 20px;
  width: 200px;
}

.card {
  /*background-color: #e7e7e7;*/
  margin-top: 20px;
  margin-left: 20px;
}

.input-style {
  margin-bottom: 4px;
  border-radius: 20px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  color: #3c3c3c;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

label {
  *display: inline;
  display: inline-block;
  text-align: left;
  flex-direction: row;
  justify-content: flex-end;
  width: 120px;
}

input {
  display: inline-block;
  *display: inline;
}

p {
  text-align: left;
  margin-left: 20px;
}

.border {
  border: 1px solid black;
}

.centerVertically {
  margin: auto;
}
.error {
  font-size: 12px;
  color: red;
}

.flex-div {
  display: flex;
  white-space: nowrap;
}

.margin-left {
  margin-left: 30px;
}
</style>
