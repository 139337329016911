<template lang="html">
  <div>
    <!-- <add-location-dialog ref="addLocationDialog" :showCompanies="false"></add-location-dialog> -->
    <add-person-dialog ref="addPersonDialog"></add-person-dialog>
    <el-dialog
      title="Edit New Company"
      v-model="showDialog"
      center>
      <el-form :model="company" :rules="companyRules" ref="editCompanyForm">
        <el-form-item label="Corporate Name" prop="corporateName">
          <el-input
            v-model="company.corporateName"
            placeholder="Insert Corporate Name" />
        </el-form-item>
        <el-form-item label="Form Of Organization" prop="formOfOrganization">
          <el-input
            placeholder="Insert Form Of Organization"
            v-model="company.formOfOrganization" />
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input
            placeholder="Insert Description"
            v-model="company.description" />
        </el-form-item>
        <el-form-item label="Website" prop="linkWebsite">
          <el-input
            placeholder="Insert Link to website"
            v-model="company.linkWebsite" />
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select v-model="company.type" placeholder="Select Type">
            <el-option
              v-for="type in typeOptions"
              :key="type.value"
              :value="type.value"
              :label="type.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Expert" prop="expertId">
          <el-select
            placeholder="Select Expert"
            v-model="company.expertId"
            @change="openAddPersonDialog">
            <el-option
              key="new-person"
              value="new-person"
              label="New Person...">
            </el-option>
            <el-option
              v-for="person in persons"
              :key="person.id"
              :value="person.id"
              :label="getPersonName(person)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Headquarter" prop="headquarterId">
          <el-select
            placeholder="Select Headquarter"
            v-model="company.headquarterId"
            @change="openAddLocationDialog">
            <el-option
              key="new-location"
              value="new-location"
              label="New Headquarter...">
            </el-option>
            <el-option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
              :label="location.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          class="space-top"
          type="primary"
          @click="editCompany">
            Edit
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import AddCompanyValidationRules from "../AddCompanyValidationRules";
import AddPersonDialog from "./AddPersonDialog";

export default {
  name: "edit-company-dialog",
  mixins: [AddCompanyValidationRules],
  components: {
    AddPersonDialog,
  },
  data() {
    return {
      company: {
        id: "",
        corporateName: "",
        formOfOrganization: "",
        description: "",
        linkWebsite: "",
        type: "",
        expertId: "",
        headquarterId: "",
      },
      showDialog: false,
      typeOptions: [
        {
          value: "vendor",
          label: "Vendor",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "unspecified",
          label: "Unspecified",
        },
      ],
    };
  },
  methods: {
    openDialog(company) {
      this.fillData(company);
      this.showDialog = true;
    },
    fillData(company) {
      this.company.id = company.id;
      this.company.corporateName = company.corporateName;
      this.company.formOfOrganization = company.formOfOrganization;
      this.company.description = company.description;
      this.company.linkWebsite = company.linkWebsite;
      this.company.type = company.type;
      if (company.expert != null) {
        this.company.expertId = company.expert.id;
      }
      if (company.headquarter != null) {
        this.company.headquarterId = company.headquarter.id;
      }
    },
    getPersonName(person) {
      let middleName = "";
      if (person.middleName != null && person.middleName.length > 0) {
        middleName = " " + person.middleName;
      }
      return person.givenName + middleName + " " + person.surname;
    },
    editCompany() {
      this.$refs.editCompanyForm.validate((valid) => {
        if (valid) {
          let payload = this.createPayload();
          this.$store
            .dispatch("updateMainCompany", payload)
            .then((response) =>
              this.$store.commit("updateCompany", response.data)
            );
          this.$refs.editCompanyForm.resetFields();
          this.showDialog = false;
        }
      });
    },
    openAddLocationDialog() {
      if (this.company.headquarterId === "new-location") {
        this.$refs.addLocationDialog.openDialog();
        this.company.headquarterId = "";
      }
    },
    openAddPersonDialog() {
      if (this.company.expertId === "new-person") {
        this.$refs.addPersonDialog.openDialog();
        this.company.expertId = "";
      }
    },
    createPayload() {
      return {
        corporateData: {
          expertId: this.company.expertId,
          headquarterId: this.company.headquarterId,
          corporateName: this.company.corporateName,
          formOfOrganization: this.company.formOfOrganization,
          linkWebsite: this.company.linkWebsite,
          description: this.company.description,
          type: this.company.type,
        },
        mainCompanyId: this.company.id,
      };
    },
  },
  computed: {
    persons: {
      get() {
        return this.$store.state.persons;
      },
      set() {
        // Do nothing
      },
    },
    locations: {
      get() {
        return this.$store.state.customersLocations;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
