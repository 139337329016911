<template>
  <div class="tableContainer">
    <h3>Update Software Package Revision</h3>
    <el-table
      :data="revisionSoftwarePackages"
      v-loading="loadingRevisionSoftwarePackages"
    >
      <el-table-column
        style="width: 30%"
        prop="name"
        label="Name"
      ></el-table-column>
      <el-table-column
        style="width: 30%; border: 1px solid red"
        prop="version"
        label="Version"
        sortable
      >
      </el-table-column>
      <el-table-column width="150">
        <template v-slot="scope">
          <el-button type="primary" @click="updateSoftware(scope.row)"
            >Update</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="40">
        <template v-slot="scope">
          <img
            width="20"
            height="20"
            v-if="checkIfLoadingRevision(scope.row, 'software')"
            src="../../../static/loading-gif.gif"
          />
        </template>
      </el-table-column>
    </el-table>
    <br />
    <h3>New Software Versions</h3>
    <el-table
      :data="newSoftwarePackages"
      v-loading="loadingNewSoftwarePackages"
    >
      <el-table-column
        style="width: 30%"
        prop="name"
        label="Name"
      ></el-table-column>
      <el-table-column
        style="width: 30%"
        prop="version"
        label="Version"
        sortable
      />
      <el-table-column width="150" label="Action">
        <template v-slot="scope">
          <el-button type="primary" @click="shopNewSoftware(scope.row)"
            >Shop</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="40">
        <template v-slot="scope">
          <img
            width="20"
            height="20"
            v-if="checkIfLoadingNew(scope.row, 'software')"
            src="../../../static/loading-gif.gif"
          />
        </template>
      </el-table-column>
    </el-table>
    <h3>Update Image Revision</h3>
    <el-table :data="revisionImages" v-loading="loadingRevisionImages">
      <el-table-column
        style="width: 30%"
        prop="name"
        label="Name"
      ></el-table-column>
      <el-table-column
        style="width: 30%; border: 1px solid red"
        prop="buildNr"
        label="Build Nr"
        sortable
      >
      </el-table-column>
      <el-table-column width="150">
        <template v-slot="scope">
          <el-button type="primary" @click="updateImage(scope.row)"
            >Update</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="40">
        <template v-slot="scope">
          <img
            width="20"
            height="20"
            v-if="checkIfLoadingRevision(scope.row, 'image')"
            src="../../../static/loading-gif.gif"
          />
        </template>
      </el-table-column>
    </el-table>
    <br />
    <h3>New Images</h3>
    <el-table :data="newImages" v-loading="loadingNewImages">
      <el-table-column
        style="width: 30%"
        prop="name"
        label="Name"
      ></el-table-column>
      <el-table-column
        style="width: 30%; border: 1px solid red"
        prop="buildNr"
        label="Build Nr"
        sortable
      >
      </el-table-column>
      <el-table-column width="150">
        <template v-slot="scope">
          <el-button type="primary" @click="shopNewImage(scope.row)"
            >Shop</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="40">
        <template v-slot="scope">
          <img
            width="20"
            height="20"
            v-if="checkIfLoadingNew(scope.row, 'image')"
            src="../../../static/loading-gif.gif"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import { repairImage, shopNewImages } from "../../services/customerImages";
import {
  getLatestVersion,
  shopLatestVersion,
  repairSoftware,
} from "../../services/customerSoftwares";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      loadingRevisionSoftwares: [],
      loadingNewSoftwares: [],
      updateImages: [],
      newImagesArray: [],
    };
  },
  methods: {
    checkIfLoadingRevision(entity, type) {
      if (type === "software") {
        let index = this.loadingRevisionSoftwares.indexOf(entity.id);
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      } else if (type === "image") {
        let index = this.updateImages.indexOf(entity.id);
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkIfLoadingNew(entity, type) {
      if (type === "software") {
        let index = this.loadingNewSoftwares.indexOf(entity.id);
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      } else if (type === "image") {
        let index = this.newImagesArray.indexOf(entity.id);
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    getUsedVersion(softwareId) {
      getLatestVersion(impersonation.getCustomer().id, softwareId)
        .then((response) => console.log(response.data))
        .catch((err) => console.log({ err }));
      return "Under Construction";
    },
    shopNewSoftware(data) {
      this.loadingNewSoftwares.push(data.id);
      shopLatestVersion(impersonation.getCustomer().id, data.id)
        .then((response) => {
          this.$store.commit("removeNewSoftware", response.data.id);
          for (let i = 0; i < this.loadingNewSoftwares.length; i++) {
            if (this.loadingNewSoftwares[i] === data.id) {
              this.loadingNewSoftwares.splice(i, 1);
              break;
            }
          }
        })
        .catch((err) => ElMessage.error(err.data));
    },
    shopNewImage(data) {
      this.newImagesArray.push(data.id);
      shopNewImages(impersonation.getCustomer().id, data.id)
        .then((response) => {
          this.$store.commit("removeNewImage", response.data.id);
          for (let i = 0; i < this.newImagesArray.length; i++) {
            if (this.newImagesArray[i] === data.id) {
              this.newImagesArray.splice(i, 1);
              break;
            }
          }
        })
        .catch((err) => ElMessage.error(err.data));
    },
    updateSoftware(data) {
      this.loadingRevisionSoftwares.push(data.id);
      repairSoftware(impersonation.getCustomer().id, data.id)
        .then((response) => {
          this.$store.commit("removeRevisionSoftware", response.data.id);
          for (let i = 0; i < this.loadingRevisionSoftwares.length; i++) {
            if (this.loadingRevisionSoftwares[i] === data.id) {
              this.loadingRevisionSoftwares.splice(i, 1);
              break;
            }
          }
        })
        .catch((err) => ElMessage.error(err.data));
    },
    updateImage(data) {
      this.updateImages.push(data.id);
      repairImage(impersonation.getCustomer().id, data.id).then((response) => {
        this.$store.commit("removeRevisionImage", response.data.id);
        for (let i = 0; i < this.updateImages.length; i++) {
          if (this.updateImages[i] === data.id) {
            this.updateImages.splice(i, 1);
            break;
          }
        }
      });
    },
  },
  computed: {
    ...mapState([
      "newSoftwarePackages",
      "loadingNewSoftwarePackages",
      "loadingRevisionSoftwarePackages",
      "revisionSoftwarePackages",
      "revisionImages",
      "loadingRevisionImages",
      "newImages",
      "loadingNewImages",
    ]),
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}
</style>
