<template>
  <el-dialog
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
    title="Add Release-Plan"
  >
    <el-form :model="releasePlan" ref="form" :rules="rules">
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="releasePlan.name"
          placeholder="Please set the name"
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import impersonation from "../../impersonation";

export default {
  data() {
    return {
      showDialog: false,
      releasePlan: {
        id: "",
        name: "",
        customerId: impersonation.getCustomer().id,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please set the name of the release plan",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog(releasePlan) {
      this.releasePlan.name = releasePlan.name;
      this.releasePlan.id = releasePlan.id;
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editReleasePlan", this.releasePlan);
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
