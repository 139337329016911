export function getSWImages () {
  return window.axiosInstance.get('swImages')
}

export function addSWImage (data) {
  return window.axiosInstance.post('swImages', data)
}

export function editSWImage (data) {
  return window.axiosInstance.post('swImages/edit', data)
}

export function deleteSWImage (imageId) {
  return window.axiosInstance.delete(`/swImages/${imageId}`)
}

export function getStreamlessImages () {
  return window.axiosInstance.get('swImages/streamless')
}

export function setStream (streamId, payload) {
  return window.axiosInstance.post(`swImages/addToStream/${streamId}`, payload)
}

export function getStreamsImages (streamId) {
  return window.axiosInstance.get(`swImages/imageStreamImages/${streamId}`)
}

export function publishInShop (imageId) {
  return window.axiosInstance.post(`swImages/publish/${imageId}`)
}

export function retreatFromShop (imageId) {
  return window.axiosInstance.post(`swImages/retreat/${imageId}`)
}

export function deleteUnattendFileAdd (subfolderPath, fileName) {
  return window.axiosInstance.delete(`swImages/unattend/${subfolderPath}/${fileName}`)
}

export function deleteUnattendFileEdit (subfolderPath, imageId) {
  return window.axiosInstance.delete(`swImages/unattend/${subfolderPath}/${imageId}`)
}

export function deleteOEMPartitionFileEdit (subfolderPath, imageId) {
  return window.axiosInstance.delete(`swImages/oemPartition/${subfolderPath}/${imageId}`)
}
