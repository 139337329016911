export function createWorkflow (payload) {
  return window.axiosInstance.post(`/workflows`, payload)
}

export function getWorkflows () {
  return window.axiosInstance.get(`/workflows`)
}

export function updateWorkflow (payload) {
  return window.axiosInstance.put(`/workflows`, payload)
}
