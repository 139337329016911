export function getVendorModels () {
  return window.axiosInstance.get('vendor-mgmt')
}

export function getVendorModel (vendorModelId) {
  return window.axiosInstance.get(`vendor-mgmt/${vendorModelId}`)
}

export function addVendorModel (vendorModel) {
  return window.axiosInstance.post('vendor-mgmt', vendorModel)
}

export function updateVendorModel (vendorModel) {
  return window.axiosInstance.put('vendor-mgmt', vendorModel)
}

export function deleteVendorModel (vendorModelId) {
  return window.axiosInstance.delete(`vendor-mgmt/${vendorModelId}`)
}
