<template>
  <el-dialog
    title="Hardware Model Drivers"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-table :data="drivers">
      <el-table-column prop="name" label="Name"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      drivers: [],
      showDialog: false,
    };
  },
  methods: {
    openDialog(drivers) {
      this.drivers = drivers;
      this.showDialog = true;
    },
    handleClose() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
