import passwordStrength from '../../validations/passwordStrength'
import { vnName } from '../virtualNetworks/VirtualNetworkValidation'

export default{
  name: 'virtual-machine-mixin',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input virtual network name.', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars.', trigger: 'blur' }
        ],
        subnet: [
          { type: 'array', required: true, message: 'Please select subnet.', trigger: 'change' }
        ],
        type: [
          { required: true, message: 'Please select a server size.', trigger: 'blur' }
        ],
        operatingSystem: [
          { required: true, message: 'Please select an operating system.', trigger: 'blur' }
        ],
        'admin.username': [
          { required: true, message: 'Please select an admin username.', trigger: 'blur' }
        ],
        'admin.password': [
          { required: true, message: 'Please input admin password', trigger: 'blur' },
          { validator: passwordStrength, trigger: 'blur' },
          { min: 10, message: 'Password should at least have 10 chars', trigger: 'blur' }
        ],
        storageAccount: [
          { required: true, message: 'Please select a system disk.', trigger: 'change' }
        ],
        'systemDisk.name': [
          { required: true, message: 'Please define a system disk name.', trigger: 'blur' }
        ],
        'additionalDisk.name': [
          { required: true, message: 'Please define a additional disk name.', trigger: 'blur' }
        ],
        subnetName: [
          { required: true, message: 'Please input subnet name', trigger: 'blur' },
          { validator: vnName, trigger: 'blur' }
        ]
      }
    }
  }
}
