export default function userNamevalidation (rule, value, callback) {
  let regexp = /^[^\\/"[\]:|<>+=;,?*@.]+$/
  if (value === '') {
    callback(new Error('Please input user name'))
  }
  if (value.toLowerCase() === 'admin' || value.toLowerCase() === 'administrator' || value.toLowerCase() === 'user') {
    callback(new Error('User name cannot be ' + value))
  }
  if (!value.match(regexp)) {
    callback('User name must not contain special characters')
  } else if (value.length > 20) {
    callback('User name must not be more than 20 characters long')
  } else {
    callback()
  }
}
