<template>
  <el-dialog
    v-model="showDialog"
    title="Revision Messages"
    width="50%"
    :close-on-click-modal="false"
    :before-close="handleCloseDialog"
  >
    <div class="container">
      <el-table :data="revisionMessages" v-loading="loading">
        <el-table-column
          prop="displayRevisionNumber"
          label="Revision Number"
        ></el-table-column>
        <el-table-column prop="message" label="Message"></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { getRevisionMessages } from "../../services/softwares";
import { getRevisionMessagesCustomer } from "../../services/customerSoftwares";
import { errorMessage } from "../../store/modules/util";
import impersonation from "../../impersonation";

export default {
  data() {
    return {
      showDialog: false,
      revisionMessages: [],
      loading: false,
    };
  },
  methods: {
    open(softwareId) {
      this.showDialog = true;
      this.loading = true;
      getRevisionMessages(softwareId)
        .then((response) => {
          this.revisionMessages = response.data;
        })
        .catch((error) => errorMessage(error.response.data))
        .finally(() => {
          this.loading = false;
        });
    },
    openCustomerSoftware(customerSoftwareId) {
      this.showDialog = true;
      getRevisionMessagesCustomer(
        impersonation.getCustomer().id,
        customerSoftwareId
      )
        .then((response) => {
          this.revisionMessages = response.data;
        })
        .catch((error) => errorMessage(error.response.data))
        .finally(() => {
          this.loading = false;
        });
    },
    handleCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  text-align: center;
}
</style>
