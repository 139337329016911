  export default{
    name: 'task-validation',
    data () {
      let validateFiles = (rule, value, callback) => {
        let vm = this.model
        if (vm.files === null || vm.files.length === 0) {
          callback(new Error('Please add at least one file'))
        } else {
          callback()
        }
      }
      let validateExecutionContext = (rule, value, callback) => {
        if (this.model.executionContext === '') {
          callback(new Error('Please set the execution context'))
        } else {
          callback()
        }
      }
      let validateVisibility = (rule, value, callback) => {
        if (this.model.visibility === '') {
          callback(new Error('Please set the visibility property'))
        } else {
          callback()
        }
      }
      return {
        rules: {
          name: [
            { required: true, message: 'Please input task name', trigger: ['blur', 'change'] },
            { min: 3, message: 'Name should at least have 3 chars', trigger: ['blur', 'change'] }
          ],
          files: [
            { validator: validateFiles, type: 'array', required: true, trigger: ['blur', 'change'] }
          ],
          executable: [
            { required: true, message: 'Please specify executable', trigger: 'change' }
          ],
          executionContext: [
            { validator: validateExecutionContext, required: true, trigger: 'change' }
          ],
          visibility: [
            { validator: validateVisibility, required: true, trigger: 'change' }
          ]
        }
      }
    }
  }
