<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Customer Task Streams</h3>
      <el-row type="flex" class="header">
        <el-col :span="6" class="search">
          <el-input
            placeholder="Find Task Stream"
            v-model="search"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon v-if="isSubset" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
          <div class="results" v-if="isSubset">
            <div v-if="!search">
              showing {{ filteredCustomerStreams.length }} of
              {{ customerTaskStreams.length }}
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="8" :offset="14" style="margin-bottom: 30px">
      <el-button type="primary" @click="goToShop">Add Task</el-button>
    </el-col>
    <div class="tableContainer">
      <el-table
        v-loading="loadingCustomersStreams"
        :data="filteredCustomerStreams"
        highlight-current-row
      >
        <el-table-column prop="icon" label="Icon" width="80">
          <template v-slot="scope">
            <img
              v-if="
                scope.row.icon !== null &&
                scope.row.icon.id !== '' &&
                scope.row.icon.name != ''
              "
              :src="scope.row.icon"
              height="40"
              width="40"
            />
            <img
              v-else
              src="../../../static/packages-icon-21.png"
              height="40"
              width="40"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column
          align="center"
          prop="updateSettings"
          label="Update Settings"
        ></el-table-column>
        <el-table-column align="center" label="Latest Active Version">
          <template v-slot="scope">
            <span>{{ getLatestActiveSWVersion(scope.row.id) }}</span>
          </template>
          <!-- Get latest version of all active sw packages in customersStreams -->
        </el-table-column>
        <el-table-column
          prop="isEnterpriseStream"
          label="Enterprise"
          align="center"
        >
          <template v-slot="scope">
            <img
              v-if="scope.row.isEnterpriseStream === true"
              width="20"
              height="20"
              src="../../../static/success.png"
            />
          </template>
        </el-table-column>
        <el-table-column label="Application Owner" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.applicationOwner !== null">{{
              scope.row.applicationOwner.givenName +
              " " +
              scope.row.applicationOwner.surname
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Deployment Status">
          <template>
            <span>x / {{ clients.length }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              :showCustomerSoftware="true"
              @showCustomerSoftware="showStreamsSoftware"
              @edit="editCustomerSoftwareStream"
              @delete="confirmDelete"
            ></actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <customer-softwares ref="customerSoftwares"></customer-softwares>
    <edit-customer-stream ref="editCustomerStream"></edit-customer-stream>
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import impersonation from "../../impersonation";
import CustomerSoftwares from "./CustomerSoftwares.vue";
import EditCustomerStream from "./EditCustomerStream.vue";
import { mapState } from "vuex";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "customer-softwares": CustomerSoftwares,
    "edit-customer-stream": EditCustomerStream,
  },
  created() {
    let customerId = impersonation.getCustomer().id;
    this.$store.dispatch("getCustomersStreams", customerId);
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    goToShop() {
      this.$router.push({ name: "buy" });
    },
    showStreamsSoftware(entity) {
      this.$refs.customerSoftwares.openDialog(entity.streamMembers);
    },
    confirmDelete(customerStream) {
      this.$confirm(
        "This will permanently delete the Stream and its Software Packages. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, customerStream), this.deleteError);
    },
    deleteSuccess(customerStream) {
      let payload = {
        customerId: impersonation.getCustomer().id,
        customerStreamId: customerStream.id,
      };
      this.$store.dispatch("deleteCustomerStream", payload);
      this.showDialog = false;
    },
    deleteError() {},
    editCustomerSoftwareStream(stream) {
      this.$refs.editCustomerStream.openDialog(stream);
    },
    clearSearch() {
      this.search = "";
    },
    getLatestActiveSWVersion(swStreamId) {
      let latestVersion = 0;
      let index = 0;
      let stream = this.customersStreams.find((el) => {
        return el.id === swStreamId;
      });
      latestVersion = parseInt(
        stream.streamMembers[0].version.replaceAll(".", "")
      );
      for (let i = 0; i < stream.streamMembers.length; i++) {
        let streamMember = stream.streamMembers[i];
        if (streamMember.customerStatus === "Active") {
          let toCompare = parseInt(streamMember.version.replaceAll(".", ""));
          if (toCompare > latestVersion) {
            latestVersion = toCompare;
            index = i;
          }
        } else {
          latestVersion = 0;
        }
      }
      if (latestVersion === 0) {
        return "no active version";
      } else {
        return stream.streamMembers[index].version;
      }
    },
  },
  computed: {
    ...mapState(["customersStreams", "loadingCustomersStreams", "clients"]),
    customerTaskStreams() {
      var result = [];
      for (let i = 0; i < this.customersStreams.length; i++) {
        if (this.customersStreams[i].type === "Task") {
          result.push(this.customersStreams[i]);
        }
      }
      return result;
    },
    filteredCustomerStreams() {
      var temp = this.filterEntities(this.customersStreams, this.search);
      var result = [];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].type === "Task") {
          result.push(temp[i]);
        }
      }
      return result;
    },
    isSubset() {
      return (
        this.customersStreams.length !== this.filteredCustomerStreams.length
      );
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  margin-bottom: 50px;
}
</style>
