<template>
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <h3>Companies</h3>
        <companies-settings :customerId="customerId"></companies-settings>
        <br/>
        <h3>Persons</h3>
        <persons-settings></persons-settings>
        <br/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PersonsSettings from '../customers/settings/PersonsSettings'
import CompaniesSettings from '../customers/settings/CompaniesSettings'
import impersonation from '../../impersonation/'

export default {
  components: {
    PersonsSettings,
    CompaniesSettings
  },
  data () {
    return {
      customerId: ''
    }
  },
  created () {
    this.customerId = impersonation.getCustomer().id
    // this.$store.dispatch('getCustomersCompanies', customerId)
    this.$store.dispatch('getCustomersPersons', this.customerId)
  }
}
</script>

<style lang="css" scoped>
</style>
