<template lang="html">
  <div class="container">
    <add-location ref="addLocation" :showCompanies="true" :customerId="customerId"></add-location>
    <edit-location-dialog ref="editLocationDialog"></edit-location-dialog>
    <el-col :span="22">
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input placeholder="Find locations" v-model="search">
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i slot="suffix" class="el-input__icon el-icon-circle-close clickable" v-if="isSubset()" @click="clearSearch"></i> -->
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset()">
            showing {{filteredLocations.length}} of {{locations.length}}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button
            type="primary"            
            @click="openAddLocationDialog"
            class="top-right">
              Add Location
          </el-button>
        </el-col>
      </el-row>
    </el-col>
    <el-table :data="filteredLocations" style="width: 90%; margin: 0 auto;">
      <el-table-column label="Name">
        <template v-slot="scope">
          {{getNameData(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column label="Country">
        <template v-slot="scope">
  {{ getCountryData(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Postcode" prop="postcode"></el-table-column>
      <el-table-column label="City">
        <template v-slot="scope">
  {{ getCityData(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Street/Nr">
        <template v-slot="scope">
  {{ getStreetAndNumber(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Time Zone" prop="timeZone"></el-table-column>
      <el-table-column label="Public IP" prop="publicIP"></el-table-column>
      <el-table-column label="Download/Upload Speed">
        <template v-slot="scope">
  {{ getDownloadUploadSpeed(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Type" prop="type"></el-table-column>
      <el-table-column label="Company">
        <template v-slot="scope">
  {{ getCompanyName(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Customer">
        <template v-slot="scope">
  {{ getCustomerName(scope.row) }}
</template>
      </el-table-column>
      <el-table-column label="Actions">
        <template v-slot="scope">
  <actions-dropdown
    :hideRetreatFromShop="true"
    :entity="scope.row"
    :edit="true"
    :deleteItem="true"
    @edit="openEditLocationDialog"
    @delete="confirmDelete"
  ></actions-dropdown>
</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown";
import _ from "lodash";
import AddLocation from "./AddLocation";
import EditLocationDialog from "./EditLocationDialog";

export default {
  name: "locations-settings",
  mixins: [FilterMixin],
  props: ["customerId"],
  components: {
    ActionsDropdown,
    AddLocation,
    EditLocationDialog,
  },
  data() {
    return {
      search: "",
    };
  },
  created() {
    this.$store.dispatch("getCustomers");
  },
  methods: {
    openAddLocationDialog() {
      this.$refs.addLocation.openDialog();
    },
    isSubset() {
      return this.locations.length !== this.filteredLocations.length;
    },
    clearSearch() {
      this.search = "";
    },
    getNameData(data) {
      return data.name + " (" + data.nameAbbreviation + ")";
    },
    getCityData(data) {
      if (data.city && data.cityAbbreviation) {
        return data.city + " (" + data.cityAbbreviation + ")";
      } else {
        return "";
      }
    },
    getCountryData(data) {
      if (data.country && data.countryAbbreviation) {
        return data.country + " (" + data.countryAbbreviation + ")";
      } else {
        return "";
      }
    },
    getStreetAndNumber(data) {
      return data.street + " " + data.number;
    },
    getDownloadUploadSpeed(data) {
      if (data.downloadSpeed && data.uploadSpeed) {
        return data.downloadSpeed + " / " + data.uploadSpeed + " kbit/s";
      } else {
        return "";
      }
    },
    getCompanyName(data) {
      let result = "//";
      var companies = _.cloneDeep(this.$store.state.customersCompanies);
      for (let i = 0; i < companies.length; i++) {
        if (data.companyId === companies[i].id) {
          result = companies[i].corporateName;
          break;
        }
      }
      return result;
    },
    getCustomerName(data) {
      let result = "//";
      if (data.customer != null && data.customer !== undefined) {
        result = data.customer.name;
      }
      return result;
    },
    openEditLocationDialog(location) {
      this.$refs.editLocationDialog.openDialog(location);
    },
    confirmDelete(location) {
      this.$confirm(
        "This will permanently delete the location. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, location));
    },
    deleteSuccess(location) {
      this.$store.dispatch("deleteLocation", location.id);
    },
  },
  computed: {
    locations: {
      get() {
        return this.$store.state.customersLocations;
      },
      set() {
        // Do nothing
      },
    },
    filteredLocations() {
      return this.filterEntities(this.locations, this.search);
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  padding-top: 20px;
}
</style>
