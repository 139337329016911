<template>
  <el-form-item label="Location" :prop="prop" :rules="rules">
    <el-select v-model="model" @change="change" filterable placeholder="Select location">
      <el-option v-for="location in locations" :key="location.name" :label="location.displayName" :value="location.name"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'location-select',
  props: ['value', 'prop'],
  data () {
    return {
      model: this.value,
      rules: [
        { required: true, message: 'Location is required', trigger: 'change' }
      ]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    ...mapState(['locations'])
  }
}
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
