<template>
  <div>
    <header-admin class="menu" id="header-admin"></header-admin>
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderAdmin from "./HeaderAdmin.vue";

export default {
  name: "base-admin",
  components: {
    HeaderAdmin,
  },
};
</script>

<style scoped>
.menu {
  margin-bottom: 20px;
}
</style>
