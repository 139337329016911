<template>
  <div>
    <br />
    <el-col :span="22" :offset="1">
      <div class="alert-banner" v-if="showHintBanner">
        <span
          >You dont have created a Storage Entry Point yet. Please create a SEP </span
        ><router-link :to="{ name: 'bases' }"> here</router-link>
      </div>
      <br />
      <el-col :span="6" class="search">
        <el-input
          placeholder="Find products"
          v-model="search"
          prefix-icon="Search"
        >
          <template #suffix>
            <el-icon v-if="isSubset" @click="clearSearch">
              <CircleClose />
            </el-icon>
          </template>
        </el-input>
        <div v-if="isSubset">
          showing {{ filteredImages.length }} of {{ buyOSImages.length }}
        </div>
      </el-col>
      <br />
      <br />
      <div style="width: 85%; margin: 0 auto">
        <el-table :data="filteredImages" v-loading="loading">
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column label="Edition" prop="edition"></el-table-column>
          <el-table-column
            label="Architecture"
            prop="architecture"
          ></el-table-column>
          <el-table-column label="Language" prop="language"></el-table-column>
          <el-table-column label="Build-Nr" prop="buildNr"></el-table-column>
          <el-table-column label="Update" prop="update"></el-table-column>
          <el-table-column label="icon">
            <template v-slot="scope">
              <img
                v-if="typeof scope.row.icon === 'string'"
                :src="scope.row.icon"
                height="40"
                width="40"
              />
              <img
                v-else
                src="../../../static/packages-icon-21.png"
                height="40"
                width="40"
              />
            </template>
          </el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <el-button type="primary" @click="confirmOrder(scope.row)"
                >Order</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import FilterMixin from "../../mixins/FilterMixin";
import { successMessage, errorMessage } from "../../store/modules/util";
import { getSEPs } from "../../services/customers";

export default {
  mixins: [FilterMixin],
  created() {
    var customerId = impersonation.getCustomer().id;
    getSEPs(customerId)
      .then((response) => {
        if (response.data.length !== 0) {
          this.showHintBanner = false;
        } else {
          this.showHintBanner = true;
        }
      })
      .catch(() => {
        this.showHintBanner = true;
      });
    this.$store.dispatch("getOSImages");
    this.$store.dispatch("getCustomersOSImages", customerId);
  },
  data() {
    return {
      search: "",
      showHintBanner: false,
      loading: false,
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    confirmOrder(shopitem) {
      this.$confirm(
        "Do you want to order the " + shopitem.name + " Image. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.orderImage.bind(this, shopitem), this.donothing);
    },
    donothing() {},
    orderImage(shopitem) {
      let payload = {
        shopItem: shopitem,
        customerId: impersonation.getCustomer().id,
      };
      this.loading = true;
      this.$store
        .dispatch("orderImage", payload)
        .then((response) => {
          successMessage(
            "Thanks! We have received your order. Your OS-Image is now available."
          );
          this.$store.commit("addCustomersOSImage", response.data);
        })
        .catch((error) => {
          if (
            error.response.data.includes(
              "The csdp Cloud Entry Point is not set"
            ) ||
            error.response.data.includes(
              "The csdp storage entry point does not exist or has not been created yet"
            )
          ) {
            this.$router.push("bases");
          }
          errorMessage(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapState(["customersOSImages", "buyOSImages"]),
    isSubset() {
      return this.buyOSImages.length !== this.filteredImages.length;
    },
    filteredImages() {
      for (let i = 0; i < this.customersOSImages.length; i++) {
        let temp = this.customersOSImages[i];
        for (let j = 0; j < this.buyOSImages.length; j++) {
          if (this.buyOSImages[j].id === temp.imageId) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.buyOSImages.splice(j, 1);
            break;
          }
        }
      }
      return this.filterEntities(this.buyOSImages, this.search);
    },
  },
};
</script>

<style lang="css" scoped>
.alert-banner {
  border-radius: 20px;
  background-color: #ffdb1f;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  min-height: 25px;
  height: 25px;
}
</style>
