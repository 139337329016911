export function getCustomersSmbStorages (customerId) {
  return window.axiosInstance.get(`/smbStorages/${customerId}`)
}

export function createSmbStorage (payload) {
  return window.axiosInstance.post('/smbStorages', payload)
}

export function updateSmbStorage (payload) {
  return window.axiosInstance.put(`/smbStorages`, payload)
}

export function deleteSmbStorage (storageId) {
  return window.axiosInstance.delete(`/smbStorages/${storageId}`)
}
