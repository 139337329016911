<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Customer Software Streams</h3>
      <el-row type="flex" class="header">
        <el-col :span="6" class="search">
          <el-input
            placeholder="Find Software Stream"
            v-model="search"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon v-if="isSubset" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
          <div class="results" v-if="isSubset">
            <div v-if="!search">
              showing {{ filteredCustomerStreams.length }} of
              {{ customerSoftwareStreams.length }}
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <div class="flex marginBot">
      <el-button v-if="!showEnterpriseOnly" @click="showEnterpriseOnlyHandler"
        >Set Installation Order (Enterprise)</el-button
      >
      <el-button v-else @click="showAllStreams">Show All Streams</el-button>
      <el-button type="primary" @click="goToShop">Add Software</el-button>
      <el-button type="primary" @click="activateLatestSWVersions"
        >Activate latest software version for all streams</el-button
      >
      <el-button
        v-if="multipleSelection.length !== 0"
        type="primary"
        @click="setApplicationOwner"
        >Set Application Owner</el-button
      >
    </div>
    <div class="tableContainer">
      <el-table
        v-if="!showEnterpriseOnly"
        v-loading="loadingCustomersStreams"
        :data="filteredCustomerStreams"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="icon" label="Icon" width="80">
          <template v-slot="scope">
            <img
              v-if="
                scope.row.icon !== null &&
                scope.row.icon.id !== '' &&
                scope.row.icon.name != ''
              "
              :src="scope.row.icon"
              height="40"
              width="40"
            />
            <img
              v-else
              src="../../../static/packages-icon-21.png"
              height="40"
              width="40"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column
          align="center"
          prop="updateSettings"
          label="Update Settings"
        ></el-table-column>
        <el-table-column align="center" label="Latest Active Version">
          <template v-slot="scope">
            <span>{{ getLatestActiveSWVersion(scope.row.id) }}</span>
          </template>
          <!-- Get latest version of all active sw packages in customersStreams -->
        </el-table-column>
        <el-table-column
          prop="isEnterpriseStream"
          label="Enterprise"
          align="center"
        >
          <template v-slot="scope">
            <img
              v-if="scope.row.isEnterpriseStream === true"
              width="20"
              height="20"
              src="../../../static/success.png"
            />
          </template>
        </el-table-column>
        <el-table-column prop="priority" label="Priority Nr">
          <template v-slot="scope">
            <span v-if="scope.row.priority === 0"></span>
            <span v-else>{{ scope.row.priority }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Application Owner" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.applicationOwner !== null">{{
              scope.row.applicationOwner.givenName +
              " " +
              scope.row.applicationOwner.surname
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Deployment Status">
          <template>
            <span>x / {{ clients.length }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="110">
          <template v-slot="scope">
            <actions-dropdown
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              :showCustomerSoftware="true"
              :hideRetreatFromShop="true"
              @showCustomerSoftware="showStreamsSoftware"
              @edit="editCustomerSoftwareStream"
              @delete="confirmDelete"
            ></actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <template v-else>
        <div class="tableContainer">
          <table style="width: 80%; border: 1px solid red">
            <thead>
              <tr>
                <th style="border: 1px solid black">Icon</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <draggable v-model="enterpriseStreams">
              <tr v-for="(stream, index) in enterpriseStreams" :key="index">
                <td>
                  <div style="1px solid red">
                    <img
                      v-if="
                        stream.icon !== null &&
                        stream.icon.id !== '' &&
                        stream.icon.name != ''
                      "
                      :src="stream.icon"
                      height="40"
                      width="40"
                    />
                    <img
                      v-else
                      src="../../../static/packages-icon-21.png"
                      height="40"
                      width="40"
                    />
                  </div>
                </td>
                <td style="width: 50%">
                  {{ stream.name }}
                </td>
                <td style="width: 25%">
                  <img
                    width="40"
                    height="40"
                    src="../../../static/pic_sort-list.png"
                  />
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <el-button type="primary" @click="setPriorities()"
          >Set Priorities</el-button
        >
      </template>
    </div>
    <customer-softwares ref="customerSoftwares"></customer-softwares>
    <edit-customer-stream ref="editCustomerStream"></edit-customer-stream>
    <set-application-owner ref="setApplicationOwner"></set-application-owner>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import CustomerSoftwares from "./CustomerSoftwares.vue";
import EditCustomerStream from "./EditCustomerStream.vue";
import { getWinPESAS } from "../../services/customers";
import {
  activateLatestSWVersions,
  setPriorities,
} from "../../services/customerSoftwares";
import SetApplicationOwner from "./SetApplicationOwner.vue";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "customer-softwares": CustomerSoftwares,
    "edit-customer-stream": EditCustomerStream,
    "set-application-owner": SetApplicationOwner,
    draggable,
  },
  created() {
    let customerId = impersonation.getCustomer().id;
    this.$store.dispatch("getClients");
    this.$store.dispatch("getCustomersStreams", customerId);
    this.$store.dispatch("getCustomersPersons", customerId);
    this.$store.dispatch("getCustomersOSImages", customerId);
  },
  data() {
    return {
      enterpriseStreams: [],
      search: "",
      searchImage: "",
      searchReleasePlans: "",
      searchHardwareModel: "",
      multipleSelection: [],
      showEnterpriseOnly: false,
    };
  },
  methods: {
    setPriorities() {
      setPriorities(impersonation.getCustomer().id, this.enterpriseStreams)
        .then(() => {
          this.$notify.success({
            title: "Success",
            message: "The priority of the enterprise streams was set",
            duration: 3000,
          });
          this.showEnterpriseOnly = false;
          this.$store.dispatch(
            "getCustomersStreams",
            impersonation.getCustomer().id
          );
        })
        .catch((error) => console.log({ error }));
    },
    showEnterpriseOnlyHandler() {
      this.showEnterpriseOnly = true;
    },
    showAllStreams() {
      this.showEnterpriseOnly = false;
    },
    setApplicationOwner() {
      this.$refs.setApplicationOwner.openDialog(this.multipleSelection);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    activateLatestSWVersions() {
      let customerId = impersonation.getCustomer().id;
      activateLatestSWVersions(customerId)
        .then(() => {
          this.$router.go();
        })
        .catch((error) => console.log({ error }));
    },
    async downloadwinpe() {
      var customer = null;
      await getWinPESAS(impersonation.getCustomer().id).then((response) => {
        customer = response.data;
      });
      var fileLink = document.createElement("a");
      fileLink.setAttribute("href", customer.winPEDownloadLink);
      fileLink.click();
    },
    downloadsmartdeploy() {
      this.$store.dispatch("downloadSmartDeploy");
      this.$notify.success({
        title: "Info",
        message: "The download will start shortly...",
        duration: 5000,
      });
    },
    goToShop() {
      this.$router.push({ name: "buy" });
    },
    getLatestActiveSWVersion(swStreamId) {
      var result = "no active version";
      let stream = this.customersStreams.find((el) => {
        return el.id === swStreamId;
      });
      let activeVersions = stream.streamMembers.filter(
        (x) => x.customerStatus === "Active"
      );
      if (activeVersions.length === 1) {
        result = activeVersions[0].version;
      } else {
        let activeVersion = 0;
        for (let i = 0; i < activeVersions.length; i++) {
          if (activeVersion === 0) {
            activeVersion = activeVersions[i].version;
          } else {
            let version1 = parseInt(activeVersion.replaceAll(".", ""));
            let version2 = parseInt(
              activeVersions[i].version.replaceAll(".", "")
            );
            if (version1 < version2) {
              activeVersion = activeVersions[i].version;
            }
          }
        }
        if (activeVersion !== 0) {
          result = activeVersion;
        }
      }
      return result;
    },
    deleteCustomerImage(image) {
      let payload = {
        customerId: impersonation.getCustomer().id,
        imageId: image.id,
      };
      this.$store.dispatch("deleteCustomerOSImage", payload);
    },
    confirmDeleteImage(image) {
      this.$confirm(
        "This will permanently delete the Image. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteCustomerImage.bind(this, image), this.deleteError);
    },
    confirmDelete(customerStream) {
      this.$confirm(
        "This will permanently delete the Stream and its Software Packages. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, customerStream), this.deleteError);
    },
    deleteSuccess(customerStream) {
      let payload = {
        customerId: impersonation.getCustomer().id,
        customerStreamId: customerStream.id,
      };
      this.$store.dispatch("deleteCustomerStream", payload);
      this.showDialog = false;
    },
    deleteError() {},
    editCustomerSoftwareStream(stream) {
      this.$refs.editCustomerStream.openDialog(stream);
    },
    clearSearch() {
      this.search = "";
    },
    clearSearchHardwareModel() {
      this.searchHardwareModel = "";
    },
    showStreamsSoftware(entity) {
      this.$refs.customerSoftwares.openDialog(entity.streamMembers);
    },
    clearSearchImage() {
      this.searchImage = "";
    },
  },
  computed: {
    ...mapState([
      "customersStreams",
      "loadingCustomersStreams",
      "customersOSImages",
      "clients",
    ]),
    customerSoftwareStreams() {
      var result = [];
      for (let i = 0; i < this.customersStreams.length; i++) {
        if (this.customersStreams[i].type === "Software") {
          result.push(this.customersStreams[i]);
        }
      }
      return result;
    },
    filteredCustomerStreams() {
      var temp = this.filterEntities(this.customersStreams, this.search);
      var result = [];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].type === "Software") {
          result.push(temp[i]);
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.enterpriseStreams = _.cloneDeep(
        this.customersStreams.filter((el) => {
          return el.isEnterpriseStream;
        })
      );

      // this.enterpriseStreams.sort((a, b) => {
      //   return a.priority - b.priority
      // })
      return result;
    },
    filteredImages() {
      return this.filterEntities(this.customersOSImages, this.searchImage);
    },
    isSubset() {
      return (
        this.customersStreams.length !== this.filteredCustomerStreams.length
      );
    },
    isSubsetImage() {
      return this.customersOSImages.length !== this.filteredImages.length;
    },
  },
};
</script>

<style lang="css" scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  text-align: left;
  margin-bottom: 20px;
}
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  margin-bottom: 50px;
}

.marginBot {
  margin-bottom: 20px;
}

.tableColumn {
  width: 100px;
  border: 1px solid red;
}
</style>
