<template>
  <el-dialog
    v-model="showDialog"
    title="Edit client"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="client" :rules="rules" label-width="180px">
      <el-form-item label="Client UUID" prop="uuid">
        <el-input v-model="client.uuid" placeholder="Client UUID"></el-input>
      </el-form-item>
      <el-form-item label="Serial Number" prop="serialNumber">
        <el-input
          v-model="client.serialNumber"
          placeholder="Serial Number"
        ></el-input>
      </el-form-item>
      <el-form-item label="4k Hash">
        <el-input v-model="client.hash4K" placeholder="4k Hash"></el-input>
      </el-form-item>
      <el-form-item label="Machine GUID">
        <el-input
          v-model="client.machineGUID"
          placeholder="Machine GUID"
        ></el-input>
      </el-form-item>
      <el-divider></el-divider>
      <div style="margin: 0 auto">
        <span v-if="showErrorMAC" style="color: red"
          >Please add at least one MAC address</span
        >
      </div>
      <br />
      <el-form-item v-for="(mac, index) in macAddresses" :key="index">
        <div style="display: flex; justify-content: space-between">
          <label>{{ "MAC " + (index + 1) }}</label>
          <el-input style="width: 80%" v-model="mac.value"> </el-input>
        </div>
      </el-form-item>
      <div style="margin: 0 auto">
        <el-button type="success" @click="addMAC">Add MAC</el-button>
        <el-button type="warning" @click="removeMAC">Remove last MAC</el-button>
      </div>
      <el-divider></el-divider>
      <el-form-item label="Computer Target Name" prop="name">
        <el-input
          v-model="client.name"
          placeholder="Computer Target Name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          v-model="client.description"
          placeholder="Description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Timezone" prop="timezone">
        <el-select width="80%" v-model="client.timezone">
          <el-option
            v-for="tz in timezones"
            :key="tz.key"
            :label="tz.label"
            :value="tz.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="flex">
        <el-form-item label="Base" prop="baseId">
          <el-select v-model="client.baseId" placeholder="Select the Base">
            <el-option
              v-for="base in bases"
              :key="base.id"
              :label="base.name"
              :value="base.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit">Edit</el-button>
    </template>
  </el-dialog>
</template>
<script>
import _ from "lodash";
import ClientValidation from "./ClientValidation";
import { getBases } from "../../services/bases";
import impersonation from "../../impersonation";
import { getMACAddresses } from "../../services/clients";
// import { mapState } from 'vuex'

export default {
  name: "clients-edit",
  mixins: [ClientValidation],
  data() {
    let validateComputerName = (rule, value, callback) => {
      let regexp = /^[A-Za-z0-9-]*$/;
      this.client.name = this.client.name.trim();
      this.client.name = this.client.name.replace(/\s/g, "");
      if (this.client.name.length > 15 || this.client.name.length < 3) {
        callback(
          "The computer name must have minimal 3 and maximal 15 letters"
        );
      } else {
        let regValidation = this.client.name.match(regexp);
        if (!regValidation) {
          callback(
            "The computer name only has to have numbers and letters in it"
          );
        } else {
          callback();
        }
      }
    };
    return {
      showErrorMAC: false,
      macAddresses: [],
      showDialog: false,
      bases: [],
      client: {
        id: "",
        name: "",
        description: "",
        uuid: "",
        softwares: [],
        domainId: "",
        timezone: "",
        hash4K: "",
        machineGUID: "",
      },
      clientsoftwares: [],
      oldsoftwares: [],
      rules: {
        name: [{ validator: validateComputerName, trigger: "blur" }],
        uuid: [
          { required: true, message: "Please set the UUID", trigger: "blur" },
        ],
        serialNumber: [
          {
            required: true,
            message: "Please set the Serial Number",
            trigger: "blur",
          },
        ],
      },
      timezones: [
        { key: 1, value: "W. Europe Standard Time", label: "Germany" },
        { key: 2, value: "GMT Standard Time", label: "United Kingdom" },
        { key: 3, value: "Central Europe Standard Time", label: "Serbia" },
      ],
    };
  },
  methods: {
    handleClose() {
      this.$refs.form.resetFields();
      this.macAddresses = [];
      this.showDialog = false;
    },
    validateMACAddresses() {
      if (this.macAddresses.length === 0) {
        this.showErrorMAC = true;
        return false;
      } else {
        this.showErrorMAC = false;
        return true;
      }
    },
    addMAC() {
      this.macAddresses.push({ key: this.macAddresses.length, value: "" });
    },
    removeMAC() {
      this.macAddresses.splice(this.macAddresses.length - 1, 1);
    },
    open(client) {
      getMACAddresses(impersonation.getCustomer().id, client.id)
        .then((response) => {
          let data = response.data;
          for (let i = 0; i < data.length; i++) {
            this.macAddresses.push({ key: i, value: data[i] });
          }
        })
        .catch((error) => console.log({ error }));
      getBases(impersonation.getCustomer().id)
        .then((response) => {
          this.bases = response.data;
        })
        .catch((err) => console.error(err));
      this.client = client;
      if (client.baseId != null && client.baseId.length > 0) {
        this.$store.dispatch("getDomains", client.baseId);
      }
      this.showDialog = true;
    },
    buildClientObj(client) {
      console.log({ client });
      let result = {
        id: client.id,
        uuid: "",
        name: "",
        description: "",
        baseId: "",
        subnet: "",
        serialNumber: "",
        macAddresses: [],
        timezone: "",
        machineGUID: "",
        hash4K: "",
      };
      result.uuid = client.uuid;
      result.name = client.name;
      result.description = client.description;
      result.baseId = client.baseId;
      result.subnet = client.subnet;
      result.serialNumber = client.serialNumber;
      result.hash4K = client.hash4K;
      result.machineGUID = client.machineGUID;
      result.timezone = client.timezone;
      for (let i = 0; i < this.macAddresses.length; i++) {
        result.macAddresses.push(this.macAddresses[i].value);
      }
      return result;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        let validMACAddresses = this.validateMACAddresses();
        if (valid && validMACAddresses) {
          const clientObj = this.buildClientObj(_.cloneDeep(this.client));
          console.log({ clientObj });
          this.$store.dispatch("editClient", _.cloneDeep(clientObj));
          this.macAddresses = [];
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    // ...mapState(['clientSoftwares', 'clientSoftwareLoading'])
    domains() {
      return this.$store.state.domains;
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-content: space-around;
}
</style>
