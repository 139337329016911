<template>
  <el-form-item label="Storage Account Type" :prop="prop" placeholder="Select type" :rules="rules">
    <el-select v-model="model" @change="change" filterable placeholder="Select type">
      <el-option v-for="type in storageTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'storage-type-select',
  props: ['value', 'prop'],
  data () {
    return {
      model: this.value,
      rules: [
        { required: true, message: 'Storage type is required', trigger: 'change' }
      ]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    ...mapState(['storageTypes'])
  }
}
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
