<template>
  <el-form-item label="Virtual Machine Name" :prop="prop" :rules="rules.name">
    <el-input v-model="model" @change="change" placeholder="Select Virtual Machine Name" :disabled="disabled"></el-input>
  </el-form-item>
</template>

<script>
  import VirtualMachineValidation from './VirtualMachineValidation'
  import { checkVMName } from '../../services/virtualMachines'
  import impersonation from '../../impersonation'

  export default {
    name: 'virtual-machine-name',
    props: ['value', 'prop', 'disabled', 'baseId'],
    mixins: [
      VirtualMachineValidation
    ],
    data () {
      return {
        model: this.value,
        rules: {
          name: [
            { validator: this.validateVMName, trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      change (value) {
        this.$emit('input', value)
      },
      validateVMName (rule, value, callback) {
        if (this.baseId === '' || this.baseId === undefined) {
          callback(new Error('Please select the base first'))
        } else if (value === '') {
          callback(new Error('VM name is required'))
        } else {
          let customerId = impersonation.getCustomer().id
          checkVMName(customerId, this.baseId, value)
            .then(() => {
              callback()
            })
            .catch(() => {
              callback(new Error('There already exists a VM with the name ' + value + ' in Azure'))
            })
        }
      }
    }
  }
</script>
