<template lang="html">
  <div>
    <add-company-dialog
      ref="addCompanyDialog"
      :renderNewLocationOption="true"></add-company-dialog>
    <el-dialog
      title="Edit Person"
      v-model="showDialog"
      center>
      <el-form :model="formModel" :rules="personRules" ref="editPersonForm">
        <el-form-item label="Title" prop="title">
          <el-input
            placeholder="Insert Title"
            v-model="formModel.title" />
        </el-form-item>
        <el-form-item label="Given Name" prop="givenName">
          <el-input
            placeholder="Insert Given Name"
            v-model="formModel.givenName" />
        </el-form-item>
        <el-form-item label="Middle Name" prop="middleName">
          <el-input
            placeholder="Insert Middle Name"
            v-model="formModel.middleName" />
        </el-form-item>
        <el-form-item label="Surname" prop="surname">
          <el-input
            placeholder="Insert Surname"
            v-model="formModel.surname" />
        </el-form-item>
        <el-form-item label="Academic Degree" prop="academicDegree">
          <el-input
            placeholder="Insert Academic Degree"
            v-model="formModel.academicDegree" />
        </el-form-item>
        <el-form-item label="Employee Type" prop="employeeType">
          <el-select v-model="formModel.employeeType" placeholder="Select Type">
            <el-option
              v-for="type in typeOptions"
              :key="type.value"
              :label="type.label"
              :value="type.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Domain" prop="domain">
          <el-input
            placeholder="Insert Domain"
            v-model="formModel.domain" />
        </el-form-item>
        <el-form-item label="Departement" prop="departementName">
          <el-input
            placeholder="Insert Departement Name"
            v-model="formModel.departementName" />
        </el-form-item>
        <el-form-item label="Departement (short)" prop="departementShort">
          <el-input
            placeholder="Insert Abbreviation of Departement"
            v-model="formModel.departementShort" />
        </el-form-item>
        <el-form-item label="Cost Center" prop="costCenter">
          <el-input
            placeholder="Insert Cost Center"
            v-model="formModel.costCenter" />
        </el-form-item>
        <el-form-item label="Room Number" prop="roomNr">
          <el-input
            placeholder="Insert Room Number"
            v-model="formModel.roomNr" />
        </el-form-item>
        <el-form-item label="Phone Number" prop="phoneNr">
          <el-input
            placeholder="Insert Phone Number"
            v-model="formModel.phoneNr" />
        </el-form-item>
        <el-form-item label="Fax" prop="faxNumber">
          <el-input
            placeholder="Insert Fax Number"
            v-model="formModel.faxNr" />
        </el-form-item>
        <el-form-item label="Mobile Phone Number" prop="mobileNr">
          <el-input
            placeholder="Insert Mobil Phone Number"
            v-model="formModel.mobileNr" />
        </el-form-item>
        <el-form-item label="Email" prop="emailPrimary">
          <el-input
            placeholder="Insert Email"
            v-model="formModel.emailPrimary" />
        </el-form-item>
        <el-form-item label="Email (optional)" prop="emailOptional">
          <el-input
            placeholder="Insert Optional Email"
            v-model="formModel.emailOptional" />
        </el-form-item>
        <el-form-item label="Employee Number" prop="employeeNr">
          <el-input
            placeholder="Insert Employee Number"
            v-model="formModel.employeeNr" />
        </el-form-item>
        <el-form-item label="State" prop="state">
          <el-select v-model="formModel.state" placeholder="Select State">
            <el-option
              v-for="state in stateOptions"
              :key="state.value"
              :label="state.label"
              :value="state.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Company" prop="companyId">
          <el-select
            v-model="formModel.companyId"
            placeholder="Select Company"
            @change="openCreateCompanyDialog()">
            <el-option
              key="new-company"
              value="new-company"
              label="+ New Company">
            </el-option>
            <el-option
              v-for="company in companies"
              :key="company.id"
              :label="company.corporateName"
              :value="company.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="editPerson()">Edit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import AddCompanyDialog from "./AddCompanyDialog";
import AddPersonValidationRules from "../AddPersonValidationRules";
import _ from "lodash";

export default {
  name: "edit-person-dialog",
  components: {
    AddCompanyDialog,
  },
  mixins: [AddPersonValidationRules],
  data() {
    return {
      showDialog: false,
      formModel: {
        title: "",
        givenName: "",
        middleName: "",
        surname: "",
        academicDegree: "",
        employeeType: "",
        domain: "",
        departementShort: "",
        departementName: "",
        costCenter: "",
        roomNr: "",
        phoneNr: "",
        faxNr: "",
        mobileNr: "",
        emailPrimary: "",
        emailOptional: "",
        employeeNr: "",
        state: "",
        companyId: "",
      },
      passedCompId: "",
      typeOptions: [
        {
          value: "internal",
          label: "Internal Employee",
        },
        {
          value: "external",
          label: "External Employee",
        },
      ],
      stateOptions: [
        {
          value: "new",
          label: "Created",
        },
        {
          value: "enabled",
          label: "Enabled",
        },
        {
          value: "disabled",
          label: "Disabled",
        },
        {
          value: "recycled",
          label: "Recycled",
        },
      ],
    };
  },
  methods: {
    openDialog(person) {
      this.fillData(person);
      this.showDialog = true;
    },
    openCreateCompanyDialog() {
      if (this.formModel.companyId === "new-company") {
        this.$refs.addCompanyDialog.openDialog();
        this.formModel.companyId = this.passedCompId;
      }
    },
    editPerson() {
      this.$refs.editPersonForm.validate((valid) => {
        if (valid) {
          // Send the API request
          this.$store
            .dispatch("updatePerson", _.cloneDeep(this.formModel))
            .then((response) => {
              this.$store.commit("editPerson", response.data);
              this.$refs.editPersonForm.resetFields();
              this.showDialog = false;
            });
        }
      });
    },
    fillData(personData) {
      this.passedCompId = personData.companyId;
      this.formModel.id = personData.id;
      this.formModel.companyId = personData.companyId;
      this.formModel.academicDegree = personData.academicDegree;
      this.formModel.costCenter = personData.costCenter;
      this.formModel.departementName = personData.departementName;
      this.formModel.departementShort = personData.departementShort;
      this.formModel.domain = personData.domain;
      this.formModel.emailOptional = personData.emailOptional;
      this.formModel.emailPrimary = personData.emailPrimary;
      this.formModel.employeeNr = personData.employeeNr;
      this.formModel.employeeType = personData.employeeType;
      this.formModel.faxNr = personData.faxNr;
      this.formModel.givenName = personData.givenName;
      this.formModel.middleName = personData.middleName;
      this.formModel.mobileNr = personData.mobileNr;
      this.formModel.phoneNr = personData.phoneNr;
      this.formModel.roomNr = personData.roomNr;
      this.formModel.state = personData.state;
      this.formModel.surname = personData.surname;
      this.formModel.title = personData.title;
    },
  },
  computed: {
    companies: {
      get() {
        return this.$store.state.customersCompanies;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
</style>
