<template>
  <el-dialog
    v-model="showDialog"
    title="Revision Message"
    width="50%"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="container">
      <el-form ref="form" :model="model" :rules="rules">
        <el-form-item prop="value">
          <textarea class="textarea" v-model="model.value"> </textarea>
        </el-form-item>
        <el-button type="primary" @click="submit">OK</el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { addRevisionNumber } from "../../services/softwares";
// import { success, errorMessage } from '../../store/modules/util'

export default {
  data() {
    return {
      showDialog: false,
      model: {
        entityId: "",
        value: "",
        type: "",
      },
      rules: {
        // value: [{required: true, message: 'Please enter a changelog message for the revision', trigger: 'blur'}]
      },
    };
  },
  methods: {
    open(entityId, type) {
      console.log({ entityId }, { type });
      this.showDialog = true;
      this.model.entityId = entityId;
      this.model.type = type;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.model.value !== "") {
            if (this.model.type === "software") {
              addRevisionNumber(this.model)
                .then((response) => console.log(response.data))
                .catch((error) => console.log({ error }));
              // eslint-disable-next-line no-empty
            } else if (this.model.type === "image") {
            }
          }
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.textarea {
  width: 250px;
  height: 200px;
  margin-top: 20px;
  width: 95%;
  overflow: hidden;
  resize: none;
}

.container {
  text-align: center;
}
</style>
