<template>
  <div>
    <el-dialog
      v-model="showDialog"
      :close-on-click-modal="false"
      title="Add Cloud Entry Point"
    >
      <div style="margin: 10px auto; text-align: center">
        <el-select v-model="selectedCEP">
          <el-option
            v-for="cep in ceps"
            :key="cep.key"
            :value="cep.value"
            :label="cep.value"
            :disabled="cep.value !== 'Azure' ? true : false"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin: 0 auto; text-align: center">
        <el-button type="primary" @click="submit">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addCEP",
  data() {
    return {
      showDialog: false,
      selectedCEP: "",
      ceps: [
        { key: 1, value: "Azure" },
        { key: 2, value: "AWS" },
        { key: 3, value: "Google Cloud" },
        { key: 4, value: "IBM Cloud" },
        { key: 5, value: "GAIA-X" },
      ],
    };
  },
  methods: {
    open() {
      this.$store.dispatch("getSubscriptions");
      this.showDialog = true;
    },
    submit() {
      if (this.selectedCEP === "Azure") {
        this.$emit("openAzure");
        this.selectedCEP = "";
        this.showDialog = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
