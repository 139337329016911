<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find shop item"
              v-model="search"
              autofocus
              @input="setShop"
            >
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
            <div class="results" v-if="isSubset">
              <div v-if="!searchVar">
                showing {{ filteredShopItems.length }} of {{ shopitems.length }}
              </div>
            </div>
          </el-col>
          <el-col :span="8" class="search" style="margin-left: 10px">
            <el-input
              placeholder="Find shop item by category"
              v-model="searchcategory"
              autofocus
              @input="setCategory"
            >
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearchCategory"
              ></i> -->
            </el-input>
            <div class="results" v-if="isSubset">
              <div v-if="searchVar">
                showing {{ filteredShopItems.length }} of {{ shopitems.length }}
              </div>
            </div>
          </el-col>
          <el-col class="bs-align-right">
            <!-- icon="el-icon-plus" -->
            <el-button type="primary" @click="openShopItemAdd"
              >Add item</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="filteredShopItems" highlight-current-row>
          <el-table-column type="expand">
            <template v-slot="props">
              <h4>Files</h4>
              <p v-for="file in props.row.images" :key="file.id">
                <el-tag type="gray">
                  <!-- <i class="el-icon-document"></i>  -->
                  {{ file.name }}</el-tag
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Name" sortable width="200">
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
          </el-table-column>
          <el-table-column prop="price" label="Price" sortable width="150">
          </el-table-column>
          <el-table-column
            prop="managedServicePrice"
            label="Managed Service"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="managedServiceLifecyclePrice"
            label="Managed Service (Lifecycle)"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="categories" label="Categories">
            <template v-slot="scope">
              <div
                v-for="index in scope.row.categories"
                :key="index.id"
                style="display: inline; margin-left: 5px"
              >
                <el-tag size="small">{{ index.name }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template v-slot="scope">
              <actions-dropdown
                :hideRetreatFromShop="true"
                :entity="scope.row"
                :edit="true"
                :deleteItem="true"
                @edit="openShopItemEdit"
                @delete="confirmDelete"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <item-add ref="itemAdd"></item-add>
    <item-edit ref="itemEdit"></item-edit>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import FilterMixinCategory from "../../mixins/FilterMixinCategory";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import ItemAdd from "./ItemAdd.vue";
import ItemEdit from "./ItemEdit.vue";

export default {
  name: "shop-items",
  mixins: [FilterMixin, FilterMixinCategory],
  created() {
    this.$store.dispatch("getShopItems");
  },
  components: {
    ActionsDropdown,
    ItemAdd,
    ItemEdit,
  },
  data() {
    return {
      searchVar: false,
    };
  },
  methods: {
    fixPricesDisplayed() {
      this.shopitems.forEach((el) => {
        var priceSplitted = el.price.split(",");
        var mngedPriceSplitted = el.managedServicePrice.split(",");
        var mngedLifecyclePriceSplitted =
          el.managedServiceLifecyclePrice.split(",");
        if (priceSplitted.length === 1) {
          el.price = el.price + ",00";
        } else if (priceSplitted[1].length === 1) {
          el.price = el.price + "0";
        }
        if (mngedPriceSplitted.length === 1) {
          el.managedServicePrice = el.managedServicePrice + ",00";
        } else if (mngedPriceSplitted[1].length === 1) {
          el.managedServicePrice = el.managedServicePrice + "0";
        }
        if (mngedLifecyclePriceSplitted.length === 1) {
          el.managedServiceLifecyclePrice =
            el.managedServiceLifecyclePrice + ",00";
        } else if (mngedLifecyclePriceSplitted[1].length === 1) {
          el.managedServiceLifecyclePrice =
            el.managedServiceLifecyclePrice + "0";
        }
      });
      return this.shopitems;
    },
    openShopItemAdd() {
      this.$refs.itemAdd.open();
    },
    openShopItemEdit(item) {
      this.$refs.itemEdit.open(_.cloneDeep(item));
    },
    confirmDelete(item) {
      this.$confirm(
        "This will permanently delete the item. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, item), this.deleteError);
    },
    deleteSuccess(item) {
      this.$store.dispatch("deleteShopItem", item);
    },
    deleteError() {},
    setCategory() {
      this.searchVar = true;
    },
    setShop() {
      this.searchVar = false;
    },
  },
  computed: {
    ...mapState(["shopitems", "shopLoading"]),
    filteredShopItems() {
      if (!this.searchVar) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.shopItems = this.fixPricesDisplayed();
        return this.filterEntities(this.shopitems, this.search);
      } else {
        return this.filterCategory(this.shopitems, this.searchcategory);
      }
    },
    isSubset() {
      return this.shopitems.length !== this.filteredShopItems.length;
    },
  },
};
</script>
