<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Device Properties</h3>
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input
            placeholder="Find device property"
            v-model="search"
            autofocus
          >
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubset"
              @click="clearSearch"
            ></i> -->
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredDeviceProperties.length }} of
            {{ deviceProperties.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button type="primary" @click="openDevicePropertyAdd"
            >Add Device Property</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table
        :data="filteredDeviceProperties"
        highlight-current-row
        v-loading.body="devicePropertiesLoading"
      >
        <el-table-column prop="propertyName" label="Property Name" sortable>
        </el-table-column>
        <el-table-column prop="parameterName" label="Parameter Name" sortable>
        </el-table-column>
        <el-table-column prop="command" label="Command"> </el-table-column>
        <el-table-column prop="category" label="Category">
          <template v-slot="scope">
            <el-tag size="small" v-if="scope.row.category">
              {{ scope.row.category.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <!-- @delete="confirmDelete" -->
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              @edit="openDevicePropertyEdit(scope.row)"
              :delete="true"
            ></actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <device-property-add ref="devicePropertyAdd"></device-property-add>
    <device-property-edit ref="devicePropertyEdit"></device-property-edit>
  </div>
</template>

<script>
import DevicePropertyAdd from "../deviceproperties/DevicePropertiesAdd";
import DevicePropertyEdit from "../deviceproperties/DevicePropertiesEdit";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import _ from "lodash";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    DevicePropertyAdd,
    DevicePropertyEdit,
  },
  created() {
    this.$store.dispatch("getDeviceProperties");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    openDevicePropertyAdd() {
      this.$refs.devicePropertyAdd.open();
    },
    openDevicePropertyEdit(deviceProperty) {
      this.$refs.devicePropertyEdit.open(_.cloneDeep(deviceProperty));
    },
  },
  computed: {
    ...mapState(["deviceProperties", "devicePropertiesLoading"]),
    filteredDeviceProperties() {
      return this.filterDeviceProperties(this.deviceProperties, this.search);
    },
    isSubset() {
      return (
        this.deviceProperties.length !== this.filteredDeviceProperties.length
      );
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  padding-bottom: 50px;
}
</style>
