<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find system house"
              v-model="search"
              autofocus
            >
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredSystemhouses.length }} of
              {{ systemhouses.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <!-- icon="el-icon-plus" -->
            <el-button type="primary" @click="openSystemhouseAdd"
              >Add system house</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="filteredSystemhouses"
          highlight-current-row
          v-loading.body="systemhousesLoading"
        >
          <el-table-column prop="name" label="Name" sortable> </el-table-column>
          <el-table-column label="Customers" width="170">
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'customers',
                  query: { systemhouse: scope.row.id },
                }"
              >
                <el-button type="text" size="small"> customers </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Users" width="150">
            <template v-slot="scope">
              <router-link
                :to="{ name: 'users', query: { systemhouse: scope.row.id } }"
              >
                <el-button type="text" size="small">users</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="110">
            <template v-slot="scope">
              <actions-dropdown
                :entity="scope.row"
                :edit="true"
                :deleteItem="true"
                :hideRetreatFromShop="true"
                @edit="openSystemhouseEdit"
                @delete="confirmDelete"
                v-if="scope.row.name !== 'Bitstream Systems'"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <systemhouse-add ref="systemhouseAdd"></systemhouse-add>
    <systemhouse-edit ref="systemhouseEdit"></systemhouse-edit>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import SystemhouseAdd from "./SystemhouseAdd.vue";
import SystemhouseEdit from "./SystemhouseEdit.vue";

export default {
  name: "bs-systemhouses",
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    SystemhouseAdd,
    SystemhouseEdit,
  },
  methods: {
    openSystemhouseAdd() {
      this.$refs.systemhouseAdd.open();
    },
    openSystemhouseEdit(systemhouse) {
      this.$refs.systemhouseEdit.open(_.cloneDeep(systemhouse));
    },
    confirmDelete(systemhouse) {
      this.$confirm(
        "This will permanently delete the systemhouse. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, systemhouse), this.deleteError);
    },
    deleteSuccess(systemhouse) {
      this.$store.dispatch("deleteSystemhouse", systemhouse);
    },
    deleteError() {},
  },
  computed: {
    ...mapState(["systemhouses", "systemhousesLoading"]),
    filteredSystemhouses() {
      return this.filterEntities(this.systemhouses, this.search);
    },
    isSubset() {
      return this.systemhouses.length !== this.filteredSystemhouses.length;
    },
  },
};
</script>
