export function getOSModels () {
  return window.axiosInstance.get('osModels')
}

export function addOSModel (osModel) {
  return window.axiosInstance.post('osModels', osModel)
}

export function editOSModel (osModel) {
  return window.axiosInstance.put('osModel', osModel)
}

export function deleteOSModel (osModelId) {
  return window.axiosInstance.delete(`osModels/${osModelId}`)
}
