<template>
  <div class="container">
    <add-person-dialog
      ref="addPersonDialog"
      showCategorySelection="true"
    ></add-person-dialog>
    <edit-person-dialog ref="editPersonDialog"></edit-person-dialog>
    <el-col :span="22">
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input
            placeholder="Find persons"
            v-model="search"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon v-if="isSubset" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredPersons.length }} of {{ persons.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <el-button
            type="primary"
            @click="openAddPersonDialog"
            class="top-right"
          >
            <el-icon>
              <Plus />
            </el-icon>
            Add Person
          </el-button>
        </el-col>
      </el-row>
    </el-col>
    <div class="table-container">
      <el-table
        :data="filteredPersons"
        row-class-name="clickable"
        style="width: 100%"
      >
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Type" prop="type"></el-table-column>
        <el-table-column
          label="Cost Center"
          prop="costCenter"
        ></el-table-column>
        <el-table-column
          label="Phone Numbers"
          prop="contactNrs"
        ></el-table-column>
        <el-table-column
          label="Email Addresses"
          prop="emails"
        ></el-table-column>
        <el-table-column label="Company" prop="companyName"></el-table-column>
        <el-table-column label="Actions">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :deleteItem="true"
              :edit="true"
              @edit="openEditPersonDialog"
              @delete="confirmDelete"
            ></actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import AddPersonValidationRules from "./AddPersonValidationRules";
// import impersonation from '../../../impersonation'
import AddPersonDialog from "./dialogs/AddPersonDialog";
import FilterMixin from "../../../mixins/FilterMixin";
import ActionsDropdown from "../../shared/ActionsDropdown";
import EditPersonDialog from "./dialogs/EditPersonDialog";

export default {
  name: "persons-settings",
  mixins: [AddPersonValidationRules, FilterMixin],
  props: ["customerId"],
  components: {
    AddPersonDialog,
    ActionsDropdown,
    EditPersonDialog,
  },
  data() {
    return {
      search: "",
    };
  },
  created() {
    this.getCompanies();
  },
  methods: {
    openAddPersonDialog() {
      this.$refs.addPersonDialog.openDialog();
    },
    getCompanies() {
      this.$store.dispatch("getCustomersCompanies", this.customerId);
    },
    openEditPersonDialog(person) {
      let personToEdit = this.getPersonToEdit(person.id);
      if (personToEdit !== null) {
        this.$refs.editPersonDialog.openDialog(personToEdit);
      } else {
        console.error("The person to edit does not exist");
      }
    },
    createPersonsData(personsArray) {
      let result = [];
      for (let i = 0; i < personsArray.length; i++) {
        let personEdited = {
          id: personsArray[i].id,
          name:
            personsArray[i].givenName +
            " " +
            personsArray[i].middleName +
            " " +
            personsArray[i].surname,
          type: personsArray[i].employeeType,
          costCenter: personsArray[i].costCenter,
          phoneNr: personsArray[i].phoneNr,
          contactNrs:
            "Phone: " +
            personsArray[i].phoneNr +
            "| Mobile Phone: " +
            personsArray[i].mobileNr,
          emails:
            "Primary: " +
            personsArray[i].emailPrimary +
            "| Optional: " +
            personsArray[i].emailOptional,
          companyName: personsArray[i].company
            ? personsArray[i].company.corporateName
            : "//",
        };
        result.push(personEdited);
      }
      return result;
    },
    confirmDelete(person) {
      this.$confirm(
        "This will permanently delete the person. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, person));
    },
    deleteSuccess(person) {
      this.$store.dispatch("deletePerson", person.id);
    },
    getPersonToEdit(id) {
      let possiblePersons = this.$store.state.persons;
      for (let i = 0; i < possiblePersons.length; i++) {
        if (possiblePersons[i].id === id) {
          return possiblePersons[i];
        }
      }
      return null;
    },
    updateComponent() {
      // Rerendering the page to get updated persons
      // this.rerender = !this.rerender
      // this.$forceUpdate()
      // this.rerender = false
    },
  },
  computed: {
    filteredPersons() {
      return this.filterEntities(
        this.createPersonsData(this.persons),
        this.search
      );
    },
    isSubset() {
      return this.persons.length !== this.filteredPersons.length;
    },
    persons: {
      get() {
        return this.$store.state.persons;
      },
      set() {
        // Fo nothing
      },
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  padding-top: 20px;
}
.space-left {
  margin-left: 20px;
}
.flex {
  display: flex;
  justify-content: center;
}
.fullWidthCentered {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}
</style>
