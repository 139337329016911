<template>
  <el-dialog v-model="showDialog" title="Add Device Option">
    <el-form
      :model="deviceOption"
      :rules="rules"
      label-width="180px"
      ref="form"
    >
      <el-form-item label="Name" prop="name">
        <el-input
          placeholder="Device Option name"
          v-model="deviceOption.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          placeholder="Device Option description"
          v-model="deviceOption.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Os Type" prop="osType">
        <el-select v-model="deviceOption.osType">
          <el-option
            v-for="os in osTypes"
            :label="os.value"
            :key="os.key"
            :value="os.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="BitStream only script" prop="bitstreamScript">
        <el-switch
          v-model="deviceOption.bitstreamScript"
          :active-value="true"
          :inactive-value="false"
          active-text="true"
          inactive-text="false"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="PE only" prop="peOnly">
        <el-checkbox v-model="deviceOption.peOnly"></el-checkbox>
      </el-form-item>
      <el-form-item label="Script" prop="content">
        <div class="editor-container">
          <prism-editor
            class="my-editor height-300"
            v-model="deviceOption.content"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
      </el-form-item>
      <br />
      <el-form-item label="Files" prop="files">
        <upload-files
          :action="uploadUrl"
          :file-list="files"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        ></upload-files>
      </el-form-item>
      <div>
        <el-button @click="submit(deviceOption)" type="primary">Add</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
// Editor integration
import { PrismEditor } from "vue-prism-editor";

import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-powershell";

// import highlighting library (you can use any library you want just return html string)
// import { highlight, languages } from "prismjs/components/prism-core";

// import powershell syntax highlighting
// import "prismjs/components/prism-powershell";

import _ from "lodash";
// import { Drop } from "vue-drag-drop";
import DeviceOptionValidation from "./DeviceOptionValidation";
import UploadFiles from "../shared/UploadFiles";

export default {
  name: "device-option-add",
  components: { UploadFiles, PrismEditor },
  mixins: [DeviceOptionValidation, UploadFiles],
  data() {
    let validateContent = (rule, value, callback) => {
      let content = this.deviceOption.content;
      console.log({ content });
      if (content === "# Powershell Script" || content === "") {
        callback(new Error("Please add a script"));
      } else {
        callback();
      }
    };
    return {
      osTypes: [
        { key: 1, value: "Windows" },
        { key: 2, value: "Ubuntu" },
        { key: 3, value: "RedHat" },
      ],
      showDialog: false,
      uploadUrl: window.APPLICATION_SETTING_API_ENDPOINT_URL + "/scripts/temp",
      deviceOption: {
        name: "",
        description: "",
        bitstreamScript: true,
        peOnly: false,
        content: "",
        attachments: [],
        osType: "",
      },
      files: [],
      editor: "",
      rules: {
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        peOnly: [{ required: true, trigger: "change" }],
        osType: [
          {
            required: true,
            message: "Please select the OS type",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.js, "powershell");
    },
    open() {
      let vm = this;
      vm.deviceOption.content = "# Powershell Script";
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch(
            "addDeviceOption",
            _.cloneDeep(this.deviceOption)
          );
          this.showDialog = false;
          this.$refs.form.resetFields();
        } else {
          console.log("DEVICE OPTION INVALID!");
        }
      });
    },
    handleDrop(data, e) {
      let vm = this;
      e.preventDefault();
      let reader = new window.FileReader();
      let files = e.target.files || e.dataTransfer.files;
      reader.onload = function () {
        vm.deviceOption.content = reader.result;
      };
      reader.readAsText(files.item(0));
    },
    setFiles(fileList) {
      this.deviceOption.attachments = fileList.map(function (file) {
        return file.response;
      });
    },
    handleRemove(a, fileList) {
      this.setFiles(fileList);
    },
    handleSuccess(a, b, fileList) {
      this.setFiles(fileList);
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.editor-container {
  width: 50vh;
  height: 50vh;
  border: 1px solid blue;
}
</style>
