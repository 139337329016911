<template>
  <div>
    <base-add-status ref="baseAddStatus"></base-add-status>
    <el-dialog
      v-model="showDialog"
      fullscreen
      title="Create new base"
      custom-class="step-dialog"
    >
      <el-row>
        <el-col :offset="6" :span="12">
          <div class="steps">
            <el-steps :active="step" finish-status="success">
              <el-step title="General"></el-step>
              <el-step title="Network"></el-step>
              <el-step title="VPN"></el-step>
              <!--el-step title="Virtual Machine"></el-step-->
            </el-steps>
          </div>

          <!-- Step 1: General -->
          <el-form
            ref="form0"
            :model="model"
            label-position="top"
            v-if="step === 0"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Base name"
                  placeholder="Base name"
                  prop="name"
                  :rules="rules.name"
                >
                  <el-input v-model="model.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <subscription-select
                  ref="subscriptionSelect"
                  v-model="model.subscriptionId"
                ></subscription-select>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <resource-group-name
                  ref="resourceGroupName"
                  v-model="model.resourceGroup.name"
                  prop="resourceGroup.name"
                  :subscriptionId="model.subscriptionId"
                ></resource-group-name>
              </el-col>
              <el-col :span="12">
                <location-select
                  v-model="model.resourceGroup.location"
                  prop="resourceGroup.location"
                ></location-select>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <storage-account-name
                  ref="storageAccountSelect"
                  v-model="model.storageAccount.name"
                  prop="storageAccount.name"
                ></storage-account-name>
              </el-col>
              <el-col :span="12">
                <storage-type-select
                  v-model="model.storageAccount.type"
                  prop="storageAccount.type"
                ></storage-type-select>
              </el-col>
            </el-row>
          </el-form>

          <!-- Step 2: Network-->
          <el-form
            ref="form1"
            :model="model"
            label-position="top"
            v-if="step === 1"
          >
            <div class="network">
              <virtual-network-name
                v-model="model.virtualNetwork.name"
                prop="virtualNetwork.name"
                class="network-name"
              ></virtual-network-name>
              <el-form-item prop="addressRange" label="Address Range">
                <ip-range
                  v-model="model.virtualNetwork.addressRange"
                  class="network-range"
                ></ip-range>
              </el-form-item>
              <div class="subnet-add" style="visibility: hidden">
                <el-button type="success" @click="addSubnet">
                  <el-icon><Plus /></el-icon>
                </el-button>
              </div>
            </div>
            <el-form-item prop="subnets" label="Subnets">
              <div prop="subnets" label="Subnets">
                <div
                  v-for="(subnet, index) in model.virtualNetwork.subnets"
                  :key="index"
                  class="subnet"
                >
                  <!-- :disabled="index === 1" -->
                  <el-input
                    :readonly="index === 2"
                    v-model="subnet.name"
                    placeholder="Subnet name"
                    class="network-name"
                  ></el-input>
                  <ip-range
                    v-model="subnet.addressRange"
                    class="subnet-ip"
                  ></ip-range>
                  <el-button
                    :disabled="index < 2"
                    :plain="true"
                    type="danger"
                    @click="removeSubnet(subnet)"
                    class="subnet-action"
                  >
                    <el-icon>
                      <Close />
                    </el-icon>
                  </el-button>
                </div>
                <div class="subnet-add">
                  <el-button type="success" @click="addSubnet">
                    <el-icon> <Plus /> </el-icon>
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>

          <!-- Step 3: VPN-->
          <el-form
            ref="form2"
            :model="model"
            label-position="top"
            v-if="step === 2"
          >
            <el-row :gutter="20">
              <el-col :sm="24" :md="12">
                <vpn-name v-model="model.vpn.name" prop="vpn.name"></vpn-name>
              </el-col>
              <el-col :sm="24" :md="12">
                <el-form-item
                  label="Local Address Range"
                  class="addressRange"
                  required
                >
                  <ip-range v-model="model.vpn.local.addressRange"></ip-range>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :md="12">
                <el-form-item
                  label="Local Public IP"
                  class="addressRange"
                  required
                >
                  <ip v-model="model.vpn.local.publicIp"></ip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>

      <template v-slot:footer>
        <div class="steps-footer">
          <el-button type="primary" @click="previous" :disabled="step < 1">
            <el-icon><DArrowLeft /></el-icon>
          </el-button>
          <el-button type="primary" @click="next" v-if="step < 2">
            Next
            <el-icon><DArrowRight /></el-icon>
          </el-button>
          <el-button type="success" @click="submit(model)" v-if="step === 2">
            Submit
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import BaseAddStatus from "./BaseAddStatus.vue";
import SubscriptionSelect from "../subscriptions/SubscriptionSelect.vue";
import ResourceGroupName from "../resourceGroups/ResourceGroupName.vue";
import LocationSelect from "../shared/LocationSelect.vue";
import StorageAccountName from "../storageAccounts/StorageAccountName.vue";
import StorageTypeSelect from "../storageAccounts/StorageTypeSelect.vue";
import VirtualNetworkName from "../virtualNetworks/VirtualNetworkName";
import Ip from "../shared/Ip.vue";
import IpRange from "../shared/IpRange.vue";
import VpnName from "../vpns/VpnName";
// import getUserIP from '../shared/PublicIp'
import JQuery from "jquery";

export default {
  name: "base-add",
  components: {
    SubscriptionSelect,
    ResourceGroupName,
    LocationSelect,
    StorageAccountName,
    StorageTypeSelect,
    Ip,
    IpRange,
    VirtualNetworkName,
    VpnName,
    BaseAddStatus,
    /* VirtualMachineName,
      ServerTypeSelect,
      OsSelect,
      DiskSize,
      DiskName,
      UserName */
  },
  beforeCreate() {
    // let vm = this
    // getUserIP(function (publicip) {
    //   if (publicip) {
    //     vm.model.vpn.local.publicIp = publicip
    //   }
    // })
  },
  data() {
    /* let validatePass = (rule, value, callback) => {
        let username = this.model.virtualMachine.admin.username
        let regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.=_+<>#-:,;])[A-Za-z\d@$!%*?&.=_+<>#-:,;]{8,}$/
        let symbolexp = /[[\]{}()/\\]/
        if (value === '') {
          callback(new Error('Please input the password'))
        }
        if (value.length < 8) {
          callback(new Error('Password must be minimum 8 characters long'))
        }
        if (username !== '' && value !== '') {
          if (value.includes(username)) {
            callback(new Error('Password must not contain username'))
          }
        }
        if (value.match(symbolexp)) {
          callback(new Error('[ ] { } (  ) / \\ characters are not allowed in the password.'))
        }
        if (!value.match(regexp)) {
          callback(new Error('Password must have a digit, uppercase and lowercase letter and one of these characters @$!%*?&.=_+<>#-:,;'))
        } else {
          if (this.model.virtualMachine.admin.confirmPwd !== '') {
            this.$refs.form3.validateField('virtualMachine.admin.confirmPwd')
          }
          callback()
        }
      }
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password again'))
        } else if (value !== this.model.virtualMachine.admin.password) {
          callback(new Error('Passwords do not match'))
        } else {
          callback()
        }
      } */
    return {
      showDialog: false,
      step: 0,
      ip: "",
      model: {
        name: "",
        subscriptionId: "",
        resourceGroup: {
          name: "",
          location: "",
        },
        storageAccount: {
          name: "",
          type: "",
        },
        virtualNetwork: {
          name: "yournetwork",
          addressRange: "10.10.0.0/16",
          subnets: [
            { name: "frontend", addressRange: "10.10.1.0/24" },
            { name: "gatewaysubnet", addressRange: "10.10.2.0/24" },
          ],
        },
        vpn: {
          name: "",
          local: {
            addressRange: "",
            publicIP: "",
          },
        },
      },
      rules: {
        // password: [{validator: validatePass, required: true, trigger: ['blur', 'change']}],
        // confirmPwd: [{validator: validatePass2, required: true, trigger: ['blur', 'change']}],
        name: [
          {
            required: true,
            message: "Please input base name.",
            trigger: "blur",
          },
        ],
      },
      // overrideLabel: 'Confirm Password'
    };
  },
  methods: {
    open() {
      this.step = 0;
      this.model = {
        name: "",
        subscriptionId: "",
        resourceGroup: {
          name: "",
          location: "",
        },
        storageAccount: {
          name: "",
          type: "",
        },
        virtualNetwork: {
          name: "yournetwork",
          addressRange: "10.10.0.0/16",
          subnets: [
            { name: "frontend", addressRange: "10.10.1.0/24" },
            { name: "gatewaysubnet", addressRange: "10.10.2.0/24" },
          ],
        },
        vpn: {
          name: "",
          local: {
            addressRange: "",
            publicIP: "",
          },
        },
      };
      this.showDialog = true;
    },
    next() {
      this.$refs["form" + this.step].validate((valid) => {
        if (valid) {
          this.step += 1;
        }
      });
    },
    previous() {
      this.step -= 1;
    },
    addSubnet() {
      this.model.virtualNetwork.subnets.push({ name: "", addressRange: "" });
    },
    removeSubnet(subnet) {
      const id = _.findIndex(this.model.virtualNetwork.subnets, subnet);
      this.model.virtualNetwork.subnets.splice(id, 1);
    },
    submit(base) {
      this.$refs["form" + this.step].validate((valid) => {
        if (valid) {
          this.showDialog = false;
          this.$refs.baseAddStatus.openDialog(_.cloneDeep(base));
        }
      });
    },
    getLocalIp() {
      let $ = JQuery;
      let thisComponent = this;
      $.getJSON("http://jsonip.com/?callback=?", function (data) {
        thisComponent.model.vpn.local.publicIp = data.ip;
      });
    },
  },
  computed: {
    ...mapState(["images"]),
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 25px;
}

.subnet {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.network {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.network-name {
  min-width: 220px;
  flex-grow: 1;
}

.subnet-ip,
.network-range {
  margin: 0 14px;
  min-width: 220px;
}

.subnet-action {
  border: none;
}

.subnet-add {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.steps-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
<style>
.network label[for="addressRange"] {
  padding-left: 12px;
}
</style>
