<template lang="html">
  <div>
    <el-dialog
      v-model="showDialog"
      title="Assign Person">
      <el-form
        ref="form"
        :model="person"
        :rules="rules">
        <el-form-item prop="personId">
          <el-select
            v-model="person.personId"
            placeholder="Please select the person"
            @change="addPerson">
            <el-option
              v-for="person in persons"
              :key="person.id"
              :label="person.givenName + ' ' + person.surname"
              :value="person.id">
            </el-option>
            <el-option
              key="new-person"
              value="new-person"
              label="+ Add Person">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="submit">
          <el-button type="primary" @click="submit">Assign</el-button>
        </div>
      </el-form>
    </el-dialog>
    <add-person-dialog ref="addPerson"></add-person-dialog>
  </div>
</template>

<script>
import AddPersonDialog from "../../customers/settings/dialogs/AddPersonDialog.vue";
import impersonation from "../../../impersonation";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "assign-person",
  components: {
    AddPersonDialog,
  },
  created() {
    this.$store
      .dispatch("getCustomersPersons", impersonation.getCustomer().id)
      .then((response) => {
        this.$store.state.persons = response.data;
      });
  },
  data() {
    let validatePersonId = (rule, value, callback) => {
      if (this.person.personId.length === 0) {
        callback(new Error("Please select the person to assign"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      person: {
        personId: "",
        assetId: "",
      },
      rules: {
        personId: [{ validator: validatePersonId, trigger: "change" }],
      },
    };
  },
  methods: {
    addPerson(value) {
      if (value === "new-person") {
        this.$refs.addPerson.openDialog();
        this.person.personId = "";
      }
    },
    openDialog(assetModelId) {
      this.person.assetId = assetModelId;
      this.showDialog = true;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("assignPerson", _.cloneDeep(this.person));
          this.$refs.form.resetFields();
          this.$emit("refresh");
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    ...mapState(["persons"]),
  },
};
</script>

<style lang="css" scoped>
.submit {
  margin: 0 auto;
  text-align: center;
}
</style>
