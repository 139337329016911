<template lang="html">
  <div>
    <el-dialog
      title="Add Asset Class"
      v-model="showDialog">
      <el-form
        ref="form"
        :model="assetClass"
        :rules="rules">
        <el-form-item label="Asset Class Name" prop="name">
          <el-input
            v-model="assetClass.name"
            placeholder="Please set the name">
          </el-input>
        </el-form-item>
        <el-form-item label="Asset Type" prop="assetTypeId">
          <el-select
            v-model="assetClass.assetTypeId"
            placeholder="Please select the Asset Type">
            <el-option
              v-for="assetType in assetTypes"
              :key="assetType.id"
              :value="assetType.id"
              :label="assetType.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="submit">Add Asset Class</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "asset-class-dialog",
  created() {},
  data() {
    let validateName = (rule, value, callback) => {
      if (this.assetClass.name.length !== 0) {
        callback();
      } else {
        callback(new Error("Please set the name"));
      }
    };
    return {
      showDialog: false,
      assetClass: {
        name: "",
        assetTypeId: "",
        customerId: impersonation.getCustomer().id,
        fromAdmin: false,
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        assetTypeId: [
          {
            required: true,
            message: "Please select the Asset Type",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.assetClass);
          this.$store.dispatch("addAssetClass", payload);
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    ...mapState(["assetTypes"]),
  },
};
</script>

<style lang="css" scoped>
</style>
