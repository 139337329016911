<template>
  <div>
    <el-dialog
      v-model="showDialog"
      :title="getTitle"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div style="margin: 0 auto">
        <el-form ref="form" :model="model" :rules="rules">
          <el-form-item prop="osType">
            <el-checkbox-group :max="1">
              <el-checkbox disabled label="Windows"></el-checkbox>
              <el-checkbox disabled label="Linux"></el-checkbox>
              <el-checkbox disabled label="MacOS"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="OS Stream" prop="osStreamId">
            <el-select
              v-model="model.osStreamId"
              @change="resetForm"
              filterable
            >
              <el-option
                v-for="stream in customerImageStreams"
                :key="stream.id"
                :label="stream.name"
                :value="stream.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="selectedOSStream.type === 'Linux'"
            label="Username"
            prop="usernameLinux"
          >
            <el-input v-model="model.usernameLinux"></el-input>
          </el-form-item>
          <div>
            <span>Edition: {{ " " + selectedOSStream.edition }}</span>
          </div>
          <div>
            <span>Architecture: {{ " " + selectedOSStream.architecture }}</span>
          </div>
          <div>
            <span>Language: {{ " " + selectedOSStream.language }}</span>
          </div>
          <div v-if="selectedOSStream.type === 'Linux'">
            <span>User Password: {{ " " + userPasswordLinux }}</span>
          </div>
          <div v-if="selectedOSStream.type === 'Linux'">
            <span>Admin Password: {{ " " + adminPasswordLinux }}</span>
          </div>
          <div v-if="selectedOSStream.type === 'Linux'">
            <span
              >Partition Encryption Password:
              {{ " " + partitionEncryptionPassLinux }}</span
            >
          </div>
          <br />
          <el-form-item v-if="checkValues" label="Image" prop="imageId">
            <el-select v-model="model.imageId" width="100%">
              <el-option
                v-for="image in filterimages"
                :key="image.id"
                :label="
                  image.name +
                  ' (BuildNr: ' +
                  getBuildNr(image) +
                  getUpdate(image) +
                  ')'
                "
                :value="image.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="selectedOSStream.type !== undefined">
            <el-form-item
              v-if="selectedOSStream.type === 'Windows'"
              label="Keyboard Layout (Windows)"
              prop="keyboardLayout"
            >
              <el-select v-model="model.keyboardLayoutWindows">
                <el-option
                  v-for="layout in keyboardLayoutsWindows"
                  :key="layout.key"
                  :value="layout.value"
                  :label="layout.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else
              label="Keyboard Layout (Linux)"
              prop="keyboardLayout"
            >
              <el-select
                multiple
                v-model="selectedKeyboardLinux"
                @change="setKeyboardLayoutLinux"
              >
                <el-option
                  v-for="layout in keyboardLayoutsLinux"
                  :key="layout.key"
                  :value="layout.value"
                  :label="layout.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="selectedOSStream.type === 'Windows'"
              label="Timezone (Windows)"
              prop="timeZoneWindows"
            >
              <el-select v-model="model.timeZoneWindows">
                <el-option
                  v-for="tz in timeZonesWindows"
                  :key="tz.key"
                  :value="tz.value"
                  :label="tz.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else-if="selectedOSStream.type === 'Linux'"
              label="Timezone (Linux)"
              prop="timeZoneLinux"
            >
              <el-select v-model="model.timeZoneLinux">
                <el-option
                  v-for="tz in timeZonesLinux"
                  :key="tz.key"
                  :value="tz.value"
                  :label="tz.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="selectedOSStream.type === 'Linux'"
              label="Local"
              prop="languagePackLinux"
            >
              <el-select v-model="model.languagePackLinux">
                <el-option
                  v-for="lp in languagePacksLinux"
                  :key="lp.key"
                  :label="lp.label"
                  :value="lp.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="Install Script" prop="installScript">
            <el-select v-model="model.installScript">
              <el-option
                v-for="script in adminOptions"
                :label="script.name"
                :key="script.id"
                :value="script.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="selectedOSStream.type === 'Windows'"
            label="Local Admin Username"
            prop="localAdminUsername"
          >
            <el-input
              style="width: 50%"
              v-model="model.localAdminUsername"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="selectedOSStream.type === 'Windows'"
            label="Local Admin Password"
            prop="localAdminPassword"
          >
            <el-input
              style="width: 50%"
              type="password"
              show-password
              v-model="model.localAdminPassword"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit"
              >Start Installation</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <deviceoption-parameter
      ref="deviceOptionParameter"
    ></deviceoption-parameter>
  </div>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import { setOSSettings, getOSSettings } from "../../services/clients";
import _ from "lodash";
import DeviceOptionParameter from "../deviceoptions/DeviceOptionParameter";
// success,
import { errorMessage } from "../../store/modules/util";

export default {
  components: { "deviceoption-parameter": DeviceOptionParameter },
  created() {
    let customerId = impersonation.getCustomer().id;
    this.$store.dispatch("getCustomerImageStreams", customerId);
    this.$store.dispatch("getAdminOptions");
  },
  data() {
    let validateUsername = (rule, value, callback) => {
      var regex = /^[a-z0-9]*$/;
      if (this.model.usernameLinux === "") {
        callback(new Error("Please set the intial users name"));
      }
      let result = this.model.usernameLinux.match(regex);
      if (!result) {
        callback(
          new Error(
            "The username must consist of lowercase alphanumeric letters"
          )
        );
      } else {
        callback();
      }
    };
    let validateInstallScript = (rule, value, callback) => {
      if (this.model.installScript === "") {
        callback(new Error("Please set the installation script"));
      } else {
        callback();
      }
    };
    let validateLocalAdminPassword = (rule, value, callback) => {
      if (this.model.localAdminPassword !== "") {
        if (this.model.localAdminPassword.length < 8) {
          callback(
            new Error(
              "The local admin password must contain 8 or more characters!"
            )
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let validateLocalAdminUsername = (rule, value, callback) => {
      let regexp = /^[A-Za-z0-9_ ]*$/;
      if (this.model.localAdminUsername !== "") {
        let regValidation = this.model.localAdminUsername.match(regexp);
        if (regValidation) {
          callback();
        } else {
          callback(
            new Error(
              'Please just use alphanumeric characters with space or "_"!'
            )
          );
        }
      } else {
        callback();
      }
    };
    return {
      localAdminUsername: "",
      localAdminPassword: "",
      selectedKeyboardLinux: [],
      languagePacksLinux: [
        { key: 1, label: "German", value: "de_DE.UTF-8" },
        { key: 2, label: "English (US)", value: "en_US.UTF-8" },
      ],
      keyboardLayoutsLinux: [
        { key: 1, value: "de", label: "German" },
        { key: 2, value: "en", label: "English" },
      ],
      keyboardLayoutsWindows: [
        { key: 1, value: "de-DE", label: "German" },
        { key: 2, value: "en-US", label: "English (US)" },
      ],
      timeZonesWindows: [
        {
          key: 1,
          value: "W. Europe Standard Time",
          label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        },
        {
          key: 2,
          value: "E. Europe Standard Time",
          label: "(GMT+02:00) Bucharest",
        },
      ],
      timeZonesLinux: [
        { key: 1, value: "Europe/Berlin", label: "Europe/Berlin" },
      ],
      images: [],
      showFilters: false,
      rules: {
        osStreamId: [
          {
            required: true,
            message: "Please select the stream",
            trigger: "change",
          },
        ],
        edition: [
          {
            required: true,
            message: "Please select the edition",
            trigger: "change",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please select the architecture",
            trigger: "change",
          },
        ],
        language: [
          {
            required: true,
            message: "Please select the language",
            trigger: "change",
          },
        ],
        // patchInfo: [{required: true, message: 'Please select the patch information', trigger: 'change'}],
        imageId: [
          {
            required: true,
            message: "Please select the image",
            trigger: "change",
          },
        ],
        usernameLinux: [
          { required: true, validator: validateUsername, trigger: "blur" },
        ],
        installScript: [
          {
            required: true,
            validator: validateInstallScript,
            trigger: ["change", "blur"],
          },
        ],
        localAdminPassword: [
          {
            validator: validateLocalAdminPassword,
            trigger: ["change", "blur"],
          },
        ],
        localAdminUsername: [
          {
            validator: validateLocalAdminUsername,
            trigger: ["change", "blur"],
          },
        ],
        // languagePackLinux: [{required: true, message: 'Please select the language pack', trigger: 'change'}]
      },
      selectedOSStream: {},
      model: {
        osStreamId: "",
        edition: "",
        architecture: "",
        language: "",
        patchInfo: "",
        imageId: "",
        keyboardLayoutWindows: "",
        keyboardLayoutLinux: "",
        timeZoneWindows: "",
        timeZoneLinux: "",
        usernameLinux: "",
        languagePackLinux: "",
        installScript: "",
        localAdminPassword: "",
        localAdminUsername: "",
      },
      client: {
        id: "",
        name: "",
      },
      showDialog: false,
      osSettings: {},
      userPasswordLinux: "",
      adminPasswordLinux: "",
      partitionEncryptionPassLinux: "",
    };
  },
  methods: {
    getBuildNr(image) {
      if (image.buildNr !== null && image.buildNr !== "") {
        return image.buildNr;
      } else {
        return "//";
      }
    },
    getUpdate(image) {
      var result = " | Update: ";
      if (image.type === "Linux") {
        result = "";
      } else {
        if (image.update !== null && image.update !== "") {
          result += image.update;
        } else {
          result += "//";
        }
      }
      return result;
    },
    setKeyboardLayoutLinux(val) {
      for (let i = 0; i < val.length; i++) {
        if (i === 0) {
          this.model.keyboardLayoutLinux = val[i];
        } else {
          this.model.keyboardLayoutLinux += ", " + val[i];
        }
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.model);
          setOSSettings(
            impersonation.getCustomer().id,
            this.model.imageId,
            this.client.id,
            payload
          )
            .then(() => {
              let scriptVersions = null;
              if (
                this.model.installScript !== null &&
                this.model.installScript !== ""
              ) {
                for (let i = 0; i < this.adminOptions.length; i++) {
                  if (this.adminOptions[i].id === this.model.installScript) {
                    scriptVersions = this.adminOptions[i].versions;
                    break;
                  }
                }
                scriptVersions.sort((a, b) => b.number - a.number);
              }
              // TODO: ADD SCRIPT ID
              this.$refs.deviceOptionParameter.open(
                this.client.id,
                scriptVersions[0],
                0,
                true,
                this.model.installScript
              );
            })
            .catch((err) => console.log({ err }))
            .finally(() => {
              this.model = {
                osStreamId: "",
              };
              this.client = {
                name: "",
                id: "",
              };
              this.showDialog = false;
            });
        }
      });
    },
    resetForm(val) {
      let osStream = this.customerImageStreams.find((el) => {
        return el.id === val;
      });
      this.model = {
        osStreamId: this.model.osStreamId,
        edition: "",
        architecture: "",
        language: "",
        patchInfo: "",
        imageId: "",
        keyboardLayoutWindows: "",
        keyboardLayoutLinux: "",
        timeZoneWindows: "",
        timeZoneLinux: "",
        usernameLinux: "",
        languagePackLinux: "",
      };
      this.selectedOSStream = osStream;
    },
    handleClose() {
      // reset values
      this.model = {
        osStreamId: "",
      };
      this.client = {
        name: "",
        id: "",
      };
      this.showDialog = false;
    },
    openDialog(client) {
      this.client = client;
      getOSSettings(impersonation.getCustomer().id, this.client.id)
        .then((response) => {
          console.log({ response }, response.data.installScript);
          if (response.data.installScript !== null) {
            this.model.installScript = response.data.installScript;
          } else {
            this.model.installScript = "";
          }
          if (response.data.osSettingsImageId !== null) {
            let image = null;
            for (let i = 0; i < this.customerImageStreams.length; i++) {
              let checkBreak = false;
              for (
                let j = 0;
                j < this.customerImageStreams[i].images.length;
                j++
              ) {
                if (
                  this.customerImageStreams[i].images[j].id ===
                  response.data.osSettingsImageId
                ) {
                  this.selectedOSStream = this.customerImageStreams[i];
                  image = this.customerImageStreams[i].images[j];
                  checkBreak = true;
                  break;
                }
              }
              if (checkBreak) {
                // Leave first for loop
                break;
              }
            }
            let stream = this.customerImageStreams.filter((x) => {
              return x.id === image.customerImageStreamId;
            });
            this.images = stream[0].images;
            this.model.imageId = image.id;
            this.model.osStreamId = image.customerImageStreamId;
            if (response.data.localAdminPassword !== null) {
              this.model.localAdminPassword = response.data.localAdminPassword;
            } else {
              this.model.localAdminPassword = "";
            }
            if (response.data.localAdminUsername !== null) {
              this.model.localAdminUsername = response.data.localAdminUsername;
            } else {
              this.model.localAdminUsername = "";
            }
            if (response.data.keyboardLayoutLinux !== null) {
              this.model.keyboardLayoutLinux =
                response.data.keyboardLayoutLinux;
              this.selectedKeyboardLinux =
                response.data.keyboardLayoutLinux.split(", ");
            } else if (response.data.keyboardLayoutWindows !== null) {
              this.model.keyboardLayoutWindows =
                response.data.keyboardLayoutWindows;
            }
            if (response.data.timeZoneLinux !== null) {
              this.model.timeZoneLinux = response.data.timeZoneLinux;
            } else if (response.data.timeZoneWindows !== null) {
              this.model.timeZoneWindows = response.data.timeZoneWindows;
            }
            if (response.data.userPasswordLinux === null) {
              this.userPasswordLinux = "//";
            } else {
              this.userPasswordLinux = response.data.userPasswordLinux;
            }
            if (response.data.adminPasswordLinux == null) {
              this.adminPasswordLinux = "//";
            } else {
              this.adminPasswordLinux = response.data.adminPasswordLinux;
            }
            if (response.data.partitionEncryptionPassLinux === null) {
              this.partitionEncryptionPassLinux = "//";
            } else {
              this.partitionEncryptionPassLinux =
                response.data.partitionEncryptionPassLinux;
            }
            if (response.data.languagePackLinux !== null) {
              this.model.languagePackLinux = response.data.languagePackLinux;
            }
            this.model.usernameLinux = response.data.usernameLinux;
            this.showFilters = true;
          } else {
            this.$refs.form.resetFields();
          }
        })
        .catch((error) => errorMessage(error.response));
      this.showDialog = true;
    },
  },
  computed: {
    ...mapState(["customerImageStreams", "adminOptions"]),
    checkValues() {
      if (this.model.osStreamId !== "") {
        return true;
      } else {
        return false;
      }
    },
    getTitle() {
      return "Install OS for Client " + this.client.name;
    },
    filterimages() {
      let osStream = this.customerImageStreams.filter((x) => {
        return x.id === this.model.osStreamId;
      });
      return osStream[0].images;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
