<template>
  <div class="container">
    <add-company-dialog
      ref="addCompanyDialog"
      :renderNewLocationOption="true"
    ></add-company-dialog>
    <edit-company-dialog ref="editCompanyDialog"></edit-company-dialog>
    <el-col :span="22">
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input
            placeholder="Find companies"
            v-model="search"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon v-if="isSubset" @click="clearSearch">
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredCompanies.length }} of {{ companies.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <el-button
            type="primary"
            class="top-right"
            @click="openCreateCompanyDialog"
          >
            <el-icon>
              <Plus />
            </el-icon>
            Add Company
          </el-button>
        </el-col>
      </el-row>
    </el-col>
    <div class="table-container">
      <el-table :data="filteredCompanies">
        <el-table-column
          label="Corporate Name"
          prop="corporateName"
        ></el-table-column>
        <el-table-column
          label="Description"
          prop="description"
        ></el-table-column>
        <el-table-column
          label="Organization"
          prop="formOfOrganization"
        ></el-table-column>
        <el-table-column label="Website" prop="linkWebsite"></el-table-column>
        <el-table-column label="Type" prop="type"></el-table-column>
        <el-table-column label="Expert" prop="expert">
          <template v-slot="scope">
            {{ getExpertName(scope.row.expert) }}
          </template>
        </el-table-column>
        <el-table-column label="Headquarter" prop="headquarter">
          <template v-slot="scope">
            {{ getHeadquarterName(scope.row.headquarter) }}
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              @edit="openEditCompanyDialog"
              @delete="confirmDelete"
            ></actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import AddCompanyValidationRules from "./AddCompanyValidationRules";
// import impersonation from '../../../impersonation'
import ActionsDropdown from "../../shared/ActionsDropdown";
import AddCompanyDialog from "./dialogs/AddCompanyDialog";
import EditCompanyDialog from "./dialogs/EditCompanyDialog";
import FilterMixin from "../../../mixins/FilterMixin";

export default {
  name: "companies-settings",
  props: ["customerId"],
  mixins: [AddCompanyValidationRules, FilterMixin],
  components: {
    ActionsDropdown,
    AddCompanyDialog,
    EditCompanyDialog,
  },
  data() {
    return {
      search: "",
      formModel: {
        corporateName: "",
        formOfOrganization: "",
        street: "",
        streetNr: "",
        postcode: "",
        city: "",
        country: "",
        phoneNr: "",
        linkWebsite: "",
        type: "",
        description: "",
        name: "",
        publicIp: "",
        expert: "",
      },
      typeOptions: [
        {
          value: "vendor",
          label: "Vendor",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "unspecified",
          label: "Unspecified",
        },
      ],
    };
  },
  created() {
    this.getCompaniesOfCustomer();
  },
  methods: {
    openEditCompanyDialog(company) {
      this.$refs.editCompanyDialog.openDialog(company);
    },
    confirmDelete(company) {
      this.$confirm(
        "This will permanently delete the company. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, company));
    },
    deleteSuccess(company) {
      this.$store.dispatch("deleteCompany", company.id);
    },
    getCompaniesOfCustomer() {
      this.$store
        .dispatch("getCustomersCompanies", this.customerId)
        .then((response) =>
          this.$store.commit("setCustomersCompanies", response.data)
        );
    },
    isSubset() {
      return this.companies.length !== this.filteredCompanies.length;
    },
    openCreateCompanyDialog() {
      this.$refs.addCompanyDialog.openDialog();
    },
    getHeadquarterName(headquarter) {
      if (headquarter != null) {
        return headquarter.name;
      } else {
        return "//";
      }
    },
    getExpertName(expert) {
      if (expert != null) {
        return (
          expert.givenName + " " + expert.middleName + " " + expert.surname
        );
      } else {
        return "//";
      }
    },
  },
  computed: {
    filteredCompanies() {
      return this.filterCompanies(this.companies, this.search);
    },
    companies: {
      get() {
        return this.$store.state.customersCompanies;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  padding-top: 20px;
}
.space-top {
  margin-top: 20px;
}
.space-left {
  margin-left: 20px;
}
.flex {
  display: flex;
  justify-content: center;
}
.table-container {
  width: 100%;
}
</style>
