<template>
  <div class="container">
    <h3 class="center">SmartDeploy Settings</h3>
    <el-row>
      <el-col :span="22" :offset="1">
        <el-form :model="customer" label-width="220px" ref="customerForm">
          <el-form-item label="Helpdesk Email" prop="email">
            <el-input
              type="email"
              v-model="customer.email"
              placeholder="Please set the helpdesk email"
            ></el-input>
          </el-form-item>
          <el-form-item label="Helpdesk Phone Nr" prop="phone">
            <el-input
              v-model="customer.phone"
              placeholder="Please set the helpdesk phone nr"
            ></el-input>
          </el-form-item>
          <el-form-item label="Opening Times" prop="openingTimes">
            <el-input
              :autosize="{ minRows: 5 }"
              placeholder="Opening Times"
              type="textarea"
              v-model="customer.openingTimes"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Button 1 Label">
            <el-input
              v-model="customer.btn1Label"
              placeholder="Please set the Button Label"
            ></el-input>
          </el-form-item>
          <el-form-item label="Command Line Button 1">
            <el-input
              placeholder="Please put in the command line for button 1"
              v-model="customer.cmdBtn1"
            ></el-input>
          </el-form-item>
          <el-form-item label="Button 2 Label">
            <el-input
              v-model="customer.btn2Label"
              placeholder="Please set the Button Label"
            ></el-input>
          </el-form-item>
          <el-form-item label="Command Line Button 2">
            <el-input
              placeholder="Please put in the command line for button 2"
              v-model="customer.cmdBtn2"
            ></el-input>
          </el-form-item>
          <el-form-item label="Button 3 Label">
            <el-input
              v-model="customer.btn3Label"
              placeholder="Please set the Button Label"
            ></el-input>
          </el-form-item>
          <el-form-item label="Command Line Button 3">
            <el-input
              placeholder="Please put in the command line for button 3"
              v-model="customer.cmdBtn3"
            ></el-input>
          </el-form-item>
          <el-form-item label="Button 4 Label">
            <el-input
              v-model="customer.btn4Label"
              placeholder="Please set the Button Label"
            ></el-input>
          </el-form-item>
          <el-form-item label="Command Line Button 4">
            <el-input
              placeholder="Please put in the command line for button 4"
              v-model="customer.cmdBtn4"
            ></el-input>
          </el-form-item>
          <el-form-item label="Banner Link">
            <el-input
              v-model="customer.bannerLink"
              placeholder="Please put in the link for the banner"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="SmartDeploy-Label (right)"
            prop="iconRight"
            id="iconRight"
          >
            <div class="uploader-container-icon">
              <el-upload
                ref="iconRightUploader"
                class="avatar-uploader"
                :headers="headers"
                :action="uploadUrl"
                :limit="1"
                drag
                :file-list="iconsRight"
                :on-remove="handleRemoveIconRight"
                :on-success="handleSuccessIconRight"
                :before-upload="beforeUploadIconRight"
              >
                <img v-if="iconURLRight" :src="iconURLRight" class="avatar" />
                <div v-else>
                  <div class="el-upload__text">
                    <el-icon>
                      <UploadFilled />
                    </el-icon>
                    Drop file here or <em>click to upload</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item
            label="SmartDeploy-Label (left)"
            prop="iconsLeft"
            id="iconLeft"
          >
            <div class="uploader-container-icon">
              <el-upload
                ref="iconLeftUploader"
                class="avatar-uploader"
                :headers="headers"
                :action="uploadUrl"
                drag
                :limit="1"
                :file-list="iconsLeft"
                :on-remove="handleRemoveIconLeft"
                :on-success="handleSuccessIconLeft"
                :before-upload="beforeUploadIconLeft"
              >
                <img v-if="iconURLLeft" :src="iconURLLeft" class="avatar" />
                <div v-else>
                  <div class="el-upload__text">
                    <el-icon>
                      <UploadFilled />
                    </el-icon>
                    Drop file here or <em>click to upload</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="SmartDeploy-Banner" prop="banners">
            <div class="uploader-container-banner">
              <el-upload
                ref="bannerUploader"
                class="avatar-uploader"
                :headers="headers"
                :action="uploadUrl"
                :limit="1"
                drag
                :file-list="banners"
                :on-remove="handleRemoveBanner"
                :on-success="handleSuccessBanner"
                :before-upload="beforeUploadBanner"
              >
                <img v-if="bannerURL" :src="bannerURL" class="avatar-banner" />
                <div v-else>
                  <div class="el-upload__text">
                    <el-icon>
                      <UploadFilled />
                    </el-icon>
                    Drop file here or <em>click to upload</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-button
            type="primary"
            style="text-align: center; margin-bottom: 30px"
            @click="submit"
            >Save</el-button
          >
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  downloadIconOrBanner,
  deleteIconOrBanner,
  getCustomer,
} from "../../services/customers";
import _ from "lodash";
import impersonation from "../../impersonation";
import { errorMessageBlob } from "../../store/modules/util";

export default {
  created() {
    getCustomer(impersonation.getCustomer().id)
      .then((response) => {
        this.customer = response.data;
      })
      .catch((err) => console.log({ err }))
      .finally(() => {
        if (
          this.customer.iconRight !== null &&
          this.customer.iconRight.id !== "" &&
          this.customer.iconRight.guid !== ""
        ) {
          downloadIconOrBanner(this.customer.iconRight.id)
            .then((response) => {
              this.iconURLRight = window.URL.createObjectURL(response.data);
            })
            .catch(() => {
              errorMessageBlob(
                "Error: The right icon does not exist anymore. Please upload an icon again."
              );
            });
        } else {
          this.customer.iconRight = {
            id: "",
            name: "",
          };
          this.iconsRight = [];
        }
        if (
          this.customer.iconLeft !== null &&
          this.customer.iconLeft.id !== "" &&
          this.customer.iconLeft.guid !== ""
        ) {
          downloadIconOrBanner(this.customer.iconLeft.id)
            .then((response) => {
              this.iconURLLeft = window.URL.createObjectURL(response.data);
            })
            .catch(() => {
              errorMessageBlob(
                "Error: The left icon does not exist anymore. Please upload an icon again."
              );
            });
        } else {
          this.customer.iconLeft = {
            id: "",
            name: "",
          };
          this.iconsLeft = [];
        }
        if (
          this.customer.banner !== null &&
          this.customer.banner.id !== "" &&
          this.customer.banner.guid !== ""
        ) {
          downloadIconOrBanner(this.customer.banner.id)
            .then((response) => {
              this.bannerURL = window.URL.createObjectURL(response.data);
            })
            .catch(() => {
              errorMessageBlob(
                "ERROR: The banner does not exist anymore. Please upload a banenr again."
              );
            });
        } else {
          this.customer.banner = {
            id: "",
            name: "",
          };
          this.banners = [];
        }
      });
  },
  data() {
    return {
      iconsLeft: [],
      iconsRight: [],
      banners: [],
      iconURLRight: "",
      iconURLLeft: "",
      bannerURL: "",
      uploadUrl:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/software/iconAndBanner/upload",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      customer: {
        id: "",
        openingTimes: "",
        phone: "",
        email: "",
        btn1Label: "",
        btn2Label: "",
        btn3Label: "",
        btn4Label: "",
        cmdBtn1: "",
        cmdBtn2: "",
        cmdBtn3: "",
        cmdBtn4: "",
        iconRight: {
          id: "",
          name: "",
        },
        iconLeft: {
          id: "",
          name: "",
        },
        banner: {
          id: "",
          name: "",
        },
        bannerLink: "",
      },
    };
  },
  methods: {
    setFilesIconRight(file) {
      if (file.length > 0) {
        this.customer.iconRight = {
          id: file[file.length - 1].response.id,
          name: file[file.length - 1].name,
        };
      } else {
        this.customer.iconRight = { id: "", name: "" };
      }
    },
    handleRemoveIconRight(a, files) {
      this.setFilesIconRight(files);
      deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
      this.iconURLRight = "";
    },
    handleSuccessIconRight(a, b, files) {
      this.setFilesIconRight(files);
    },
    setFilesIconLeft(file) {
      if (file.length > 0) {
        this.customer.iconLeft = {
          id: file[0].response.id,
          name: file[0].name,
        };
      } else {
        this.customer.iconLeft = { id: "", name: "" };
      }
    },
    handleRemoveIconLeft(a, files) {
      this.setFilesIconLeft(files);
      deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
      this.iconURLLeft = "";
    },
    handleSuccessIconLeft(a, b, files) {
      this.setFilesIconLeft(files);
    },
    setFilesBanner(file) {
      if (file.length > 0) {
        this.customer.banner = { id: file[0].response.id, name: file[0].name };
      } else {
        this.customer.banner = { id: "", name: "" };
      }
    },
    handleRemoveBanner(a, files) {
      this.setFilesBanner(files);
      deleteIconOrBanner(a.response.id).catch((error) => console.log(error));
      this.bannerURL = "";
    },
    handleSuccessBanner(a, b, files) {
      this.setFilesBanner(files);
    },
    beforeUploadIconRight(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURLRight = url;
    },
    beforeUploadIconLeft(file) {
      var url = window.URL.createObjectURL(file);
      this.iconURLLeft = url;
    },
    beforeUploadBanner(file) {
      var url = window.URL.createObjectURL(file);
      this.bannerURL = url;
    },
    submit() {
      let data = _.cloneDeep(this.customer);
      this.$store.dispatch("setSDSettings", data);
      this.customer.iconURLLeft = "";
      this.customer.iconURLRight = "";
      this.customer.bannerURL = "";
    },
  },
};
</script>

<style lang="css" scoped>
.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.avatar-uploader-icon {
  height: 100px;
  width: 100%;
}

i:before {
  position: relative;
  top: 30%;
}

.uploader-container-icon {
  width: 200px;
  height: 200px;
}

.uploader-container-banner {
  width: 500px;
  height: 200px;
}

.avatar {
  width: 200px;
  height: 100px;
  margin: 0 auto;
  object-fit: fill;
}

.avatar-banner {
  margin: 0 auto;
  width: 500px;
  height: 100px;
  object-fit: fill;
}

.center {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 30px;
}

.dialog {
  margin: 0 auto;
}

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: inline-block;
}

.submit-btn {
  margin-top: 10px;
  text-align: center;
}

/* form {
  padding: 2rem;
} */

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
