<template>
  <el-dialog
    title="Delete Customer"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div style="text-align: center; margin: 0 auto">
      <p>
        This will permanently delete the customer:
        <br />
        <span style="font-weight: bold">{{ customer.name }}</span>
        <br />
        The corresponding cloud data will be deleted, too. For continue deleting
        please enter the customer name:
      </p>
      <div class="flex" style="margin-bottom: 10px">
        <el-input
          style="width: 80%; margin-right: 10px"
          v-model="input"
          @input="validateInput"
        ></el-input>
        <img
          style="width: 20px; height: 20px"
          v-if="disableSubmit"
          src="../../../static/error.png"
        />
        <img
          v-else
          style="width: 20px; height: 20px"
          src="../../../static/success.png"
        />
      </div>
      <div class="flex">
        <el-button :disabled="disableSubmit" type="primary" @click="submit"
          >Ok</el-button
        >
        <el-button @click="handleClose">Cancel</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      disableSubmit: true,
      input: "",
      customer: {
        id: "",
        name: "",
      },
    };
  },
  methods: {
    validateInput(value) {
      if (value === this.customer.name) {
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
    },
    openDialog(customer) {
      this.customer.id = customer.id;
      this.customer.name = customer.name;
      this.showDialog = true;
    },
    handleClose() {
      this.showDialog = false;
    },
    submit() {
      this.$store.dispatch("deleteCustomer", this.customer);
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
