export function getCustomersStorageAccounts (customerId) {
  return window.axiosInstance.get(`/storage-accounts/${customerId}`)
}

export function getStorageAccountFromAzure (storageAccountId) {
  return window.axiosInstance.get(`/storage-accounts/${storageAccountId}`)
}

export function createStorageAccount (payload) {
  return window.axiosInstance.post(`/storage-accounts`, payload)
}
