export function getCustomersResourceGroups (customerId) {
  return window.axiosInstance.get(`/resource-groups/${customerId}`)
}

export function createResourceGroup (payload) {
  return window.axiosInstance.post(`/resource-groups`, payload)
}

export function checkResGrpNameAvailable (customerId, subscriptionId, resGrpName, managed) {
  return window.axiosInstance.put(`/resource-groups/validate/${customerId}/${subscriptionId}/${resGrpName}/${managed}`)
}
