import Auth from '../auth'

export default {
  name: 'permission',
  computed: {
    isAdmin () {
      return Auth.getAuth().admin === 'True'
    },
    isSystemhouse () {
      return Auth.hasPermission('systemhouse')
    }
  }
}
