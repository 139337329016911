<template>
  <div>
    <el-dialog
      title="Software Packages"
      v-model="showDialog"
      width="80%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-table :data="filteredSoftwares" @sort-change="sortVersions">
        <el-table-column prop="version" label="Version" sortable="custom">
        </el-table-column>
        <el-table-column
          prop="customerStatus"
          label="Customer Status"
          sortable
        ></el-table-column>
        <el-table-column
          prop="displayRevisionNumber"
          label="Revision Number"
        ></el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :deleteItem="true"
              :edit="true"
              :showRevisionNr="true"
              @delete="confirmDelete"
              @edit="openCustomerSWEdit"
              @showRevisionNr="openRevisionOverview"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <edit-customer-sw-package ref="editSWPackage"></edit-customer-sw-package>
    <revision-overview ref="revisionOverview"></revision-overview>
  </div>
</template>

<script>
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import impersonation from "../../impersonation";
import EditCustomerSWPackage from "./EditCustomerSWPackage.vue";
import RevisionMessageOverview from "../softwareStream/RevisionMessageOverview.vue";

export default {
  components: {
    ActionsDropdown,
    "edit-customer-sw-package": EditCustomerSWPackage,
    "revision-overview": RevisionMessageOverview,
  },
  data() {
    return {
      sortVersionOrder: undefined,
      softwarePackages: [],
      showDialog: false,
    };
  },
  methods: {
    sortVersions(column) {
      this.sortVersionOrder = column.order;
    },
    openRevisionOverview(software) {
      this.$refs.revisionOverview.openCustomerSoftware(software.id);
    },
    compareDesc(a, b) {
      if (
        parseInt(a.version.replaceAll(".", "")) <
        parseInt(b.version.replaceAll(".", ""))
      ) {
        return 1;
      } else {
        return -1;
      }
    },
    compareAsc(a, b) {
      if (
        parseInt(a.version.replaceAll(".", "")) >
        parseInt(b.version.replaceAll(".", ""))
      ) {
        return 1;
      } else {
        return -1;
      }
    },
    openCustomerSWEdit(swPackage) {
      this.$refs.editSWPackage.openDialog(swPackage);
    },
    openDialog(swPackages) {
      this.softwarePackages = swPackages.sort(this.compare);
      this.showDialog = true;
    },
    handleClose() {
      this.softwarePackages = [];
      this.showDialog = false;
    },
    confirmDelete(customerSoftware) {
      this.$confirm(
        "This will permanently delete the Software Package. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, customerSoftware), this.deleteError);
    },
    deleteSuccess(customerSoftware) {
      let payload = {
        customerId: impersonation.getCustomer().id,
        customerSoftwareId: customerSoftware.id,
      };
      this.$store.dispatch("deleteCustomerSoftware", payload);
      this.showDialog = false;
    },
    deleteError() {},
  },
  computed: {
    filteredSoftwares() {
      if (this.sortVersionOrder === "ascending") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.softwarePackages = this.softwarePackages.sort(this.compareAsc);
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.softwarePackages = this.softwarePackages.sort(this.compareDesc);
      }
      return this.softwarePackages;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
