<template>
  <div class="padding">
  <el-button type="primary" class="submit">Update</el-button>
    <el-form ref="customer-general-form" :model="formModel">
      <div class="form-item-container">
        <el-form-item label="Company Name">
          <el-input
            :v-model="formModel.companyName"
            placeholder="Insert Company Name">
          </el-input>
        </el-form-item>
        <el-form-item label="Corporate Name">
          <el-input
            :v-model="formModel.corporateName"
            placeholder="Insert Corporate Name">
          </el-input>
        </el-form-item>
        <el-form-item label="Form of Organization">
          <el-input
            :v-model="formModel.formOfOrganization"
            placeholder="Insert Form Of Organization">
          </el-input>
        </el-form-item>
        <el-form-item label="Website">
          <el-input
            :v-model="formModel.webpageLink"
            placeholder="Insert Website Link">
          </el-input>
        </el-form-item>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Headquarter" name="headquarter">
          <div class="form-item-container">
            <el-form-item label="Street">
              <el-input
                placeholder="Insert Street"
                :v-model="formModel.headquarterStreet">
              </el-input>
            </el-form-item>
            <el-form-item
              placeholder="Insert Number"
              :v-model="formModel.headquarterStreetNr">
            </el-form-item>
            <el-form-item label="Postcode">
              <el-input
                placeholder="Insert Postcode"
                :v-model="formModel.headquarterPostcode">
              </el-input>
            </el-form-item>
            <el-form-item label="Country">
              <el-input
                placeholder="Insert Country"
                :v-model="formModel.headquarterCountry">
              </el-input>
            </el-form-item>
            <el-form-item label="Phone Number">
              <el-input
                placeholder="Insert Phone Number"
                :v-model="formModel.headquarterPhoneNr">
              </el-input>
            </el-form-item>
        </div>
        </el-collapse-item>
        <el-collapse-item title="Technical Expert" name="expert">
          <div class="form-item-container">
            <el-form-item label="Given Name">
              <el-input
              placeholder="Insert Given Name"
              :v-model="formModel.expertGivenName">
            </el-input>
            </el-form-item>
            <el-form-item label="Surname">
              <el-input
                placeholder="Insert Surname"
                :v-model="formModel.expertSurname">
              </el-input>
            </el-form-item>
            <el-form-item label="Phone Number">
              <el-input
                placeholder="Insert Phone Number"
                :v-model="formModel.expertPhoneNr">
              </el-input>
            </el-form-item>
            <el-form-item label="Email">
              <el-input
                placeholder="Insert Company Email"
                :v-model="formModel.expertEmail">
            </el-input>
            </el-form-item>
            <el-form-item label="Second Email (optional)">
              <el-input
                placeholder="Insert Private Email"
                :v-model="formModel.expertPrivateEmail">
              </el-input>
            </el-form-item>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
</div>
</template>

<script>
  // Imports

  // VueJS data & logic
  export default {
    name: 'general-customer-data',
    // The components used in here
    mixins: [],
    data () {
      return {
        formModel: {
          companyName: '',
          corporateName: '',
          formOfOrganization: '',
          headquarterStreet: '',
          headquarterStreetNr: '',
          headquarterPostcode: '',
          headquarterCity: '',
          headquarterCountry: '',
          headquarterPhoneNr: '',
          webpageLink: '',
          expertGivenName: '',
          expertSurname: '',
          expertPhoneNr: '',
          expertEmail: '',
          expertPrivateEmail: ''
        },
        activeNames: []
      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style>
  .padding {
    margin: 0 auto;
    width: 60%;
    padding: 25px;
  }
  .form-item-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 15px;
  }
</style>
