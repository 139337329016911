export default {

  setCustomer(customer) {
    sessionStorage.setItem('impersonateCustomer', JSON.stringify(customer))
  },

  removeCustomer() {
    sessionStorage.removeItem('impersonateCustomer')
  },

  getCustomer() {
    return JSON.parse(sessionStorage.getItem('impersonateCustomer'))
  }
}
