<template>
  <el-dialog v-model="showDialog" title="Download Device Option Script">
    <el-form ref="form" :model="deviceOption" label-width="180px">
      <el-form-item label="Version">
        <el-select v-model="versiondetails" placeholder="Select">
          <el-option
            v-for="item in deviceOptionVersions"
            :key="item.id"
            :label="'version ' + item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="downloadScript(versiondetails)"
      >Download</el-button
    >
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "device-option-download",
  data() {
    return {
      showDialog: false,
      deviceOption: {
        name: "",
        description: "",
        versions: [],
      },
      versiondetails: "",
    };
  },
  methods: {
    open(deviceOption) {
      this.deviceOption = deviceOption;
      this.showDialog = true;
    },
    downloadScript(versiondetails) {
      let obj = { id: this.deviceOption.id };
      obj.detail = this.deviceOption.versions.find(
        (x) => x.id === versiondetails
      );
      obj.name = this.deviceOption.name;
      obj.version = this.deviceOption.versions.find((x) => x.id);
      this.$store.dispatch("downloadDeviceOption", _.cloneDeep(obj));
      this.showDialog = false;
      this.versiondetails = "";
      /* this.$notify.info({
          title: 'Info',
          message: 'Downloading script, this operation can take a few second..',
          duration: 0
        }) */
    },
  },
  computed: {
    deviceOptionVersions() {
      return _.orderBy(this.deviceOption.versions, "number", "desc");
    },
  },
};
</script>
