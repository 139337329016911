<template>
  <div>
    <software-add ref="softwareAdd" @refresh="refresh()"></software-add>
    <import-software
      ref="importSoftware"
      @refresh="refresh()"
    ></import-software>
    <streamless-softwares ref="streamlessSoftwares"></streamless-softwares>
    <el-dialog
      title="Choose how to add Software"
      v-model="showDialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="75%"
    >
      <div style="margin: 0 auto; text-align: center">
        <el-form ref="form" :model="model" :rules="rules">
          <el-form-item prop="selectedOption">
            <el-select v-model="model.selectedOption">
              <el-option
                v-for="option in options"
                :key="option.key"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" @click="continueAddingSoftware"
            >Continue</el-button
          >
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImportSoftware from "../software/ImportSoftware.vue";
import SoftwareAdd from "../software/SoftwareAdd.vue";
import StreamlessSoftwares from "./StreamlessSoftwares.vue";

export default {
  components: {
    SoftwareAdd,
    ImportSoftware,
    "streamless-softwares": StreamlessSoftwares,
  },
  data() {
    return {
      streamId: "",
      streamName: "",
      streamType: "",
      showDialog: false,
      model: {
        selectedOption: "",
      },
      options: [
        { key: 2, label: "Add Version", value: "new" },
        { key: 3, label: "Import Software", value: "import" },
      ],
      rules: {
        selectedOption: [
          {
            required: true,
            message: "Please select the option to add software",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    refresh() {
      // TODO: Do something :)
    },
    continueAddingSoftware() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let val = this.model.selectedOption;
          if (val === "existing") {
            this.$refs.streamlessSoftwares.openDialog(this.streamId);
            this.$refs.form.resetFields();
            this.showDialog = false;
          } else if (val === "new") {
            this.$refs.softwareAdd.open(
              null,
              this.streamId,
              this.streamName,
              this.streamType
            );
            this.$refs.form.resetFields();
            this.showDialog = false;
          } else {
            this.$refs.importSoftware.openDialog(this.streamId);
            this.$refs.form.resetFields();
            this.showDialog = false;
          }
        }
      });
    },
    openDialog(streamId, streamName, streamType) {
      this.streamId = streamId;
      this.streamName = streamName;
      this.streamType = streamType;
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
