<template>
  <el-dialog
    title="Add Admin Device Option"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      :model="adminDeviceOption"
      :rules="rules"
      label-width="180px"
      ref="form"
    >
      <el-form-item label="Name" prop="name">
        <el-input
          placeholder="Device Option name"
          v-model="adminDeviceOption.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          placeholder="Device Option description"
          v-model="adminDeviceOption.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Os Type" prop="osType">
        <span>{{ adminDeviceOption.osType }}</span>
      </el-form-item>
      <el-form-item prop="type" label="Type">
        <el-select v-model="adminDeviceOption.type">
          <el-option
            v-for="type in types"
            :key="type.id"
            :value="type.value"
            :label="type.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PE only" prop="peOnly">
        <el-checkbox v-model="adminDeviceOption.peOnly"></el-checkbox>
      </el-form-item>
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      osTypes: [
        { key: 1, value: "Windows" },
        { key: 2, value: "Ubuntu" },
        { key: 3, value: "RedHat" },
      ],
      showDialog: false,
      types: [{ id: 1, value: "Installation" }],
      adminDeviceOption: {
        name: "",
        description: "",
        content: "",
        type: "",
        peOnly: false,
        osType: "",
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch(
            "editAdminOption",
            _.cloneDeep(this.adminDeviceOption)
          );
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
    openDialog(adminDeviceOption) {
      this.adminDeviceOption = adminDeviceOption;
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
