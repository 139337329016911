<template>
  <el-dialog
    v-model="showDialog"
    title="Add Devices"
    :before-close="handleClose"
  >
    <div class="centered">
      <el-form ref="chooseTypeForm" v-if="this.type === ''">
        <el-form-item label="Type" prop="clientType">
          <el-select
            v-model="type"
            placeholder="Please select the client type"
            @change="choseType"
          >
            <el-option label="Match Client" value="match" key="0"> </el-option>
            <el-option
              label="Physical device on premis"
              value="physical"
              key="1"
            >
            </el-option>
            <el-option
              disabled
              label="VM on premis Hyper-Visor"
              value="hyperVisor"
              key="2"
            >
            </el-option>
            <el-option label="VM in Cloud" value="virtual" key="3"> </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-button type="primary" @click="choseType">Confirm</el-button> -->
      </el-form>
    </div>
    <div class="centered">
      <el-form
        :model="clientMatchModel"
        :rules="rules"
        ref="matchClient"
        v-if="type === 'match'"
      >
        <el-form-item label="Client Validation Id" prop="clientValidationId">
          <el-input
            v-model="clientMatchModel.clientValidationId"
            placeholder="Client UUID"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitMatchClient">Add</el-button>
      </el-form>
      <el-form ref="chooseCloudForm" v-if="type === 'virtual'">
        <el-form-item label="Cloud" prop="cloudType">
          <el-select v-model="cloudType" placeholder="Please select your Cloud">
            <el-option label="Azure Cloud" value="Azure"> </el-option>
            <el-option label="IBM Cloud" value="IBM" disabled> </el-option>
            <el-option label="Google Cloud" value="googleCloud" disabled>
            </el-option>
            <el-option label="AWS" value="AWS" disabled> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-form
        v-if="type === 'physical'"
        ref="formPhysical"
        :model="client"
        :rules="rules"
        label-width="180px"
      >
        <el-form-item label="Device UUID" prop="uuid">
          <el-input v-model="client.uuid" placeholder="Client UUID"></el-input>
        </el-form-item>
        <el-form-item label="Serial Number" prop="serialNumber">
          <el-input
            v-model="client.serialNumber"
            placeholder="Serial Number"
          ></el-input>
        </el-form-item>
        <el-form-item label="4k Hash">
          <el-input v-model="client.hash4k" placeholder="4k Hash"></el-input>
        </el-form-item>
        <el-form-item label="Machine GUID">
          <el-input
            v-model="client.machineGuid"
            placeholder="Machine GUID"
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <div style="margin: 0 auto">
          <span v-if="showErrorMAC" style="color: red"
            >Please add at least one MAC address</span
          >
        </div>
        <br />
        <el-form-item v-for="(mac, index) in macAddresses" :key="index">
          <div style="display: flex; justify-content: space-between">
            <label>{{ "MAC " + (index + 1) }}</label>
            <el-input style="width: 80%" v-model="mac.value"> </el-input>
          </div>
        </el-form-item>
        <div style="margin: 0 auto">
          <el-button type="success" @click="addMAC">Add MAC</el-button>
          <el-button type="warning" @click="removeMAC"
            >Remove last MAC</el-button
          >
        </div>
        <el-divider></el-divider>
        <el-form-item label="Device Name" prop="clientName">
          <el-input
            v-model="client.clientName"
            placeholder="Client name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input
            v-model="client.description"
            placeholder="Description"
          ></el-input>
        </el-form-item>
        <el-form-item label="Timezone" prop="timezone">
          <el-select width="80%" v-model="client.timezone">
            <el-option
              v-for="tz in timezones"
              :key="tz.key"
              :label="tz.label"
              :value="tz.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Base" prop="baseId">
          <el-select
            v-model="client.baseId"
            placeholder="Select the Base"
            @change="setSubnetsForPhysical()"
          >
            <el-option
              v-for="base in bases"
              :key="base.id"
              :value="base.id"
              :label="base.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="Subnet" prop="subnet">
        <el-select
          v-model="client.subnet"
          placeholder="Select the subnet">
          <el-option
            v-for="subnet in subnets"
            :key="subnet.id"
            :value="subnet.id"
            :label="subnet.name">
          </el-option>
        </el-select>
      </el-form-item> -->
        <el-button type="primary" @click="back('formPhysical')">
          Back</el-button
        >
        <el-button type="primary" @click="submit(client)">Add</el-button>
      </el-form>
    </div>
    <div>
      <el-form
        v-if="cloudType === 'Azure'"
        ref="formVM"
        :model="virtualMachine"
        label-position="top"
        :rules="virtualNetworkRules"
      >
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <el-form-item label="Base" prop="baseId">
              <el-select
                v-model="virtualMachine.baseId"
                placeholder="Select the Base"
              >
                <!-- @change="setSubnetsForVM()"> -->
                <el-option
                  v-for="base in bases"
                  :key="base.id"
                  :value="base.id"
                  :label="base.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="Subnet" prop="subnet" :rules="rules.subnet">
            <el-select
              v-model="virtualMachine.subnet"
              placeholder="Select the subnet">
              <el-option
                v-for="subnet in subnets"
                :key="subnet.id"
                :value="subnet.id"
                :label="subnet.name">
              </el-option>
            </el-select>
          </el-form-item> -->
          </el-col>
          <el-col :sm="24" :md="12">
            <virtual-machine-name
              v-model="virtualMachine.name"
              prop="name"
              :baseId="virtualMachine.baseId"
            ></virtual-machine-name>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <server-type-select
              v-model="virtualMachine.type"
              prop="type"
            ></server-type-select>
          </el-col>
          <el-col :sm="24" :md="12">
            <os-select
              v-model="virtualMachine.operatingSystem"
              prop="operatingSystem"
              :disabled="true"
            ></os-select>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <user-name
              v-model="virtualMachine.admin.username"
              prop="admin.username"
            ></user-name>
          </el-col>
          <el-col :sm="24" :md="12">
            <password-input
              v-model="virtualMachine.admin.password"
              prop="admin.password"
              :overrideRule="rules.password"
            ></password-input>
            <password-input
              v-model="virtualMachine.admin.confirmPwd"
              prop="admin.confirmPwd"
              :overrideRule="rules.confirmPwd"
              :overridelabel="overrideLabel"
            ></password-input>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <el-form-item
              label="System Disk Name"
              prop="systemDisk.name"
              :rules="rules.diskname"
            >
              <el-input
                v-model="virtualMachine.systemDisk.name"
                placeholder="Select Disk Name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12">
            <disk-size
              v-model="virtualMachine.systemDisk.sizeInGb"
              prop="systemDisk.sizeInGb"
              disk="System"
            ></disk-size>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="24" :md="12">
            <disk-name
              v-model="virtualMachine.additionalDisk.name"
              prop="additionalDisk.name"
              disk="Additional"
            ></disk-name>
          </el-col>
          <el-col :sm="24" :md="12">
            <disk-size
              v-model="virtualMachine.additionalDisk.sizeInGb"
              prop="additionalDisk.sizeInGb"
              disk="Additional"
            ></disk-size>
          </el-col>
        </el-row>
        <el-button type="primary" @click="back('formVM')">Back</el-button>
        <!-- :disabled="createVMLoading" put down below -->
        <el-button type="primary" @click="submit(client)">Add</el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import Auth from "../../auth";
import _ from "lodash";
import ClientValidation from "./ClientValidation";
import { mapState } from "vuex";
import VirtualMachineName from "../virtualMachines/VirtualMachineName.vue";
import UserName from "../virtualMachines/UserName.vue";
import ServerTypeSelect from "../virtualMachines/ServerTypeSelect.vue";
import OsSelect from "../virtualMachines/OsSelect.vue";
import PasswordInput from "../shared/PasswordInput.vue";
import DiskName from "../virtualMachines/DiskName.vue";
import DiskSize from "../virtualMachines/DiskSize.vue";
import impersonation from "../../impersonation";
import { checkDiskName } from "../../services/virtualMachines";

export default {
  name: "clients-add",
  mixins: [ClientValidation],
  components: {
    VirtualMachineName,
    UserName,
    ServerTypeSelect,
    OsSelect,
    PasswordInput,
    DiskName,
    DiskSize,
  },
  data() {
    let validateClientValId = (rule, value, callback) => {
      let regexp =
        /^[123456789ACEHKMNPRTUWXY]{4}?-[123456789ACEHKMNPRTUWXY]{4}?$/;
      if (
        this.clientMatchModel.clientValidationId === "" ||
        this.clientMatchModel.clientValidationId === null
      ) {
        callback(new Error("Please enter the client validation id"));
      } else {
        if (!this.clientMatchModel.clientValidationId.match(regexp)) {
          callback(
            new Error(
              "The client validation id has to fit the pattern 'xxxx-xxxx' and it only contains from these letters: '123456789ACEHKMNPRTUWXY'"
            )
          );
        } else {
          callback();
        }
      }
    };
    let validatePass = (rule, value, callback) => {
      let username = this.virtualMachine.admin.username;
      let regexp =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.=_+<>#-:,;])[A-Za-z\d@$!%*?&.=_+<>#-:,;]{8,}$/;
      let symbolexp = /[[\]{}()/\\]/;
      if (value === "") {
        callback(new Error("Please input the password"));
      }
      if (value.length < 8) {
        callback(new Error("Password must be minimum 8 characters long"));
      }
      if (username !== "" && value !== "") {
        if (value.includes(username)) {
          callback(new Error("Password must not contain username"));
        }
      }
      if (value.match(symbolexp)) {
        callback(
          new Error(
            "[ ] { } (  ) / \\ characters are not allowed in the password."
          )
        );
      }
      if (!value.match(regexp)) {
        callback(
          new Error(
            "Password must have a digit, uppercase and lowercase letter and one of these characters @$!%*?&.=_+<>#-:,;"
          )
        );
      } else {
        if (this.virtualMachine.admin.confirmPwd !== "") {
          this.$refs.formVM.validateField("admin.confirmPwd");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== this.virtualMachine.admin.password) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    let validatediskname = (rule, value, callback) => {
      let baseId = this.virtualMachine.baseId;
      if (baseId === "" || baseId === undefined) {
        callback(new Error("Please select base first"));
      } else if (value === "") {
        callback(new Error("System disk name is required"));
      } else {
        checkDiskName(
          impersonation.getCustomer().id,
          this.virtualMachine.baseId,
          this.virtualMachine.systemDisk.name
        )
          .then(() => {
            callback();
          })
          .catch(() => {
            callback(new Error("System disk name exists"));
          });
      }
    };
    let validateComputerName = (rule, value, callback) => {
      let regexp = /^[A-Za-z0-9-]*$/;
      this.client.clientName = this.client.clientName.trim();
      this.client.clientName = this.client.clientName.replace(/\s/g, "");
      if (
        this.client.clientName.length > 15 ||
        this.client.clientName.length < 3
      ) {
        callback(
          "The computer name must have minimal 3 and maximal 15 letters"
        );
      } else {
        let regValidation = this.client.clientName.match(regexp);
        if (!regValidation) {
          callback(
            "The computer name only has to have numbers and letters in it"
          );
        } else {
          callback();
        }
      }
    };
    return {
      showErrorMAC: false,
      macAddresses: [],
      showDialog: false,
      type: "",
      cloudType: "",
      overrideLabel: "Confirm Password",
      clientMatchModel: {
        clientValidationId: "",
      },
      client: {
        uuid: "",
        clientName: "",
        description: "",
        baseId: "",
        subnet: "",
        serialNumber: "",
        macAddresses: [],
        timezone: "",
        hash4k: "",
        machineGuid: "",
      },
      virtualMachine: {
        name: "",
        subnetId: "",
        type: "Standard_DS1_v2",
        operatingSystem: "2019-Datacenter",
        admin: {
          username: "",
          password: "",
          confirmPwd: "",
        },
        systemDisk: {
          name: "system",
          sizeInGb: 150,
        },
        additionalDisk: {
          name: "data",
          sizeInGb: 150,
        },
        baseId: "",
        customerId: impersonation.getCustomer().id,
      },
      rules: {
        clientValidationId: [
          { validator: validateClientValId, required: true, trigger: "blur" },
        ],
        password: [
          {
            validator: validatePass,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        confirmPwd: [
          {
            validator: validatePass2,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        diskname: [
          { validator: validatediskname, required: true, trigger: "blur" },
        ],
        name: [
          { validator: validateComputerName, trigger: ["blur", "change"] },
        ],
        uuid: [
          { required: true, message: "Please set the UUID", trigger: "blur" },
        ],
        serialNumber: [
          {
            required: true,
            message: "Please set the Serial Number",
            trigger: "blur",
          },
        ],
        macAddresses: [
          {
            required: true,
            message: "Please select the MAC addresses",
            trigger: "change",
          },
        ],
        clientName: [{ validator: validateComputerName, trigger: "blur" }],
        // baseId: [{required: true, message: 'Please select the base', trigger: 'change'}],
        // subnet: [{required: true, message: 'Please select the subnet', trigger: 'change'}]
      },
      subnets: [],
      timezones: [
        { key: 1, value: "W. Europe Standard Time", label: "Germany" },
        { key: 2, value: "GMT Standard Time", label: "United Kingdom" },
        { key: 3, value: "Central Europe Standard Time", label: "Serbia" },
      ],
    };
  },
  methods: {
    validateMACAddresses() {
      if (this.macAddresses.length === 0) {
        this.showErrorMAC = true;
        return false;
      } else {
        this.showErrorMAC = false;
        return true;
      }
    },
    handleClose(done) {
      if (this.$refs.formPhysical !== undefined) {
        this.$refs.formPhysical.resetFields();
      } else if (this.$refs.formVM !== undefined) {
        this.$refs.formVM.resetFields();
      } else if (this.$refs.chooseCloudForm !== undefined) {
        this.$refs.chooseCloudForm.resetFields();
      } else if (this.$refs.matchClient !== undefined) {
        this.$refs.matchClient.resetFields();
      }
      this.type = "";
      this.cloudType = "";
      this.macAddresses = [];
      done();
    },
    open() {
      this.showDialog = true;
    },
    setSubnetsForVM() {
      var base = this.bases.find((element) => {
        if (element.id === this.virtualMachine.baseId) {
          return element;
        }
      });
      var currentSubnets = base.virtualNetwork.subnets;
      this.subnets = _.filter(currentSubnets, function (o) {
        return o.name !== "gatewaysubnet";
      });
    },
    setSubnetsForPhysical() {
      var base = this.bases.find((element) => {
        if (element.id === this.client.baseId) {
          return element;
        }
      });
      var currentSubnets = base.virtualNetwork.subnets;
      this.subnets = _.filter(currentSubnets, function (o) {
        return o.name !== "gatewaysubnet";
      });
    },
    addMAC() {
      this.macAddresses.push({ key: this.macAddresses.length, value: "" });
    },
    removeMAC() {
      this.macAddresses.splice(this.macAddresses.length - 1, 1);
    },
    submit(client) {
      var userId = Auth.getAuth().id;
      if (this.type === "physical") {
        let validMACAddresses = this.validateMACAddresses();
        this.$refs.formPhysical.validate((valid) => {
          if (valid && validMACAddresses) {
            const clientObj = this.buildClientObj(client);
            var payload = { userId: userId, client: clientObj };
            this.$store.dispatch("addClient", payload);
            this.showDialog = false;
            this.type = "";
            this.$refs.formPhysical.resetFields();
            this.macAddresses = [];
          }
        });
      } else if (this.type === "virtual") {
        this.$refs.formVM.validate((valid) => {
          if (valid) {
            this.$store.dispatch(
              "createVirtualMachine",
              _.cloneDeep(this.virtualMachine)
            );
            this.subnets = [];
            this.type = "";
            this.showDialog = false;
            this.$refs.formVM.resetFields();
            this.$refs.chooseTypeForm.resetFields();
            this.$refs.chooseCloudForm.resetFields();
          }
        });
      }
    },
    submitMatchClient() {
      window.axiosInstance
        .post(
          `customers/${impersonation.getCustomer().id}/clients/match`,
          _.cloneDeep(this.clientMatchModel)
        )
        .then(() => this.$store.dispatch("getClients"))
        .catch((err) => console.log({ err }));
    },
    choseType() {
      if (this.type.length > 0) {
        this.showTypeSelect = false;
      }
    },
    back(formRef) {
      this.$refs[formRef].resetFields();
      this.type = "";
    },
    buildClientObj(client) {
      let result = {
        uuid: "",
        name: "",
        description: "",
        baseId: "",
        subnet: "",
        serialNumber: "",
        macAddresses: [],
        timezone: client.timezone,
        hash4K: "",
        machineGUID: "",
      };
      result.uuid = client.uuid;
      result.name = client.clientName;
      result.description = client.description;
      result.baseId = client.baseId;
      result.subnet = client.subnet;
      result.serialNumber = client.serialNumber;
      result.hash4k = client.hash4k;
      result.machineGuid = client.machineGuid;
      for (let i = 0; i < this.macAddresses.length; i++) {
        result.macAddresses.push(this.macAddresses[i].value);
      }
      return result;
    },
  },
  computed: {
    ...mapState(["clients", "clientLoading", "createVMLoading"]),
    bases() {
      return this.$store.state.base.bases;
    },
  },
};
</script>

<style scoped>
.centered {
  margin: 0 auto;
  text-align: center;
}
</style>
