<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Assign Client Inventory">
    <el-form
      ref="form"
      :model="client"
      :rules="rules">
      <el-form-item prop="clientId">
        <el-select
          v-model="client.clientId"
          placeholder="Please select the client">
          <el-option
            v-for="client in clients"
            :key="client.id"
            :label="client.name"
            :value="client.id">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="submit">
        <el-button type="primary" @click="submit">Assign</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../../impersonation";
import _ from "lodash";

export default {
  name: "assign-client",
  created() {
    this.$store.dispatch("getClients", impersonation.getCustomer().id);
  },
  data() {
    let validateClientId = (rule, value, callback) => {
      if (this.client.clientId.length === 0) {
        callback(new Error("Please select the client to assign"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      client: {
        clientId: "",
        assetId: "",
      },
      rules: {
        clientId: [{ validator: validateClientId, trigger: "change" }],
      },
    };
  },
  methods: {
    openDialog(assetModelId) {
      this.client.assetId = assetModelId;
      this.showDialog = true;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("assignAsset", _.cloneDeep(this.client));
          this.$refs.form.resetFields();
          this.$emit("refresh");
          this.showDialog = false;
        }
      });
    },
  },
  computed: {
    ...mapState(["clients"]),
  },
};
</script>

<style lang="css" scoped>
.submit {
  margin: 0 auto;
  text-align: center;
}
</style>
