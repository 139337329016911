<template>
  <el-form-item label="Domain Name" :prop="prop" placeholder="Select domain" :rules="rules" required>
    <el-input v-model="model" @change="change" class="name-input"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'domain-input',
  props: ['value', 'prop'],
  data () {
    return {
      model: `${this.value.name}.${this.value.tld}`,
      rules: [
        { validator: this.domainName, trigger: 'blur' }
      ]
    }
  },
  methods: {
    change (value) {
      const parts = value.split('.')
      const tld = parts.pop()
      const name = parts.join('.')
      this.$emit('input', {name, tld})
    },
    domainName (rule, value, callback) {
      if (!this.model) {
        callback('Domain name is required')
      }

      if (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(this.model)) {
        callback('Invalid domain name')
        return
      }
      callback()
    }
  }
}
</script>
