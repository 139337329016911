<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Add OS">
    <el-form :model="osModel" ref="form" :rules="rules">
      <el-form-item prop="osName" label="Name">
        <el-input placeholder="Please set the name" v-model="osModel.osName"></el-input>
      </el-form-item>
      <el-form-item prop="vendor" label="Vendor">
        <el-input placeholder="Please set the vendor" v-model="osModel.vendor"></el-input>
      </el-form-item>
      <el-form-item prop="architecture" label="Architecture">
        <el-input placeholder="Please set the architecture" v-model="osModel.architecture"></el-input>
      </el-form-item>
      <el-form-item prop="osType" label="Type">
        <el-input placeholder="Please set the type" v-model="osModel.osType"></el-input>
      </el-form-item>
      <el-form-item prop="version" label="Version">
        <el-input placeholder="Please set the version" v-model="osModel.version"></el-input>
      </el-form-item>
      <el-form-item prop="releaseDate" label="Release Date">
        <el-date-picker
          v-model="osModel.releaseDate"
          placeholder="Pick the release date"
          format="DD.MM.YYYY"
          @change="formatDate('releaseDate')"
          type="date">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="supportEnd" label="Support End Date">
        <el-date-picker
          v-model="osModel.supportEnd"
          placeholder="Pick the support end date"
          format="DD.MM.YYYY"
          @change="formatDate('supportEnd')"
          type="date">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="validModels" label="Hardware Models">
        <el-select multiple v-model="osModel.validModels" placeholder="Select Hardware Models">
          <el-option
            v-for="hardware in hardwareModels"
            :key="hardware.id"
            :label="hardware.name"
            :value="hardware.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="content" label="Content file">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadURL"
          :on-remove="handleRemoveContent"
          :on-success="handleSuccessContent"
          :file-list="content">
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        </el-upload>
      </el-form-item>
      <el-button @click="submit">Add OS</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "add-OS-dialog",
  created() {},
  data() {
    let validateContent = (rule, value, callback) => {
      let vm = this.osModel;
      if (
        vm.content === null ||
        vm.content.id === "" ||
        vm.content.name === ""
      ) {
        callback(new Error("Please add a file"));
      } else {
        callback();
      }
    };
    let validateValidModels = (rule, value, callback) => {
      let vm = this.osModel;
      if (vm.validModels === null || vm.validModels.length === 0) {
        callback(new Error("Please select min one hardware model"));
      } else {
        callback();
      }
    };
    return {
      uploadURL:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/tasks/upload/releaseMgmt",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      showDialog: false,
      osModel: {
        vendor: "",
        osName: "",
        architecture: "",
        osType: "",
        version: "",
        releaseDate: "",
        supportEnd: "",
        content: {},
        validModels: [],
      },
      content: [],
      rules: {
        vendor: [
          { required: true, message: "Please set the vendor", trigger: "blur" },
        ],
        osName: [
          { required: true, message: "Please set the name", trigger: "blur" },
        ],
        architecture: [
          {
            required: true,
            message: "Please set the architecture",
            trigger: "blur",
          },
        ],
        osType: [
          {
            required: true,
            message: "Please set the OS type",
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: "Please set the version",
            trigger: "blur",
          },
        ],
        releaseDate: [
          {
            required: true,
            message: "The release date has to be set",
            trigger: "blur",
          },
        ],
        // supportEnd: [{required: true, message: 'The release date has to be set', trigger: 'blur'}],
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        validModels: [
          { validator: validateValidModels, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  methods: {
    handleRemoveContent(a, fileList) {
      let fileName = a.name;
      this.$store.dispatch("removeReleaseMgmtFile", fileName);
      this.setContent(fileList);
    },
    handleSuccessContent(a, b, fileList) {
      this.setContent(fileList);
    },
    setContent(file) {
      if (file.length > 0) {
        this.osModel.content = {
          id: file[0].response.id,
          name: file[0].response.name,
        };
      } else {
        this.osModel.content = { id: "", name: "" };
      }
    },
    openDialog() {
      this.showDialog = true;
      console.log("PROCESS ENV: ", process.env);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var temp = _.cloneDeep(this.osModel);
          console.log({ temp });
          this.$store.dispatch("addOSModel", _.cloneDeep(this.osModel));
        }
      });
    },
    formatDate(name) {
      if (name === "releaseDate") {
        let date = new Date(Date.parse(this.osModel.releaseDate));
        date = new Date(date - date.getTimezoneOffset() * 60000);
        this.osModel.releaseDate = date.toUTCString();
      } else if (name === "supportEnd") {
        let date = new Date(Date.parse(this.osModel.supportEnd));
        date = new Date(date - date.getTimezoneOffset() * 60000);
        this.osModel.supportEnd = date.toUTCString();
      }
    },
  },
  computed: {
    hardwareModels() {
      return this.$store.state.hardwareModels;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
