export default{
  name: 'certificate-validation',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input certificate name', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' }
        ]
      }
    }
  }
}
