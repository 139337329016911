export function addSoftwareStream(data) {
  return window.axiosInstance.post('/softwareStreams', data)
}

export function getSoftwareStreams() {
  return window.axiosInstance.get(`/softwareStreams`)
}

export function editSoftwareStream(data) {
  return window.axiosInstance.post('/softwareStreams/edit', data)
}

export function setSoftwaresToStream(streamId, data) {
  return window.axiosInstance.post(`/softwareStreams/${streamId}`, data)
}

export function deleteSoftwareStream(streamId) {
  return window.axiosInstance.delete(`softwareStreams/${streamId}`)
}

export function uniqueStreamName(streamName) {
  return window.axiosInstance.get(`/softwareStreams/${streamName}`)
}

export function uniqueStreamNameEdit(streamName, streamId) {
  return window.axiosInstance.get(`/softwareStreams/edit/${streamName}/${streamId}`)
}

export function getIconRef(streamId) {
  return window.axiosInstance.get(`/softwareStreams/getIconRef/${streamId}`)
}
