<template>
  <el-dialog
    title="Add Admin Device Option"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      :model="adminDeviceOption"
      :rules="rules"
      label-width="180px"
      ref="form"
    >
      <el-form-item label="Name" prop="name">
        <el-input
          placeholder="Device Option name"
          v-model="adminDeviceOption.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          placeholder="Device Option description"
          v-model="adminDeviceOption.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Os Type" prop="osType">
        <el-select v-model="adminDeviceOption.osType">
          <el-option
            v-for="os in osTypes"
            :label="os.value"
            :key="os.key"
            :value="os.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="type" label="Type">
        <el-select v-model="adminDeviceOption.type">
          <el-option
            v-for="t in types"
            :key="t.id"
            :value="t.value"
            :label="t.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PE only" prop="peOnly">
        <el-checkbox v-model="adminDeviceOption.peOnly"></el-checkbox>
      </el-form-item>
      <el-form-item label="Script" prop="content">
        <div class="editor-container">
          <prism-editor
            class="my-editor height-300"
            v-model="adminDeviceOption.content"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
        <!-- <drop @drop="handleDrop" class="drop">
          <el-input
            :autosize="{ minRows: 5 }"
            id="optionadd"
            ref="optionadd"
            type="textarea"
            v-model="adminDeviceOption.content"
            wrap="off"
            >{{}}
          </el-input>
        </drop> -->
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button @click="submit" type="primary">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import Codemirror from "../../../static/codemirror/lib/codemirror";
import _ from "lodash";

import { PrismEditor } from "vue-prism-editor";

import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-powershell";
// require("../../../static/codemirror/addon/edit/matchbrackets");
// require("../../../static/codemirror/mode/powershell/powershell");
// require("../../../static/codemirror/doc/docs.css");
// require("../../../static/codemirror/lib/codemirror.css");
// require("../../../static/codemirror/theme/monokai.css");

export default {
  components: { PrismEditor },
  data() {
    let validateContent = (rule, value, callback) => {
      let content = this.adminDeviceOption.content;
      console.log({ content });
      if (content === "# Powershell Script" || content === "") {
        callback(new Error("Please add a script"));
      } else {
        callback();
      }
    };
    return {
      osTypes: [
        { key: 1, value: "Windows" },
        { key: 2, value: "Ubuntu" },
        { key: 3, value: "RedHat" },
      ],
      showDialog: false,
      types: [{ id: 1, value: "Installation" }],
      adminDeviceOption: {
        name: "",
        description: "",
        content: "",
        attachments: [],
        type: "",
        peOnly: false,
        osType: "",
      },
      editor: "",
      rules: {
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: "Please set a name for the option",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please set the type of the option",
            trigger: "change",
          },
        ],
        osType: [
          {
            required: true,
            message: "Please select the OS type",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js, "powershell");
    },
    handleDrop(data, e) {
      let vm = this;
      e.preventDefault();
      let reader = new window.FileReader();
      let files = e.target.files || e.dataTransfer.files;
      reader.onload = function () {
        vm.adminDeviceOption.content = reader.result;
      };
      reader.readAsText(files.item(0));
    },
    openDialog() {
      let vm = this;
      vm.adminDeviceOption.content = "# Powershell Script";
      vm.$nextTick(() => {
        if (document.getElementsByClassName("CodeMirror").length === 0) {
          // console.log({ Codemirror });
          // vm.editor = Codemirror.fromTextArea(
          //   document.getElementById("optionadd"),
          //   {
          //     mode: "powershell",
          //     lineNumbers: true,
          //     theme: "monokai",
          //     indentUnit: 4,
          //     tabMode: "shift",
          //     matchBrackets: true,
          //   }
          // );
        }
      });
      this.showDialog = true;
    },
    handleClose() {
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = _.cloneDeep(this.adminDeviceOption);
          this.$store.dispatch("addAdminOption", data);
          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.editor-container {
  width: 50vh;
  height: 50vh;
  border: 1px solid blue;
}
</style>
