<template>
  <div>
    <under-construction></under-construction>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input placeholder="Find workflow" v-model="search" autofocus>
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredWorkflows.length }} of {{ workflows.length }}
            </div>
          </el-col>
        </el-row>
        <el-table :data="filteredWorkflows" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable width="200">
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
          </el-table-column>
          <el-table-column width="150">
            <template>
              <el-button type="primary">Order Now</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UnderConstruction from "../shared/UnderConstrction";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";

export default {
  components: { UnderConstruction },
  name: "workflows",
  created() {
    this.$store.dispatch("getWorkflows");
  },
  mixins: [FilterMixin],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState(["workflows"]),
    filteredWorkflows() {
      return this.filterEntities(this.workflows, this.search);
    },
    isSubset() {
      return this.workflows.length !== this.filteredWorkflows.length;
    },
  },
};
</script>
