<template lang="html">
  <el-dialog
    v-model="showDialog"
    title="Add Vendor Model">
    <el-form
      :model="vendorModel"
      :rules="rules"
      ref="form">
      <el-form-item
        prop="name"
        label="Name">
        <el-input v-model="vendorModel.name" placeholder="Please set the name"></el-input>
      </el-form-item>
      <el-form-item
        prop="modelFamily"
        label="Model Family">
        <el-input v-model="vendorModel.modelFamily" placeholder="Please set the model family"></el-input>
      </el-form-item>
      <el-form-item
        prop="modelType"
        label="Model Type">
        <el-input v-model="vendorModel.modelType" placeholder="Please set the model type"></el-input>
      </el-form-item>
      <el-form-item
        prop="files"
        label="Attachements">
        <el-upload
          class="upload-demo"
          drag
          multiple
          :headers="headers"
          :action="uploadURL"
          :on-remove="handleRemoveFile"
          :on-success="handleSuccessFile"
          :file-list="vendorModel.files">
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        </el-upload>
      </el-form-item>
      <div style="margin: 0 auto; text-align: center">
        <el-button type="primary" @click="submit">Add Vendor Model</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "addVendorModel",
  data() {
    let validateName = (rule, value, callback) => {
      if (this.vendorModel.name === "") {
        callback(new Error("Please set the name"));
      } else {
        callback();
      }
    };
    let validateModelFamily = (rule, value, callback) => {
      if (this.vendorModel.modelFamily === "") {
        callback(new Error("Please set the model family"));
      } else {
        callback();
      }
    };
    let validateModelType = (rule, value, callback) => {
      if (this.vendorModel.modelType === "") {
        callback(new Error("Please set the model type"));
      } else {
        callback();
      }
    };
    let validateFiles = (rule, value, callback) => {
      if (this.vendorModel.files.length === 0) {
        callback(new Error("Please upload at least one file"));
      } else {
        callback();
      }
    };
    return {
      uploadURL:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/tasks/upload/releaseMgmt",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      showDialog: false,
      vendorModel: {
        name: "",
        modelFamily: "",
        modelType: "",
        files: [],
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        modelFamily: [{ validator: validateModelFamily, trigger: "blur" }],
        modelType: [{ validator: validateModelType, trigger: "blur" }],
        files: [{ validator: validateFiles, trigger: "change" }],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    handleRemoveFile(a, fileList) {
      let fileName = a.name;
      this.$store.dispatch("removeReleaseMgmtFile", fileName);
      this.setFileList(fileList);
    },
    handleSuccessFile(a, b, fileList) {
      this.setFile(fileList);
    },
    setFile(file) {
      if (file.length > 0) {
        this.vendorModel.files.push({
          id: file[file.length - 1].response.id,
          name: file[file.length - 1].response.name,
        });
      }
    },
    setFileList(fileList) {
      this.vendorModel.files = [];
      fileList.forEach((x) => {
        this.vendorModel.files.push({ id: x.id, name: x.name });
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("addVendorModel", _.cloneDeep(this.vendorModel));
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
