<template>
  <div class="container">
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <h3 style="text-align: center">Software Streams</h3>
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find software stream"
              v-model="searchSoftwareStreams"
              autofocus
            >
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubsetSoftwareStreams"
                @click="clearSearchSoftwareStreams"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results">
              showing {{ filteredSoftwareStreams.length }} of
              {{ softwareStreams.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <!-- icon="el-icon-plus" -->
            <el-button type="primary" @click="openAddSoftwareStream"
              >Add Software Stream</el-button
            >
          </el-col>
        </el-row>
        <div class="tableContainer">
          <el-table :data="filteredSoftwareStreams" highlight-current-row>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column
              prop="architecture"
              label="Architecture"
            ></el-table-column>
            <el-table-column prop="vendor" label="Vendor"></el-table-column>
            <el-table-column prop="language" label="Language"></el-table-column>
            <el-table-column
              prop="descriptionShort"
              label="Description"
            ></el-table-column>
            <el-table-column prop="icon" label="Icon">
              <template v-slot="scope">
                <img
                  v-if="
                    scope.row.icon !== null &&
                    scope.row.icon.id !== '' &&
                    scope.row.icon.name != ''
                  "
                  :src="scope.row.icon"
                  height="40"
                  width="40"
                />
                <img
                  v-else
                  src="../../../static/packages-icon-21.png"
                  height="40"
                  width="40"
                />
              </template>
            </el-table-column>
            <el-table-column prop="latestWebVersion" label="Version Status">
              <template v-slot="scope">
                <div style="display: flex; align-items: center">
                  <el-icon
                    class="iconTable"
                    :size="25"
                    v-if="
                      scope.row.latestWebVersion === null ||
                      scope.row.latestWebVersion === ''
                    "
                  >
                    <QuestionFilled />
                  </el-icon>
                  <el-icon
                    class="iconTable"
                    :size="25"
                    color="#67C23A"
                    v-else-if="compareVersionNumber(scope.row) === 0"
                    ><CircleCheckFilled
                  /></el-icon>
                  <el-icon
                    class="iconTable"
                    :size="25"
                    color="#E6A23C"
                    v-else-if="compareVersionNumber(scope.row) === 1"
                    ><InfoFilled
                  /></el-icon>
                  <span>{{ scope.row.latestWebVersion }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="downloadLink"
              label="Download Link"
            >
              <template v-slot="scope">
                <el-icon
                  :size="30"
                  style="vertical-align: middle"
                  v-if="
                    scope.row.downloadLink !== '' &&
                    scope.row.downloadLink != null
                      ? true
                      : false
                  "
                  @click="openDownloadLink(scope.row.downloadLink)"
                  ><Download
                /></el-icon>
                <!-- <a :href="scope.row.downloadLink" target="_blank">{{
                  scope.row.downloadLink
                }}</a> -->
              </template>
            </el-table-column>
            <el-table-column label="Actions" width="90">
              <template v-slot="scope">
                <actions-dropdown
                  :hideRetreatFromShop="true"
                  :entity="scope.row"
                  :deleteItem="true"
                  :edit="true"
                  :manageSoftware="true"
                  @delete="deleteSoftwareStream"
                  @edit="openEditSoftwareStream"
                  @manageSoftware="openManageSoftware"
                >
                </actions-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <software-add ref="softwareAdd" @refresh="refresh()"></software-add>
    <software-edit ref="softwareEdit" @refresh="refresh()"></software-edit>
    <add-rule ref="ruleAdd" @refresh="refresh()"></add-rule>
    <edit-rule ref="ruleEdit" @refresh="refresh()"></edit-rule>
    <import-software ref="importSoftware"></import-software>
    <add-sw-stream ref="addSoftwareStream"></add-sw-stream>
    <edit-sw-stream ref="editSoftwareStream"></edit-sw-stream>
    <manage-software ref="manageSoftware"></manage-software>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import SoftwareAdd from "./SoftwareAdd.vue";
import SoftwareEdit from "./SoftwareEdit.vue";
import ImportSoftware from "./ImportSoftware";
import AddRule from "../rules/AddRule";
import EditRule from "../rules/EditRule";
import { getSoftwareJsonFile } from "../../services/softwares";
import FileSaver from "file-saver";
import AddSoftwareStream from "../softwareStream/AddSoftwareStream.vue";
import EditSoftwareStream from "../softwareStream/EditSoftwareStream.vue";
import ManageSoftware from "../softwareStream/ManageSoftware.vue";
import { compareVersions, validate } from "compare-versions";

export default {
  name: "softwares",
  mixins: [FilterMixin],
  beforeCreate() {},
  created() {
    this.$store.dispatch("getSystemhouses");
    this.$store.dispatch("getSoftwares");
    this.$store.dispatch("getRules");
    this.$store.dispatch("getSoftwareStreams");
  },
  components: {
    ActionsDropdown,
    SoftwareAdd,
    SoftwareEdit,
    AddRule,
    EditRule,
    ImportSoftware,
    "add-sw-stream": AddSoftwareStream,
    "edit-sw-stream": EditSoftwareStream,
    "manage-software": ManageSoftware,
  },
  data() {
    return {
      searchRules: "",
      searchDeviceOptions: "",
      searchSoftwareStreams: "",
      versionId: {},
      searchDrivers: "",
    };
  },
  methods: {
    compareVersionNumber(stream) {
      var latestActiveSWVersion = this.getLatestActiveSWVersion(stream.id);
      var latestWebVersion = stream.latestWebVersion;
      if (
        latestActiveSWVersion !== null &&
        latestActiveSWVersion !== "" &&
        latestWebVersion !== null &&
        latestWebVersion !== ""
      ) {
        if (latestActiveSWVersion === "no active version") {
          return 1;
        }
        if (validate(latestWebVersion) && validate(latestActiveSWVersion)) {
          return compareVersions(latestWebVersion, latestActiveSWVersion);
        } else {
          return NaN;
        }
      } else {
        return NaN;
      }
    },
    getLatestActiveSWVersion(swStreamId) {
      var result = "no active version";
      let stream = this.softwareStreams.find((el) => {
        return el.id === swStreamId;
      });
      let activeVersions = stream.streamMembers.filter(
        (x) => x.status === "Active"
      );
      if (activeVersions.length === 1) {
        result = activeVersions[0].version;
      } else {
        let activeVersion = 0;
        for (let i = 0; i < activeVersions.length; i++) {
          if (activeVersion === 0) {
            activeVersion = activeVersions[i].version;
          } else {
            let version1 = parseInt(activeVersion.replaceAll(".", ""));
            let version2 = parseInt(
              activeVersions[i].version.replaceAll(".", "")
            );
            if (version1 < version2) {
              activeVersion = activeVersions[i].version;
            }
          }
        }
        if (activeVersion !== 0) {
          result = activeVersion;
        }
      }
      return result;
    },
    openDownloadLink(link) {
      window.open(link, "_blank").focus();
    },
    downloadIcon(value) {
      let currentIcon = null;
      if (value !== null) {
        this.$store.dispatch("fetchSoftwareIcon", value.id);
        return currentIcon;
      }
    },
    openManageSoftware(stream) {
      this.$refs.manageSoftware.openDialog(stream);
    },
    openEditSoftwareStream(stream) {
      this.$refs.editSoftwareStream.openDialog(stream);
    },
    openAddSoftwareStream() {
      this.$refs.addSoftwareStream.openDialog();
    },
    deleteSoftwareStream(stream) {
      this.$confirm(
        "This will permanently delete the stream and remove the stream from the software packages. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteStreamSuccess.bind(this, stream), this.deleteError);
    },
    deleteStreamSuccess(stream) {
      this.$store.dispatch("deleteSoftwareStream", stream.id);
    },
    importSoftware() {
      this.$refs.importSoftware.openDialog();
    },
    exportSoftware(software) {
      getSoftwareJsonFile(software.id).then((response) => {
        let blob = response.data;
        var fileName = software.name.split(" ").join("_");
        fileName += "_" + software.version;
        FileSaver.saveAs(blob, fileName + ".json", true);
      });
    },
    openRuleEdit(rule) {
      this.$refs.ruleEdit.openDialog(rule);
    },
    refresh() {
      this.$router.go();
    },
    openSoftwareAdd() {
      this.$refs.softwareAdd.open(null, this.stream.id, this.stream.type);
    },
    async openSoftwareEdit(software) {
      await this.$refs.softwareEdit.open(
        software.id,
        this.stream.id,
        this.stream.type
      );
    },
    openAddRuleDialog() {
      this.$refs.ruleAdd.openDialog();
    },
    confirmDelete(software) {
      this.$confirm(
        "This will permanently delete the software. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, software), this.deleteError);
    },
    deleteSuccess(software) {
      this.$store.dispatch("deleteSoftware", software);
    },
    confirmDeleteRule(rule) {
      this.$confirm(
        "This will permanently delete the rule. Remember this may effect some software, too. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteRuleSuccess.bind(this, rule), this.deleteError);
    },
    deleteRuleSuccess(rule) {
      this.$store.dispatch("deleteRule", rule.id);
    },
    deleteError() {},
    clearSearchRules() {
      this.searchRules = "";
    },
    clearSearchSoftwareStreams() {
      this.searchSoftwareStreams = "";
    },
  },
  computed: {
    ...mapState([
      "softwares",
      "softwaresLoading",
      "softwareIcon",
      "rules",
      "rulesLoading",
      "softwareStreams",
    ]),
    filteredSoftwares() {
      let temp = this.softwares.filter((x) => x.Type === "Software");
      return this.filterEntities(temp, this.search);
    },
    isSubset() {
      return this.softwares.length !== this.filteredSoftwares.length;
    },
    filteredRules() {
      return this.filterEntities(this.rules, this.searchRules);
    },
    isSubsetRules() {
      return this.rules.length !== this.filteredRules.length;
    },
    filteredSoftwareStreams() {
      let result = [];
      for (let i = 0; i < this.softwareStreams.length; i++) {
        if (this.softwareStreams[i].type === "Software") {
          result.push(this.softwareStreams[i]);
        }
      }
      return this.filterEntities(result, this.searchSoftwareStreams);
    },
    isSubsetSoftwareStreams() {
      return (
        this.softwareStreams.length !== this.filteredSoftwareStreams.length
      );
    },
    isSubsetTasks() {
      return this.softwareStreams.length !== this.filteredTasks.length;
    },
    isSubsetImages() {
      return this.swImages.length !== this.filteredSWImages.length;
    },
    filteredSWImages() {
      return this.filterEntities(this.swImages, this.searchSWImages);
    },
    filteredDrivers() {
      return this.filterEntities(this.drivers, this.searchDrivers);
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  padding-bottom: 50px;
}

.iconTable {
  margin-right: 10px;
}
</style>
