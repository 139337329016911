<template>
  <el-dialog
    title="Edit Driver"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form ref="form" :rules="rules" :model="driver">
      <el-form-item label="Name" prop="name">
        <el-input
          placeholder="Please set the name"
          v-model="driver.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          placeholder="Please set the description"
          v-model="driver.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="Connection String" prop="connectionString">
        <el-input
          placeholder="Please set the connectionString"
          v-model="driver.connectionString"
        ></el-input>
      </el-form-item>
      <el-form-item label="Container Name" prop="containerName">
        <el-input
          placeholder="Please set the container name"
          v-model="driver.containerName"
        ></el-input>
      </el-form-item>
      <el-form-item label="Subfolder Path" prop="subFolderPath">
        <el-input
          placeholder="Please set the path to the subfolder"
          v-model="driver.subFolderPath"
        ></el-input>
      </el-form-item>
      <el-form-item label="Version" prop="version">
        <el-input v-model="driver.version" placeholder="Version"></el-input>
      </el-form-item>
      <el-form-item label="Vendor" prop="vendor">
        <el-input placeholder="Vendor" v-model="driver.vendor"></el-input>
      </el-form-item>
      <el-button type="primary" @click="submit">Save</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    let validateVersion = (rule, value, callback) => {
      let exp = /^\d+/;
      let array = this.driver.version.split(".");
      if (array.length === 4) {
        for (let i = 0; i < 4; i++) {
          // eslint-disable-next-line no-empty
          if (exp.test(array[i])) {
          } else {
            callback(
              "Please set the version number with 4 digits like 'x.x.x.x'"
            );
            break;
          }
        }
        callback();
      } else {
        callback("The version number has to have 4 digits");
      }
    };
    return {
      showDialog: false,
      driver: {
        id: "",
        name: "",
        description: "",
        subFolderPath: "",
        connectionString: "",
        version: "",
        vendor: "",
      },
      // swArchitectures: [
      //   {key: 1, value: 'x64'},
      //   {key: 2, value: 'x86'},
      //   {key: 3, value: 'x86 + x64'}
      // ],
      // languages: [
      //   {key: 1, value: 'not defined'},
      //   {key: 2, value: 'MUI (multi user interface)'},
      //   {key: 3, value: 'German (de-DE)'},
      //   {key: 4, value: 'English (en-US)'}
      // ],
      rules: {
        name: [
          {
            required: true,
            message: "Please set the name of the driver",
            trigger: "blur",
          },
        ],
        connectionString: [
          {
            required: true,
            message:
              "Please set the connection string of the storage account in Azure",
            trigger: "blur",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please set the architecture for the driver",
            trigger: "change",
          },
        ],
        version: [
          { validator: validateVersion, required: true, trigger: "blur" },
        ],
        fileName: [
          {
            required: true,
            message: "Please set the file name of the driver",
            trigger: "blur",
          },
        ],
        subFolderPath: [
          {
            required: true,
            message: "Please set the path to the subfolder",
            trigger: "blur",
          },
        ],
        containerName: [
          {
            required: true,
            message: "Please set the container name of the Azure container",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog(driver) {
      this.driver = driver;
      this.showDialog = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editDriver", _.cloneDeep(this.driver));
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
