export function getUsers () {
  return window.axiosInstance.get('/users')
}

export function addUser (user) {
  return window.axiosInstance.post(`/users`, user)
}

export function editUser (user) {
  return window.axiosInstance.put(`/users/${user.id}`, user)
}

export function deleteUser (user) {
  return window.axiosInstance.delete(`/users/${user.id}`)
}

export async function emailExists (email) {
  return window.axiosInstance.get(`/users/${email}`)
}

export function reportBug (payload) {
  return window.axiosInstance.post('/users/bugReport', payload)
}

export function resetPassword (email) {
  return window.axiosInstance.get(`auth/resetPassword/${email}`)
}
