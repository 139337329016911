export default{
  name: 'task-validation',
  data () {
    let validateData = (rule, value, callback) => {
      let vm = this.value
      if (vm.data === null || vm.data.length === 0) {
        callback(new Error('Please upload a file'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        name: [
          {required: true, message: 'Please input a rule name', trigger: 'blur'},
          { min: 3, message: 'Name should at least have 3 chars', trigger: ['blur', 'change'] }
        ],
        data: [
          { validator: validateData, type: 'array', required: true, trigger: ['blur', 'change'] }
        ],
        type: [
          {required: true, message: 'Please select the type', trigger: ['change', 'blur']},
          { min: 3, message: 'Name should at least have 3 chars', trigger: ['blur', 'change'] }
        ],
        architecture: [
          { min: 3, message: 'Name should at least have 3 chars', trigger: ['blur', 'change'] }
        ],
        path: [
          {required: true, message: 'Please set the path', trigger: 'blur'}
        ]
      }
    }
  }
}
