<template>
  <div>
    <el-row>
      <br />
      <el-col :span="22" :offset="1">
        <el-row type="flex" class="header">
          <el-col class="bs-align-left" :span="8">
            <div style="white-space: nowrap">
              <label>Asset Management</label>
            </div>
          </el-col>
          <el-col class="bs-align-left" :span="8">
            <div style="white-space: nowrap">
              <label></label>
            </div>
          </el-col>
          <el-col class="bs-align-right" :span="8">
            <div class="flex">
              <el-button type="primary" class="button_conf" @click="createLabel"
                >Create Label</el-button
              >
              <el-button type="primary" class="button_conf" @click="exportAsCSV"
                >Export Selection</el-button
              >
            </div>
          </el-col>
        </el-row>
        <!-- <p><hr style="height:2px"/></p> -->
        <template>
          <el-table
            :data="filteredAssets"
            @row-click="openAssetManagementDetails"
            ref="multipleSelectTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="Asset-ID" sortable prop="assetID">
            </el-table-column>
            <el-table-column
              label="Asset Status"
              prop="assetStatus"
            ></el-table-column>
            <el-table-column label="Asset-Type" sortable prop="assetType">
              <template v-slot="scope">
                {{
                  scope.row.assetType === null ? "//" : scope.row.assetType.name
                }}
              </template>
            </el-table-column>
            <el-table-column label="Asset Class" prop="assetClass">
              <template v-slot="scope">
                {{
                  scope.row.assetClass === null
                    ? "//"
                    : scope.row.assetClass.name
                }}
              </template>
            </el-table-column>
            <el-table-column label="Device-Name" prop="client" sortable>
              <template v-slot="scope">
                {{ scope.row.client === null ? "//" : scope.row.client.name }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
import _ from "lodash";

export default {
  name: "asset-management",
  created() {
    this.$store.dispatch("getAssetLabels");
  },
  components: {},
  data() {
    return {
      name: "",
      search: "",
      multipleSelection: [],
    };
  },
  methods: {
    createLabel() {
      this.$router.push({ name: "asset-label" });
    },
    openAssetManagementDetails(entity) {
      this.$router.push({
        name: "assetmanagementdetails",
        params: { id: entity.id },
      });
    },
    deleteSuccess() {},
    deleteError() {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportAsCSV() {
      if (this.multipleSelection.length === 0) {
        ElMessage.error({
          message: "Action denied: Please select at least one Asset Label!",
          showClose: true,
        });
      } else {
        this.$store.dispatch(
          "exportAssetSelectionToCSV",
          _.cloneDeep(this.multipleSelection)
        );
      }
    },
  },
  computed: {
    ...mapState(["customers", "assetLabels"]),
    filteredAssets() {
      let result = [];
      let id = impersonation.getCustomer().id;
      // let assetModels = this.$store.state.assetLabels
      this.assetLabels.forEach((el) => {
        if (el.customerId === id) {
          result.push(el);
        }
      });
      return result;
    },
  },
};
</script>
<style scoped>
</style>
