export function getAdminOptions () {
  return window.axiosInstance.get('admin-device-option')
}

export function addAdminOption (payload) {
  return window.axiosInstance.post('admin-device-option', payload)
}

export function checkOptionName (optionName) {
  return window.axiosInstance.put('admin-device-option', optionName)
}

export function getAdminScriptVersion (scriptId) {
  return window.axiosInstance.get(`admin-device-option/${scriptId}`)
}

export function addAdminScriptVersion (scriptId, payload) {
  return window.axiosInstance.post(`admin-device-option/${scriptId}/versions`, payload)
}

export function deleteAdminOption (optionId) {
  return window.axiosInstance.delete(`admin-device-option/${optionId}`)
}

export function editAdminOption (payload) {
  return window.axiosInstance.post('admin-device-option/edit', payload)
}
