<template lang="html">
  <div class="">
    <el-row>
      <el-col :span="22" :offset="1">
        <el-dialog
          v-model="showEditDialog"
          ref="editParam"
          title="Edit Parameter">
          <h3>Corporate Parameters</h3>
          <el-form :model="paramToEdit">
            <el-form-item label="Name">
              <el-input v-model="paramToEdit.key"></el-input>
            </el-form-item>
            <el-form-item label="Value">
              <el-input v-model="paramToEdit.value"></el-input>
            </el-form-item>
            <div v-if="errorsEdit.length > 0">
              <div class="flex" v-for="(error, index) in errorsEdit" :key="index" >
                <p class="nospace error">{{error}}</p>
              </div>
            </div>            
            <el-button @click="editParameter" type="primary">Edit</el-button>
          </el-form>
        </el-dialog>
        <h1>Corporate Parameters Details</h1>
        <h3>Corporate Parameters</h3>
        <el-row>
          <el-col :span="22">
            <el-row class="header" type="flex">
              <el-col :span="8" class="search">
                <el-input placeholder="Find parameter" v-model="searchFixed" prefix-icon="Search">
                  <template #suffix>
                    <el-icon v-if="isSubsetFix" @click="clearSearchFixed">
                      <CircleClose />
                    </el-icon>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <div class="results" v-if="isSubsetFix">
                  showing {{filteredParametersFix.length}} of {{ParamsFix.length}}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-table :data="filteredParametersFix">
          <el-table-column label="Parameter Name" prop="key" sortable>
          </el-table-column>
          <el-table-column label="Value" prop="value" sortable>
            <template v-slot="scope">
              <span v-if="scope.row.key === '$LtSASwrite'">
                {{scope.row.key === '$LtSASwrite' ? (scope.row.value.length === 0 ? 'Value Not Set' : 'Value Set') : scope.row.value}}
              </span>
              <span v-else-if="scope.row.key === '$LtSASread'">
                {{scope.row.key === '$LtSASread' ? (scope.row.value.length === 0 ? 'Value Not Set' : 'Value Set') : scope.row.value}}
              </span>
              <span v-else>
                {{scope.row.key === '$clientSecret' ? (scope.row.value.length === 0 ? 'Value Not Set' : 'Value Set') : scope.row.value}}
              </span>
            </template>
          </el-table-column>
        </el-table>

        <h3>Custom Corporate Parameters</h3>
        <el-row>
          <el-col :span="22">
            <el-row class="header" type="flex">
              <el-col :span="8" class="search">
                <el-input placeholder="Find custom parameter" v-model="searchCustom" prefix-icon="Search">
                  <template #suffix>
  <el-icon v-if="isSubsetCustom" @click="clearSearchCustom">
    <CircleClose />
  </el-icon>
</template>                  
                </el-input>
              </el-col>
              <el-col :offset="1" :span="8">
                <div class="results" v-if="isSubsetCustom">
                  showing {{filteredCustomEntites.length}} of {{ParamsCustom.length}}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div class="flex" style="margin-bottom: 2rem">
          <el-input class="space-right" placeholder="Parameter name" v-model="parameterModel.key"></el-input>
          <el-input class="space-right" placeholder="Parameter value" v-model="parameterModel.value"></el-input>
          <el-button @click="addParameter" size="small" type="primary">
            <el-icon>
              <Plus />
            </el-icon>
            Add Parameter
          </el-button>
        </div>
        <div v-if="errors.length > 0">
          <div class="flex" v-for="(error, index) in errors" :key="index">
            <p class="nospace error">{{error}}</p>
          </div>
        </div>
        <br/>
        <el-table :data="filteredCustomEntites">
          <el-table-column label="Name" prop="key" sortable>
          </el-table-column>
          <el-table-column label="Value" prop="value" sortable></el-table-column>
          <el-table-column label="Actions" width="110">
            <template v-slot="scope">
  <actions-dropdown
    :entity="scope.row"
    :edit="true"
    :deleteItem="true"
    @edit="editCustomParam"
    @delete="deleteCustomParam"
    :hideRetreatFromShop="true"
  ></actions-dropdown>
</template>
          </el-table-column>
        </el-table>
        <br/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import FilterMixin from "../../mixins/FilterMixin";
import impersonation from "../../impersonation";
import _ from "lodash";

export default {
  name: "client-parameters",
  components: {
    ActionsDropdown,
  },
  mixins: [FilterMixin],
  created() {
    this.customerId = this.$route.params.id;
    this.$store.dispatch("getCustomer", this.customerId);
    this.errors = [];
  },
  data() {
    return {
      showDialog: false,
      customerId: "",
      searchFixed: "",
      searchCustom: "",
      errors: [],
      parameterModel: {
        key: "",
        value: "",
      },
      showEditDialog: false,
      paramToEdit: {
        key: "",
        value: "",
        id: "",
      },
      formerParamName: "",
      errorsEdit: [],
    };
  },
  methods: {
    clearSearchCustom() {
      this.searchCustom = "";
    },
    clearSearchFixed() {
      this.searchFixed = "";
    },
    addParameter() {
      if (this.validateInput()) {
        let data = {
          parameter: {
            key: String.trim(this.parameterModel.key),
            value: String.trim(this.parameterModel.value),
          },
          customerId: this.customerId,
        };
        this.$store.dispatch("addCustomCorporateParameter", data);
        this.parameterModel.key = "";
        this.parameterModel.value = "";
      }
    },
    validateInput() {
      let specialCharRegex = /[!@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errors = [];
      if (this.parameterModel.key && this.parameterModel.value) {
        if (this.parameterModel.key.startsWith("$")) {
          if (!specialCharRegex.test(this.parameterModel.key)) {
            for (let i = 0; i < this.Parameters.length; i++) {
              if (this.parameterModel.key === this.Parameters[i].key) {
                this.errors.push(
                  "There already exists a parameter with this name! Please rename the new parameter!"
                );
                return false;
              }
            }
          } else {
            this.errors.push(
              "The name must not contain special characters or spaces!"
            );
            return false;
          }
        } else {
          this.errors.push("The name must start with a dollar sign!");
          return false;
        }
        return true;
      } else {
        if (!this.parameterModel.key) {
          this.errors.push("You must enter a parameter name");
        }
        if (!this.parameterModel.value) {
          this.errors.push("You must enter a parameter value");
        }
        return false;
      }
    },
    editCustomParam(parameter) {
      this.paramToEdit.key = parameter.key;
      this.paramToEdit.value = parameter.value;
      this.paramToEdit.id = parameter.id;
      this.formerParamName = parameter.key;
      this.showEditDialog = true;
    },
    deleteCustomParam(parameter) {
      let payload = {
        customerId: impersonation.getCustomer().id,
        parameterId: parameter.id,
      };
      this.$store.dispatch("deleteCustomParam", payload);
    },
    validateEditInput() {
      let specialCharRegex = /[!@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errorsEdit = [];
      if (!this.paramToEdit.key.startsWith("$")) {
        this.errorsEdit.push("The name must start with a dollar sign!");
      }
      if (!this.paramToEdit.key) {
        this.errorsEdit.push("You have to set the parameter name!");
      }
      if (!this.paramToEdit.value) {
        this.errorsEdit.push("You have to set the parameter value!");
      }
      if (!specialCharRegex.test(this.paramToEdit.key)) {
        if (this.paramToEdit.key !== this.formerParamName) {
          for (let i = 0; i < this.Parameters.length; i++) {
            if (this.paramToEdit.key === this.Parameters[i].key) {
              this.errorsEdit.push(
                "There already exists a parameter with this name! Please rename the new parameter!"
              );
            }
          }
        }
      } else {
        this.errorsEdit.push(
          "The name must not contain special characters or spaces!"
        );
      }
      return this.errorsEdit.length === 0;
    },
    editParameter() {
      if (this.validateEditInput()) {
        let payload = {
          customerId: this.customerId,
          parameter: _.cloneDeep(this.paramToEdit),
        };
        this.$store.dispatch("editCustomCorporateParameter", payload);
        this.showEditDialog = false;
      }
    },
  },
  computed: {
    filteredParametersFix() {
      return this.filterWithKey(this.ParamsFix, this.searchFixed);
    },
    filteredCustomEntites() {
      return this.filterWithKey(this.ParamsCustom, this.searchCustom);
    },
    isSubsetFix() {
      return this.ParamsFix.length !== this.filteredParametersFix.length;
    },
    isSubsetCustom() {
      return this.ParamsCustom.length !== this.filteredCustomEntites.length;
    },
    ParamsFix() {
      return _.filter(this.Parameters, { isEditable: false });
    },
    ParamsCustom() {
      return _.filter(this.Parameters, { isEditable: true });
    },
    Parameters() {
      return this.$store.state.customer.customerDetails.parameters;
    },
  },
};
</script>

<style lang="css" scoped>
.centeredText {
  text-align: center;
}

.flex {
  width: 100%;
  display: flex;
}

.space-right {
  padding-right: 10px;
}
</style>
