<template>
  <div>
    <h3 style="text-align: center; margin: 30px auto">General Settings</h3>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <h3>Customer OS Images</h3>
        <el-col :span="22" :offset="1">
          <el-row type="flex" class="header">
            <el-col :span="6" class="search">
              <el-input placeholder="Find Image" v-model="searchImage">
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-circle-close clickable"
                  v-if="isSubsetImage"
                  @click="clearSearchImage"
                ></i> -->
              </el-input>
              <div class="results" v-if="isSubsetImage">
                <div v-if="!searchImage">
                  showing {{ filteredImages.length }} of
                  {{ customersOSImages.length }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <div class="tableContainer">
          <el-table
            v-loading="loadingCustomersImage"
            :data="filteredImages"
            highlight-current-row
          >
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="edition" label="Edition"></el-table-column>
            <el-table-column
              prop="architecture"
              label="Architecture"
            ></el-table-column>
            <el-table-column prop="vendor" label="Vendor"></el-table-column>
            <el-table-column prop="language" label="Language"></el-table-column>
            <el-table-column
              prop="descriptionShort"
              label="Description"
            ></el-table-column>
            <el-table-column prop="icon" label="Icon">
              <template v-slot="scope">
                <img
                  v-if="
                    scope.row.icon !== null &&
                    scope.row.icon.id !== '' &&
                    scope.row.icon.name != ''
                  "
                  :src="scope.row.icon"
                  height="40"
                  width="40"
                />
                <img
                  v-else
                  src="../../../static/packages-icon-21.png"
                  height="40"
                  width="40"
                />
              </template>
            </el-table-column>
            <el-table-column width="110">
              <template v-slot="scope">
                <actions-dropdown
                  :hideRetreatFromShop="true"
                  :entity="scope.row"
                  :edit="false"
                  :osStreamSettings="true"
                  :deleteItem="true"
                  :showCustomerImages="true"
                  @osStreamSettings="openOSStreamSettings(scope.row)"
                  @delete="confirmDeleteCustomerImageStream(scope.row)"
                  @showCustomerImages="openCustomerImageDialog(scope.row)"
                >
                  <!-- :manageSettingsFiles="true"
                  @manageSettingsFiles="manageSettingsFiles(scope.row)" -->
                </actions-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <customer-images ref="customerImages"></customer-images>
    <os-stream-settings ref="customerStreamSettings"></os-stream-settings>
    <manage-settings-files ref="manageSettings"></manage-settings-files>
  </div>
</template>

<script>
import ManageSettingsFiles from "./ManageSettingsDialog.vue";
import impersonation from "../../impersonation";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import CustomerImages from "./CustomerImages.vue";
import CustomerOSStreamSettings from "../customerSoftware/CustomerOSStreamSettings.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "customer-images": CustomerImages,
    "os-stream-settings": CustomerOSStreamSettings,
    "manage-settings-files": ManageSettingsFiles,
  },
  created() {
    let customerId = impersonation.getCustomer().id;
    this.$store.dispatch("getCustomerImageStreams", customerId);
  },
  data() {
    let customerId = impersonation.getCustomer().id;
    return {
      searchImage: "",
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      actionOEMLogo:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customers/oemLogo/upload/" +
        customerId,
      actionUnattend:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customers/unattend/upload/" +
        customerId,
      actionOEMPartition:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customers/oemPartition/upload/" +
        customerId,
      actionDeskBackground:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customers/deskBackground/upload/" +
        customerId,
      generalSettings: {
        unattendFile: {
          id: "",
          name: "",
        },
        oemPartitionFile: {
          id: "",
          name: "",
        },
        oemLogoFile: {
          id: "",
          name: "",
        },
      },
      fileListOEMLogo: [],
      fileListOEMPartition: [],
      fileListUnattend: [],
      fileListDeskBackground: [],
    };
  },
  methods: {
    manageSettingsFiles(customerImageStream) {
      this.$refs.manageSettings.openDialog(customerImageStream);
    },
    clearSearchImage() {
      this.searchImage = "";
    },
    openOSStreamSettings(imageStream) {
      this.$refs.customerStreamSettings.openDialog(imageStream);
    },
    openCustomerImageDialog(imageStream) {
      this.$refs.customerImages.openDialog(imageStream);
    },
    deleteCustomerImageStream(customerStream) {
      this.$store.dispatch("deleteCustomerImageStream", customerStream.id);
    },
    confirmDeleteCustomerImageStream(customerStream) {
      this.$confirm(
        "This will permanently delete the Image Stream. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(
        this.deleteCustomerImageStream.bind(this, customerStream),
        this.deleteError
      );
    },
    deleteError() {},
  },
  computed: {
    ...mapState([
      "customersOSImages",
      "loadingCustomersImage",
      "customerImageStreams",
    ]),
    filteredImages() {
      return this.filterEntities(this.customerImageStreams, this.searchImage);
    },
    isSubsetImage() {
      return this.customerImageStreams.length !== this.filteredImages.length;
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}
</style>
