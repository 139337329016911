import _ from 'lodash'
async function validateParameterName (rule, value, callback) {
  const paramUnique = await window.axiosInstance.post(`/customers/add-parameter/check-unique`, {name: value})
  if (!paramUnique.data) {
    callback(new Error('The parameter name is already taken! Rename it please.'))
  } else if (!_.startsWith(value, '$')) {
    callback(new Error('The parameter name must begin with a dollar sign.'))
  }
}

export default {
  name: 'deviceProperty-validation',
  data () {
    return {
      rules: {
        propertyName: [
          { required: true, message: 'Please input device property name', trigger: ['blur', 'change'] },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' }
        ],
        parameterName: [
          {validator: validateParameterName, required: true, trigger: 'blur'}
        ]
      }
    }
  }
}
