<template>
  <el-dialog width="75%" v-model="showDialog">
    <div>
      <!-- <client-parameters ref="clientParams"></client-parameters> -->
      <el-row>
        <el-col :span="22" :offset="1">
          <div class="container">
            <el-header> Corporate </el-header>
            <el-main>
              <el-collapse v-model="activeNames">
                <el-collapse-item name="1" title="Corporate">
                  <general-settings :customerId="customerId"></general-settings>
                </el-collapse-item>
              </el-collapse>
            </el-main>
            <!--<el-header>
              Corporate
            </el-header>
            <el-main>
              <general-settings></general-settings>
            </el-main>-->
          </div>
          <div class="container">
            <el-header> Companies </el-header>
            <el-main>
              <companies-settings :customerId="customerId"></companies-settings>
            </el-main>
          </div>
          <div class="container">
            <el-header> Persons </el-header>
            <el-main>
              <persons-settings :customerId="customerId"></persons-settings>
            </el-main>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import GeneralSettings from "./settings/GeneralSettings";
import PersonsSettings from "./settings/PersonsSettings";
import CompaniesSettings from "./settings/CompaniesSettings";

export default {
  name: "customers-settings",
  mixins: [],
  components: {
    GeneralSettings,
    PersonsSettings,
    CompaniesSettings,
  },
  data() {
    return {
      showDialog: false,
      customerName: "",
      customerId: "",
      activeNames: [],
    };
  },
  methods: {
    open(customer) {
      this.customerName = customer.name;
      this.customerId = customer.id;
      this.showDialog = true;
    },
  },
  computed: {},
};
</script>

<style lang="css" scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
