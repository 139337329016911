<template>
  <el-dialog
    title="Software Packages"
    v-model="showDialog"
    width="80%"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    {{ setApplicationOwners }}
    <div style="text-align: center">
      <el-select v-model="applicationOwnerId">
        <el-option
          v-for="p in personsArray"
          :key="p.id"
          :value="p.id"
          :label="p.givenName + ' ' + p.surname"
        >
        </el-option>
      </el-select>
      <div style="margin-top: 10px">
        <span v-if="showError" style="color: red"
          >Please select the application owner</span
        >
      </div>
      <div style="margin-top: 10px">
        <el-button type="primary" @click="submit"
          >Set Application Owner</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { setApplicationOwners } from "../../services/customerSoftwares";
import impersonation from "../../impersonation";
import { successMessage, errorMessage } from "../../store/modules/util";

export default {
  data() {
    return {
      showDialog: false,
      applicationOwnerId: "",
      selectedSoftwareStreams: [],
      showError: false,
    };
  },
  methods: {
    openDialog(selectedSoftwareStreams) {
      this.selectedSoftwareStreams = selectedSoftwareStreams;
      this.showDialog = true;
    },
    handleClose() {
      this.applicationOwnerId = "";
      this.showDialog = false;
    },
    submit() {
      if (this.applicationOwnerId !== "") {
        this.showError = false;
        let payload = {
          selectedSoftwareStreams: this.selectedSoftwareStreams,
          applicationOwnerId: this.applicationOwnerId,
        };
        setApplicationOwners(impersonation.getCustomer().id, payload)
          .then(() => {
            successMessage(
              "The application owner was set for the selected streams"
            );
          })
          .catch((error) => {
            errorMessage(error.response);
          })
          .finally(() => {
            this.$store.dispatch(
              "getCustomersStreams",
              impersonation.getCustomer().id
            );
            this.applicationOwnerId = "";
            this.showDialog = false;
          });
      } else {
        this.showError = true;
      }
    },
  },
  computed: {
    ...mapState(["persons"]),
    personsArray() {
      let result = _.cloneDeep(this.persons);
      return result;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
