<template>
  <el-form-item label="Virtual Network Name" :prop="prop" :rules="rules.name">
    <el-input v-model="model" @change="change" placeholder="Select Virtual Network Name"></el-input>
  </el-form-item>
</template>

<script>
  import VirtualNetworkValidation from './VirtualNetworkValidation'

  export default {
    name: 'virtual-network-name',
    props: ['value', 'prop'],
    mixins: [
      VirtualNetworkValidation
    ],
    data () {
      return {
        model: this.value
      }
    },
    methods: {
      change (value) {
        this.$emit('input', value)
      }
    }
  }
</script>
