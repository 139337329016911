<template lang="html">
  <div>
    <add-company-dialog
      ref="editCompanyDialog"
      :renderNewLocationOption="false"></add-company-dialog>
    <el-dialog
      title="Edit Location"
      v-model="showDialog">
      <el-form :model="location" :rules="locationRules" ref="edit-location-form">
        <el-form-item label="Name" prop="name">
          <el-input
            placeholder="Please insert the name"
            v-model="location.name"></el-input>
        </el-form-item>
        <el-form-item label="Name (abbr.)" prop="nameAbbreviation">
          <el-input
            placeholder="Please insert the name abbreviation"
            v-model="location.nameAbbreviation"></el-input>
        </el-form-item>
        <div v-if="location.type === 'in-cloud'">
          <el-form-item label="Azure Location" prop="azureLocation">
            <el-select
              v-model="location.azureLocation"
              placeholder="Select Azure Location"
              filterable>
              <el-option
                v-for="azureLocation in azureLocations"
                :key="azureLocation.name"
                :label="azureLocation.displayName"
                :value="azureLocation.displayName">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="Country" prop="country">
            <country-selection
              ref="countrySelect"
              @setCountryData="fillCountryData"
              :passedCountryName="getCountryName()">
            </country-selection>
          </el-form-item>
          <el-form-item label="Time Zone" prop="timeZone">
            <el-select
             v-model="location.timeZone"
             placeholder="Select Timezone">
             <el-option
               v-for="timeZone in timeZones"
               :key="timeZone.zoneName"
               :value="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
               :label="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
               >
             </el-option>
           </el-select>
           <p class="error" v-if="timeZones.length === 0">
             Please select the country first
           </p>
          </el-form-item>
          <el-form-item label="City" prop="city">
            <el-input
              placeholder="Please insert the city"
              v-model="location.city"></el-input>
          </el-form-item>
          <el-form-item label="City (abbr.)" prop="cityAbbreviation">
            <el-input
              placeholder="Please insert the city abbreviation"
              v-model="location.cityAbbreviation"></el-input>
          </el-form-item>
          <el-form-item label="Street" prop="street">
            <el-input
              placeholder="Please insert the street"
              v-model="location.street"></el-input>
          </el-form-item>
          <el-form-item label="Number" prop="number">
            <el-input
              placeholder="Please insert the house number"
              v-model="location.number"></el-input>
          </el-form-item>
          <el-form-item label="Postcode" prop="postcode">
            <el-input
              placeholder="Please insert the postcode"
              v-model="location.postcode"></el-input>
          </el-form-item>
          <el-form-item label="Public IP" prop="publicIp">
            <el-input
              placeholder="Please insert the public IP address"
              v-model="location.publicIp"></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item label="Download Speed" prop="downloadSpeed">
              <el-input
                placeholder="Please insert download speed (kbit/s)"
                v-model="location.downloadSpeed"></el-input>
            </el-form-item>
            <el-form-item label="Upload Speed" prop="uploadSpeed">
              <el-input
                placeholder="Please insert upload speed (kbit/s)"
                v-model="location.uploadSpeed">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="Company" prop="companyId">
            <el-select
              v-model="location.companyId"
              placeholder="Select company"
              @change="openEditCompanyDialog">
              <el-option
                key="new-company"
                value="new-company"
                label="+ New Company">
              </el-option>
              <el-option
                v-for="company in companies"
                :key="company.id"
                :value="company.id"
                :label="company.corporateName">
              </el-option>
            </el-select>
        </el-form-item>
        <!-- icon="el-icon-plus" -->
        <el-button
          type="primary"        
          @click="submit('edit-location-form')">
            Edit
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import LocationValidationRules from "./LocationValidationRules";
import AddCompanyDialog from "../customers/settings/dialogs/AddCompanyDialog";
import CountrySelection from "./CountrySelection";
import _ from "lodash";

export default {
  name: "edit-location-dialog",
  mixins: [LocationValidationRules],
  components: {
    AddCompanyDialog,
    CountrySelection,
  },
  data() {
    return {
      showDialog: false,
      location: {
        id: "",
        name: "",
        nameAbbreviation: "",
        country: "",
        countryAbbreviation: "",
        city: "",
        cityAbbreviation: "",
        street: "",
        number: "",
        postcode: "",
        publicIp: "",
        companyId: "",
        uploadSpeed: "",
        downloadSpeed: "",
        type: "",
        timeZone: "",
        azureLocation: "",
      },
      timeZones: [],
    };
  },
  methods: {
    openDialog(locationData) {
      this.location.id = locationData.id;
      this.location.name = locationData.name;
      this.location.nameAbbreviation = locationData.nameAbbreviation;
      this.location.country = locationData.country;
      this.location.countryAbbreviation = locationData.countryAbbreviation;
      this.location.city = locationData.city;
      this.location.cityAbbreviation = locationData.cityAbbreviation;
      this.location.street = locationData.street;
      this.location.number = locationData.number;
      this.location.postcode = locationData.postcode;
      this.location.publicIp = locationData.publicIP;
      this.location.companyId = locationData.companyId;
      this.location.uploadSpeed = locationData.uploadSpeed;
      this.location.downloadSpeed = locationData.downloadSpeed;
      this.location.type = locationData.type;
      this.location.timeZone = locationData.timeZone;
      this.location.azureLocation = locationData.azureLocation;
      this.showDialog = true;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("updateLocation", _.cloneDeep(this.location));
          this.$refs["edit-location-form"].resetFields();
          this.showDialog = false;
        }
      });
    },
    openEditCompanyDialog() {
      if (this.location.companyId === "new-company") {
        this.$refs.editCompanyDialog.openDialog();
      }
    },
    fillCountryData(countryData) {
      this.location.country = countryData.name;
      this.location.countryAbbreviation = countryData.code;
      this.timeZones = countryData.zones;
      let oldTimeZoneFound = false;
      if (this.location.timeZone) {
        let timeZoneName = this.location.timeZone.split(" ")[0];
        for (let i = 0; i < countryData.zones.length; i++) {
          if (countryData.zones[i].zoneName === timeZoneName) {
            oldTimeZoneFound = true;
            break;
          }
        }
      }
      if (!oldTimeZoneFound) {
        this.location.timeZone = "";
      }
    },
    getCountryName() {
      return this.location.country;
    },
    computeTimeZone(zoneName, gmtOffset) {
      return zoneName + " (GMT" + gmtOffset + ")";
    },
  },
  computed: {
    companies: {
      get() {
        return this.$store.state.customersCompanies;
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        // Do nothing
      },
    },
    azureLocations() {
      return this.$store.state.locations;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
