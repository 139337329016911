export function getWinPESASUrl () {
  return window.axiosInstance.get(`/agent/downloads/winpe`, { responseType: 'blob' })
}

export function downloadFirstAgent () {
  return window.axiosInstance.get(`/agent/downloads/firstagent`, { responseType: 'blob' })
}

export function downloadSmartDeploy () {
  return window.axiosInstance.get(`/connect/download`, { responseType: 'blob' })
}

export function downloadSmartDeploywin7 () {
  return window.axiosInstance.get(`/connect/download/3.5`, { responseType: 'blob' })
}

export function downloadViaSAS (url) {
  let auth = window.axiosInstance.defaults.headers.common['Authorization']
  delete window.axiosInstance.defaults.headers.common['Authorization']
  let response = window.axiosInstance.get(url, { responseType: 'blob' })
  window.axiosInstance.defaults.headers.common['Authorization'] = auth
  return response
}
