<template>
  <el-dialog
    v-model="showDialog"
    :title="getTitle"
    :close-on-click-modal="false"
    :before-close="handleCloseDialog"
    width="75%"
  >
    <el-form
      ref="form"
      :model="software"
      label-width="220px"
      :rules="rulesSoftware"
    >
      <el-button
        type="primary"
        @click="changeAccordion"
        size="small"
        style="margin-bottom: 5px"
      >
        {{ label }}
      </el-button>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="General" name="general">
          <el-form-item label="Name" prop="name" :rules="rulesSoftware.name">
            <el-input
              disabled
              v-model="software.name"
              placeholder="Software name"
            ></el-input>
          </el-form-item>
          <el-form-item label="App Version" prop="version">
            <el-input
              v-model="software.version"
              placeholder="Version"
              @change="versionChanged"
            ></el-input>
          </el-form-item>
          <el-form-item label="Type" prop="type" :rules="rulesSoftware.type">
            <el-select disabled v-model="software.type">
              <el-option v-for="t in types" :key="t" :label="t" :value="t">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Running Context"
            prop="runningContext"
            :rules="rulesSoftware.runningContext"
          >
            <el-select
              placeholder="Please select the running context"
              v-model="software.runningContext"
              @change="setTaskRC"
            >
              <el-option
                v-for="rc in runningContexts"
                :key="rc"
                :label="rc"
                :value="rc"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Package-Status"
            prop="status"
            :rules="rulesSoftware.status"
          >
            <el-select v-model="software.status" filterable>
              <el-option v-for="s in status" :key="s" :label="s" :value="s">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Systemhouses">
            <el-select
              v-model="software.systemhouses"
              @change="getSystemhousesCustomers"
              multiple
              filterable
            >
              <el-option
                v-for="sys in systemhouses"
                :key="sys.id"
                :value="sys.id"
                :label="sys.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Customers">
            <el-select
              v-model="software.customers"
              filterable
              multiple
              @change="getCustomersClients"
            >
              <el-option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
                :label="customer.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Clients">
            <el-select v-model="software.clients" multiple filterable>
              <el-option
                v-for="client in clients"
                :key="client.id"
                :label="client.name"
                :value="client.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-select v-model="software.customers" filterable multiple>
              <el-option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
                :label="customer.name"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="Package Size (MB)">
            <el-input
              v-model="software.packageSize"
              placeholder="Please set the package size"
            ></el-input>
          </el-form-item>
          <el-form-item label="Estimated Installation Time (sec)">
            <el-input
              v-model="software.installationTime"
              placeholder="Please set the estimated installation time"
            ></el-input>
          </el-form-item>
          <el-form-item label="Prerequisites/Dependencies">
            <el-input
              :autosize="{ minRows: 5 }"
              placeholder="Prerequisites/Dependencies"
              type="textarea"
              v-model="software.prerequisites"
            ></el-input>
          </el-form-item>
          <el-form-item label="Vendor Release Date">
            <el-input
              placeholder="Please set the vendor release date"
              v-model="software.vendorReleaseDate"
            ></el-input>
          </el-form-item>
          <el-form-item label="Compliancy Rule">
            <el-input
              :autosize="{ minRows: 5 }"
              placeholder="Compliancy Rule"
              type="textarea"
              v-model="software.compliancyRule"
            ></el-input>
          </el-form-item>
          <el-form-item label="SHA256 Checksum">
            <el-input
              placeholder="SHA256 Checksum"
              v-model="software.checksum"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Dedicated Download Link"
            prop="dedicatedDownloadLink"
          >
            <el-input v-model="software.dedicatedDownloadLink"></el-input>
          </el-form-item>
          <el-form-item
            v-if="software.version !== ''"
            label="Next Software Version"
            prop="nextSoftwareId"
          >
            <el-select v-model="software.nextSoftwareId">
              <el-option
                v-for="next in nextSW"
                :key="next.id"
                :value="next.id"
                :label="next.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="software.version !== ''"
            label="Previous Software Version"
            prop="prevSoftwareId"
          >
            <el-select
              v-model="software.prevSoftwareId"
              @change="loadSmallerVersions"
            >
              <el-option
                v-for="prev in prevSW"
                :key="prev.id"
                :value="prev.id"
                :label="prev.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="software.prevSoftwareId !== ''"
            label="Minimal Software Version"
            prop="minimalSoftwareId"
          >
            <el-select v-model="software.minimalSoftwareId">
              <el-option
                v-for="min in minimalSW"
                :key="min.id"
                :value="min.id"
                :label="min.version"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item
          v-if="software.type !== 'Task'"
          title="Rule Applicability"
          name="ruleApplicability"
        >
          <el-form
            :model="ruleApplicabilityModel"
            ref="formRuleApplicability"
            :rules="rulesApplicability"
          >
            <el-form-item prop="osType">
              <el-checkbox-group
                v-model="checkedOSType"
                @change="onChangeOSType"
                :max="1"
              >
                <el-checkbox label="Windows"></el-checkbox>
                <el-checkbox disabled label="Linux"></el-checkbox>
                <el-checkbox disabled label="MacOS"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item v-if="isWindows" prop="osVersions">
              <el-checkbox-group
                v-model="osVersionNames"
                @change="onChangeVersionName"
              >
                <el-checkbox
                  v-for="versionName in windowsVersionNames"
                  :label="versionName"
                  :key="versionName"
                >
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <div style="display: flex">
              <el-form-item v-if="isWindows10" prop="win10Versions">
                <el-checkbox
                  label="All Win10 Versions"
                  v-model="software.allWin10Versions"
                ></el-checkbox>
                <el-checkbox-group
                  :disabled="software.allWin10Versions ? true : false"
                  v-model="selectedWin10Versions"
                  @change="onChangeWin10Versions"
                >
                  <div
                    v-for="version in win10Versions"
                    :key="version"
                    style="display: block"
                  >
                    <el-checkbox :label="version" :key="version"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item v-if="win11Selected" prop="win11Versions">
                <el-checkbox
                  label="All Win11 Versions"
                  v-model="software.allWin11Versions"
                ></el-checkbox>
                <el-checkbox-group
                  :disabled="software.allWin11Versions ? true : false"
                  v-model="selectedWin11Versions"
                  @change="onChangeWin11Versions"
                >
                  <div
                    v-for="version in win11Versions"
                    :key="version"
                    style="display: block"
                  >
                    <el-checkbox :label="version" :key="version"> </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <el-form-item label="Architecture" prop="architecture">
              <el-checkbox-group
                :disabled="win11Selected ? true : false"
                v-model="selectedArchitectureApplicability"
                @change="onChangeArchitectureApplicability"
              >
                <el-checkbox label="64bit"></el-checkbox>
                <el-checkbox label="32bit"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item
          v-if="software.type !== 'Task'"
          title="Rule Detection"
          name="ruleDetection"
        >
          <el-form
            :model="ruleDetectionModel"
            ref="formRuleDetection"
            :rules="rulesDetection"
          >
            <el-form-item label="Type" prop="type">
              <el-select
                v-model="ruleDetectionModel.type"
                placeholder="Rule type"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="this.ruleDetectionModel.type === 'fileExists'">
              <el-form-item label="Path" prop="path">
                <el-input
                  v-model="ruleDetectionModel.path"
                  placeholder="Path"
                ></el-input>
              </el-form-item>
              <el-form-item prop="checkVersionNr">
                <el-checkbox v-model="ruleDetectionModel.checkVersionNr"
                  >Check Version Number</el-checkbox
                >
              </el-form-item>
              <el-form-item label="Check Version Nr" prop="versionNr">
                <el-input
                  v-model="ruleDetectionModel.versionNr"
                  placeholder="Version Number"
                  @change="changeTaskName"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              v-if="this.ruleDetectionModel.type === 'script'"
              label="File"
              prop="data"
            >
              <upload-file
                :id="id"
                :action="uploadUrl"
                :file-list="files"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              ></upload-file>
            </el-form-item>
            <el-form-item>
              <span>
                Succession
                <el-switch v-model="ruleDetectionModel.successon"></el-switch>
              </span>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item title="Task Install" name="taskInstall">
          <el-form-item
            ref="taskInputName"
            label="Name"
            prop="name"
            :rules="rulesTask.name"
          >
            <el-input
              v-model="software.taskInstall.name"
              placeholder="Task name"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="installationType" label="Installation-Type">
            <el-checkbox-group
              v-model="installationTypeChecked"
              :max="1"
              @change="changedInstalltionType"
            >
              <el-checkbox label="Powershell Script"></el-checkbox>
              <el-checkbox label="Bitstream PS-Wrapper" disabled></el-checkbox>
              <el-checkbox
                label="Powershell App Deployment Toolkit"
                disabled
              ></el-checkbox>
              <el-checkbox label="Use Own" disabled></el-checkbox>
            </el-checkbox-group>
          </el-form-item> -->
          <el-form-item
            ref="taskInputFiles"
            label="Files"
            prop="files"
            :rules="rulesTask.files"
          >
            <upload-files
              :action="uploadUrlFiles"
              :file-list="software.taskInstall.files"
              :on-remove="handleRemoveTaskInstall"
              :on-success="handleSuccessTaskInstall"
            ></upload-files>
          </el-form-item>
          <el-form-item
            ref="taskInputExecutable"
            label="Executable"
            prop="executable"
            :rules="rulesTask.executable"
          >
            <el-select
              v-model="software.taskInstall.executable"
              placeholder="Select executable"
            >
              <el-option
                v-for="file in software.taskInstall.files"
                :key="file.id"
                :label="file.name"
                :value="file.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            ref="taskInputCommandline"
            label="Arguments"
            :rules="rulesTask.commandline"
          >
            <el-input
              v-model="software.taskInstall.commandline"
              placeholder="Arguments"
            ></el-input>
          </el-form-item>
          <el-form-item
            ref="taskInputInstallationCmd"
            label="Installation Command"
          >
            <el-input
              v-model="software.taskInstall.installationCmd"
              placeholder="Installation Command"
            ></el-input>
          </el-form-item>
          <el-form-item
            ref="taskInputUninstallCommand"
            label="Uninstall Command"
            :rules="rules.uninstallCommand"
          >
            <el-input
              v-model="software.taskInstall.uninstallCommand"
              placeholder="Uninstall Command"
            ></el-input>
          </el-form-item>
          <el-form-item label="Restart Behavior">
            <el-select v-model="software.taskInstall.restartBehavior">
              <el-option
                v-for="restartBehavior in restartBehaviors"
                :key="restartBehavior.key"
                :label="restartBehavior.value"
                :value="restartBehavior.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Return Codes">
            <div>
              <div
                style="margin-bottom: 20px; align-items: center"
                class="flex"
                v-for="(returnCode, index) in software.taskInstall.returnCodes"
                :key="returnCode"
              >
                <el-input
                  class="spaceRight"
                  v-model="returnCode.code"
                ></el-input>
                <el-select class="spaceRight" v-model="returnCode.value">
                  <el-option
                    v-for="code in codes"
                    :key="code.key"
                    :label="code.value"
                    :value="code.value"
                  ></el-option>
                </el-select>
                <el-icon @click="removeReturnCode(index)" :size="20"
                  ><Delete
                /></el-icon>
              </div>
            </div>
            <div class="addBtn" type="primary">
              <el-button @click="addReturnCode">+ Add Return Code</el-button>
            </div>
          </el-form-item>
          <el-form-item label="Intune File">
            <el-upload
              :headers="headers"
              accept=".intunewin"
              :action="uploadUrlFiles"
              :limit="1"
              :file-list="intuneFiles"
              :on-remove="handleRemoveIntuneFile"
              :on-success="handleSuccessIntuneFile"
            >
              <el-button type="primary">Click to upload</el-button>
            </el-upload>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(software)">Save</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import { defaultModel } from "./SoftwareAddModel";
import UploadFile from "../shared/UploadFile";
import UploadFiles from "../shared/UploadFiles";
// getSoftwareIconFileRef
import {
  getSoftwarePackage,
  getNextSoftwareForEdit,
  getPrevSoftwareForEdit,
} from "../../services/softwares";
import { mapState } from "vuex";
import { getSoftwareRules } from "../../services/rules";
import { getSystemhousesCustomers } from "../../services/systemhouses";
import { getCustomersClients } from "../../services/customers";

export default {
  emits: ["refresh", "revisionMessage"],
  name: "software-edit",
  components: {
    UploadFile,
    UploadFiles,
  },
  // mixins: [ TaskValidation ],
  data() {
    let validateData = (rule, value, callback) => {
      let vm = this.value;
      if (vm.data === null || vm.data.length === 0) {
        callback(new Error("Please upload a file"));
      } else {
        callback();
      }
    };
    let validateArchitectureApplicability = (rule, value, callback) => {
      if (this.selectedArchitectureApplicability.length === 0) {
        callback(new Error("Please select at least one architecture type"));
      } else {
        callback();
      }
    };
    let validateArchitectureDetection = (rule, value, callback) => {
      if (this.selectedArchitectureDetection.length === 0) {
        callback(new Error("Please select at least one architecture type"));
      } else {
        callback();
      }
    };
    let validateOSTypeApplicability = (rule, value, callback) => {
      if (this.checkedOSType.length === 0) {
        callback(new Error("Please select at least one OS type"));
      } else {
        callback();
      }
    };
    let validateOSVersionApplicability = (rule, value, callback) => {
      if (this.osVersionNames.length === 0) {
        callback(new Error("Please select at least one Windows OS type"));
      } else {
        callback();
      }
    };
    let validateWin10VersionsApplicability = (rule, value, callback) => {
      if (this.software.allWin10Versions) {
        callback();
      }
      if (this.selectedWin10Versions.length === 0) {
        callback(new Error("Please select at least on Windows 10 version"));
      } else {
        callback();
      }
    };
    let validateWin11VersionsApplicability = (rule, value, callback) => {
      if (this.software.allWin11Versions) {
        callback();
      }
      if (this.selectedWin11Versions.length === 0) {
        callback(new Error("Please select at least on Windows 11 version"));
      } else {
        callback();
      }
    };
    let validateFiles = (rule, value, callback) => {
      let vm = this.software.taskInstall;
      if (vm.files === null || vm.files.length === 0) {
        callback(new Error("Please add at least one file"));
      } else {
        callback();
      }
    };
    let validateExecutionContext = (rule, value, callback) => {
      if (this.software.taskInstall.executionContext === "") {
        callback(new Error("Please set the execution context"));
      } else {
        callback();
      }
    };
    let validateVisibility = (rule, value, callback) => {
      if (this.software.taskInstall.visibility === "") {
        callback(new Error("Please set the visibility property"));
      } else {
        callback();
      }
    };
    let validateExecutable = (rule, value, callback) => {
      if (this.software.taskInstall.executable === "") {
        callback(new Error("Please specify the executable"));
      } else {
        callback();
      }
    };
    let validateVersion = (rule, value, callback) => {
      let exp = /^(\d+.)+\d+/;
      if (this.software.version[this.software.version.length - 1] === ".") {
        callback(new Error("The version number must not end with a dot"));
      } else {
        var expressionTestResult = exp.test(this.software.version);
        if (expressionTestResult) {
          callback();
        } else {
          callback("The version number format is not supported");
        }
      }
    };
    return {
      intuneFiles: [],
      codes: [
        { key: 1, value: "Failed" },
        { key: 2, value: "Hard reboot" },
        { key: 3, value: "Soft reboot" },
        { key: 4, value: "Retry" },
        { key: 5, value: "Success" },
      ],
      restartBehaviors: [
        { key: 1, value: "App install may force a device restart" },
        { key: 2, value: "No specific action" },
        { key: 3, value: "Determine behavior based on return codes" },
        { key: 4, value: "Intune will force a mandatory device restart" },
      ],
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      win11Selected: false,
      checkAll: false,
      installationTypeChecked: [],
      streamId: "",
      nextSW: [],
      minimalSW: [],
      prevSW: [],
      customers: [],
      rulesTask: {
        name: [
          {
            required: true,
            message: "Please input task name",
            trigger: ["blur", "change"],
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: ["blur", "change"],
          },
        ],
        files: [
          {
            validator: validateFiles,
            type: "array",
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        executable: [{ validator: validateExecutable, trigger: "change" }],
        executionContext: [
          {
            validator: validateExecutionContext,
            required: true,
            trigger: "change",
          },
        ],
        visibility: [
          { validator: validateVisibility, required: true, trigger: "change" },
        ],
      },
      clients: [],
      swArchitectures: ["x64", "x86", "x86 + x64"],
      languages: [
        "not defined",
        "MUI (multi user interface)",
        "German (de-DE)",
        "English (en-US)",
      ],
      types: ["Software", "Task"],
      runningContexts: ["Run in service", "Run in user context"],
      status: ["Creation", "Test", "Acceptance", "Pilot", "Active", "Outdated"],
      showDialog: false,
      checkedOSType: [],
      osVersionNames: [],
      selectedWin10Versions: [],
      selectedWin11Versions: [],
      selectedArchitectureApplicability: [],
      selectedArchitectureDetection: [],
      icons: [],
      activeNames: ["general"],
      uploadUrl:
        window.APPLICATION_SETTING_API_ENDPOINT_URL + "/software/icon/upload",
      uploadUrlFiles:
        window.APPLICATION_SETTING_API_ENDPOINT_URL + "/tasks/upload",
      model: _.clone(defaultModel),
      ruleDetectionModel: {
        id: "",
        type: "fileExists",
        architecture: [],
        successon: true,
        data: null,
        versionNr: "0.0.0.1",
        checkVersionNr: true,
      },
      ruleApplicabilityModel: {
        id: "",
        type: "",
        architecture: [],
        successon: true,
        data: null,
        versionNr: "",
        checkVersionNr: false,
        osType: "",
        osVersionNames: [],
        win10Versions: [],
      },
      windowsVersionNames: [
        "Win 7",
        "Win 8",
        "Win 10",
        "Win 11",
        "Win Server 2016",
        "Win Server 2019",
        "Win Server 2022",
      ],
      win10Versions: [
        "1709",
        "1803",
        "1809",
        "1903",
        "1909",
        "2004",
        "20H2",
        "21H1",
        "21H2",
        "22H2",
      ],
      win11Versions: ["21H2", "22H2"],
      isWindows: false,
      isWindows10: false,
      options: [
        {
          value: "script",
          label: "Script",
        },
        {
          value: "fileExists",
          label: "File exists",
        },
      ],
      windowsOptions: [
        {
          value: "win7",
          label: "Windows 7",
        },
        {
          value: "win8",
          label: "Windows 8",
        },
        {
          value: "win10",
          label: "Windows 10",
        },
      ],
      rulesDetection: {
        data: [
          {
            validator: validateData,
            type: "array",
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the type",
            trigger: ["change", "blur"],
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: ["blur", "change"],
          },
        ],
        architecture: [
          {
            required: true,
            validator: validateArchitectureDetection,
            type: "array",
            message: "Please select at least one architecture type",
            trigger: "change",
          },
        ],
        path: [
          { required: true, message: "Please set the path", trigger: "blur" },
        ],
      },
      rulesApplicability: {
        data: [
          {
            validator: validateData,
            type: "array",
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the type",
            trigger: ["change", "blur"],
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: ["blur", "change"],
          },
        ],
        architecture: [
          {
            required: true,
            validator: validateArchitectureApplicability,
            type: "array",
            message: "Please select at least one architecture type",
            trigger: "change",
          },
        ],
        path: [
          { required: true, message: "Please set the path", trigger: "blur" },
        ],
        osType: [
          {
            required: true,
            validator: validateOSTypeApplicability,
            type: "array",
            message: "Please select at least one OS type",
            trigger: "change",
          },
        ],
        osVersions: [
          {
            required: true,
            validator: validateOSVersionApplicability,
            type: "array",
            message: "Please select at least one OS version",
            trigger: "change",
          },
        ],
        win10Versions: [
          {
            required: true,
            validator: validateWin10VersionsApplicability,
            type: "array",
            message: "Please select at least one Windows 10 version",
            trigger: "change",
          },
        ],
        win11Versions: [
          {
            required: true,
            validator: validateWin11VersionsApplicability,
            type: "array",
            message: "Please select at least one Win 11 version",
            trigger: "change",
          },
        ],
      },
      software: {
        allWin10Versions: true,
        displayRevisionNumber: 0,
        id: "",
        dedicatedDownloadLink: "",
        installationType: "",
        prevSoftwareId: "",
        nextSoftwareId: "",
        minimalSoftwareId: "",
        publishInShop: false,
        runningContext: "Run in service",
        language: "not definded",
        architecture: "x64",
        installationTime: "",
        packageSize: "",
        prerequisites: "",
        vendorReleaseDate: "",
        gnuLicence: false,
        compliancyRule: "",
        checksum: "",
        type: "",
        website: "",
        downloadLink: "",
        status: "",
        name: "",
        descriptionShort: "",
        description: "",
        vendor: "",
        version: "",
        streamId: "",
        icon: {
          id: "",
          name: "",
        },
        ruleApplicability: {
          id: "",
          type: "",
          architecture: "",
          successon: true,
          data: null,
          versionNr: "",
          checkVersionNr: false,
          osType: "",
          osVersionNames: [],
          win10Versions: [],
        },
        ruleDetection: {
          id: "",
          type: "",
          architecture: "",
          successon: true,
          data: null,
          versionNr: "",
          checkVersionNr: false,
        },
        taskInstall: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
          installationType: "",
          restartRequired: false,
          restartBehavior: "",
          returnCodes: [],
          intuneFile: { id: null, name: "", guid: "" },
        },
        taskUninstall: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
          runningContext: "",
          installationType: "",
          restartRequired: false,
        },
        taskUpdate: {
          name: "",
          files: [],
          executable: "",
          commandline: "",
          useShellExecute: false,
          installationType: "",
          restartRequired: false,
        },
      },
      label: "Expand all",
      taskuninstall: {
        status: "Enable",
        rule: false,
      },
      taskupdate: {
        status: "Enable",
        rule: false,
      },
      rulesSoftware: {
        name: [
          {
            required: true,
            message: "Please input software name",
            trigger: "blur",
          },
          {
            min: 3,
            message: "Name should at least have 3 chars",
            trigger: "blur",
          },
        ],
        version: [
          {
            validator: validateVersion,
            message: "Please set the version of the software",
            trigger: "blur",
          },
        ],
        ruleApplicabilityId: [
          {
            required: true,
            message: "Please select the applicability rule",
            trigger: "change",
          },
        ],
        ruleDetectionId: [
          {
            required: true,
            message: "Please select the detection rule",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select the status",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select the software type",
            trigger: "change",
          },
        ],
        architecture: [
          {
            required: true,
            message: "Please select the architecture",
            trigger: "change",
          },
        ],
        runningContext: [
          {
            required: true,
            message: "Please select the running context",
            trigger: "change",
          },
        ],
        publishInShop: [
          {
            required: true,
            message:
              "Please select if you want to publish this software in the customer shops",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    handleRemoveIntuneFile() {
      this.software.taskInstall.intuneFile = { id: null, guid: "", name: "" };
    },
    handleSuccessIntuneFile() {
      let data = this.intuneFiles[0];
      let file = { guid: data.response.id, name: data.name, id: null };
      this.software.taskInstall.intuneFile = file;
    },
    removeReturnCode(index) {
      this.software.taskInstall.returnCodes.splice(index, 1);
    },
    addReturnCode() {
      this.software.taskInstall.returnCodes.push({
        id: null,
        code: "",
        value: "",
      });
    },
    handleCheckAllChange(value) {
      if (value) {
        this.checkedOSType = ["Windows"];
        this.selectedWin10Versions = [
          "1709",
          "1809",
          "1903",
          "1909",
          "2004",
          "20H2",
          "21H1",
          "21H2",
        ];
        this.software.ruleApplicability.osType = "Windows";
        this.software.ruleApplicability.osVersionNames = [
          "Win 7",
          "Win 8",
          "Win 10",
          "Win 11",
          "Win Server 2016",
          "Win Server 2019",
          "Win Server 2022",
        ];
        this.software.ruleApplicability.win10Versions = [
          "1709",
          "1809",
          "1903",
          "1909",
          "2004",
          "20H2",
          "21H1",
          "21H2",
        ];
      } else {
        this.checkedOSType = [];
        this.selectedWin10Versions = [];
        this.software.ruleApplicability.osVersionNames = [];
        this.software.ruleApplicability.win10Versions = [];
      }
    },
    changedInstalltionType() {
      this.software.taskInstall.installationType =
        this.installationTypeChecked[0];
    },
    loadSmallerVersions(value) {
      let temp = this.prevSW;
      console.log({ temp });
      let prevVersion = this.prevSW.find((el) => el.id === value).version;
      prevVersion = prevVersion.split(".");
      let result = [];
      for (let i = 0; i < this.prevSW.length; i++) {
        let currentVersion = this.prevSW[i].version.split(".");
        if (currentVersion.length === prevVersion.length) {
          if (Number(currentVersion[i]) < Number(prevVersion[i])) {
            result.push(this.prevSW[i]);
          } else if (Number(currentVersion[i]) === Number(prevVersion[i])) {
            if (i === this.prevSW.length - 1) {
              result.push(this.prevSW[i]);
            } else {
              continue;
            }
          } else {
            break;
          }
        }
      }
      this.minimalSW = result;
    },
    versionChanged(value) {
      var splitByDot = value.split(".");
      if (splitByDot.length == 1) {
        value = value + ".0";
        this.software.version = value;
      }
      this.software.nextSoftwareId = "";
      this.software.prevSoftwareId = "";
      this.software.minimalSoftwareId = "";
      getNextSoftwareForEdit(this.streamId, value, this.software.id).then(
        (response) => {
          this.nextSW = response.data;
        }
      );
      getPrevSoftwareForEdit(this.streamId, value, this.software.id).then(
        (response) => {
          this.prevSW = response.data;
        }
      );
    },
    handleCloseDialog() {
      this.$refs.form.resetFields();
      this.intuneFiles = [];
      this.showDialog = false;
    },
    setTaskRC(val) {
      this.software.taskInstall.runningContext = val;
    },
    changeTaskName(val) {
      if (val.split(".").length == 1) {
        this.ruleDetectionModel.versionNr = val + ".0.0.0";
      }
      this.software.taskInstall.name =
        this.software.name + " " + this.software.version;
    },
    setFilesTaskInstall(file, type) {
      if (type === "add") {
        let before = this.software.taskInstall.files;
        console.log({ before });
        this.software.taskInstall.files.push(file);
        let after = this.software.taskInstall.files;
        console.log({ after });
      } else {
        for (let i = 0; i < this.software.taskInstall.files.length; i++) {
          if (this.software.taskInstall.files[i].name === file) {
            if (
              this.software.taskInstall.executable ===
              this.software.taskInstall.files[i].id
            ) {
              this.software.taskInstall.executable = "";
            }
            this.software.taskInstall.files.splice(i, 1);
            break;
          }
        }
      }
    },
    handleRemoveTaskInstall(a) {
      this.setFilesTaskInstall(a.name, "delete");
    },
    handleSuccessTaskInstall(a, b) {
      this.software.taskInstall.files = this.software.taskInstall.files.filter(
        (x) => x.id !== undefined
      );
      let file = { id: a.id, name: b.name };
      this.software.taskInstall.files.push(file);
      this.removeError();
    },
    removeError() {
      if (
        this.software.taskInstall.files !== null ||
        this.software.taskInstall.files.length >= 1
      ) {
        let id = this.id;
        let el = document.getElementById(id);
        let sibling = el.nextSibling;
        let parentNode = sibling.parentElement;
        if (sibling !== null && sibling.classList !== undefined) {
          if (sibling.classList.contains("el-form-item__error")) {
            parentNode.removeChild(sibling);
          }
        }
      }
    },
    handleClose() {
      this.$refs.taskInstall.resetModel();
      this.showDialog = false;
    },
    getCustomersClients(value) {
      getCustomersClients(value).then((response) => {
        this.clients = response.data;
      });
    },
    async getSystemhousesCustomers(value) {
      getSystemhousesCustomers(value).then((response) => {
        this.customers = response.data;
      });
    },
    onChangeArchitectureApplicability(value) {
      this.ruleApplicabilityModel.architecture = value;
    },
    onChangeArchitectureDetection(value) {
      this.ruleDetectionModel.architecture = value;
    },
    onChangeWin10Versions(value) {
      this.ruleApplicabilityModel.win10Versions = value;
    },
    onChangeWin11Versions(value) {
      this.ruleApplicabilityModel.win11Versions = value;
    },
    onChangeVersionName(value) {
      this.ruleApplicabilityModel.osVersionNames = value;
      var found11 = this.ruleApplicabilityModel.osVersionNames.find(
        (x) => x === "Win 11"
      );
      if (found11 === "Win 11") {
        this.win11Selected = true;
        this.ruleApplicabilityModel.architecture = ["64bit"];
      } else {
        this.win11Selected = false;
      }
      var found10 = this.ruleApplicabilityModel.osVersionNames.find(
        (x) => x === "Win 10"
      );
      if (found10 === "Win 10") {
        this.isWindows10 = true;
      } else {
        this.isWindows10 = false;
      }
    },
    onChangeOSType(value) {
      this.ruleApplicabilityModel.osType = value[0];
      if (value.length !== 0 && value[0] === "Windows") {
        this.isWindows = true;
      } else {
        this.isWindows = false;
      }
    },
    getSoftwareRules(software) {
      getSoftwareRules(software.id)
        .then((result) => {
          this.software.ruleDetectionId = result.data.ruleDetection.id;
          this.software.ruleApplicabilityId = result.data.ruleApplicability.id;
        })
        .catch((error) =>
          console.error("The rules could not be loaded: " + error.data)
        );
    },
    async open(softwareId, streamId) {
      this.showDialog = true;
      this.streamId = streamId;
      let software = null;
      await getSoftwarePackage(softwareId)
        .then((response) => {
          software = response.data;
          this.software.allWin10Versions = software.allWin10Versions;
          this.filelist = software.taskInstall.files;
          if (
            software.taskInstall.installationType != null ||
            software.taskinstall.installationType !== ""
          ) {
            this.installationTypeChecked.push(
              software.taskInstall.installationType
            );
          }
          if (software.systemhouses.length !== 0) {
            this.getSystemhousesCustomers(software.systemhouses);
          }
          if (software.customers.length !== 0) {
            this.getCustomersClients(software.customers);
          }
          if (software.taskInstall && software.taskInstall !== null) {
            this.taskinstall = { status: "Disable", rule: true };
          } else {
            this.taskinstall = { status: "Enable", rule: false };
            software.taskInstall = this.model.taskInstall;
          }
          if (software.taskUninstall && software.taskUninstall !== null) {
            this.taskuninstall = { status: "Disable", rule: true };
          } else {
            this.taskuninstall = { status: "Enable", rule: false };
            software.taskUninstall = this.model.taskUninstall;
          }
          if (software.taskUpdate && software.taskUpdate !== null) {
            this.taskupdate = { status: "Disable", rule: true };
          } else {
            this.taskupdate = { status: "Enable", rule: false };
            software.taskUpdate = this.model.taskUpdate;
          }
          this.ruleDetectionModel = software.ruleDetection;
          this.ruleApplicabilityModel = software.ruleApplicability;
          if (software.ruleApplicability.osType != null) {
            this.checkedOSType = [software.ruleApplicability.osType];
            if (software.ruleApplicability.osType === "Windows") {
              this.isWindows = true;
            }
          }
          this.selectedArchitectureApplicability =
            software.ruleApplicability.architecture;
          this.selectedArchitectureDetection =
            software.ruleDetection.architecture;
          this.osVersionNames = software.ruleApplicability.osVersionNames;
          this.selectedWin10Versions = software.ruleApplicability.win10Versions;
          this.selectedWin11Versions = software.ruleApplicability.win11Versions;
          var foundWin10 = this.osVersionNames.find((x) => x === "Win 10");
          if (foundWin10 === "Win 10") {
            this.isWindows10 = true;
          }
          var foundWin11 = this.osVersionNames.find((x) => x === "Win 11");
          if (foundWin11 === "Win 11") {
            this.win11Selected = true;
          }

          this.software = _.cloneDeep(software);
          if (software.taskInstall.intuneFile !== null) {
            this.intuneFiles.push(software.taskInstall.intuneFile);
          }
          this.getSoftwareRules(software);
          if (this.software.version !== "") {
            getNextSoftwareForEdit(
              streamId,
              this.software.version,
              this.software.id
            ).then((response) => {
              this.nextSW = response.data;
            });
            getPrevSoftwareForEdit(
              streamId,
              this.software.version,
              this.software.id
            ).then((response) => {
              this.prevSW = response.data;
            });
            // this.loadSmallerVersions(this.software.version);
          }
        })
        .catch((err) => console.error(err));
    },
    submit(software) {
      this.taskuninstall.status = "Disable";
      this.taskuninstall.rule = true;
      this.taskupdate.status = "Disable";
      this.taskupdate.rule = true;
      this.$refs.form.validate((validForm) => {
        if (this.software.type !== "Task") {
          this.$refs.formRuleApplicability.validate((validApplicability) => {
            this.$refs.formRuleDetection.validate((validDetection) => {
              if (validForm && validApplicability && validDetection) {
                if (this.taskupdate.status === "Enable")
                  delete software.taskUpdate;
                if (this.taskuninstall.status === "Enable")
                  delete software.taskUninstall;
                this.software.ruleDetection = this.ruleDetectionModel;
                this.software.ruleApplicability = this.ruleApplicabilityModel;
                this.software.taskInstall.name =
                  this.software.name + " " + this.software.version;
                var payload = _.cloneDeep(this.software);
                console.log({ payload });
                this.$store.dispatch("editSoftware", payload);
                this.$emit("revisionMessage", software.id);
                this.showDialog = false;
                this.$emit("refresh");
                this.activeNames = ["general"];
                this.$refs.form.resetFields();
                this.$refs.formRuleDetection.resetFields();
                this.$refs.formRuleApplicability.resetFields();
                this.intuneFiles = [];
              } else {
                this.activeNames = [
                  "general",
                  "ruleApplicability",
                  "ruleDetection",
                  "taskInstall",
                  "taskUninstall",
                  "taskUpdate",
                ];
                this.label = "Collapse all";
              }
            });
          });
        } else {
          if (validForm) {
            this.software.taskInstall.name =
              this.software.name + " " + this.software.version;
            var payload = _.cloneDeep(this.software);
            this.$store.dispatch("editSoftware", payload);
            this.$emit("revisionMessage", software.id);
            this.showDialog = false;
            this.$emit("refresh");
            this.activeNames = ["general"];
            this.$refs.form.resetFields();
            this.$refs.formRuleDetection.resetFields();
            this.$refs.formRuleApplicability.resetFields();
          } else {
            this.activeNames = [
              "general",
              "ruleApplicability",
              "ruleDetection",
              "taskInstall",
              "taskUninstall",
              "taskUpdate",
            ];
            this.label = "Collapse all";
          }
        }
      });
    },
    changeAccordion() {
      // this.label = this.label === 'Collapse all' ? 'Expand all' : 'Collapse all'
      if (this.activeNames.length > 0 && this.label === "Expand all") {
        this.label = "Collapse all";
        this.activeNames = [
          "general",
          "ruleApplicability",
          "ruleDetection",
          "taskInstall",
          "taskUninstall",
          "taskUpdate",
        ];
      } else if (this.activeNames.length > 0) {
        this.label = "Expand all";
        this.activeNames = [];
      } else {
        this.label = "Collapse all";
        this.activeNames = [
          "general",
          "ruleApplicability",
          "ruleDetection",
          "taskInstall",
          "taskUninstall",
          "taskUpdate",
        ];
      }
    },
    togglelabel(propname) {
      if (this[propname]["status"] === "Enable") {
        this[propname]["status"] = "Disable";
        this[propname]["rule"] = true;
      } else {
        this[propname]["status"] = "Enable";
        this[propname]["rule"] = false;
      }
    },
    setFiles(file) {
      if (file.length > 0) {
        this.software.icon = { id: file[0].response.id, name: file[0].name };
      } else {
        this.software.icon = { id: "", name: "" };
      }
    },
    handleRemove(a, fileList) {
      this.setFiles(fileList);
    },
    handleSuccess(a, b, fileList) {
      this.setFiles(fileList);
    },
  },
  computed: {
    ...mapState(["rules", "systemhouses"]),
    getTitle() {
      return (
        "Edit software " +
        this.software.name +
        " (RevisionNr: " +
        this.software.displayRevisionNumber +
        ")"
      );
    },
  },
};
</script>

<style lang="css" scoped>
.flex {
  display: flex;
}

.spaceRight {
  padding-right: 20px;
}

.addBtn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
