<template>
  <el-dialog
    v-model="showDialog"
    title="Manage OS Settings Files"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <el-form ref="form" :model="customerImage">
      <el-form-item label="OEM Partition File (.txt)" prop="OEMPartition">
        <el-upload
          :limit="1"
          accept="text/plain"
          multiple
          class="upload-item-file"
          :headers="headers"
          :action="actionOEMPartition + '/' + this.customerImage.id"
          drag
          :before-upload="validateOEMPartition"
          :on-exceed="onExceed"
          :file-list="fileListOEMPartition"
          :on-remove="onRemoveOEMPartition"
          :on-success="onSuccessOEMPartition"
        >
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="Unattend File (.xml)" prop="unattend">
        <el-upload
          accept="text/xml"
          :limit="1"
          class="upload-item-file"
          :headers="headers"
          :action="actionUnattend + '/' + this.customerImage.id"
          drag
          :before-upload="validateUnattendFile"
          :on-exceed="onExceed"
          :file-list="fileListUnattend"
          :on-remove="onRemoveUnattend"
          :on-success="onSuccessUnattend"
        >
          <!-- <i class="el-icon-upload"></i> -->
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import impersonation from "../../impersonation";
import { ElMessage } from "element-plus";
import {
  deleteOEMPartition,
  // deleteOEMLogo,
  // deleteDesktop,
  deleteUnattend,
} from "../../services/customerImages";

export default {
  data() {
    let customerId = impersonation.getCustomer().id;
    return {
      showDialog: false,
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      actionOEMLogo:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customerImages/oemLogo/upload/" +
        customerId,
      actionUnattend:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customerImages/unattend/upload/" +
        customerId,
      actionOEMPartition:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customerImages/oemPartition/upload/" +
        customerId,
      actionDeskBackground:
        window.APPLICATION_SETTING_API_ENDPOINT_URL +
        "/customerImages/deskBackground/upload/" +
        customerId,
      customerImage: {
        name: "",
        description: "",
        subFolderName: "",
        fileName: "",
        downloadLink: "",
        architecture: "",
        language: "",
        version: "",
        update: "",
        vendor: "",
        buildNr: "",
        publishInShop: "",
        desktopFile: {
          id: "",
          name: "",
          guid: "",
        },
        oemLogo: {
          id: "",
          name: "",
          guid: "",
        },
        oemPartition: {
          id: "",
          name: "",
          guid: "",
        },
        unattend: {
          id: "",
          name: "",
          guid: "",
        },
      },
      fileListOEMLogo: [],
      fileListOEMPartition: [],
      fileListUnattend: [],
      fileListDeskBackground: [],
      fileList: [],
    };
  },
  methods: {
    // validateDeskBackground(file) {
    //   var fileType = file.name.split(".")[1];
    //   if (fileType === "deskthemepack") {
    //     return true;
    //   } else {
    //     this.$message.error(
    //       "The desktop background file has to be a .deskthemepack"
    //     );
    //     return false;
    //   }
    // },
    validateOEMPartition(file) {
      if (file.type === "text/plain") {
        return true;
      } else {
        this.$message.error("The OEM partition file has to be a text file");
        return false;
      }
    },
    validateUnattendFile(file) {
      if (file.type === "text/xml") {
        return true;
      } else {
        this.$message.error("The unattend file has to be a xml file");
        return false;
      }
    },
    // validateOEMLogo(file) {
    //   if (file.type === "image/bmp") {
    //     return true;
    //   } else {
    //     this.$message.error("The OEM logo has to be a bmp file");
    //     return false;
    //   }
    // },
    // onSuccessOEMLogo(a, b, fileList) {
    //   let fileName = fileList[0].name;
    //   this.customerImage.oemLogo.name = fileName;
    //   this.customerImage.oemLogo.guid = fileName;
    //   ElMessage.success({
    //     message: "Updated the OEM Logo file successfully",
    //     duration: 5000,
    //   });
    // },
    onSuccessUnattend(a, b, fileList) {
      let fileName = fileList[0].name;
      this.customerImage.unattend.name = fileName;
      this.customerImage.unattend.guid = fileName;
      ElMessage.success({
        message: "Updated the Unattend file successfully",
        duration: 5000,
      });
    },
    onSuccessOEMPartition(a, b, fileList) {
      let fileName = fileList[0].name;
      this.customerImage.oemPartition.name = fileName;
      this.customerImage.oemPartition.guid = fileName;
      ElMessage.success({
        message: "Updated the OEM Partition file successfully",
        duration: 5000,
      });
    },
    // onSuccessDeskBackground(a, b, fileList) {
    //   let fileName = fileList[0].name;
    //   this.customerImage.deskBackground.name = fileName;
    //   this.customerImage.deskBackground.guid = fileName;
    //   ElMessage.success({
    //     message: "Updated the Desktop Background file successfully",
    //     duration: 5000,
    //   });
    // },
    // onExceed() {
    //   this.$message.error("Do not upload more than 1 files");
    // },
    // onRemoveOEMLogo() {
    //   deleteOEMLogo(impersonation.getCustomer().id, this.customerImage.id)
    //     .then(() =>
    //       ElMessage.success({
    //         message: "Deleted the logo file successfully",
    //         duration: 3000,
    //       })
    //     )
    //     .catch((err) => console.log({ err }));
    //   this.fileListOEMLogo = [];
    //   // TODO: delete file in Azure and show Message
    // },
    onRemoveOEMPartition() {
      deleteOEMPartition(impersonation.getCustomer().id, this.customerImage.id)
        .then(() =>
          ElMessage.success({
            message: "Deleted the partition file successfully",
            duration: 3000,
          })
        )
        .catch((err) => console.log({ err }));
      this.fileListOEMPartition = [];
    },
    onRemoveUnattend() {
      deleteUnattend(impersonation.getCustomer().id, this.customerImage.id)
        .then(() =>
          ElMessage.success({
            message: "Deleted the unattend file successfully",
            duration: 3000,
          })
        )
        .catch((err) => console.log({ err }));
      this.fileListUnattend = [];
      // TODO: delete file in Azure and show Message
    },
    // onRemoveDeskBackground() {
    //   deleteDesktop(impersonation.getCustomer().id, this.customerImage.id)
    //     .then(() =>
    //       ElMessage.success({
    //         message: "Deleted the desktheme file successfully",
    //         duration: 3000,
    //       })
    //     )
    //     .catch((err) => console.log({ err }));
    //   this.fileListDeskBackground = [];
    //   // TODO: delete file in Azure and show Message
    // },
    openDialog(customerImageStream) {
      // this.customerImage = customerImage;
      console.log({ customerImageStream });
      if (customerImageStream.unattend !== null) {
        this.fileListUnattend.push(customerImageStream.unattend);
      }
      if (customerImageStream.oemPartition !== null) {
        this.fileListOEMPartition.push(customerImageStream.oemPartition);
      }
      this.showDialog = true;
    },
    onClose() {
      this.customerImage = {
        name: "",
        description: "",
        subFolderName: "",
        fileName: "",
        downloadLink: "",
        architecture: "",
        language: "",
        version: "",
        update: "",
        vendor: "",
        buildNr: "",
        publishInShop: "",
        desktopFile: {
          id: "",
          name: "",
          guid: "",
        },
        oemLogo: {
          id: "",
          name: "",
          guid: "",
        },
        oemPartition: {
          id: "",
          name: "",
          guid: "",
        },
        unattend: {
          id: "",
          name: "",
          guid: "",
        },
      };
      this.fileListOEMLogo = [];
      this.fileListUnattend = [];
      this.fileListOEMPartition = [];
      this.fileListDeskBackground = [];
      this.$store.dispatch(
        "getCustomersOSImages",
        impersonation.getCustomer().id
      );
      this.showDialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
