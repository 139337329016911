export function addAzureData (customerId, payload) {
  return window.axiosInstance.post(`/azureClouds/${customerId}`, payload)
}

export function getCEPs (customerId) {
  return window.axiosInstance.get(`/azureClouds/${customerId}`)
}

export function updateCEP (customerId, payload) {
  return window.axiosInstance.put(`/azureClouds/${customerId}`, payload)
}

export function deleteCEP (customerId, cepId) {
  return window.axiosInstance.delete(`azureClouds/${customerId}/${cepId}`)
}
