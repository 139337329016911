import Impersonation from '../impersonation'

export default {
  name: 'impersonation-mixin',
  computed: {
    customer () {
      return Impersonation.getCustomer()
    }
  }
}
