<template>
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <!-- <el-row type="flex" class="header">
          <el-col class="bs-align-right" :span="24">
            <el-button type="primary" class="button_conf" @click="openCorporateSheet"> Corporate datasheet</el-button>
          </el-col>
        </el-row> -->
        <el-row>
          <div class="container">
            <el-header>Cloud Entry Points</el-header>
            <el-main>
              <el-col class="bs-align-right" :span="24">
                <el-button type="primary" @click="openCEPAddDialog"
                  >Add Cloud Entry Point</el-button
                >
              </el-col>
              <el-table :data="filteredCEPs" row-class-name="clickable">
                <el-table-column label="Name" prop="name"></el-table-column>
                <el-table-column label="Type" prop="type"></el-table-column>
                <el-table-column label="Is Standard" prop="isStandard">
                  <template v-slot="scope">
                    {{ scope.row.isStandard ? "True" : "False" }}
                  </template>
                </el-table-column>
                <el-table-column>
                  <template v-slot="scope">
                    <actions-dropdown
                      :entity="scope.row"
                      :deleteItem="true"
                      :edit="true"
                      @edit="editCEP"
                      @delete="deleteCEP"
                    ></actions-dropdown>
                  </template>
                </el-table-column>
              </el-table>
              <addCEP ref="CEPAddDialog" @openAzure="openCloudAzure"></addCEP>
              <add-cloud-azure ref="addcloudAzure"></add-cloud-azure>
              <edit-azureCEP ref="editAzureCEP"></edit-azureCEP>
            </el-main>
          </div>
          <div class="container">
            <el-header>Storage Entry Points</el-header>
            <el-main>
              <el-col class="bs-align-right" :span="24">
                <el-button
                  type="primary"
                  :disabled="SEPs.length === 0 ? false : true"
                  @click="openAddSEP"
                  >Add Storage Entry Point</el-button
                >
              </el-col>
              <el-table
                :data="SEPs"
                row-class-name="clickable"
                v-loading="sepsLoading"
              >
                <el-table-column label="Resource Group" prop="resourceGrpName">
                </el-table-column>
                <el-table-column label="Type" prop="type"></el-table-column>
                <el-table-column label="Is CSDP" prop="isCSDP">
                  <template v-slot="scope">
                    {{ scope.row.isCSDP ? "True" : "False" }}
                  </template>
                </el-table-column>
                <el-table-column label="Location" prop="location">
                </el-table-column>
                <el-table-column label="Storage Account" prop="storageAccount">
                </el-table-column>
                <el-table-column label="Storage Type" prop="storageAccountType">
                </el-table-column>
                <el-table-column label="Status" prop="status"></el-table-column>
                <el-table-column>
                  <template v-slot="scope">
                    <actions-dropdown
                      :entity="scope.row"
                      :deleteItem="true"
                      :hideRetreatFromShop="true"
                      @delete="deleteSEP"
                      @createSAS="createSASKey"
                      :createSAS="true"
                    >
                    </actions-dropdown>
                  </template>
                </el-table-column>
              </el-table>
              <addSEP ref="addSEP"></addSEP>
            </el-main>
          </div>
          <div class="container">
            <el-header> Base </el-header>
            <el-main>
              <el-row>
                <el-col :span="22" :offset="1">
                  <el-row type="flex" class="header">
                    <el-col :span="8" class="search">
                      <el-input
                        placeholder="Find bases"
                        v-model="search"
                        prefix-icon="Search"
                      >
                        <template #suffix>
                          <el-icon v-if="isSubset" @click="clearSearch">
                            <CircleClose />
                          </el-icon>
                        </template>
                      </el-input>
                    </el-col>
                    <el-col :span="8">
                      <div class="results" v-if="isSubset">
                        showing {{ filterfedBases.length }} of
                        {{ bases.length }}
                      </div>
                    </el-col>
                    <el-col :span="8" class="bs-align-right">
                      <el-button type="primary" @click="openBaseAdd">
                        <el-icon>
                          <Plus />
                        </el-icon>
                        Add base</el-button
                      >
                    </el-col>
                  </el-row>
                  <el-table
                    :data="filteredBases"
                    row-class-name="clickable"
                    v-loading.body="basesLoading"
                  >
                    <el-table-column prop="name" label="Name" width="250">
                      <template v-slot="scope">
                        <router-link
                          :to="{
                            name: 'domains',
                            params: { id: scope.row.id },
                          }"
                        >
                          <el-button type="text" size="default">{{
                            scope.row.name
                          }}</el-button>
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="status"
                      label="Status"
                      show-overflow-tooltip
                    >
                      <template v-slot="scope">
                        <img
                          height="20"
                          @click="openBaseStatus(scope.row)"
                          v-if="
                            scope.row.baseStatus === null ||
                            scope.row.baseStatus.status === null
                          "
                          src="../../../static/notStarted.png"
                        />
                        <img
                          height="20"
                          @click="openBaseStatus(scope.row)"
                          v-else-if="scope.row.baseStatus.status === 'Creating'"
                          src="../../../static/loading-gif.gif"
                        />
                        <img
                          height="20"
                          @click="openBaseStatus(scope.row)"
                          v-else-if="scope.row.baseStatus.status === 'Error'"
                          src="../../../static/error.png"
                        />
                        <img
                          height="20"
                          @click="openBaseStatus(scope.row)"
                          v-else-if="scope.row.baseStatus.status === 'Created'"
                          src="../../../static/success.png"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="subscription"
                      label="Subscription"
                      show-overflow-tooltip
                    >
                      <template v-slot="scope">
                        {{ scope.row.subscription.name }}
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template v-slot="scope">
                        <actions-dropdown
                          :entity="scope.row"
                          @delete="deleteBase"
                        ></actions-dropdown>
                      </template>
                    </el-table-column>
                    <el-table-column label="Details" width="170">
                      <template v-slot="scope">
                        <el-button
                          size="small"
                          @click.stop="openBaseParameters(scope.row)"
                        >
                          <el-icon>
                            <Setting />
                          </el-icon>
                          Base Parameters</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
              <base-add ref="baseAdd"></base-add>
              <base-add-status ref="baseAddStatus"></base-add-status>
            </el-main>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import BaseAdd from "./BaseAdd";
import impersonation from "../../impersonation";
import AddCEP from "../clouds/AddCEP.vue";
import EditAzureCEP from "../clouds/EditAzureCEP";
import AddCloudAzure from "../clouds/AddCloudAzure.vue";
import ActionsDropdown from "../shared/ActionsDropdown";
import AddSEP from "../sep/AddSEP.vue";
import { getSASKey } from "../../services/storageEntryPoints";
import { success, errorMessage } from "../../store/modules/util";
import { deleteBase } from "../../services/bases";
import BaseAddStatus from "./BaseAddStatus";

export default {
  name: "bases",
  mixins: [FilterMixin],
  components: {
    BaseAdd,
    AddCEP,
    AddCloudAzure,
    ActionsDropdown,
    EditAzureCEP,
    AddSEP,
    BaseAddStatus,
  },
  beforeCreate() {},
  created() {
    let customerId = impersonation.getCustomer().id;
    this.$store.dispatch("getBases");
    this.$store.dispatch("getCustomersCEPs", customerId);
    this.$store.dispatch("getSEPs", customerId);
  },
  data() {
    return {
      search: "",
      activeNames: [],
    };
  },
  methods: {
    openBaseStatus(val) {
      this.$refs.baseAddStatus.openDialog(val);
    },
    deleteBase(base) {
      this.$confirm(
        "This will permanently delete the base and it's components. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteBaseSuccess.bind(this, base), this.deleteError);
    },
    deleteBaseSuccess(base) {
      deleteBase(impersonation.getCustomer().id, base.id)
        .then(() => {
          success();
          this.$store.dispatch("getBases");
        })
        .catch((err) => errorMessage(err.response.data));
    },
    createSASKey(sep) {
      getSASKey(impersonation.getCustomer().id, sep)
        .then(() => {
          success();
        })
        .catch((err) => errorMessage(err.response.data));
    },
    deleteSEP(sep) {
      this.$confirm(
        "This will permanently delete the SEP. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSEPSuccess.bind(this, sep), this.deleteError);
    },
    deleteSEPSuccess(sep) {
      let data = {
        customerId: impersonation.getCustomer().id,
        sepId: sep.id,
      };
      this.$store.dispatch("deleteSEP", data);
    },
    deleteError() {},
    openAddSEP() {
      this.$refs.addSEP.openDialog();
    },
    editCEP(cep) {
      this.$refs.editAzureCEP.open(cep);
    },
    deleteCEP(cep) {
      var payload = {
        id: cep.id,
        customerId: impersonation.getCustomer().id,
      };
      this.$store.dispatch("deleteCEP", payload);
    },
    openCEPAddDialog() {
      this.$refs.CEPAddDialog.open();
    },
    openBaseAdd() {
      this.$refs.baseAdd.open();
    },
    openBase(base) {
      this.$router.push({ name: "domains", params: { id: base.id } });
    },
    openBaseParameters(base) {
      this.$router.push({ name: "parameters", params: { id: base.id } });
    },
    openCustomerParameters() {
      this.$router.push({
        name: "customer-parameters",
        params: { id: impersonation.getCustomer().id },
      });
    },
    openCloudAzure() {
      this.$refs.addcloudAzure.open();
    },
    openCorporateSheet() {
      this.$router.push({ name: "corporate-sheet" });
    },
  },
  computed: {
    ...mapState({
      bases: (state) => state.base.bases,
      basesLoading: (state) => state.base.basesLoading,
      CEPs: (state) => state.CEPs,
      SEPs: (state) => state.SEPs,
      sepsLoading: (state) => state.sepsLoading,
    }),
    filteredBases() {
      return this.filterEntities(this.bases, this.search);
    },
    isSubset() {
      return this.bases.length !== this.filteredBases.length;
    },
    filteredCEPs() {
      return this.CEPs;
    },
  },
};
</script>
<style lang="css" scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
