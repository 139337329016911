<template lang="html">
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <h1>Corporate Datasheet Details</h1>
        <!--<h3>Corporate</h3>
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1" title="Corporate">
            <general-settings></general-settings>
          </el-collapse-item>
        </el-collapse>
        <br/>-->
        <h3>Companies</h3>
        <companies-settings></companies-settings>
        <br/>
        <h3>Persons</h3>
        <persons-settings></persons-settings>
        <br/>
        <h3>Locations</h3>
        <location-settings></location-settings>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PersonsSettings from "../customers/settings/PersonsSettings";
import CompaniesSettings from "../customers/settings/CompaniesSettings";
import LocationSettings from "../locations/LocationSettings";
export default {
  name: "corporate-sheet",
  mixins: [],
  components: {
    PersonsSettings,
    CompaniesSettings,
    LocationSettings,
  },
  data() {
    return {
      // For opening collapseable by default
      activeNames: [],
    };
  },
  methods: {},
  computed: {},
};
</script>


<style lang="css" scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
