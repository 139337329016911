export function addOrder (orderitem, customerId) {
  return window.axiosInstance.post(`/order/${customerId}`, orderitem)
}

export function orderSoftware (shopItem, customerId) {
  return window.axiosInstance.post(`/order/software/${customerId}`, shopItem)
}

export function orderImage (shopItem, customerId) {
  return window.axiosInstance.post(`/order/image/${customerId}`, shopItem)
}

export function orderDriver (payload, customerId) {
  return window.axiosInstance.post(`/order/driver/${customerId}`, payload)
}

export function orderSelectedSoftware (payload, customerId) {
  return window.axiosInstance.post(`/order/selectedSoftware/${customerId}`, payload)
}
