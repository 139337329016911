<template>
  <div>
    <div class="center">
      <span>{{ defaultMessage }}</span>
    </div>
    <el-button type="primary" @click="backToLogin">Back</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultMessage:
        "Your user " +
        this.$route.params.email +
        " lacks the permissions to work in the WPM portal or is inactive.\n Please contact your administrator to get the required permissions or reactivate your account",
    };
  },
  computed: {},
  methods: {
    backToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="css">
.center {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 50%;
  min-width: 100vh;
  min-height: 50vh;
}
</style>
