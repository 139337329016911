<template>
  <el-dialog
    v-model="showDialog"
    title="Software"
    width="85%"
    :close-on-click-modal="false"
  >
    <div>
      {{ peekedMessages }}
      <div>
        <el-select label="Type" v-model="type">
          <el-option v-for="t in types" :key="t" :value="t"></el-option>
        </el-select>
      </div>
      <div class="swAssignBody">
        <div class="sw" v-if="type == 'Software'">
          <el-select v-if="type == 'Software'" v-model="selectedSWFilter">
            <el-option
              v-for="swf in swFilter"
              :key="swf"
              :value="swf"
            ></el-option>
          </el-select>
          <el-table :data="swToDisplay" highlight-current-row>
            <el-table-column label="Id" prop="id"></el-table-column>
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column label="Version" prop="version"></el-table-column>
            <el-table-column>
              <template v-slot="scope">
                <el-button
                  v-if="selectedSWFilter === 'New Software'"
                  type="primary"
                  @click="assignNewSoftware(scope.row)"
                  >Install</el-button
                >
                <el-button
                  v-else-if="selectedSWFilter === 'Installed Software'"
                  type="primary"
                  >Uninstall</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="task" v-else-if="type == 'Task'">
          <el-select v-if="type == 'Task'" v-model="selectedTaskFilter">
            <el-option
              v-for="tf in taskFilter"
              :key="tf"
              :value="tf"
            ></el-option>
          </el-select>
          <el-table :data="tasksToDisplay" highlight-current-row>
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column label="Version" prop="version"></el-table-column>
            <el-table-column>
              <template v-slot="scope">
                <el-button type="primary" @click="assignNewSoftware(scope.row)"
                  >Assign</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- <template v-slot:footer>
      <el-button type="primary" @click="submit()">Save</el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import _ from "lodash";
import impersonation from "../../impersonation";

export default {
  name: "assign-software",
  mixins: [FilterMixin],
  data() {
    return {
      peekedMessages: [],
      type: "",
      types: ["Software", "Task"],
      swFilter: ["Installed Software", "New Software", "Assigned Software"],
      taskFilter: ["New Tasks", "Assigned Tasks"],
      selectedSWFilter: "",
      selectedTaskFilter: "",
      showDialog: false,
      warnRequired: true,
      clientId: "",
      client: { managedSoftware: [] },
      deassignSoftware: [],
      multipleSelectionAvail: "",
      searchOne: "",
    };
  },
  methods: {
    open(clientId, client) {
      let customerId = impersonation.getCustomer().id;
      window.axiosInstance
        .get(`customers/${customerId}/clients/${clientId}/getAssignedTasks`)
        .then((response) => (this.peekedMessages = response.data.Result))
        .catch((error) => console.log({ error }));
      this.clientId = clientId;
      this.client = client;
      this.warnRequired = false;
      this.showDialog = true;
    },
    submit() {
      let savedList = this.assignSoftwares;
      let unsaveList = this.deassignSoftware;
      let vm = this;
      for (let entry in savedList) {
        savedList[entry].clientId = vm.clientId;
        vm.$store.dispatch("addSoftwareAssign", savedList[entry]);
      }
      for (let index in unsaveList) {
        unsaveList[index].clientId = vm.clientId;
        vm.$store.dispatch("deleteSoftwareAssign", unsaveList[index]);
      }
      this.showDialog = false;
    },
    handleSelectionAvail(val) {
      this.multipleSelectionAvail = val;
    },
    handleSelectionAssign(val) {
      this.multipleSelectionAssign = val;
    },
    assignNewSoftware(sw) {
      console.log("CLIENTID: ", this.clientId);
      var payload = { id: sw.id, clientId: _.clone(this.clientId) };
      console.log({ payload });
      this.$store.dispatch("addSoftwareAssign", payload);
    },
    warnInstall(el) {
      if (!this.warnRequired && el) {
        this.$alert("This will force install the software on the client", {
          confirmButtonText: "OK",
          type: "warning",
          callback: () => {
            this.warnRequired = true;
          },
        });
      }
    },
    clearSearchOne() {
      this.searchOne = "";
    },
  },
  computed: {
    ...mapState([
      "customersSoftware",
      "loadingCustomerSoftware",
      "assignSoftwares",
      "softwareAssignLoading",
    ]),
    softwarePackages() {
      return this.$store.state.customersSoftware.filter(
        (e) => e.type == "Software"
      );
    },
    taskPackages() {
      return this.$store.state.customersSoftware.filter(
        (e) => e.type == "Task"
      );
    },
    swToDisplay() {
      var result = [];
      if (this.selectedSWFilter === "Installed Software") {
        // TODO: save and load installed software -> filter these
      } else if (this.selectedSWFilter === "New Software") {
        this.softwarePackages.forEach((el) => {
          console.log({ el });
          if (
            this.peekedMessages.find(
              (x) => x.Body.CustomerSoftwareId == el.id
            ) === undefined
          ) {
            result.push(el);
          }
        });
      } else if (this.selectedSWFilter === "Assigned Software") {
        result = this.softwarePackages.filter((el) => {
          return this.peekedMessages.find((x) => {
            return x.Body.CustomerSoftwareId == el.id;
          });
        });
      }
      return result;
    },
    tasksToDisplay() {
      let result = [];
      if (this.selectedTaskFilter === "New Tasks") {
        this.taskPackages.forEach((el) => {
          console.log({ el });
          if (
            this.peekedMessages.find((x) => {
              x.Body.CustomerSoftwareId == el.id;
            }) === undefined
          ) {
            result.push(el);
          }
        });
      } else if (this.selectedTaskFilter === "Assigned Tasks") {
        result = this.taskPackages.filter((el) => {
          return this.peekedMessages.find((x) => {
            return x.Body.CustomerSoftwareId == el.id;
          });
        });
      }
      return result;
    },
    // filteredSoftwares() {
    //   let vm = this;
    //   var temp = [];
    //   if (this.type != "") {
    //     temp = vm.$store.state.customersSoftware.filter(
    //       (e) => e.type === this.type
    //     );
    //   } else {
    //     temp = vm.$store.state.customersSoftware.filter(
    //       (e) => e.type === "Software"
    //     );
    //   }
    //   if (vm.$store.state.assignSoftwares.length) {
    //     for (let index in vm.$store.state.assignSoftwares) {
    //       let arrayToRemove = vm.$store.state.assignSoftwares[index];
    //       temp = temp.filter((e) => e.id !== arrayToRemove.id);
    //     }
    //   }
    //   return this.filterEntities(temp, this.search);
    // },
    isSubset() {
      return this.customersSoftware.length !== this.filteredSoftwares.length;
    },
    filteredAssigned() {
      return this.filterEntities(this.assignSoftwares, this.searchOne);
    },
    isSubsetOne() {
      return this.assignSoftwares.length !== this.filteredAssigned.length;
    },
  },
};
</script>

<style scoped>
</style>
