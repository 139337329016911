<template>
  <div class="padding">
    <add-person-dialog
      ref="addPersonDialog"
      :showCategorySelection="false"
    ></add-person-dialog>
    <!-- <add-location
      ref="addLocationDialog"
      @loadLocations="getLocations"
      :showCompanies="false"
      :compId="this.mainCompId"
    >
    </add-location> -->
    <el-form :model="formModel" ref="form" :rules="rules">
      <div class="form-item-container">
        <h3>Corporate</h3>
        <el-form-item label="Name" prop="compName">
          <el-input placeholder="Insert a name" v-model="formModel.compName">
          </el-input>
        </el-form-item>
        <el-form-item label="Form Of Organization" prop="organizationForm">
          <el-input
            v-model="formModel.organizationForm"
            placeholder="Insert the form of organization"
          ></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input
            v-model="formModel.description"
            placeholder="Insert a Description"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select v-model="formModel.type" placeholder="Insert a Type">
            <el-option
              v-for="t in typeOptions"
              :label="t.value"
              :key="t.key"
              :value="t.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Website" prop="websiteLink">
          <el-input
            placeholder="Insert link to website"
            v-model="formModel.websiteLink"
          ></el-input>
        </el-form-item>
        <el-form-item label="Headquarter" prop="headquarter">
          <el-select
            v-model="formModel.headquarter"
            @change="openCreateLocationDialog()"
            placeholder="Select the headquarter"
          >
            <el-option
              key="new-location"
              value="new-location"
              label="+ New Location"
            >
            </el-option>
            <el-option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
              :label="location.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Technical Expert" prop="expert">
          <el-select
            @change="openCreatePersonDialog()"
            placeholder="Select the expert"
            v-model="formModel.expert"
          >
            <el-option key="new-person" value="new-person" label="+ New Person">
            </el-option>
            <el-option
              v-for="person in persons"
              :key="person.id"
              :value="person.id"
              :label="getName(person)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="btn-container">
      <el-button type="primary" @click="editData()"> Edit </el-button>
    </div>
    -->
  </div>
</template>

<script>
// Imports
// import impersonation from '../../../impersonation'
import AddPersonDialog from "./dialogs/AddPersonDialog";
// import AddLocation from "../../locations/AddLocation.vue";

// VueJS data & logic
export default {
  name: "general-settings",
  // The components used in here
  mixins: [],
  created() {
    this.getGeneralData();
    // Fetch the companies & the locations
    this.getPersons();
    this.getLocations();
    // Fill data from passed props
    this.fillData();
  },
  components: {
    AddPersonDialog,
    // AddLocation,
  },
  data() {
    return {
      typeOptions: [
        {
          value: "vendor",
          label: "Vendor",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "unspecified",
          label: "Unspecified",
        },
      ],
      formModel: {
        compName: "",
        organizationForm: "",
        headquarter: "",
        websiteLink: "",
        expert: "",
        description: "",
        type: "",
      },
      rules: {
        compName: [
          {
            required: true,
            message: "Please insert a name",
            trigger: "blur",
          },
        ],
        organizationForm: [
          {
            required: true,
            message: "Please insert the form of organization",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please insert a description",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please insert a type",
            trigger: "change",
          },
        ],
        headquarter: [
          {
            required: true,
            message: "Please insert a headquarter",
            trigger: "change",
          },
        ],
        websiteLink: [
          {
            required: true,
            message: "Please insert the link to the website",
            trigger: "blur",
          },
        ],
        expert: [
          {
            required: true,
            message: "Please insert the expert",
            trigger: "change",
          },
        ],
      },
      activeNames: [],
    };
  },
  methods: {
    getGeneralData() {
      this.$store
        .dispatch("getMainCompanyAndExpert", this.customerId)
        .then((response) => this.fillData(response.data));
      // Fetch the companies & the locations
      this.getPersons();
      this.getLocations();
    },
    fillData() {
      this.formModel.compName = this.companyName;
      this.formModel.organizationForm = this.formOfOrganization;
      this.formModel.websiteLink = this.link;
      this.formModel.description = this.description;
      this.formModel.type = this.type;
    },
    editData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // Do something
          let payload = this.createPayload();
          this.$store
            .dispatch("updateCorporateData", payload)
            .then((response) => {
              this.$emit("refreshData", response.data);
              this.$refs.form.resetFields();
              this.getPersons();
              this.getLocations();
              this.showDialog = false;
            });
        }
      });
    },
    getPersons() {
      this.$store
        .dispatch("getCustomersPersons", this.$props.customerId)
        .then((response) => this.$store.commit("setPersons", response.data));
    },
    getLocations() {
      this.$store
        .dispatch("returnCustomersLocations", this.$props.customerId)
        .then((response) =>
          this.$store.commit("setCustomersLocations", response.data)
        );
    },
    setExpert(expertId) {
      this.formModel.expert = expertId;
    },
    setHeadquarter(headquarterId) {
      this.formModel.headquarter = headquarterId;
    },
    getName(person) {
      return person.givenName + " " + person.surname;
    },
    openCreateLocationDialog() {
      if (this.formModel.headquarter === "new-location") {
        // Open dialog
        this.$refs.addLocationDialog.openDialog();
        // Reset value of headquarter to passed prop
        this.formModel.headquarter = this.headquarterId;
      }
    },
    openCreatePersonDialog() {
      if (this.formModel.expert === "new-person") {
        // Open dialog
        this.$refs.addPersonDialog.openDialog();
        // Reset value of expert to passed prop
        this.formModel.expert = this.expertId;
      }
    },
    createPayload() {
      return {
        expertId: this.formModel.expert,
        headquarterId: this.formModel.headquarter,
        corporateName: this.formModel.compName,
        formOfOrganization: this.formModel.organizationForm,
        linkWebsite: this.formModel.websiteLink,
        description: this.formModel.description,
        type: this.formModel.type,
        customerId: this.customerId,
      };
    },
    openDialog() {
      this.$refs.editDataDialog.setExpert(this.expertId);
      this.$refs.editDataDialog.setHeadquarter(this.headquarterId);
      this.$refs.editDataDialog.openDialog();
    },
  },
  computed: {
    locations: {
      get() {
        return this.$store.state.customersLocations;
      },
      set() {
        // Do nothing
      },
    },
    persons: {
      get() {
        return this.$store.state.persons;
      },
      set() {
        // Do nothing
      },
    },
  },
  props: [
    "companyName",
    "formOfOrganization",
    "headquarterId",
    "link",
    "expertId",
    "mainCompId",
    "type",
    "description",
    "customerId",
  ],
};
</script>

<style>
.padding {
  margin: 0 auto;
  width: 75%;
  padding: 25px;
}

.form-item-container {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 15px;
}

.btn-container {
  padding-top: 15px;
}
</style>
