export function addCompany (company) {
  return window.axiosInstance.post(`/companies`, company)
}

export function getCustomersCompanies (customerId) {
  return window.axiosInstance.get(`/companies/${customerId}`)
}

export function updateMainCompany (companyId, payload) {
  return window.axiosInstance.put(`/companies/${companyId}`, payload)
}

export function deleteCompany (companyId) {
  return window.axiosInstance.delete(`companies/${companyId}`)
}
