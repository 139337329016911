<template>
  <el-dialog v-model="showDialog" title="Add new script version">
    <el-form ref="form" :rules="rules" :model="adminOption">
      <el-form-item label="Script" prop="content">
        <drop @drop="handleDrop" class="drop">
          <el-input
            :autosize="{ minRows: 5 }"
            id="adminVersionAdd"
            ref="adminVersionAdd"
            type="textarea"
            v-model="adminOption.content"
            wrap="off"
            >{{}}
          </el-input>
        </drop>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button @click="submit(deviceOption)" type="primary">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import {
  getAdminScriptVersion,
  addAdminScriptVersion,
} from "../../services/adminOptions";
import { Drop } from "vue-drag-drop";
import Codemirror from "../../../static/codemirror/lib/codemirror";
require("../../../static/codemirror/addon/edit/matchbrackets");
require("../../../static/codemirror/mode/powershell/powershell");
require("../../../static/codemirror/doc/docs.css");
require("../../../static/codemirror/lib/codemirror.css");
require("../../../static/codemirror/theme/monokai.css");

export default {
  emits: ["refreshAdminOptions"],
  components: { Drop },
  data() {
    let validateContent = (rule, value, callback) => {
      let content = this.editor.getDoc().getValue("\n");
      if (content === "") {
        callback(new Error("Please add script"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      adminOption: {
        id: "",
        content: "",
      },
      editor: "",
      rules: {
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.adminOption.content = this.editor.getDoc().getValue("\n");
          addAdminScriptVersion(
            this.adminOption.id,
            _.cloneDeep(this.adminOption)
          )
            .then(() => {
              this.$emit("refreshAdminOptions");
            })
            .catch((error) => console.log({ error }));

          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
    handleDrop(data, e) {
      let vm = this;
      e.preventDefault();
      let reader = new window.FileReader();
      let files = e.target.files || e.dataTransfer.files;
      reader.onload = function () {
        vm.adminOption.content = reader.result;
      };
      reader.readAsText(files.item(0));
    },
    openDialog(adminOption) {
      this.adminOption = adminOption;
      let vm = this;
      vm.adminOption.id = adminOption.id;
      this.showDialog = true;
      getAdminScriptVersion(this.adminOption.id)
        .then((response) => {
          let data = response.data;
          vm.$nextTick(() => {
            if (document.getElementsByClassName("CodeMirror").length === 0) {
              var inputElement = document.getElementById("adminVersionAdd");
              vm.editor = Codemirror.fromTextArea(inputElement, {
                mode: "powershell",
                lineNumbers: true,
                theme: "monokai",
                indentUnit: 4,
                tabMode: "shift",
                matchBrackets: true,
              });
              vm.editor.getDoc().setValue(data.content);
              vm.adminOption.content = data.content;
            }
          });
        })
        .catch((error) => console.log({ error }));
    },
  },
};
</script>

<style lang="css" scoped>
</style>
