import { getUsers } from '../services/users'

function signInSuccess(response) {
  const token = response.data.token
  window.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  var claims = token.split('.')[1]
  // let result = window.encodeURIcomponent(claims)
  let data = window.atob(claims)
  const auth = JSON.parse(data) // decode JWT payload into JSON
  localStorage.setItem('token', token)
  localStorage.setItem('auth', JSON.stringify(auth))
}

export default {
  signIn(credentials) {
    return window.axiosInstance.post('/auth', credentials).then(signInSuccess)
  },

  signOut() {
    window.axiosInstance.put('auth')
      .finally(() => {
        window.axiosInstance.defaults.headers.common['Authorization'] = null
        localStorage.removeItem('token')
        localStorage.removeItem('auth')
      })
  },

  recoverAuth() {
    const token = localStorage.getItem('token')

    if (token) {
      window.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }

    return getUsers().then(() => {
      return this.getAuth()
    }, () => {
      return false
    })
  },

  getAuth() {
    try {
      var auth = JSON.parse(localStorage.getItem('auth'))
      return auth
    } catch (error) {
      console.log({ error })
    }
  },

  getPermission() {
    const auth = this.getAuth()
    if (!auth) {
      return 'anonymous'
    }
    return auth.admin === 'True' ? 'admin' : auth['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  },
  isAdmin() {
    const auth = this.getAuth()
    if (!auth) {
      return 'anonymous'
    }
    return auth.admin === 'True'
  },
  hasPermission(permission) {
    const permissions = { 'admin': 100, 'systemhouse': 70, 'customer': 40, 'anonymous': 0 }
    return permissions[permission] <= permissions[this.getPermission()]
  }
}
