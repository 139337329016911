<template>
  <div>
    <InfoPanel text="Customer Panel" color="#0b57a5"></InfoPanel>
    <el-menu
      mode="horizontal"
      :default-active="routeName"
      :router="true"
      class="bs-header"
      background-color="#0b57a5"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <!-- <el-menu-item route="/customer-settings" index="customer-settings">General Settings</el-menu-item> -->
      <!--<el-menu-item route="/bases" index="bases">Environment</el-menu-item>-->
      <el-sub-menu
        index="bases"
        style="float: left; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>Environment</template>
        <el-menu-item style="min-width: auto" index="company" route="/company"
          >Company</el-menu-item
        >
        <el-menu-item style="min-width: auto" index="iam" route="/iam"
          >IAM</el-menu-item
        >
        <el-menu-item style="min-width: auto" index="bases" route="/bases"
          >Base Management</el-menu-item
        >
        <el-menu-item
          style="min-width: auto"
          index="asset"
          route="/asset-management"
          >Asset Management</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu index="shops">
        <template v-slot:title>Catalog</template>
        <el-menu-item route="/buy" index="buy">Hardware</el-menu-item>
        <el-menu-item route="/imageShop" index="imageShop">OS</el-menu-item>
        <el-menu-item route="/softwareShop" index="softwareShop"
          >Software</el-menu-item
        >
        <el-menu-item route="/driverShop" index="driverShop"
          >Driver</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu
        index="smartdeploy"
        style="float: left; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>Hardware</template>
        <el-menu-item route="/smartdeploy" index="devices"
          >Device Overview</el-menu-item
        >
        <el-menu-item route="/hardwareModels" index="hardwareModels"
          >Hardware Models</el-menu-item
        >
        <el-menu-item route="/driverMamangement" index="driverMamangement"
          >Driver Management</el-menu-item
        >
      </el-sub-menu>
      <el-sub-menu
        index="customerSoftware"
        style="float: left; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>Software</template>
        <el-menu-item
          style="min-width: auto"
          index="generalSettings"
          route="/generalSettings"
          >OS Management</el-menu-item
        >
        <!-- General Settings -->
        <el-menu-item route="/customerSoftware" index="customerSoftware"
          >App Management</el-menu-item
        >
        <el-menu-item route="/officeSettings" index="officeSettings"
          >Office Settings</el-menu-item
        >
        <el-menu-item route="/taskManagement" index="taskManagement"
          >Task Management</el-menu-item
        >
        <el-menu-item route="/reports" index="reports">Reports</el-menu-item>
        <el-menu-item index="softwareDashboard" :route="swDashboardRoute"
          >Update Dashboard</el-menu-item
        >
        <el-menu-item index="releasePlans" route="/releasePlans"
          >Release Planning</el-menu-item
        >
        <el-sub-menu index="downloads">
          <template v-slot:title>Download</template>
          <el-menu-item
            index="downloadPE"
            :route="currentRouteName"
            style="min-width: auto"
            @click="downloadwinpe"
            >Bootable (WinPE)</el-menu-item
          >
          <el-menu-item
            index="downloadSD"
            :route="currentRouteName"
            style="min-width: auto"
            @click="downloadsmartdeploy"
            >SmartDeploy</el-menu-item
          >
          <el-menu-item
            index="downloadRegisterApp"
            :route="currentRouteName"
            @click="downloadRegisterClientApp"
            >AutoRegisterClient.exe</el-menu-item
          >
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu
        index="customSettings"
        style="float: left; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>Custom Settings</template>
        <el-menu-item
          style="min-width: auto"
          index="smartDeploySettings"
          route="/smartDeploySettings"
          >Smart Deploy</el-menu-item
        >
        <!-- Write new page for SD settings -->
        <el-menu-item
          style="min-width: auto"
          index="customer-parameters"
          :route="corporateParameterRoute"
          >Corporate Parameters</el-menu-item
        >
        <!-- Corporate Parameteres -->
        <el-menu-item index="general-settings" route="/general-settings"
          >General Settings</el-menu-item
        >
      </el-sub-menu>
      <el-menu-item route="/workflows" index="workflows"
        >Workflows</el-menu-item
      >
      <el-menu-item route="/domainregistrations" index="domainregistrations"
        >Domain Registration</el-menu-item
      >
      <el-menu-item route="/certifications" index="certifications"
        >Certifications</el-menu-item
      >
      <el-sub-menu
        index="additional"
        style="float: right; margin-right: 10px; min-width: auto"
      >
        <template v-slot:title>{{ auth.sub }}</template>
        <el-menu-item
          index="role"
          ref="role"
          style="min-width: auto"
          :disabled="true"
        >
          Role - {{ permission }}
        </el-menu-item>
        <el-menu-item
          style="min-width: auto"
          index="settings"
          route="settingscustomer"
          >Settings</el-menu-item
        >
        <el-menu-item style="min-width: auto" index="login" @click="signOut"
          >Logout</el-menu-item
        >
      </el-sub-menu>
      <el-menu-item style="float: right">{{ customer.name }}</el-menu-item>
      <el-menu-item
        v-if="isSystemhouse"
        route="/customers"
        style="float: right"
        index="customers"
        >{{ customer.systemhouse.name }}</el-menu-item
      >
    </el-menu>
    <bug-report ref="bugReport"></bug-report>
  </div>
</template>

<script>
import _ from "lodash";
import Auth from "../auth";
import Impersonation from "../impersonation";
import ImpersonationMixin from "../mixins/ImpersonationMixin";
import Permission from "../mixins/Permission";
import InfoPanel from "./InfoPanel";
import BugReport from "./BugReport.vue";
import { getWinPESAS, getRegisterClientSAS } from "../services/customers";
import * as Msal from "@azure/msal-browser";

export default {
  name: "header-inventory",
  created() {
    this.$store.dispatch("getBases");
  },
  components: {
    InfoPanel,
    BugReport,
  },
  mixins: [ImpersonationMixin, Permission],
  methods: {
    async downloadRegisterClientApp() {
      var url = "";
      await getRegisterClientSAS()
        .then((response) => {
          url = response.data;
        })
        .catch((err) => console.log({ err }));
      var fileLink = document.createElement("a");
      fileLink.setAttribute("href", url);
      fileLink.click();
    },
    downloadsmartdeploy() {
      this.$store.dispatch("downloadSmartDeploy");
      this.$notify.success({
        title: "Info",
        message: "The download will start shortly...",
        duration: 5000,
      });
    },
    async downloadwinpe() {
      var customer = null;
      await getWinPESAS(Impersonation.getCustomer().id).then((response) => {
        customer = response.data;
      });
      var fileLink = document.createElement("a");
      fileLink.setAttribute("href", customer.winPEDownloadLink);
      fileLink.click();
    },
    signOut() {
      if (this.$store.state.loginType == "microsoft") {
        const msalConfig = {
          auth: {
            clientId: window.APPLICATION_SETTING_B2C_CLIENT_ID,
            authority: window.APPLICATION_SETTING_B2C_AUTHORITY,
            redirectUri: window.APPLICATION_SETTING_B2C_REDIRECT_URL,
            knownAuthorities: window.APPLICATION_SETTING_B2C_KNOWN_AUTHS,
            autoRefershToken: false,
          },
        };
        const msalInstance = new Msal.PublicClientApplication(msalConfig);
        // sessionStorage.clear();
        msalInstance.logoutPopup();
      }

      Auth.signOut();
      Impersonation.removeCustomer();
      this.$store.commit("initState");
    },
    openBugReportDialog() {
      this.$refs.bugReport.openDialog();
    },
  },
  computed: {
    currentRouteName() {
      var route = this.$route;
      return route.redirectedFrom;
    },
    getUserName() {
      var removeDiacritics = require("diacritics").remove;
      let name = this.auth.name.toString("utf8");
      name = name.replace(/\u00e4/g, "ae");
      name = name.replace(/\u00fc/g, "ue");
      name = name.replace(/\u00f6/g, "oe");
      name = name.replace(/\u00df/g, "ss");
      name = removeDiacritics(name);
      return name;
    },
    corporateParameterRoute() {
      return "/customer/" + Impersonation.getCustomer().id + "/";
    },
    swDashboardRoute() {
      return "/softwareDashboard/" + Impersonation.getCustomer().id;
    },
    auth() {
      return Auth.getAuth();
    },
    permission() {
      let role = Auth.getPermission();
      if (role === "systemhouse") {
        return "Systemhouse Manager";
      }
      if (role === "customer") {
        return "Customer Manager";
      }
      if (role === "admin") {
        return "Admin";
      }
      return null;
    },
    routeName() {
      return this.$route.name;
    },
    baseName() {
      let baseName = null;
      if (this.$route.name === "domains") {
        const base = _.find(this.$store.state.bases, this.$route.params);
        if (base) {
          baseName = base.name;
        }
      }
      return baseName;
    },
  },
};
</script>

<style scoped>
.menu-breadcrumbs.el-menu-item:hover,
.menu-breadcrumbs.el-menu-item:focus {
  background: none;
  cursor: initial;
}

.el-menu--horizontal > .el-menu-item.menu-breadcrumbs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0;
}
</style>

<style>
.menu-breadcrumbs .el-breadcrumb__inner {
  color: #fff;
}

.menu-breadcrumbs .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #ffd04b;
}
</style>
