<template>
  <div class="ip">
    <el-input-number v-model="ipRange.octet1" :min="0" :max="255" :controls="false" @change="update1"></el-input-number>
    <div class="el-input-number divider-dot">.</div>
    <el-input-number v-model="ipRange.octet2" :min="0" :max="255" :controls="false" @change="update2"></el-input-number>
    <span class="el-input-number divider-dot">.</span>
    <el-input-number v-model="ipRange.octet3" :min="0" :max="255" :controls="false" @change="update3"></el-input-number>
    <span class="el-input-number divider-dot">.</span>
    <el-input-number v-model="ipRange.octet4" :min="0" :max="255" :controls="false" @change="update4"></el-input-number>
  </div>
</template>

<script>
export default{
  name: 'ip',
  props: ['value'],
  methods: {
    update1 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${value}.${ipRange.octet2}.${ipRange.octet3}.${ipRange.octet4}`)
    },
    update2 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${value}.${ipRange.octet3}.${ipRange.octet4}`)
    },
    update3 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${ipRange.octet2}.${value}.${ipRange.octet4}`)
    },
    update4 (value) {
      const ipRange = this.ipRange
      this.$emit('input', `${ipRange.octet1}.${ipRange.octet2}.${ipRange.octet3}.${value}`)
    }
  },
  computed: {
    ipRange () {
      const pattern = /^\d{1,3}(\.\d{1,3}){3}/
      if (pattern.test(this.value)) {
        const [octet1, octet2, octet3, octet4] = this.value.split('.')
        return { octet1, octet2, octet3, octet4 }
      } else {
        return { octet1: 0, octet2: 0, octet3: 0, octet4: 0 }
      }
    }
  }
}
</script>

<style scoped>
  .ip {
    display: inline-block;
    height: 36px;
    border: 1px solid rgb(191, 203, 217);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .el-input-number {
    width: 32px;
  }

  .divider-dot {
    display: inline-block;
    color: rgb(131, 140, 150);
    font-size: 22px;
    width: 4px;
  }
</style>

<style>
  .ip .el-input-number.is-without-controls input {
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .ip .el-input__inner {
    background-color: transparent;
  }
</style>
