export function getCustomersSoftware (customerId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}`)
}

export function getCustomersStreams (customerId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}/customerStreams`)
}

export function editCustomerSoftwareStream (customerId, streamId, payload) {
  return window.axiosInstance.post(`customerSoftware/${customerId}/edit/customerSoftwareStream/${streamId}`, payload)
}

export function deleteCustomerSoftware (customerId, customerSoftwareId) {
  return window.axiosInstance.delete(`customerSoftware/${customerId}/${customerSoftwareId}`)
}

export function deleteCustomerStream (customerId, customerStreamId) {
  return window.axiosInstance.delete(`customerSoftware/${customerId}/customerStreams/${customerStreamId}`)
}

export function editCustomerSoftware (customerId, payload) {
  return window.axiosInstance.post(`customerSoftware/${customerId}`, payload)
}

export function getNewSoftware (customerId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}/newSoftware`)
}

export function getLatestVersion (customerId, softwareId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}/getLatestVersion/${softwareId}`)
}

export function checkRevisionNumber (customerId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}/checkRevisionNumbers`)
}

export function shopLatestVersion (customerId, softwareId) {
  return window.axiosInstance.post(`customerSoftware/${customerId}/shopLatestVersion/${softwareId}`)
}

export function repairSoftware (customerId, softwareId) {
  return window.axiosInstance.post(`customerSoftware/${customerId}/repairSoftware/${softwareId}`)
}

export function getRevisionMessagesCustomer (customerId, customerSoftwareId) {
  return window.axiosInstance.get(`customerSoftware/${customerId}/revisionMessages/${customerSoftwareId}`)
}

export function activateLatestSWVersions (customerId) {
  return window.axiosInstance.put(`customerSoftware/${customerId}/activateLatestSoftwares`)
}

export function setApplicationOwners (customerId, payload) {
  return window.axiosInstance.post(`customerSoftware/${customerId}/setApplicationOwners`, payload)
}

export function setPriorities (customerId, payload) {
  return window.axiosInstance.post(`customerSoftware/${customerId}/setPriorities`, payload)
}
