<template>
  <div style="margin-top: 30px; margin-bottom: 30px">
    <h3 style="text-align: center">Customer Hardware Models</h3>
    <el-col :span="22" :offset="1">
      <el-row type="flex" class="header">
        <el-col :span="6" class="search">
          <el-input
            placeholder="Find Hardware Model Plan"
            v-model="searchHardwareModel"
          >
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubsetHardwareModel"
              @click="clearSearchHardwareModel"
            ></i> -->
          </el-input>
          <div class="results" v-if="isSubsetHardwareModel">
            <div v-if="!search">
              showing {{ filteredHardwareModels.length }} of
              {{ customerHardwareModels.length }}
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table :data="filteredHardwareModels">
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column
          prop="counter"
          label="Number of models in usage"
        ></el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :delete="false"
              :edit="false"
              :showDrivers="true"
              @showDrivers="showDriverDialog(scope.row)"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <hw-model-drivers ref="hwModelDrivers"></hw-model-drivers>
  </div>
</template>

<script>
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import impersonation from "../../impersonation";
import CustomerHardwareDrivers from "../customerSoftware/CustomerHardwareDrivers.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "hw-model-drivers": CustomerHardwareDrivers,
  },
  created() {
    this.$store.dispatch(
      "getCustomerHardwareModels",
      impersonation.getCustomer().id
    );
  },
  data() {
    return {
      searchHardwareModel: "",
    };
  },
  methods: {
    showDriverDialog(val) {
      this.$refs.hwModelDrivers.openDialog(val.drivers);
    },
  },
  computed: {
    ...mapState(["customerHardwareModels"]),
    filteredHardwareModels() {
      return this.filterEntities(
        this.customerHardwareModels,
        this.searchHardwareModel
      );
    },
    isSubsetHardwareModel() {
      return (
        this.customerHardwareModels.length !==
        this.filteredHardwareModels.length
      );
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}
</style>
