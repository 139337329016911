<template>
  <div class="container">
    <el-col style="width: 50%" :offset="1">
      <h3 style="text-align: center">Task Streams</h3>
      <el-row type="flex" class="header">
        <el-col :span="12" class="search">
          <el-input placeholder="Find Task Stream" v-model="search">
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubset"
              @click="clearSearch"
            ></i> -->
          </el-input>
        </el-col>
        <el-col :span="4">
          <div class="results" v-if="isSubset">
            <div>
              showing {{ searchResult.length }} of
              {{ filteredSoftwareStreams.length }}
            </div>
          </div>
        </el-col>
        <el-col :span="4" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button type="primary" @click="openAddTaskStream"
            >Add Task Stream</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table :data="searchResult" highlight-current-row>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column
          prop="architecture"
          label="Architecture"
        ></el-table-column>
        <el-table-column prop="vendor" label="Vendor"></el-table-column>
        <el-table-column prop="language" label="Language"></el-table-column>
        <el-table-column
          prop="descriptionShort"
          label="Description"
        ></el-table-column>
        <el-table-column prop="icon" label="Icon">
          <template v-slot="scope">
            <img
              v-if="
                scope.row.icon !== null &&
                scope.row.icon.id !== '' &&
                scope.row.icon.name != ''
              "
              :src="scope.row.icon"
              height="40"
              width="40"
            />
            <img
              v-else
              src="../../../static/packages-icon-21.png"
              height="40"
              width="40"
            />
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="90">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :deleteItem="true"
              :edit="true"
              :manageSoftware="true"
              @delete="deleteSoftwareStream"
              @edit="openEditSoftwareStream"
              @manageSoftware="openManageSoftware"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <edit-sw-stream ref="editSoftwareStream"></edit-sw-stream>
    <manage-software ref="manageSoftware"></manage-software>
    <add-sw-stream ref="addTaskStream"></add-sw-stream>
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import { mapState } from "vuex";
import EditSoftwareStream from "../softwareStream/EditSoftwareStream.vue";
import AddSoftwareStream from "../softwareStream/AddSoftwareStream.vue";
import ManageSoftware from "../softwareStream/ManageSoftware.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "edit-sw-stream": EditSoftwareStream,
    "add-sw-stream": AddSoftwareStream,
    "manage-software": ManageSoftware,
  },
  created() {
    this.$store.dispatch("getSoftwareStreams");
    this.$store.dispatch("getSoftwares");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    openManageSoftware(stream) {
      this.$refs.manageSoftware.openDialog(stream);
    },
    openEditSoftwareStream(stream) {
      this.$refs.editSoftwareStream.openDialog(stream);
    },
    deleteSoftwareStream(stream) {
      this.$confirm(
        "This will permanently delete the stream and remove the stream from the software packages. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteStreamSuccess.bind(this, stream), this.deleteError);
    },
    deleteStreamSuccess(stream) {
      this.$store.dispatch("deleteSoftwareStream", stream.id);
    },
    clearSearch() {
      this.search = "";
    },
    openAddTaskStream() {
      this.$refs.addTaskStream.openDialog();
    },
  },
  computed: {
    ...mapState(["softwareStreams"]),
    filteredSoftwareStreams() {
      return this.softwareStreams.filter((x) => x.type === "Task");
    },
    searchResult() {
      return this.filterEntities(this.filteredSoftwareStreams, this.search);
    },
    isSubset() {
      return (
        this.softwareStreams.length !== this.filteredSoftwareStreams.length
      );
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  padding-bottom: 50px;
}
</style>
