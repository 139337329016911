<template>
  <el-form-item :prop="prop" label="Operating System" :rules="rules">
    <el-select v-model="model" @change="change" filterable placeholder="Select Operating System" :disabled="disabled">
      <el-option v-for="image in images" :key="image" :label="image" :value="image"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'os-select',
  props: ['value', 'prop', 'disabled'],
  created () {
    this.$store.dispatch('getImages')
  },
  data () {
    return {
      model: this.value,
      rules: [
        { required: true, message: 'Operating System is required', trigger: 'change' }
      ]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    ...mapState(['images'])
  }
}
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
