<template>
  <div>
    <under-construction></under-construction>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find domain registrations"
              v-model="search"
              autofocus
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredDomainregs.length }} of {{ domainregs.length }}
            </div>
          </el-col>
        </el-row>
        <el-table :data="filteredDomainregs" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable> </el-table-column>
          <el-table-column width="150">
            <template>
              <el-button type="primary">Order Now</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UnderConstruction from "../shared/UnderConstrction";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";

export default {
  components: { UnderConstruction },
  name: "domain-reg",
  created() {
    this.$store.dispatch("getDomainregs");
  },
  mixins: [FilterMixin],
  data() {
    return {
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    ...mapState(["domainregs"]),
    filteredDomainregs() {
      return [];
      // return this.filterEntities(this.domainregs, this.search)
    },
    isSubset() {
      return [];
      // return this.domainregs.length !== this.filteredDomainregs.length
    },
  },
};
</script>
