<template lang="html">
  <div>
    <add-company-dialog ref="addCompanyDialog"
                        :renderNewLocationOption="false"></add-company-dialog>
    <el-dialog
      title="Add "
      v-model="showDialog">
      <el-form
        :model="formModel"
        :rules="locationRules"
        ref="add-location-form">
        <el-form-item label="Name" prop="name">
          <el-input
            placeholder="Please insert the name"
            v-model="formModel.name"></el-input>
        </el-form-item>
        <el-form-item label="Name (abbr.)" prop="nameAbbreviation">
          <el-input
            placeholder="Please insert the name abbreviation"
            v-model="formModel.nameAbbreviation"></el-input>
        </el-form-item>
        <div v-if="isInCloud()">
          <el-form-item label="Azure Location" prop="azureLocation">
            <el-select
              placeholder="Select Azure Location"
              v-model="formModel.azureLocation"
              filterable>
              <el-option
                v-for="azureLocation in azureLocations"
                :key="azureLocation.name"
                :label="azureLocation.displayName"
                :value="azureLocation.displayName">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="Country" prop="country">
            <country-selection id="c-s" @setCountryData="fillCountryData"></country-selection>
          </el-form-item>
          <el-form-item label="Time Zone" prop="timeZone">
             <el-select
              v-model="formModel.timeZone"
              placeholder="Select Timezone">
              <el-option
                v-for="timeZone in timeZones"
                :key="timeZone.zoneName"
                :value="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
                :label="computeTimeZone(timeZone.zoneName, timeZone.gmtOffset)"
                >
              </el-option>
            </el-select>
            <p class="error" v-if="timeZones.length === 0">
              Please select the country first
            </p>
          </el-form-item>
          <el-form-item label="City" prop="city">
            <el-input
              placeholder="Please insert the city"
              v-model="formModel.city"></el-input>
          </el-form-item>
          <el-form-item label="City (abbr.)" prop="cityAbbreviation">
            <el-input
              placeholder="Please insert the city abbreviation"
              v-model="formModel.cityAbbreviation"></el-input>
          </el-form-item>
          <el-form-item label="Street" prop="street">
            <el-input
              placeholder="Please insert the street"
              v-model="formModel.street"></el-input>
          </el-form-item>
          <el-form-item label="Number" prop="number">
            <el-input
              placeholder="Please insert the house number"
              v-model="formModel.number"></el-input>
          </el-form-item>
          <el-form-item label="Postcode" prop="postcode">
            <el-input
              placeholder="Please insert the postcode"
              v-model="formModel.postcode"></el-input>
          </el-form-item>
          <el-form-item label="Public IP" prop="publicIp">
            <el-input
              placeholder="Please insert the public IP address"
              v-model="formModel.publicIp"></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item label="Download Speed" prop="downloadSpeed">
              <el-input placeholder="Please insert download speed (kbit/s)" v-model="formModel.downloadSpeed"></el-input>
            </el-form-item>
            <el-form-item label="Upload Speed" prop="uploadSpeed">
              <el-input placeholder="Please insert upload speed (kbit/s)" v-model="formModel.uploadSpeed"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="Company" prop="company">
            <el-select
              v-model="formModel.company"
              placeholder="Select company"
              @change="openAddCompanyDialog">
              <el-option
                key="new-company"
                value="new-company"
                label="+ New Company">
              </el-option>
              <el-option
                v-for="company in companies"
                :key="company.id"
                :value="company.id"
                :label="company.corporateName">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- icon="el-icon-plus" -->
        <el-button
          type="primary"
          @click="submit('add-location-form')">
            Add
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import AddCompanyDialog from "../customers/settings/dialogs/AddCompanyDialog";
import CountrySelection from "./CountrySelection";
import LocationValidationRules from "./LocationValidationRules";

export default {
  name: "add-location",
  mixins: [LocationValidationRules],
  props: ["showCompanies", "compId", "customerId"],
  components: {
    AddCompanyDialog,
    CountrySelection,
  },
  created() {
    this.getCompanies();
  },
  data() {
    return {
      showDialog: false,
      formModel: {
        name: "",
        nameAbbreviation: "",
        country: "",
        countryAbbreviation: "",
        city: "",
        cityAbbreviation: "",
        street: "",
        number: "",
        postcode: "",
        publicIp: "",
        company: "",
        uploadSpeed: "",
        downloadSpeed: "",
        type: "",
        timeZone: "",
        azureLocation: "",
      },
      types: [
        {
          key: 1,
          name: "on-prem",
        },
        {
          key: 2,
          name: "in-cloud",
        },
      ],
      timeZones: [],
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    openAddCompanyDialog() {
      if (this.formModel.company === "new-company") {
        this.$refs.addCompanyDialog.openDialog();
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let payload = this.createPayloadData();
          this.$store.dispatch("addLocation", payload);
          this.$refs[formName].resetFields();
          this.showDialog = false;
        }
      });
    },
    createPayloadData() {
      let model = this.formModel;
      return {
        name: model.name,
        nameAbbreviation: model.nameAbbreviation,
        country: model.country,
        countryAbbreviation: model.countryAbbreviation,
        city: model.city,
        cityAbbreviation: model.cityAbbreviation,
        street: model.street,
        number: model.number,
        postcode: model.postcode,
        publicIp: model.publicIp,
        uploadSpeed: model.uploadSpeed,
        downloadSpeed: model.downloadSpeed,
        type: model.type,
        timeZone: model.timeZone,
        azureLocation: model.azureLocation,
        customerId: this.customerId,
        companyId:
          this.showCompanies === false && this.compId !== undefined
            ? this.compId
            : model.company,
      };
    },
    getCompanies() {
      this.$store.dispatch("getCustomersCompanies", this.customerId);
    },
    /*  openAddCompanyDialog () {
        if (this.formModel.company === 'new-company') {
          this.$refs.addCompany.openDialog()
        }
      }, */
    fillCountryData(countryData) {
      this.formModel.country = countryData.name;
      this.formModel.countryAbbreviation = countryData.code;
      this.timeZones = countryData.zones;
      this.formModel.timeZone = "";
    },
    isInCloud() {
      return this.formModel.type === "in-cloud";
    },
    clearType() {
      this.formModel.type = "";
    },
    computeTimeZone(zoneName, gmtOffset) {
      return zoneName + " (GMT" + gmtOffset + ")";
    },
  },
  computed: {
    companies: {
      get() {
        return this.$store.state.customersCompanies;
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        // Do nothing
      },
    },
    azureLocations: {
      get() {
        return this.$store.state.locations;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.space-left {
  margin-left: 20px;
}
.centered {
  margin: 0 auto;
  text-align: center;
}
</style>
