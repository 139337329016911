export default{
  name: 'display-role',
  methods: {
    getRole (user) {
      if (user.roles.includes('systemhouse')) {
        return 'Systemhouse Manager'
      }

      if (user.roles.includes('customer')) {
        return 'Customer Manager'
      }
    },
    getRoleName (user) {
      let rolename = ''
      if (user.roles.includes('systemhouse')) {
        let syshouse = user.systemhouse.name
        rolename = 'Systemhouse Manager / ' + syshouse
      }
      if (user.roles.includes('customer')) {
        let customer = user.customer.name
        rolename = 'Customer Manager / ' + customer
      }
      return rolename
    }
  }
}
