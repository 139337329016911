export function getBuyItems () {
  return window.axiosInstance.get('/shop')
}

export function getBuyItemImage (fileId) {
  return window.axiosInstance.get(`/shop/download/${fileId}`, {responseType: 'blob'})
}

export function getSoftwareShopItems (customerId) {
  return window.axiosInstance.get(`/shop/getSoftware/${customerId}`)
}

export function getShopItemsCategories () {
  return window.axiosInstance.get('/shop/shopCategories')
}
