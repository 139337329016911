<template>
  <div>
    <h3>Microsoft Office Configuration</h3>
    <span style="color: red" v-if="showError"
      >Please check wich configuration to use</span
    >
    <el-checkbox v-model="checkedDefault" @change="changeCustom"
      >Use default package configuration file</el-checkbox
    >
    <el-checkbox v-model="checkedCustom" @change="changeDefault"
      >Use custom configuration file:
    </el-checkbox>
    <br />
    <br />
    <span>
      <a
        href="https://config.office.com/deploymentsettings"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to get to the Office 365 Customization Tool
      </a>
    </span>
    <div style="margin: 0 auto; width: 80%">
      <el-input
        style="width: 60%"
        v-model="model.officeConfig"
        type="textarea"
        :rows="10"
      >
      </el-input>
      <br />
      <br />
      <el-button type="primary" @click="submit">Save</el-button>
    </div>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import { getOfficeConfig, setOfficeConfig } from "../../services/customers";
import { success, errorMessage } from "../../store/modules/util";

export default {
  created() {
    let customerId = impersonation.getCustomer().id;
    getOfficeConfig(customerId)
      .then((response) => {
        this.model.officeConfig = response.data.officeConfig;
        this.model.useCustomConfig = response.data.useCustomConfig;
        if (this.model.useCustomConfig) {
          this.checkedCustom = true;
          this.checkedDefault = false;
        } else {
          this.checkedCustom = false;
          this.checkedDefault = true;
        }
      })
      .catch((error) => console.log({ error }));
  },
  data() {
    return {
      showError: false,
      checkedCustom: false,
      checkedDefault: true,
      selectedConfig: [],
      model: {
        officeConfig: "",
        useCustomConfig: false,
      },
    };
  },
  methods: {
    changeCustom(val) {
      if (val) {
        this.checkedCustom = false;
      }
    },
    changeDefault(val) {
      if (val) {
        this.checkedDefault = false;
      }
    },
    submit() {
      if (this.model.officeConfig !== null && this.model.officeConfig !== "") {
        if (!this.checkedCustom && !this.checkedDefault) {
          this.showError = true;
        } else {
          this.showError = false;
          if (this.checkedCustom) {
            this.model.useCustomConfig = true;
          }
          let customerId = impersonation.getCustomer().id;
          setOfficeConfig(customerId, this.model)
            .then(() => {
              success();
            })
            .catch((error) => errorMessage(error.response));
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
