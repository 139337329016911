<template>
  <el-form-item label="Subscription" :prop="prop" placeholder="Select subscription" :rules="rules">
    <el-select v-model="model" @change="change" filterable placeholder="Select subscription">
      <el-option v-for="subscription in subscriptions" :key="subscription.id" :label="subscription.name" :value="subscription.id"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'subscription-select',
  props: ['value', 'prop'],
  created () {
    this.$store.dispatch('getSubscriptions')
  },
  data () {
    return {
      model: this.value,
      rules: [
        // TODO: Add this again
        // { required: true, message: 'Subscription is required', trigger: 'change' }
      ]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
      this.$store.state.currentSelectedSubscriptionId = this.model
    },
    resetModel () {
      this.model = ''
    }
  },
  watch: {
    subscriptions: function (subscriptions) {
      if (subscriptions.length === 1) {
        this.model = subscriptions[0].id
        this.$emit('input', subscriptions[0].id)
      }
    }
  },
  computed: {
    ...mapState(['subscriptions'])
  }
}
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>
