<template>
  <div>
    <el-form-item
      ref="taskInputName"
      v-if="visibleName"
      label="Name"
      :prop="propName"
      :rules="rules.name"
    >
      <el-input v-model="model.name" placeholder="Task name"></el-input>
    </el-form-item>
    <!-- <el-form-item :prop="propInstallationType" label="Installation-Type">
      <el-checkbox-group
        v-model="installationTypeChecked"
        :max="1"
        @change="changedInstalltionType"
      >
        <el-checkbox label="Powershell Script"></el-checkbox>
        <el-checkbox label="Bitstream PS-Wrapper" disabled></el-checkbox>
        <el-checkbox
          label="Powershell App Deployment Toolkit"
          disabled
        ></el-checkbox>
        <el-checkbox label="Use Own" disabled></el-checkbox>
      </el-checkbox-group>
    </el-form-item> -->
    <el-form-item
      ref="taskInputFiles"
      label="Files"
      :prop="propFiles"
      :rules="rules.files"
    >
      <upload-files
        :id="id"
        :action="uploadUrl"
        :file-list="filelist"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
      ></upload-files>
    </el-form-item>
    <el-form-item
      ref="taskInputExecutable"
      label="Executable"
      :prop="propExecutable"
      :rules="rules.executable"
    >
      <el-select v-model="model.executable" placeholder="Select executable">
        <el-option
          v-for="file in model.files"
          :key="file.id"
          :label="file.name"
          :value="file.id"
        ></el-option>
      </el-select>
      <!-- <span style="float: right">
        UseShellExecute <el-switch v-model="model.useShellExecute"></el-switch>
      </span> -->
    </el-form-item>
    <!-- <el-form-item ref="taskInputRestart" label="Restart required">
      <el-checkbox v-model="model.restartRequired"></el-checkbox>
    </el-form-item> -->
    <el-form-item
      ref="taskInputCommandline"
      label="Arguments"
      :prop="propCommandline"
      :rules="rules.commandline"
    >
      <el-input v-model="model.commandline" placeholder="Arguments"></el-input>
    </el-form-item>
    <el-form-item
      ref="taskInputInstallationCmd"
      label="Installation Command"
      :prop="propInstallCommand"
    >
      <el-input
        v-model="model.installationCmd"
        placeholder="Installation Command"
      ></el-input>
    </el-form-item>
    <el-form-item
      ref="taskInputUninstallCommand"
      label="Uninstall Command"
      :prop="propUninstallCommand"
      :rules="rules.uninstallCommand"
    >
      <el-input
        v-model="model.uninstallCommand"
        placeholder="Uninstall Command"
      ></el-input>
    </el-form-item>
    <el-form-item label="Restart Behavior" :prop="restartBehavior">
      <el-select v-model="model.restartBehavior">
        <el-option
          v-for="restartBehavior in restartBehaviors"
          :key="restartBehavior.key"
          :label="restartBehavior.value"
          :value="restartBehavior.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Return Codes" :prop="returnCodes">
      <div>
        <div
          style="margin-bottom: 20px; align-items: center"
          class="flex"
          v-for="(returnCode, index) in model.returnCodes"
          :key="returnCode"
        >
          <!-- <span
            style="width: 35%"
            class="ml-3 w-35 text-gray-600 inline-flex items-center"
            >Return Code</span
          > -->
          <el-input class="spaceRight" v-model="returnCode.code"></el-input>
          <el-select class="spaceRight" v-model="returnCode.value">
            <el-option
              v-for="code in codes"
              :key="code.key"
              :label="code.value"
              :value="code.value"
            ></el-option>
          </el-select>
          <el-icon @click="removeReturnCode(index)" :size="20"
            ><Delete
          /></el-icon>
        </div>
      </div>
      <div class="addBtn" type="primary">
        <el-button @click="addReturnCode">+ Add Return Code</el-button>
      </div>
    </el-form-item>
    <el-form-item label="Intune File" :prop="intuneFile">
      <el-upload
        :headers="headers"
        accept=".intunewin"
        :action="uploadUrl"
        :limit="1"
        :file-list="intuneFiles"
        :on-remove="handleRemoveIntuneFile"
        :on-success="handleSuccessIntuneFile"
      >
        <el-button type="primary">Click to upload</el-button>
      </el-upload>
    </el-form-item>
  </div>
</template>

<script>
import TaskValidation from "./TaskValidation";
import UploadFiles from "../shared/UploadFiles";

export default {
  name: "task-input",
  components: {
    UploadFiles,
  },
  mixins: [TaskValidation],
  props: [
    "value",
    "prop",
    "id",
    "visibleName",
    "setName",
    "enableContext",
    "enableNewWindow",
    "passedCheckedContext",
    "intuneFile",
  ],
  data() {
    return {
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
      intuneFiles: [],
      codes: [
        { key: 1, value: "Failed" },
        { key: 2, value: "Hard reboot" },
        { key: 3, value: "Soft reboot" },
        { key: 4, value: "Retry" },
        { key: 5, value: "Success" },
      ],
      restartBehaviors: [
        { key: 1, value: "App install may force a device restart" },
        { key: 2, value: "No specific action" },
        { key: 3, value: "Determine behavior based on return codes" },
        { key: 4, value: "Intune will force a mandatory device restart" },
      ],
      installationTypeChecked: ["Powershell Script"],
      executionContexts: [
        { label: "Run in Admin Context", value: "admin", key: "1" },
        { label: "Run in User Context", value: "user", key: "2" },
      ],
      visibilities: [
        { label: "No new Window", value: "no new", key: "1" },
        { label: "Run in new Window", value: "new", key: "2" },
      ],
      uploadUrl: window.APPLICATION_SETTING_API_ENDPOINT_URL + "/tasks/upload",
      files: [],
      propName: this.prop + ".name",
      propFiles: this.prop + ".files",
      propExecutable: this.prop + ".executable",
      propCommandline: this.prop + ".installCommand",
      propInstallationType: this.prop + ".installationType",
      propUninstallCommand: this.prop + ".uninstallCommand",
      propInstallCommand: this.prop + ".installCommand",
      restartBehavior: this.prop + ".restartBehavior",
      returnCodes: this.prop + ".returnCodes",
      model: this.value,
    };
  },
  computed: {
    filelist() {
      return this.model.files.map(function (file) {
        return { name: file.name };
      });
    },
  },
  methods: {
    handleRemoveIntuneFile() {
      this.model.intuneFile = { id: null, guid: "", name: "" };
    },
    handleSuccessIntuneFile() {
      let data = this.intuneFiles[0];
      let file = { guid: data.response.id, name: data.name, id: null };
      this.model.intuneFile = file;
    },
    removeReturnCode(index) {
      this.model.returnCodes.splice(index, 1);
    },
    addReturnCode() {
      this.model.returnCodes.push({ id: null, code: "", value: "" });
    },
    changedInstalltionType() {
      this.model.installationType = this.installationTypeChecked[0];
    },
    changeContext(value) {
      var context = "";
      if (value[0] === "Run in Admin Context") {
        context = "admin";
      } else if (value[0] === "Run in User Context") {
        context = "user";
      }
      this.model.executionContext = context;
    },
    changeVisibility(value) {
      var context = "";
      if (value[0] === "No new Window") {
        context = "new";
      } else if (value[0] === "Run in new Window") {
        context = "not new";
      }
      this.model.visibility = context;
    },
    change(value) {
      this.$emit("input", value);
    },
    setFiles(file, type) {
      if (type === "add") {
        this.model.files.push(file);
      } else {
        for (let i = 0; i < this.model.files.length; i++) {
          if (this.model.files[i].name === file) {
            if (this.model.executable === this.model.files[i].id) {
              this.model.executable = "";
            }
            this.model.files.splice(i, 1);
            break;
          }
        }
      }
    },
    handleRemove(a) {
      this.setFiles(a.name, "delete");
    },
    handleSuccess(a, b) {
      let file = { id: a.id, name: b.name };
      this.setFiles(file, "add");
      this.removeError();
    },
    removeError() {
      if (this.model.files !== null || this.model.files.length >= 1) {
        let id = this.id;
        let el = document.getElementById(id);
        let sibling = el.nextSibling;
        let parentNode = sibling.parentElement;
        if (sibling !== null && sibling.classList !== undefined) {
          if (sibling.classList.contains("el-form-item__error")) {
            parentNode.removeChild(sibling);
          }
        }
      }
    },
  },
};
</script>
<style lang="css" scoped>
.flex {
  display: flex;
}

.spaceRight {
  padding-right: 20px;
}

.addBtn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
