<template>
  <el-dialog
    title="OS Stream Settings"
    v-model="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <span
      v-if="this.imageStream.type === null"
      style="text-align: center; color: red"
    >
      Your image stream is outdated! To use this function please shop the images
      used again.
    </span>
    <el-form v-else ref="form" :model="model" :rules="rules">
      <el-form-item
        v-if="imageStream.type === 'Windows'"
        label="Product Key"
        prop="productKey"
      >
        <el-input v-model="model.productKey"></el-input>
      </el-form-item>
      <div v-if="imageStream.type === 'Linux'">
        <el-form-item label="Language Packs" prop="localSettingLinux">
          <el-select multiple v-model="model.localSettingLinux">
            <el-option
              v-for="localSetting in localSettingsLinux"
              :key="localSetting.key"
              :value="localSetting.value"
              :label="localSetting.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-button type="primary" @click="submit">Submit</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import { setOSStreamSettings } from "../../services/customerImages";

export default {
  data() {
    let validateKeyboardLayoutWindows = (rule, value, callback) => {
      if (this.model.keyboardLayoutWindwos === "") {
        callback("Please select the keyboard layout");
      } else {
        callback();
      }
    };
    let validateKeyboardLayoutLinux = (rule, value, callback) => {
      if (this.model.keyboardLayoutLinux === "") {
        callback("Please select the keyboard layout");
      } else {
        callback();
      }
    };
    let validateWindowsTimezone = (rule, value, callback) => {
      if (this.model.timeZoneWindows === "") {
        callback("Please select the time zone");
      } else {
        callback();
      }
    };
    let validateLinuxTimezone = (rule, value, callback) => {
      if (this.model.timeZoneLinux === "") {
        callback("Please select the time zone");
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      selectedKeyboardLinux: [],
      keyboardLayoutsLinux: [
        { key: 1, value: "de", label: "German" },
        { key: 2, value: "en", label: "English" },
      ],
      keyboardLayoutsWindows: [
        { key: 1, value: "de-DE", label: "German" },
        { key: 2, value: "en-US", label: "English (US)" },
      ],
      timeZonesWindows: [
        {
          key: 1,
          value: "W. Europe Standard Time",
          label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        },
        {
          key: 2,
          value: "E. Europe Standard Time",
          label: "(GMT+02:00) Bucharest",
        },
      ],
      timeZonesLinux: [
        { key: 1, value: "Europe/Berlin", label: "Europe/Berlin" },
      ],
      localSettingsLinux: [
        { key: 1, value: "de", label: "German" },
        { key: 2, value: "en", label: "English" },
      ],
      model: {
        localSettingLinux: "",
        productKey: "",
      },
      imageStream: {
        id: "",
        type: "",
      },
      rules: {
        keyboardLayoutWindwos: [
          { validator: validateKeyboardLayoutWindows, trigger: "change" },
        ],
        keyboardLayoutLinux: [
          { validator: validateKeyboardLayoutLinux, trigger: "change" },
        ],
        timeZoneWindows: [
          { validator: validateWindowsTimezone, trigger: "change" },
        ],
        timeZoneLinux: [
          { validator: validateLinuxTimezone, trigger: "change" },
        ],
        localSettingLinux: [
          {
            required: true,
            message: "Please select the local setting",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    setKeyboardLayoutLinux(val) {
      for (let i = 0; i < val.length; i++) {
        if (i === 0) {
          this.model.keyboardLayoutLinux = val[i];
        } else {
          this.model.keyboardLayoutLinux += ", " + val[i];
        }
      }
    },
    openDialog(imageStream) {
      this.imageStream = imageStream;
      if (imageStream.type === "Linux") {
        this.model.adminPasswordLinux = imageStream.adminPasswordLinux;
        this.model.usernameLinux = imageStream.usernameLinux;
        this.model.userPasswordLinux = imageStream.userPasswordLinux;
        this.model.partitionEncryptionPassLinux =
          imageStream.partitionEncryptionPassLinux;
      }
      this.model.productKey = imageStream.productKey;
      this.model.localSettingLinux = imageStream.localSettingLinux;
      this.showDialog = true;
    },
    handleClose() {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.showDialog = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          setOSStreamSettings(this.imageStream.id, this.model)
            .then((response) => {
              let imageStream = response.data;
              for (
                let i = 0;
                i < this.$store.state.customerImageStreams.length;
                i++
              ) {
                if (
                  this.$store.state.customerImageStreams[i].id ===
                  imageStream.id
                ) {
                  this.$store.state.customerImageStreams.splice(
                    i,
                    1,
                    imageStream
                  );
                  break;
                }
              }
              if (this.$refs.form !== undefined) {
                this.$refs.form.resetFields();
              }
              this.showDialog = false;
            })
            .catch((err) => console.log({ err }));
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
