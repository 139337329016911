export function createCertification (payload) {
  return window.axiosInstance.post(`/certifications`, payload)
}

export function getCertifications () {
  return window.axiosInstance.get(`/certifications`)
}

export function updateCertification (payload) {
  return window.axiosInstance.put(`/certifications`, payload)
}
