<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find customer"
              v-model="search"
              autofocus
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredCustomers.length }} of {{ customers.length }}
            </div>
          </el-col>

          <el-col :span="8" class="bs-align-right">
            <!-- <el-button icon="el-icon-document" @click="openCredentialsTutorial">Retrieve Credentials</el-button> -->
            <el-button type="primary" @click="openCustomerAdd">
              <el-icon>
                <Plus />
              </el-icon>
              Add customer</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="filteredCustomers"
          row-class-name="clickable"
          highlight-current-row
          v-loading.body="customersLoading"
        >
          <el-table-column prop="name" label="Customer name" sortable>
            <template v-slot="scope">
              <el-button
                @click="impersonateCustomer(scope.row)"
                type="text"
                size="default"
                >{{ scope.row.name }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="systemhouse.name"
            label="Systemhouse"
            :filters="systemhousesMap"
            :filter-method="filterBySystemhouse"
            :filtered-value="systemhouseFilter"
          >
            <template v-slot="scope">
              <el-tag size="small" v-if="scope.row.systemhouse">
                {{ scope.row.systemhouse.name }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Users">
            <template v-slot="scope">
              <router-link
                :to="{ name: 'users', query: { customer: scope.row.id } }"
              >
                <el-button type="text" size="small">users</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="110">
            <template v-slot="scope">
              <actions-dropdown
                :hideRetreatFromShop="true"
                :edit="true"
                :deleteItem="true"
                :datasheet="true"
                :entity="scope.row"
                @edit="openCustomerEdit"
                @delete="confirmDelete"
                @datasheet="openCustomerSettings"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <customer-add ref="customerAdd"></customer-add>
    <customer-edit ref="customerEdit"></customer-edit>
    <customer-settings ref="customerSettings"></customer-settings>
    <delete-customer ref="deleteCustomer"></delete-customer>
    <credentials-tutorial ref="credentialsTutorial"></credentials-tutorial>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import CredentialsTutorial from "./CredentialsTutorial.vue";
import CustomerAdd from "./CustomerAdd.vue";
import CustomerEdit from "./CustomerEdit.vue";
import CustomerSettings from "./CustomerSettings.vue";
import Impersonation from "../../impersonation";
import DeleteCustomer from "./DeleteCustomer.vue";

export default {
  name: "customers",
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    CredentialsTutorial,
    CustomerAdd,
    CustomerEdit,
    CustomerSettings,
    "delete-customer": DeleteCustomer,
    // CustomerDefaults
  },
  data() {
    var systemhouseFilter = this.$route.query.systemhouse
      ? [this.$route.query.systemhouse]
      : [];
    return {
      systemhouseFilter: systemhouseFilter,
    };
  },
  methods: {
    openCustomerAdd() {
      this.$refs.customerAdd.open();
    },
    openCustomerEdit(customer) {
      this.$refs.customerEdit.open(_.cloneDeep(customer));
    },
    openCustomerSettings(customer) {
      this.$refs.customerSettings.open(_.cloneDeep(customer));
    },
    openCredentialsTutorial() {
      this.$refs.credentialsTutorial.open();
    },
    impersonateCustomer(customer) {
      Impersonation.setCustomer(customer);
      this.$router.push("smartdeploy");
    },
    openCustomerDetails() {
      this.$router.push("customer-settings");
    },
    filterBySystemhouse(value, row) {
      return row.systemhouse.id === value;
    },
    confirmDelete(customer) {
      this.$refs.deleteCustomer.openDialog(customer);
      // this.$confirm('This will permanently delete the customer. Continue? The corresponding cloud data will be deleted, too.', 'Confirm', {
      //   confirmButtonText: 'OK',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(this.deleteSuccess.bind(this, customer), this.deleteError)
    },
    deleteSuccess(customer) {
      this.$store.dispatch("deleteCustomer", customer);
    },
    deleteError() {},
  },
  computed: {
    ...mapState({
      customers: (state) => state.customer.customers,
      customersLoading: (state) => state.customer.customersLoading,
    }),
    ...mapGetters(["systemhousesMap"]),
    filteredCustomers() {
      return this.filterEntities(this.customers, this.search);
    },
    isSubset() {
      return this.customers.length !== this.filteredCustomers.length;
    },
  },
};
</script>

<style scoped>
.el-table tr.current-row .row-actions,
.el-table tr:hover .row-actions {
  opacity: 1;
}
</style>
