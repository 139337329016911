import 'es6-promise/auto'
import 'element-plus/dist/index.css'
import * as Vue from 'vue'
import store from './store'
import App from './App'
import axios from 'axios'
import finallyPromise from 'promise.prototype.finally'
import Auth from './auth'
import VueAxios from 'vue-axios'
import { createRouter, createWebHashHistory } from 'vue-router'
import ElementPlus from 'element-plus'
import timeago from 'vue-timeago3'
import Login from './components/Login.vue'
import BaseAdmin from './components/BaseAdmin.vue'
import Scripts from './components/scripts/Scripts.vue'
import Softwares from './components/software/Softwares.vue'
import Shop from './components/shop/Shop.vue'
import Users from './components/users/Users.vue'
import Systemhouses from './components/systemhouses/Systemhouses.vue'
import Customers from './components/customers/Customers.vue'
import Corporate from './components/datasheet/Corporate'
import BaseInventory from './components/BaseInventory.vue'
import Settings from './components/settings/Settings.vue'
import Clients from './components/clients/Clients.vue'
import Bases from './components/bases/Bases'
import Buy from './components/buy/Buy.vue'
import Workflows from './components/workflows/Workflows.vue'
import AdminWorkflow from './components/workflows/AdminWorkflow.vue'
import DomainReg from './components/domainreg/DomainReg.vue'
import AdminDomainReg from './components/domainreg/AdminDomainReg.vue'
import Certifications from './components/certifications/Certifications.vue'
import AdminCertification from './components/certifications/AdminCertification.vue'
import Domains from './components/domains/Domains.vue'
import Domain from './components/domains/Domain.vue'
import locale from 'element-plus/lib/locale/lang/en'
import Datasheet from './components/datasheet/Datasheet'
import GeneralCustomerData from './components/customers/GeneralCustomerData'
import BaseParameters from './components/bases/BaseParameters'
import CustomerParameters from './components/customers/CustomerParameters'
import GeneralSettingsCustomer from './components/customers/GeneralSettingsCustomer'
import ClientInventory from './components/datasheet/ClientInventory'
import AssetLabel from './components/asset_management/AssetLabel'
import AssetManagementDetails from './components/asset_management/AssetManagementDetails'
import AssetManagement from './components/asset_management/AssetManagement'
import VendorModels from './components/vendor_management/VendorModels'
import ResetPassword from './components/ResetPassword'
import CustomerSoftwareStreams from './components/customerSoftware/CustomerSoftwareStreams'
import CustomerSoftwareDashboard from './components/customerSoftware/CustomerSoftwareDashboard'
import GeneralSettings from './components/generalSettings/GeneralSettings'
import SmartDeploySettings from './components/customers/SmartDeploySettings'
import DriverManagement from './components/drivers/DriverManagement'
import HardwareModels from './components/hardwareModels/HardwareModels'
import TaskManagement from './components/customerSoftware/TaskManagement'
import Reports from './components/customerSoftware/Reports'
import ReleasePlanning from './components/customerSoftware/ReleasePlanning'
import Company from './components/bases/Company'
import IAM from './components/bases/IAM'
import Tasks from './components/software/Tasks'
import Images from './components/software/Images'
import Drivers from './components/software/Drivers'
import DeviceOptions from './components/software/DeviceOptions'
import DeviceProperties from './components/software/DeviceProperties'
import OfficeSettings from './components/software/OfficeSettings'
import ImageShop from './components/buy/ImageShop'
import SoftwareShop from './components/buy/SoftwareShop'
import DriverShop from './components/buy/DriverShop'
import AdminOptionOverview from './components/adminoptions/AdminOptionOverview'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import NoPermission from './components/NoPermission'

finallyPromise.shim() // decorate promises with .finally()

const routes = [
  {
    name: 'noPermission',
    path: '/noPermission/:email?',
    component: NoPermission,
    meta: { requiredPermission: 'anonymous' }
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: { requiredPermission: 'anonymous' }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
    meta: { requiredPermission: 'anonymous' }
  },
  {
    name: 'base',
    path: '/base',
    component: BaseAdmin,
    children: [
      {
        name: 'scripts',
        path: '/scripts',
        component: Scripts,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'hardware',
        path: '/hardware',
        component: Shop,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'admin-workflow',
        path: '/admin-workflow',
        component: AdminWorkflow,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'softwares',
        path: '/softwares',
        component: Softwares,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'tasks',
        path: '/tasks',
        component: Tasks,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'drivers',
        path: '/drivers',
        component: Drivers,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'deviceOptions',
        path: '/deviceOptions',
        component: DeviceOptions,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'adminOptions',
        path: '/adminDeviceOption',
        component: AdminOptionOverview,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'deviceProperties',
        path: '/deviceProperties',
        component: DeviceProperties,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'images',
        path: '/images',
        component: Images,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'admin-domainreg',
        path: '/admin-domainreg',
        component: AdminDomainReg,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'admin-certification',
        path: '/admin-certification',
        component: AdminCertification,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'systemhouses',
        path: '/systemhouses',
        component: Systemhouses,
        meta: { requiredPermission: 'admin' }
      },
      {
        name: 'customers',
        path: '/customers',
        component: Customers,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'users',
        path: '/users',
        component: Users,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'settings',
        path: '/settings',
        component: Settings,
        meta: { requiredPermission: 'systemhouse' }
      },
      {
        name: 'vendorModels',
        path: '/vendorModels',
        component: VendorModels,
        meta: { requiredPermission: 'admin' }
      }
    ]
  },
  {
    name: 'inventory',
    path: '/inventory',
    component: BaseInventory,
    meta: { requiredPermission: 'customer' },
    children: [
      {
        name: 'officeSettings',
        path: '/officeSettings',
        component: OfficeSettings,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'iam',
        path: '/iam',
        component: IAM,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'company',
        path: '/company',
        component: Company,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'releasePlans',
        path: '/releasePlans',
        component: ReleasePlanning,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'taskManagement',
        path: '/taskManagement',
        component: TaskManagement,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'reports',
        path: '/reports',
        component: Reports,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'hardwareModels',
        path: '/hardwareModels',
        component: HardwareModels,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'driverMamangement',
        path: '/driverMamangement',
        component: DriverManagement,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'bases',
        path: '/bases',
        component: Bases,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'smartDeploySettings',
        path: '/smartDeploySettings',
        component: SmartDeploySettings,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'corporate-sheet',
        path: '/corporate-sheet',
        component: Corporate,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'parameters',
        path: '/parameters/:id/',
        component: BaseParameters,
        meta: { requiredPermission: 'customer' }
      },
      // {
      //   name: 'customer-settings',
      //   path: '/customer-settings',
      //   component: CustomerSettings,
      //   meta: { requiredPermission: 'systemhouse' }
      // },
      {
        name: 'customer-parameters',
        path: '/customer/:id/',
        component: CustomerParameters,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'general-settings',
        path: '/general-settings',
        component: GeneralSettingsCustomer,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'domains',
        path: '/bases/:id',
        component: Domains,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'domain',
        path: '/bases/:id/:did',
        component: Domain,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'clients',
        path: '/clients',
        component: Clients,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'buy',
        path: '/buy',
        component: Buy,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'driverShop',
        path: '/driverShop',
        component: DriverShop,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'imageShop',
        path: '/imageShop',
        component: ImageShop,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'softwareShop',
        path: '/softwareShop',
        component: SoftwareShop,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'workflows',
        path: '/workflows',
        component: Workflows,
        meta: { requiredPermission: 'customer' }
      },
      {
        path: '/smartdeploy',
        name: 'devices',
        component: Clients,
        meta: { requiredPermission: 'customer' }
      },
      {
        path: '/customerSoftware',
        name: 'customerSoftware',
        component: CustomerSoftwareStreams,
        meta: { requiredPermission: 'customer' }
      },
      {
        path: '/softwareDashboard/:customerId',
        name: 'softwareDashboard',
        component: CustomerSoftwareDashboard,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'datasheet',
        path: '/smartdeploy/:id/',
        component: Datasheet,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'clientinventory',
        path: '/smartdeploy/inventory/:id/',
        component: ClientInventory,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'asset-label',
        path: '/assetlabel',
        component: AssetLabel,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'assetmanagementdetails',
        path: '/assetmanagementdetails/:id',
        component: AssetManagementDetails,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'asset-management',
        path: '/asset-management',
        component: AssetManagement,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'domainregistrations',
        path: '/domainregistrations',
        component: DomainReg,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'generalSettings',
        path: '/generalSettings',
        component: GeneralSettings,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'certifications',
        path: '/certifications',
        component: Certifications,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'settingsCustomer',
        path: '/settingscustomer',
        component: Settings,
        meta: { requiredPermission: 'customer' }
      },
      {
        name: 'generalCustomerData',
        path: '/customer/general',
        component: GeneralCustomerData,
        meta: { requiredPermission: 'customer' }
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/login'
  }
]

// CLIENT ID FOR AZURE AD AUTH clientId: 'dd36f074-67a7-4d6c-9c9a-1080e6b44d00',

const router = createRouter({ history: createWebHashHistory(), routes: routes })

router.beforeEach((to, from, next) => {
  if (to.path === '/bases' && store.state.refreshBasesID === '') {
    var refreshBasesID = setInterval(() => store.dispatch('getBases'), 60000)
    store.state.refreshBasesID = refreshBasesID
  }
  if (from.path === '/bases' && store.state.refreshBasesID !== '') {
    clearInterval(store.state.refreshBasesID)
    store.state.refreshBasesID = ''
  }
  if (to.path.includes('/softwareDashboard')) {
    let customerId = to.params.customerId
    store.dispatch('getNewSoftware', customerId)
    store.dispatch('getRevisionSoftware', customerId)
    store.dispatch('getRevisionImage', customerId)
    store.dispatch('getNewImages', customerId)
  }
  if (Auth.hasPermission(to.meta.requiredPermission)) {
    next()
  } else {
    if (to.path.includes('/noPermission') || to.path.includes('/login')) {
      next()
    } else {
      console.error('Missing permissions to access this state.')
    }
  }
})

var axiosInstance = axios.create({
  baseURL: window.APPLICATION_SETTING_API_ENDPOINT_URL
})
window.axiosInstance = axiosInstance
// FIXME: waiting for https://github.com/mzabriskie/axios/issues/649 to be fixed
// Vue.use(VueAxios, axiosInstance)
// Vue.use(ElementPlus, { locale })
// Vue.use(VueTimeago, {
//   name: 'timeago',
//   locale: 'en-US'
// })

// restore session
Auth.recoverAuth().then((result) => {
  if (result) {
    store.dispatch('init')
  } else {
    router.push({ name: 'login' })
  }
})

// var app = Vue.createApp({
//   store: store,
//   router: router,
//   el: '#app',
//   template: '<App/>',
//   components: { App }
// })

const app = Vue.createApp({
  el: '#app',
  template: '<App/>',
  components: { App }
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
app.use(router)
app.use(VueAxios, axiosInstance)
app.use(ElementPlus, { locale })
app.use(timeago)
app.mount('#app')
