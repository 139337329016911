<template>
  <div class="login">
    <h1 class="heading">
      <img src="../../static/product-logo-white.svg" class="logo" /><span
        class="title"
        >IT-Manager</span
      >
    </h1>
    <el-card class="credential-form">
      <el-alert
        title="Sign in failed"
        type="warning"
        description="Unknown email or invalid password."
        v-show="error"
        @close="error = false"
      >
      </el-alert>
      <el-form :model="credentials" @keyup.enter="signIn" label-width="120px">
        <h3>Welcome to the IT-Manager</h3>
        <br />
        <div>
          <el-button style="width: 60%" @click="signInMicrosoft">
            <span id="centerInBtn">Login</span></el-button
          >
        </div>
        <!-- <h3>or with your WPM account</h3>
        <div class="form-item-container">
          <div>
            <el-form-item class="form-item" label="Email">
              <el-input
                v-model="credentials.email"
                placeholder="Email"
                prefix-icon="Message"
              >
              </el-input>
            </el-form-item>
          </div>
          <br />
          <el-form-item class="form-item" label="Password">
            <el-input
              prefix-icon="el-icon-key"
              :type="type"
              v-model="credentials.password"
            >
              <template #suffix>
                <el-icon class="el-input__icon" @click="toggleMask"
                  ><View
                /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <div class="btn-container">
            <div style="margin-bottom: 10px">
              <el-button style="width: 60%" type="primary" @click="signIn"
                >Login</el-button
              >
            </div>
          </div>
        </div>
        <br />
        <br />
        <el-button type="text" @click="openResetPassword"
          >Forgot Password</el-button
        >
        <div v-if="btn1Label !== ''" style="margin-top: 10px">
          <el-button
            style="width: 150px; border: 1px solid lightgrey; font-size: 10px"
            @click="changeEnvironment('btn1')"
            >{{ btn1Label }}</el-button
          >
          <el-button
            style="width: 150px; border: 1px solid lightgrey; font-size: 10px"
            @click="changeEnvironment('btn2')"
            >{{ btn2Label }}</el-button
          > -->
        <!-- </div> -->
      </el-form>
    </el-card>
    <footer>
      <a href="https://www.bitstream.de/">Bitstream</a>
      <a href="https://www.bitstream.de/impressum">Impress</a>
      <a href="https://www.bitstream.de/datenschutz/">Privacy</a>
    </footer>
    <reset-password ref="resetPassword"></reset-password>
  </div>
</template>

<script>
import Auth from "../auth";
import Impersonation from "../impersonation";
import ResetPassword from "./ResetPassword.vue";
import * as Msal from "@azure/msal-browser";

// msal configuration
// const msalConfig = {
//   auth: {
//     clientId: "b77a5416-994e-4126-be5d-0a91b820cbf1",
//     tenantId: "85469981-451b-4862-b1d0-32dd2b77be68",
//     authority: "https://login.microsoftonline.com/common/",
//     redirectUri: "http://localhost:1783",
//     autoRefershToken: false,
//   },
// };
// const msalInstance = new Msal.PublicClientApplication(msalConfig);

// Removed password component in order for login to work in IE11 on key press of enter key

export default {
  name: "login",
  components: {
    ResetPassword,
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
        name: "",
      },
      error: false,
      type: "password",
      btn1Label: "",
      btn2Label: "",
      btn1Value: "",
      btn2Value: "",
    };
  },
  created() {
    var url = "" + window.APPLICATION_SETTING_API_ENDPOINT_URL;
    if (url.includes("bitstreamrelease")) {
      this.btn1Label = "Development Environment";
      this.btn2Label = "Production Environment";
      this.btn1Value = "https://bitstreamdev.azurewebsites.net";
      this.btn2Value = "https://portal.bitstream.azurewebsites.net";
    } else if (url.includes("bitstreamdev")) {
      this.btn1Label = "Release Environment";
      this.btn2Label = "Production Environment";
      this.btn1Value = "https://bitstreamrelease.azurewebsites.net";
      this.btn2Value = "https://portal.bitstream.azurewebsites.net";
    } else if (url.includes("portal.bitstream")) {
      this.btn1Label = "Development Environment";
      this.btn2Label = "Release Environment";
      this.btn1Value = "https://bitstreamdev.azurewebsites.net";
      this.btn2Value = "https://bitstreamrelease.azurewebsites.net";
    } else if (url.includes("localhost")) {
      this.btn1Label = "Development Environment";
      this.btn2Label = "Release Environment";
      this.btn1Value = "https://bitstreamdev.azurewebsites.net";
      this.btn2Value = "https://bitstreamrelease.azurewebsites.net";
    }
  },
  methods: {
    parseJwt(token) {
      var base64Claims = token.split(".")[1];
      var base64 = base64Claims.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    changeEnvironment(val) {
      var route = "";
      if (val === "btn1") {
        route = this.btn1Value;
      } else if (val === "btn2") {
        route = this.btn2Value;
      }
      window.location = route;
    },
    openResetPassword() {
      this.$refs.resetPassword.openDialog();
    },
    signIn() {
      this.$store.state.loginType = "local";
      Auth.signIn(this.credentials).then(this.signInSuccess, this.signInError);
    },
    async signInMicrosoft() {
      this.$store.state.loginType = "microsoft";
      const msalConfig = {
        auth: {
          clientId: window.APPLICATION_SETTING_B2C_CLIENT_ID,
          authority: window.APPLICATION_SETTING_B2C_AUTHORITY,
          redirectUri: window.APPLICATION_SETTING_B2C_REDIRECT_URL,
          knownAuthorities: window.APPLICATION_SETTING_B2C_KNOWN_AUTHS,
          autoRefershToken: false,
        },
      };
      const msalInstance = new Msal.PublicClientApplication(msalConfig);
      try {
        const loginRequest = {
          scopes: window.APPLICATION_SETTING_B2C_SCOPES,
        };

        msalInstance.loginPopup(loginRequest).then(() => {
          // We need an account for accessing a token silently
          const accounts = msalInstance.getAllAccounts();
          var request = {
            account: accounts[0],
            scopes: window.APPLICATION_SETTING_B2C_SCOPES,
          };

          msalInstance
            .acquireTokenSilent(request)
            .then((tokenResponse) => {
              if (
                tokenResponse.account.idTokenClaims.newUser !== undefined &&
                tokenResponse.account.idTokenClaims.newUser
              ) {
                var user = {
                  firstName: tokenResponse.account.idTokenClaims?.given_name,
                  familyName: tokenResponse.account.idTokenClaims?.family_name,
                  email: tokenResponse.account.idTokenClaims?.emails[0],
                  b2cId: tokenResponse.account.localAccountId,
                };
                window.axiosInstance
                  .post("connect/registerUser", user)
                  .then(() =>
                    this.$message.success(
                      'The user "' + user.email + '" was created successfully!'
                    )
                  );
              }
              var token = tokenResponse.accessToken;
              window.axiosInstance.defaults.headers.common["Authorization"] =
                "Bearer " + token;

              localStorage.setItem("token", token);

              // Set auth for ui permissions
              window.axiosInstance
                .get("auth/getClaimsForUi")
                .then((response) => {
                  // Get account infos via token of server and store in local storage
                  const accountProjection = response.data.token;
                  var claims = accountProjection.split(".")[1];
                  let data = window.atob(claims);
                  const auth = JSON.parse(data); // decode JWT payload into JSON
                  localStorage.setItem("auth", JSON.stringify(auth));

                  // initialize frontend state
                  this.$store.dispatch("init");
                  const initialState = {
                    admin: "systemhouses",
                    systemhouse: "customers",
                    customer: "bases",
                  };

                  const permission = Auth.getPermission();
                  if (permission === "customer") {
                    const customer = JSON.parse(Auth.getAuth()["customer"]);
                    Impersonation.setCustomer(customer);
                  }
                  if (permission !== "") {
                    this.$router.push(initialState[permission]);
                  } else {
                    this.$router.push("/noPermission/" + auth.sub);
                  }
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    this.$router.push("/noPermission");
                  }
                  console.log({ error });
                });
              // this.signInSuccess();
            })
            .catch(function (error) {
              console.log({ error });
              //Acquire token silent failure, and send an interactive request
              // msalInstance
              //   .acquireTokenPopup(request)
              //   .then(function (accessTokenResponse) {
              //     // Acquire token interactive success
              //     let accessToken = accessTokenResponse.accessToken;
              //     // Call your API with token

              //   })
              //   .catch(function (error) {
              //     // Acquire token interactive failure
              //     console.log(error);
              //   });
              // }
            });
        });
      } catch (err) {
        console.log("LoginError: ", { err });
      }
    },
    signInSuccess() {
      this.$store.dispatch("init");
      const initialState = {
        admin: "systemhouses",
        systemhouse: "customers",
        customer: "bases",
      };
      const permission = Auth.getPermission();
      if (permission === "customer") {
        const customer = JSON.parse(Auth.getAuth()["customer"]);
        Impersonation.setCustomer(customer);
      }
      this.$router.push(initialState[permission]);
    },
    signInError() {
      this.error = true;
    },
    toggleMask() {
      this.type = this.type === "password" ? "text" : "password";
    },
  },
  computed: {
    signInType() {
      return this.$store.state.loginType;
    },
  },
};
</script>

<style scoped>
.form-item-container {
  text-align: center;
}

.login {
  height: 100%;
  background: linear-gradient(#0039bf, #2680ff);
}

.heading {
  color: #fff;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.logo {
  width: 62px;
  height: 62px;
}

.title {
  margin: 10px 0 0 10px;
}

.credential-form {
  width: 35%;
  height: 70%;
  margin: 0 auto 0 auto;
}

.form-item-old {
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

footer {
  margin-top: 20px;
  font-size: 0.9em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

footer a {
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
}

#centerInBtn {
  vertical-align: middle;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
</style>
