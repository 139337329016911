<template>
  <div class="container">
    <h3 style="text-align: center">OS Image Streams</h3>
    <el-col style="width: 100%" :span="22" :offset="1">
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input placeholder="Find OS Image" v-model="search">
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubset"
              @click="clearSearch"
            ></i> -->
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results">
            <div>
              showing {{ filteredSWImages.length }} of {{ imageStreams.length }}
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button type="primary" @click="openAddImageStream"
            >Add OS Image Stream</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table :data="filteredSWImages" highlight-current-row>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="edition" label="Edition"></el-table-column>
        <el-table-column
          prop="architecture"
          label="Architecture"
        ></el-table-column>
        <el-table-column prop="language" label="Language"></el-table-column>
        <el-table-column prop="vendor" label="Vendor"></el-table-column>
        <el-table-column
          prop="descriptionShort"
          label="Description"
        ></el-table-column>
        <el-table-column prop="icon" label="Icon">
          <template v-slot="scope">
            <img
              v-if="
                scope.row.icon !== null &&
                scope.row.icon.id !== '' &&
                scope.row.icon.name != ''
              "
              :src="scope.row.icon"
              height="40"
              width="40"
            />
            <img
              v-else
              src="../../../static/packages-icon-21.png"
              height="40"
              width="40"
            />
          </template>
        </el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :deleteItem="true"
              :edit="true"
              :showStreamImages="true"
              @delete="deleteImage(scope.row)"
              @edit="openEditImageStream(scope.row)"
              @showStreamImages="openStreamImages(scope.row)"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <add-sw-image ref="addSWImage"></add-sw-image>
    <edit-sw-image ref="editImage"></edit-sw-image> -->
    <add-image-stream ref="addImageStream"></add-image-stream>
    <edit-image-stream ref="editImageStream"></edit-image-stream>
    <stream-images ref="streamImages"></stream-images>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
// import AddSWImage from '../swImages/AddSWImage.vue'
// import EditImage from '../swImages/EditImage.vue'
import AddImageStream from "./AddImageStream.vue";
import EditImageStream from "./EditImageStream.vue";
import StreamImages from "./StreamImages.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    // 'add-sw-image': AddSWImage,
    "edit-image-stream": EditImageStream,
    "add-image-stream": AddImageStream,
    "stream-images": StreamImages,
  },
  created() {
    this.$store.dispatch("getImageStreams");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    openStreamImages(stream) {
      this.$refs.streamImages.openDialog(
        stream.id,
        stream.subFolderName,
        stream.name,
        stream.type
      );
    },
    deleteImage(image) {
      this.$confirm(
        "This will permanently delete the stream and remove the stream from the OS packages. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteStreamSuccess.bind(this, image), this.deleteError);
    },
    deleteStreamSuccess(image) {
      this.$store.dispatch("deleteImageStream", image.id);
    },
    deleteError() {},
    clearSearch() {
      this.search = "";
    },
    openEditImageStream(image) {
      this.$refs.editImageStream.openDialog(image);
    },
    openAddImageStream() {
      this.$refs.addImageStream.openDialog();
    },
  },
  computed: {
    ...mapState(["imageStreams"]),
    filteredSWImages() {
      return this.filterEntities(this.imageStreams, this.search);
    },
    isSubset() {
      return this.imageStreams.length !== this.filteredSWImages.length;
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.container {
  padding-bottom: 50px;
}
</style>
