<template>
  <div style="margin-top: 30px">
    <h3 style="text-align: center">Customer Release-Plans</h3>
    <el-col :span="22" :offset="1">
      <el-row type="flex" class="header">
        <el-col :span="6" class="search">
          <el-input
            placeholder="Find Release Plan"
            v-model="searchReleasePlans"
            prefix-icon="Search"
          >
            <template #suffix>
              <el-icon
                v-if="isSubsetReleasePlans"
                @click="clearSearchReleasePlans"
              >
                <CircleClose />
              </el-icon>
            </template>
          </el-input>
          <div class="results" v-if="isSubsetReleasePlans">
            <div v-if="!search">
              showing {{ filteredReleasePlans.length }} of
              {{ releasePlans.length }}
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="8" :offset="14" style="margin-bottom: 30px">
      <el-button type="primary" @click="openAddReleaseDialog"
        >Add Release-Plan</el-button
      >
    </el-col>
    <div class="tableContainer">
      <el-table :data="filteredReleasePlans">
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column width="110">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :deleteItem="true"
              @edit="openEditReleasePlan"
              @delete="deleteReleasePlan"
            >
            </actions-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <release-plan-add ref="releasePlanAdd"></release-plan-add>
    <release-plan-edit ref="releasePlanEdit"></release-plan-edit>
  </div>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import FilterMixin from "../../mixins/FilterMixin";
import AddReleasePlan from "../releasePlan/AddReleasePlan.vue";
import EditReleasePlan from "../releasePlan/EditReleasePlan.vue";
import ActionsDropdown from "../shared/ActionsDropdown.vue";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    "release-plan-add": AddReleasePlan,
    "release-plan-edit": EditReleasePlan,
  },
  created() {
    this.$store.dispatch(
      "getCustomersReleasePlans",
      impersonation.getCustomer().id
    );
  },
  data() {
    return {
      searchReleasePlans: "",
    };
  },
  methods: {
    deleteReleasePlan(releasePlan) {
      this.$store.dispatch("deleteReleasePlan", releasePlan.id);
    },
    openEditReleasePlan(releasePlan) {
      this.$refs.releasePlanEdit.openDialog(releasePlan);
    },
    openAddReleaseDialog() {
      this.$refs.releasePlanAdd.openDialog();
    },
    clearSearchReleasePlans() {
      this.searchReleasePlans = "";
    },
  },
  computed: {
    ...mapState(["releasePlans"]),
    filteredReleasePlans() {
      return this.filterEntities(this.releasePlans, this.searchReleasePlans);
    },
    isSubsetReleasePlans() {
      return this.releasePlans.length !== this.filteredReleasePlans.length;
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}
</style>
