<template>
  <div class="container">
    <el-col :span="22" :offset="1">
      <h3 style="text-align: center">Device Options</h3>
      <el-row type="flex" class="header">
        <el-col :span="8" class="search">
          <el-input placeholder="Find device option" v-model="search" autofocus>
            <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i
              slot="suffix"
              class="el-input__icon el-icon-circle-close clickable"
              v-if="isSubset"
              @click="clearSearch"
            ></i> -->
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="results" v-if="isSubset">
            showing {{ filteredDeviceOptions.length }} of
            {{ deviceOptions.length }}
          </div>
        </el-col>
        <el-col :span="8" class="bs-align-right">
          <!-- icon="el-icon-plus" -->
          <el-button type="primary" @click="openDeviceOptionAdd"
            >Add Device Option</el-button
          >
        </el-col>
      </el-row>
    </el-col>
    <div class="tableContainer">
      <el-table
        :data="filteredDeviceOptions"
        highlight-current-row
        v-loading.body="deviceOptionLoading"
      >
        <el-table-column prop="name" label="Name" sortable> </el-table-column>
        <el-table-column prop="description" label="Description">
        </el-table-column>
        <el-table-column prop="versions" label="Versions" sortable width="130">
          <template v-slot="scope">
            {{ scope.row.versions.length }} versions
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="90">
          <template v-slot="scope">
            <actions-dropdown
              :hideRetreatFromShop="true"
              :entity="scope.row"
              :edit="true"
              :download="true"
              :version="true"
              :deleteItem="true"
              @edit="openDeviceOptionEdit(scope.row)"
              @download="downloadDeviceOption(scope.row)"
              @version="openDeviceOptionVersionAdd(scope.row)"
              @delete="deleteDeviceOption"
            >
            </actions-dropdown>
            <!-- <el-button size="small" @click="openDeviceOptionEdit(scope.row)" icon="el-icon-edit">Edit</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <device-option-download ref="deviceOptionDownload"></device-option-download>
    <device-option-add ref="deviceOptionAdd"></device-option-add>
    <device-option-edit ref="deviceOptionEdit"></device-option-edit>
    <device-option-version-add
      ref="deviceOptionVersionAdd"
    ></device-option-version-add>
  </div>
</template>

<script>
import DeviceOptionAdd from "../deviceoptions/DeviceOptionAdd";
import DeviceOptionEdit from "../deviceoptions/DeviceOptionEdit";
import DeviceOptionDownload from "../deviceoptions/DeviceOptionDownload";
import DeviceOptionVersionAdd from "../deviceoptions/DeviceOptionVersionAdd";
import { success, errorMessage } from "../../store/modules/util";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import { deleteOption } from "../../services/deviceoptions";
import _ from "lodash";

export default {
  mixins: [FilterMixin],
  components: {
    ActionsDropdown,
    DeviceOptionAdd,
    DeviceOptionEdit,
    DeviceOptionVersionAdd,
    DeviceOptionDownload,
  },
  created() {
    this.$store.dispatch("getDeviceOptions");
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    downloadDeviceOption(deviceOption) {
      this.$refs.deviceOptionDownload.open(_.cloneDeep(deviceOption));
    },
    clearSearch() {
      this.search = "";
    },
    openDeviceOptionAdd() {
      this.$refs.deviceOptionAdd.open();
    },
    openDeviceOptionEdit(deviceOption) {
      this.$refs.deviceOptionEdit.open(_.cloneDeep(deviceOption));
    },
    openDeviceOptionVersionAdd(deviceOption) {
      if (!deviceOption.content) {
        deviceOption.content = "";
      }
      this.$refs.deviceOptionVersionAdd.open(_.cloneDeep(deviceOption));
    },
    deleteDeviceOption(option) {
      this.$confirm(
        "This will permanently delete the option. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteOptionSuccess.bind(this, option), this.deleteError);
    },
    deleteOptionSuccess(option) {
      deleteOption(option.id)
        .then(() => {
          success();
          this.$store.dispatch("getDeviceOptions");
        })
        .catch((err) => errorMessage(err.response.data));
    },
  },
  computed: {
    ...mapState(["deviceOptions", "deviceOptionLoading"]),
    filteredDeviceOptions() {
      return this.filterEntities(this.deviceOptions, this.search);
    },
    isSubset() {
      return this.deviceOptions.length !== this.filteredDeviceOptions.length;
    },
  },
};
</script>

<style lang="css" scoped>
.tableContainer {
  width: 80%;
  margin: 0 auto;
}

.container {
  padding-bottom: 50px;
}
</style>
