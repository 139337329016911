<template>
  <el-row type="flex" justify="center">
    <el-col :md="12" :sm="18" :xs="24">
      <el-card>
        <h4>Settings</h4>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="form.name"
              :placeholder="auth.name"
              :disabled="isAdmin"
            ></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="form.email"
              :placeholder="auth.sub"
              type="email"
              :disabled="isAdmin"
            ></el-input>
          </el-form-item>
          <el-form-item label="New password" prop="newPassword">
            <el-input
              v-model="form.newPassword"
              placeholder="New password"
              :type="type"
            >
              <!-- slot="suffix" -->
              <!-- <i
                class="el-input__icon el-icon-more clickable"
                @click="toggleMask"
              ></i> -->
            </el-input>
          </el-form-item>
          <el-form-item label="Confirm" prop="newPasswordConfirm">
            <el-input
              v-model="newPasswordConfirm"
              placeholder="Confirm the new password"
              :type="type"
            >
              <!-- slot="suffix" -->
              <!-- <i
                class="el-input__icon el-icon-more clickable"
                @click="toggleMask"
              ></i> -->
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="submit(form)"
              >Save</el-button
            >
          </el-form-item>
          <el-alert
            title="User changed successfully!"
            type="success"
            v-show="success"
            @close="success = false"
          >
          </el-alert>
          <el-alert
            title="User could  not be changed."
            type="warning"
            v-show="error"
            @close="error = false"
          >
          </el-alert>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import Auth from "../../auth";
import ToggleMask from "../../mixins/ToggleMask";
import passwordStrength from "../../validations/passwordStrength";
import updateUserSettings from "../../services/usersettings";

export default {
  name: "settings",
  mixins: [ToggleMask],
  data() {
    let validatePass = (rule, value, callback) => {
      if (
        this.newPasswordConfirm === "" ||
        this.newPasswordConfirm === undefined
      ) {
        callback(new Error("Please repeat the password"));
      } else if (this.newPasswordConfirm !== this.form.newPassword) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    return {
      success: false,
      error: false,
      type: "password",
      form: {
        name: "",
        email: "",
        newPassword: "",
      },
      newPasswordConfirm: "",
      rules: {
        newPassword: [
          {
            required: true,
            message: "Please input new password",
            trigger: "change",
          },
          { validator: passwordStrength, trigger: "blur" },
          {
            min: 8,
            message: "Password should at least have 8 chars",
            trigger: "change",
          },
        ],
        newPasswordConfirm: [
          { validator: validatePass, required: true, trigger: "change" },
        ],
      },
    };
  },
  computed: {
    auth() {
      return Auth.getAuth();
    },
    isAdmin() {
      return Auth.isAdmin();
    },
  },
  methods: {
    submit(form) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = {
            name: form.name ? form.name : this.auth.name,
            email: form.email ? form.email : this.auth.sub,
            password: form.newPassword,
          };
          updateUserSettings(payload).then(
            this.updateSuccess,
            this.updateError
          );
        }
      });
    },
    updateSuccess() {
      this.success = true;
    },
    updateError() {
      this.error = true;
    },
  },
};
</script>
