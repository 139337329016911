<template>
  <el-dialog v-model="showDialog" title="Add Certification">
    <el-form ref="form" :rules="rules" :model="cert" label-width="180px">
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="cert.name"
          placeholder="Certification name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          type="textarea"
          v-model="cert.description"
          placeholder="Description"
          :autosize="{ minRows: 3 }"
        ></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(cert)">Add</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import CertValidation from "./CertValidation";

export default {
  name: "cert-add",
  mixins: [CertValidation],
  data() {
    return {
      showDialog: false,
      files: [],
      cert: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    submit(cert) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("addCert", _.cloneDeep(cert));
          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
  },
};
</script>
