<template>
  <el-col :offset="1" :span="22">
    <template>
      <div v-for="(category, index) in categories" :key="index">
        <div v-if="categoryProperties(category)">
          <h3>{{ category.category }}</h3>
          <div>
            <el-table
              :data="category.values"
              height="250"
              highlight-current-row
            >
              <el-table-column label="Name" prop="name"> </el-table-column>
              <el-table-column label="Value" prop="value"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <client-parameters></client-parameters>
      <!--<div>
        <h3>Installed Software</h3>
        <el-row>
          <el-col :span="22">
            <el-row class="header" type="flex">
              <el-col :span="8" class="search">
                <el-input placeholder="Find software" v-model="search">
                  <i class="el-input__icon el-icon-search" slot="prefix"></i>
                  <i @click="clearSearch" class="el-input__icon el-icon-circle-close clickable" slot="suffix"
                     v-if="isSubset"></i>
                </el-input>
              </el-col>
              <el-col :span="8">
                <div class="results" v-if="isSubset&&preinstalledSoftware">
                  showing {{filteredSoftware.length}} of {{preinstalledSoftware.length}}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-table
          :data="filteredSoftware"
          height="250"
          highlight-current-row>
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column label="Version" prop="version"></el-table-column>
          <el-table-column label="Size in KB" prop="size"></el-table-column>
          <el-table-column label="Installation Date" prop="installedAt"></el-table-column>
        </el-table>
      </div>-->
      <br />
    </template>
  </el-col>
</template>

<script>
import { mapState } from "vuex";
import impersonation from "../../impersonation";
import ClientParameters from "../clients/ClientParameters.vue";
import _ from "lodash";
import FilterMixin from "../../mixins/FilterMixin";

export default {
  name: "datasheet",
  mixins: [FilterMixin],
  components: {
    ClientParameters,
  },
  created() {
    this.customerId = impersonation.getCustomer().id;
    this.clientId = this.$route.params.id;
    this.uploadUrl =
      window.APPLICATION_SETTING_API_ENDPOINT_URL +
      "/customers/" +
      this.customerId +
      "/clients/" +
      this.clientId +
      "/upload/unattend";
    this.$store.dispatch("getDatasheet", this.clientId);
  },
  data() {
    return {
      customerId: "",
      clientId: "",
      uploadUrl: "",
      search: "",
    };
  },
  methods: {
    back() {
      this.$router.push({ name: "devices" });
    },
    isSubset() {
      // return this.preinstalledSoftware.length !== this.filteredSoftware.length
    },
    clearSearch() {
      this.search = "";
    },
    setFiles(file) {
      if (file != null) {
        this.datasheet.files = { id: file.response.id, name: "file uploaded" };
      } else {
        this.datasheet.files = null;
      }
    },
    handleRemove() {
      this.setFiles(null);
    },
    handleSuccess(a, b) {
      this.setFiles(b);
    },
    formatValues(values) {
      let valueArray = values.split(",,");
      for (let i = 0; i < valueArray.length; i++) {
        if (valueArray[i].length === 0) {
          valueArray.splice(i, 1);
        }
      }
      return valueArray;
    },
    showDataTeaser(value) {
      if (value.length > 50) {
        return value.substring(0, 50) + "...";
      } else {
        return value;
      }
    },
    categoryProperties(cat) {
      var val = _.find(this.datasheet.categories, cat);
      if (val.values !== null && val.values.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState(["datasheet", "datasheetLoading"]),
    filteredSoftware() {
      return this.filterEntities(this.preinstalledSoftware, this.search);
    },
    files() {
      if (this.datasheet.unattend) {
        return [{ name: "file uploaded" }];
      } else {
        return [];
      }
    },
    categories() {
      return this.datasheet.categories;
    },
    preinstalledSoftware() {
      return this.datasheet.preinstalledSoftwares;
    },
  },
};
</script>

<style scoped>
.border-red {
  border: 1px solid red;
}

.border-black {
  border: 1px solid black;
}

.preserve-space {
  white-space: pre;
}

.heading {
  font-size: 14px;
  color: #909399;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: bold;
  float: left;
}

.contents {
  font-size: 14px;
  color: #606266;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: left;
  margin: 0;
}

.nospace {
  margin-bottom: 0;
}
</style>
