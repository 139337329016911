<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find domain registrations"
              v-model="search"
              autofocus
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredDomainregs.length }} of {{ Domainregs.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <el-button type="primary" @click="openDomainregAdd"
              ><el-icon><Plus />></el-icon>Add domain registration</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="filteredDomainregs" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable> </el-table-column>
          <el-table-column width="110">
            <template v-slot="scope">
              <!-- TODO: add @delete="confirmDelete" -->
              <actions-dropdown
                :hideRetreatFromShop="true"
                :edit="true"
                :entity="scope.row"
                @edit="openDomainregEdit"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <domainreg-add ref="domainregAdd"></domainreg-add>
    <domainreg-edit ref="domainregEdit"></domainreg-edit>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import DomainregAdd from "./DomainregAdd.vue";
import DomainregEdit from "./DomainregEdit.vue";

export default {
  name: "admin-domain-reg",
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getDomainregs");
  },
  components: {
    ActionsDropdown,
    DomainregAdd,
    DomainregEdit,
  },
  methods: {
    openDomainregAdd() {
      this.$refs.domainregAdd.open();
    },
    openDomainregEdit(domainreg) {
      this.$refs.domainregEdit.open(_.cloneDeep(domainreg));
    },
    confirmDelete(domainreg) {
      this.$confirm(
        "This will permanently delete the domain registration. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, domainreg), this.deleteError);
    },
    deleteSuccess(domainreg) {
      this.$store.dispatch("deleteDomainreg", domainreg);
    },
    deleteError() {},
  },
  computed: {
    ...mapState(["domainregs"]),
    filteredDomainregs() {
      return this.filterEntities(this.domainregs, this.search);
    },
    isSubset() {
      return this.domainregs.length !== this.filteredDomainregs.length;
    },
  },
};
</script>
