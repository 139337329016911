<template>
  <el-dialog v-model="showDialog" title="Execute script">
    <el-form ref="form" :model="model" :rules="rules" label-width="280px">
      <el-form-item
        label="Script version"
        prop="scriptVersionId"
        placeholder="Select version"
        class="script-selection"
      >
        <el-select
          v-model="model.scriptVersionId"
          filterable
          placeholder="Select version"
          @change="scriptSelected"
        >
          <el-option
            v-for="version in scriptVersions"
            :key="version.id"
            :label="version.name"
            :value="version.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <h2 v-if="model.parameters">Parameters</h2>
      <span v-if="model.parameters && model.parameters.length === 0"
        >This script has no parameters.</span
      >
      <div v-if="model.parameters">
        <el-form-item
          v-for="parameter in model.parameters"
          :rules="rules.parameter"
          :label="parameter.key"
          :key="parameter.key"
          placeholder="Specify Parameter"
          required
        >
          <el-input v-model="parameter.value"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(model)">Execute</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from "element-plus";
import { mapState } from "vuex";
import ImpersonationMixin from "../../mixins/ImpersonationMixin";
import { scriptGetParams, scriptExecute } from "../../services/scripts";

export default {
  name: "script-execute",
  mixins: [ImpersonationMixin],
  created() {
    this.$store.dispatch("getScripts");
  },
  data() {
    return {
      showDialog: false,
      baseId: null,
      domainId: null,
      scriptId: null,
      // domain: {},
      model: {
        scriptVersionId: "",
        parameters: undefined,
      },
      rules: {
        scriptVersionId: [
          {
            required: true,
            message: "Please select a script.",
            trigger: "change",
          },
        ],
        parameter: [
          {
            required: true,
            message: "Please specify a value.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    scriptSelected(scriptVersionId) {
      if (!scriptVersionId) {
        return;
      }

      const loading = ElLoading.service({ fullscreen: true });
      scriptGetParams(
        this.customer.id,
        this.baseId,
        this.domainId,
        scriptVersionId
      ).then((response) => {
        this.model.parameters = response.data;
        loading.close();
      });
    },
    open(baseId, domainId, scriptId) {
      this.baseId = baseId;
      this.domainId = domainId;
      this.scriptId = scriptId;
      this.model = {
        scriptVersionId: null,
        parameters: null,
      };
      this.showDialog = true;
    },
    submit(model) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          scriptExecute(
            this.customer.id,
            this.baseId,
            this.domainId,
            model.scriptVersionId,
            model.parameters
          ).then(() => {
            //  console.log(response.data)
          });
          this.showDialog = false;
          this.$refs.form.resetFields();
        }
      });
    },
  },
  computed: {
    ...mapState(["scripts"]),
    scriptVersions() {
      const scriptVersions = [];
      if (this.scripts && this.scriptId) {
        const script = this.scripts.filter(
          (script) => script.id === this.scriptId
        );
        const name = script[0].name;
        script[0]["versions"].map((version) =>
          scriptVersions.push({
            name: name + " v" + version.number,
            id: version.id,
          })
        );
      }
      return scriptVersions;
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
