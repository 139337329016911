export function getSystemhouses () {
  return window.axiosInstance.get('/systemhouses')
}

export function addSystemhouse (systemhouse) {
  return window.axiosInstance.post('/systemhouses', systemhouse)
}

export function editSystemhouse (systemhouse) {
  return window.axiosInstance.put(`/systemhouses/${systemhouse.id}`, systemhouse)
}

export function deleteSystemhouse (systemhouse) {
  return window.axiosInstance.delete(`/systemhouses/${systemhouse.id}`)
}

export function getSystemhousesCustomers (payload) {
  return window.axiosInstance.post(`/systemhouses/getSystemhousesCustomers`, payload)
}

export function getSyshouseUserCustomers () {
  return window.axiosInstance.get('/systemhouses/getSystemhouseUserData')
}
