<template>
  <el-dialog v-model="showDialog" title="Credentials Tutorial" width="75%">
    <div class="steps-footer">
      <el-button
        class="beautify"
        type="primary"
        @click="prev"
        :disabled="step < 1"
      >
        <el-icon>
          <ArrowLeft />
        </el-icon>
        Previous
      </el-button>
      <div style="width: 100%; padding: 0 20px; text-align: left">
        <el-steps :active="step" finish-status="success">
          <el-step title="Step 1"></el-step>
          <el-step title="Step 2"></el-step>
          <el-step title="Step 3"></el-step>
          <el-step title="Step 4"></el-step>
          <el-step title="Step 5"></el-step>
          <el-step title="Step 6"></el-step>
          <el-step title="Step 7"></el-step>
          <el-step title="Step 8"></el-step>
          <el-step title="Step 9"></el-step>
          <el-step title="Step 10"></el-step>
          <el-step title="Step 11"></el-step>
          <el-step title="Step 12"></el-step>
        </el-steps>
      </div>
      <el-button
        class="beautify"
        type="primary"
        @click="next"
        :disabled="step >= 11"
      >
        Next
        <el-icon>
          <ArrowRight />
        </el-icon>
      </el-button>
    </div>

    <div v-if="step === 0">
      <h3>
        Sign in the <a href="https://portal.azure.com">Azure Portal</a> (MS
        Account)
      </h3>
      <img
        src="static/retrieveCredentials/tutorial-1-sign-in.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 1">
      <h3>Register a new app by following the steps in the screenshot.</h3>
      <img
        src="static/retrieveCredentials/tutorial-2-add-app.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 2">
      <h3>Fill out the form and click 'Create' on the bottom.</h3>
      <img
        src="static/retrieveCredentials/tutorial-3-create-app.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 3">
      <h3>
        Look up the newly created app in the list and click it. Write down the
        Application ID (= BitStream Client ID)<br />
        Click the "All settings" and click "Keys" to generate an application
        key.
      </h3>
      <img
        src="static/retrieveCredentials/tutorial-4-show-app.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 4">
      <h3>
        Fill out the form and click "Save" on the top. Please note the secret
        because it will de displayed only once (= Client Secret)
      </h3>
      <img
        src="static/retrieveCredentials/tutorial-5-create-key.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 5">
      <h3>Go back to the app and click on "Required Permissions".</h3>
      <img
        src="static/retrieveCredentials/tutorial-6-go-to-permissions.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 6">
      <h3>Click "Add" and then click "Select an API".</h3>
      <img
        src="static/retrieveCredentials/tutorial-7-select-api.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 7">
      <h3>Click "Windows Azure Service Management API".</h3>
      <img
        src="static/retrieveCredentials/tutorial-8-select-api.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 8">
      <h3>
        Activate "Delegated Permissions" click the button "Select" and then
        click the button "Done".
      </h3>
      <img
        src="static/retrieveCredentials/tutorial-9-enable-access.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 9">
      <h3>
        Go back to the "Azure Active Directory", then click "Properties" and
        copy the Directory ID (= Tennant ID)
      </h3>
      <img
        src="static/retrieveCredentials/tutorial-10-subscription.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 10">
      <h3>
        Go to Subscriptions and select the subscription on which the workplace
        manager should work. Press on Access control and add a new assignment.
      </h3>
      <img
        src="static/retrieveCredentials/ScreenshotStep11.png"
        class="screenshot"
      />
    </div>

    <div v-if="step === 11">
      <h3>
        Fill out the form and search for the app registration. Select it and
        press save. You are done.
      </h3>
      <img
        src="static/retrieveCredentials/ScreenshotStep12.png"
        class="screenshot"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "credentials-tutorial",
  data() {
    return {
      showDialog: false,
      step: 0,
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    next() {
      this.step += 1;
    },
    prev() {
      this.step -= 1;
    },
  },
};
</script>

<style scoped>
.screenshot {
  width: 100%;
}
.el-row {
  margin-bottom: 25px;
}

.steps-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>

<style>
.step-dialog .el-dialog__footer {
  border-top: 1px solid #eee;
  padding: 15px 20px;
}

.step-dialog {
  display: flex;
  flex-direction: column;
}

.step-dialog .el-dialog__body {
  flex-grow: 1;
}
.beautify {
  width: 10em;
}
</style>
