<template>
  <el-dialog v-model="showDialog" title="Add new script version">
    <el-form :model="deviceOption" ref="form">
      <el-form-item label="Script" prop="content">
        <div class="editor-container">
          <prism-editor
            class="my-editor height-300"
            v-model="deviceOption.content"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </div>
      </el-form-item>
      <el-form-item label="Files" prop="attachements">
        <upload-files
          :action="uploadUrl"
          :file-list="deviceOption.attachments"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        ></upload-files>
      </el-form-item>
    </el-form>
    <el-button @click="submit" type="primary">Add</el-button>
  </el-dialog>
</template>
<script>
import { PrismEditor } from "vue-prism-editor";

import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-powershell";

import _ from "lodash";
import DeviceOptionValidation from "./DeviceOptionValidation";
// import { Drop } from "vue-drag-drop";
import { getDeviceOption } from "../../services/deviceoptions";
import UploadFiles from "../shared/UploadFiles";

export default {
  name: "device-option-version-add",
  mixins: [DeviceOptionValidation],
  components: { UploadFiles, PrismEditor },
  data() {
    let validateContent = (rule, value, callback) => {
      console.log({ rule });
      console.log({ value });
      callback();
    };
    return {
      showDialog: false,
      uploadUrl: window.APPLICATION_SETTING_API_ENDPOINT_URL + "/scripts/temp",
      deviceOption: {
        id: "",
        content: "",
        attachments: [],
      },
      files: [],
      editor: "",
      rules: {
        content: [
          {
            validator: validateContent,
            required: true,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js, "powershell");
    },
    open(deviceoption) {
      this.deviceOption = deviceoption;
      getDeviceOption(deviceoption).then((response) => {
        let resp = response.data;
        this.deviceOption = resp;
      });
      this.showDialog = true;
    },
    handleDrop(data, e) {
      let vm = this;
      e.preventDefault();
      let reader = new window.FileReader();
      let files = e.target.files || e.dataTransfer.files;
      reader.onload = function () {
        vm.deviceOption.content = reader.result;
      };
      reader.readAsText(files.item(0));
    },
    submit() {
      this.$refs.form.validate((valid) => {
        console.log({ valid });
        this.$store.dispatch(
          "addDeviceOptionVersion",
          _.cloneDeep(this.deviceOption)
        );
        this.showDialog = false;
        this.$refs.form.resetFields();
        // if (valid) {
        // } else {
        //   console.log("DEVICE OPTION INVALID!");
        // }
      });
    },
    setFiles(fileList) {
      this.deviceOption.attachments = fileList.map(function (file) {
        return file.response;
      });
    },
    handleRemove(a, fileList) {
      this.setFiles(fileList);
    },
    handleSuccess(a, b, fileList) {
      this.setFiles(fileList);
    },
  },
  computed: {
    attachments() {
      return this.deviceOption.attachments.map(function (file) {
        return { name: file.name };
      });
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.editor-container {
  width: 50vh;
  height: 50vh;
  border: 1px solid blue;
}
</style>
