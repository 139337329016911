<template lang="html">
  <div class="">
    <el-dialog
      v-model="showEditDialog"
      ref="editParam"
      title="Edit Parameter">
      <h3>Client Parameters</h3>
      <el-form :model="paramToEdit">
        <el-form-item label="Parameter Name">
          <el-input v-model="paramToEdit.parameterName"></el-input>
        </el-form-item>
        <el-form-item label="Value">
          <el-input v-model="paramToEdit.value"></el-input>
        </el-form-item>
        <div v-if="errorsEdit.length > 0">
          <div class="flex" v-for="(error, index) in errorsEdit" :key="index">
          <p class="nospace error">{{error}}</p>
        </div>
        </div>        
        <el-button @click="editParameter" type="primary">Edit</el-button>
      </el-form>
    </el-dialog>
    <h1>Client Parameters Details</h1>
    <h3>Client Parameters</h3>
    <el-row>
      <el-col :span="22">
        <el-row class="header" type="flex">
          <el-col :span="8" class="search">
            <el-input placeholder="Find client parameter" v-model="searchFixed" prefix-icon="Search">
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>

            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubsetFix">
              showing {{filteredParametersFix.length}} of {{deviceParamsFix.length}}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-table :data="filteredParametersFix">
      <el-table-column label="Parameter Name" prop="parameterName" sortable>
      </el-table-column>
      <el-table-column label="Value" prop="value" sortable>
      </el-table-column>
    </el-table>

  <h3>Custom Client Parameters</h3>
    <el-row>
      <el-col :span="22">
        <el-row class="header" type="flex">
          <el-col :span="8" class="search">
            <el-input 
              placeholder="Find custom client parameter" 
              v-model="searchCustom" prefix-icon="Search">
              <template #suffix>
  <el-icon v-if="isSubsetCustom" @click="clearSearch">
    <CircleClose />
  </el-icon>
</template>
          </el-input>
          </el-col>
          <el-col :offset="1" :span="8">
            <div class="results" v-if="isSubsetCustom">
              showing {{filteredCustomEntites.length}} of {{deviceParamsCustom.length}}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="flex" style="margin-bottom: 2rem">
      <el-input class="space-right" placeholder="Parameter name" v-model="parameterModel.name"></el-input>
      <el-input class="space-right" placeholder="Parameter value" v-model="parameterModel.value"></el-input>
      <el-button @click="addParameter" size="small" type="primary">
        <el-icon>
          <Plus />
        </el-icon>
        Add Parameter
      </el-button>
    </div>
    <div v-if="errors.length > 0">
      <div class="flex" v-for="(error, index) in errors" :key="index">
        <p class="nospace error">{{error}}</p>
      </div>
    </div>
    <el-table :data="filteredCustomEntites">
      <el-table-column label="Parameter Name" prop="parameterName" sortable>
      </el-table-column>
      <el-table-column label="Value" prop="value" sortable></el-table-column>
      <el-table-column label="Actions" width="110">
        <template v-slot="scope">
  <actions-dropdown
    :entity="scope.row"
    :edit="true"
    @edit="editCustomParam"
    :hideRetreatFromShop="true"
  ></actions-dropdown>
</template>
      </el-table-column>
    </el-table>
    <br/>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
// TODO: Show two tables: fixed parameters and custom parameters (add possibility to edit them)
//       Show the fixed parameter first, then show "Add" section and finally show table with custom parameters
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import FilterMixin from "../../mixins/FilterMixin";
import impersonation from "../../impersonation";
import _ from "lodash";

export default {
  // TODO: Fetch the clients parameters
  name: "client-parameters",
  components: {
    ActionsDropdown,
  },
  mixins: [FilterMixin],
  created() {
    this.clientId = this.$route.params.id;
    this.$store.dispatch("getDeviceParameters", {
      customerId: impersonation.getCustomer().id,
      clientId: this.clientId,
    });
    this.errors = [];
  },
  data() {
    return {
      showDialog: false,
      clientId: "",
      searchFixed: "",
      searchCustom: "",
      errors: [],
      parameterModel: {
        name: "",
        value: "",
      },
      showEditDialog: false,
      paramToEdit: {
        parameterName: "",
        value: "",
        id: "",
      },
      formerParamName: "",
      errorsEdit: [],
    };
  },
  methods: {
    // openDialog (clientData) {
    //   this.currentClient = clientData
    //   this.$store.dispatch('getDeviceParameters', {customerId: impersonation.getCustomer().id, clientId: this.currentClient.id})
    //   this.errors = []
    //   this.showDialog = true
    // },
    addParameter() {
      if (this.validateInput()) {
        let data = {
          parameter: {
            clientId: this.clientId,
            parameterName: this.parameterModel.name,
            value: this.parameterModel.value,
          },
          customerId: impersonation.getCustomer().id,
        };
        this.$store.dispatch("addCustomParameter", data);
        this.parameterModel.name = "";
        this.parameterModel.value = "";
      }
    },
    validateInput() {
      let specialCharRegex = /[!@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errors = [];
      if (this.parameterModel.name && this.parameterModel.value) {
        if (this.parameterModel.name.startsWith("$")) {
          if (!specialCharRegex.test(this.parameterModel.name)) {
            for (let i = 0; i < this.deviceParams.length; i++) {
              if (this.parameterModel.name === this.deviceParams[i].name) {
                this.errors.push(
                  "There already exists a parameter with this name! Please rename the new parameter!"
                );
                return false;
              }
            }
          } else {
            this.errors.push(
              "The name must not contain special characters or spaces!"
            );
            return false;
          }
        } else {
          this.errors.push("The name must start with a dollar sign!");
          return false;
        }
        return true;
      } else {
        if (!this.parameterModel.name) {
          this.errors.push("You must enter a parameter name");
        }
        if (!this.parameterModel.value) {
          this.errors.push("You must enter a parameter value");
        }
        return false;
      }
    },
    editCustomParam(parameter) {
      this.paramToEdit.parameterName = parameter.parameterName;
      this.paramToEdit.value = parameter.value;
      this.paramToEdit.id = parameter.id;
      this.formerParamName = parameter.name;
      this.showEditDialog = true;
    },
    validateEditInput() {
      let specialCharRegex = /[!@#%^&*()_+=[\]{};':"\\|,.<>/? ]/;
      this.errorsEdit = [];
      if (!this.paramToEdit.parameterName.startsWith("$")) {
        this.errorsEdit.push("The name must start with a dollar sign!");
      }
      if (!this.paramToEdit.parameterName) {
        this.errorsEdit.push("You have to set the parameter name!");
      }
      if (!this.paramToEdit.value) {
        this.errorsEdit.push("You have to set the parameter value!");
      }
      if (!specialCharRegex.test(this.paramToEdit.parameterName)) {
        if (this.paramToEdit.parameterName !== this.formerParamName) {
          for (let i = 0; i < this.deviceParams.length; i++) {
            if (this.paramToEdit.parameterName === this.deviceParams[i].name) {
              this.errorsEdit.push(
                "There already exists a parameter with this name! Please rename the new parameter!"
              );
            }
          }
        }
      } else {
        this.errorsEdit.push(
          "The name must not contain special characters or spaces!"
        );
      }
      return this.errorsEdit.length === 0;
    },
    editParameter() {
      if (this.validateEditInput()) {
        let payload = {
          customerId: impersonation.getCustomer().id,
          clientId: this.clientId,
          data: _.cloneDeep(this.paramToEdit),
        };
        this.$store.dispatch("editDeviceParameter", payload);
        this.showEditDialog = false;
      }
    },
  },
  computed: {
    filteredParametersFix() {
      return this.filterEntities(this.deviceParamsFix, this.searchFixed);
    },
    filteredCustomEntites() {
      return this.filterEntities(this.deviceParamsCustom, this.searchCustom);
    },
    isSubsetFix() {
      return this.deviceParamsFix.length !== this.filteredParametersFix.length;
    },
    isSubsetCustom() {
      return (
        this.deviceParamsCustom.length !== this.filteredCustomEntites.length
      );
    },
    deviceParamsFix() {
      let result = [];
      for (var i = 0; i < this.$store.state.deviceParameters.length; i++) {
        if (this.$store.state.deviceParameters[i].isEditable === false) {
          result.push(this.$store.state.deviceParameters[i]);
        }
      }
      // let paramsFix = this.$store.state.deviceParameters.filter(param => param.isEditable === false)
      return result;
    },
    deviceParamsCustom() {
      let result = [];
      for (var i = 0; i < this.$store.state.deviceParameters.length; i++) {
        if (this.$store.state.deviceParameters[i].isEditable === true) {
          result.push(this.$store.state.deviceParameters[i]);
        }
      }
      return result;
      // let paramsCustom = this.$store.state.deviceParameters.filter(param => param.isEditable === true)
      // return paramsCustom
    },
    deviceParams() {
      return this.$store.state.deviceParameters;
    },
  },
};
</script>

<style lang="css" scoped>
.centeredText {
  text-align: center;
}

.flex {
  width: 100%;
  display: flex;
}

.space-right {
  padding-right: 10px;
}
</style>
