export default {
  name: 'cloudEP-validation',
  data () {
    return {
      rulesCEP: {
        name: [
          { required: true, message: 'Please set the name of the CEP', trigger: 'blur' }
        ],
        TenantId: [
          { required: true, message: 'Please input azure tenant id', trigger: ['blur', 'change'] }
        ],
        ClientId: [
          { required: true, message: 'Please input azure client id', trigger: ['blur', 'change'] }
        ],
        ClientSecret: [
          { required: true, message: 'Please input azure client secret', trigger: ['blur', 'change'] }
        ]
      }
    }
  }
}
