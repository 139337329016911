<template lang="html">
  <span>UNDER CONSTRUCTION</span>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="css" scoped>
</style>
