<template>
  <el-dialog title="Reset your password" v-model="showDialog">
    <div class="center">
      <el-form ref="form" :model="form" :rules="rules">
        <!-- <el-form-item prop="name">
          <div class="flex">
            <label for="name" style="margin-right: 20px;">Username</label>
            <el-input id="name" class="input" v-model="form.name" placeholder="Please enter your name" ></el-input>
          </div>
        </el-form-item> -->
        <el-form-item prop="email">
          <div class="flex">
            <label for="email" style="margin-right: 50px">Email</label>
            <el-input
              class="input"
              id="email"
              v-model="form.email"
              placeholder="Please enter your email"
              type="email"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 20%" @click="submit()"
            >Reset Password</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { resetPassword } from "../services/users";
import { ElMessage } from "element-plus";

export default {
  name: "reset-password",
  data() {
    let validateAdminEmail = (rule, value, callback) => {
      if (this.form.email === "ams@bitstream.de") {
        callback(new Error("You can  not reset  the Admin Password"));
      } else {
        callback();
      }
    };
    return {
      showDialog: false,
      form: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please type in your email address",
            trigger: "blur",
          },
          { validator: validateAdminEmail, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          resetPassword(this.form.email)
            .then(() => {
              ElMessage.success({
                message:
                  "Your password was reset. Please check your email account",
                duration: 5000,
              });
              this.$refs.form.resetFields();
              this.showDialog = false;
            })
            .catch((error) => {
              var message =
                "Your password could not be changed: " + error.response.data;
              ElMessage.error({
                message: message,
                showClose: true,
                duration: 5000,
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0 auto;
  text-align: center;
}

.input {
  width: 50%;
}

.flex {
  display: flex;
}
</style>
