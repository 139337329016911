<template>
  <div style="width: 80%; margin: 0 auto; padding-top: 20px">
    <div>
      <el-form :model="model" ref="form" :rules="rules">
        <el-form-item
          label="Auto Register Password"
          prop="autoRegisterPassword"
        >
          <!-- type="password" -->
          <el-input
            v-model="model.autoRegisterPassword"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Allow Auto Register Clients"
          prop="autoRegisterClients"
        >
          <el-switch
            v-model="model.autoRegisterClients"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-button type="primary" @click="submit">Save</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getGeneralSettings,
  setGeneralSettings,
} from "../../services/customers";
import impersonation from "../../impersonation";
import { ElMessage } from "element-plus";

export default {
  created() {
    getGeneralSettings(impersonation.getCustomer().id).then((response) => {
      this.model.autoRegisterPassword = response.data.autoRegisterPassword;
      this.model.autoRegisterClients = response.data.autoRegisterClients;
    });
  },
  data() {
    return {
      model: {
        autoRegisterPassword: "",
        autoRegisterClients: false,
        localAdminPassword: "",
        localAdminUsername: "",
      },
      rules: {
        autoRegisterPassword: [
          {
            required: true,
            message: "Please set the auto register password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          setGeneralSettings(this.model, impersonation.getCustomer().id).then(
            () => {
              ElMessage.success({
                message: "The password was set successfully",
                duration: 3000,
              });
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
