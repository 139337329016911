export function getSoftwares() {
  return window.axiosInstance.get(`/software`)
}

export function addSoftware(software) {
  return window.axiosInstance.post(`/software`, software)
}

export function editSoftware(software) {
  return window.axiosInstance.put(`/software/${software.id}`, software)
}

export function deleteSoftware(software) {
  return window.axiosInstance.delete(`/software/${software.id}`)
}

export function getSoftwareIcon(fileId) {
  return window.axiosInstance.get(`software/icon/${fileId}`, { responseType: 'blob' })
}

export function getSoftwareIconForShop(softwareId) {
  return window.axiosInstance.get(`software/icon/forShop/${softwareId}`, { responseType: 'blob' })
}

export function getSoftwareIconFileRef(fileId) {
  return window.axiosInstance.get(`software/icon/get/${fileId}`)
}

export function getSoftwareJsonFile(fileId) {
  return window.axiosInstance.get(`software/export/${fileId}`, { responseType: 'blob' })
}

export function getSoftwarePackage(softwareId) {
  return window.axiosInstance.get(`software/getWithIncludes/${softwareId}`)
}

export function getPrevSoftwareForEdit(streamId, version, softwareId) {
  return window.axiosInstance.get(`software/prevSW/${streamId}/${version}/${softwareId}`)
}

export function getNextSoftwareForEdit(streamId, version, softwareId) {
  return window.axiosInstance.get(`software/nextSW/${streamId}/${version}/${softwareId}`)
}

export function getPrevSoftware(streamId, version) {
  return window.axiosInstance.get(`software/prevSW/${streamId}/${version}`)
}

export function getNextSoftware(streamId, version) {
  return window.axiosInstance.get(`software/nextSW/${streamId}/${version}`)
}

export function downloadSoftwareFiles(softwareId) {
  return window.axiosInstance.get(`software/downloadFiles/${softwareId}`)
}

export function publishInShop(softwareId) {
  return window.axiosInstance.put(`software/publishInShop/${softwareId}`)
}

export function retreatFromShop(softwareId) {
  return window.axiosInstance.put(`software/retreatFromShop/${softwareId}`)
}

export function activateSoftware(softwareId) {
  return window.axiosInstance.post(`software/activate/${softwareId}`)
}

export function outdateSoftware(softwareId) {
  return window.axiosInstance.post(`software/outdate/${softwareId}`)
}

export function addRevisionNumber(payload) {
  return window.axiosInstance.post('software/revisionMessage', payload)
}

export function getRevisionMessages(softwareId) {
  return window.axiosInstance.get(`software/revisionMessage/${softwareId}`)
}
