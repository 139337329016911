<template lang="html">
  <el-dialog
    title="Add Hardware Model"
    v-model="showDialog">
    <el-form
      :model="hardwareModel"
      :rules="rules"
      ref="form">
        <el-form-item prop="name" label="Name">
          <el-input placeholder="Please set the name" v-model="hardwareModel.name"></el-input>
        </el-form-item>
        <el-form-item prop="vendor" label="Vendor">
          <el-input placeholder="Please set the vendor" v-model="hardwareModel.vendor"></el-input>
        </el-form-item>
        <el-form-item prop="modelFamily" label="Model Family">
          <el-input placeholder="Please set the model family" v-model="hardwareModel.modelFamily"></el-input>
        </el-form-item>
        <el-form-item prop="modelType" label="Model Type">
          <el-input placeholder="Please set the model type" v-model="hardwareModel.modelType"></el-input>
        </el-form-item>
        <el-form-item prop="productionStart" label="Production Start">
          <el-date-picker
          placeholder="Please set the prodcution start date"
          v-model="hardwareModel.productionStart"
          format="DD.MM.YYYY"
          @change="formatDate('productionStart')"
          type="date">                    
        </el-date-picker>
        </el-form-item>
        <el-form-item prop="productionEnd" label="Production End">
          <el-date-picker
            placeholder="Please set the prodcution end date"
            v-model="hardwareModel.productionEnd"
            format="DD.MM.YYYY"
            @change="formatDate('productionEnd')"
            type="date">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="submit">Add Hardware Model</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "add-hardware-model-dialog",
  created() {},
  data() {
    return {
      showDialog: false,
      hardwareModel: {
        name: "",
        vendor: "",
        modelFamily: "",
        modelType: "",
        productionStart: "",
        productionEnd: "",
      },
      rules: {
        name: [
          { required: true, message: "Please set the name", trigger: "blur" },
        ],
        vendor: [
          { required: true, message: "Please set the vendor", trigger: "blur" },
        ],
        modelFamily: [
          {
            required: true,
            message: "Please set the model family",
            trigger: "blur",
          },
        ],
        modelType: [
          {
            required: true,
            message: "Please set the model type",
            trigger: "blur",
          },
        ],
        productionStart: [
          {
            required: true,
            message: "Please set the production start date",
            trigger: "blur",
          },
        ],
        productionEnd: [
          {
            required: true,
            message: "Please set the production end date",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.hardwareModel);
          console.log({payload})
          this.$store.dispatch("addHardwareModel", payload);
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
    formatDate(name) {
      if (name === "productionStart") {
        let date = new Date(Date.parse(this.hardwareModel.productionStart));
        console.log({date})
        date = new Date(date - date.getTimezoneOffset() * 60000);
        this.hardwareModel.productionStart = date.toUTCString();
      } else {
        let date = new Date(Date.parse(this.hardwareModel.productionEnd));
        console.log({date})
        date = new Date(date - date.getTimezoneOffset() * 60000);
        this.hardwareModel.productionEnd = date.toUTCString();
      }
    },
  },
  computed: {},
};
</script>

<style lang="css" scoped>
</style>
