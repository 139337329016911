export default{
  name: 'filter-mixin',
  data () {
    return {
      search: ''
    }
  },
  methods: {
    clearSearch () {
      this.search = ''
    },
    filterWithKey (entities, query, property = 'key') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }
      return result
    },
    filterEntities (entities, query, property = 'name') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()
        result = entities.filter(function (entity) {
          if (entity[property] !== null && entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }
      return result
    },
    filterInventory (entities, query, property = 'keyName') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }
      return result
    },
    valueFilterInventory (entities, query, property = 'value') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }
      return result
    },
    filterCompanies (entities, query, property = 'corporateName') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }

      return result
    },
    filterSmbStorages (entities, query, property = 'serverName') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }

      return result
    },
    filterDeviceProperties (entities, query, property = 'propertyName') {
      let result = entities
      if (query) {
        query = query.trim().toLowerCase()

        result = entities.filter(function (entity) {
          if (entity[property].toLowerCase().indexOf(query) !== -1) {
            return entity
          }
        })
      }

      return result
    }
  }
}
