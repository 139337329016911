import passwordStrength from '../../validations/passwordStrength'

export default{
  name: 'client-validation',
  data () {
    return {
      rules: {
        id: [
          { required: true, message: 'Please input client ID', trigger: 'blur' }
        ],
        clientName: [
          { required: true, message: 'Please input client name', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' }
        ],
        baseId: [
          {
            required: false,
            message: 'The Base has to be selected',
            trigger: 'change'
          }
        ]
      },
      virtualNetworkRules: {
        name: [
          { required: true, message: 'Please input virtual network name.', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars.', trigger: 'blur' }
        ],
        type: [
          { required: true, message: 'Please select a server size.', trigger: 'change' }
        ],
        operatingSystem: [
          { required: true, message: 'Please select an operating system.', trigger: 'blur' }
        ],
        'admin.username': [
          { required: true, message: 'Please select an admin username.', trigger: 'blur' }
        ],
        'admin.password': [
          { required: true, message: 'Please input admin password', trigger: 'blur' },
          { validator: passwordStrength, trigger: 'blur' },
          { min: 10, message: 'Password should at least have 10 chars', trigger: 'blur' }
        ],
        storageAccount: [
          { required: true, message: 'Please select a system disk.', trigger: 'change' }
        ],
        'systemDisk.name': [
          { required: true, message: 'Please define a system disk name.', trigger: 'blur' }
        ],
        'additionalDisk.name': [
          { required: true, message: 'Please define a additional disk name.', trigger: 'blur' }
        ],
        baseId: [
          {
            required: true, message: 'Please select the Base', trigger: 'change'
          }
        ]
      }
    }
  }
}
