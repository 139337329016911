<template lang="html">
  <div>
    <add-person-dialog ref="addPersonDialog"></add-person-dialog>
    <add-location-dialog ref="addLocationDialog" :showCompanies="false"></add-location-dialog>
    <el-dialog
      title="Add New Company"
      v-model="showDialog">
      <el-form :model="formModel" :rules="companyRules" ref="addCompanyDialogForm">
        <el-form-item label="Corporate Name" prop="corporateName">
          <el-input
            v-model="formModel.corporateName"
            placeholder="Insert Corporate Name" />
        </el-form-item>
        <el-form-item label="Form Of Organization" prop="formOfOrganization">
          <el-input
            placeholder="Insert Form Of Organization"
            v-model="formModel.formOfOrganization" />
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input
            placeholder="Insert Description"
            v-model="formModel.description" />
        </el-form-item>
        <el-form-item label="Website" prop="linkWebsite">
          <el-input
            placeholder="Insert Link to website"
            v-model="formModel.linkWebsite" />
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select v-model="formModel.type" placeholder="Select Type">
            <el-option
              v-for="type in typeOptions"
              :key="type.value"
              :value="type.value"
              :label="type.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Expert" prop="expertId">
          <el-select
            placeholder="Select Expert"
            v-model="formModel.expertId"
            @change="openAddPersonDialog">
            <el-option
              key="new-expert"
              value="new-expert"
              label="+ New Expert">
            </el-option>
            <el-option
              v-for="person in persons"
              :key="person.id"
              :value="person.id"
              :label="getPersonName(person)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Headquarter"
          prop="headquarterId"
          v-if="renderNewLocationOption">
          <el-select
            placeholder="Select Headquarter"
            v-model="formModel.headquarterId"
            @change="openAddLocationDialog">
            <el-option
              key="new-location"
              value="new-location"
              label="+ New Headquarter">
            </el-option>
            <el-option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
              :label="location.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          class="space-top"
          type="primary"
          @click="submit('addCompanyDialogForm')">
            Create Company
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import AddCompanyValidationRules from "../AddCompanyValidationRules";
import impersonation from "../../../../impersonation";
import _ from "lodash";
import AddPersonDialog from "./AddPersonDialog.vue";
//  import AddLocation from '../../../locations/AddLocation.vue'
import AddLocationDialog from "../../../locations/AddLocationDialog";

export default {
  name: "add-company-dialog",
  mixins: [AddCompanyValidationRules],
  props: ["renderNewLocationOption"],
  components: {
    AddLocationDialog,
    // AddLocation,
    AddPersonDialog,
  },
  beforeCreate() {
    // Register the AddPersonDialog component here to prevent the circular reference error!!
    this.$options.components.AddPersonDialog = require("./AddPersonDialog.vue");
  },
  data() {
    return {
      showDialog: false,
      formModel: {
        corporateName: "",
        formOfOrganization: "",
        linkWebsite: "",
        type: "",
        description: "",
        expertId: "",
        headquarterId: "",
      },
      typeOptions: [
        {
          value: "vendor",
          label: "Vendor",
        },
        {
          value: "supplier",
          label: "Supplier",
        },
        {
          value: "internal",
          label: "Internal",
        },
        {
          value: "unspecified",
          label: "Unspecified",
        },
      ],
    };
  },
  methods: {
    openDialog() {
      this.$store
        .dispatch("getCustomersPersons", impersonation.getCustomer().id)
        .then((response) => this.$store.commit("setPersons", response.data));
      this.showDialog = true;
    },
    submit(formName) {
      let payload = this.createPayloadData();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("addCompany", _.cloneDeep(payload));
          this.$refs[formName].resetFields();
          this.closeDialog();
        }
      });
    },
    createPayloadData() {
      let model = this.formModel;
      return {
        corporateName: model.corporateName,
        formOfOrganization: model.formOfOrganization,
        linkWebsite: model.linkWebsite,
        type: model.type,
        description: model.description,
        expertId: model.expertId,
        headquarterId: model.headquarterId,
        customerId: impersonation.getCustomer().id,
      };
    },
    resetForm() {
      this.formModel = {
        corporateName: "",
        formOfOrganization: "",
        linkWebsite: "",
        type: "",
        description: "",
        expertId: "",
        headquarterId: "",
      };
    },
    closeDialog() {
      this.showDialog = false;
    },
    getPersonName(person) {
      let middleName = "";
      if (person.middleName != null && person.middleName.length > 0) {
        middleName = " " + person.middleName;
      }
      return person.givenName + middleName + " " + person.surname;
    },
    openAddLocationDialog() {
      if (this.formModel.headquarterId === "new-location") {
        this.$refs.addLocationDialog.openDialog();
        this.formModel.headquarterId = "";
      }
    },
    openAddPersonDialog() {
      if (this.formModel.expertId === "new-expert") {
        this.$refs.addPersonDialog.openDialog();
        this.formModel.expertId = "";
      }
    },
  },
  computed: {
    // not used
    filteredPersons() {
      let result = [];
      for (let i = 0; i < this.persons.length; i++) {
        if (
          this.persons[i].company !== undefined &&
          this.persons[i].company !== null
        ) {
          if (this.persons[i].company.expertId !== this.persons[i].id) {
            result.push(this.persons[i]);
          }
        }
      }
      return result;
    },
    persons: {
      get() {
        return this.$store.state.persons;
      },
      set() {
        // Do nothing
      },
    },
    locations: {
      get() {
        return this.$store.state.customersLocations;
      },
      set() {
        // Do nothing
      },
    },
  },
};
</script>

<style lang="css" scoped>
.space-top {
  margin-top: 20px;
}
</style>
