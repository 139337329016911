<template>
  <el-upload
    class="upload-item-file"
    :headers="headers"
    :action="action"
    drag
    :limit="1"
    :on-exceed="onExceed"
    :file-list="fileList"
    :on-remove="onRemove"
    :on-success="onSuccess"
  >
    <!-- <i class="el-icon-upload"></i> -->
    <div class="el-upload__text">
      Drop file here or <em>click to upload</em>
    </div>
  </el-upload>
</template>

<script>
export default {
  name: "upload-file",
  props: ["action", "fileList", "onRemove", "onSuccess"],
  methods: {
    onExceed() {
      this.$message.error("Do not upload more than 1 files");
    },
  },
  data() {
    return {
      headers: {
        Authorization:
          window.axiosInstance.defaults.headers.common["Authorization"],
      },
    };
  },
};
</script>

<style>
.upload-item-file {
  width: 100%;
}

.el-upload {
  width: 100%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-upload-dragger:hover {
  border-color: #20a0ff;
}

.el-upload-dragger .el-icon-upload {
  font-size: 67px;
  color: #97a8be;
  margin: 20px 0 16px;
  line-height: 50px;
}

.el-upload-dragger .el-upload__text em {
  color: #20a0ff;
  font-style: normal;
}

.el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.el-upload-list__item:first-child {
  margin-top: 10px;
}

.el-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 14px;
  color: #48576a;
  line-height: 1.8;
  margin-top: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  position: relative;
}

.el-upload-list__item:hover {
  background-color: #eef1f6;
}

.el-upload-list__item-name {
  color: #48576a;
  display: block;
  margin-right: 40px;
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}

.el-upload-list__item.is-success .el-upload-list__item-name:hover {
  color: #20a0ff;
  cursor: pointer;
}

.el-upload-list__item-name [class^="el-icon"] {
  color: #97a8be;
  margin-right: 7px;
  height: 100%;
  line-height: inherit;
}

.el-upload-list__item-status-label {
  position: absolute;
  right: 10px;
  top: 0;
  line-height: inherit;
  color: #13ce66;
}

.el-upload-list__item.is-success .el-icon-close {
  display: none;
}

.el-upload-list__item.is-success:hover .el-icon-close {
  display: inline-block;
  cursor: pointer;
  opacity: 0.75;
  transform: scale(0.7);
  color: #48576a;
}

.el-upload-list__item.is-success:hover .el-icon-circle-check {
  display: none;
}
</style>
