async function uniqueName(_, value, callback) {
  await window.axiosInstance.post('/customers', { Name: value })
    .then(response => {
      if (response.data.Exists === true) {
        callback(new Error('A customer with this name already exists'))
      } else {
        callback()
      }
    })
}

export default {
  name: 'customer-validation',
  data() {
    return {
      customerRules: {
        name: [
          { required: true, message: 'Please input customer name', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' },
          { validator: uniqueName, trigger: 'blur' }
        ],
        systemhouse: [
          { required: true, message: 'Please select a systemhouse', trigger: 'change' }
        ]
        // csdpRoot: [
        //   { required: true, message: 'Please set the CSDP Root Path', trigger: 'blur' }
        // ],
        // csdpContainer: [
        //   { required: true, message: 'Please set the CSDP Container', trigger: 'blur' }
        // ],
        // ltSASRead: [
        //   { required: true, message: 'Please set the Long Term SAS key (readonly)', trigger: 'blur' }
        // ],
        // ltSASWrite: [
        //   { required: true, message: 'Please set the Long Term SAS key (write access)', trigger: 'blur' }
        // ]
      },
      customerEditRules: {
        name: [
          { required: true, message: 'Please set the customer name', trigger: 'blur' }
        ],
        systemhouseId: [
          { required: true, message: 'Please select a systemhouse', trigger: 'change' }
        ]
        // csdpRoot: [
        //   { required: true, message: 'Please set the CSDP Root Path', trigger: 'blur' }
        // ],
        // csdpContainer: [
        //   { required: true, message: 'Please set the CSDP Container', trigger: 'blur' }
        // ],
        // ltSASRead: [
        //   { required: true, message: 'Please set the Long Term SAS key (readonly)', trigger: 'blur' }
        // ],
        // ltSASWrite: [
        //   { required: true, message: 'Please set the Long Term SAS key (write access)', trigger: 'blur' }
        // ]
      }
    }
  }
}
