export function getCustomersLocations (customerId) {
  return window.axiosInstance.get(`/locations/${customerId}`)
}

export function addLocation (payload) {
  return window.axiosInstance.post(`/locations`, payload)
}

export function getLocations () {
  return window.axiosInstance.get(`azure/locations`)
}

export function deleteLocation (locationId) {
  return window.axiosInstance.delete(`/locations/${locationId}`)
}

export function updateLocation (location) {
  return window.axiosInstance.put(`/locations`, location)
}
