export function getImageStreams () {
  return window.axiosInstance.get('imageStreams')
}

export function addImageStream (payload) {
  return window.axiosInstance.post('imageStreams', payload)
}

export function getImageIcon (fileId) {
  return window.axiosInstance.get(`imageStreams/icon/${fileId}`, {responseType: 'blob'})
}

export function editImageStream (payload) {
  return window.axiosInstance.post('imageStreams/edit', payload)
}

export function deleteImageStream (streamId) {
  return window.axiosInstance.delete(`imageStreams/${streamId}`)
}

export function getIconRef (streamId) {
  return window.axiosInstance.get(`/imageStreams/getIconRef/${streamId}`)
}
