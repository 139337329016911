export default {
  name: 'location-validation',
  data () {
    return {
      locationRules: {
        name: [
          {
            required: true,
            message: 'The name is required',
            trigger: 'blur'
          }
        ]
        /* nameAbbreviation: [
          {
            required: true,
            message: 'The name is required',
            trigger: 'blur'
          }
        ],
        country: [
          {
            required: true,
            message: 'The country is required',
            trigger: 'change'
          }
        ],
        countryAbbreviation: [
          {
            required: true,
            message: 'The country is required',
            trigger: 'change'
          }
        ],
        city: [
          {
            required: true,
            message: 'The city is required',
            trigger: 'blur'
          }
        ],
        cityAbbreviation: [
          {
            required: true,
            message: 'The city is required',
            trigger: 'blur'
          }
        ],
        street: [
          {
            required: true,
            message: 'The street name is required',
            trigger: 'blur'
          }
        ],
        number: [
          {
            required: true,
            pattern: /^[0-9]+/,
            message: 'The house number is required',
            trigger: 'blur'
          }
        ],
        postcode: [
          {
            required: true,
            pattern: /^[0-9]+/,
            message: 'The postcode is required',
            trigger: 'blur'
          }
        ],
        publicIp: [
          {
            required: true,
            pattern: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
            message: 'The public IP address is required',
            trigger: 'blur'
          }
        ],
        downloadSpeed: [
          {
            required: true,
            message: 'Please calculate your download speed',
            trigger: 'blur'
          }
        ],
        uploadSpeed: [
          {
            required: true,
            message: 'Please calculate your upload speed',
            trigger: 'blur'
          }
        ],
        timeZone: [
          {
            required: true,
            message: 'Please insert your timezone',
            trigger: 'change'
          }
        ],
        azureLocation: [
          {
            required: true,
            message: 'Please set the Azure location',
            trigger: 'change'
          }
        ],
        company: [
          {
            required: true,
            message: 'Please select the company of the location',
            trigger: 'change'
          }
        ],
        companyId: [
          {
            required: true,
            message: 'Please select the company of the location',
            trigger: 'change'
          }
        ] */
      }
    }
  }
}
