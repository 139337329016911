<template lang="html">
  <div>
    <el-dialog
      title="Add Asset Type"
      v-model="showDialog">
      <el-form
        ref="form"
        :model="assetType"
        :rules="rules">
        <el-form-item label="Asset Type Name" :rules="rules.name" prop="name">
          <el-input v-model="assetType.name" placeholder="Please set the name"></el-input>
        </el-form-item>
        <div style="margin: 0 auto">
          <el-button type="primary" @click="submit">Add</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import impersonation from "../../impersonation";
import _ from "lodash";

export default {
  name: "asset-type-dialog",
  data() {
    let validateName = (rule, value, callback) => {
      if (this.assetType.name.length !== 0) {
        callback();
      } else {
        callback(new Error("Please set the name"));
      }
    };
    return {
      showDialog: false,
      assetType: {
        name: "",
        customerId: impersonation.getCustomer().id,
        fromAdmin: false,
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
      },
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let payload = _.cloneDeep(this.assetType);
          this.$store.dispatch("addAssetType", payload);
          this.$refs.form.resetFields();
          this.showDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>
