export function createVM (payload) {
  return window.axiosInstance.post('/virtual-machines', payload)
}

export function getVMs (customerId) {
  return window.axiosInstance.get(`/virtual-machines/${customerId}`)
}

export function checkDiskName (customerId, baseId, diskName) {
  return window.axiosInstance.get(`virtual-machines/${customerId}/${baseId}/${diskName}`)
}

export function checkVMName (customerId, baseId, vmName) {
  return window.axiosInstance.get(`virtual-machines/${customerId}/${baseId}/${vmName}/checkName`)
}
