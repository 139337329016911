export function addPerson(person) {
  return window.axiosInstance.post(`/persons`, person)
}

export function getCustomersUnemployedPersons(customerId) {
  return window.axiosInstance.get(`/persons/${customerId}`)
}

export function addExpert(person) {
  return window.axiosInstance.post(`/persons/expert`, person)
}

export function deletePerson(personId) {
  return window.axiosInstance.delete(`/persons/${personId}`)
}

export function editPerson(payload) {
  return window.axiosInstance.put(`/persons`, payload)
}

export function getCustomersPersons(customerId) {
  return window.axiosInstance.get(`persons/all/${customerId}`)
}
