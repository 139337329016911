<template lang="html">
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <div>
          <h3>Vendor Models</h3>
          <el-row type="flex" class="header">
            <el-col :span="8" class="search">
              <el-input placeholder="Find vendor model" v-model="searchVendorModel" autofocus>
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <i slot="suffix" class="el-input__icon el-icon-circle-close clickable" v-if="isSubsetVendorModel" @click="clearSearchVendorModel"></i> -->
              </el-input>
            </el-col>
            <el-col :span="8" class="border">
              <div class="results" v-if="isSubsetVendorModel">
                <!-- {{filteredVendorModels().length}} showing  of {{vendorModels.length}} -->
              </div>
            </el-col>
            <el-col :span="8" class="bs-align-right">
              <!-- icon="el-icon-plus" -->
              <el-button
                type="primary"                
                class="button"
                @click="openAddVendorModel">
                  Add Vendor Model
              </el-button>
            </el-col>
          </el-row>
        </div>
        <el-table :data="vendorModels">
          <el-table-column prop="name" label="Name"></el-table-column>
          <el-table-column prop="modelFamily" label="Model Family"></el-table-column>
          <el-table-column prop="modelType" label="Model Type"></el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <el-button type="primary" @click="openEditVendorModel(scope.row)">Edit</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <addVendorModel ref="addVendorModel"></addVendorModel>
    <editVendorModel ref="editVendorModel"></editVendorModel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddVendorModel from "./dialogs/AddVendorModel";
import EditVendorModel from "./dialogs/EditVendorModel";

export default {
  name: "VendorModels",
  components: {
    AddVendorModel,
    EditVendorModel,
  },
  created() {
    this.$store.dispatch("getVendorModels");
  },
  data() {
    return {
      searchVendorModel: "",
    };
  },
  methods: {
    openAddVendorModel() {
      this.$refs.addVendorModel.openDialog();
    },
    isSubsetVendorModel() {
      // TODO
      return true;
    },
    clearSearchVendorModel() {
      this.searchVendorModel = "";
    },
    openEditVendorModel(vendorModel) {
      // console.log(this.$refs)
      this.$refs.editVendorModel.openDialog(vendorModel);
    },
  },
  computed: {
    ...mapState(["vendorModels"]),
  },
};
</script>

<style lang="css" scoped>
</style>
