<template>
  <div>
    <under-construction></under-construction>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find certification"
              v-model="search"
              autofocus
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredCerts.length }} of {{ certs.length }}
            </div>
          </el-col>
        </el-row>
        <el-table :data="filteredCerts" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable width="200">
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
          </el-table-column>
          <el-table-column width="150">
            <template>
              <el-button type="primary">Order Now</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UnderConstruction from "../shared/UnderConstrction";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";

export default {
  components: { UnderConstruction },
  name: "certification",
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getCerts");
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState(["certs"]),
    filteredCerts() {
      return [];
      // return this.filterEntities(this.certs, this.search)
    },
    isSubset() {
      return [];
      // return this.certs.length !== this.filteredCerts.length
    },
  },
};
</script>
