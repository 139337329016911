<template>
  <div>
    <h2>{{}}</h2>
    <el-row>
      <el-col :span="22" :offset="1">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find Domain Option"
              v-model="search"
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredScripts.length }} of {{ scripts.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <el-button @click="openBootableUSBTutorial"
              ><el-icon><Document /></el-icon>Bootable USB</el-button
            >
            <el-button @click="openDomainDetail"
              ><el-icon><Tickets /></el-icon>Domain Details</el-button
            >
          </el-col>
        </el-row>

        <!--el-row style="margin: 0 -16px;">
          <el-col :span="8">
            <el-card class="box-card" style="margin: 0 16px 32px 16px; padding-bottom: 16px">
              <div slot="header" class="clearfix">
                <span class="module-name">{{ "User" }}</span>
              </div>
              <div class="bottom clearfix">
                <el-button style="float: right;" type="text" @click="openScriptFile()" >Add File</el-button>
              </div>
            </el-card>
          </el-col-->
        <!--el-col :span="8">
            <el-card class="box-card" style="margin: 0 16px 32px 16px; padding-bottom: 16px">
              <div slot="header" class="clearfix">
                <span class="module-name">{{ "GPO" }}</span>
              </div>
              <div class="bottom clearfix">
                <el-button style="float: right;" type="text">Add GPO</el-button>
              </div>
            </el-card>
          </el-col-->
        <!--el-col :span="8">
            <el-card class="box-card" style="margin: 0 16px 32px 16px; padding-bottom: 16px">
              <div slot="header" class="clearfix">
                <span class="module-name">{{ "OU" }}</span>
              </div>
              <div class="bottom clearfix">
                <el-button style="float: right;" type="text" @click="openOuAdd()">Add OU</el-button>
              </div>
            </el-card>
          </el-col>
        </el-row-->
        <el-row style="margin: 0 -16px">
          <el-col :span="8" v-for="script in filteredScripts" :key="script.id">
            <el-card
              class="box-card"
              style="margin: 0 16px 32px 16px; padding-bottom: 16px"
            >
              <!-- slot="header" -->
              <div class="clearfix">
                <span class="module-name">{{ script.name }}</span>
                <span class="versions"
                  >{{ script.versions.length }} version<span
                    v-if="script.versions.length > 1"
                    >s</span
                  ></span
                >
              </div>
              <div class="text item" style="margin-bottom: 24px">
                {{ script.description }}
              </div>
              <div class="bottom clearfix">
                <el-button
                  style="float: right"
                  type="text"
                  @click="openScriptExecute(script.id)"
                  >Add {{ script.name }}</el-button
                >
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <script-execute ref="scriptExecute"></script-execute>
    <!--script-file ref="scriptFile"></script-file-->
    <domain-detail ref="domainDetail"></domain-detail>
    <!--ou-add ref="ouAdd"></ou-add-->
    <bootableusb-tutorial ref="bootableusbTutorial"></bootableusb-tutorial>
  </div>
</template>

<script>
import FilterMixin from "../../mixins/FilterMixin";
import ScriptExecute from "../scripts/ScriptExecute";
// import ScriptFile from '../scripts/ScriptAddFile'
// import OuAdd from '../ou/OuAdd'
import DomainDetail from "./DomainDetail";
import BootableusbTutorial from "./BootableUsbTutorial";

export default {
  name: "domain",
  mixins: [FilterMixin],
  components: {
    ScriptExecute,
    DomainDetail,
    // ScriptFile,
    // OuAdd,
    BootableusbTutorial,
  },
  created() {
    this.$store.dispatch("getScripts");
    //    this.$store.dispatch('getDomains', this.$route.params.id);
  },
  data() {
    return {
      id: this.$route.params.id,
      did: this.$route.params.did,
      search: "",
    };
  },
  methods: {
    openDomainDetail() {
      this.$refs.domainDetail.open(this.id, this.did);
    },
    openScriptExecute(scriptId) {
      this.$refs.scriptExecute.open(this.id, this.did, scriptId);
    },
    /* openScriptFile () {
      this.$refs.scriptFile.open()
    },
    openOuAdd () {
      this.$refs.ouAdd.open()
    }, */
    openBootableUSBTutorial() {
      this.$refs.bootableusbTutorial.open();
    },
  },
  computed: {
    scripts() {
      return this.$store.state.scripts;
    },
    scriptsLoading() {
      return this.$store.state.scriptsLoading;
    },
    filteredScripts() {
      return this.filterEntities(this.scripts, this.search);
    },
    isSubset() {
      return this.scripts.length !== this.filteredScripts.length;
    },
  },
};
</script>

<style scoped>
.module-name {
  font-size: 1.2em;
}

.versions {
  font-size: 16px;
  color: #aaa;
}
</style>
