export default{
  name: 'deviceOption-validation',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input device option name', trigger: 'blur' },
          { min: 3, message: 'Name should at least have 3 chars', trigger: 'blur' }
        ],
        content: [{required: true, message: 'Please add script', trigger: 'blur'}],
        peOnly: [{required: true, trigger: 'change'}],
        osType: [{required: true, message: 'Please select the OS type', trigger: 'change'}]
      }
    }
  }
}
