<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input
              placeholder="Find certification"
              v-model="search"
              autofocus
              prefix-icon="Search"
            >
              <template #suffix>
                <el-icon v-if="isSubset" @click="clearSearch">
                  <CircleClose />
                </el-icon>
              </template>
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="results" v-if="isSubset">
              showing {{ filteredCerts.length }} of {{ certs.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <el-button type="primary" @click="openCertAdd">
              <el-icon>
                <Plus />
              </el-icon>
              Add certification</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="filteredCerts" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable width="200">
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
          </el-table-column>
          <el-table-column width="110">
            <template v-slot="scope">
              <actions-dropdown
                :entity="scope.row"
                :edit="true"
                :deleteItem="true"
                @edit="openCertEdit"
                @delete="confirmDelete"
                :hideRetreatFromShop="true"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <cert-add ref="certAdd"></cert-add>
    <cert-edit ref="certEdit"></cert-edit>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import CertAdd from "./CertAdd.vue";
import CertEdit from "./CertEdit.vue";

export default {
  name: "admin-certs",
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getCerts");
  },
  components: {
    ActionsDropdown,
    CertAdd,
    CertEdit,
  },
  methods: {
    openCertAdd() {
      this.$refs.certAdd.open();
    },
    openCertEdit(cert) {
      this.$refs.certEdit.open(_.cloneDeep(cert));
    },
    confirmDelete(cert) {
      this.$confirm(
        "This will permanently delete the certification. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, cert), this.deleteError);
    },
    deleteSuccess(cert) {
      this.$store.dispatch("deleteCert", cert);
    },
    deleteError() {},
  },
  computed: {
    ...mapState(["certs"]),
    filteredCerts() {
      return this.filterEntities(this.certs, this.search);
    },
    isSubset() {
      return this.certs.length !== this.filteredCerts.length;
    },
  },
};
</script>
