<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="22" :sm="24">
        <el-row type="flex" class="header">
          <el-col :span="8" class="search">
            <el-input placeholder="Find workflow" v-model="search" autofocus>
              <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close clickable"
                v-if="isSubset"
                @click="clearSearch"
              ></i> -->
            </el-input>
          </el-col>
          <el-col :span="8" class="border">
            <div class="results" v-if="isSubset">
              showing {{ filteredWorkflows.length }} of {{ workflows.length }}
            </div>
          </el-col>
          <el-col :span="8" class="bs-align-right">
            <!-- icon="el-icon-plus" -->
            <el-button type="primary" @click="openWorkflowAdd"
              >Add workflow</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="filteredWorkflows" highlight-current-row>
          <el-table-column prop="name" label="Name" sortable width="200">
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
          </el-table-column>
          <el-table-column width="110">
            <template v-slot="scope">
              <!-- TODO: ADD @delete="confirmDelete" -->
              <actions-dropdown
                :hideRetreatFromShop="true"
                :entity="scope.row"
                @edit="openWorkflowEdit"
                :edit="true"
              ></actions-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <workflow-add ref="workflowAdd"></workflow-add>
    <workflow-edit ref="workflowEdit"></workflow-edit>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import FilterMixin from "../../mixins/FilterMixin";
import ActionsDropdown from "../shared/ActionsDropdown.vue";
import WorkflowAdd from "./WorkflowAdd.vue";
import WorkflowEdit from "./WorkflowEdit.vue";

export default {
  name: "admin-workflows",
  mixins: [FilterMixin],
  created() {
    this.$store.dispatch("getWorkflows");
  },
  components: {
    ActionsDropdown,
    WorkflowAdd,
    WorkflowEdit,
  },
  methods: {
    openWorkflowAdd() {
      this.$refs.workflowAdd.open();
    },
    openWorkflowEdit(workflow) {
      this.$refs.workflowEdit.open(_.cloneDeep(workflow));
    },
    confirmDelete(workflow) {
      this.$confirm(
        "This will permanently delete the workflow. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(this.deleteSuccess.bind(this, workflow), this.deleteError);
    },
    deleteSuccess(workflow) {
      this.$store.dispatch("deleteWorkflow", workflow);
    },
    deleteError() {},
  },
  computed: {
    ...mapState(["workflows"]),
    filteredWorkflows() {
      return this.filterEntities(this.workflows, this.search);
    },
    isSubset() {
      return this.workflows.length !== this.filteredWorkflows.length;
    },
  },
};
</script>
