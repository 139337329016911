export function getCustomersOSImages (customerId) {
  return window.axiosInstance.get(`customerImages/${customerId}`)
}

export function deleteCustomerOSImage (customerId, imageId) {
  return window.axiosInstance.delete(`customerImages/${customerId}/${imageId}`)
}

export function deleteOEMPartition (customerId, imageId) {
  return window.axiosInstance.delete(`customerImages/deleteOEMPartition/${customerId}/${imageId}`)
}

export function deleteOEMLogo (customerId, imageId) {
  return window.axiosInstance.delete(`customerImages/deleteOEMLogo/${customerId}/${imageId}`)
}

export function deleteUnattend (customerId, imageId) {
  return window.axiosInstance.delete(`customerImages/deleteUnattend/${customerId}/${imageId}`)
}

export function deleteDesktop (customerId, imageId) {
  return window.axiosInstance.delete(`customerImages/deleteDesktop/${customerId}/${imageId}`)
}

export function getCustomerImageStreams (customerId) {
  return window.axiosInstance.get(`customerImages/getImageStreams/${customerId}`)
}

export function deleteCustomerImageStream (streamId) {
  return window.axiosInstance.delete(`customerImages/customerImageStream/${streamId}`)
}

export function getCustomerImageStreamsImages (streamId) {
  return window.axiosInstance.get(`customerImages/customerImageStream/${streamId}`)
}

export function setOSStreamSettings (streamId, payload) {
  return window.axiosInstance.post(`customerImages/setOSSettings/${streamId}`, payload)
}

export function getRevisionImages (customerId) {
  return window.axiosInstance.get(`customerImages/${customerId}/checkRevisionNumbers`)
}

export function repairImage (customerId, imageId) {
  return window.axiosInstance.post(`customerImages/repairImage/${customerId}/${imageId}`)
}

export function getNewImages (customerId) {
  return window.axiosInstance.get(`customerImages/${customerId}/newImages`)
}

export function shopNewImages (customerId, imageId) {
  return window.axiosInstance.post(`customerImages/${customerId}/shopNewImage/${imageId}`)
}
