export function getDeviceOptions() {
  return window.axiosInstance.get('/device-options')
}

export function getDeviceOption(deviceOption) {
  return window.axiosInstance.get(`/scripts/${deviceOption.id}`)
}

export function addDeviceOption(deviceOption) {
  return window.axiosInstance.post('/device-options', deviceOption)
}

export function editDeviceOption(deviceOption) {
  return window.axiosInstance.put(`/device-options/${deviceOption.id}`, deviceOption)
}

export function addDeviceOptionVersion(deviceOption) {
  console.log('Payload for request: ', { deviceOption })
  return window.axiosInstance.post(`/device-options/${deviceOption.id}/versions`, deviceOption)
}

export function getDeviceOptionVersions() {
  return window.axiosInstance.get(`/device-options/versions`)
}

export function addDeviceOptionAssign(customerId, clientId, deviceOptionAssign) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${clientId}/options`, deviceOptionAssign)
}

export function getDeviceOptionAssign(customerId, clientId) {
  return window.axiosInstance.get(`/customers/${customerId}/clients/${clientId}/options`)
}

export function deleteDeviceOptionAssign(customerId, clientId) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${clientId}/options`, [])
}

export function downloadDeviceOption(deviceOptionId, scriptDetails) {
  return window.axiosInstance.get(`/scripts/${deviceOptionId}/zip/${scriptDetails.number}`, { responseType: 'blob' })
}

export function deleteOption(optionId) {
  return window.axiosInstance.delete(`/scripts/${optionId}`)
}

export function assignAdminOption(customerId, clientId, payload) {
  return window.axiosInstance.post(`/customers/${customerId}/clients/${clientId}/assignAdminOption`, payload)
}
