export const defaultModel = {
  name: '',
  descriptionShort: '',
  description: '',
  vendor: '',
  version: '',
  ruleApplicability: {
    name: '',
    type: '',
    architecture: '',
    successon: true,
    data: null
  },
  ruleDetection: {
    name: '',
    type: '',
    architecture: '',
    successon: true,
    data: null
  },
  taskInstall: {
    name: '',
    files: [],
    executable: '',
    commandline: '',
    useShellExecute: false
  },
  taskUninstall: {
    name: '',
    files: [],
    executable: '',
    commandline: '',
    useShellExecute: false
  },
  taskUpdate: {
    name: '',
    files: [],
    executable: '',
    commandline: '',
    useShellExecute: false
  }
}
