<template>
  <el-dialog v-model="showDialog" title="Edit workflow">
    <el-form ref="form" :model="workflow" label-width="180px">
      <el-form-item label="Name" prop="name">
        <el-input
          v-model="workflow.name"
          placeholder="Workflow name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          type="textarea"
          v-model="workflow.description"
          placeholder="Description"
          :autosize="{ minRows: 3 }"
        ></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <el-button type="primary" @click="submit(workflow)">Edit</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "workflow-edit",
  data() {
    return {
      showDialog: false,
      workflow: {
        id: "",
        name: "",
        description: "",
      },
    };
  },
  methods: {
    open(workflow) {
      this.workflow = workflow;
      this.showDialog = true;
    },
    submit(workflow) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch("editWorkflow", _.cloneDeep(workflow));
          this.showDialog = false;
        }
      });
    },
  },
};
</script>
