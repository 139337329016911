export const defaultModel = {
  assetId: '',
  uuid: '',
  name: '',
  description: '',
  organizationalUnitId: '',
  macAddresses: [],
  vendor: '',
  usageStatus: '',
  os: {
    uefi: false,
    osVersion: '',
    languagePackage: '',
    keyboardLayout: ''
  },
  bios: {
    biosSettings: '',
    installedVersion: '',
    internalVersion: '',
    manufacturer: '',
    manufacturerVersion: ''
  },
  hardware: {
    type: '',
    hdd: '',
    displayResolution: '',
    chipSet: '',
    manufacturer: '',
    modelNumber: '',
    processor: '',
    ram: '',
    serialNumber: '',
    serviceTag: '',
    tpmChipData: ''
  },
  network: {
    ipv4: '',
    ipv6: '',
    dns: '',
    gateway: '',
    dhcp: ''
  },
  purchase: {
    acquisitionCost: '',
    costUnitAssignment: '',
    decommissioningDate: '',
    purchaseDate: '',
    vendor: ''
  },
  partition: {
    gpt: '',
    overprovisioning: ''
  },
  joinedDomain: '',
  proxy: '',
  location: '',
  installationDate: '',
  mainUser: ''
}
